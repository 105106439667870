<!-- 经纪人列表 -->
<template>
    <list-page-layout>
        <search-panel slot="header" @search="handleSearch" @refresh="handleRefresh" />
        <container-panel>
            <!-- 标题 start -->
            <template slot="title">
                企业认证管理
            </template>
            <!-- 标题 end -->
            <!-- 操作按钮 start -->
            <template slot="button" slot-scope="">
                <custom-button
                    v-auth="ENTERPRISE_AUTHORIZATION_ADD_MENU_CODE"
                    text="新增企业认证"
                    type="primary"
                    @click="handleAddAgent" />
            </template>
            <!-- 操作按钮 end -->
            <!-- 表格 start -->
            <template slot="content" slot-scope="">
                <a-table
                    :columns="columns"
                    :data-source="data"
                    :pagination="pagination"
                    :loading="loading"
                    row-key="id"
                    @change="handleTableChange"
                >
                    <!-- 在职状态 -->
                    <template slot="jobStatus" slot-scope="jobStatus">
                        {{ getApiEnumLabelFromList(jobStatusList, jobStatus) }}
                    </template>
                    <!-- 操作 -->
                    <template slot="action" slot-scope="action">
                        <a-button
                            v-auth="ENTERPRISE_AUTHORIZATION_DETAIL_MENU_CODE"
                            type="link"
                            @click="handleClickLook(action)"
                        >
                            查看
                        </a-button>
                        <a-button
                            v-if="action.type === '默认' && (action.state === ENTERPRISE_AUTHORIZATION_WAIT_SUBBMIT_TEXT || action.state === ENTERPRISE_AUTHORIZATION_UNPASS_TEXT ||  action.state ===ENTERPRISE_AUTHORIZATION_CANCEL_TEXT)"
                            v-auth="ENTERPRISE_AUTHORIZATION_DELETE_BUTTON"
                            type="link"
                            @click="handleDelete(action)"
                        >
                            删除
                        </a-button>
                        <a-button
                            v-if="action.state === ENTERPRISE_AUTHORIZATION_PASS_TEXT && action.enterpriseAuthorizationBook && action.enterpriseAuthorizationBook.state === ENTERPRISE_AUTHORIZATION_HAS_VALID_TEXT"
                            v-auth="ENTERPRISE_AUTHORIZATION_PURCHASE_INSURANCE_BUTTON"
                            type="link"
                            @click="handleJumpCart(action)"
                        >
                            购买保险
                        </a-button>
                        <a-button
                            v-if="action.state === ENTERPRISE_AUTHORIZATION_PASS_TEXT && action.enterpriseAuthorizationBook && (action.enterpriseAuthorizationBook.state === ENTERPRISE_AUTHORIZATION_HAS_VALID_TEXT || action.enterpriseAuthorizationBook.state === ENTERPRISE_AUTHORIZATION_INVALIDS_TEXT)"
                            v-auth="ENTERPRISE_AUTHORIZATION_ORDER_BUTTON"
                            type="link"
                            @click="handleJumpOrderList(action)"
                        >
                            查看订单
                        </a-button>
                        <a-button
                            v-if="action.state === ENTERPRISE_AUTHORIZATION_PASS_TEXT && action.enterpriseAuthorizationBook && (action.enterpriseAuthorizationBook.state === ENTERPRISE_AUTHORIZATION_WAIT_VALID_TEXT || action.enterpriseAuthorizationBook.state === ENTERPRISE_AUTHORIZATION_HAS_VALID_TEXT)"
                            v-auth="ENTERPRISE_AUTHORIZATION_RENEW_AUTHENTICATION_MENU_CODE"
                            type="link"
                            @click="handleClickLook(action, true, true, true)"
                        >
                            更新认证
                        </a-button>
                        <a-button
                            v-if="action.state === ENTERPRISE_AUTHORIZATION_PASS_TEXT && action.enterpriseAuthorizationBook && action.enterpriseAuthorizationBook.state === ENTERPRISE_AUTHORIZATION_INVALIDS_TEXT"
                            v-auth="ENTERPRISE_AUTHORIZATION_RENEW_VALID_MENU_CODE"
                            type="link"
                            @click="handleClickLook(action, true, true)"
                        >
                            更新授权
                        </a-button>
                        <!-- TODO 3.2.2-b期需求暂不做 -->
                        <!-- <a-button
                            v-auth="AGENT_MANAGEMENT_LIST_QUIT_BUTTON"
                            type="link"
                            @click="handleClickQuit(action)"
                        >
                            离职
                        </a-button> -->
                    </template>
                </a-table>
            </template>
            <!-- 表格 end -->
        </container-panel>
    </list-page-layout>
</template>

<script>
// 组件
import { Button as AButton, Table as ATable } from 'ant-design-vue'
import ListPageLayout from '@layout/ListPageLayout'
import ContainerPanel from '@components/ContainerPanel'
import SearchPanel from './SearchPanel'
import CustomButton from '@components/Button'

// 常量
import { DEFAULT_PAGES_SIZE, CLIENT_BASE_URL } from '@/config'

// 权限
import {
    ENTERPRISE_AUTHORIZATION_DETAIL_MENU_CODE,
    ENTERPRISE_AUTHORIZATION_ADD_MENU_CODE,
    ENTERPRISE_AUTHORIZATION_RENEW_AUTHENTICATION_MENU_CODE,
    ENTERPRISE_AUTHORIZATION_RENEW_VALID_MENU_CODE
} from '@constant/menu_code'

// 权限
import {
    ENTERPRISE_AUTHORIZATION_DELETE_BUTTON,
    ENTERPRISE_AUTHORIZATION_PURCHASE_INSURANCE_BUTTON,
    ENTERPRISE_AUTHORIZATION_ORDER_BUTTON
} from '@constant/authEnum/enterprise'

// 枚举
import { JOB_STATUS_LIST,
         ENTERPRISE_AUTHORIZATION_WAIT_SUBBMIT_TEXT,
         ENTERPRISE_AUTHORIZATION_WAIT_AUTHORIZATION_TEXT,
         ENTERPRISE_AUTHORIZATION_PASS_TEXT,
         ENTERPRISE_AUTHORIZATION_UNPASS_TEXT,
         ENTERPRISE_AUTHORIZATION_CANCEL_TEXT,
         ENTERPRISE_AUTHORIZATION_WAIT_VALID_TEXT,
         ENTERPRISE_AUTHORIZATION_HAS_VALID_TEXT,
         ENTERPRISE_AUTHORIZATION_INVALIDS_TEXT,
         ENTERPRISE_AUTHORIZATION_WAIT_SUBBMIT_RENEW_TEXT,
         ENTERPRISE_AUTHORIZATION_WAIT_AUTHORIZATION_RENEW_TEXT,
         ENTERPRISE_AUTHORIZATION_PASS_RENEW_TEXT,
         ENTERPRISE_AUTHORIZATION_UNPASS_RENEW_TEXT,
         ENTERPRISE_AUTHORIZATION_CANCEL_RENEW_TEXT } from '@constant/enum'

// 工具
import moment from 'moment'

// mixins
import tableShowTotal from '@mixins/tableShowTotal'
import enumOperate from '@mixins/enumOperate'
import qs from 'qs'

// 接口
// import getAgentManagementListService from '@service/agent/agentManagement/getAgentManagementListService'
// import agentManagementExportService from '@service/agent/agentManagement/agentManagementExportService'
import getEnterpriseAuthorizationListService from '@service/enterprise/getEnterpriseAuthorizationListService'
import removeEnterpriseAuthorizationService from '@service/enterprise/removeEnterpriseAuthorizationService'

const columns = [
    {
        title: '企业名称',
        dataIndex: 'enterprisName',
        key: 'enterprisName',
        width: 150
    },
    {
        title: '统一信用代码',
        dataIndex: 'creditCode',
        key: 'creditCode',
        width: 150
    },
    {
        title: '注册地址',
        dataIndex: 'regAddress',
        key: 'regAddress',
        width: 160
    },
    {
        title: '审核状态',
        dataIndex: 'state',
        key: 'state',
        width: 90,
        customRender: (text, record) => {
            const { state, type } = record
            const result = `${type !== '默认' ? type + state : state}`
            return result
        }
    },
    {
        title: '授权状态',
        dataIndex: 'authState',
        key: 'authState',
        width: 90,
        customRender: (text, record) => {
            const { state, enterpriseAuthorizationBook } = record
            const result = `${state === '已通过' ? enterpriseAuthorizationBook ? enterpriseAuthorizationBook.state : '-' : '-'}`
            return result
        }
    },
    {
        title: '授权生效日期',
        dataIndex: 'startDateTime',
        key: 'startDateTime',
        customRender: (text, record) => {
            const { state, enterpriseAuthorizationBook } = record
            const result = `${state === '已通过' ? enterpriseAuthorizationBook ? enterpriseAuthorizationBook.startDateTime : '-' : '-'}`
            return result
        }
    },
    {
        title: '授权到期日期',
        dataIndex: 'endDateTime',
        key: 'endDateTime',
        customRender: (text, record) => {
            const { state, enterpriseAuthorizationBook } = record
            const result = `${state === '已通过' ? enterpriseAuthorizationBook ? enterpriseAuthorizationBook.endDateTime : '-' : '-'}`
            return result
        }
    },
    {
        title: '申请时间',
        dataIndex: 'modifyDateTime',
        key: 'modifyDateTime'
    },
    {
        title: '操作',
        key: 'action',
        scopedSlots: { customRender: 'action' }
    }
]

export default {
    name: 'EnterpriseAuthorizationListPage',
    components: {
        ListPageLayout,
        SearchPanel,
        ContainerPanel,
        ATable,
        AButton,
        CustomButton
    },
    mixins: [tableShowTotal, enumOperate],
    data() {
        return {
            // 权限code
            ENTERPRISE_AUTHORIZATION_DETAIL_MENU_CODE,
            ENTERPRISE_AUTHORIZATION_ADD_MENU_CODE,
            ENTERPRISE_AUTHORIZATION_RENEW_AUTHENTICATION_MENU_CODE,
            ENTERPRISE_AUTHORIZATION_RENEW_VALID_MENU_CODE,
            ENTERPRISE_AUTHORIZATION_DELETE_BUTTON,
            ENTERPRISE_AUTHORIZATION_PURCHASE_INSURANCE_BUTTON,
            ENTERPRISE_AUTHORIZATION_ORDER_BUTTON,
            ENTERPRISE_AUTHORIZATION_WAIT_SUBBMIT_TEXT,
            ENTERPRISE_AUTHORIZATION_WAIT_AUTHORIZATION_TEXT,
            ENTERPRISE_AUTHORIZATION_PASS_TEXT,
            ENTERPRISE_AUTHORIZATION_UNPASS_TEXT,
            ENTERPRISE_AUTHORIZATION_CANCEL_TEXT,
            ENTERPRISE_AUTHORIZATION_WAIT_VALID_TEXT,
            ENTERPRISE_AUTHORIZATION_HAS_VALID_TEXT,
            ENTERPRISE_AUTHORIZATION_INVALIDS_TEXT,
            ENTERPRISE_AUTHORIZATION_WAIT_SUBBMIT_RENEW_TEXT,
            ENTERPRISE_AUTHORIZATION_WAIT_AUTHORIZATION_RENEW_TEXT,
            ENTERPRISE_AUTHORIZATION_PASS_RENEW_TEXT,
            ENTERPRISE_AUTHORIZATION_UNPASS_RENEW_TEXT,
            ENTERPRISE_AUTHORIZATION_CANCEL_RENEW_TEXT,
            // 在职状态
            jobStatusList: JOB_STATUS_LIST,
            // 列
            columns,
            // 查询条件
            params: {},
            // 数据
            data: [],
            // 分页数量
            limit: DEFAULT_PAGES_SIZE,
            // 总记录数量
            total: 0,
            // 当前索引
            current: 1,
            // 加载状态
            loading: false,
            // 导出按钮加载状态
            exportLoading: false
        }
    },
    computed: {
        pagination() {
            const {
                total,
                limit,
                current,
                tableShowTotal
            } = this
            return {
                showTotal: (total, range) => tableShowTotal(total, range),
                current,
                total,
                pageSize: limit,
                showSizeChanger: true,
                showQuickJumper: true
            }
        },
        userInfo() {
            return this.$store.getters.userInfo
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        moment,
        /**
         * 获取 列表
         */
        async getList() {
            try {
                this.loading = true
                const { params, current, limit } = this
                const { total, records } = await getEnterpriseAuthorizationListService({
                    userId: this.userInfo.id,
                    current,
                    size: limit,
                    ...params
                })
                this.total = total || 0
                if (records && records.length) {
                    this.data = records
                } else {
                    this.data = []
                }
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        },
        /**
         * 新增经纪人
         */
        handleAddAgent() {
            this.$router.push({ name: 'EnterpriseAuthorizationAdd' })
        },
        /**
         * 批量导入(暂时不做)
         */
        handleBatchLoad() {

        },
        /**
         * 导出
         */
        async handleExportAgent() {
            try {
                // this.exportLoading = true
                // const { params } = this
                // const url = await agentManagementExportService({
                //     ...params
                // })
                // this.$message.success('导出成功')
                // let link = document.createElement('a')
                // // 设置导出的文件名
                // let getTime = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
                // link.download = `经纪人明细${getTime}.xlsx`
                // link.href = url
                // // 点击获取文件
                // link.click()
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.exportLoading = false
            }
        },
        /**
         * 查看
         */
        handleClickLook(action, needEdit, isRenewCer, isConfirm) {
            if (isRenewCer) {
                if (isConfirm) {
                    this.$confirm({
                        title: '更新认证审核期间，无法购买保险，谨慎操作！',
                        okText: '确定',
                        okType: 'danger',
                        cancelText: '取消',
                        onOk: async () => {
                            const { id } = action || {}
                            let query = {
                                id,
                                isRenewCer
                            }
                            if (needEdit) query.needEdit = true
                            this.$router.push({
                                name: 'EnterpriseAuthorizationDetail',
                                query
                            })
                        },
                        onCancel() {
                        }
                    })
                } else {
                    const { id } = action || {}
                    let query = {
                        id,
                        isRenewCer
                    }
                    if (needEdit) query.needEdit = true
                    this.$router.push({
                        name: 'EnterpriseAuthorizationDetail',
                        query
                    })
                }

            } else {
                const { id } = action || {}
                let query = { id }
                if (needEdit) query.needEdit = true
                this.$router.push({
                    name: 'EnterpriseAuthorizationDetail',
                    query
                })
            }

        },
        /**
         * 删除
         */
        async handleDelete(action) {
            this.$confirm({
                title: '企业信息删除后不可撤销恢复，请谨慎操作！',
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk: async () => {
                    try {
                        this.loading = true
                        const { id } = action || {}
                        await removeEnterpriseAuthorizationService({ id }) || {}
                        this.getList()
                    } catch (e) {
                        this.$message.error(e.message)
                        this.$captureException(e)
                    } finally {
                        this.loading = false
                    }
                },
                onCancel() {
                }
            })
        },
        /**
         * 跳转 购物车 实为跳crm产品列表
         * @params item
         */
        handleJumpCart(action) {
            const { id, enterprisName, enterpriseId } = action || {}

            const query = {
                enterprisName,
                enterpriseId,
                enterpriseAuthorizationId: id
            }
            // window.open('/product', '_self')
            // path: '/product',
            //     query: {}
            this.$router.push({
                name: 'EnterpriseAuthorizationProductList',
                query
            })
            // const query = {
            //     agentUserId: this.userInfo.id, // 经纪人id
            //     mobile: this.userInfo.mobile, // 经纪人手机号
            //     details: 'CRM'
            // }

            // window.open(`${CLIENT_BASE_URL}/product/info?${qs.stringify(query)}`, '_blank' )
            // window.open(`${CLIENT_BASE_URL}/cart?${qs.stringify(query)}`, '_blank' )
            // window.open(`http://172.16.22.202:3000/cart?${qs.stringify(query)}`, '_blank' )
        },
        /**
         * 跳转 订单列表 实为购物车
         * @params item
         */
        handleJumpOrderList() {
            const query = {
                agentUserId: this.userInfo.id, // 经纪人id
                mobile: this.userInfo.mobile, // 经纪人手机号
                details: 'CRM'
            }


            // const query = {
            //     agentUserId: this.userInfo.id, // 经纪人id
            //     mobile: this.userInfo.mobile, // 经纪人手机号
            //     details: 'CRM',
            //     redirectPath: 'order-list'
            // }

            // window.open(`${CLIENT_BASE_URL}/order/list?${qs.stringify(query)}`, '_blank' )
            // window.open(`http://172.16.22.202:3000/order/list?${qs.stringify(query)}`, '_blank' )
            // 走不校验登录的cart页
            window.open(`${CLIENT_BASE_URL}/cart?${qs.stringify(query)}`, '_blank' )
            // window.open(`http://172.16.22.202:3000/cart?${qs.stringify(query)}`, '_blank' )

        },
        /**
         * 离职(暂时不做)
         */
        handleClickQuit() {

        },
        /**
         * 响应 table change 事件
         * @param pagination
         */
        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.current = current
            this.limit = pageSize
            this.getList()
        },
        /**
         * 搜索
         */
        handleSearch(params) {
            console.log('params:' + JSON.stringify(params, null, 4))

            let tmpParams = JSON.parse(JSON.stringify(params))
            if (params.enterpriseAuthorizationBookState) {
                tmpParams.state = ENTERPRISE_AUTHORIZATION_PASS_TEXT
            }
            if (params.state) {
                switch (params.state) {
                    case ENTERPRISE_AUTHORIZATION_WAIT_SUBBMIT_RENEW_TEXT:
                        tmpParams.state = ENTERPRISE_AUTHORIZATION_WAIT_SUBBMIT_TEXT
                        tmpParams.type = '更新'
                        break
                    case ENTERPRISE_AUTHORIZATION_WAIT_AUTHORIZATION_RENEW_TEXT:
                        tmpParams.state = ENTERPRISE_AUTHORIZATION_WAIT_AUTHORIZATION_TEXT
                        tmpParams.type = '更新'
                        break
                    case ENTERPRISE_AUTHORIZATION_PASS_RENEW_TEXT:
                        tmpParams.state = ENTERPRISE_AUTHORIZATION_PASS_TEXT
                        tmpParams.type = '更新'
                        break
                    case ENTERPRISE_AUTHORIZATION_UNPASS_RENEW_TEXT:
                        tmpParams.state = ENTERPRISE_AUTHORIZATION_UNPASS_TEXT
                        tmpParams.type = '更新'
                        break
                    case ENTERPRISE_AUTHORIZATION_CANCEL_RENEW_TEXT:
                        tmpParams.state = ENTERPRISE_AUTHORIZATION_CANCEL_TEXT
                        tmpParams.type = '更新'
                        break
                    case ENTERPRISE_AUTHORIZATION_WAIT_SUBBMIT_TEXT:
                        tmpParams.type = '默认'
                        break
                    case ENTERPRISE_AUTHORIZATION_WAIT_AUTHORIZATION_TEXT:
                        tmpParams.type = '默认'
                        break
                    case ENTERPRISE_AUTHORIZATION_PASS_TEXT:
                        tmpParams.type = '默认'
                        break
                    case ENTERPRISE_AUTHORIZATION_UNPASS_TEXT:
                        tmpParams.type = '默认'
                        break
                    case ENTERPRISE_AUTHORIZATION_CANCEL_TEXT:
                        tmpParams.type = '默认'
                        break
                }
            }
            this.current = 1
            this.params = tmpParams
            this.getList()
        },
        /**
         * 刷新
         */
        handleRefresh(params) {
            this.handleSearch(params)
        }
    }
}
</script>

<style>

</style>
