export function copyText(text) {
    // 根据实际情况更改,需要复制内容的载体
    // 生成一个textarea对象
    let textArea = document.createElement('input')
    // 设置属性
    textArea.style.position = 'fixed'
    textArea.style.top = 0
    textArea.style.left = 0
    textArea.style.width = '2em'
    textArea.style.height = '2em'
    textArea.style.padding = 0
    textArea.style.border = 'none'
    textArea.style.outline = 'none'
    textArea.style.boxShadow = 'none'
    textArea.style.background = 'transparent'
    textArea.value = text
    // 添加到页面body
    document.body.appendChild(textArea)
    textArea.select()
    // 执行
    let bool = document.execCommand('copy')
    // 移除对象
    document.body.removeChild(textArea)
    return bool
}
