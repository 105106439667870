<!-- 客户概览Tab -->
<template>
    <a-spin :spinning="loading">
        <div class="cutomer-overview-wrap">
            <a-card title="基本信息">
                <custom-descriptions :value="detail.userInfo" :options="infoOptions" />
            </a-card>
            <a-card title="发票抬头">
                <a-table size="middle" row-key="invoiceTaxCoding" :pagination="false" :data-source="detail.userInvoiceList" :columns="invoiceOptions">
                    <template #type="{text}">
                        <span>{{ text === '0' ? '普票' : '专票' }}</span>
                    </template>
                </a-table>
            </a-card>
            <a-card title="收件地址">
                <a-table size="middle" row-key="receiverMobile" :pagination="false" :data-source="detail.userShippingAddressList" :columns="addressOptions" />
            </a-card>
        </div>
    </a-spin>
</template>
<script>
import { Card as ACard, Table as ATable } from 'ant-design-vue'
import CustomDescriptions from '@/weights/CustomDescriptions'
// 接口请求
import getCustomerSelectOneCustomerService from '@service/customer/getCustomerSelectOneCustomerService'

import { formatAddress } from '@utils/address'
export default {
    name: 'CutomerOverview',
    components: {
        ACard,
        ATable,
        CustomDescriptions
    },
    data() {
        return {
            loading: false,
            detail: {
                userInfo: {},
                userInvoiceList: [],
                userShippingAddressList: []
            },
            infoOptions: [
                {
                    title: '登录账号',
                    key: 'userName'
                },
                {
                    title: '用户ID',
                    key: 'userId'
                },
                {
                    title: '注册时间',
                    key: 'createDateTime'
                },
                {
                    title: '手机号码',
                    key: 'mobile'
                },
                {
                    title: '姓名',
                    key: 'name'
                },
                {
                    title: '昵称',
                    key: 'alias'
                },
                {
                    title: '性别',
                    key: 'sex'
                },
                {
                    title: '生日',
                    key: 'birthday'
                },
                {
                    title: '电子邮箱',
                    key: 'email'
                },
                {
                    title: '所在省市区',
                    key: 'area'
                },
                {
                    title: '详细地址',
                    key: 'address'
                }
            ],
            invoiceOptions: [
                {
                    title: '抬头类型',
                    dataIndex: 'type',
                    scopedSlots: { customRender: 'type' }
                }, {
                    title: '发票抬头',
                    dataIndex: 'invoiceLookedUp'
                }, {
                    title: '纳税人识别号',
                    dataIndex: 'invoiceTaxCoding'
                }
            ],
            addressOptions: [
                {
                    title: '收件人',
                    dataIndex: 'receiverName'
                }, {
                    title: '联系方式',
                    dataIndex: 'receiverMobile'
                }, {
                    title: '收件地址',
                    dataIndex: 'receiverAddress'
                }
            ]
        }
    },
    computed: {
        customerId() {
            return this.$route.query.id
        }
    },
    created() {
        this.init()
    },
    methods: {
        async init() {
            this.loading = true
            try {
                const { customerId } = this
                const detail = await getCustomerSelectOneCustomerService({ customerId }) || {}
                const { sex, provinceName, cityName, areaName } = detail?.userVO?.userExtends || {}
                // 转换拼接收件地址
                const userShippingAddressList = []
                for (let item of detail.userShippingAddressList) {
                    const receiverAddress = `${await formatAddress([item.provinceCode, item.cityCode, item.areaCode])} ${item.receiverAddress}` || '-'
                    userShippingAddressList.push({
                        ...item,
                        receiverAddress
                    })
                }

                this.detail = {
                    ...detail,
                    userShippingAddressList,
                    userInfo: {
                        ...detail.userVO,
                        ...detail.userVO?.userExtends,
                        sex: {
                            0: '男',
                            1: '女'
                        }[sex] || '',
                        area: [provinceName || '', cityName || '', areaName || ''].join(' ')
                    }
                }
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.loading = false
            }
        },
        handleBack() {
            this.$router.back()
        },
        // 用于外部刷新
        refresh() {
            this.init()
        }
    }
}
</script>

<style lang="scss">
.cutomer-overview-wrap {
    .ant-card {
        margin-bottom: 8px;
    }

    .ant-table-tbody {
        & > tr:nth-child(1) {
            position: relative;

            &::after {
                position: absolute;
                top: -2px;
                left: -2px;
                width: 44px;
                height: 44px;
                content: "";
                background: url("~@assets/images/tag.png");
                background-size: contain;
            }
        }
    }
}
</style>

