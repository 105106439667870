<!-- 公海客户管理-待分配 -->
<template>
    <div>
        <pro-table
            ref="tableRef"
            row-key="id"
            title="公海客户管理"
            :scroll="scrollConfig"
            :columns="columns"
            :request="getList"
            :row-selection="{ fixed: true,selectedRowKeys, onChange: onSelectChange }"
        >
            <template #extendBtn>
                <a-button v-auth="HIGHSEAS_CUSTOMER_ADD_BUTTON" type="primary" icon="plus-circle" @click="handleOpenModal(ADD_CUSTOMER)">添加客户</a-button>
            </template>
            <template #tabs>
                <customer-tab slot="header" @changetab="changeTab" />
            </template>
            <template #tableExtend>
                <a-button v-auth="HIGHSEAS_CUSTOMER_ALLOT_BUTTON"
                          :disabled="distributeDis"
                          type="primary"
                          @click="handleOpenModal(BATCH_DISTRIBUTE)">
                    <i class="iconfont icon-fenpei" />
                    <span class="btnTxt">批量分配经纪人</span>
                </a-button>
            </template>
            <!-- 操作 -->
            <template #action="{record}">
                <div class="btn-group">
                    <a-button
                        type="link"
                        @click="handleDetail(record)"
                    >
                        查看
                    </a-button>
                    <a-divider type="vertical" />
                    <a-button
                        v-auth="HIGHSEAS_CUSTOMER_FOLLOW_BUTTON"
                        type="link"
                        @click="handleOpenModal(FOLLOW_RECORD,record)"
                    >
                        跟进记录
                    </a-button>
                    <a-divider type="vertical" />
                    <a-button
                        v-auth="HIGHSEAS_CUSTOMER_ALLOT_BUTTON"
                        type="link"
                        @click="handleOpenModal(DISTRIBUTE,record)"
                    >
                        分配
                    </a-button>
                    <a-divider type="vertical" />
                    <a-button
                        v-auth="HIGHSEAS_CUSTOMER_REMARK_BUTTON"
                        type="link"
                        @click="handleOpenModal(EDIT_CUSTOMER,record)"
                    >
                        备注
                    </a-button>
                </div>
            </template>
        </pro-table>
        <cutomer-modal :loading="modalLoading"
                       :init-model-value="initModelValue"
                       :mode="mode"
                       @cancel="handleCancel"
                       @ok="handleOk"
                       @close="handleCancel" />
    </div>
</template>

<script>
// 组件
import ProTable from '@components/ProTable'
import CutomerModal from './Modal'
import CustomerTab from './CustomerTab'

// 配置
import columns from './columns'
import { MODE_EUMN } from './Modal/constant'
import { HIGHSEAS_CUSTOMER_ADD_BUTTON,
         HIGHSEAS_CUSTOMER_ALLOT_BUTTON,
         HIGHSEAS_CUSTOMER_REMARK_BUTTON,
         HIGHSEAS_CUSTOMER_FOLLOW_BUTTON } from '@/constant/authEnum/HighSeasCustomer'
import {
    Divider as ADivider
} from 'ant-design-vue'
import { scrollConfig } from '@constant/listScrollConfig'

// 接口请求
import getHighSeasCustomerListService from '@service/customer/getHighSeasCustomerListService'
import saveCustomerService from '@service/customer/saveCustomerService'
import saveCustomerAgentService from '@service/customer/saveCustomerAgentService'
import saveCustomerCommunicationService from '@service/customer/saveCustomerCommunicationService'
import allotCustomerAgentService from '@service/customer/allotCustomerAgentService'
import batchAllotCustomerAgentService from '@service/customer/batchAllotCustomerAgentService'
import getCustomerAgentSelectOneService from '@service/customer/getCustomerAgentSelectOneService'

// 方法
import { getObjectLowKey } from '@/utils'
import moment from 'moment'

export default {
    name: 'CustomerList',
    components: {
        ProTable,
        ADivider,
        CutomerModal,
        CustomerTab
    },
    data() {
        return {
            ...MODE_EUMN,
            HIGHSEAS_CUSTOMER_ADD_BUTTON,
            HIGHSEAS_CUSTOMER_ALLOT_BUTTON,
            HIGHSEAS_CUSTOMER_REMARK_BUTTON,
            HIGHSEAS_CUSTOMER_FOLLOW_BUTTON,
            mode: null,
            modalLoading: false,
            columns,
            selectedRowKeys: [],
            allSelectedRows: {},
            initModelValue: {},
            isNewCustomer: true,
            tmpParams: {
                pageSize: 10,
                current: 1,
                areaCodeList: []
            },
            tmpAction: 'search',
            scrollConfig
        }
    },
    computed: {
        // 批量分配按钮禁用态
        distributeDis() {
            return !this.selectedRowKeys.length
        },
        // 用户信息缓存
        userInfo() {
            return this.$store.getters.userInfo
        }
    },
    methods: {
        // 获取列表数据
        async getList(params, action) {
            console.log('params:' + JSON.stringify(params, null, 4))
            console.log('action:' + action)
            this.tmpParams = params
            this.tmpAction = action
            if (action === 'search') { // 搜索按钮触发
                this.selectedRowKeys = []
                this.allSelectedRows = {}
            }
            const { pageSize, current, modifyDateTime = [], registerDateTime = [], areaCodeList, ...otherParams } = params
            const [provinceCode, cityCode, areaCode] = areaCodeList
            const { total, records } = await getHighSeasCustomerListService({
                isNewCustomer: this.isNewCustomer,
                current,
                size: pageSize,
                modifyStartDateTime: modifyDateTime[0] ? modifyDateTime[0].format('YYYY-MM-DD HH:mm:ss') : undefined,
                modifyEndDateTime: modifyDateTime[1] ? modifyDateTime[1].format('YYYY-MM-DD HH:mm:ss') : undefined,
                startUserCreationTime: registerDateTime[0] ? registerDateTime[0].format('YYYY-MM-DD HH:mm:ss') : undefined,
                endUserCreationTime: registerDateTime[1] ? registerDateTime[1].format('YYYY-MM-DD HH:mm:ss') : undefined,
                provinceCode,
                cityCode,
                areaCode,
                ...otherParams
            })
            return {
                data: records,
                total
            }
        },
        // 勾选选择
        onSelectChange(selectedRowKeys, selectedRows) {
            this.selectedRowKeys = selectedRowKeys
            // 合并多页选中的源数据
            this.allSelectedRows = this.selectedRowKeys.reduce((total, key) => {
                const item = this.allSelectedRows[key]
                return Object.assign(total, { [key]: item || selectedRows.find(i => i.id === key) })
            }, {})
        },
        // 点击查看
        handleDetail(record) {
            this.$router.push({
                name: 'HighSeasCustomerDetail',
                query: {
                    id: record.id,
                    userId: record.userId
                }
            })
        },
        // 打开多种模式弹窗
        async handleOpenModal(mode, record = {}) {
            let data = {}
            switch (mode) {
                case MODE_EUMN.EDIT_CUSTOMER:
                    this.mode = MODE_EUMN.EDIT_CUSTOMER_NULL
                    this.modalLoading = true
                    try {
                        data = await getCustomerAgentSelectOneService({
                            customerId: record.id,
                            agentUserId: this.userInfo.id,
                            type: '无'
                        }) || {}

                        this.initModelValue = {
                            ...data,
                            area: [data.provinceCode, data.cityCode, data.areaCode],
                            ...getObjectLowKey(record, ['mobile']),
                            customerId: record.id,
                            agentUserId: this.userInfo.id

                        }
                    } catch (e) {
                        this.$message.error(e.message)
                    } finally {
                        this.modalLoading = false
                    }
                    break
                case MODE_EUMN.FOLLOW_RECORD:
                    this.initModelValue = {
                        ...getObjectLowKey(record, ['userId']),
                        customerId: record.id
                    }
                    break
                case MODE_EUMN.DISTRIBUTE:
                    this.initModelValue = {
                        descriptions: getObjectLowKey(record, ['userId', 'name', 'mobile']),
                        customerId: record.id
                    }
                    break
                case MODE_EUMN.BATCH_DISTRIBUTE:
                    this.initModelValue = { table: Object.values(this.allSelectedRows) }
                    break
                default:
                    this.initModelValue = {}
                    break
            }
            this.mode = mode
        },
        // 关闭按钮操作
        handleCancel() {
            this.mode = null
        },
        // 确定按钮
        async handleOk(form) {
            const { area = [], ...otherForm } = form
            let params = {}
            this.modalLoading = true
            try {
                switch (this.mode) {
                    // 新增客户
                    case MODE_EUMN.ADD_CUSTOMER:
                        params = {
                            ...otherForm,
                            provinceCode: area[0],
                            cityCode: area[1],
                            areaCode: area[2]
                        }
                        await saveCustomerService(params)
                        break
                    // 客户备注
                    case MODE_EUMN.EDIT_CUSTOMER:
                        params = {
                            ...otherForm,
                            provinceCode: area[0],
                            cityCode: area[1],
                            areaCode: area[2]
                        }
                        await saveCustomerAgentService(params)
                        break
                    // 跟进记录
                    case MODE_EUMN.FOLLOW_RECORD:
                        if (otherForm.nextDataTime && moment(otherForm.dataTime).isAfter(moment(otherForm.nextDataTime)) ) {
                            throw new Error('下次跟进时间不可早于跟进时间')
                        }
                        params = {
                            ...otherForm,
                            dataTime: otherForm.dataTime ? otherForm.dataTime.format('YYYY-MM-DD HH:mm:ss') : undefined,
                            nextDataTime: otherForm.nextDataTime ? otherForm.nextDataTime.format('YYYY-MM-DD HH:mm:ss') : undefined
                        }
                        await saveCustomerCommunicationService(params)
                        break
                    // 分配经纪人
                    case MODE_EUMN.DISTRIBUTE:
                        params = { ...otherForm }
                        await allotCustomerAgentService(params)
                        break
                    // 批量分配经纪人
                    case MODE_EUMN.BATCH_DISTRIBUTE:
                        params = otherForm.table.map(i => ({
                            customerId: i.id,
                            agentUserId: otherForm.agentUserId
                        }))
                        await batchAllotCustomerAgentService(params)
                        this.selectedRowKeys = []
                        this.selectedRows = []
                        break
                    default:
                        break
                }
                this.mode = null // 关闭弹窗
                this.$message.success('保存成功')
                this.$refs.tableRef.onReload() // 重载列表
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.modalLoading = false
            }
        },

        changeTab(param) {
            this.isNewCustomer = param.isNewCustomer === '1'
            // this.getList(this.tmpParams, this.tmpAction)

            this.$refs.tableRef.onReset() // 重载列表

        }
    }
}
</script>

<style lang="scss">
.btnTxt {
    margin-left: 8px;
}

.btn-group {
    display: flex;
    align-items: center;
    .ant-btn {
        padding: 0;
    }
}
</style>
