<!-- 客户详情-客户备注 -->
<template>
    <a-spin :spinning="loading">
        <base-info title="客户备注" :data="remarksInfo">
            <template #action>
                <custom-button type="default" @click="handleDetail" text="修改备注" >
                    <template #expansion>
                        <a-icon type="edit" />
                    </template>
                </custom-button>
            </template>

            <!-- 编辑客户弹框 -->
            <update-customer-info-modal
                v-if="showUpdateCustomerInfoModal"
                :visible="showUpdateCustomerInfoModal"
                title="编辑客户"
                :params="params"
                @close="handleCloseModal"
                @success="handleSuccessModal"
            />
        </base-info>
    </a-spin>
</template>

<script>
    // 组件
    import { Button as AButton, Icon as AIcon } from 'ant-design-vue'
    import CustomButton from '@components/Button'
    import BaseInfo from '../BaseInfo'
    import UpdateCustomerInfoModal from '../UpdateCustomerInfoModal'

    // 常量
    import { SALES_CATEGORY_NOT_TEXT } from '@constant/enum'

    // mixins
    import common from '../Tabs/mixins'

    // api
    import getCustomerAgentSelectOneService from '@service/customer/getCustomerAgentSelectOneService'

    export default {
        name: 'BaseRemarks',
        components: {
            AButton,
            AIcon,
            CustomButton,
            BaseInfo,
            UpdateCustomerInfoModal
        },
        mixins: [common],
        props: {
            params: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
        data() {
            return {
                SALES_CATEGORY_NOT_TEXT,
                // 客户id
                customerId: '',
                // 经纪人id
                agentUserId: '',
                // 客户备注
                remarksInfo: [],
                // 编辑客户弹框参数
                showUpdateCustomerInfoModal: false,
                loading: false
            }
        },
        computed: {},
        watch: {
            params: {
                handler(params) {
                    const { customerId, agentUserId } = params || {}
                    if (!( customerId && agentUserId)) return

                    this.customerId = customerId
                    this.agentUserId = agentUserId

                    // 初始化
                    this.init()
                },
                immediate: true
            }
        },
        mounted() {
        },
        methods: {
            /**
             * 初始化
             */
            async init() {
                // 获取 备注
                await this.getRemarks()
            },
            /**
             * 获取 备注
             */
            async getRemarks() {
                try {
                    this.loading = true
                    const {
                        customerId,
                        agentUserId,
                        SALES_CATEGORY_NOT_TEXT
                    } = this
                    const result = await getCustomerAgentSelectOneService({
                        customerId,
                        agentUserId,
                        type: SALES_CATEGORY_NOT_TEXT
                    })

                    this.initRemarksData(result)
                } catch (e) {
                    this.$message.error(e.message)
                    this.$captureException(e)
                } finally {
                    this.loading = false
                }
            },
            /**
             * 备注-初始化数据
             */
            initRemarksData(result) {
                const {
                    name,
                    gender,
                    intention,
                    stage,
                    enterpriseName,
                    email,
                    provinceName,
                    cityName,
                    areaName,
                    address,
                    description
                } = result || {}

                this.remarksInfo = [
                    {
                        key: '姓名',
                        value: name
                    },
                    {
                        key: '性别',
                        value: gender
                    },
                    {
                        key: '客户意向',
                        value: intention
                    },
                    {
                        key: '客户阶段',
                        value: stage
                    },
                    {
                        key: '企业名称',
                        value: enterpriseName
                    },
                    {
                        key: '电子邮箱',
                        value: email
                    },
                    {
                        key: '所在省市区',
                        value: `${provinceName || ''}${cityName || ''}${areaName || ''}`
                    },
                    {
                        key: '详细地址',
                        value: address
                    },
                    {
                        key: '备注',
                        value: description
                    }
                ]
            },
            /**
             * 编辑备注
             */
            handleDetail() {
                this.showUpdateCustomerInfoModal = true
            },
            /**
             * 关闭弹框
             */
            handleCloseModal() {
                this.showUpdateCustomerInfoModal = false
            },
            /**
             * 编辑成功处理
             */
            handleSuccessModal() {
                this.showUpdateCustomerInfoModal = false
                this.init()
                this.$emit('refresh-head')
            }
        }
    }
</script>
