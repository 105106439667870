<!-- 订单管理-列表-筛选 -->
<template>
    <search-panel :model="form" layout="vertical" @search="handleSearch" @refresh="handleRefresh">
        <form-col
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            :span="12"
            label="提交时间"
            prop="submitTime">
            <template :slot="formColDefaultSlot">
                <a-range-picker v-model="form.submitTime"
                                :mode="submitTimeMode"
                                :show-time="showTimeOptions"
                />
            </template>
        </form-col>
        <form-col label="投保单号" prop="castId">
            <template :slot="formColDefaultSlot">
                <a-input v-model.trim="form.castId" placeholder="请输入投保单号" />
            </template>
        </form-col>
        <form-col label="订单状态" prop="status">
            <template :slot="formColDefaultSlot">
                <a-select v-model="form.status" placeholder="请选择订单状态">
                    <a-select-option value>全部</a-select-option>
                    <template v-for="item in orderStatusList">
                        <a-select-option :key="item.value" :value="item.value">{{ item.label }}</a-select-option>
                    </template>
                </a-select>
            </template>
        </form-col>
        <form-col label="险种" prop="dangerPlantedId">
            <template :slot="formColDefaultSlot">
                <a-select v-model="form.dangerPlantedId" placeholder="请选择险种">
                    <a-select-option value>全部</a-select-option>
                    <template v-for="item in insureList">
                        <a-select-option :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
                    </template>
                </a-select>
            </template>
        </form-col>
        <form-col label="保险公司" prop="insuranceEnterpriseId">
            <template :slot="formColDefaultSlot">
                <a-select v-model="form.insuranceEnterpriseId" placeholder="请选择保险公司">
                    <a-select-option value>全部</a-select-option>
                    <template v-for="item in insureCompanyList">
                        <a-select-option :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
                    </template>
                </a-select>
            </template>
        </form-col>
        <form-col label="成交经纪人" prop="name">
            <template :slot="formColDefaultSlot">
                <a-select
                    v-model.trim="personalName"
                    show-search
                    placeholder="请输入关键字选择成员"
                    option-filter-prop="children"
                    :filter-option="false"
                    :allow-clear="true"
                    :show-arrow="false"
                    @change="handleNameChange($event,'personalLoading','personalSelectList','personalName','dealHousekeeperId')"
                    @search="getNameSelect($event,'personalLoading','personalSelectList','personalName','dealHousekeeperId')"
                >
                    <a-spin v-if="personalLoading" slot="notFoundContent" size="small" type="loading" />
                    <a-select-option v-for="item in personalSelectList" :key="item.id" :value="`${item.userName} （${item.userId}）`">
                        {{ item.userName || '-' }} （{{ item.userId }}）
                    </a-select-option>
                </a-select>
            </template>
        </form-col>
        <form-col label="项目名称" prop="projectName">
            <template :slot="formColDefaultSlot">
                <a-input v-model.trim="form.projectName" placeholder="请输入项目名称" />
            </template>
        </form-col>
        <template :slot="searchExpansionSlot">
            <form-col label="产品名称" prop="spuName">
                <template :slot="formColDefaultSlot">
                    <a-input v-model.trim="form.spuName" placeholder="请输入产品名称" />
                </template>
            </form-col>
            <form-col label="投保人" prop="castName">
                <template :slot="formColDefaultSlot">
                    <a-input v-model.trim="form.castName" placeholder="请输入投保人" />
                </template>
            </form-col>
            <form-col label="被保人" prop="castInsuranceDestName">
                <template :slot="formColDefaultSlot">
                    <a-input v-model.trim="form.castInsuranceDestName" placeholder="请输入被保人" />
                </template>
            </form-col>
        </template>
    </search-panel>
</template>

<script>
import debounce from 'lodash/debounce'
// 组件
import {
    Select as ASelect,
    DatePicker as ADatePicker,
    Input as AInput
} from 'ant-design-vue'
import SearchPanel from '@components/BaseSearchPanel'
import FormCol from '@components/FormCol'

// 全局数据
import {
    SIGNING_DATE_TYPE_ENUM_LIST,
    SIGNING_DATE_THIS_MONTH,
    SIGNING_DATE_THIS_CUSTOM,
    CUSTOMER_ORDER_ORDER_STATE_ENUM_LIST
} from '@constant/enum'

// 工具类
import { filterParams } from '@utils/search.js'
import moment from 'moment'

import getTime from '@mixins/getTime'
import searchCommon from '@mixins/searchCommon'

export default {
    name: 'OrderManageSearchPanel',
    components: {
        SearchPanel,
        FormCol,
        ASelect,
        AInput,
        ASelectOption: ASelect.Option,
        ARangePicker: ADatePicker.RangePicker
    },
    mixins: [getTime, searchCommon],
    data() {
        this.getNameSelect = debounce(this.getNameSelect, 500)
        return {
            SIGNING_DATE_THIS_CUSTOM,
            submitTimeMode: ['date', 'date'],
            showTimeOptions: {
                defaultValue: [
                    moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')
                ]
            },
            form: {
                // 投保单号
                castId: '',
                // 订单状态
                status: '',
                // 成交经纪人
                dealHousekeeperId: '',
                // 保险公司
                insuranceEnterpriseId: '',
                // 险种
                dangerPlantedId: '',
                // 项目名称
                projectName: '',
                // 产品名称
                spuName: '',
                // 投保人
                castName: '',
                // 被保人
                castInsuranceDestName: '',
                // 提交时间
                submitTime: []
            },
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
            // 成交经纪人姓名搜索数据
            personalLoading: false,
            personalSelectList: [],
            personalName: undefined,
            // 险种list
            insureList: [],
            // 保险公司list
            insureCompanyList: [],
            // 订单状态list
            orderStatusList: CUSTOMER_ORDER_ORDER_STATE_ENUM_LIST,
            // 筛选-插槽
            formColDefaultSlot: 'formElements',
            searchExpansionSlot: 'expansion'
        }
    },
    mounted() {
        this.init()
        this.getInsureList('insureList')
        this.getInsureCompanyList('insureCompanyList')
    },
    methods: {
        moment,
        init() {
            const params = this.formatParams()
            this.$emit('get-init', filterParams(params))
        },
        /**
         * 响应搜索事件
         */
        handleSearch() {
            const params = this.formatParams()
            this.$emit('search', filterParams(params))
        },
        /**
         * 响应刷新事件
         */
        handleRefresh() {
            this.resetData()
            const params = this.formatParams()
            this.$emit('refresh', filterParams(params))
        },
        /**
         *
         * 刷新置空数据
         */
        resetData() {
            this.personalSelectList = [],
            this.personalName = undefined
            this.form.dealHousekeeperId = ''
            this.form.submitTime = []
        },
        /**
         * 格式化参数
         */
        formatParams() {
            const { form } = this
            let { submitTime, ...params } = form
            let [startTime, endTime] = submitTime || []
            startTime = startTime ? `${startTime.format('YYYY-MM-DD HH:mm:ss')}` : ''
            endTime = endTime ? `${endTime.format('YYYY-MM-DD HH:mm:ss')}` : ''
            params.startTime = startTime
            params.endTime = endTime
            return params

        }
    }
}
</script>

<style lang="scss">
</style>
