<template>
    <a-form-model
        layout="horizontal"
        :labelCol="{ span: 0 }"
        :wrapperCol="{ span: 24 }"
        :model="form"
        ref="ruleForm"
        :rules="rules"
    >
        <a-form-model-item label prop="content">
            <a-text-area v-model="form.content" placeholder="请输入备注内容" :rows="4"></a-text-area>
        </a-form-model-item>
    </a-form-model>
</template>

<script>
// 组件
import { FormModel as AFormModel, Input as AInput } from 'ant-design-vue'

// 接口请求
import createRemarkService from '@service/clue/createRemarkService'
import createCustomerRemarkService from '@service/customer/createCustomerRemarkService'
import createBrokerRemarkService from '@service/broker/createBrokerRemarkService'

// 全局数据
import {
    REMARK_TYPE_CLUE,
    REMARK_TYPE_CUSTOMER,
    REMARK_TYPE_BROKER
} from '@constant/enum'

// 工具类
import { filterParams } from '@utils/search.js'

const INTERFACE_LIST = {
    [REMARK_TYPE_CLUE]: {
        url: createRemarkService,
        idName: 'potentialCustomerId'
    },
    [REMARK_TYPE_CUSTOMER]: {
        url: createCustomerRemarkService,
        idName: 'customerId'
    },
    [REMARK_TYPE_BROKER]: {
        url: createBrokerRemarkService,
        idName: 'agentPeopleId'
    }
}

export default {
    name: 'RemarkFormPanel',
    props: {
        id: {
            type: String,
            default() {
                return ''
            }
        },
        businessType: {
            type: String,
            default() {
                return ''
            }
        }
    },
    data() {
        return {
            REMARK_TYPE_CLUE, // 业务类型-线索
            REMARK_TYPE_CUSTOMER, // 业务类型-客户
            REMARK_TYPE_BROKER, // 业务类型-经纪人
            form: {
                content: '' // 备注
            },
            rules: {
                content: [
                    {
                        required: true,
                        message: '请输入备注内容',
                        trigger: 'blur'
                    }
                ]
            },
            loading: false
        }
    },
    computed: {
        dataChange() {
            const { mode, data } = this
            return {
                mode,
                data
            }
        }
    },
    watch: {},
    mounted() {},
    methods: {
        /**
         * 新增
         */
        async addRemark(data) {
            try {
                const { businessType } = this
                this.$emit('loading', true)
                await INTERFACE_LIST[businessType].url(data)
                this.$message.success('添加成功')
                this.$emit('success')
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.$emit('loading', false)
            }
        },
        /**
         * 点击确定按钮
         */
        handleSend() {
            const { form, id, businessType } = this
            const params = {
                [INTERFACE_LIST[businessType].idName]: id,
                ...form
            }
            const data = filterParams(params)
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.addRemark(data)
                }
            })
        },
        /**
         * 关闭弹框
         */
        handleCancel() {
            this.$emit('close')
        },
        /**
         * 重置按钮事件
         */
        handleReset() {
            this.form = {
                name: '',
                description: '',
                id: this.form.id
            }
        }
    },
    components: {
        AFormModel,
        AFormModelItem: AFormModel.Item,
        ATextArea: AInput.TextArea
    }
}
</script>

<style lang="scss">
</style>