<!-- 公海客户详情 -->
<template>
    <a-spin :spinning="loading">
        <list-page-layout class="customer-list-page customer-detail-wrap">
            <div class="header-panel-wrap">
                <h3>公海客户详情</h3>
                <a-button @click="handleBack">返回</a-button>
            </div>
            <div class="user-panel-wrap">
                <img v-if="detail.avatarUrl" :src="detail.avatarUrl">
                <img v-else src="~@assets/images/avatar.png">
                <div class="info">
                    <div class="detail">
                        <div class="person">
                            <p class="mobile">{{ detail.customerMobile || '' }}</p>
                            <p class="name">
                                <span v-if="detail.userName">{{ detail.userName }}</span>
                                <a-tag v-if="detail.state == 1" class="has">
                                    已注册
                                </a-tag>
                                <a-tag v-else class="null">
                                    未注册
                                </a-tag>
                            </p>
                        </div>
                        <div class="more">
                            <custom-descriptions :value="detail" :options="options" />
                        </div>
                    </div>
                    <div class="tags">
                        <template v-if="detail.label">
                            <a-tag class="common">
                                {{ detail.label }}
                            </a-tag>
                        </template>
                        <a-tag v-if="detail.intentionValue" class="common">
                            客户意向：{{ detail.intentionValue }}
                        </a-tag>
                    </div>
                </div>
            </div>
            <a-tabs class="tabs-panel-wrap" :default-active-key="tabs[0].component" @change="handleChangeTab">
                <a-tab-pane v-for="({ tab, component }) in tabs" :key="component" :tab="tab">
                    <component :is="component" :ref="`${component}Ref`" />
                </a-tab-pane>
            </a-tabs>
        </list-page-layout>
        <template v-for="({ detailComponent }) in tabs">
            <component :is="detailComponent"
                       :key="detailComponent"
                       :z-index="detailZIndex[detailComponent]"
                       :detail-id="detailIds[detailComponent]"
                       :visible="detailDrawerVisable[detailComponent]"
                       @close="handleDetailClose(detailComponent)" />
        </template>
    </a-spin>
</template>
<script>
import ListPageLayout from '@layout/ListPageLayout'
import { Tabs, Tag as ATag } from 'ant-design-vue'
import CustomDescriptions from '@/weights/CustomDescriptions'
// 接口请求

import getCustomerSelectOneCustomerInfoHeadService from '@service/customer/getCustomerSelectOneCustomerInfoHeadService'

const tabs = [{
    tab: '客户概况',
    component: 'CutomerOverview'
},
{
    tab: '客户备注',
    component: 'CutomerRemark'
},
{
    tab: '跟进记录',
    component: 'FollowRecord'
},
{
    tab: '线索记录',
    component: 'LeadRecord',
    detailComponent: 'LeadRecordDetail'
},
{
    tab: '订单记录',
    component: 'OrderRecord',
    detailComponent: 'OrderRecordDetail'
},
{
    tab: '保单记录',
    component: 'PolicyRecord',
    detailComponent: 'PolicyRecordDetail'
},
{
    tab: '发票记录',
    component: 'BillRecord',
    detailComponent: 'BillRecordDetail'
},
{
    tab: '保全记录',
    component: 'PreservationRecord',
    detailComponent: 'PreservationRecordDetail'
},
{
    tab: '分配记录',
    component: 'AllocationRecord'
}]

let detailZIndexTotal = 1000

export default {
    name: 'HighSeasCustomerDetail',
    components: {
        ListPageLayout,
        CustomDescriptions,
        ATag,
        ATabs: Tabs,
        ATabPane: Tabs.TabPane,
        ...tabs.reduce((t, i) => Object.assign(t, { [i.component]: require(`./${i.component}/index`).default }), {}),
        ...tabs.reduce((t, i) => i.detailComponent ? Object.assign(t, { [i.detailComponent]: require(`./${i.component}/detail`).default }) : t, {})
    },
    data() {
        return {
            loading: false,
            detailZIndex: {
                LeadRecordDetail: 1000,
                OrderRecordDetail: 1000,
                PolicyRecordDetail: 1000,
                BillRecordDetail: 1000,
                PreservationRecordDetail: 1000,
                AllocationRecordDetail: 1000
            },
            detail: {},
            detailDrawerVisable: {
                LeadRecordDetail: false,
                OrderRecordDetail: false,
                PolicyRecordDetail: false,
                BillRecordDetail: false,
                PreservationRecordDetail: false,
                AllocationRecordDetail: false
            },
            detailIds: {},
            tabs,
            options: [{
                title: '客户创建时间',
                key: 'customerCreateDateTime'
            },
            {
                title: '客户来源',
                key: 'customerSourceName'
            },
            {
                title: '分配经纪人',
                key: 'allotAgentUser'
            },
            {
                title: '注册时间',
                key: 'userCreateDateTime'
            },
            {
                title: '注册来源',
                key: 'userSource'
            },
            {
                title: '关联经纪人',
                key: 'associatedAgent'
            }]
        }
    },
    computed: {
        customerId() {
            return this.$route.query.id
        }
    },
    created() {
        this.init()
    },
    methods: {
        async init() {
            const { customerId } = this
            this.loading = true
            try {
                const detail = await getCustomerSelectOneCustomerInfoHeadService({ customerId })
                this.detail = {
                    ...detail,
                    customerCreateDateTime: this.$formatDateTime(detail.customerCreateDateTime),
                    userCreateDateTime: this.$formatDateTime(detail.userCreateDateTime)
                }
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.loading = false
            }
        },
        handleBack() {
            this.$router.back()
        },
        // 强制刷新当前tab内容
        handleChangeTab(activeKey) {
            if (this.$refs[`${activeKey}Ref`]) {
                const ref = this.$refs[`${activeKey}Ref`]
                ref?.[0].refresh?.()
            }
        },
        handleDetailClose(key) {
            this.detailDrawerVisable[key] = false
        },
        handleToggleDetail({ key, toggle, detailId }) {
            detailZIndexTotal++
            this.detailDrawerVisable[key] = toggle
            this.detailZIndex[key] = detailZIndexTotal
            this.detailIds[key] = detailId
        }
    },
    provide() {
        return { toggleDetail: this.handleToggleDetail }
    }
}
</script>

<style lang="scss">
@import "@assets/styles/varibles";

.customer-detail-wrap {
    .ant-descriptions-item-label {
        color: #646566;
    }

    .ant-descriptions-item-content {
        color: #323233;
    }

    .header-panel-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 24px;
        background: #f7f8fa;
        border-radius: 4px 4px 0 0;
        box-shadow: 0 2px 30px 0 rgb(220 222 223 / 50%);

        h3 {
            margin: 0;
        }
    }

    .user-panel-wrap {
        display: flex;
        padding: 24px;
        background: #fff;

        img {
            width: 80px;
            height: 80px;
            margin-right: 24px;
            border: 4px solid #fff;
            border-radius: 50%;
            box-shadow: 0 0 8px 0 #f2f2f2;
        }

        .info {
            .detail {
                display: flex;
                justify-content: space-between;

                .person {
                    flex: none;
                    margin-right: 48px;

                    .mobile {
                        font-family: PingFangSC-Medium, "PingFang SC";
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 20px;
                        color: #323233;
                    }

                    .name {
                        display: flex;

                        span {
                            margin-right: 16px;
                        }

                        .has {
                            color: #0b8e2b;
                            background-color: #edffed;
                            border: none;
                            border-radius: 2px;
                        }

                        .null {
                            color: #e6125f;
                            background-color: #fff1f0;
                            border: none;
                            border-radius: 2px;
                        }
                    }
                }
            }

            .tags {
                display: flex;

                .common {
                    padding: 6px 12px;
                    font-size: $font-size;
                    color: #ef7400;
                    background-color: #fff7e6;
                    border: none;
                    border-radius: $font-size;

                    .intention-value-icon {
                        margin-right: 4px;
                    }
                }
            }
        }
    }

    .tabs-panel-wrap {
        .ant-tabs-bar {
            margin-bottom: 9px;
            background-color: $component-background;
            border-top: 1px dashed #ebedf0;
            border-bottom: none;

            .ant-tabs-nav-container {
                margin-bottom: 0;

                .ant-tabs-nav-wrap {
                    margin-bottom: 0;

                    .ant-tabs-tab {
                        padding: 13px 0;
                        margin: 0 36px 0 24px;
                        font-size: $font-sizeFifteen;
                        color: $custom-button-default-text-color;
                    }

                    .ant-tabs-tab-active {
                        color: $base-color;
                        border-bottom: 3px solid $base-color;
                    }

                    .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
                        display: none !important;
                    }
                }
            }
        }
    }
}

</style>
