/**
 * 订单管理模块 模块
 */
import BasicLayout from '@layout/BasicLayout'

import {
    ORDER_MANAGE_NEW_MENU_CODE,
    ORDER_MANAGE_NEW_LIST_MENU_CODE
} from '@constant/menu_code'

const routes = [{
    path: '/orderManage',
    name: 'OrderManage',
    redirect: '/orderManage/list',
    component: BasicLayout,
    code: ORDER_MANAGE_NEW_MENU_CODE,
    sort: 5,
    meta: {
        title: '订单管理',
        icon: 'C-dingdanguanli',
        iconType: 'iconfont'
    },
    children: [{
        path: 'list',
        name: 'OrderManageList',
        component: () => import('./List/index'),
        hidden: true,
        code: ORDER_MANAGE_NEW_LIST_MENU_CODE,
        meta: {
            breadcrumb: false,
            keepAlive: true
        },
        children: []
    }]
}]

export default routes