import { BASE_URL } from '@config'

/** 工作台-团队详情
 * ————————————————————————————————————————————————————————————————————————————————————————————*/
/**
 * 工作台-团队详情-成员姓名查询
 *
 */
export const GET_TEAM_NAME_LIST = {
    url: `${BASE_URL}/user/team/selectList`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 工作台-团队详情-团队名称权限查询
 *
 */
export const GET_TEAM_NAME_AUTH_LIST = {
    url: `${BASE_URL}/user/team/teamAuthSelectList`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 工作台-团队详情-统计查询
 *
 */
 export const GET_TEAM_MANAGE_COUNT = {
    url: `${BASE_URL}/user/team-user/queryAuthBrokerCount`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 工作台-团队详情-列表查询
 *
 */
 export const GET_TEAM_MANAGE_LIST = {
    url: `${BASE_URL}/user/team-user/teamAuthBrokerSelect`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}