<template>
    <a-form-model
        layout="horizontal"
        :labelCol="{ span: 4 }"
        :wrapperCol="{ span: 20 }"
        :model="form"
        ref="ruleForm"
        :rules="rules">
        <!--线索分配状态 radio-->
        <a-form-model-item label="线索分配状态" prop="ruleStatus">
            <a-radio-group name="radioGroup" v-model="form.ruleStatus">
                <template v-for="item in CLUE_DISTRIBUTE_STATUS_ENUM_LIST">
                    <a-radio :key="item.value" :value="item.value">{{item.label}}</a-radio>
                </template>
            </a-radio-group>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 20, offset: 4 }">
            <a-button type="primary" :loading="loading" @click="handleSubmit">
                提交
            </a-button>
        </a-form-model-item>
    </a-form-model>
</template>

<script>
    // 组件
    import {
        FormModel as AFormModel,
        Radio as ARadio,
        Button as AButton
    } from 'ant-design-vue'

    // 全局变量
    import {
        CLUE_DISTRIBUTE_STATUS_ENUM_LIST
    } from '@constant/enum'

    // 接口请求
    import createClueDistributeService from '@service/clue/clueDistribute/createClueDistributeService'
    import updateClueDistributeService from '@service/clue/clueDistribute/updateClueDistributeService'

    export default {
        name: 'FormPanel',
        props: {
            mode: {
                type: String,
                default() {
                    return ''
                }
            },
            data: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
        data() {
            return {
                CLUE_DISTRIBUTE_STATUS_ENUM_LIST,
                // 表单-参数
                form: {
                    // 线索分配状态
                    ruleStatus: true
                },
                // 表单-验证规则
                rules: {
                    ruleStatus: [
                        {
                            required: true,
                            message: '请选择线索分配状态',
                            trigger: 'blur'
                        }
                    ]
                },
                loading: false
            }
        },
        computed: {
            dataChange() {
                const {mode, data} = this
                return {mode, data}
            }
        },
        watch: {
            dataChange: {
                handler(val) {
                    const {mode, data} = val
                    if (mode === 'edit') {
                        // 修改
                        this.formatForm(data)
                    } else {
                        // 新增
                        this.formatForm()
                    }
                },
                immediate: true
            }
        },
        mounted() {
        },
        methods: {
            /**
             * 点击确定按钮
             */
            handleSubmit() {
                const {mode} = this
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                        if (mode === 'edit') {
                            // 修改
                            this.updateClueDistribute(this.formatParams())
                        } else {
                            // 新增
                            this.createClueDistribute(this.formatParams())
                        }
                    }
                })
            },
            /**
             * 新增
             */
            async createClueDistribute(data) {
                try {
                    this.loading = true
                    await createClueDistributeService(data)
                    this.$message.success('添加成功')
                    this.$emit('success')
                } catch (e) {
                    this.$message.error(e.message)
                } finally {
                    this.loading = false
                }
            },
            /**
             * 修改
             */
            async updateClueDistribute(data) {
                try {
                    this.loading = true
                    await updateClueDistributeService(data)
                    this.$message.success('修改成功')
                    this.$emit('success')
                } catch (e) {
                    this.$message.error(e.message)
                } finally {
                    this.loading = false
                }
            },
            /**
             * 格式化form数据
             * @param data
             */
            formatForm(data = {}) {
                const { mode } = this
                const {
                    id,
                    ruleStatus = 0
                } = data

                this.form = {
                    ruleStatus: ruleStatus ? false : true
                }

                if (mode === 'edit') {
                    // 修改
                    this.form.id = id
                }
            },
            /**
             * 格式化params数据
             * @param data
             */
            formatParams(data = {}) {
                const { mode, form } = this
                const {
                    id,
                    ruleStatus
                } = form || {}
                let params = {
                    ruleStatus: ruleStatus ? 0 : 1
                }

                // 修改
                if (mode === 'edit') {
                    params.id = id
                }

                return params
            }
        },
        components: {
            AFormModel,
            AFormModelItem: AFormModel.Item,
            ARadioGroup: ARadio.Group,
            ARadio,
            AButton
        }
    }
</script>

<style lang="scss">
</style>