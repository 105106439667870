<!-- 客户详情-保单记录 -->
<template>
    <container-panel class="policy-record-tab-panel">
        <!-- 筛选 start -->
        <template #screen>
            <search-panel slot="header" @search="handleListSearch" @refresh="handleListRefresh" />
        </template>
        <!-- 筛选 end -->

        <!-- statistics start -->
        <template #statistics>
            <div>
                <span style="display: inline-block; margin-bottom: 27px">共计保费(元)：{{insurancePremiumTotal}}</span>
            </div>
        </template>
        <!-- statistics end -->

        <!-- 表格 start -->
        <template #content>
            <a-table
                size="middle"
                :columns="columns"
                :data-source="data"
                :pagination="pagination"
                :loading="loading"
                row-key="id"
                :scroll="{ x: '100%' }"
                @change="handleTableChange"
            >
                <!-- 保险期间 -->
                <template slot="startInsuranceTime" slot-scope="text, record">
                    <p>{{text}}</p>
                    <p>{{record.endInsuranceTime}}</p>
                </template>
                <!-- 操作 -->
                <template slot="action" slot-scope="action">
                    <custom-button text="查看" type="link" @click="handleDetail(action)" />
                </template>
            </a-table>
        </template>
        <!-- 筛选 end -->

        <!-- 详情-线上保单 start -->
        <policy-record-detail
            :visible="visible"
            :cast-id="castId"
            @close="handleDetailClose"
        />
        <!-- 详情 end -->

        <!-- 详情-线下保单 start -->
        <policy-record-detail-off
            :visible="visibleOff"
            :id="id"
            @close="handleDetailCloseOff"
        />
        <!-- 详情 end -->

        <!-- 详情-G端保单 start -->
        <policy-record-detail-g
            :visible="visibleG"
            :id="id"
            @close="handleDetailCloseG"
        />
        <!-- 详情 end -->
    </container-panel>
</template>

<script>
// 组件
import CustomButton from '@components/Button'
import PolicyRecordDetail from '@weights/Customer/Components/ComponentsDetail/Detail/PolicyRecordDetail'
import PolicyRecordDetailOff from '@weights/Customer/Components/ComponentsDetail/Detail/PolicyRecordDetailOff'
import PolicyRecordDetailG from '@weights/Customer/Components/ComponentsDetail/Detail/PolicyRecordDetailG'
import SearchPanel from './SearchPanel'

// 常量
import {
    CUSTOMER_POLICY_BUSINESS_TYPE_WORKSPACE,
    CUSTOMER_POLICY_BUSINESS_TYPE_TEAM,
    SALES_CATEGORY_DISTRIBUTION,
    SALES_CATEGORY_SELF_SUPPORT,
    POLICY_POLICY_TYPE_ONE,
    POLICY_POLICY_TYPE_TWO,
    POLICY_POLICY_TYPE_THREE
} from '@/constant/enum'

// mixins
import thousandBit from '@mixins/thousandBit'
import common from '../mixins'

// 接口请求
import getPolicyListService from '@service/customer/common/getPolicyListService'

export default {
    name: 'PolicyRecordTabPanel',
    components: {
        CustomButton,
        SearchPanel,
        PolicyRecordDetail,
        PolicyRecordDetailOff,
        PolicyRecordDetailG
    },
    mixins: [common, thousandBit],
    props: {
        params: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        // from 客户详情
        const columns = [
            {
                title: '保单号',
                dataIndex: 'policyNo',
                key: 'policyNo',
                width: 220
            },
            {
                title: '保单类型',
                dataIndex: 'type',
                key: 'type',
                width: 220
            },
            {
                title: '平台系统',
                dataIndex: 'externalSystemName',
                key: 'externalSystemName',
                width: 220
            },
            {
                title: '签单日期',
                dataIndex: 'singleDateTime',
                key: 'singleDateTime',
                width: 200
            },
            {
                title: '险种',
                dataIndex: 'dangerPlantedName',
                key: 'dangerPlantedName',
                width: 200,
                ellipsis: true,
                customRender: text => this.empty(text)
            },
            {
                title: '投保人',
                dataIndex: 'castEntepriseName',
                key: 'castEntepriseName',
                width: 200,
                customRender: text => this.empty(text)
            },
            {
                title: '被保人',
                dataIndex: 'insuredEnterpriseName',
                key: 'insuredEnterpriseName',
                width: 200,
                customRender: text => this.empty(text)
            },
            {
                title: '保险金额（元）',
                dataIndex: 'insuranceMoney',
                key: 'insuranceMoney',
                width: 200,
                customRender: text => this.empty(text)
            },
            {
                title: '保费（元）',
                dataIndex: 'payMoney',
                key: 'payMoney',
                width: 200
            },
            {
                title: '项目名称',
                dataIndex: 'projectName',
                key: 'projectName',
                width: 200
            },
            {
                title: '保险公司',
                dataIndex: 'insuranceEnterpriseName',
                key: 'insuranceEnterpriseName',
                width: 200
            },
            {
                title: '保险期间',
                dataIndex: 'startInsuranceTime',
                key: 'startInsuranceTime',
                width: 200,
                scopedSlots: { customRender: 'startInsuranceTime' }
            },
            {
                title: '经纪人',
                dataIndex: 'agentName',
                key: 'agentName',
                width: 200,
                customRender: (text, record) => {
                    const { agentId } = record
                    const result = `${text ? text : '-'}${agentId ? '(' + agentId + ')' : ''}`

                    return this.empty(result)
                }
            },
            {
                title: '保单状态',
                dataIndex: 'state',
                width: 200
            },
            {
                title: '操作',
                key: 'action',
                fixed: 'right',
                width: 60,
                align: 'right',
                scopedSlots: { customRender: 'action' }
            }
        ]

        return {
            CUSTOMER_POLICY_BUSINESS_TYPE_WORKSPACE,
            CUSTOMER_POLICY_BUSINESS_TYPE_TEAM,
            SALES_CATEGORY_DISTRIBUTION,
            SALES_CATEGORY_SELF_SUPPORT,
            POLICY_POLICY_TYPE_ONE,
            POLICY_POLICY_TYPE_TWO,
            POLICY_POLICY_TYPE_THREE,
            columns,
            // 用户id
            userId: '',
            // 经纪人id
            crmAgentUserId: '',
            // 保单号
            id: '',
            // 投保单号
            castId: '',
            // 查询条件
            searchParams: {},
            // 数据
            data: [],
            // 总记录数量
            total: 0,
            // 当前索引
            current: 1,
            // 加载状态
            loading: false,
            // 详情-线上保单-抽屉
            visible: false,
            // 详情-线下保单-抽屉
            visibleOff: false,
            // 详情-G端保单-抽屉
            visibleG: false,
            // 共计保费
            insurancePremiumTotal: 0
        }
    },
    computed: {
        userInfo() {
            return this.$store.getters.userInfo
        },
        customerType() {
            const { businessType, SALES_CATEGORY_DISTRIBUTION, SALES_CATEGORY_SELF_SUPPORT } = this
            let result = null
            if (businessType === SALES_CATEGORY_DISTRIBUTION) {
                result = 2
            } else if (businessType === SALES_CATEGORY_SELF_SUPPORT) {
                result = 1
            }

            return result
        }
    },
    watch: {
        params: {
            handler(params) {
                // 列表
                this.handleActionDefault(params)
            },
            immediate: true
        }
    },
    mounted() {
    },
    methods: {
        /**
             * 列表
             */
        handleActionDefault(params) {
            const { userId, crmAgentUserId } = params || {}
            if (!(userId && crmAgentUserId)) return

            this.userId = userId
            this.crmAgentUserId = crmAgentUserId

            // 获取列表
            this.getList()
        },
        /**
             * 获取 列表
             */
        async getList() {
            try {
                this.loading = true
                const {
                    userId,
                    crmAgentUserId,
                    searchParams,
                    current,
                    limit,
                    userInfo,
                    customerType
                } = this
                const { id: loginUser } = userInfo
                let data = {
                    current,
                    loginUser,
                    submitId: userId,
                    agentId: crmAgentUserId,
                    customerType,
                    size: limit,
                    ...searchParams
                }

                const { total, records, extra } = await getPolicyListService(data)
                this.total = total || 0
                this.data = records || []
                const { insurancePremiumTotal } = extra || {}
                this.insurancePremiumTotal = insurancePremiumTotal || 0
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        },
        /**
             * 查看
             */
        handleDetail(action) {
            const { id, castInsuranceId, type } = action
            this.id = id
            this.castId = castInsuranceId
            if (type === POLICY_POLICY_TYPE_ONE) {
                this.visible = true
            } else if (type === POLICY_POLICY_TYPE_TWO) {
                this.visibleOff = true
            } else if (type === POLICY_POLICY_TYPE_THREE) {
                this.visibleG = true
            }
        },
        /**
         * 列表-查看-线上保单-关闭
         */
        handleDetailCloseOff() {
            this.visibleOff = false
        },
        /**
         * 列表-查看-线下保单-关闭
         */
        handleDetailCloseG() {
            this.visibleG = false
        }
    }
}
</script>

<style lang="scss">
</style>
