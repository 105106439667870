<!-- 详情-操作日志 -->
<template>
    <descriptions-panel class="operation-log" :title="title">
        <template #content>
            <a-table
                size="middle"
                :columns="columns"
                :data-source="data"
                :loading="loading"
                :pagination="false"
                row-key="id"
            >
                <!-- 线索记录-订单状态 -->
                <template slot="state" slot-scope="text">
                    {{ getApiEnumLabelFromList(CUSTOMER_LEAD_ORDER_STATE_ENUM_LIST, text) }}
                </template>
                <!-- 订单记录-订单状态 -->
                <template slot="status" slot-scope="text">
                    {{ getApiEnumLabelFromList(CUSTOMER_ORDER_ORDER_STATE_ENUM_LIST, text) }}
                </template>
            </a-table>
        </template>
    </descriptions-panel>
</template>

<script>
// 组件
import { Table as ATable } from 'ant-design-vue'
import DescriptionsPanel from '../DescriptionsPanel'

// 常量
import { CUSTOMER_LEAD_ORDER_STATE_ENUM_LIST, CUSTOMER_ORDER_ORDER_STATE_ENUM_LIST } from '@/constant/enum'

// mixins
import enumOperate from '@mixins/enumOperate'
import empty from '@mixins/empty'

export default {
    name: 'OperationLog',
    components: {
        ATable,
        DescriptionsPanel
    },
    mixins: [enumOperate, empty],
    props: {
        title: {
            type: String,
            default() {
                return '操作日志'
            }
        },
        data: {
            type: Array,
            default() {
                return []
            }
        },
        loading: {
            type: Boolean,
            default() {
                return false
            }
        },
        columns: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            CUSTOMER_LEAD_ORDER_STATE_ENUM_LIST,
            CUSTOMER_ORDER_ORDER_STATE_ENUM_LIST
        }
    },
    computed: {},
    watch: {},
    mounted() {},
    methods: {}
}
</script>

<style lang="scss">
    @import '@assets/styles/varibles.scss';

    .operation-log {
        .ant-table-thead {
            th {
                font-weight: bold;
            }
        }
        .ant-table-column-title,
        td {
            color: $custom-button-default-text-color;
        }
    }
</style>
