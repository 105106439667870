/**
 * G端平台系统模块 模块
 */
import BasicLayout from '@layout/BasicLayout'

import {
    G_PLATFORM_MENU_CODE,
    G_PLATFORM_LIST_MENU_CODE
} from '@constant/menu_code'

const routes = [{
    path: '/gPlatform',
    name: 'GPlatform',
    redirect: '/gPlatform/list',
    component: BasicLayout,
    code: G_PLATFORM_MENU_CODE,
    sort: 99,
    meta: {
        title: 'G端平台系统',
        icon: 'Gduanpingtaixitong',
        iconType: 'iconfont'
    },
    children: [{
        path: 'list',
        name: 'GPlatformList',
        component: () => import('./List/index'),
        hidden: true,
        code: G_PLATFORM_LIST_MENU_CODE,
        meta: {
            breadcrumb: false,
            keepAlive: true
        },
        children: []
    }]
}]

export default routes
