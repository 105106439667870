<!-- 保全记录-详情 -->
<template>
    <drawer-panel
        title="保全详情"
        :visible="visible"
        :loading="loading"
        @after-visible-change="afterVisibleChange"
        @close="handleClose"
    >
        <!-- 状态 start -->
        <template #top>
            <status-panel>
                <template #logo>
                    <img :src="imgSrc" alt="">
                </template>
                <template #content>
                    <span>当前批单状态：{{ getApiEnumLabelFromList(PRESERVATION_STATE_MENU_LIST, record && record.state) }}</span>
                </template>
            </status-panel>
        </template>
        <!-- 状态 end -->

        <template #content>
            <!-- 保全信息 start -->
            <field-list title="保全信息" :data="invoiceInfo">
                <template #opts>
                    <a-button v-if="record && record.insuranceRefund && record.insuranceRefund.credentials" @click="handlePreview(record && record.insuranceRefund && record.insuranceRefund.credentials)">查看凭证</a-button>
                </template>
            </field-list>
            <!-- 保全信息 end -->

            <!-- 保单信息 start -->
            <field-list title="保单信息" :data="policyInfo"/>
            <!-- 保单信息 end -->
        </template>
    </drawer-panel>
</template>

<script>
    // 常量
    import {
        CORRECT_SERVICE_TYPE_ENUM_LIST,
        PRESERVATION_STATE_MENU_LIST,
        PRESERVATION_STATE_REFUSE,
        PRESERVATION_STATE_FINISHED
    } from '@/constant/enum'

    // mixins
    import common from '../mixins'

    // 接口请求
    import getCustomerInsuranceCorrectingDetailService from '@service/customer/getCustomerInsuranceCorrectingDetailService'

    export default {
        name: 'PreservationRecordDetail',
        mixins: [common],
        props: {
            id: {
                type: [Number, String],
                default() {
                    return ''
                }
            },
            visible: {
                type: Boolean,
                default() {
                    return false
                }
            }
        },
        data() {
            return {
                CORRECT_SERVICE_TYPE_ENUM_LIST,
                PRESERVATION_STATE_MENU_LIST,
                PRESERVATION_STATE_REFUSE,
                PRESERVATION_STATE_FINISHED,
                loading: false,
                // 单条
                record: {},
                imgSrc: require('@/assets/images/waiting.png'),
                // 保全信息
                invoiceInfo: [],
                // 保单信息
                policyInfo: []
            }
        },
        computed: {
        },
        watch: {
        },
        methods: {
            /**
             * 初始化
             */
            init() {
                // 获取单条
                this.getRecord()
            },
            /**
             * 获取单条
             */
            async getRecord() {
                const { id } = this
                if (!id) return

                try {
                    this.loading = true
                    const result = await getCustomerInsuranceCorrectingDetailService({ id })
                    this.record = result || {}

                    // 数据初始化
                    this.initData()
                } catch (e) {
                    this.$message.error(e.message)
                    this.$captureException(e)
                } finally {
                    this.loading = false
                }
            },
            /**
             * 数据初始化
             */
            initData() {
                const { record } = this
                const {
                    id,
                    createDateTime,
                    type,
                    insuranceId,
                    insuranceTime,
                    insuranceCompany,
                    dangerPlantedName,
                    insuranceMoney,
                    insuranceRefund,
                    payMoney,
                    projectName,
                    policyHolderEnterpriseName,
                    insuredEnterpriseName,
                    castInsuranceId,
                    startInsuranceTime,
                    endInsuranceTime
                } = record || {}
                const {
                    reason,
                    refundMoney,
                    refundPoundage,
                    description
                } = insuranceRefund || {}

                // 0. 状态icon
                this.handelImgSrc()

                // 1. 保全信息
                this.invoiceInfo = [
                    {
                        key: '服务单号',
                        value: id
                    },
                    {
                        key: '申请时间',
                        value: createDateTime
                    },
                    {
                        key: '批单类型',
                        value: this.getApiEnumLabelFromList(CORRECT_SERVICE_TYPE_ENUM_LIST, type)
                    },
                    {
                        key: '退保原因',
                        value: reason
                    },
                    {
                        key: '原保费（元）',
                        value: payMoney
                    },
                    {
                        key: '退保金额（元）',
                        value: refundMoney
                    },
                    {
                        key: '退保手续费',
                        value: refundPoundage
                    },
                    {
                        key: '备注',
                        value: description
                    }
                ]

                // 2. 保单信息
                this.policyInfo = [
                    {
                        key: '保单号',
                        value: insuranceId,
                        link: true,
                        linkBusiness: 'PolicyRecordDetail',
                        linkParams: {
                            castId: castInsuranceId
                        }
                    },
                    {
                        key: '签单时间',
                        value: insuranceTime
                    },
                    {
                        key: '保险公司',
                        value: insuranceCompany
                    },
                    {
                        key: '险种',
                        value: dangerPlantedName
                    },
                    {
                        key: '保险金额（保函金额）元',
                        value: insuranceMoney
                    },
                    {
                        key: '保费（元）',
                        value: payMoney
                    },
                    {
                        key: '项目名称',
                        value: projectName
                    },
                    {
                        key: '投保人',
                        value: policyHolderEnterpriseName
                    },
                    {
                        key: '被保人',
                        value: insuredEnterpriseName
                    },
                    {
                        key: '关联订单号',
                        value: castInsuranceId,
                        link: true,
                        linkBusiness: 'OrderRecordDetail',
                        linkParams: {
                            castId: castInsuranceId
                        }
                    },
                    {
                        key: '保险期间',
                        value: this.empty(this.handleRangeTime(startInsuranceTime, endInsuranceTime))
                    }
                ]
            },
            /**
             * 状态icon
             */
            handelImgSrc() {
                const { state } = this.record || {}

                const errorList = [
                    PRESERVATION_STATE_REFUSE
                ]
                const successList = [
                    PRESERVATION_STATE_FINISHED
                ]

                this.imgSrc = this.handleStateIcon(state, errorList, successList)
            }
        }
    }
</script>


