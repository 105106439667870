import { getVm } from '@/utils/vm'

import { CUSTOMER_ORDER_ORDER_STATE_ENUM_LIST } from '@/constant/enum'
import { getLabelformList } from '@/utils'
import moment from 'moment'

// 接口请求
import getTeamUserSelectListService from '@/service/customer/getTeamUserSelectListService'
import getInsuranceTypeListService from '@service/workSpace/getInsuranceTypeListService'
import getInsureCompanyListService from '@service/insureCompany/getInsureCompanyListService'

export default [
    {
        hideInTable: true,
        title: '提交时间',
        dataIndex: 'submitTime',
        valueType: 'dateRange',
        span: 12,
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
        fieldProps: {
            showTime: { defaultValue: [moment('2022-01-01 00:00:00'), moment('2022-01-01 23:59:59')] },
            format: 'YYYY-MM-DD HH:mm:ss',
            disabledDate: () => false
        }
    },
    {
        hideInTable: true,
        title: '投保单号',
        dataIndex: 'castId'
    },
    {
        hideInTable: true,
        title: '订单状态',
        dataIndex: 'status',
        valueType: 'select',
        initialValue: '',
        fieldProps: {
            options: [{
                label: '全部',
                value: ''
            }, ...CUSTOMER_ORDER_ORDER_STATE_ENUM_LIST]
        }
    },
    {
        hideInTable: true,
        title: '险种',
        dataIndex: 'dangerPlantedId',
        valueType: 'select',
        initialValue: '',
        fieldProps: {
            asyncData: async () => {
                try {
                    const data = await getInsuranceTypeListService()
                    return [
                        {
                            label: '全部',
                            value: ''
                        }, ...data.map(({ id, name }) => ({
                            label: name,
                            value: id
                        }))
                    ]
                } catch (e) {
                    getVm().$message.error(e.message)
                }
            }
        }
    },
    {
        hideInTable: true,
        title: '保险公司',
        dataIndex: 'insuranceEnterpriseId',
        valueType: 'select',
        initialValue: '',
        fieldProps: {
            asyncData: async () => {
                try {
                    const data = await getInsureCompanyListService()
                    return [
                        {
                            label: '全部',
                            value: ''
                        }, ...data.map(({ id, name }) => ({
                            label: name,
                            value: id
                        }))
                    ]
                } catch (e) {
                    getVm().$message.error(e.message)
                }
            }
        }
    },
    {
        hideInTable: true,
        title: '成交经纪人',
        dataIndex: 'dealHousekeeperId',
        valueType: 'searchInput',
        fieldProps: {
            isAll: false,
            placeholder: '请输入关键字选择成员',
            onSearch: async keyword => {
                try {
                    const data = await getTeamUserSelectListService({
                        backMapQueryResult: false,
                        userNameQuery: keyword
                    })
                    return data.map(({ userId, userName }) => ({
                        label: userName,
                        value: userId
                    })) || []
                } catch (e) {
                    getVm().$message.error(e.message)
                }
            },
            getContent: item => `${item.label} (${item.value})`
        }
    },
    {
        hideInTable: true,
        title: '操作经纪人',
        dataIndex: 'agentUserId',
        valueType: 'searchInput',
        fieldProps: {
            isAll: false,
            placeholder: '请输入关键字选择成员',
            onSearch: async keyword => {
                try {
                    const data = await getTeamUserSelectListService({
                        backMapQueryResult: false,
                        userNameQuery: keyword
                    })
                    return data.map(({ userId, userName }) => ({
                        label: userName,
                        value: userId
                    })) || []
                } catch (e) {
                    getVm().$message.error(e.message)
                }
            },
            getContent: item => `${item.label} (${item.value})`
        }
    },
    {
        hideInTable: true,
        title: '项目名称',
        isExpansion: true,
        dataIndex: 'projectName'
    },
    {
        hideInTable: true,
        title: '产品名称',
        isExpansion: true,
        dataIndex: 'spuName'
    },
    {
        hideInTable: true,
        title: '投保人',
        isExpansion: true,
        dataIndex: 'castName'
    },
    {
        hideInTable: true,
        title: '被保人',
        isExpansion: true,
        dataIndex: 'castInsuranceDestName'
    },


    {
        hideInSearch: true,
        title: '投保单号',
        dataIndex: 'castInsuranceId',
        width: 200
    },
    {
        hideInSearch: true,
        title: '提交时间',
        dataIndex: 'createDateTime',
        width: 180
    },
    {
        hideInSearch: true,
        title: '险种',
        dataIndex: 'dangerPlantedName',
        width: 180
    },
    {
        hideInSearch: true,
        title: '投保人',
        dataIndex: 'castInsuranceEnterpriseName',
        width: 180
    },
    {
        hideInSearch: true,
        title: '保险金额（元）',
        dataIndex: 'insuranceMoney',
        width: 180
    },
    {
        hideInSearch: true,
        title: '保费（元）',
        dataIndex: 'payMoney',
        width: 180
    },
    {
        hideInSearch: true,
        title: '保险公司',
        dataIndex: 'insuranceCompany',
        width: 180
    },
    {
        hideInSearch: true,
        title: '操作经纪人',
        dataIndex: 'agentName',
        customRender: (text, record) => text ? `${text}(${record.agentUserId})` : '-',
        width: 180
    },
    {
        hideInSearch: true,
        title: '成交经纪人',
        dataIndex: 'dealHousekeeperName',
        customRender: (text, record) => {
            const {
                dealHousekeeperName,
                dealHousekeeperId
            } = record || {}
            let result = ''
            result = `${dealHousekeeperName || '-'} (${dealHousekeeperId || '-'})`
            if (!dealHousekeeperName && !dealHousekeeperId) {
                result = '-'
            }
            return result
        },
        width: 180
    },
    {
        hideInSearch: true,
        title: '订单状态',
        dataIndex: 'insuranceStatus',
        customRender: getLabelformList(CUSTOMER_ORDER_ORDER_STATE_ENUM_LIST),
        width: 180
    },
    {
        hideInSearch: true,
        title: '操作',
        key: 'action',
        scopedSlots: { customRender: 'action' },

        width: 100,
        fixed: 'right'
    }
]
