<!-- 地区选择器 -->
<template>
    <a-cascader v-model="realValue"
                :options="options"
                placeholder="请选择所在地区"
                change-on-select
                @change="handleChangeArea"
    />
</template>

<script>
import { Cascader as ACascader } from 'ant-design-vue'
import getProvinceRedisService from '@/service/getProvinceRedisService'

export default {
    name: 'AddressCascader',
    components: { ACascader },
    props: {
        value: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            // 地区数组
            options: []
        }
    },
    computed: {
        realValue: {
            get() {
                return this.value || []
            },
            set(val) {
                this.$emit('input', val)
            }
        }
    },
    created() {
        this.getProvinceRedis()
    },
    methods: {
        async getProvinceRedis() {
            try {
                this.options = await getProvinceRedisService()
            } catch (e) {
                this.$message.error(e.message)
            }
        },
        /**
         * 地区选择
         */
        handleChangeArea(value, selectedOptions) {
            this.$emit('change', value, selectedOptions)
        }
    }
}
</script>

<style lang='scss'>
</style>
