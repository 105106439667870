// 业务级表单组件
import SearchPanel from '@components/SearchPanel'
import FormCol from '@components/FormCol'
import FormItem from '@components/ProForm/FormItem'
import {
    FormModel as AFormModel
} from 'ant-design-vue'
import './index.scss'

export default {
    name: 'ProForm',
    components: {
        SearchPanel,
        FormCol,
        FormItem,
        AFormModel
    },
    props: {
        columns: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        /**
         * 响应搜索事件
         */
        handleSearch() {
            this.$emit('search')
        }
    },

    render(c) {

        return c('div', { class: 'pro-form-wrap' }, [
            c(
                'AFormModel',
                {
                    ref: 'formRef',
                    attrs: {
                        model: this.$attrs.value,
                        ...this.$attrs
                    },
                    on: this.$listeners

                },
                [
                    c(
                        'FormItem',
                        {
                            attrs: {
                                value: this.$attrs.value,
                                columns: this.columns,
                                span: 24
                            }
                        },
                        []
                    )
                ]
            )
        ])
    },
    mounted() {
        Object.assign(Object.getPrototypeOf(this), this.$refs.formRef)
    }
}
