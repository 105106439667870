<template>
    <div class="page-layout">
        <!-- header -->
        <div class="page-layout__header-wrap">
            <page-header class="page-layout__header"
                         :title="headerTitle"
                         :breadcrumb="breadcrumb"
            >
                <slot v-if="$slots.title" slot="title" name="title" />
                <slot v-if="$slots.headerContent" name="headerContent" />
                <slot v-if="$slots.headerAction" slot="action" name="headerAction" />
            </page-header>
        </div>
        <!-- content -->
        <div class="page-layout__content">
            <div class="page-layout__inner">
                <template v-if="showLoading">
                    <a-spin :spinning="loading">
                        <slot />
                    </a-spin>
                </template>
                <template v-else>
                    <slot />
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { Spin as ASpin } from 'ant-design-vue'
import PageHeader from '@components/PageHeader'

export default {
    name: 'PageLayout',
    components: {
        PageHeader,
        ASpin
    },
    props: {
        header: {
            type: Boolean,
            default: true
        },
        showTitle: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        full: {
            type: Boolean,
            default: false
        },
        showLoading: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        route() {
            return this.$route
        },
        headerTitle() {
            const { title, route, showTitle } = this
            const { meta } = route
            const { title: metaTitle } = meta || {}
            return showTitle ? title || metaTitle || '' : ''
        },
        breadcrumb() {
            return this.$route.matched
        }
    }
}
</script>

<style lang="scss">
@import "@assets/styles/varibles";

.page-layout {
    position: relative;

    &__header-wrap {
        position: fixed;
        top: 64px;
        z-index: 2;
        width: 100%;
    }

    &__content {
        box-sizing: border-box;
        padding: $padding 24px;
        margin-top: 41px;
    }

    &__inner {
        //background: $component-background;
    }
}
</style>
