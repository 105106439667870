import axios from '@service'
import { DOWN_CUST_INSURANCE } from './api'
import { HTTP_ERR_SUCCESS, HTTP_RESULT_FIELD, HTTP_ERR_MESSAGE_FIELD } from '@/constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@/constant/error'

/**
 * 下载投保单
 * @param payload
 * @param config
 * @returns {Promise<*>}
 */
export default async function downCustInsuranceService(payload = {}, config = {}) {
    const param = {
        ...DOWN_CUST_INSURANCE,
        ...config,
        params: payload
    }
    const { data } = await axios(param)
    if (data.code === HTTP_ERR_SUCCESS) {
        return data[HTTP_RESULT_FIELD]
    } else {
        throw new Error(data[HTTP_ERR_MESSAGE_FIELD] || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
