import { validateEmailFunc } from '@utils/validate'
import moment from 'moment'

export default [
    {
        hideInTable: true,
        title: '备注人',
        dataIndex: 'agentUserNameQuery'
    },
    {
        hideInSearch: true,
        title: '客户编号',
        dataIndex: 'customerId'
    },
    {
        hideInSearch: true,
        title: '姓名',
        dataIndex: 'name'
    },
    {
        hideInSearch: true,
        title: '企业名称',
        dataIndex: 'enterpriseName'
    },
    {
        hideInSearch: true,
        title: '客户意向',
        dataIndex: 'intention'
    },
    {
        title: '更新时间',
        dataIndex: 'modifyDateTime',

        valueType: 'dateRange',
        span: 16,
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
        fieldProps: {
            showTime: { defaultValue: [moment('2022-01-01 00:00:00'), moment('2022-01-01 23:59:59')] },
            format: 'YYYY-MM-DD HH:mm:ss',
            disabledDate: () => false
        }
    },
    {
        hideInSearch: true,
        title: '性别',
        dataIndex: 'gender'
    },
    {
        hideInSearch: true,
        title: '电子邮箱',
        dataIndex: 'email',
        rules: [{
            validator: validateEmailFunc,
            trigger: 'blur'
        }]
    },
    {
        hideInSearch: true,
        title: '所在地区',
        dataIndex: 'area'
    },
    {
        hideInSearch: true,
        title: '详细地址',
        dataIndex: 'address'
    },
    {
        hideInSearch: true,
        title: '备注人',
        dataIndex: 'agentUserName'
    },
    {
        hideInSearch: true,
        title: '备注',
        dataIndex: 'description'
    }
].map(item => ({
    ...item,
    width: 180
}))
