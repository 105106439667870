import { BASE_URL } from '@config'

/** G端平台系统接口
 * ————————————————————————————————————————————————————————————————————————————————————————————*/


/**
 * G端平台系统-列表
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const GET_G_PLATFORM_LIST = {
    url: `${BASE_URL}/user/transformation-external-platform-system/select`,
    method: 'get',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}


