<template>
    <a-spin :spinning="loading">
        <div class="statistics-wrap">
            <div v-for="({ title, imgSrc, money, unit ,tipText, average, averageNum }) in dataSource" :key="title" class="statistics-card">
                <div class="statistics-card-top">
                    <img class="icon" :src="imgSrc">
                    <div class="info">
                        <div class="title">
                            <span>{{ title }}</span>
                            <a-tooltip v-if="tipText" class="tool-tip">
                                <template slot="title">
                                    {{ tipText }}
                                </template>
                                <img :src="tipIcon" alt="" class="tip-icon">
                            </a-tooltip>
                        </div>
                        <div>
                            <span class="money">{{ money }}</span>
                            <span class="unit">{{ unit }}</span>
                        </div>
                    </div>
                </div>
                <div v-if="average" class="statistics-card-bottom">{{ average }}{{ averageNum }}</div>
            </div>
        </div>
    </a-spin>
</template>

<script>
// 组件
import { Spin as ASpin, Tooltip as ATooltip } from 'ant-design-vue'

export default {
    name: 'StatisticsBar',
    components: {
        ASpin,
        ATooltip
    },
    props: {
        dataSource: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return { tipIcon: require('@/assets/images/hover-tip.png') }
    }

}
</script>

<style lang="scss">
.statistics-wrap {
    display: flex;
    align-items: center;
    padding: 27px 0;
    border-bottom: 1px dashed #ebedf0;

    .statistics-card {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
        justify-content: center;

        &::after {
            position: absolute;
            top: 50%;
            right: 0;
            width: 1px;
            height: 40px;
            margin-top: -20px;
            content: "";
            background: #ebedf0;
        }
        &:last-child::after {
            width: 0;
        }
        &-top {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &-bottom {
            margin-top: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #646566;
            line-height: 20px;
        }

        .icon {
            display: block;
            width: 56px;
            height: 56px;
            margin-right: 16px;
        }

        .info {
            position: relative;
            top: 4px;
            .title {
                display: flex;
                align-items: center;
                font-size: 14px;
                margin-bottom: 13px;
                color: #323233;
                .tool-tip {
                    margin-left: 12px;
                }
                .tip-icon {
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                }
            }

            .money {
                margin-right: 11px;
                /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
                font-family: DINAlternate-Bold, DINAlternate;
                font-size: 26px;
                font-weight: bold;
                color: #0D80FA;
                line-height: 20px;
            }

            .unit {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #646566;
            }
        }
    }
}
</style>
