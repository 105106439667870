<!-- 工作台/团队详情 -->
<template>
    <list-page-layout class="team-manage-page">
        <!-- title -->
        <common-title title="成员列表">
            <template #extra>
                <custom-button type="default" text="返回" @click="handleBack" />
            </template>
        </common-title>

        <container-panel>
            <!-- search start -->
            <template #screen>
                <search-panel
                    ref="searchPanel"
                    slot="header"
                    @search="handleSearch"
                    @refresh="handleRefresh"
                    @get-init="handleGetInit"
                />
            </template>
            <!-- search end -->

            <!-- statistics start -->
            <template #statistics>
                <div>
                    <span class="tip-icon">*</span>
                    <span>统计数据不含退保保单</span>
                </div>
                <statistics-bar :data-source="statisticsList" :loading="statisticsLoading" />
            </template>
            <!-- statistics end -->

            <!-- 表格 start -->
            <template #content>
                <a-table
                    size="middle"
                    :columns="columns"
                    :data-source="data"
                    :pagination="pagination"
                    :loading="loading"
                    row-key="id"
                    @change="handleTableChange"
                >
                    <!-- 操作 -->
                    <template slot="action" slot-scope="action">
                        <a-button
                            v-auth="WORKSPACE_POLICY_MANAGE_MENU_CODE"
                            type="link"
                            @click="handleInsuranceDetail(action)"
                        >
                            查看保单详情
                        </a-button>
                    </template>
                </a-table>
            </template>
            <!-- 表格 end -->
        </container-panel>
    </list-page-layout>
</template>

<script>
// 组件
import { Button as AButton, Table as ATable } from 'ant-design-vue'
import ListPageLayout from '@layout/ListPageLayout'
import ContainerPanel from '@components/ContainerPanel'
import CommonTitle from '@components/CommonTitle'
import SearchPanel from './SearchPanel'
import StatisticsBar from '@/weights/StatisticsBarDetails'
import CustomButton from '@components/Button'

// 全局数据
import { DEFAULT_PAGES_SIZE } from '@config'
import {
    WORKSPACE_POLICY_MANAGE_MENU_CODE
} from '@constant/menu_code'
import { CUSTOMER_POLICY_BUSINESS_TYPE_TEAM } from '@constant/enum'

// mixins
import enumOperate from '@mixins/enumOperate'
import tableShowTotal from '@mixins/tableShowTotal'
import thousandBit from '@mixins/thousandBit'

// 接口请求
import getTeamManageCountService from '@/service/workSpace/teamMange/getTeamManageCountService'
import getTeamManageListService from '@/service/workSpace/teamMange/getTeamManageListService'

const columns = [
    // {
    //     title: 'ID',
    //     dataIndex: 'id',
    //     key: 'id',
    //     align: 'left'
    // },
    {
        title: '成员姓名',
        dataIndex: 'userName',
        align: 'left',
        customRender: (text, recod) => {
            const { userName, userId } = recod
            if (!userName && !userId) {
                return '-'
            } else {
                return `${userName || '-'}（${userId || '-'}）`
            }
        }
    },
    {
        title: '所属团队',
        dataIndex: 'teamName',
        align: 'left',
        customRender: text => text || '-'
    },
    // 产品告知隐藏
    // {
    //     title: '销售类别',
    //     dataIndex: 'type',
    //     align: 'left',
    //     customRender: text => text || '-'
    // },
    {
        title: '组别',
        dataIndex: 'teamGroupName',
        align: 'left',
        customRender: text => text || '-'
    },
    {
        title: '角色',
        dataIndex: 'teamGroupValueName',
        align: 'left',
        customRender: text => text || '-'
    },
    {
        title: '成交保费',
        dataIndex: 'finalPremiums',
        align: 'left',
        customRender: text => text ? (text * 1).toFixed(2) : '0.00'
    },
    {
        title: '操作',
        key: 'action',
        scopedSlots: { customRender: 'action' },
        align: 'left'
    }
]


const columnsWithCommision = [
    // {
    //     title: 'ID',
    //     dataIndex: 'id',
    //     key: 'id',
    //     align: 'left'
    // },
    {
        title: '成员姓名',
        dataIndex: 'userName',
        align: 'left',
        customRender: (text, recod) => {
            const { userName, userId } = recod
            if (!userName && !userId) {
                return '-'
            } else {
                return `${userName || '-'}（${userId || '-'}）`
            }
        }
    },
    {
        title: '所属团队',
        dataIndex: 'teamName',
        align: 'left',
        customRender: text => text || '-'
    },
    // 产品告知隐藏
    // {
    //     title: '销售类别',
    //     dataIndex: 'type',
    //     align: 'left',
    //     customRender: text => text || '-'
    // },
    {
        title: '组别',
        dataIndex: 'teamGroupName',
        align: 'left',
        customRender: text => text || '-'
    },
    {
        title: '角色',
        dataIndex: 'teamGroupValueName',
        align: 'left',
        customRender: text => text || '-'
    },
    {
        title: '成交保费',
        dataIndex: 'finalPremiums',
        align: 'left',
        customRender: text => text ? (text * 1).toFixed(2) : '0.00'
    },
    {
        title: '应收佣金',
        dataIndex: 'insuranceSettlementPremium',
        align: 'left',
        customRender: text => text ? (text * 1).toFixed(2) : '0.00'
    },
    {
        title: '操作',
        key: 'action',
        scopedSlots: { customRender: 'action' },
        align: 'left'
    }
]

const statisticsList = [{
    title: '团队人数',
    imgSrc: require('@assets/images/workSpace/total_personal@2x.png'),
    money: '0',
    unit: '人',
    key: 'teamUserTotal'
},
{
    title: '合计保费',
    imgSrc: require('@assets/images/workSpace/total_premium@2x.png'),
    money: '0.00',
    unit: '元',
    key: 'insurancePremiumTotal'
},
{
    title: '保额统计',
    imgSrc: require('@assets/images/workSpace/month-premium@2x.png'),
    money: '0.00',
    unit: '元',
    key: 'insuranceAmountTotal'
},
{
    title: '合计保单笔数',
    imgSrc: require('@assets/images/workSpace/total_policy@2x.png'),
    money: '0',
    unit: '单',
    key: 'insuranceCount'
}]

const statisticsListWithCommision = [{
    title: '团队人数',
    imgSrc: require('@assets/images/workSpace/total_personal@2x.png'),
    money: '0',
    unit: '人',
    key: 'teamUserTotal'
},
{
    title: '合计保费',
    imgSrc: require('@assets/images/workSpace/total_premium@2x.png'),
    money: '0.00',
    unit: '元',
    key: 'insurancePremiumTotal'
},
{
    title: '合计应收',
    imgSrc: require('@assets/images/workSpace/month-premium@2x.png'),
    money: '0.00',
    unit: '元',
    key: 'insuranceSettlementTotal'
},
{
    title: '合计保单笔数',
    imgSrc: require('@assets/images/workSpace/total_policy@2x.png'),
    money: '0',
    unit: '单',
    key: 'insuranceCount'
}]

export default {
    name: 'TeamManage',
    components: {
        ListPageLayout,
        CommonTitle,
        SearchPanel,
        ContainerPanel,
        AButton,
        ATable,
        StatisticsBar,
        CustomButton
    },
    mixins: [enumOperate, tableShowTotal, thousandBit],
    data() {
        return {
            WORKSPACE_POLICY_MANAGE_MENU_CODE,
            CUSTOMER_POLICY_BUSINESS_TYPE_TEAM,
            // 查询条件
            params: {},
            // 数据
            data: [],
            // 分页数量
            limit: DEFAULT_PAGES_SIZE,
            // 总记录数量
            total: 0,
            // 当前索引
            current: 1,
            // 加载状态
            loading: false,
            // table 列配置
            columns,
            // 当前选中的行数据
            currentRow: {},
            statisticsList,
            // 权限团队成员id
            authUserId: '',
            statisticsLoading: true,
            // 个人佣金是否可见状态
            commissionVisibility: false
        }
    },
    computed: {
        pagination() {
            const { total, limit, current, tableShowTotal } = this
            return {
                showTotal: (total, range) => tableShowTotal(total, range),
                current,
                total,
                pageSize: limit,
                showSizeChanger: true,
                showQuickJumper: true
            }
        }
    },
    watch: {
        // 获取从工作台带过来的authUserId和commissionVisibility
        $route: {
            handler(route) {
                const { authUserId, commissionVisibility } = route.query || {}
                this.authUserId = authUserId
                this.commissionVisibility = commissionVisibility
                if (this.commissionVisibility) {
                    this.columns = columnsWithCommision
                    this.statisticsList = statisticsListWithCommision
                } else {
                    this.columns = columns
                    this.statisticsList = statisticsList

                }
            },
            immediate: true
        }
    },
    mounted() {},
    methods: {
        handleGetInit(params, signDate) {
            this.params = params
            this.signDate = signDate
            this.getCount()
            this.getList()
        },
        /**
         * 获取统计数据
         */
        async getCount() {
            try {
                const { params, authUserId } = this
                if (!authUserId) {
                    return false
                }
                const data = await getTeamManageCountService({
                    authUserId,
                    ...params
                }) || {}
                // console.log('getTeamManageCountService:' + JSON.stringify(data, null, 4))
                this.statisticsList.forEach(item => {
                    // console.log('getTeamManageCountService item' + JSON.stringify(item, null, 4))
                    if (item.key !== 'teamUserTotal' && item.key !== 'insuranceCount') {
                        item.money = this.thousandBit(data[item.key] * 1 ? parseFloat(data[item.key]).toFixed(2) : '0.00')
                    } else {
                        item.money = this.thousandBit(data[item.key] * 1 ? data[item.key] : '0')
                    }
                })
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.statisticsLoading = false
            }
        },
        /**
         * 获取 列表
         */
        async getList() {
            try {
                const { params, current, limit, authUserId } = this
                if (!authUserId) {
                    return false
                }
                this.loading = true
                const { total, records } = await getTeamManageListService({
                    current,
                    size: limit,
                    authUserId,
                    ...params
                })
                this.total = total || 0
                this.data = records
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.loading = false
            }
        },
        /**
         * 响应搜索事件
         */
        handleSearch(params, signDate) {
            this.current = 1
            this.params = params
            this.signDate = signDate
            this.getCount()
            this.getList()
        },
        /**
         * 响应刷新事件
         */
        handleRefresh(params, signDate) {
            this.handleSearch(params, signDate)
        },
        /**
         * 响应 table change 事件
         * @param pagination
         */
        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.current = current
            this.limit = pageSize
            this.getCount()
            this.getList()
        },
        /**
         * 查看保单详情 如果从工作台-团队详情跳转：则展示1、2、3字段
         */
        handleInsuranceDetail(record) {
            const { CUSTOMER_POLICY_BUSINESS_TYPE_TEAM, signDate, params } = this
            const { signingStartDate, signingEndDate } = params
            const { userId, userName } = record || {}
            this.$router.push({
                name: 'PolicyManage',
                query: {
                    userId,
                    userName,
                    businessType: CUSTOMER_POLICY_BUSINESS_TYPE_TEAM,
                    signDate,
                    signingStartDate,
                    signingEndDate,
                    commissionVisibility: true
                }
            })
        },
        /**
         * 返回
         */
        handleBack() {
            this.$router.back()
        }
    }
}
</script>

<style lang="scss">
.team-manage-page {
    .header-panel-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 24px;
        background: #f7f8fa;
        border-radius: 4px 4px 0 0;
        box-shadow: 0 2px 30px 0 rgb(220 222 223 / 50%);

        h3 {
            margin: 0;
        }
    }
    &__remark-button {
        width: 100%;
        span {
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    .tip-icon {
        color: #D40000;
        margin-right: 2px;
    }
    .container-panel__content .ant-table-content button:last-child {
        padding-right: 0!important;
    }
}
</style>
