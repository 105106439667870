<template>
    <span>
        <span v-if="orderNo">{{ prefixText }}
            <span class="order-color" @click="handleClick">{{ orderNo }}</span>
            {{ subfixText }}</span>
        <span v-else>{{ message }}</span>
    </span>
</template>

<script>
const INTERCEPTION_REG = /\d+/g

export default {
    name: 'ContentText',
    props: {
        message: {
            type: String,
            required: true
        }
    },
    computed: {
        // 截取出来的单号
        orderNo() {
            const matches = this.message.match(INTERCEPTION_REG)
            return matches ? matches[0] : undefined
        },
        // 单号的长度
        orderNoLen() {
            return this.orderNo?.length
        },
        // 单号出现的起始位置
        orderNoStartIndex() {
            if (!this.orderNo) return -1
            return this.message.indexOf(this.orderNo)
        },
        // 单号出现的结束位置的后一位
        orderNoEndIndex() {
            if (!this.orderNo) return -1
            return this.orderNoStartIndex + this.orderNoLen
        },
        // 单号之后的文本
        subfixText() {
            if (!this.orderNo) return
            return this.message.substring(this.orderNoEndIndex)
        },
        // 单号之前的文本
        prefixText() {
            if (!this.orderNo) return
            return this.message.substring(0, this.orderNoStartIndex)
        }
    },
    methods: {
        handleClick() {
            this.$emit('click-order', this.orderNo)
        }
    }
}
</script>


<style lang="scss" scoped>
.order-color {
    color: #0D80FA;
    cursor: pointer;
}
</style>
