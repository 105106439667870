/**
 * 前端枚举维护
 * ————————————————————————————————————————————————————————————————————————————————————————————————————————————————
 **/

/**
 * 用户权限 枚举
 * @type {Number}
 */
// 用户权限 -- 菜单权限
export const PERMISSION_TYPE_MENU = 1
// 用户权限 -- 菜单权限
export const PERMISSION_TYPE_BUTTON = 2

/**
 * 线索
 * ——————————————————————————————————————————————————————————————————————————————————————————————
 */
/**
 * 线索分配状态 枚举
 * @type {Boolean}
 */
// 线索分配状态 枚举 —— 开启
export const CLUE_DISTRIBUTE_STATUS_ON = true
// 线索分配状态 枚举 —— 关闭
export const CLUE_DISTRIBUTE_STATUS_OFF = false
// 线索分配状态 枚举 枚举列表
export const CLUE_DISTRIBUTE_STATUS_ENUM_LIST = [{
    label: '开启',
    value: CLUE_DISTRIBUTE_STATUS_ON
}, {
    label: '关闭',
    value: CLUE_DISTRIBUTE_STATUS_OFF
}]

/**
 * 线索来源 枚举
 * @type {String}
 */
// 线索来源 枚举 -- 未知
export const CLUE_SOURCE_UNKNOW = '0'
// 线索来源 枚举 -- 工保网
export const CLUE_SOURCE_GONGBAO = 1
// 线索来源 枚举 -- 经纪人
export const CLUE_SOURCE_BROKER = 2
// 线索来源 枚举 -- 试算
export const CLUE_SOURCE_TRIAL = 3
// 线索来源 枚举 -- 渠道
export const CLUE_SOURCE_CHANNEL = 4
export const CLUE_SOURCE_ENUM_LIST = [{
    label: '未知',
    value: CLUE_SOURCE_UNKNOW
},
{
    label: '工保网',
    value: CLUE_SOURCE_GONGBAO
},
{
    label: '经纪人',
    value: CLUE_SOURCE_BROKER
},
{
    label: '试算',
    value: CLUE_SOURCE_TRIAL
},
{
    label: '渠道',
    value: CLUE_SOURCE_CHANNEL
}]

/**
 * 线索类型 枚举
 * @type {String}
 */
// 线索类型 枚举 -- 客户线索
export const CLUE_TYPE_CUSTOMER_CLUE = 0
// 线索类型 枚举 -- 经纪人线索
export const CLUE_TYPE_BROKER_CLUE = 1
export const CLUE_TYPE_ENUM_LIST = [{
    label: '客户线索',
    value: CLUE_TYPE_CUSTOMER_CLUE
}, {
    label: '经纪人线索',
    value: CLUE_TYPE_BROKER_CLUE
}]

/**
 * 备注类型 枚举 用于备注组件内区分类型
 * @type {String}
 */
export const REMARK_TYPE_CLUE = 'clue' // 线索
export const REMARK_TYPE_CUSTOMER = 'customer' // 客户
export const REMARK_TYPE_BROKER = 'broker' // 经纪人

/**
 * 下拉管家列表类型
 * @type {String}
 */
export const STEWARD_LIST_TYPE_CUSTOMER_CLUE = '0' // 客户线索下拉
export const STEWARD_LIST_TYPE_BROKER_CLUE = '1' // 经纪人线索下拉
export const STEWARD_LIST_TYPE_CUSTOMER_LIST = '2' // 客户列表下拉(业务管家)
export const STEWARD_LIST_TYPE_BROKER_LIST = '3' // 经纪人列表下拉
export const STEWARD_LIST_TYPE_SERVE_LIST = '4' // 订单列表下拉(服务管家)


/**
 * 分配管家接口类型，用于区分线索管理、客户管理、经纪人管理
 * @type {String}
 */
export const DISPENSE_STEWARD_TYPE_CUSTOMER_CLUE = '0' // 线索管理
export const DISPENSE_STEWARD_TYPE_CUSTOMER = '1' // 客户管理
export const DISPENSE_STEWARD_TYPE_BROKER = '2' // 经纪人管理
export const DISPENSE_STEWARD_TYPE_BROKER_CLUE = '3' // 经纪人管理

/**
 * 管家类型--测试环境
 * @type {String}
 */
// export const STEWARD_TYPE_BUSINESS = 214 // 业务管家
// export const STEWARD_TYPE_SERVE = 215 // 服务管家
// export const STEWARD_TYPE_INTERNET = 216 // 互联网管家
// export const STEWARD_TYPE_COMMERICAL = 227 // 商务管家
// export const STEWARD_TYPE_BUSINESS_ADMIN = 228 // 业务管家主管
// export const STEWARD_TYPE_INTERNET_ADMIN = 229 // 互联网管家主管
// export const STEWARD_TYPE_COMMERICAL_ADMIN = 230 // 商务管家主管
// export const STEWARD_TYPE_SERVE_ADMIN = 231 // 服务管家主管
/**
 * 管家类型--预发/生产环境
 * @type {String}
 */
export const STEWARD_TYPE_BUSINESS = '214' // 业务管家
export const STEWARD_TYPE_SERVE = '215' // 服务管家
export const STEWARD_TYPE_INTERNET = '216' // 互联网管家
export const STEWARD_TYPE_COMMERICAL = '220' // 商务管家
export const STEWARD_TYPE_BUSINESS_ADMIN = '221' // 业务管家主管
export const STEWARD_TYPE_INTERNET_ADMIN = '223' // 互联网管家主管
export const STEWARD_TYPE_COMMERICAL_ADMIN = '224' // 商务管家主管
export const STEWARD_TYPE_SERVE_ADMIN = '222' // 服务管家主管

/**
 * 标志crm调用
 * @type {String}
 */
export const IS_CRM = '1'


/**
 * @description 验证码类型
 * @type {String}
 */
export const VERIFY_CODE_TYPE = {
    login: 'PHONE_LOGIN',
    register: 'PHONE_REGISTER',
    updatePassword: 'PHONE_PASSWORD',
    updateMobile: 'PHONE_CHANGE',
    verityIdentity: 'PHONE_VERIFY'
}

/**
 * @description 修改用户信息 更新 类型
 * @type {Number}
 */

export const UPDATE_INFO_TYPE = 0 // 常规更新
export const UPDATE_INFO_EMPTY_STRING_TYPE = 1 // 空字符串更新  (后端约定传空字符串则更新当前字段)

export const UPDATE_TYPE_USER = 'UPDATE_USER' // 修改-个人信息
export const UPDATE_TYPE_PASSWORD = 'UPDATE_PASSWORD' // 修改-密码
export const UPDATE_TYPE_PHONE = 'UPDATE_PHONE' // 修改-手机号

/**
 * 预约联系时间段
 * @type {String}
 */
export const APPOINTMENT_CONTACT_TIME_AM = 0 // 上午
export const APPOINTMENT_CONTACT_TIME_PM = 1 // 下午
export const APPOINTMENT_CONTACT_TIME_ENUM_LIST = [
    {
        value: APPOINTMENT_CONTACT_TIME_AM,
        label: '上午'
    }, {
        value: APPOINTMENT_CONTACT_TIME_PM,
        label: '下午'
    }
]

// 获取可分配服务管家列表queryType参数
export const GET_All_OCATING_QUERY_TYPE = 'auth_o'

/**
 * 管家组id
 * @type {String}
 */
export const ASSISTANT_LIST_ID = '215' // 助理列表
export const BUSINESS_HOUSEKEEPER_ID = '214' // 业务管家
export const SPECIAL_AGENT_ID = '234' // 特别经纪人
export const GROUP_ID_ENUM_LIST = [
    {
        value: BUSINESS_HOUSEKEEPER_ID,
        label: '业务管家'
    }, {
        value: SPECIAL_AGENT_ID,
        label: '特别业务管家'
    }
]

/**
 * 管家管理-业务关联列表-关联用户标签
 * @type {Number}
 */
export const BUSINESS_HOUSEKEEPER = 0 // 业务管家
export const SPECIAL_BUSINESS_HOUSEKEEPER = 1 // 特别业务管家
export const BUSINESS_HOUSEKEEPER_TEXT = '业务管家'
export const SPECIAL_BUSINESS_HOUSEKEEPER_TEXT = '特别业务管家'

export const BUSINESS_HOUSEKEEPER_ENUM_LIST = [
    {
        value: BUSINESS_HOUSEKEEPER,
        label: BUSINESS_HOUSEKEEPER_TEXT
    }, {
        value: SPECIAL_BUSINESS_HOUSEKEEPER,
        label: SPECIAL_BUSINESS_HOUSEKEEPER_TEXT
    }
]


/**
 * 客户意向
 * @type {Number}
 */
export const CUSTOMER_INTENTION_HIGH = '0' // 高
export const CUSTOMER_INTENTION_MEDIUM = '1' // 中
export const CUSTOMER_INTENTION_LOW = '2' // 低

export const CUSTOMER_INTENTION_ENUM_LIST = [
    {
        label: '高',
        value: CUSTOMER_INTENTION_HIGH
    }, {
        label: '中',
        value: CUSTOMER_INTENTION_MEDIUM
    }, {
        label: '低',
        value: CUSTOMER_INTENTION_LOW
    }
]


/**
 * 性别
 * @type {Number}
 */

export const SEX_ENUM_LIST = [
    {
        label: '男',
        value: '0'
    }, {
        label: '女',
        value: '1'
    }
]


/**
 * 客户
 * ——————————————————————————————————————————————————————————————————————————————————————————————
 */
/**
 * 跟进方式 枚举
 * @type {Number}
 */
// 跟进方式 枚举 —— 电话沟通
export const CUSTOMER_FOLLOW_TYPE_PHONE = 0
export const CUSTOMER_FOLLOW_TYPE_PHONE_ENUM = {
    label: '电话沟通',
    value: CUSTOMER_FOLLOW_TYPE_PHONE
}
// 跟进方式 枚举 —— 见面拜访
export const CUSTOMER_FOLLOW_TYPE_VISIT = 1
export const CUSTOMER_FOLLOW_TYPE_VISIT_ENUM = {
    label: '见面拜访',
    value: CUSTOMER_FOLLOW_TYPE_VISIT
}
// 跟进方式 枚举 —— 微信聊天
export const CUSTOMER_FOLLOW_TYPE_WE_CHAT = 2
export const CUSTOMER_FOLLOW_TYPE_WE_CHAT_ENUM = {
    label: '微信聊天',
    value: CUSTOMER_FOLLOW_TYPE_WE_CHAT
}
export const CUSTOMER_FOLLOW_TYPE_ENUM_LIST = [
    CUSTOMER_FOLLOW_TYPE_PHONE_ENUM, CUSTOMER_FOLLOW_TYPE_VISIT_ENUM, CUSTOMER_FOLLOW_TYPE_WE_CHAT_ENUM
]

/**
 * 是否注册 枚举
 * @type {Number}
 */
// 未注册
export const CUSTOMER_REGISTERED_NOT = 0
// 已注册
export const CUSTOMER_REGISTERED = 1
export const CUSTOMER_REGISTER_ENUM_LIST = [
    {
        label: '是',
        value: CUSTOMER_REGISTERED
    }, {
        label: '否',
        value: CUSTOMER_REGISTERED_NOT
    }
]

/**
 * 线索记录-订单状态 枚举
 * @type {Number}
 */
// 订单状态 枚举 —— 取消
export const CUSTOMER_LEAD_ORDER_STATE_CANCEL = -1
export const CUSTOMER_LEAD_ORDER_STATE_CANCEL_ENUM = {
    label: '取消',
    value: CUSTOMER_LEAD_ORDER_STATE_CANCEL
}
// 订单状态 枚举 —— 待处理
export const CUSTOMER_LEAD_ORDER_STATE_PENDING = 0
export const CUSTOMER_LEAD_ORDER_STATE_PENDING_ENUM = {
    label: '待处理',
    value: CUSTOMER_LEAD_ORDER_STATE_PENDING
}
// 订单状态 枚举 —— 处理中
export const CUSTOMER_LEAD_ORDER_STATE_WE_PROCESSING = 1
export const CUSTOMER_LEAD_ORDER_STATE_WE_PROCESSING_ENUM = {
    label: '处理中',
    value: CUSTOMER_LEAD_ORDER_STATE_WE_PROCESSING
}
// 订单状态 枚举 —— 已完成
export const CUSTOMER_LEAD_ORDER_STATE_WE_COMPLETED = 2
export const CUSTOMER_LEAD_ORDER_STATE_WE_COMPLETED_ENUM = {
    label: '已完成',
    value: CUSTOMER_LEAD_ORDER_STATE_WE_COMPLETED
}
// 订单状态 枚举 —— 关闭
export const CUSTOMER_LEAD_ORDER_STATE_WE_CLOSE = 3
export const CUSTOMER_LEAD_ORDER_STATE_WE_CLOSE_ENUM = {
    label: '关闭',
    value: CUSTOMER_LEAD_ORDER_STATE_WE_CLOSE
}
export const CUSTOMER_LEAD_ORDER_STATE_ENUM_LIST = [
    CUSTOMER_LEAD_ORDER_STATE_CANCEL_ENUM,
    CUSTOMER_LEAD_ORDER_STATE_PENDING_ENUM,
    CUSTOMER_LEAD_ORDER_STATE_WE_PROCESSING_ENUM,
    CUSTOMER_LEAD_ORDER_STATE_WE_COMPLETED_ENUM,
    CUSTOMER_LEAD_ORDER_STATE_WE_CLOSE_ENUM
]

/*
 * 自营分销经纪人类型
 * @type {number}
 */
// 无
export const SALES_CATEGORY_NOT_TEXT = '无'
// 分销
export const SALES_CATEGORY_DISTRIBUTION = 0
export const SALES_CATEGORY_DISTRIBUTION_TEXT = '分销'
export const SALES_CATEGORY_DISTRIBUTION_ENUM = {
    label: SALES_CATEGORY_DISTRIBUTION_TEXT,
    value: SALES_CATEGORY_DISTRIBUTION
}
// 自营
export const SALES_CATEGORY_SELF_SUPPORT = 1
export const SALES_CATEGORY_SELF_SUPPORT_TEXT = '自营'
export const SALES_CATEGORY_SELF_SUPPORT_ENUM = {
    label: SALES_CATEGORY_SELF_SUPPORT_TEXT,
    value: SALES_CATEGORY_SELF_SUPPORT
}
export const SALES_CATEGORY_USE_ENUM_LIST = [SALES_CATEGORY_DISTRIBUTION_ENUM, SALES_CATEGORY_SELF_SUPPORT_ENUM]


/**
 * 保单列表-业务来源
 * @type {Number}
 */
// 工作台
export const CUSTOMER_POLICY_BUSINESS_TYPE_WORKSPACE = 2
// 工作台-团队详情
export const CUSTOMER_POLICY_BUSINESS_TYPE_TEAM = 3
// 线上保单
export const CUSTOMER_POLICY_BUSINESS_TYPE_ONLINE = 4

/**
 * 订单记录-订单状态 枚举
 * @type {Number}
 */
// 订单状态 枚举 —— 待提交
export const CUSTOMER_ORDER_ORDER_STATE_PENDING = 0
export const CUSTOMER_ORDER_ORDER_STATE_PENDING_ENUM = {
    label: '待提交',
    value: CUSTOMER_ORDER_ORDER_STATE_PENDING
}
// 订单状态 枚举 —— 提交订单
export const CUSTOMER_ORDER_ORDER_STATE_SUBMIT = 1
export const CUSTOMER_ORDER_ORDER_STATE_SUBMIT_ENUM = {
    label: '提交订单',
    value: CUSTOMER_ORDER_ORDER_STATE_SUBMIT
}
// 订单状态 枚举 —— 审查审核
export const CUSTOMER_ORDER_ORDER_STATE_CHECK = 2
export const CUSTOMER_ORDER_ORDER_STATE_CHECK_ENUM = {
    label: '审查审核',
    value: CUSTOMER_ORDER_ORDER_STATE_CHECK
}
// 订单状态 枚举 —— 风控审核
export const CUSTOMER_ORDER_ORDER_STATE_RISK = 3
export const CUSTOMER_ORDER_ORDER_STATE_RISK_ENUM = {
    label: '风控审核',
    value: CUSTOMER_ORDER_ORDER_STATE_RISK
}
// 订单状态 枚举 —— 保险审核
export const CUSTOMER_ORDER_ORDER_STATE_INSURANCE = 4
export const CUSTOMER_ORDER_ORDER_STATE_INSURANCE_ENUM = {
    label: '保险审核',
    value: CUSTOMER_ORDER_ORDER_STATE_INSURANCE
}
// 订单状态 枚举 —— 支付订单
export const CUSTOMER_ORDER_ORDER_STATE_PAY = 5
export const CUSTOMER_ORDER_ORDER_STATE_PAY_ENUM = {
    label: '待支付订单',
    value: CUSTOMER_ORDER_ORDER_STATE_PAY
}
// 订单状态 枚举 —— 保单出函
export const CUSTOMER_ORDER_ORDER_STATE_POLICY = 6
export const CUSTOMER_ORDER_ORDER_STATE_POLICY_ENUM = {
    label: '保单出函',
    value: CUSTOMER_ORDER_ORDER_STATE_POLICY
}
// 订单状态 枚举 —— 订单完成
export const CUSTOMER_ORDER_ORDER_STATE_COMPLETED = 7
export const CUSTOMER_ORDER_ORDER_STATE_COMPLETED_ENUM = {
    label: '订单完成',
    value: CUSTOMER_ORDER_ORDER_STATE_COMPLETED
}
// 订单状态 枚举 —— 订单已关闭
export const CUSTOMER_ORDER_ORDER_STATE_CLOSE = 8
export const CUSTOMER_ORDER_ORDER_STATE_CLOSE_ENUM = {
    label: '订单已关闭',
    value: CUSTOMER_ORDER_ORDER_STATE_CLOSE
}
// 订单状态 枚举 —— 待后补审核
export const CUSTOMER_ORDER_ORDER_STATE_SUPPLEMENT = 20
export const CUSTOMER_ORDER_ORDER_STATE_SUPPLEMENT_ENUM = {
    label: '待后补审核',
    value: CUSTOMER_ORDER_ORDER_STATE_SUPPLEMENT
}
export const CUSTOMER_ORDER_ORDER_STATE_ENUM_LIST = [
    CUSTOMER_ORDER_ORDER_STATE_PENDING_ENUM,
    CUSTOMER_ORDER_ORDER_STATE_SUBMIT_ENUM,
    CUSTOMER_ORDER_ORDER_STATE_CHECK_ENUM,
    CUSTOMER_ORDER_ORDER_STATE_RISK_ENUM,
    CUSTOMER_ORDER_ORDER_STATE_INSURANCE_ENUM,
    CUSTOMER_ORDER_ORDER_STATE_PAY_ENUM,
    CUSTOMER_ORDER_ORDER_STATE_POLICY_ENUM,
    CUSTOMER_ORDER_ORDER_STATE_COMPLETED_ENUM,
    CUSTOMER_ORDER_ORDER_STATE_CLOSE_ENUM,
    CUSTOMER_ORDER_ORDER_STATE_SUPPLEMENT_ENUM
]

/**
 * 订单记录-操作日志-订单状态 枚举
 * @type {number}
 */
// 操作日志-订单状态 -- 待定
export const USER_ORDER_STATUS_PENDING = 0
export const USER_ORDER_STATUS_PENDING_ENUM = {
    label: '待定',
    value: USER_ORDER_STATUS_PENDING
}
// 操作日志-订单状态 -- 待支付
export const USER_ORDER_STATUS_SUBMIT = 1
export const USER_ORDER_STATUS_SUBMIT_ENUM = {
    label: '待支付',
    value: USER_ORDER_STATUS_SUBMIT
}
// 操作日志-订单状态 -- 支付中
export const USER_ORDER_STATUS_IN = 2
export const USER_ORDER_STATUS_IN_ENUM = {
    label: '支付中',
    value: USER_ORDER_STATUS_IN
}
// 操作日志-订单状态 -- 支付成功
export const USER_ORDER_STATUS_DONE = 3
export const USER_ORDER_STATUS_DONE_ENUM = {
    label: '支付成功',
    value: USER_ORDER_STATUS_DONE
}
// 操作日志-订单状态 -- 支付失败
export const USER_ORDER_STATUS_FAILED = 4
export const USER_ORDER_STATUS_FAILED_ENUM = {
    label: '支付失败',
    value: USER_ORDER_STATUS_FAILED
}
// 操作日志-订单状态 -- 支付退款
export const USER_ORDER_STATUS_REFUND = 5
export const USER_ORDER_STATUS_REFUND_ENUM = {
    label: '支付退款',
    value: USER_ORDER_STATUS_REFUND
}
// 操作日志-订单状态 -- 支付关闭
export const USER_ORDER_STATUS_OFF = 6
export const USER_ORDER_STATUS_OFF_ENUM = {
    label: '支付关闭',
    value: USER_ORDER_STATUS_OFF
}
// 操作日志-订单状态 -- 订单取消
export const USER_ORDER_STATUS_CANCEL = 7
export const USER_ORDER_STATUS_CANCEL_ENUM = {
    label: '订单取消',
    value: USER_ORDER_STATUS_CANCEL
}
export const USER_ORDER_STATUS_ENUM_LIST = [
    USER_ORDER_STATUS_PENDING_ENUM,
    USER_ORDER_STATUS_SUBMIT_ENUM,
    USER_ORDER_STATUS_IN_ENUM,
    USER_ORDER_STATUS_DONE_ENUM,
    USER_ORDER_STATUS_FAILED_ENUM,
    USER_ORDER_STATUS_REFUND_ENUM,
    USER_ORDER_STATUS_OFF_ENUM,
    USER_ORDER_STATUS_CANCEL_ENUM
]

/**
 * 保单记录-保单状态 枚举
 * @type {number}
 */

// 保单记录-保单状态 -- 保险中
export const POLICY_ORDER_STATUS_INSURANCE = 0
export const POLICY_ORDER_STATUS_INSURANCE_LABEL = '保险中'
export const POLICY_ORDER_STATUS_INSURANCE_ENUM = {
    label: '保险中',
    value: POLICY_ORDER_STATUS_INSURANCE
}
// 保单记录-保单状态 -- 待生效
export const POLICY_ORDER_STATUS_EFFECTIVE = 1
export const POLICY_ORDER_STATUS_EFFECTIVE_LABEL = '待生效'
export const POLICY_ORDER_STATUS_EFFECTIVE_ENUM = {
    label: '待生效',
    value: POLICY_ORDER_STATUS_EFFECTIVE
}
// 保单记录-保单状态 -- 待续保
export const POLICY_ORDER_STATUS_RENEWED = 2
export const POLICY_ORDER_STATUS_RENEWED_LABEL = '待续保'
export const POLICY_ORDER_STATUS_RENEWED_ENUM = {
    label: '待续保',
    value: POLICY_ORDER_STATUS_RENEWED
}
// 保单记录-保单状态 -- 保险退保
export const POLICY_ORDER_STATUS_SURRENDER = 3
export const POLICY_ORDER_STATUS_SURRENDER_LABEL = '保险退保'
export const POLICY_ORDER_STATUS_SURRENDER_ENUM = {
    label: '保险退保',
    value: POLICY_ORDER_STATUS_SURRENDER
}
export const POLICY_ORDER_STATUS_ENUM_LIST = [
    POLICY_ORDER_STATUS_INSURANCE_ENUM,
    POLICY_ORDER_STATUS_EFFECTIVE_ENUM,
    POLICY_ORDER_STATUS_RENEWED_ENUM,
    POLICY_ORDER_STATUS_SURRENDER_ENUM
]

/**
 * 发票记录-发票类型 枚举
 * @type {Number}
 */
// 发票类型 -- 电子发票
export const INVOICE_ORDER_TYPE_ELECTRONIC = 0
export const INVOICE_ORDER_TYPE_ELECTRONIC_ENUM = {
    label: '增值税普通发票（电子发票）',
    value: INVOICE_ORDER_TYPE_ELECTRONIC
}
// 发票类型 -- 纸质发票
export const INVOICE_ORDER_TYPE_PAPER = 1
export const INVOICE_ORDER_TYPE_PAPER_ENUM = {
    label: '增值税普通发票（纸质发票）',
    value: INVOICE_ORDER_TYPE_PAPER
}
export const INVOICE_ORDER_TYPE_ENUM_LIST = [
    INVOICE_ORDER_TYPE_ELECTRONIC_ENUM, INVOICE_ORDER_TYPE_PAPER_ENUM
]

/**
 * 发票记录-发票状态 枚举
 * @type {Number}
 */
// 发票状态 枚举 —— 未出票
export const INVOICE_STATE_TICKET_NOT = 1
export const INVOICE_STATE_TICKET_NOT_MENU = {
    value: INVOICE_STATE_TICKET_NOT,
    label: '未出票'
}
// 发票状态 枚举 —— 发票完成
export const INVOICE_STATE_FINISHED = 3
export const INVOICE_STATE_FINISHED_MENU = {
    value: INVOICE_STATE_FINISHED,
    label: '发票完成'
}
// 发票状态 枚举 —— 拒绝发票
export const INVOICE_STATE_REFUSE = 4
export const INVOICE_STATE_REFUSE_MENU = {
    value: INVOICE_STATE_REFUSE,
    label: '拒绝发票'
}
export const INVOICE_STATE_MENU_LIST = [
    INVOICE_STATE_TICKET_NOT_MENU, INVOICE_STATE_FINISHED_MENU, INVOICE_STATE_REFUSE_MENU
]

/**
 * 保全记录-批改类型枚举
 * @type {Number}
 */
// 批改类型 -- 修改
export const CORRECT_SERVICE_TYPE_UPDATE = 0
// 批改类型-- 退保
export const CORRECT_SERVICE_TYPE_REFUND = 1
// 批改类型 -- 理赔
export const CORRECT_SERVICE_TYPE_CLAIM = 2
// 批改类型枚举
export const CORRECT_SERVICE_TYPE_ENUM_LIST = [{
    label: '修改',
    value: CORRECT_SERVICE_TYPE_UPDATE
}, {
    label: '退保',
    value: CORRECT_SERVICE_TYPE_REFUND
}, {
    label: '理赔',
    value: CORRECT_SERVICE_TYPE_CLAIM
}]

/**
 * 保全记录-状态 枚举
 * @type {Number}
 */
// 状态 枚举 —— 待审核
export const PRESERVATION_STATE_TICKET_NOT = 0
export const PRESERVATION_STATE_TICKET_NOT_MENU = {
    value: PRESERVATION_STATE_TICKET_NOT,
    label: '待审核'
}
// 状态 枚举 —— 通过
export const PRESERVATION_STATE_FINISHED = 1
export const PRESERVATION_STATE_FINISHED_MENU = {
    value: PRESERVATION_STATE_FINISHED,
    label: '通过'
}
// 状态 枚举 —— 拒绝
export const PRESERVATION_STATE_REFUSE = 3
export const PRESERVATION_STATE_REFUSE_MENU = {
    value: PRESERVATION_STATE_REFUSE,
    label: '拒绝'
}
export const PRESERVATION_STATE_MENU_LIST = [
    PRESERVATION_STATE_TICKET_NOT_MENU, PRESERVATION_STATE_FINISHED_MENU, PRESERVATION_STATE_REFUSE_MENU
]

// 工作台--枚举===========
/**
 * 消息已读/未读
 * @type {Boolean}
 */
export const MESSAGE_READ = true // 已读
export const MESSAGE_UNREAD = false // 未读

export const MESSAGE_READ_MENU = {
    label: '已读',
    value: MESSAGE_READ
}

export const MESSAGE_UNREAD_MENU = {
    label: '未读',
    value: MESSAGE_UNREAD
}
export const MESSAGE_READ_STATE_MENU = [MESSAGE_READ_MENU, MESSAGE_UNREAD_MENU]

/**
 * 消息类型枚举
 * @type {Number}
 */
export const GENERAL_NOTICE = 0 // 普通通知
export const IMPORTANT_NOTICE = 1 // 重要通知
export const URGENT_NOTICE = 2 // 紧急通知
export const REMIND_NOTICE = 3 // 提醒

export const MESSAGE_TYPE_GENERAL_MENU = {
    label: '普通通知',
    value: GENERAL_NOTICE
}

export const MESSAGE_TYPE_IMPORTANT_MENU = {
    label: '重要通知',
    value: IMPORTANT_NOTICE
}

export const MESSAGE_TYPE_URGENT_MENU = {
    label: '紧急通知',
    value: URGENT_NOTICE
}

export const MESSAGE_TYPE_REMIND_MENU = {
    label: '提醒',
    value: REMIND_NOTICE
}
export const MESSAGE_TYPE_LIST_MENU = [
    MESSAGE_TYPE_GENERAL_MENU,
    MESSAGE_TYPE_IMPORTANT_MENU,
    MESSAGE_TYPE_URGENT_MENU,
    MESSAGE_TYPE_REMIND_MENU
]

/**
 * 保单详情-来源 枚举
 * @type {number}
 */
// 来源 -- 未知
export const ORDER_SOURCE_UNKNOWN = 0
export const ORDER_SOURCE_UNKNOWN_ENUM = {
    label: '未知',
    value: ORDER_SOURCE_UNKNOWN
}
// 来源 -- pc
export const ORDER_SOURCE_GBW = 1
export const ORDER_SOURCE_GBW_ENUM = {
    label: '工保网-pc',
    value: ORDER_SOURCE_GBW
}
// 来源 -- H5
export const ORDER_SOURCE_AGENT = 2
export const ORDER_SOURCE_AGENT_ENUM = {
    label: 'H5',
    value: ORDER_SOURCE_AGENT
}
// 来源 -- 公众号
export const ORDER_SOURCE_TRIAL = 3
export const ORDER_SOURCE_TRIAL_ENUM = {
    label: '公众号',
    value: ORDER_SOURCE_TRIAL
}
// 来源 -- 渠道
export const ORDER_SOURCE_CHANNEL = 4
export const ORDER_SOURCE_CHANNEL_ENUM = {
    label: '渠道',
    value: ORDER_SOURCE_CHANNEL
}
// 来源 -- app
export const ORDER_SOURCE_APP = 5
export const ORDER_SOURCE_APP_ENUM = {
    label: '工保网-app',
    value: ORDER_SOURCE_APP
}
export const ORDER_SOURCE_ENUM_LIST = [
    ORDER_SOURCE_UNKNOWN_ENUM,
    ORDER_SOURCE_GBW_ENUM,
    ORDER_SOURCE_AGENT_ENUM,
    ORDER_SOURCE_TRIAL_ENUM,
    ORDER_SOURCE_CHANNEL_ENUM,
    ORDER_SOURCE_APP_ENUM
]

/**
 * 基本信息-性别 枚举
 * @type {Number}
 */
// 性别 枚举 —— 男
export const CUSTOMER_BASE_INFO_SEX_MALE = 0
export const CUSTOMER_BASE_INFO_SEX_MALE_MENU = {
    value: CUSTOMER_BASE_INFO_SEX_MALE,
    label: '男'
}
// 性别 枚举 —— 女
export const CUSTOMER_BASE_INFO_SEX_FEMALE = 1
export const CUSTOMER_BASE_INFO_SEX_FEMALE_MENU = {
    value: CUSTOMER_BASE_INFO_SEX_FEMALE,
    label: '女'
}
export const CUSTOMER_BASE_INFO_SEX_LIST = [
    CUSTOMER_BASE_INFO_SEX_MALE_MENU, CUSTOMER_BASE_INFO_SEX_FEMALE_MENU
]

/**
 * 签单日期枚举
 * @type {number}
 */
export const INSURANCE_DATE_ALL = -1
// 签单日期 -- 今天
export const SIGNING_DATE_TODAY = 1
// 签单日期-- 本月
export const SIGNING_DATE_THIS_MONTH = 2
// 签单日期 -- 今年
export const SIGNING_DATE_THIS_YEAR = 3
// 签单日期 -- 自定义
export const SIGNING_DATE_THIS_CUSTOM = 4
export const SIGNING_DATE_TYPE_ENUM_LIST = [
    {
        label: '今天',
        value: SIGNING_DATE_TODAY
    },
    {
        label: '本月',
        value: SIGNING_DATE_THIS_MONTH
    },
    {
        label: '今年',
        value: SIGNING_DATE_THIS_YEAR
    },
    {
        label: '自定义',
        value: SIGNING_DATE_THIS_CUSTOM
    }
]
export const INSURANCE_DATE_TYPE_ENUM_LIST = [
    {
        label: '请选择',
        value: INSURANCE_DATE_ALL
    },
    {
        label: '今天',
        value: SIGNING_DATE_TODAY
    },
    {
        label: '本月',
        value: SIGNING_DATE_THIS_MONTH
    },
    {
        label: '今年',
        value: SIGNING_DATE_THIS_YEAR
    },
    {
        label: '自定义',
        value: SIGNING_DATE_THIS_CUSTOM
    }
]

/**
 * 险种是否启用
 * @type {number}
 */
// 险种启用 是
export const INSURANCE_TYPE_OPEN = 1
// 险种启用 否
export const INSURANCE_TYPE_CLOSE = 0

export const INSURANCE_TYPE_ENUM_LIST = [
    {
        label: '是',
        value: INSURANCE_TYPE_OPEN
    }, {
        label: '否',
        value: INSURANCE_TYPE_CLOSE
    }
]

/**
 * 人员常规状态枚举
 * @type {String}
 */
// 在职
export const PERSONAL_STATUS_IN_JOBS = 0
export const PERSONAL_STATUS_IN_JOBS_TEXT = '在职'
// 注销
export const PERSONAL_STATUS_WRITE_OFF = 1
export const PERSONAL_STATUS_WRITE_OFF_TEXT = '注销'
// 离职
export const PERSONAL_STATUS_QUIT_OFF = 2
export const PERSONAL_STATUS_QUIT_OFF_TEXT = '离职'

export const PERSONAL_STATUS_IN_JOBS_ENUM_LIST = [{
    label: PERSONAL_STATUS_IN_JOBS_TEXT,
    value: PERSONAL_STATUS_IN_JOBS
}, {
    label: PERSONAL_STATUS_WRITE_OFF_TEXT,
    value: PERSONAL_STATUS_WRITE_OFF
}, {
    label: PERSONAL_STATUS_QUIT_OFF_TEXT,
    value: PERSONAL_STATUS_QUIT_OFF
}]

/**
* 投保模块相关枚举
 * ——————————————————————————————————————————————————————————————————————————————————————————————
 */
/**
 * 投保模式
 * @type {string}
 */
// 投保
export const INSURE_MODE_INSURE = 'insure'

/**
 * 客户服务-推广获客-表单类型 枚举
 * @type {Boolean}
 * 此表单类型 与 client 前台枚举保持一致，修改时请同时进行维护
 */
// 表单类型 枚举 —— 信息收集-单险种
export const PROMOTE_FORM_TYPE_INFO_SINGLE = '0'
// 表单类型 枚举 —— 信息收集-多险种
export const PROMOTE_FORM_TYPE_INFO_MULTIPLE = '1'
// 表单类型 枚举 —— 投保-单险种
export const PROMOTE_FORM_TYPE_INSURE_SINGLE = '2'
// 表单类型 枚举 —— 投保-多险种
export const PROMOTE_FORM_TYPE_INSURE_MULTIPLE = '3'

// 表单类型 枚举 枚举列表
export const PROMOTE_FORM_TYPE_ENUM_LIST = [
    {
        label: '信息收集-单险种',
        value: PROMOTE_FORM_TYPE_INFO_SINGLE
    },
    {
        label: '信息收集-多险种',
        value: PROMOTE_FORM_TYPE_INFO_MULTIPLE
    },
    {
        label: '投保-单险种',
        value: PROMOTE_FORM_TYPE_INSURE_SINGLE
    },
    {
        label: '投保-多险种',
        value: PROMOTE_FORM_TYPE_INSURE_MULTIPLE
    }
]

/**
 * 客户阶段-枚举
 * @type {Number}
 *
 */
// 客户阶段 枚举 —— 电话沟通
export const CUSTOMER_STAGE_PHONE_COMMUNICATION = 0
// 客户阶段 枚举 —— 需求确认
export const CUSTOMER_STAGE_DEMAND_CONFIRM = 1
// 客户阶段 枚举 —— 方案/报价
export const CUSTOMER_STAGE_SCHEME_QUOTATION = 2
// 客户阶段 枚举 —— 成交
export const CUSTOMER_STAGE_CLINCH_DEAL = 3
// 客户阶段 枚举 —— 暂时搁置
export const CUSTOMER_STAGE_SUSPEND_TEMPORARILY = 4
// 客户阶段 枚举 —— 谈判/合同
export const CUSTOMER_STAGE_NEGOTIATION_CONTRACT = 5
// 客户阶段 枚举 枚举列表
export const CUSTOMER_STAGE_TYPE_ENUM_LIST = [
    {
        label: '电话沟通',
        value: CUSTOMER_STAGE_PHONE_COMMUNICATION
    },
    {
        label: '需求确认',
        value: CUSTOMER_STAGE_DEMAND_CONFIRM
    },
    {
        label: '方案/报价',
        value: CUSTOMER_STAGE_SCHEME_QUOTATION
    },
    {
        label: '成交',
        value: CUSTOMER_STAGE_CLINCH_DEAL
    },
    {
        label: '暂时搁置',
        value: CUSTOMER_STAGE_SUSPEND_TEMPORARILY
    },
    {
        label: '谈判/合同',
        value: CUSTOMER_STAGE_NEGOTIATION_CONTRACT
    }
]

/*
 * 保单类型--枚举
*/
// 保单类型 --全部
export const POLICY_TYPE_ALL = ''
// 保单类型 -- B端线上保单
export const POLICY_TYPE_ONLINE = 'B端线上保单'
// 保单类型-- B端线下保单
export const POLICY_TYPE_LINE = 'B端线下保单'
// 保单类型 -- G端保单
export const POLICY_TYPE_G = 'G端保单'

export const POLICY_TYPE_ENUM_LIST = [
    {
        label: '全部',
        value: POLICY_TYPE_ALL
    },
    {
        label: 'B端线上保单',
        value: POLICY_TYPE_ONLINE
    },
    {
        label: 'B端线下保单',
        value: POLICY_TYPE_LINE
    },
    {
        label: 'G端保单',
        value: POLICY_TYPE_G
    }
]

/**
 * 线索记录-来源类型 枚举
 * @type {Number}
 */
// 来源类型 枚举 —— 普通咨询
export const CUSTOMER_LEAD_SOURCE_TYPE_ZERO = 0
export const CUSTOMER_LEAD_SOURCE_TYPE_ZERO_ENUM = {
    label: '普通咨询',
    value: CUSTOMER_LEAD_SOURCE_TYPE_ZERO
}
// 来源类型 枚举 —— 预约咨询
export const CUSTOMER_LEAD_SOURCE_TYPE_ONE = 1
export const CUSTOMER_LEAD_SOURCE_TYPE_ONE_ENUM = {
    label: '预约咨询',
    value: CUSTOMER_LEAD_SOURCE_TYPE_ONE
}
// 来源类型 枚举 —— 投保咨询
export const CUSTOMER_LEAD_SOURCE_TYPE_TWO = 2
export const CUSTOMER_LEAD_SOURCE_TYPE_TWO_ENUM = {
    label: '投保咨询',
    value: CUSTOMER_LEAD_SOURCE_TYPE_TWO
}
// 来源类型 枚举 —— 报价咨询
export const CUSTOMER_LEAD_SOURCE_TYPE_THREE = 3
export const CUSTOMER_LEAD_SOURCE_TYPE_THREE_ENUM = {
    label: '报价咨询',
    value: CUSTOMER_LEAD_SOURCE_TYPE_THREE
}
// 来源类型 枚举 —— G端保单
export const CUSTOMER_LEAD_SOURCE_TYPE_FOUR = 4
export const CUSTOMER_LEAD_SOURCE_TYPE_FOUR_ENUM = {
    label: 'G端保单',
    value: CUSTOMER_LEAD_SOURCE_TYPE_FOUR
}
export const CUSTOMER_LEAD_SOURCE_TYPE_ENUM_LIST = [
    CUSTOMER_LEAD_SOURCE_TYPE_ZERO_ENUM,
    CUSTOMER_LEAD_SOURCE_TYPE_ONE_ENUM,
    CUSTOMER_LEAD_SOURCE_TYPE_TWO_ENUM,
    CUSTOMER_LEAD_SOURCE_TYPE_THREE_ENUM,
    CUSTOMER_LEAD_SOURCE_TYPE_FOUR_ENUM
]

/**
 * 保单记录-保单类型 枚举
 * @type {number}
 */

// 保单记录-保单类型 -- B端线上保单
export const POLICY_POLICY_TYPE_ONE = 'B端线上保单'
export const POLICY_POLICY_TYPE_ONE_ENUM = {
    label: 'B端线上保单',
    value: POLICY_POLICY_TYPE_ONE
}
// 保单记录-保单类型 -- B端线下保单
export const POLICY_POLICY_TYPE_TWO = 'B端线下保单'
export const POLICY_POLICY_TYPE_TWO_ENUM = {
    label: 'B端线下保单',
    value: POLICY_POLICY_TYPE_TWO
}
// 保单记录-保单类型 -- G端保单
export const POLICY_POLICY_TYPE_THREE = 'G端保单'
export const POLICY_POLICY_TYPE_THREE_ENUM = {
    label: 'G端保单',
    value: POLICY_POLICY_TYPE_THREE
}
export const POLICY_POLICY_TYPE_ENUM_LIST = [
    POLICY_POLICY_TYPE_ONE_ENUM, POLICY_POLICY_TYPE_TWO_ENUM, POLICY_POLICY_TYPE_THREE_ENUM
]


/**
 * 文件上传-阿里云oss目录
 * ——————————————————————————————————————————————————————————————————————————————————————————————
 */
/**
 * 阿里云oss目录
 * @type {string}
 */
// 阿里云oss目录 —— 产品
export const FILE_UPLOAD_DIRECTORY_PRODUCT = 'product/'
// 阿里云oss目录 —— 渠道
export const FILE_UPLOAD_DIRECTORY_CHANNEL = 'channel/'
// 阿里云oss目录 —— 投保表单-资料模板
export const FILE_UPLOAD_DIRECTORY_TEMPLATE = 'template/'
// 阿里云oss目录 —— 产品的渠道
export const FILE_UPLOAD_DIRECTORY_PRODUCT_CHANNEL = 'product-channel/'
// 阿里云oss目录 —— 富文本附件
export const FILE_UPLOAD_DIRECTORY_RICH_TEXT = 'rich-text/'
// 阿里云oss目录 —— 产品披露
export const FILE_UPLOAD_DIRECTORY_PRODUCT_DISCLOSURE = 'disclosure/'
// 阿里云oss目录 —— 经纪人管理
export const FILE_UPLOAD_DIRECTORY_AGENT_MANAGE = 'institutions/'
// 阿里云oss目录 —— 运营管理-推广获客表单管理
export const FILE_UPLOAD_DIRECTORY_PROMOTE = 'promote/'
// 阿里云oss目录 —— 企业认证
export const FILE_UPLOAD_ENTERPRISE_AUTHORIZATION = 'enterprise-authorization/'

/**
 * 企业认证审核状态枚举
 * @type {Number}
 */
// 待提交
export const ENTERPRISE_AUTHORIZATION_WAIT_SUBBMIT = 0
// 待审核
export const ENTERPRISE_AUTHORIZATION_WAIT_AUTHORIZATION = 1
// 已通过
export const ENTERPRISE_AUTHORIZATION_PASS = 2
// 未通过
export const ENTERPRISE_AUTHORIZATION_UNPASS = 3
// 已撤销
export const ENTERPRISE_AUTHORIZATION_CANCEL = 4
// 更新待提交
export const ENTERPRISE_AUTHORIZATION_WAIT_SUBBMIT_RENEW = 5
// 更新待审核
export const ENTERPRISE_AUTHORIZATION_WAIT_AUTHORIZATION_RENEW = 6
// 更新已通过
export const ENTERPRISE_AUTHORIZATION_PASS_RENEW = 7
// 更新未通过
export const ENTERPRISE_AUTHORIZATION_UNPASS_RENEW = 8
// 更新已撤销
export const ENTERPRISE_AUTHORIZATION_CANCEL_RENEW = 9

// 待提交
export const ENTERPRISE_AUTHORIZATION_WAIT_SUBBMIT_TEXT = '待提交'
// 待审核
export const ENTERPRISE_AUTHORIZATION_WAIT_AUTHORIZATION_TEXT = '待审核'
// 已通过
export const ENTERPRISE_AUTHORIZATION_PASS_TEXT = '已通过'
// 未通过
export const ENTERPRISE_AUTHORIZATION_UNPASS_TEXT = '未通过'
// 已撤销
export const ENTERPRISE_AUTHORIZATION_CANCEL_TEXT = '已撤销'
// 更新待提交
export const ENTERPRISE_AUTHORIZATION_WAIT_SUBBMIT_RENEW_TEXT = '更新待提交'
// 更新待审核
export const ENTERPRISE_AUTHORIZATION_WAIT_AUTHORIZATION_RENEW_TEXT = '更新待审核'
// 更新已通过
export const ENTERPRISE_AUTHORIZATION_PASS_RENEW_TEXT = '更新已通过'
// 更新未通过
export const ENTERPRISE_AUTHORIZATION_UNPASS_RENEW_TEXT = '更新未通过'
// 更新已撤销
export const ENTERPRISE_AUTHORIZATION_CANCEL_RENEW_TEXT = '更新已撤销'


export const ENTERPRISE_AUTHORIZATION_LIST = [{
    label: ENTERPRISE_AUTHORIZATION_WAIT_SUBBMIT_TEXT,
    value: ENTERPRISE_AUTHORIZATION_WAIT_SUBBMIT
},
{
    label: ENTERPRISE_AUTHORIZATION_WAIT_AUTHORIZATION_TEXT,
    value: ENTERPRISE_AUTHORIZATION_WAIT_AUTHORIZATION
},
{
    label: ENTERPRISE_AUTHORIZATION_PASS_TEXT,
    value: ENTERPRISE_AUTHORIZATION_PASS
},
{
    label: ENTERPRISE_AUTHORIZATION_UNPASS_TEXT,
    value: ENTERPRISE_AUTHORIZATION_UNPASS
},
{
    label: ENTERPRISE_AUTHORIZATION_CANCEL_TEXT,
    value: ENTERPRISE_AUTHORIZATION_CANCEL
},
{
    label: ENTERPRISE_AUTHORIZATION_WAIT_SUBBMIT_RENEW_TEXT,
    value: ENTERPRISE_AUTHORIZATION_WAIT_SUBBMIT_RENEW
},
{
    label: ENTERPRISE_AUTHORIZATION_WAIT_AUTHORIZATION_RENEW_TEXT,
    value: ENTERPRISE_AUTHORIZATION_WAIT_AUTHORIZATION_RENEW
},
{
    label: ENTERPRISE_AUTHORIZATION_PASS_RENEW_TEXT,
    value: ENTERPRISE_AUTHORIZATION_PASS_RENEW
},
{
    label: ENTERPRISE_AUTHORIZATION_UNPASS_RENEW_TEXT,
    value: ENTERPRISE_AUTHORIZATION_UNPASS_RENEW
},
{
    label: ENTERPRISE_AUTHORIZATION_CANCEL_RENEW_TEXT,
    value: ENTERPRISE_AUTHORIZATION_CANCEL_RENEW
}]

/**
 * 企业认证授权状态枚举
 * @type {Number}
 */
// 待生效
export const ENTERPRISE_AUTHORIZATION_WAIT_VALID = 0
// 已生效
export const ENTERPRISE_AUTHORIZATION_HAS_VALID = 1
// 已过期
export const ENTERPRISE_AUTHORIZATION_INVALID = 2

// 待生效
export const ENTERPRISE_AUTHORIZATION_WAIT_VALID_TEXT = '待生效'
// 已生效
export const ENTERPRISE_AUTHORIZATION_HAS_VALID_TEXT = '已生效'
// 已过期
export const ENTERPRISE_AUTHORIZATION_INVALIDS_TEXT = '已过期'

export const ENTERPRISE_AUTHORIZATION_VALID_LIST = [{
    label: ENTERPRISE_AUTHORIZATION_WAIT_VALID_TEXT,
    value: ENTERPRISE_AUTHORIZATION_WAIT_VALID
}, {
    label: ENTERPRISE_AUTHORIZATION_HAS_VALID_TEXT,
    value: ENTERPRISE_AUTHORIZATION_HAS_VALID
}, {
    label: ENTERPRISE_AUTHORIZATION_INVALIDS_TEXT,
    value: ENTERPRISE_AUTHORIZATION_INVALID
}]

/**
 * 投保单/反担保参数枚举
 */
// 投保单
export const CAST_PARAMS = 'cast_insurance_single'
// 反担保
export const GUARANTEE_PARAMS = 'counter_guarantee'