<template>
  <search-panel :model="form" layout="vertical" @search="handleSearch" @refresh="handleRefresh">
    <form-col label="保司名称" prop="company">
      <template :slot="formColDefaultSlot">
        <a-input v-model="form.company" placeholder="请输入"></a-input>
      </template>
    </form-col>
  </search-panel>
</template>

<script>
// 组件
import {
  Input as AInput,
} from 'ant-design-vue'
import SearchPanel from '@components/SearchPanel'
import FormCol from '@components/FormCol'

// 工具类
import {filterParams} from '@utils/search'
export default {
  name: 'ClientClueSearchPanel',
  data() {
    return {
      form: {
        company: '',
      },
        // 筛选-插槽
        formColDefaultSlot: 'formElements'
    }
  },
  computed: {},
  mounted() {
  },
  methods: {
    /**
     * 响应搜索事件
     */
    handleSearch() {
      const params = this.formatParams()
      this.$emit('search', filterParams(params))
    },
    /**
     * 响应刷新事件
     */
    handleRefresh() {
      const params = this.formatParams()
      this.$emit('refresh', filterParams(params))
    },
    /**
     * 格式化参数
     */
    formatParams() {
      const {form} = this
      let {submitTime, ...params} = form
      let [startTime, endTime] = submitTime || []
      startTime = startTime
          ? `${startTime.format('YYYY-MM-DD')} 00:00:00`
          : ''
      endTime = endTime ? `${endTime.format('YYYY-MM-DD')} 23:59:59` : ''
      params.startTime = startTime
      params.endTime = endTime
      return params
    }
  },
  components: {
    SearchPanel,
    SubmitTimeSection: () => import('@weights/SubmitTimeSection'),
    FormCol,
    AInput
  }
}
</script>

<style lang="scss">
</style>
