<!-- 编辑客户信息-表单 -->
<template>
    <a-spin :spinning="spinning">
        <a-form-model
            ref="ruleForm"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 24 }"
            :model="form"
            :rules="rules"
            class="update-customer-info-modal-form-panel"
        >
            <a-form-model-item label="客户意向" prop="intention">
                <a-select v-model="form.intention" placeholder="请选择客户意向">
                    <template v-for="item in CUSTOMER_INTENTION_ENUM_LIST">
                        <a-select-option :key="item.value" :value="item.label">{{ item.label }}</a-select-option>
                    </template>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="客户阶段" prop="stage">
                <a-select v-model="form.stage" placeholder="请选择客户阶段">
                    <template v-for="item in CUSTOMER_STAGE_TYPE_ENUM_LIST">
                        <a-select-option :key="item.value" :value="item.value">{{ item.label }}</a-select-option>
                    </template>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="姓名" prop="name">
                <a-input
                    v-model.trim="form.name"
                    placeholder="请输入姓名"
                    :max-length="20"
                />
            </a-form-model-item>
            <a-form-model-item label="性别" prop="gender">
                <a-select v-model="form.gender" placeholder="请选择性别">
                    <template v-for="item in SEX_ENUM_LIST">
                        <a-select-option :key="item.value" :value="item.label">{{ item.label }}</a-select-option>
                    </template>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="企业名称" prop="enterpriseName">
                <a-input
                    v-model.trim="form.enterpriseName"
                    placeholder="请输入企业名称"
                    :max-length="50"
                />
            </a-form-model-item>
            <a-form-model-item label="电子邮箱" prop="email">
                <a-input
                    v-model.trim="form.email"
                    placeholder="请输入电子邮箱"
                    :max-length="20"
                />
            </a-form-model-item>
            <a-form-model-item label="所在省市区" prop="selectAreaCode">
                <a-cascader 
                    v-model="selectAreaCode"
                    :options="areaData"
                    placeholder="请选择所在省市区"
                    change-on-select
                    @change="handleChangeArea"
                />
            </a-form-model-item>
            <a-form-model-item label="详细地区" prop="address">
                <a-input
                    v-model.trim="form.address"
                    placeholder="请输入详细地区"
                    :max-length="50"
                />
            </a-form-model-item>
            <a-form-model-item label="备注" prop="description">
                <a-text-area
                    v-model.trim="form.description"
                    placeholder="请输入备注"
                    :auto-size="{minRows: 3}"
                    :max-length="200"
                />
            </a-form-model-item>
        </a-form-model>
    </a-spin>
</template>

<script>
// 组件
import {
    FormModel as AFormModel,
    Input as AInput,
    Select as ASelect,
    Spin as ASpin
} from 'ant-design-vue'

// 枚举
import { SEX_ENUM_LIST, CUSTOMER_INTENTION_ENUM_LIST, SALES_CATEGORY_NOT_TEXT, CUSTOMER_STAGE_TYPE_ENUM_LIST } from '@constant/enum'
import { validateEmailFunc } from '@/utils/validate.js'

// mixins
import selectArea from '@/mixins/selectArea'

// 接口请求
import saveCustomerAgentService from '@service/customer/saveCustomerAgentService'
import getCustomerAgentSelectOneService from '@service/customer/getCustomerAgentSelectOneService'

export default {
    name: 'UpdateCustomerInfoModalFormPanel',
    components: {
        AFormModel,
        AFormModelItem: AFormModel.Item,
        AInput,
        ATextArea: AInput.TextArea,
        ASelect,
        ASelectOption: ASelect.Option,
        ASpin
    },
    mixins: [selectArea],
    props: {
        params: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    data() {
        return {
            SEX_ENUM_LIST,
            CUSTOMER_INTENTION_ENUM_LIST,
            CUSTOMER_STAGE_TYPE_ENUM_LIST,
            form: {
                // 姓名
                name: '',
                // 性别
                gender: undefined,
                // 意向
                intention: undefined,
                // 客户阶段
                stage: undefined,
                // 企业名称
                enterpriseName: '',
                // 电子邮箱
                email: '',
                // 详细地区
                address: '',
                // 备注
                description: ''
            },
            // 所在地区
            selectAreaCode: [],
            // 校验规则
            rules: {
                intention: [
                    {
                        required: true,
                        message: '请选择意向',
                        trigger: 'change'
                    }
                ],
                stage: [
                    {
                        required: true,
                        message: '请选择客户阶段',
                        trigger: 'change'
                    }
                ],
                email: [
                    {
                        required: false,
                    }, {
                        validator: validateEmailFunc,
                        trigger: 'blur'
                    }
                ]
            },
            spinning: true,
            singleId: ''
        }
    },
    computed: {},
    watch: {
        selectAreaCode: {
            handler(value) {
                this.form.provinceCode = value[0] || ''
                this.form.cityCode = value[1] || ''
                this.form.areaCode = value[2] || ''
            },
            immediate: true
        }
    },
    mounted() {
        this.getEcho()
    },
    methods: {
        /**
         * 获取回显信息
         */
        async getEcho() {
            const { params } = this
            const { customerId, agentUserId } = params
            try {
                const data = await getCustomerAgentSelectOneService({
                    customerId,
                    agentUserId,
                    type: SALES_CATEGORY_NOT_TEXT
                }) || {}
                const {
                    id,
                    name,
                    gender,
                    intention,
                    stage,
                    enterpriseName,
                    email,
                    address,
                    description,
                    provinceCode,
                    cityCode,
                    areaCode
                } = data
                this.form = {
                    name,
                    gender: gender || undefined,
                    intention: intention || undefined,
                    stage: stage || undefined,
                    enterpriseName,
                    email,
                    address,
                    description
                }
                this.singleId = id
                if (provinceCode || cityCode || areaCode) {
                    this.selectAreaCode = [provinceCode, cityCode, areaCode]
                }
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.spinning = false
            }
        },
        /**
         * 添加客户
         */
        async handleSaveCustomer() {
            const { params } = this
            const { customerId, agentUserId } = params
            try {
                this.$emit('loading', true)
                const { form, singleId } = this
                await saveCustomerAgentService(
                    {
                        customerId,
                        agentUserId,
                        id: singleId,
                        ...form
                    }
                )
                this.$message.success('提交成功')
                this.getEcho()
                this.$emit('success')
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.$emit('loading', false)
            }
        },
        handleSend() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.handleSaveCustomer()
                }
            })
        },
    }
}
</script>

<style lang="scss">
.update-customer-info-modal-form-panel {
    .ant-form-item {
        display: inline-block;
        margin-bottom: 4px;
        width: 45%;
    }
    .ant-form-item:nth-child(2n-1) {
        margin-right: 10%;
    }
    .ant-form-item-label {
        text-align: left;
        height: 35px;
    }
}
</style>
