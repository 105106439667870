<template>
    <search-panel :model="form" layout="vertical" @search="handleSearch" @refresh="handleRefresh">
        <form-col label="险种" prop="dangerPlantedId">
            <template :slot="formColDefaultSlot">
                <custom-select
                    v-model="form.dangerPlantedId"
                    :options="insuranceList"
                    label-field="name"
                    value-field="id"
                />
            </template>
        </form-col>
        <form-col label="保险公司" prop="insuranceEnterpriseId">
            <template :slot="formColDefaultSlot">
                <custom-select
                    v-model="form.insuranceEnterpriseId"
                    :options="insuranceCompanyList"
                    label-field="name"
                    value-field="id"
                />
            </template>
        </form-col>
        <form-col label="项目地区" prop="address">
            <template :slot="formColDefaultSlot">
                <address-cascader v-model="form.address" @change="handleChangeArea" />
            </template>
        </form-col>
    </search-panel>
</template>

<script>
// 组件
import CustomSelect from '@weights/CustomSelect'
import AddressCascader from '@weights/AddressCascader'

import SearchPanel from '@components/SearchPanel'
import FormCol from '@components/FormCol'

// 工具类
import { filterParams } from '@utils/search.js'

export default {
    name: 'CustomerSearchPanel',
    components: {
        SearchPanel,
        FormCol,
        CustomSelect,
        AddressCascader
    },
    props: {
        // 险种 选项列表
        insuranceList: {
            type: Array,
            default: () => []
        },
        // 保险公司 选项列表
        insuranceCompanyList: {
            type: Array,
            default: () => []
        },
        // 总数
        total: {
            type: [Number, String],
            default: 0
        }
    },
    data() {
        return {
            form: {
                dangerPlantedId: '', // 险种 id (组件自己内部维持的)
                insuranceEnterpriseId: '', // 保险公司 id (组件自己内部维持的)
                address: []
            },
            areaCodeValue: [],
            areaCodeList: [],
            // 筛选-插槽
            formColDefaultSlot: 'formElements'
        }
    },
    mounted() {
        // this.handleSearch()
    },
    methods: {
        /**
         * 响应搜索事件
         */
        handleSearch() {
            const params = this.formatParams()
            this.$emit('search', filterParams(params))
        },
        /**
         * 响应刷新事件
         */
        handleRefresh() {
            this.form = {
                dangerPlantedId: '',
                insuranceEnterpriseId: '',
                address: []
            }
            this.$emit('search', {})
        },
        /**
         * 格式化参数
         */
        formatParams() {
            const { form, areaCodeList } = this

            const { address, ...other } = form
            if (!areaCodeList || !areaCodeList.length) return { ...other }
            const [p, c, a] = areaCodeList
            const addressObj = { provinceCode: p.value }
            if (c ) {
                addressObj.cityCode = c.value
            }
            if (a ) {
                addressObj.areaCode = a.value
            }

            return {
                ...other,
                ...addressObj
            }
        },
        handleChangeArea(value, selectedOptions) {
            this.areaCodeValue = value
            this.areaCodeList = selectedOptions
        }
    }
}
</script>

<style lang="scss">
</style>
