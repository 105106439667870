<template>
    <a-popover trigger="click" placement="topRight" class="previewQRcodePopover">
        <template slot="content">
            <a-spin :spinning="loading">
                <div class="preview-QRCode-popover__content">
                    <img v-show="linkAddressCode" :src="linkAddressCode" alt="" style="display:block; width:100%;height:100%">
                </div>
            </a-spin>
            <div class="preview-QRCode-popover__tip">请用微信扫一扫预览</div>
        </template>
        <a-button v-auth="PROMOTE_GUEST_VIEW_URL_BUTTON" type="link" @click="handleClickPreviewForm">
            预览
        </a-button>
    </a-popover>
</template>

<script>
// 组件
import { Popover, Spin } from 'ant-design-vue'

import {
    PROMOTE_GUEST_VIEW_URL_BUTTON
} from '@constant/authEnum/promoteGuest'

// 请求
import getPromoteFormPreviewFormService from '@service/promoteQuest/getPromoteFormPreviewFormService'

export default {
    name: 'PreviewQRCodePopover',
    components: {
        APopover: Popover,
        ASpin: Spin
    },
    props: {
        record: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            PROMOTE_GUEST_VIEW_URL_BUTTON,
            linkAddressCode: '',
            loading: true
        }
    },
    computed: {
        userInfo() {
            return this.$store.getters.userInfo
        }
    },
    methods: {
        async handleClickPreviewForm() {
            try {
                const { id, name } = this.userInfo
                const record = this.record
                const payload = {
                    id: record.id,
                    agentUserId: id,
                    agentUserName: name
                }
                const data = await getPromoteFormPreviewFormService(payload)
                this.linkAddressCode = data || ''
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.loading = false
            }


        }
    }
}
</script>

<style lang="scss">
.ant-popover-inner-content {
    padding: 0px;
}
.preview-QRCode-popover__content {
  width: 127px;
  height: 127px
}
.preview-QRCode-popover__tip {
    text-align: center;
    padding-bottom: 10px;
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #848B91;
    line-height: 12px;
}
</style>
