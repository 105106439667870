<!-- 业务关联列表 -->
<template>
    <list-page-layout>
        <!-- search -->
        <search-panel slot="header" @search="handleSearch" @refresh="handleRefresh" />
        <container-panel>
            <!-- 标题 start -->
            <template slot="title">
                业务关联列表
            </template>
            <!-- 标题 end -->

            <!-- 操作按钮 start -->
            <template slot="button" slot-scope="">
                <custom-button
                    v-auth="BUSINESS_ASSOCIATION_ADD_BUTTON"
                    text="添加关联"
                    type="default"
                    @click="handleAdd" />
            </template>
            <!-- 操作按钮 end -->

            <!-- 表格 start -->
            <template slot="content" slot-scope="">
                <a-table
                    size="middle"
                    :columns="columns"
                    :data-source="data"
                    :pagination="pagination"
                    :loading="loading"
                    row-key="id"
                    :scroll="scrollConfig"

                    @change="handleTableChange">
                    <!-- 状态 -->
                    <template slot="state" slot-scope="state">
                        <a-switch :checked="state.state === '启用'" @change="handleSwitch($event, state)" />
                    </template>
                    <!-- 操作 -->
                    <template slot="action" slot-scope="action">
                        <a-button
                            v-auth="BUSINESS_ASSOCIATION_EDIT_BUTTON"
                            type="link"
                            @click="handleEdit(action)">
                            编辑
                        </a-button>
                        <a-button
                            v-auth="BUSINESS_ASSOCIATION_DELETE_BUTTON"
                            type="link"
                            @click="handleDelete(action)">
                            删除
                        </a-button>
                    </template>
                </a-table>
            </template>
            <!-- 表格 end -->
        </container-panel>
        <create-or-update-association-modal
            v-if="visible"
            :visible="visible"
            :mode="mode"
            :data="currentRow"
            @close="handleModalClose"
            @success="handleModalSuccess"
        />
    </list-page-layout>
</template>


<script>
// 组件
import {
    Button as AButton,
    Table as ATable,
    Switch as ASwitch
} from 'ant-design-vue'
import ListPageLayout from '@layout/ListPageLayout'
import ContainerPanel from '@components/ContainerPanel'
import CustomButton from '@components/Button'
import SearchPanel from './SearchPanel'
import CreateOrUpdateAssociationModal from './CreateOrUpdateAssociationModal'

// 全局变量
import { DEFAULT_PAGES_SIZE } from '@config'
import {
    BUSINESS_ASSOCIATION_ADD_BUTTON,
    BUSINESS_ASSOCIATION_EDIT_BUTTON,
    BUSINESS_ASSOCIATION_DELETE_BUTTON
} from '@/constant/authEnum/steward'
import { scrollConfig } from '@constant/listScrollConfig'

// mixins
import tableShowTotal from '@mixins/tableShowTotal'
import enumOperate from '@mixins/enumOperate'

// 接口请求
import getAssociationListService from '@service/steward/businessAssociation/getAssociationListService'
import associationDeleteService from '@service/steward/businessAssociation/associationDeleteService'
import associationUpdateService from '@service/steward/businessAssociation/associationUpdateService'

const columns = [
    {
        title: '序号',
        dataIndex: 'id',
        key: 'id'
    },
    {
        title: '业务助理',
        dataIndex: 'assistantRealName',
        key: 'assistantRealName'
    },
    {
        title: '手机号',
        dataIndex: 'assistantPhone',
        key: 'assistantPhone'
    },
    {
        title: '业务关联账号',
        dataIndex: 'housekeeperPhone',
        key: 'housekeeperPhone'
    },
    {
        title: '关联类型',
        key: 'type',
        dataIndex: 'type'
    },
    {
        title: '状态',
        key: 'state',
        scopedSlots: { customRender: 'state' }
    },
    {
        title: '创建日期',
        key: 'createDateTime',
        dataIndex: 'createDateTime'
    },
    {
        title: '操作',
        key: 'action',
        scopedSlots: { customRender: 'action' }
    }
]

export default {
    name: 'AssociationList',
    components: {
        ListPageLayout,
        SearchPanel,
        ContainerPanel,
        AButton,
        ATable,
        CustomButton,
        ASwitch,
        CreateOrUpdateAssociationModal
    },
    mixins: [tableShowTotal, enumOperate],
    data() {
        return {
            BUSINESS_ASSOCIATION_ADD_BUTTON, // 添加
            BUSINESS_ASSOCIATION_EDIT_BUTTON, // 编辑
            BUSINESS_ASSOCIATION_DELETE_BUTTON, // 删除
            // table 列配置
            columns,
            // 查询条件
            params: {},
            // 数据
            data: [],
            // 分页数量
            limit: DEFAULT_PAGES_SIZE,
            // 总记录数量
            total: 0,
            // 当前索引
            current: 1,
            // 加载状态
            loading: false,
            // 当前选中的行数据
            currentRow: {},
            // 模态框相关参数
            visible: false,
            // 判断是添加还是编辑
            mode: '',
            scrollConfig
        }
    },
    computed: {
        pagination() {
            const {
                total,
                limit,
                current,
                tableShowTotal
            } = this
            return {
                showTotal: (total, range) => tableShowTotal(total, range),
                current,
                total,
                pageSize: limit,
                showSizeChanger: true,
                showQuickJumper: true
            }
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        /**
             * 获取 列表
             */
        async getList() {
            try {
                this.loading = true
                const { params, current, limit } = this
                const { total, records } = await getAssociationListService({
                    current,
                    size: limit,
                    ...params
                })
                this.total = total || 0
                this.data = this.getEmpty(records, '-') || []
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.loading = false
            }
        },
        /**
             * 状态启用操作
             */
        async handleSwitch(e, currRow) {
            const { id } = currRow
            let state = e ? '启用' : '不启用'
            let message = e ? '已启用' : '已禁用'
            try {
                await associationUpdateService({
                    id,
                    state
                })
                this.$message.success(message)
                this.getList()
            } catch (e) {
                this.$message.error(e.message)
            }
        },
        /**
             * 弹框关闭
             */
        handleModalClose() {
            this.visible = false
        },
        handleModalSuccess() {
            this.handleModalClose()
            this.getList()
        },
        /**
             * 添加
             */
        handleAdd() {
            this.mode = 'create'
            this.visible = true
            this.currentRow = {}
        },
        /**
             * 编辑
             */
        handleEdit(action) {
            this.mode = 'edit'
            this.currentRow = action
            this.visible = true
        },
        /**
             * 删除
             */
        handleDelete(action) {
            this.$confirm({
                title: '选中数据将被永久删除, 是否继续？',
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk: () => {
                    this.deleteAssociation(action)
                },
                onCancel() {
                }
            })
        },
        async deleteAssociation(action) {
            try {
                this.loading = true
                await associationDeleteService({ id: action.id })
                this.$message.success('删除成功')
                this.getList()
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.loading = false
            }
        },
        /**
             * 响应搜索事件
             */
        handleSearch(params) {
            this.current = 1
            this.params = params
            this.getList()
        },
        /**
             * 响应刷新事件
             */
        handleRefresh(params) {
            this.handleSearch(params)
        },
        /**
             * 响应 table change 事件
             * @param pagination
             */
        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.current = current
            this.limit = pageSize
            this.getList()
        }
    }
}
</script>

<style lang='scss'>
</style>
