<template>
  <a-locale-provider :locale="zh_CN">
    <div id="app">
      <router-view/>
    </div>
  </a-locale-provider>

</template>
<script>
import {ConfigProvider as ALocaleProvider} from 'ant-design-vue'
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')

export default {
  name: 'App',
  data() {
    return {
      zh_CN
    }
  },
  components:{
    ALocaleProvider
  }
}
</script>

