<!-- 保单记录-详情-线上保单 -->
<template>
    <drawer-panel
        class="policy-record-detail"
        title="保单详情"
        :visible="visible"
        :loading="loading"
        @after-visible-change="afterVisibleChange"
        @close="handleClose"
    >
        <!-- 状态 start -->
        <template #top>
            <status-panel>
                <template #logo>
                    <img :src="imgSrc" alt="">
                </template>
                <template #content>
                    <span>当前保单状态：{{ getApiEnumLabelFromList(POLICY_ORDER_STATUS_ENUM_LIST, record && record.crmInsuranceStatus) }}</span>
                </template>
            </status-panel>
        </template>
        <!-- 状态 end -->

        <template #content>
            <!-- 基本信息 start -->
            <field-list title="基本信息" :data="baseInfo">
                <template #tag>
                    <span class="policy-record-detail__tag">B端线上保单</span>
                </template>
            </field-list>
            <!-- 基本信息 end -->

            <!-- 保障内容 start -->
            <field-list title="保障内容" :data="coverageInfo">
                <!--                <template #opts> -->
                <!--                    <a-button -->
                <!--                        v-auth="viewPolicy" -->
                <!--                        v-if="record && record.insuranceSingleUrl" -->
                <!--                        @click="handlePreview(record && record.insuranceSingleUrl)" -->
                <!--                    >查看电子保单</a-button> -->
                <!--                    <a-button v-else disabled icon="clock-circle">保单生成中...</a-button> -->
                <!--                </template> -->
            </field-list>
            <!-- 保障内容 end -->

            <!-- 项目信息 start -->
            <field-list v-if="projectText && projectText.length > 0" title="项目信息" :data="projectText" />
            <!-- 项目信息 end -->

            <!-- 投保人 start -->
            <field-list v-if="policyHolderText && policyHolderText.length > 0" title="投保人" :data="policyHolderText" />
            <!-- 投保人 end -->

            <!-- 被保人 start -->
            <field-list v-if="insuredText && insuredText.length > 0" title="被保人" :data="insuredText" />
            <!-- 被保人 end -->
        </template>

        <div class="policy-record-detail__detail-btns">
            <a-button
                v-auth="downloadCastInsurance"
                :loading="castLoading"
                @click="handleDownInsurance(CAST_PARAMS)"
            >
                下载投保单
            </a-button>

            <a-button
                v-auth="downloadCounterGuarantee"
                :loading="guaranteeLoading"
                @click="handleDownInsurance(GUARANTEE_PARAMS)"
            >
                下载反担保
            </a-button>
            <a-button
                v-auth="viewPolicy"
                @click="handleView('insuranceSingleUrl')"
            >
                查看保单
            </a-button>
            <a-button
                v-if="record && record.letterGuaranteeAddress"
                v-auth="viewProof"
                @click="handleView('letterGuaranteeAddress')"
            >
                查看保险凭证
            </a-button>
            <a-button
                v-auth="viewInvoice"
                @click="handleView('invoiceAddress')"
            >
                查看发票
            </a-button>
        </div>

        <view-modal :visible="viewVisible" :type="viewType" :status="0" @success="handleSuccess" />
    </drawer-panel>
</template>

<script>
// 组件
import ViewModal from './ViewModal'

// 常量
import {
    POLICY_ORDER_STATUS_INSURANCE,
    POLICY_ORDER_STATUS_RENEWED,
    POLICY_ORDER_STATUS_ENUM_LIST,
    // POLICY_ORDER_STATUS_OVERDUE,
    POLICY_ORDER_STATUS_SURRENDER,
    // POLICY_ORDER_STATUS_REFUSAL,
    ORDER_SOURCE_ENUM_LIST,
    PRESERVATION_STATE_MENU_LIST,
    SALES_CATEGORY_DISTRIBUTION,
    SALES_CATEGORY_SELF_SUPPORT,
    CUSTOMER_POLICY_BUSINESS_TYPE_WORKSPACE,
    CUSTOMER_POLICY_BUSINESS_TYPE_TEAM,
    CUSTOMER_POLICY_BUSINESS_TYPE_ONLINE,
    CAST_PARAMS,
    GUARANTEE_PARAMS
} from '@/constant/enum'
import {
    DISTRIBUTION_CUSTOMER_VIEW_POLICY_BUTTON,
    DISTRIBUTION_CUSTOMER_ELECTRONIC_INVOICE_BUTTON,
    DISTRIBUTION_CUSTOMER_VIEW_PROOF_BUTTON,
    DISTRIBUTION_CUSTOMER_DOWNLOAD_CAST_INSURANCE_BUTTON,
    DISTRIBUTION_CUSTOMER_DOWNLOAD_COUNTER_GUARANTEE_BUTTON
} from '@constant/authEnum/distributionCustomer'
import {
    PROPRIETARY_CUSTOMER_VIEW_POLICY_BUTTON,
    PROPRIETARY_CUSTOMER_ELECTRONIC_INVOICE_BUTTON,
    PROPRIETARY_CUSTOMER_VIEW_PROOF_BUTTON,
    PROPRIETARY_CUSTOMER_DOWNLOAD_CAST_INSURANCE_BUTTON,
    PROPRIETARY_CUSTOMER_DOWNLOAD_COUNTER_GUARANTEE_BUTTON
} from '@constant/authEnum/proprietaryCustomer'
import {
    ONLINE_POLICY_VIEW_POLICY_BUTTON,
    ONLINE_POLICY_VIEW_PROOF_BUTTON,
    ONLINE_POLICY_VIEW_ELECTRONIC_INVOICE_BUTTON,
    ONLINE_POLICY_DOWNLOAD_CAST_INSURANCE_BUTTON,
    ONLINE_POLICY_DOWNLOAD_COUNTER_GUARANTEE_BUTTON
} from '@constant/authEnum/policy'

// mixins
import common from '../mixins'
import { previewFiles } from '@/utils/previewFiles'


// 接口请求
import getCastManageInsuranceDetailService from '@service/customer/getCastManageInsuranceDetailService'
import downCustInsuranceService from '@service/customer/downCustInsuranceService'
import downCounterGuaranteeService from '@service/customer/downCounterGuaranteeService'


export default {
    name: 'PolicyRecordDetail',
    components: { ViewModal },
    mixins: [common],
    props: {
        castId: {
            type: [Number, String],
            default() {
                return ''
            }
        },
        visible: {
            type: Boolean,
            default() {
                return false
            }
        },
        // 同businessType
        type: {
            type: [Number, String],
            default() {
                return ''
            }
        },
        // 客户id
        customerId: {
            type: [Number, String],
            default() {
                return ''
            }
        },
        // 经纪人id
        agentUserId: {
            type: [Number, String],
            default() {
                return ''
            }
        }
    },
    data() {
        return {
            POLICY_ORDER_STATUS_INSURANCE,
            POLICY_ORDER_STATUS_RENEWED,
            POLICY_ORDER_STATUS_ENUM_LIST,
            // POLICY_ORDER_STATUS_OVERDUE,
            POLICY_ORDER_STATUS_SURRENDER,
            // POLICY_ORDER_STATUS_REFUSAL,
            ORDER_SOURCE_ENUM_LIST,
            PRESERVATION_STATE_MENU_LIST,
            SALES_CATEGORY_DISTRIBUTION,
            SALES_CATEGORY_SELF_SUPPORT,
            CUSTOMER_POLICY_BUSINESS_TYPE_WORKSPACE,
            CUSTOMER_POLICY_BUSINESS_TYPE_TEAM,
            CUSTOMER_POLICY_BUSINESS_TYPE_ONLINE,
            DISTRIBUTION_CUSTOMER_VIEW_POLICY_BUTTON,
            DISTRIBUTION_CUSTOMER_ELECTRONIC_INVOICE_BUTTON,
            DISTRIBUTION_CUSTOMER_VIEW_PROOF_BUTTON,
            DISTRIBUTION_CUSTOMER_DOWNLOAD_CAST_INSURANCE_BUTTON,
            DISTRIBUTION_CUSTOMER_DOWNLOAD_COUNTER_GUARANTEE_BUTTON,
            PROPRIETARY_CUSTOMER_VIEW_POLICY_BUTTON,
            PROPRIETARY_CUSTOMER_ELECTRONIC_INVOICE_BUTTON,
            PROPRIETARY_CUSTOMER_VIEW_PROOF_BUTTON,
            PROPRIETARY_CUSTOMER_DOWNLOAD_CAST_INSURANCE_BUTTON,
            PROPRIETARY_CUSTOMER_DOWNLOAD_COUNTER_GUARANTEE_BUTTON,
            ONLINE_POLICY_VIEW_POLICY_BUTTON,
            ONLINE_POLICY_VIEW_PROOF_BUTTON,
            ONLINE_POLICY_VIEW_ELECTRONIC_INVOICE_BUTTON,
            ONLINE_POLICY_DOWNLOAD_CAST_INSURANCE_BUTTON,
            ONLINE_POLICY_DOWNLOAD_COUNTER_GUARANTEE_BUTTON,
            // 投保单
            CAST_PARAMS,
            // 反担保
            GUARANTEE_PARAMS,
            loading: false,
            // 单条
            record: {},
            imgSrc: require('@/assets/images/waiting.png'),
            // 基本信息
            baseInfo: [],
            // 保障内容
            coverageInfo: [],
            // 项目信息
            projectText: [],
            // 投保人信息
            policyHolderText: [],
            // 被保人信息
            insuredText: [],
            // 查看电子保单-按钮权限
            viewPolicy: '',
            // 查看保单凭证-按钮权限
            viewProof: '',
            // 查看发票-按钮权限
            viewInvoice: '',
            // 下载投保单-按钮权限
            downloadCastInsurance: '',
            // 下载反担保-按钮权限
            downloadCounterGuarantee: '',
            // 查看弹框
            viewVisible: false,
            // 查看类型
            viewType: '',
            // 投保单下载按钮加载状态
            castLoading: false,
            // 反担保下载按钮加载状态
            guaranteeLoading: false
        }
    },
    watch: {
        // 查看电子保单-按钮权限
        businessType: {
            handler(businessType) {
                // 分销
                if (businessType === SALES_CATEGORY_DISTRIBUTION) {
                    this.viewPolicy = DISTRIBUTION_CUSTOMER_VIEW_POLICY_BUTTON
                    this.viewProof = DISTRIBUTION_CUSTOMER_VIEW_PROOF_BUTTON
                    this.viewInvoice = DISTRIBUTION_CUSTOMER_ELECTRONIC_INVOICE_BUTTON
                    this.downloadCastInsurance = DISTRIBUTION_CUSTOMER_DOWNLOAD_CAST_INSURANCE_BUTTON
                    this.downloadCounterGuarantee = DISTRIBUTION_CUSTOMER_DOWNLOAD_COUNTER_GUARANTEE_BUTTON
                } else if (businessType === SALES_CATEGORY_SELF_SUPPORT) {
                    // 自营
                    this.viewPolicy = PROPRIETARY_CUSTOMER_VIEW_POLICY_BUTTON
                    this.viewProof = PROPRIETARY_CUSTOMER_VIEW_PROOF_BUTTON
                    this.viewInvoice = PROPRIETARY_CUSTOMER_ELECTRONIC_INVOICE_BUTTON
                    this.downloadCastInsurance = PROPRIETARY_CUSTOMER_DOWNLOAD_CAST_INSURANCE_BUTTON
                    this.downloadCounterGuarantee = PROPRIETARY_CUSTOMER_DOWNLOAD_COUNTER_GUARANTEE_BUTTON
                } else if (businessType === CUSTOMER_POLICY_BUSINESS_TYPE_WORKSPACE || businessType === CUSTOMER_POLICY_BUSINESS_TYPE_TEAM) {
                    // 工作台/团队
                    this.viewPolicy = DISTRIBUTION_CUSTOMER_VIEW_POLICY_BUTTON || PROPRIETARY_CUSTOMER_VIEW_POLICY_BUTTON
                    this.viewProof = DISTRIBUTION_CUSTOMER_VIEW_PROOF_BUTTON || PROPRIETARY_CUSTOMER_VIEW_PROOF_BUTTON
                    this.viewInvoice = DISTRIBUTION_CUSTOMER_ELECTRONIC_INVOICE_BUTTON || PROPRIETARY_CUSTOMER_ELECTRONIC_INVOICE_BUTTON
                    this.downloadCastInsurance = ONLINE_POLICY_DOWNLOAD_CAST_INSURANCE_BUTTON
                    this.downloadCounterGuarantee = ONLINE_POLICY_DOWNLOAD_COUNTER_GUARANTEE_BUTTON
                }
            },
            immediate: true
        },
        // 查看电子保单-按钮权限
        type: {
            handler(type) {
                // 线上保单
                if (type === CUSTOMER_POLICY_BUSINESS_TYPE_ONLINE) {
                    this.viewPolicy = ONLINE_POLICY_VIEW_POLICY_BUTTON
                    this.viewProof = ONLINE_POLICY_VIEW_PROOF_BUTTON
                    this.viewInvoice = ONLINE_POLICY_VIEW_ELECTRONIC_INVOICE_BUTTON
                    this.downloadCastInsurance = ONLINE_POLICY_DOWNLOAD_CAST_INSURANCE_BUTTON
                    this.downloadCounterGuarantee = ONLINE_POLICY_DOWNLOAD_COUNTER_GUARANTEE_BUTTON
                }
            },
            immediate: true
        }
    },
    methods: {
        /**
             * 初始化
             */
        async init() {
            // 获取单条
            await this.getRecord()

            // 数据初始化
            await this.initData()
        },
        /**
             * 获取单条
             */
        async getRecord() {
            const { castId } = this
            if (!castId) return

            try {
                this.loading = true
                const result = await getCastManageInsuranceDetailService({ castId })
                this.record = result || {}
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        },
         /**
         * 获取下载投保单填充后模板
         */
        async getCastInsuranceAddress() {
            const { castId } = this
            try {
                const data = await downCustInsuranceService({
                    custInsuranceId: castId
                })
                this.handleDown(data)
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.castLoading = false
            }
        },
        /**
         * 获取下载反担保填充后模板
         */
        async getCounterGuaranteeAddress() {
            const { castId } = this
            try {
                const data = await downCounterGuaranteeService({
                    custInsuranceId: castId
                })
                this.handleDown(data)
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.guaranteeLoading = false
            }
        },

        /**
             * 数据初始化
             */
        async initData() {
            const { record } = this
            const {
                policyNo,
                castInsuranceId,
                source,
                signingTime,
                castInsuranceUserName,
                castInsuranceUserId,
                agentUserName,
                agentUserId,
                businessAssistantName,
                businessAssistantId,
                refundModifyDateTime,
                refundMoney,
                refundTime,
                refundState,
                spuName,
                dangerPlantedName,
                insuranceCompany,
                insuranceMoney,
                insuranceEnterpriseInstitutionsName,
                payMoney,
                startInsuranceTime,
                endInsuranceTime,
                contentNewVo
            } = record || {}
            const {
                proEnterpriseText,
                castEnterpriseText,
                insuredEnterpriseText
            } = contentNewVo || {}

            // 0. 状态icon
            this.handelImgSrc()

            // 1. 基本信息
            // 投保用户超链接
            const insuredUserLink = await this.handleInsuredUser('投保用户', castInsuranceUserId, castInsuranceUserName)
            this.baseInfo = [
                {
                    key: '保单号',
                    value: policyNo
                },
                {
                    key: '渠道',
                    value: this.empty(this.getApiEnumLabelFromList(ORDER_SOURCE_ENUM_LIST, source))
                },
                {
                    key: '签单日期',
                    value: signingTime
                },
                insuredUserLink,
                {
                    key: '成交经纪人',
                    value: `${agentUserName ? agentUserName : '-'}${agentUserId ? '(' + agentUserId + ')' : ''}`
                },
                {
                    key: '业务助理',
                    value: `${businessAssistantName ? businessAssistantName : '-'}${businessAssistantId ? '(' + businessAssistantId + ')' : ''}`
                },
                {
                    key: '退保操作日期',
                    value: refundModifyDateTime
                },
                {
                    key: '退保金额',
                    value: refundMoney
                },
                {
                    key: '退保日期',
                    value: refundTime
                },
                {
                    key: '退保状态',
                    value: this.empty(this.getApiEnumLabelFromList(PRESERVATION_STATE_MENU_LIST, refundState))
                },
                {
                    key: '投保单号',
                    value: castInsuranceId,
                    link: true,
                    linkBusiness: 'OrderRecordDetail',
                    linkParams: { castId: castInsuranceId }
                }
            ]

            // 2. 保障内容
            this.coverageInfo = [
                {
                    key: '产品名称',
                    value: spuName
                },
                {
                    key: '险种',
                    value: dangerPlantedName
                },
                {
                    key: '保险公司',
                    value: insuranceCompany
                },
                {
                    key: '保险金额（保函金额）元',
                    value: insuranceMoney
                },
                {
                    key: '分支机构',
                    value: insuranceEnterpriseInstitutionsName
                },
                {
                    key: '保费（元）',
                    value: payMoney
                },
                {
                    key: '保险期间',
                    value: this.empty(this.handleRangeTime(startInsuranceTime, endInsuranceTime))
                }
            ]

            // 3. 项目信息
            this.projectText = proEnterpriseText || []

            // 4. 投保人信息
            this.policyHolderText = castEnterpriseText || []

            // 5.被保人信息
            this.insuredText = insuredEnterpriseText || []
        },
        /**
             * 状态icon
             */
        handelImgSrc() {
            // const { insuranceStatus } = this.record || {}
            const { crmInsuranceStatus } = this.record || {}

            // const errorList = [
            //     POLICY_ORDER_STATUS_OVERDUE,
            //     POLICY_ORDER_STATUS_SURRENDER,
            //     POLICY_ORDER_STATUS_REFUSAL
            // ]
            const errorList = [
                POLICY_ORDER_STATUS_SURRENDER
            ]
            const insuranceIngList = [
                POLICY_ORDER_STATUS_INSURANCE
            ]
            const insuranceRenewList = [
                POLICY_ORDER_STATUS_RENEWED
            ]

            // this.imgSrc = this.handleStateIcon(insuranceStatus, errorList)
            this.imgSrc = this.handleStateIconOnline(crmInsuranceStatus, errorList, null, insuranceIngList, insuranceRenewList)

        },
        /**
             * 查看
             */
        handleView(type) {
            const {
                insuranceSingleUrl,
                letterGuaranteeAddress,
                invoiceAddress
            } = this.record || {}

            // 电子保单
            if (type === 'insuranceSingleUrl') {
                if (insuranceSingleUrl) {
                    window.open(insuranceSingleUrl)
                } else {
                    this.viewType = 1
                    this.viewVisible = true
                }
            } else if (type === 'letterGuaranteeAddress') {
                // 凭证
                if (letterGuaranteeAddress) {
                    window.open(letterGuaranteeAddress)
                }
            } else if (type === 'invoiceAddress') {
                // 发票
                if (invoiceAddress) {
                    window.open(invoiceAddress)
                } else {
                    this.viewType = 6
                    this.viewVisible = true
                }
            }
        },

        /**
         * 下载投保单/反担保模板
         */
        handleDownInsurance(code) {
            if (code === CAST_PARAMS) {
                this.castLoading = true
                this.getCastInsuranceAddress()
            } else {
                this.guaranteeLoading = true
                this.getCounterGuaranteeAddress()
            }
        },

        /**
         * 下载文件
         */
        handleDown(val) {
            if (val) {
                let type = this.formatterFileType(val)
                previewFiles(type, val)
            } else {
                this.$message.error('下载地址错误')
            }
        },


        handleSuccess() {
            this.viewVisible = false
        }
    }
}
</script>

<style lang="scss">
    .policy-record-detail {
        &__tag {
            display: inline-block;
            height: 28px;
            line-height: 28px;
            margin-left: 11px;
            padding:0 10px;
            background: #F2FBFF;
            border-radius: 14px;
            color: #1079C5;
            text-align: center;
        }
        &__detail-btns {
            padding: 12px 24px;
            text-align: right;
            button {
                margin-left: 8px;
                border-color: #1877F2;
                color: #1877F2;
            }
        }
    }
</style>


