<template>
  <div v-if="false" class="gongbao-footer">
    <div class="gongbao-footer__technicalSuppor">Copyright © www.cngongbao.com, All Rights Reserved.</div>
    <div class="gongbao-footer__copyright">工保科技（浙江）有限公司</div>
  </div>
</template>

<script>
export default {
  name: "GlobalFooter",
  computed: {
    systemName(){
       return this.$store.getters.systemName
    },
    copyright() {
      return this.$store.getters.copyright
    },
    licenseNumber() {
      return this.$store.getters.licenseNumber
    }
  }
}
</script>

<style lang="scss">
@import "@assets/styles/varibles.scss";

.gongbao-footer {
  padding: $space-col-base $space-row-base;
  text-align: center;
  color: #999;
  background: $app-background-color;
  &__technicalSuppor {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #D0CFCF;
    line-height: 20px;
    text-align: center;
    padding-top: 10px;
  }

  &__copyright {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #D0CFCF;
    line-height: 20px;
    text-align: center;

    span {
      margin-right: 12px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>