<template>
    <div class="login-page">
        <section class="login-page__content" :style="bacImage">
            <div class="container-wrap">
                <div class="container">
                    <div class="head">用户登录</div>
                    <div class="content-padding">
                        <a-form
                            ref="form"
                            class="login-form"
                            :form="form"
                            @submit="handleSubmit"
                        >
                            <a-alert
                                v-if="errorMessage"
                                class="login-page__error-box"
                                type="error"
                                show-icon
                                :message="errorMessage"
                            />
                            <a-form-item>
                                <a-input
                                    v-decorator="[
                                        'phone',
                                        {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请输入手机号'
                                                },
                                                ,
                                                {
                                                    validator:
                                                        validatePhoneNumber,
                                                    trigger: 'blur'
                                                }
                                            ],
                                            validateTrigger: 'blur'
                                        }
                                    ]"
                                    size="large"
                                    placeholder="请输入手机号"
                                    :max-length="11"
                                >
                                    <div
                                        slot="prefix"
                                        :style="{ color: 'rgba(0,0,0,.25)' }"
                                    >
                                        <img
                                            width="20px"
                                            src="@assets/images/icon/phone.png"
                                            alt=""
                                            srcset=""
                                        >
                                    </div>
                                </a-input>
                            </a-form-item>
                            <a-form-item>
                                <a-input-password
                                    v-decorator="[
                                        'password',
                                        {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请输入登录密码'
                                                }
                                            ],
                                            validateTrigger: 'blur'
                                        }
                                    ]"
                                    size="large"
                                    placeholder="请输入登录密码"
                                >
                                    <div
                                        slot="prefix"
                                        :style="{ color: 'rgba(0,0,0,.25)' }"
                                    >
                                        <img
                                            width="20px"
                                            src="@/assets/images/icon/password.png"
                                            alt=""
                                            srcset=""
                                        >
                                    </div>
                                </a-input-password>
                            </a-form-item>
                            <a-form-item>
                                <a-button
                                    class="submit-button"
                                    size="large"
                                    type="primary"
                                    html-type="submit"
                                    :loading="loading"
                                    :disabled="loading"
                                >
                                    登录
                                </a-button>
                            </a-form-item>
                        </a-form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import {
    Form as AForm,
    Input as AInput,
    Button as AButton,
    Alert as AAlert
} from 'ant-design-vue'

import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@/constant/error'

import { DEFAULT_REDIRECT_PATH, YUNFU_BUILD_TYPE } from '@config'

// 工具类
import { validatePhoneNumber } from '@/utils/validate'
import { setCorporationChannelId, getCorporationChannelId } from '@utils/corporationChannelId'


export default {
    name: 'LoginView',
    components: {
        AForm,
        AInput,
        AButton,
        AInputPassword: AInput.Password,
        AFormItem: AForm.Item,
        AAlert
    },
    data() {
        return {
            errorMessage: '',
            form: this.$form.createForm(this),
            loading: false,
            validatePhoneNumber,
            YUNFU_BUILD_TYPE,
            defaultLoginBg: require('@assets/images/login_bg_default.jpg')
        }
    },
    computed: {
        // 跳转路径
        redirect() {
            const route = this.$route
            return route.query && route.query.redirect
                ? route.query.redirect
                : DEFAULT_REDIRECT_PATH
        },
        systemName() {
            return this.$store.getters.systemName || ''
        },
        bacImage() {
            const bgUrl =
            this.$store.getters.loginBgImgLogoImgInfo &&
            this.$store.getters.loginBgImgLogoImgInfo.logInAddress ?
                { background: `#69aae1 url(${this.$store.getters.loginBgImgLogoImgInfo.logInAddress}) no-repeat center / 1920px 100%` } :
                { background: `#69aae1 url(${this.defaultLoginBg}) no-repeat center / 1920px 100%` }
            return { ...bgUrl }
        },
        channelId() {
            return this.$route.query.corporationChannelId
        }
    },
    watch: {
        channelId: {
            handler(val) {
                if (val) {
                    // 将此渠道id存入缓存
                    setCorporationChannelId(val)
                    this.$store.dispatch('getCorporationChannelInfo', { id: val })
                } else {
                    // 去缓存里取，如果取到了，也去获取登录背景图
                    const corporationChannelId = getCorporationChannelId()
                    if (corporationChannelId) {
                        this.$store.dispatch('getCorporationChannelInfo', { id: corporationChannelId })
                    }
                }
            },
            immediate: true
        }
    },
    methods: {
        handleSubmit(e) {
            e.preventDefault()
            const {
                form: { validateFields },
                redirect
            } = this
            this.loading = true
            validateFields(async (err, values) => {
                if (!err) {
                    try {
                        await this.$store.dispatch('loginByPhone', values)
                        // this.$router.replace({path: redirect})
                        this.$message.success('登陆成功！')
                        this.$router.replace({ path: '/' }, () => {})
                    } catch (e) {
                        this.errorMessage =
                            e.message || ERROR_DEFAULT_HTTP_REQUEST_FAIL
                    } finally {
                        this.loading = false
                    }
                } else {
                    this.loading = false
                }
            })
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles';

.login-page {
    height: 100%;

    &__error-box {
        margin-bottom: $padding;
    }

    header {
        display: flex;
        justify-content: center;
        background: #fff;
        border-bottom: 2px solid #1571e8;
    }

    &__head {
        display: flex;
        align-items: center;
        width: 1200px;
        height: 70px;

        .logo-img {
            margin-right: 10px;

            img {
                width: 143px;
                // height: 32px;
            }
        }

        .text {
            font-size: 20px;
            font-weight: 400;
            line-height: 18px;
            color: #323233;
            letter-spacing: 1px;
        }

        .dialog {
            position: relative;
            display: inline-flex;
            align-items: center;
            padding: 0 5px;
            margin-left: 8px;
            line-height: 22px;
            background: linear-gradient(100deg, #00b0fe 0%, #74c4cf 100%);
            border-radius: 2px;
        }

        .dialog span {
            font-family: PingFangSC-Medium, 'PingFang SC';
            font-size: $font-size;
            font-weight: 500;
            line-height: 22px;
            color: #fff;
            letter-spacing: 1px;
        }

        .dialog::before {
            position: absolute;
            top: 6px;
            left: -10px;
            content: '';
            border: 5px solid;
            border-color: transparent #00b0fe transparent transparent;
        }
    }

    &__content {
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
        .container-wrap {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 1200px;

            .container {
                width: 400px;
                min-height: 400px;
                margin-top: -80px;
                background: #fff;
                border-radius: 8px;
                box-shadow: 0 0 30px 0 rgb(71 71 71 / 4%);

                .head {
                    height: 76px;
                    padding: 32px 0 0 30px;
                    font-size: 20px;
                    font-weight: bold;
                    color: #333;
                    background: #fff;
                    border-bottom: 1px solid #f1f2f4;
                    border-radius: 8px 8px 0 0;
                    box-shadow: 0 0 30px 0 rgb(71 71 71 / 4%);
                }

                .content-padding {
                    padding: 40px 30px;
                }

                .ant-input-prefix {
                    left: 16px;
                }

                .ant-input-lg {
                    height: 56px;
                    padding-left: 44px !important;
                    font-size: 14px;
                    background: #f7f8fa;
                    border: 1px solid #ededed;
                    border-radius: 4px;
                    box-shadow: 0 0 30px 0 rgb(71 71 71 / 4%);
                }

                .submit-button {
                    width: 100%;
                    height: 56px;
                    margin-top: 15px;
                    background: linear-gradient(
                        225deg,
                        #5d9ff5 0%,
                        #1877f2 100%
                    );
                    border-radius: 2px;
                    box-shadow: 0 0 30px 0 rgb(71 71 71 / 4%),
                        0 3px 6px 0 rgb(87 156 245 / 30%);
                }
            }
        }
    }

    &__footer {
        margin: 24px 0 55px;
        font-size: 12px;
        line-height: 10px;
        color: #969799;
        text-align: center;
    }
}
</style>
