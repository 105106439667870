<script>
import {
    Col as ACol,
    FormModel as AFormModel,
    Input as AInput,
    Button as AButton
} from 'ant-design-vue'

export default {
    name: 'CustomButton',
    components: {
        ACol,
        AFormModelItem: AFormModel.Item,
        AInput,
        AButton
    },
    props: {
        // span
        span: {
            type: Number,
            required: false,
            default: 6
        },
        // placeholder
        placeholder: {
            type: String,
            required: false,
            default: ''
        },
        // offset
        offset: {
            type: Number,
            required: false,
            default: 0
        }
    },
    computed: {
        props() {
            const { $props } = this
            const { ...props } = $props
            return props
        }
    },
    methods: {},
    render(h) {
        return h('a-col', { props: { span: this.span || 6, offset: this.offset }, class: 'a-col-custom'}, [h('a-form-model-item', { attrs: this.$attrs }, [this.$slots.formElements])])
    }
}
</script>

<style lang="scss">
    .a-col-custom {
        padding: 3px 8px !important;
    }
</style>
