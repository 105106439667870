import { BASE_URL } from '@config'

/** 客户跟进
 * ————————————————————————————————————————————————————————————————————————————————————————————*/
/**
 * 客户跟进-列表
 *
 */
export const GET_DISTRIBUTION_CUSTOMER_LIST = {
    url: `${BASE_URL}/customer/customer-agent/selectDistributionList`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 客户跟进-添加
 *
 */
export const DISTRIBUTION_CUSTOMER_SAVE = {
    url: `${BASE_URL}/customer/customer/saveDistributionCustomer`,
    method: 'post',
    headers: { 'content-type': 'application/json' },
    timeout: 30000
}


/**
 * 客户跟进-批量添加导入
 *
 */
export const DISTRIBUTION_CUSTOMER_IMPORT = {
    url: `${BASE_URL}/customer/customer/importDistributionCustomer`,
    method: 'post',
    headers: { 'content-type': 'application/json' },
    timeout: 30000
}
