/**
 * 工作台 模块
 */
import BasicLayout from '@layout/BasicLayout'

import {
    WORK_SPACE_MENU_CODE,
    WORKSPACE_TEAM_MANAGE_MENU_CODE,
    WORKSPACE_POLICY_MANAGE_MENU_CODE
} from '@constant/menu_code'

const routes = [{
    path: '/workspace',
    name: 'WorkSpace',
    redirect: '/workspace',
    component: BasicLayout,
    code: WORK_SPACE_MENU_CODE,
    sort: 0,
    meta: {
        title: '工作台',
        icon: 'CRM-gongzuotai',
        iconType: 'iconfont'
    },
    children: [{
        path: 'index',
        name: 'WorkSpace',
        code: WORK_SPACE_MENU_CODE,
        hidden: true,
        component: () => import('./index'),
        meta: { breadcrumb: false }
    }, {
        path: 'team-manage',
        name: 'TeamManage',
        code: WORKSPACE_TEAM_MANAGE_MENU_CODE,
        hidden: true,
        component: () => import('./TeamManage'),
        meta: {
            breadcrumb: true,
            title: '团队详情'
        }
    }, {
        path: 'policy-manage',
        name: 'PolicyManage',
        code: WORKSPACE_POLICY_MANAGE_MENU_CODE,
        hidden: true,
        component: () => import('./PolicyManage'),
        meta: {
            breadcrumb: true,
            title: '保单详情'
        }
    }]
}]

export default routes
