<!-- G端保单分析 -->
<template>
    <list-page-layout class="policy-analysis-page-g">
        <!-- title -->
        <common-title title="G端保单分析" />

        <container-panel>
            <!-- search start -->
            <template #screen>
                <search-panel
                    ref="searchPanel"
                    slot="header"
                    @search="handleSearch"
                    @refresh="handleRefresh"
                    @get-init="handleGetInit"
                    @date-change="handleDateChange"
                    @system-change="handleSystemChange"
                />
            </template>
            <!-- search end -->

            <!-- statistics start -->
            <template #statistics>
                <div class="policy-analysis">
                    <div>
                        <span class="tip-icon">*</span>
                        <span>统计数据不含退保保单</span>
                    </div>
                    <!-- // TODO 按钮权限 -->
                    <a-button
                        v-auth="POLICY_DETAIL_BUTTON"
                        type="link"
                        @click="handleClickPolicyDetails"
                    >
                        <span>查看保单明细</span> <a-icon type="right" />
                    </a-button>
                </div>
                <statistics-bar :data-source="statisticsList" :loading="statisticsLoading" />
            </template>
            <!-- statistics end -->

            <!-- 表格 start -->
            <template #content>
                <a-table
                    size="middle"
                    :columns="columns"
                    :data-source="data"
                    :pagination="pagination"
                    :loading="loading"
                    row-key="id"
                    :scroll="{ x: '100%' }"
                    @change="handleTableChange"
                >
                    <!-- 操作 -->
                    <template slot="action" slot-scope="action">
                        <a-button
                            v-auth="WORKSPACE_POLICY_MANAGE_MENU_CODE"
                            type="link"
                            @click="handleInsuranceDetail(action)"
                        >
                            查看保单详情
                        </a-button>
                    </template>
                </a-table>
            </template>
            <!-- 表格 end -->
        </container-panel>
    </list-page-layout>
</template>

<script>
import { Table as ATable } from 'ant-design-vue'
// 组件
import ListPageLayout from '@layout/ListPageLayout'
import ContainerPanel from '@components/ContainerPanel'
import CommonTitle from '@components/CommonTitle'
import SearchPanel from './SearchPanel'
import StatisticsBar from '@/weights/StatisticsBar'

// 全局数据
import { POLICY_DETAIL_BUTTON } from '@constant/authEnum/policyAnalysisG'
import {
    SIGNING_DATE_THIS_MONTH
} from '@constant/enum'

// 常量
import { DEFAULT_PAGES_SIZE } from '@config'

// mixins
import tableShowTotal from '@mixins/tableShowTotal'
import thousandBit from '@mixins/thousandBit'

// 请求
import getInsurancePolicyAnalysisService from '@/service/policyAnalysisG/getInsurancePolicyAnalysisService'


const columns = [
    {
        title: '平台系统',
        dataIndex: 'externalSystemName',
        align: 'left',
        width: 250,
        customRender: text => text || '-'
    },
    {
        title: '险种',
        dataIndex: 'dangerPlantedName',
        align: 'left',
        width: 250,
        customRender: text => text || '-'
    },
    {
        title: '保险公司',
        dataIndex: 'insuranceEnterpriseName',
        align: 'left',
        width: 250,
        customRender: text => text || '-'
    },
    {
        title: '保费（元）',
        dataIndex: 'payMoneyTotal',
        align: 'left',
        customRender: text => text ? (text * 1).toFixed(2) : '0.00'
    },
    {
        title: '保单数（笔）',
        dataIndex: 'insuranceCount',
        align: 'left',
        customRender: text => text || '-'
    },
    {
        title: '保险金额（元）',
        dataIndex: 'insuranceMoneyTotal',
        align: 'left',
        customRender: text => text ? (text * 1).toFixed(2) : '0.00'
    }
]

export default {
    name: 'PolicyAnalysisListG',
    components: {
        ATable,
        ListPageLayout,
        CommonTitle,
        ContainerPanel,
        SearchPanel,
        StatisticsBar
    },
    mixins: [tableShowTotal, thousandBit],
    data() {
        return {
            POLICY_DETAIL_BUTTON,
            // 保单统计数据配置 // TODO 字段
            statisticsList: [{
                title: '保费',
                imgSrc: require('@/assets/images/workSpace/today-premium.png'),
                money: '0',
                unit: '元',
                key: 'insurancePremiumTotal',
                tipText: '不含退保保单',
                average: '日均保费：¥ ',
                averageNum: 0,
                averageKey: 'averageBailyPremium'
            },
            {
                title: '保单数',
                imgSrc: require('@/assets/images/workSpace/total_policy@2x.png'),
                money: '0',
                unit: '笔',
                key: 'insuranceCount',
                tipText: '不含退保保单',
                average: '平均每单保费：￥ ',
                averageNum: 0,
                averageKey: 'averagePremiumPerOrder'
            },
            {
                title: '覆盖企业',
                imgSrc: require('@/assets/images/workSpace/covering-enterprise.png'),
                money: '0',
                unit: '家',
                key: 'coveringEnterprise',
                tipText: '未退保保单投保企业去重',
                average: '平均每家保费：￥ ',
                averageNum: 0,
                averageKey: 'averagePremiumPerUnit'
            },
            {
                title: '平台系统',
                imgSrc: require('@/assets/images/workSpace/platform-system.png'),
                money: '0',
                unit: '个',
                key: 'platformSystemCount',
                tipText: '未退保保单平台系统去重',
                average: '平均每个保费：￥ ',
                averageNum: 0,
                averageKey: 'averagePremiumPerPolicy'
            }],
            // 业绩数据统计
            statisticsData: {},
            statisticsLoading: false,
            tabData: {},
            // 查询条件
            params: {},
            // 数据
            data: [],
            // 分页数量
            limit: DEFAULT_PAGES_SIZE,
            // 总记录数量
            total: 0,
            // 当前索引
            current: 1,
            // 加载状态
            loading: false,
            // table 列配置
            columns,
            signingDate: SIGNING_DATE_THIS_MONTH,
            // 统计数据
            extra: {
                insurancePremiumTotal: 0, // 保险费用合计
                averageBailyPremium: 0, // 日均保费
                insuranceCount: 0, // 保单总数
                averagePremiumPerOrder: 0, // 平均每单保费
                coveringEnterprise: 0, // 覆盖企业
                averagePremiumPerUnit: 0, // 平均每家保费
                platformSystemCount: 0, // 平台系统
                averagePremiumPerPolicy: 0 // 平均每个保单
            },
            externalSystemName: ''
        }
    },
    computed: {
        pagination() {
            const { total, limit, current, tableShowTotal } = this
            return {
                showTotal: (total, range) => tableShowTotal(total, range),
                current,
                total,
                pageSize: limit,
                showSizeChanger: true,
                showQuickJumper: true
            }
        }
    },
    mounted() {},
    methods: {
        /**
             * 初始化
             */
        handleGetInit(query) {
            console.log(query, 'init===')
            this.params = query
            this.getList()
        },
        /**
             * 获取 列表
             */
        async getList() {
            try {
                this.loading = true
                const {
                    current,
                    limit,
                    params
                } = this
                let data = {
                    current,
                    size: limit,
                    ...params
                }

                const { total, records, extra } = await getInsurancePolicyAnalysisService(data)
                console.log(records, '2222')
                this.extra = extra || {} // 统计对象
                // 数据格式化
                this.statisticsList.forEach(item => {
                    if (item.key === 'insurancePremiumTotal') {
                        item.money = this.thousandBit(this.extra[item.key] * 1 ? this.extra[item.key].toFixed(2) : '0.00')
                    } else {
                        item.money = this.thousandBit(this.extra[item.key] * 1 ? this.extra[item.key] : '0')
                    }
                    item.averageNum = `${this.thousandBit(this.extra[item.averageKey] * 1 ? this.extra[item.averageKey].toFixed(2) : '0.00' )}`
                })
                this.total = total || 0
                this.data = records || [{
                    externalSystemCode: '2', // 平台系统编码
                    externalSystemName: '东里系统', // 平台系统名称
                    dangerPlantedId: '1397864736518098946', // 险种id
                    dangerPlantedName: '工程预付款保证保险', // 险种名称
                    insuranceEnterpriseId: '1', // 保险公司id
                    insuranceEnterpriseName: '天安财产保险股份有限公司', // 保险公司名称
                    insuranceCount: 1, // 保单总数
                    insuranceMoneyTotal: 1000, // 保险金额合计
                    payMoneyTotal: 10 // 保费金额
                }, {
                    externalSystemCode: '3', // 平台系统编码
                    externalSystemName: '求援系统', // 平台系统名称
                    dangerPlantedId: '1397864736518098946', // 险种id
                    dangerPlantedName: '工程预付款保证保险', // 险种名称
                    insuranceEnterpriseId: '1', // 保险公司id
                    insuranceEnterpriseName: '天安财产保险股份有限公司', // 保险公司名称
                    insuranceCount: 1, // 保单总数
                    insuranceMoneyTotal: 1000, // 保险金额合计
                    payMoneyTotal: 10 // 保费金额
                }]

            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        },
        /**
         * 响应搜索事件
         */
        handleSearch(params) {
            console.log(params, 'handleSearch')
            this.current = 1
            this.params = params
            this.getList()

        },
        /**
         * 响应刷新事件
         */
        handleRefresh(params) {
            this.handleSearch(params)
        },
        /**
         * 响应 table change 事件
         * @param pagination
         */
        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.current = current
            this.limit = pageSize
            this.getList()
        },
        handleClickPolicyDetails() {
            const { signingDate, params, externalSystemName } = this
            this.$router.push( {
                path: '/policy/list',
                query: {
                    signingDate,
                    externalSystemName,
                    isPlatFormG: true,
                    ...params,
                }
            })
        },
        handleDateChange(val) {
            this.signingDate = val
        },
        handleSystemChange(val) {
            this.externalSystemName = val
        }

    }
}
</script>

<style lang="scss">
    .policy-analysis-page-g {
        .container-panel {
            border: none
        }
        .policy-analysis {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .tip-icon {
                color: #D40000;
                margin-right: 2px;
            }
        }
    }
</style>
