import axios from '@service'
import { SEND_CODE } from './api'
import { HTTP_ERR_SUCCESS, HTTP_RESULT_FIELD, HTTP_ERR_MESSAGE_FIELD } from '@/constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@/constant/error'
import { APP_CODE } from '@/config'
/**
 * 发送验证码
 * @returns {Promise<*>}
 */
export default async function sendCodeService(payload = {}, config = {}) {
    let defaultParams = {
        // appCode: APP_CODE
    }

    const param = {
        ...SEND_CODE,
        ...config,
        params: { ...payload }
    }
    const { data } = await axios(param)
    if (data.code === HTTP_ERR_SUCCESS) {
        return data[HTTP_RESULT_FIELD]
    } else {
        throw new Error(data[HTTP_ERR_MESSAGE_FIELD] || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
