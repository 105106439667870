/**
 * 获取文件类型
 */
export default {
    methods: {
        /**
       * @param url
       * @returns {(function(*=, *=): string)|(function(*=, *=): boolean)|string}
       */
        formatterFileType(url) {
            if (url) {
                let i = url.lastIndexOf('.')
                let type = url.slice(i + 1)
                return type
            } else {
                return ''
            }
        }
    }
}
