<!-- 共用修改客户信息弹框 -->
<template>
    <custom-modal
        :title="title"
        :visible="visible"
        :mask-closable="maskClosable"
        :width="824"
        class="update-customer-info-modal-components"
        @close="handleCancel"
    >
        <template>
            <!-- 表单 start -->
            <form-panel
                ref="formPanel"
                :params="params"
                @success="handleSendSuccess"
                @loading="handleLoading"
            />
            <!-- 表单 end -->
            <!-- 按钮 start -->
            <template slot="customFooter">
                <a-button type="default" @click="handleCancel">取消</a-button>
                <a-button type="primary" :loading="loading" class="button-box-shadow" @click="handleOk">提交</a-button>
                <!-- 按钮 end -->
            </template>
        </template>
    </custom-modal>
</template>

<script>
// 组件
import { Button as AButton } from 'ant-design-vue'
import FormPanel from './FormPanel'
import CustomModal from '@components/CustomModal'

export default {
    name: 'UpdateCustomerInfoModalComponents',
    components: {
        AButton,
        FormPanel,
        CustomModal
    },
    props: {
        visible: {
            type: Boolean,
            default() {
                return false
            }
        },
        title: {
            type: String,
            default() {
                return ''
            }
        },
        params: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            loading: false,
            maskClosable: false
        }
    },
    computed: {},
    methods: {
        /**
         * 提交按钮事件
         */
        handleOk() {
            this.$refs.formPanel.handleSend()
        },
        /**
         * 按钮loading
         */
        handleLoading(loading) {
            this.loading = loading
        },
        /**
         * 关闭弹框
         */
        handleCancel() {
            this.$emit('close')
        },
        handleSendSuccess() {
            this.$emit('success')
        }
    }
}
</script>

<style lang="scss">
.update-customer-info-modal-components {
    .ant-modal-footer {
        text-align: right;
    }
}
</style>