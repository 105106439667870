<!-- 客户详情-保全记录-筛选 -->
<template>
    <search-panel :model="form"  layout="vertical" @search="handleSearch" @refresh="handleRefresh">
        <form-col label="保单号" prop="insuranceId">
            <template :slot="formColDefaultSlot">
                <a-input v-model="form.insuranceId" placeholder="请输入" />
            </template>
        </form-col>
        <form-col label="状态" prop="state">
            <template :slot="formColDefaultSlot">
                <a-select v-model="form.state" placeholder="请选择">
                    <a-select-option value>全部</a-select-option>
                    <template v-for="item in PRESERVATION_STATE_MENU_LIST">
                        <a-select-option :key="item.value" :value="item.value">{{ item.label }}</a-select-option>
                    </template>
                </a-select>
            </template>
        </form-col>
        <form-col label="服务单号" prop="id">
            <template :slot="formColDefaultSlot">
                <a-input v-model="form.id" placeholder="请输入" />
            </template>
        </form-col>
        <form-col
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            :span="12"
            label="提交时间"
            prop="submitTime">
            <template :slot="formColDefaultSlot">
                <a-range-picker v-model="form.submitTime"
                                :mode="submitTimeMode"
                                :show-time="showTimeOptions"
                />
            </template>
        </form-col>
    </search-panel>
</template>

<script>
// 常量
import { PRESERVATION_STATE_MENU_LIST } from '@/constant/enum'

// mixins
import common from '../../mixins'

export default {
    name: 'PreservationRecordSearchPanel',
    mixins: [common],
    props: {
        echoData: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            PRESERVATION_STATE_MENU_LIST,
            form: {
                // 保单号
                insuranceId: '',
                // 状态
                state: '',
                // 服务单号
                id: '',
                // 提交时间
                submitTime: []
            },
            labelCol: {
                span: 4
            },
            wrapperCol: {
                span: 20
            }
        }
    },
    watch: {
        echoData: {
            handler(val) {
            },
            immediate: true
        }
    },
    mounted() {},
    methods: {
        /**
         * 格式化参数
         */
        formatParams() {
            const { form } = this
            let { submitTime, ...params } = form
            let [startTime, endTime] = submitTime || []
            startTime = startTime ? `${startTime.format('YYYY-MM-DD HH:mm:ss')}` : ''
            endTime = endTime ? `${endTime.format('YYYY-MM-DD HH:mm:ss')}` : ''
            params.startCreateDateTime = startTime
            params.endCreateDateTime = endTime
            return params
        }
    }
}
</script>

<style lang="scss">
</style>
