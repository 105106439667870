<template>
    <div class="new-password-box">
        <a-form-model ref="formModel"
                      :model="formModel"
                      :rules="formRules">
            <a-form-model-item prop="password">
                <a-input-password v-model="formModel.password"
                                  visibility-toggle
                                  placeholder="输入密码,6-20位 大小写字母+数字组合">
                    <template slot="prefix">
                        <img src="@/assets/images/icon/password.png"
                             width="20"
                             alt="">
                        <a-divider type="vertical" />
                    </template>
                </a-input-password>
            </a-form-model-item>

            <a-form-model-item prop="rePassword">
                <a-input-password v-model="formModel.rePassword"
                                  visibility-toggle
                                  placeholder="再次确认密码">
                    <template slot="prefix">
                        <img src="@/assets/images/icon/password.png"
                             width="20"
                             alt="">
                        <a-divider type="vertical" />
                    </template>
                </a-input-password>
            </a-form-model-item>

            <a-form-model-item>
                <a-button type="primary"
                          block
                          class="next-btn"
                          :loading="loading"
                          @click="setStep">
                    下一步
                </a-button>
            </a-form-model-item>
        </a-form-model>
    </div>
</template>

<script>
import {
    FormModel as AFormModel,
    Input as AInput,
    Divider as ADivider,
    Button as AButton
} from 'ant-design-vue'

import updateUserInfoService from '@/service/updateUserInfoService'
import updatePasswordService from '@/service/updatePasswordService'
import { UPDATE_TYPE_PASSWORD } from '@/constant/enum'

export default {
    name: 'SetAccountSetNewPassword',

    components: {
        AFormModel,
        AFormModelItem: AFormModel.Item,
        AInput,
        AInputPassword: AInput.Password,
        ADivider,
        AButton
    },
    data() {
        return {
            loading: false,

            formModel: {
                password: '',
                rePassword: ''
            },

            formRules: {
                password: [
                    {
                        required: true,
                        message: '请输入密码'
                    },
                    {
                        min: 6,
                        message: '密码长度不可小于6位或大于20位'
                    },
                    {
                        max: 20,
                        message: '密码长度不可小于6位或大于20位'
                    },
                    {
                        pattern: /^(?=.*[0-9].*)(?=.*[A-Z].*)(?=.*[a-z].*).{6,20}$/,
                        message: '密码需同时包含大小写字母和数字'
                    }
                ],

                rePassword: {
                    required: true,
                    validator: this.validateNewPassword
                }
            }
        }
    },

    computed: {
        phone() {
            return this.$store.getters.userInfo.mobile
        },

        appCode() {
            return this.$store.getters.userInfo.appCode
        },
        user_id() {
            return this.$store.getters.userInfo.id
        }
    },

    // 生命周期 - 创建完成
    created() {

    },

    // DOM挂载完毕
    mounted() {

    },

    methods: {
        validateNewPassword(rule, val, cb) {
            if (!val) {
                cb(new Error('请先输入密码'))
            } else if (val !== this.formModel.password) {
                cb(new Error('两次密码不一致'))
            } else {
                cb()
            }
        },

        setStep() {
            let that = this,
                { phone, appCode, user_id, formModel: { password, rePassword } } = this

            this.$refs.formModel.validate(async valid => {
                if (valid) {
                    try {
                        that.loading = true
                        await updatePasswordService({
                            id: user_id,
                            password,
                            rePassword
                            // updateType: UPDATE_TYPE_PASSWORD,
                        })
                        that.$message.success('修改成功')
                        that.$emit('submit', that.formModel)
                    } catch (e) {
                        that.$message.error(e.message)
                    } finally {
                        that.loading = false
                    }
                }
            })
        }
    }
}
</script>
<style lang='scss'>
.new-password-box {
    .ant-input-affix-wrapper {
        .ant-input-prefix {
            left: 16px;
        }

        .ant-input {
            height: 36px;
            font-size: 14px;
            border: 1px solid #dcdee0;
            border-radius: 2px;
        }

        .ant-input:not(:first-child) {
            padding-left: 52px;
        }
    }

    .ant-form-item {
        margin-bottom: 16px;
    }

    .next-btn {
        height: 36px;
        font-size: 16px;
    }
}

</style>
