<!-- 客户管理-客户跟进-列表 -->
<template>
    <list-page-layout>
        <!-- title -->
        <common-title title="客户跟进列表">
            <template #extra>
                <a-button
                    v-auth="DISTRIBUTION_GROUP_CUSTOMER_ADD_BUTTON"
                    @click="handleImport"
                >
                    批量添加客户
                </a-button>
                <a-button
                    v-auth="DISTRIBUTION_CUSTOMER_ADD_BUTTON"
                    type="primary"
                    class="button-box-shadow"
                    icon="plus-circle"
                    @click="handleOpenModal('showAddDistributionCustomerModal')"
                >
                    添加客户
                </a-button>
            </template>
        </common-title>
        <container-panel>
            <template #tabs>
                <customer-tab slot="header" @changetab="changeTab" />
            </template>
            <!-- 筛选 -->
            <template #screen>
                <search-panel
                    ref="tableRef"
                    slot="header"
                    :type="SALES_CATEGORY_DISTRIBUTION_TEXT"
                    :echo-data="params"
                    @search="handleSearch"
                    @refresh="handleRefresh"
                />
            </template>

            <!-- 表格 start -->
            <template #content>
                <a-table
                    size="middle"
                    :columns="distributionColumns"
                    :data-source="data"
                    :pagination="pagination"
                    :loading="loading"
                    row-key="id"
                    :components="headerColumnTitle"
                    :scroll="scrollConfig"
                    @change="handleTableChange"
                >
                    <!-- 客户意向 -->
                    <!-- <template slot="intention" slot-scope="text">
                        <span>{{ text ? getApiEnumLabelFromList(CUSTOMER_INTENTION_ENUM_LIST, String(text)) : '-' }}</span>
                    </template> -->
                    <!-- 操作 -->
                    <template slot="action" slot-scope="action">
                        <div class="action-button-list">
                            <a-button
                                v-auth="DISTRIBUTION_CUSTOMER_DETAIL_MENU_CODE"
                                type="link"
                                @click="handleClickLook(action)"
                            >
                                查看
                            </a-button>
                            <template v-if="handleIsLoginUserId(action.agentUserId)">
                                <a-divider type="vertical" />
                                <a-button
                                    v-auth="DISTRIBUTION_CUSTOMER_FOLLOW_RECORD_BUTTON"
                                    type="link"
                                    @click="handleOpenModal('showFollowRecordModal', action)"
                                >
                                    跟进记录
                                </a-button>
                            </template>
                        </div>
                    </template>
                </a-table>
            </template>
            <!-- 添加客户弹框 -->
            <add-customer-modal
                :visible="showAddDistributionCustomerModal"
                title="添加客户"
                :type="SALES_CATEGORY_DISTRIBUTION_TEXT"
                @close="handleCloseModal('showAddDistributionCustomerModal')"
                @success="handleSuccessModal('showAddDistributionCustomerModal')"
            />
            <!-- 跟进记录弹框 -->
            <follow-record-modal
                :visible="showFollowRecordModal"
                title="添加跟进记录"
                :current-row="currentRow"
                @close="handleCloseModal('showFollowRecordModal')"
                @success="handleSuccessModal('showFollowRecordModal')"
            />
            <!-- 模态框 -->
            <component v-bind="modalProp" :is="modalComponent" @on-close="handleCloseImportModal" @on-refresh="getList" />
        </container-panel>
    </list-page-layout>
</template>

<script>

// 组件
import ImportModal from './ImportModal'

// 常量
import { DEFAULT_PAGES_SIZE } from '@config'
import { scrollConfig } from '@constant/listScrollConfig'

import { CUSTOMER_INTENTION_ENUM_LIST, SALES_CATEGORY_DISTRIBUTION_TEXT, SALES_CATEGORY_DISTRIBUTION } from '@constant/enum'

// 权限
import {
    DISTRIBUTION_CUSTOMER_DETAIL_MENU_CODE
} from '@constant/menu_code'
import {
    DISTRIBUTION_CUSTOMER_ADD_BUTTON,
    DISTRIBUTION_GROUP_CUSTOMER_ADD_BUTTON,
    DISTRIBUTION_CUSTOMER_FOLLOW_RECORD_BUTTON
} from '@constant/authEnum/distributionCustomer'

// mixins
import tableShowTotal from '@mixins/tableShowTotal'
import mixins from '@views/Customer/Components/mixins'
import enumOperate from '@mixins/enumOperate'
import isLoginUserId from '@mixins/isLoginUserId'
import CustomerTab from './CustomerTab'

import { customRow } from '@utils/customRow'

// 接口请求
import getDistributionCustomerListService from '@service/customer/distributionCustomer/getDistributionCustomerListService'

export default {
    name: 'DistributionCustomerList',
    components: {
        CustomerTab,
        ImportModal
    },
    mixins: [tableShowTotal,
        mixins,
        enumOperate,
        isLoginUserId],
    data() {
        return {
            DISTRIBUTION_CUSTOMER_DETAIL_MENU_CODE,
            DISTRIBUTION_CUSTOMER_ADD_BUTTON,
            DISTRIBUTION_GROUP_CUSTOMER_ADD_BUTTON,
            DISTRIBUTION_CUSTOMER_FOLLOW_RECORD_BUTTON,
            CUSTOMER_INTENTION_ENUM_LIST,
            SALES_CATEGORY_DISTRIBUTION_TEXT,
            SALES_CATEGORY_DISTRIBUTION,
            // 查询条件
            params: {},
            // 数据
            data: [],
            // 分页数量
            limit: DEFAULT_PAGES_SIZE,
            // 总记录数量
            total: 0,
            // 当前索引
            current: 1,
            // 加载状态
            loading: false,
            // 添加客户弹框相关参数
            showAddDistributionCustomerModal: false,
            // 跟进记录弹框相关参数
            showFollowRecordModal: false,
            // 当前行数据
            currentRow: null,
            // 重置数据
            isResetSearch: false,
            scrollConfig,
            // 是否已跟进
            connectionState: false,
            // 模态框
            // 模态框组件
            modalComponent: '',
            // 给模态框的传参
            modalProp: { visible: false }
        }
    },
    computed: {
        pagination() {
            const {
                total,
                limit,
                current,
                tableShowTotal
            } = this
            return {
                showTotal: (total, range) => tableShowTotal(total, range),
                current,
                total,
                pageSize: limit,
                showSizeChanger: true,
                showQuickJumper: true
            }
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        /**
         * 获取 列表
         */
        async getList() {
            try {
                this.loading = true
                const { params, current, limit } = this
                const { total, records } = await getDistributionCustomerListService({
                    connectionState: this.connectionState,
                    current,
                    size: limit,
                    ...params
                })
                this.total = total || 0
                this.data = records || []
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.loading = false
            }
        },
        // 导入
        handleImport() {
            this.handleOpenImportModal('ImportModal')
        },
        // 打开模态框
        handleOpenImportModal(modalComponent, props) {
            Object.assign(this, {
                modalComponent,
                modalProp: {
                    ...props,
                    visible: true
                }
            })
        },
        // 关闭模态框
        handleCloseImportModal() {
            this.$set(this.modalProp, 'visible', false)
        },
        /**
         * 打开弹框
         */
        handleOpenModal(modalName, action) {
            this[modalName] = true
            this.currentRow = action ? action : null
        },
        /**
         * 关闭弹框
         */
        handleCloseModal(modalName) {
            this[modalName] = false
        },
        /**
         * 成功弹框
         */
        handleSuccessModal(modalName) {
            this[modalName] = false
            this.current = 1
            this.getList()
        },
        /**
         * 查看
         */
        handleClickLook(action) {
            const { SALES_CATEGORY_DISTRIBUTION, current, limit, isResetSearch, params } = this
            const { customerId, agentUserId } = action
            this.$router.push({
                name: 'DistributionCustomerDetail',
                query: isResetSearch ? {
                    customerId,
                    agentUserId,
                    businessType: SALES_CATEGORY_DISTRIBUTION
                } : {
                    customerId,
                    agentUserId,
                    businessType: SALES_CATEGORY_DISTRIBUTION,
                    params: JSON.stringify(params),
                    current,
                    limit
                }
            })
        },
        /**
         * 响应 table change 事件
         * @param pagination
         */
        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.current = current
            this.limit = pageSize
            this.getList()
        },
        /**
         * 搜索
         */
        handleSearch(params) {
            this.current = 1
            this.params = params
            this.getList()
            this.isResetSearch = false
        },
        /**
         * 刷新
         */
        handleRefresh(params) {
            this.handleSearch(params)
            this.isResetSearch = true
        },
        /**
         * 切换tab
         */
        changeTab(param) {
            this.connectionState = param.hasConnection !== '1'

            this.$refs.tableRef.resetForm() // 重载列表

        },

        customRow(record, index) {
            return customRow()
            // return {
            //     // 这个style就是我自定义的属性，也就是官方文档中的props
            //     style: {
            //         // 行背景色
            //         // minHeight: '56px'
            //         // display: 'flex',
            //         'background-color': '#000000'
            //         // flexDirection: 'row'
            //     }
            // }
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (from.name === 'DistributionCustomerDetail') {
                if (!vm.isResetSearch && Object.getOwnPropertyNames(from.query).length > 0 ) {
                    const { params, current, limit } = from.query
                    vm.params = params && JSON.parse(params) || {}
                    vm.current = +current || 1
                    vm.limit = +limit || DEFAULT_PAGES_SIZE
                }
            }
        })
    }
}
</script>

<style lang="scss" scoped>
.action-button-list {
    display: flex;
    align-items: center;
}
</style>
