<!-- 日期时间选择 -->
<template>
    <a-date-picker
        v-model="realValue"
        v-bind="$attrs"
        v-on="$listeners"
    />
</template>

<script>
import { DatePicker as ADatePicker } from 'ant-design-vue'

export default {
    name: 'CustomDatePicker',
    components: { ADatePicker },
    props: { value: { type: null } },
    computed: {
        realValue: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        }
    }
    // methods: {
    //     onChange(value, dateString) {
    //         console.log('Selected Time: ', value)
    //         console.log('Formatted Selected Time: ', dateString)
    //     },
    //     onOk(value) {
    //         console.log('onOk: ', value)
    //     }
    // }
}
</script>
