<template>
  <page-layout class="list-page-layout"
               :header="header"
               :loading="loading"
               :title="title"
               :full="full"
               :show-loading="showLoading"
               :show-title="showTitle"
  >
    <slot name="title" slot="title"/>
    <slot name="headerContent" slot="headerContent"/>
    <slot name="headerAction" slot="headerAction"/>
    <!-- 内容 -->
    <div class="list-page-layout__inner">
      <div class="list-page-layout__inner-header"
           v-if="$slots.header"
      >
        <slot name="header"></slot>
      </div>
      <slot></slot>
    </div>
  </page-layout>

</template>

<script>
import {Card as ACard} from 'ant-design-vue'
import PageLayout from '@layout/PageLayout'

export default {
  name: 'PagePanel',
  props: {
    header: {
      type: Boolean,
      default: true
    },
    showTitle: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    full: {
      type: Boolean,
      default: false
    },
    showLoading: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  components: {
    PageLayout
  }
}
</script>

<style lang="scss">
@import "@assets/styles/varibles.scss";

.list-page-layout {
  &__inner-header {
    margin-bottom: $space-col-base;
  }
  .button-box-shadow {
    box-shadow: 0px 4px 6px -4px rgba(24,119,242,0.6);
  }
}
</style>