<!-- 详情-抽屉 -->
<template>
    <a-drawer
        class="detail-drawer"
        placement="right"
        :width="width"
        v-bind="$attrs"
        :after-visible-change="afterVisibleChange"
        v-on="$listeners"
    >
        <a-spin :spinning="loading">
            <div class="detail-drawer__container">
                <slot name="top" />
                <div ref="contentRef" class="detail-drawer__content">
                    <slot name="content" />
                </div>
                <slot />
            </div>
        </a-spin>
    </a-drawer>
</template>

<script>
    // 组件
    import { Drawer as ADrawer, Spin as ASpin } from 'ant-design-vue'

    export default {
        name: 'DrawerPanel',
        components: {
            ADrawer,
            ASpin
        },
        props: {
            width: {
                type: String,
                default() {
                    return '68%'
                }
            },
            loading: {
                type: Boolean,
                default() {
                    return false
                }
            }
        },
        data() {
            return {}
        },
        computed: {
        },
        watch: {
        },
        methods: {
            afterVisibleChange(visible) {
                this.$emit('after-visible-change', visible)
            }
        }
    }
</script>

<style lang="scss">
    @import '@assets/styles/varibles.scss';

    .detail-drawer {
        transform: none !important;
        .ant-drawer-content-wrapper,
        .ant-drawer-content {
            border-radius: 4px 0 0 4px;
            .ant-drawer-wrapper-body {
                display: flex;
                flex-direction: column;
                height: 100%;
            }
        }
        .ant-drawer-header {
            padding: 18px 0 18px 24px;
            background-color: #F7F8FA;
            border-bottom: none;
            font-size: $font-sizeSixteen;
            .ant-drawer-title {
                color: #646566;
                font-weight: 500;
            }
        }
        .ant-drawer-body {
            flex: 1;
            overflow: hidden;
            padding: 0;

            .ant-spin-nested-loading {
                height: 100%;

                .ant-spin-container {
                    height: 100%;
                }
            }
        }

        &__container {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        &__content {
            flex: 1;
            overflow-y: auto;
        }
    }
</style>


