var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('div',{staticClass:"statistics-wrap"},_vm._l((_vm.dataSource),function(ref){
var title = ref.title;
var imgSrc = ref.imgSrc;
var money = ref.money;
var unit = ref.unit;
var tipText = ref.tipText;
var average = ref.average;
var averageNum = ref.averageNum;
return _c('div',{key:title,staticClass:"statistics-card"},[_c('div',{staticClass:"statistics-card-top"},[_c('img',{staticClass:"icon",attrs:{"src":imgSrc}}),_c('div',{staticClass:"info"},[_c('div',{staticClass:"title"},[_c('span',[_vm._v(_vm._s(title))]),(tipText)?_c('a-tooltip',{staticClass:"tool-tip"},[_c('template',{slot:"title"},[_vm._v(" "+_vm._s(tipText)+" ")]),_c('img',{staticClass:"tip-icon",attrs:{"src":_vm.tipIcon,"alt":""}})],2):_vm._e()],1),_c('div',[_c('span',{staticClass:"money"},[_vm._v(_vm._s(money))]),_c('span',{staticClass:"unit"},[_vm._v(_vm._s(unit))])])])]),(average)?_c('div',{staticClass:"statistics-card-bottom"},[_vm._v(_vm._s(average)+_vm._s(averageNum))]):_vm._e()])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }