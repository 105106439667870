<template>
    <div class="transfer-seas-modal-form-panel">
        <div class="transfer-seas-modal-form-panel__tip">以下客户即将移入公海，确认提交？</div>
        <a-descriptions bordered :column="1" size="middle">
            <a-descriptions-item label="用户ID：">
                {{ empty(currentRow.userId) }}
            </a-descriptions-item>
            <a-descriptions-item label="手机号码：">
                {{ empty(currentRow.customerMobile) }}
            </a-descriptions-item>
            <a-descriptions-item label="姓名：">
                {{ empty(currentRow.name) }}
            </a-descriptions-item>
            <a-descriptions-item label="当前经纪人：">
                {{ `${empty(currentRow.agentUserName)}（${empty(currentRow.agentUserId)}）`}}
            </a-descriptions-item>
        </a-descriptions>
    </div>
</template>

<script>
import { Descriptions as ADescriptions } from 'ant-design-vue'
// mixins
import empty from '@mixins/empty'
// 接口请求
import proprietaryTransferSeasService from '@service/customer/proprietaryCustomer/proprietaryTransferSeasService'

export default {
    name: 'TransferSeasModalFormPanel',
    components: {
        ADescriptions,
        ADescriptionsItem: ADescriptions.Item,
    },
    mixins: [empty],
    props: {
        currentRow: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {}
    },
    methods: {
        async handleSend() {
            const { currentRow } =this
            const { customerId, agentUserId } = currentRow
            try {
                this.$emit('loading', true)
                await proprietaryTransferSeasService(
                    {
                        customerId,
                        agentUserId
                    }
                )
                this.$message.success('操作成功')
                this.$emit('success')
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.$emit('loading', false)
            }
        }
    }

}
</script>

<style lang='scss'>
.transfer-seas-modal-form-panel {
    &__tip {
        margin-bottom: 20px;
    }
    .ant-descriptions-item-label {
        width: 120px;
        text-align: right;
    }
    .ant-descriptions-view table {
        table-layout: fixed;
    }
    .ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-label, 
    .ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-content {
        padding: 12px;
    }
}
</style>