/**
 * 企业--企业认证列表
 * ——————————————————————————————————————————————————————————————————————————————————————————————
 */
// 企业--企业认证列表-编辑
export const ENTERPRISE_AUTHORIZATION_EDIT_BUTTON = 'ENTERPRISE_AUTHORIZATION_EDIT'
// 企业--企业认证列表-删除
export const ENTERPRISE_AUTHORIZATION_DELETE_BUTTON = 'ENTERPRISE_AUTHORIZATION_DELETE'
// 企业--企业认证列表-购买保险
export const ENTERPRISE_AUTHORIZATION_PURCHASE_INSURANCE_BUTTON = 'ENTERPRISE_AUTHORIZATION_PURCHASE_INSURANCE'
// 企业--企业认证列表-查看订单
export const ENTERPRISE_AUTHORIZATION_ORDER_BUTTON = 'ENTERPRISE_AUTHORIZATION_ORDER'
// 企业--企业认证列表-撤销审批
export const ENTERPRISE_AUTHORIZATION_CANCEL_BUTTON = 'ENTERPRISE_AUTHORIZATION_CANCEL'
// 企业--企业认证列表-提交审批
export const ENTERPRISE_AUTHORIZATION_SUBMIT_BUTTON = 'ENTERPRISE_AUTHORIZATION_SUBMIT'
