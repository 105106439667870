<template>
    <custom-modal
        title="批量添加客户"
        :visible="visible"
        :mask-closable="maskClosable"
        :width="520"
        class="agreement-edit-modal"
        @close="handleCancel"
        @ok="handleOk">
        <template>
            <!-- 表单 start -->
            <form-panel
                ref="formPanel"
                @success="handleSuccess"
                @loading="handleLoading"
                @import="handleImportSuccess"
                @download="handleImportDownload" />
            <!-- 表单 end -->
            <!-- 按钮 start -->
            <template slot="customFooter">
                <a-button v-if="!importSuccess" key="reset" @click="handleCancel"> 取消 </a-button>
                <a-button v-if="importSuccess" key="reset" @click="handleCancel"> 关闭 </a-button>
                <a-button
                    v-if="!importSuccess"
                    key="submit"
                    type="primary"
                    :loading="loading"
                    @click="handleOk">
                    导入
                </a-button>
                <a-button
                    v-else-if="downloadUrl"
                    key="submit"
                    type="primary"
                    :loading="loading"
                    @click="handleDownload">
                    下载表格
                </a-button>
                <!-- 按钮 end -->
            </template>
        </template>
    </custom-modal>
</template>

<script>
import { Modal as AModal, Button as AButton } from 'ant-design-vue'
import FormPanel from './FormPanel'
import { gotoPage } from '@utils/dom'
import CustomModal from '@components/CustomModal'


export default {
    name: 'BatchImportCustomerModal',
    components: {
        FormPanel,
        AModal,
        AButton,
        CustomModal
    },
    props: { visible: Boolean },
    data() {
        return {
            loading: false,
            maskClosable: false,
            importSuccess: false,
            downloadUrl: ''
        }
    },
    computed: {
        okButtonProps() {
            const { loading } = this
            return { props: { loading } }
        }
    },
    watch: {
        visible: {
            handler(val) {
                if (val) {
                    this.importSuccess = false
                }
            },
            immediate: true
        }
    },
    methods: {
        /**
         * 提交按钮事件
         */
        handleOk() {
            this.$refs.formPanel.handleSend()
        },
        /**
         * 按钮loading
         */
        handleLoading(loading) {
            this.loading = loading
        },
        /**
         * 关闭弹框
         */
        handleCancel() {
            this.$emit('on-close')
            this.importSuccess && this.$emit('on-refresh')
        },
        handleSuccess() {
            this.$emit('on-refresh')
            this.$emit('on-close')
        },
        /**
         * 导入成功
         */
        handleImportSuccess() {
            this.importSuccess = true
        },
        /**
         * 导入失败带下载
         */
        handleImportDownload(url) {
            this.downloadUrl = url || ''
        },
        /**
         * 下载表格
         */
        handleDownload() {
            try {
                // 执行模板下载
                gotoPage(this.downloadUrl)
            } catch (e) {
                this.$message.error(e.message)
            }
        }
    }
}
</script>

<style lang="scss">
.agreement-edit-modal {
    .ant-modal-footer {
        text-align: center;
    }
    .ant-modal-body {
        overflow: hidden;
    }
}
</style>
