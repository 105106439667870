<template>
    <a-spin :spinning="loading">
        <div class="statistics-wrap-details">
            <div v-for="({ title, imgSrc, money, unit }) in dataSource" :key="title" class="statisticsCard">
                <img class="icon" :src="imgSrc">
                <div class="info">
                    <p class="title">{{ title }}</p>
                    <p class="money-unit">
                        <span class="money">{{ money  }}</span>
                        <span class="unit">{{ unit }}</span>
                    </p>
                </div>
            </div>
        </div>
    </a-spin>
</template>

<script>
// 组件
import { Spin as ASpin } from 'ant-design-vue'
export default {
    name: 'StatisticsBar',
    components: { ASpin },
    props: {
        dataSource: {
            type: Array,
            default: () => []
        },
        statisticsData: {
            type: Object,
            default: () => {}
        },
        loading: {
            type: Boolean,
            default: true
        }
    }

}
</script>

<style lang="scss">
.statistics-wrap-details {
    // display: flex;
    align-items: center;
    justify-content: center;
    padding: 27px 0;
    border-bottom: 1px dashed #ebedf0;
    max-width: 100%;
    overflow-x: auto;
    text-align: center;
    white-space: nowrap;

    .statisticsCard {
        position: relative;
        // display: flex;
        display: inline-block;
        // flex: 1;
        align-items: center;
        justify-content: center;
        padding: 0 5%;
        width: auto;

        &::after {
            position: absolute;
            top: 50%;
            right: 0;
            width: 1px;
            height: 40px;
            margin-top: -20px;
            content: "";
            background: #ebedf0;
        }

        .icon {
            // display: block;
            width: 56px;
            height: 56px;
            margin-right: 16px;
            position: relative;
            top: -15px;
        }

        .info {
            display: inline-block;
            position: relative;
            top: 8px;
            .title {
                font-size: 14px;
                margin-bottom: 15px;
                color: #323233;
                width: auto;
                white-space:nowrap;
                text-align: left;
            }

            .money {
                margin-right: 11px;
                /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
                font-family: DINAlternate-Bold, DINAlternate;
                font-size: 26px;
                font-weight: bold;
                color: #0D80FA;
                line-height: 20px;
            }

            .unit {
                position: relative;
                top: -4px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #646566;
            }
            .money-unit {
                width: auto;
                white-space:nowrap;
                text-align: left;
            }
        }
    }
    .statisticsCard:last-child {
        &::after {
            position: absolute;
            top: 50%;
            right: 0;
            width: 1px;
            height: 40px;
            margin-top: -20px;
            content: "";
            background: transparent;
        }
    }
}
</style>
