<template>
    <div class="express-modal-form-panel">
        <a-descriptions bordered :column="1" size="middle">
            <a-descriptions-item label="快递公司：">
                {{ empty(currentRow.courierEnterprise) }}
            </a-descriptions-item>
            <a-descriptions-item label="快递单号：">
                {{ empty(currentRow.courierCoding) }}
                <a-icon
                    type="copy"
                    :style="{ color: '#2F8CE2' }"
                    @click="handleCopy(currentRow.courierCoding)"
                />
            </a-descriptions-item>
        </a-descriptions>
    </div>
</template>

<script>
        // 组件
    import { Descriptions as ADescriptions, Icon as AIcon } from 'ant-design-vue'

    // mixins
    import empty from '@mixins/empty'

    // 引入工具类
    import { copyText } from '@utils/copyText.js'

    export default {
        name: 'ExpressModalFormPanel',
        components: {
            AIcon,
            ADescriptions,
            ADescriptionsItem: ADescriptions.Item,
        },
        mixins: [empty],
        props: {
            currentRow: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
        data() {
            return {}
        },
        methods: {
            handleCopy(val) {
                copyText(val)
            }
        }
    }
</script>

<style lang='scss'>
    .express-modal-form-panel {
        &__tip {
            margin-bottom: 20px;
        }
        .ant-descriptions-item-label {
            width: 120px;
            text-align: right;
        }
        .ant-descriptions-view table {
            table-layout: fixed;
        }
        .ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-label,
        .ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-content {
            padding: 12px;
        }
    }
</style>
