<!-- 佣金列表-表单 -->
<template>
    <div>
        <a-form-model
            ref="ruleForm"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 24 }"
            :model="form"
            :rules="rules"
            class="commission-modal"
        >
            <a-form-model-item label="投保地区" prop="selectAreaCode">
                <a-cascader
                    v-model="selectAreaCode"
                    :options="areaData"
                    placeholder="请选择"
                    change-on-select
                    @change="handleChangeArea"
                />
            </a-form-model-item>
        </a-form-model>
        <div class="commission-table">
            <div>
                {{ commissionData.commissionDescription || "以下为预估佣金比例，出单后实际以财务结算佣金比例为准。" }}
            </div>
            <a-table
                size="middle"
                :columns="columns"
                :data-source="data"
                :row-key="(r,i)=>{i.toString()}"
                :pagination="pagination"
                :loading="loading"
                @change="handleTableChange"
            />
            <!-- <a-pagination
                v-model="current"
                :total="list.length"
                size="small"
                :page-size="size"
            /> -->
        </div>
    </div>
</template>

<script>
// 组件
import {
    FormModel as AFormModel,
    Table as ATable
    // Pagination as APagination

} from 'ant-design-vue'

// 常量
import { DEFAULT_PAGES_SIZE } from '@config'


// mixins
import selectArea from '@/mixins/selectArea'
import tableShowTotal from '@mixins/tableShowTotal'
import empty from '@mixins/empty'
import { accMul } from '@utils'

// 接口请求
import getProductCommissionListService from '@service/product/getProductCommissionListService'

export default {
    name: 'AddCustomerFormPanel',
    components: {
        AFormModel,
        AFormModelItem: AFormModel.Item,
        ATable
        // APagination
    },
    mixins: [selectArea, tableShowTotal, empty],
    props: {
        type: {
            type: String,
            default() {
                return ''
            }
        },
        // 佣金信息
        commissionData: {
            type: Object,
            default() {
                return {}
            }
        },
        // spuId
        spuId: {
            type: String,
            default() {
                return ''
            }
        }
    },
    data() {
        const columns = [
            {
                title: '投保地区',
                dataIndex: 'provinceName',
                customRender: (text, record) => {
                    const {
                        provinceName,
                        cityName,
                        areaName
                    } = record

                    const result = this.empty(`${provinceName ? provinceName + '-' : ''}${cityName ? cityName + '-' : ''}${areaName ? areaName : ''}`)
                    return result
                }
            }, {
                title: '推广佣金比例',
                dataIndex: 'rate',
                width: 200,
                customRender: text => {
                    const result = text !== null && text !== 0 && text !== '0' ? `${accMul(text, 100).toFixed(2)}%` : '-'

                    return this.empty(result)
                }
            }
        ]
        return {
            data: [],
            params: {},
            // 加载状态
            loading: false,
            columns,
            // 分页数量
            limit: 5,
            // 总记录数量
            total: 0,
            // 当前索引
            current: 1,
            // 所在地区
            selectAreaCode: [],
            areaCodeValue: [],
            form: {
                // 详细地区
                address: ''
            },
            // 校验规则
            rules: {}
        }
    },
    computed: {
        // list() {
        //     return []
        // },
        pagination() {
            const { total, limit, current, tableShowTotal } = this
            return {
                showTotal: (total, range) => tableShowTotal(total, range),
                current,
                total,
                pageSize: limit,
                showSizeChanger: true,
                showQuickJumper: true
            }
        },
        userInfo() {
            return this.$store.getters.userInfo
        }
    },
    watch: {
        selectAreaCode: {
            handler(value) {
                this.form.provinceCode = value[0] || ''
                this.form.cityCode = value[1] || ''
                this.form.areaCode = value[2] || ''
            },
            immediate: true
        }
    },
    mounted() {
        // 获取列表
        this.getList()
    },
    methods: {
        /**
         * 获取列表
         */
        async getList() {
            try {
                this.loading = true
                const { params, current, limit } = this
                const { id } = this.userInfo || {}

                const { total, records } = await getProductCommissionListService({
                    current,
                    size: limit,
                    userId: id,
                    spuId: this.spuId,
                    ...params
                })
                this.total = total || 0
                this.data = records
                // console.log('datadata:' + JSON.stringify(this.data, null, 4))
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.loading = false
            }
        },

        /**
         * 地区选择
         */
        handleChangeArea(value, selectedOptions) {
            // console.log('handleChangeArea value:' + JSON.stringify(value, null, 4))
            // console.log('handleChangeArea selectedOptions:' + JSON.stringify(selectedOptions, null, 4))
            this.areaCodeValue = value
            // 选择三级地区后请求列表接口
            if (this.areaCodeValue && this.areaCodeValue.length === 3) {
                this.params = {
                    provinceCode: this.areaCodeValue[0],
                    cityCode: this.areaCodeValue[1],
                    areaCode: this.areaCodeValue[2]
                }
                // 获取列表
                this.getList()
            } else if (!this.areaCodeValue || this.areaCodeValue.length === 0) {
                this.params = {}
                // 获取列表
                this.getList()
            }
        },

        /**
         * 响应 table change 事件
         * @param pagination
         */
        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.current = current
            this.limit = pageSize
            this.getList()
        }
    }
}
</script>

<style lang="scss">
.commission-modal {
    .ant-form-item {
        margin-bottom: 4px;
        padding: 0 20px;
    }
    .ant-form-item-label {
        text-align: left;
        height: 35px;
    }
}
.commission-table {
    padding: 20px 20px;
    .ant-pagination {
        margin-top: 20px;
        text-align: right;
    }
    &__total {
        margin-top: 20px;
        margin-bottom: 8px;
        .count {
            color: #1877F2;
        }
    }
    .key-point {
        color: #DF4545;
    }
}
</style>
