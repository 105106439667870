<!-- G端平台系统-列表-筛选 -->
<template>
    <search-panel :model="form" layout="vertical" @search="handleSearch" @refresh="handleRefresh">
        <form-col label="系统名称" prop="externalSystemNameQuery">
            <template :slot="formColDefaultSlot">
                <a-input v-model.trim="form.externalSystemNameQuery" placeholder="请输入系统名称" />
            </template>
        </form-col>
        <form-col label="地区" prop="area">
            <template :slot="formColDefaultSlot">
                <a-cascader v-model="form.code"
                            :options="areaData"
                            placeholder="请选择地区"
                            change-on-select
                            @change="handleChangeArea" />
            </template>
        </form-col>
    </search-panel>
</template>

<script>
// 组件
import {
    Input as AInput,
    Select as ASelect
} from 'ant-design-vue'
import SearchPanel from '@components/BaseSearchPanel'
import FormCol from '@components/FormCol'

// 工具类
import { filterParams } from '@utils/search.js'

import getTime from '@mixins/getTime'
import searchCommon from '@mixins/searchCommon'

// mixins
import selectArea from '@mixins/selectArea'

export default {
    name: 'GPlatformSearchPanel',
    components: {
        SearchPanel,
        FormCol,
        AInput,
        ASelect,
        ASelectOption: ASelect.Option
    },
    mixins: [getTime, searchCommon, selectArea],
    data() {
        return {
            form: {
                // 系统名称
                externalSystemNameQuery: '',
                // 行政地区
                code: [],
                // 省市区参数
                provinceCode: '',
                cityCode: '',
                areaCode: ''
            },
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
            // 筛选-插槽
            formColDefaultSlot: 'formElements'
        }
    },
    computed: { },
    watch: {
        'form.code': {
            handler(value) {
                const [ provinceCode, cityCode, areaCode ] = value
                this.form.provinceCode = provinceCode || ''
                this.form.cityCode = cityCode || ''
                this.form.areaCode = areaCode || ''
            },
            immediate: true
        }
    },
    mounted() {},
    methods: {
        /**
         * 筛选-响应搜索事件
         */
        handleSearch() {
            const params = this.formatParams()
            this.$emit('search', filterParams(params))
        },
        /**
         * 筛选-响应刷新事件
         */
        handleRefresh() {
            this.form.code = []
            this.form.provinceCode = ''
            this.form.cityCode = ''
            this.form.areaCode = ''
            const params = this.formatParams()
            this.$emit('refresh', filterParams(params))
        },
        /**
         * 格式化参数
         */
        formatParams() {
            const { form } = this
            let { code, ...oths } = form
            return { ...oths }
        }
    }
}
</script>

<style lang="scss">
</style>
