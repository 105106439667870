<template>
    <container-query :query="MediaQueryEnum"
                     @change="handleMediaQueryChange"
    >
        <layout class="gongbao-layout">
            <a-drawer v-if="isMobile"
                      :visible="!collapsed"
                      placement="left"
                      :closable="false"
                      :body-style="sideDrawerStyle"
                      :get-container="null"
                      @close="handleDrawerClose">
                <!--                 :theme="theme" -->
                <sider-menu :menu-data="menuRoutes"
                            :collapsed="false"
                            :collapsible="false"
                            :fixed="fixedSidebar"
                            :system-name="systemName"
                            :is-mobile="isMobile"
                            @menuSelect="onMenuSelect"
                />
            </a-drawer>
            <!-- 头部 -->
            <template>
                <layout-header v-if="fixedHeader" />
                <global-header :menu-data="menuRoutes"
                               :collapsed="collapsed"
                               :style="headerStyle"
                               :class="headerClassName"
                               @toggleCollapse="toggleCollapse"
                />
            </template>
            <layout class="gongbao-layout__main" :style="mainStyle">
                <sider-menu v-if="layout === 'side'&& !isMobile"
                            class="gongbao-layout__sider-menu"
                            :menu-data="menuRoutes"
                            :fixed="fixedSidebar"
                            :collapsed="collapsed"
                            :collapsible="true"
                            :system-name="systemName"
                            :is-mobile="isMobile"
                            @toggleCollapse="toggleCollapse"
                />
                <!-- 内容区域 -->
                <layout-content class="gongbao-layout__content">
                    <keep-alive>
                        <router-view v-if="$route.meta.keepAlive" />
                    </keep-alive>
                    <router-view v-if="!$route.meta.keepAlive" />
                </layout-content>
                <!-- 底部页脚 -->
                <layout-footer class="gongbao-layout__footer" :style="footerStyle">
                    <global-footer />
                </layout-footer>
            </layout>
        </layout>
    </container-query>
</template>

<script>
import { ContainerQuery } from 'vue-container-query'
import { Layout, Drawer as ADrawer } from 'ant-design-vue'
import SiderMenu from '@components/SiderMenu'
import GlobalHeader from '@components/GlobalHeader'
import GlobalFooter from '@components/GlobalFooter'

import { MediaQueryEnum } from '@config'

import { SET_IS_MOBILE } from '@store/mutation-types'

import { sidebarWidth, sidebarCollapsedWidth } from '@assets/styles/varibles.scss'

export default {
    name: 'BasicLayout',
    components: {
        ContainerQuery,
        Layout,
        LayoutContent: Layout.Content,
        LayoutFooter: Layout.Footer,
        LayoutHeader: Layout.Header,
        SiderMenu,
        GlobalHeader,
        GlobalFooter,
        ADrawer
    },
    data() {
        return {
            MediaQueryEnum,
            collapsed: false,
            // 媒体查询
            mediaQuery: {},
            sideDrawerStyle: {
                padding: 0,
                height: '100%'
            }
        }
    },
    computed: {
        fixedHeader() {
            return this.$store.getters.fixedHeader
        },
        fixedSidebar() {
            return this.$store.getters.fixedSidebar
        },
        layout() {
            return this.$store.getters.layout
        },
        systemName() {
            return this.$store.getters.systemName
        },
        menuRoutes() {
            return this.$store.getters.routes || []
        },
        isMobile: {
            get() {
                return this.$store.getters.isMobile
            },
            set(val) {
                this.$store.commit(SET_IS_MOBILE, val)
            }
        },
        headerStyle() {
            const { fixedHeader } = this
            const right = fixedHeader ? 0 : undefined

            return {
                zIndex: 9,
                right
            }
        },
        footerStyle() {
            const { fixedHeader, layout, isMobile, collapsed } = this
            const isTop = layout === 'topmenu'
            const needSettingWidth = fixedHeader && !isTop && !isMobile
            const siderWidth = collapsed ? sidebarCollapsedWidth : sidebarWidth
            const left = needSettingWidth ? siderWidth : 0

            return {
                zIndex: 9,
                left
            }
        },
        mainStyle() {
            const { fixedSidebar, isMobile, collapsed } = this
            const needSettingPadding = fixedSidebar && !isMobile
            const siderWidth = collapsed ? sidebarCollapsedWidth : sidebarWidth

            const paddingLeft = needSettingPadding ? siderWidth : undefined
            return { paddingLeft }
        },
        headerClassName() {
            const { fixedHeader } = this
            return { 'gongbao-fixed-header': fixedHeader }
        }
    },
    methods: {
    /**
     * 切换展开/关闭状态
     */
        toggleCollapse() {
            this.collapsed = !this.collapsed
        },
        /**
     * 响应媒体查询变更事件
     * @param val
     */
        handleMediaQueryChange(val) {
            this.query = val
            if (this.isMobile && !val['screen-xs']) {
                this.isMobile = false
                return
            }
            if (!this.isMobile && val['screen-xs']) {
                this.isMobile = true
                this.collapsed = true
                // this.settings.contentWidth = false
                // this.settings.fixSiderbar = false
            }
        },
        handleDrawerClose() {
            this.collapsed = true
        },
        onMenuSelect() {
            this.toggleCollapse()
        }
    }
}
</script>

<style lang="scss">
@import "~@assets/styles/varibles.scss";

.gongbao-layout {
  .gongbao-fixed-header {
    position: fixed;
    width: 100%;
    top: 0;
    transition: width 0.2s;
  }

  & &__sider-menu {
    top: $navbar-height;
  }

  &__main {
    transition: width, padding .2s;
    padding-bottom: 5px;
    background: $app-background-color;
  }

  &__footer {
    padding: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    transition: left 0.2s;
  }
}

</style>
