import { getLabelformList } from '@utils'
import { PROMOTE_FORM_TYPE_ENUM_LIST } from '@/constant/enum'

export default [
    // 名称   String    query
    // 标题   String    queryTitle
    // 类型   Integer  type
    {
        title: '表单名称',
        dataIndex: 'query',
        hideInTable: true
    },
    {
        title: '标题',
        width: '200px',
        hideInSearch: true,
        scopedSlots: { customRender: 'imgTitle' }
    },
    {
        title: '表单名称',
        dataIndex: 'name',
        width: '150px',
        hideInSearch: true
    },
    {
        title: '表单类型',
        width: '100px',
        dataIndex: 'type',
        customRender: getLabelformList(PROMOTE_FORM_TYPE_ENUM_LIST),
        valueType: 'select',
        initialValue: '',
        fieldProps: {
            options: [
                {
                    label: '全部',
                    value: ''
                }, ...PROMOTE_FORM_TYPE_ENUM_LIST
            ]
        }
    },
    {
        title: '标题',
        dataIndex: 'queryTitle',
        hideInTable: true
    },
    {
        title: '操作',
        hideInSearch: true,
        width: '50px',
        key: 'action',
        scopedSlots: { customRender: 'action' }
    }]
