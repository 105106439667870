<!-- 客户详情-跟进记录 -->
<template>
    <container-panel>
        <!-- 筛选 start -->
        <template #screen>
            <search-panel slot="header" @search="handleListSearch" @refresh="handleListRefresh" />
        </template>
        <!-- 筛选 end -->
        <!-- 权限和列表一样需要列表传过来 -->
        <template v-if="handleIsLoginUserId(params.userId)" #button>
            <a-button
                v-auth="addBtnAuth"
                icon="plus-circle"
                type="primary"
                @click="handleOpenModal('showFollowRecordModal')"
            >
                添加记录
            </a-button>
        </template>

        <!-- 表格 start -->
        <template #content>
            <a-table
                size="middle"
                :columns="columns"
                :data-source="data"
                :pagination="pagination"
                :loading="loading"
                row-key="id"
                @change="handleTableChange"
            >
                <!-- 跟进方式 -->
                <template slot="type" slot-scope="type">
                    {{ getApiEnumLabelFromList(CUSTOMER_FOLLOW_TYPE_ENUM_LIST, type) }}
                </template>
            </a-table>
        </template>
        <!-- 筛选 end -->

        <!-- 跟进记录弹框 -->
        <follow-record-modal
            :visible="showFollowRecordModal"
            title="添加跟进记录"
            :current-row="currentRow"
            @close="handleCloseModal('showFollowRecordModal')"
            @success="handleSuccessModal('showFollowRecordModal')"
        />
    </container-panel>
</template>

<script>
// 组件
import SearchPanel from './SearchPanel'
import FollowRecordModal from '@views/Customer/Components/FollowRecordModal'

// 常量
import { CUSTOMER_FOLLOW_TYPE_ENUM_LIST, SALES_CATEGORY_DISTRIBUTION } from '@/constant/enum'
import {
    DISTRIBUTION_CUSTOMER_FOLLOW_RECORD_BUTTON
} from '@constant/authEnum/distributionCustomer'
import {
    PROPRIETARY_CUSTOMER_DETAIL_MENU_CODE
} from '@constant/menu_code'

// mixins
import common from '../mixins'
import isLoginUserId from '@mixins/isLoginUserId'

// 接口请求
import getCustomerCommunicationSelectService from '@service/customer/getCustomerCommunicationSelectService'

export default {
    name: 'FollowRecordTabPanel',
    components: {
        SearchPanel,
        FollowRecordModal
    },
    mixins: [common, isLoginUserId],
    props: {
        params: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        const columns = [
            {
                title: '跟进ID',
                dataIndex: 'id',
                key: 'id'
            },
            {
                title: '跟进人',
                dataIndex: 'agentUserNameQuery',
                key: 'agentUserNameQuery',
                ellipsis: true
            },
            {
                title: '跟进时间',
                dataIndex: 'dataTime',
                key: 'dataTime'
            },
            {
                title: '跟进方式',
                dataIndex: 'type',
                key: 'type',
                scopedSlots: { customRender: 'type' }
            },
            {
                title: '跟进标题',
                dataIndex: 'title',
                key: 'title'
            },
            {
                title: '跟进内容',
                dataIndex: 'content',
                key: 'content',
                ellipsis: true
            },
            {
                title: '联系人',
                dataIndex: 'contactPerson',
                key: 'contactPerson',
                customRender: text => this.empty(text)
            },
            {
                title: '联系方式',
                dataIndex: 'contactPersonPhone',
                key: 'contactPersonPhone',
                customRender: text => this.empty(text)
            },
            {
                title: '下次跟进时间',
                dataIndex: 'nextDataTime',
                key: 'nextDataTime',
                customRender: text => this.empty(text)
            },
            {
                title: '下次跟进内容',
                dataIndex: 'nextContent',
                key: 'nextContent',
                ellipsis: true,
                customRender: text => this.empty(text)
            }
        ]

        return {
            CUSTOMER_FOLLOW_TYPE_ENUM_LIST,
            SALES_CATEGORY_DISTRIBUTION,
            DISTRIBUTION_CUSTOMER_FOLLOW_RECORD_BUTTON,
            PROPRIETARY_CUSTOMER_DETAIL_MENU_CODE,
            columns,
            customerId: '',
            // 查询条件
            searchParams: {},
            // 数据
            data: [],
            // 总记录数量
            total: 0,
            // 当前索引
            current: 1,
            // 加载状态
            loading: false,
            // 跟进记录弹框参数
            showFollowRecordModal: false,
            currentRow: {}
        }
    },
    computed: {
        // 添加记录-按钮权限
        addBtnAuth() {
            const { businessType } = this
            const result = businessType === SALES_CATEGORY_DISTRIBUTION ? DISTRIBUTION_CUSTOMER_FOLLOW_RECORD_BUTTON : PROPRIETARY_CUSTOMER_DETAIL_MENU_CODE
            return result
        }
    },
    watch: {
        'params.customerId': {
            handler(customerId) {
                if (!customerId) return

                this.customerId = customerId

                // 获取列表
                this.getList()
            },
            immediate: true
        }
    },
    mounted() {
    },
    methods: {
        /**
         * 获取 列表
         */
        async getList() {
            try {
                this.loading = true
                const {
                    // customerId,
                    searchParams,
                    current,
                    limit,
                    params
                } = this
                const { total, records } = await getCustomerCommunicationSelectService({
                    // customerId,
                    current,
                    size: limit,
                    ...params,
                    ...searchParams
                })
                this.total = total || 0
                this.data = records || []
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        },
        /**
         * 打开弹框
         */
        handleOpenModal(modalName) {
            this[modalName] = true
            this.currentRow = { customerId: this.customerId }
        },
        /**
         * 关闭弹框
         */
        handleCloseModal(modalName) {
            this[modalName] = false
        },
        /**
         * 成功弹框
         */
        handleSuccessModal(modalName) {
            this[modalName] = false
            this.current = 1
            this.getList()
        }
    }
}
</script>
