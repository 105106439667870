import { INVOICE_STATE_MENU_LIST, INVOICE_ORDER_TYPE_ENUM_LIST } from '@/constant/enum'
import { getLabelformList } from '@/utils'
import moment from 'moment'

export default [
    {
        hideInTable: true,
        title: '保单号',
        dataIndex: 'castInsuranceId'
    },
    {
        hideInTable: true,
        title: '发票状态',
        dataIndex: 'state',
        valueType: 'select',
        fieldProps: {
            options: [{
                label: '全部',
                value: ''
            }, ...INVOICE_STATE_MENU_LIST]
        }
    },
    {
        hideInTable: true,
        title: '投保人',
        dataIndex: 'castInsuranceEnterprise'
    },
    {
        hideInTable: true,
        title: '保险公司',
        dataIndex: 'insuranceCompany'
    },
    {
        hideInTable: true,
        title: '提交时间',
        dataIndex: 'submitTime',
        valueType: 'dateRange',
        span: 12,
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
        fieldProps: {
            showTime: { defaultValue: [moment('2022-01-01 00:00:00'), moment('2022-01-01 23:59:59')] },
            format: 'YYYY-MM-DD HH:mm:ss',
            disabledDate: () => false
        }
    },
    {
        hideInSearch: true,
        title: '保单号',
        dataIndex: 'insuranceNo',
        width: 200
    },
    {
        hideInSearch: true,
        title: '提交时间',
        dataIndex: 'applyTime',
        width: 180
    },
    {
        hideInSearch: true,
        title: '险种',
        dataIndex: 'dangerPlantedName',
        width: 180
    },
    {
        hideInSearch: true,
        title: '投保人',
        dataIndex: 'castInsuranceEnterprise',
        width: 180
    },
    {
        hideInSearch: true,
        title: '保费（元）',
        dataIndex: 'payMoney',
        width: 180
    },
    {
        hideInSearch: true,
        title: '保险公司',
        dataIndex: 'insuranceCompany',
        width: 180
    },
    {
        hideInSearch: true,
        title: '发票类型',
        dataIndex: 'invoiceType',
        customRender: getLabelformList(INVOICE_ORDER_TYPE_ENUM_LIST),
        width: 180
    },
    {
        hideInSearch: true,
        title: '状态',
        dataIndex: 'state',
        customRender: getLabelformList(INVOICE_STATE_MENU_LIST),
        width: 180
    },
    {
        hideInSearch: true,
        title: '操作',
        key: 'action',
        scopedSlots: { customRender: 'action' },

        width: 100,
        fixed: 'right'
    }
]
