/**
 * 千分位转换方法
 */
import {thousandBit} from '@utils'

export default {
    methods: {
        /**
         * 千分位转换
         * @param num
         * @returns {*}
         */
        thousandBit(num) {
            return thousandBit(num)
        }
    }
}