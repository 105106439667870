/**
 * 产品列表 模块
 */
import BasicLayout from '@layout/BasicLayout'

import {
    POLICY_ANALYSIS_LIST_G_MENU_CODE,
    POLICY_ANALYSIS_LIST_LIST_G_MENU_CODE
} from '@constant/menu_code'

const routes = [{
    path: '/policyAnalysisG',
    name: 'PolicyAnalysisG',
    redirect: '/PolicyAnalysisG/list',
    component: BasicLayout,
    code: POLICY_ANALYSIS_LIST_G_MENU_CODE,
    sort: 1,
    meta: {
        title: 'G端保单分析',
        icon: 'baohanguanli',
        iconType: 'iconfont'
    },
    children: [{
        path: 'list',
        name: 'PolicyAnalysisListG',
        component: () => import('./PolicyAnalysisListG/index'),
        hidden: true,
        code: POLICY_ANALYSIS_LIST_LIST_G_MENU_CODE,
        meta: {
            breadcrumb: false,
            keepAlive: true
        },
        children: []
    }]
}]

export default routes

