import { BASE_URL } from '@/config'

/**
 * 获取工作台信息
 */
export const AGENT_WORK_BENCH = {
    url: `${BASE_URL}/customer/customer/agentWorkbench`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 父级团队查询接口
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const QUERY_PARENT_TEAM = {
    url: `${BASE_URL}/user/team/queryParentTeam`,
    method: 'GET',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 消息列表
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const GET_MESSAGE_LIST = {
    url: `${BASE_URL}/user/user-message/select`,
    method: 'get',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 消息单条/全部已读
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const MESSAGE_UPDATE = {
    url: `${BASE_URL}/user/user-message/update`,
    method: 'put',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/** 产品
 * ————————————————————————————————————————————————————————————————————————————————————————————*/
/**
 * 险种选择
 *
 */
export const GET_INSURANCE_TYPE_LIST = {
    url: `${BASE_URL}/product/product-danger-planted/select`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}


/**
 * 获取个人佣金单条查询 佣金是否可见状态
 */
export const GET_INDIVIDUAL_COMMISSION = {
    url: `${BASE_URL}/finance/individual-commission/selectOne`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}
