<!-- 备注记录 -->
<template>
    <custom-modal
        title="备注记录"
        :visible="visible"
        :mask-closable="maskClosable"
        :width="800"
        @close="handleCancel"
        @ok="handleOk"
    >
        <div slot="customFooter" />
        <a-table
            size="middle"
            :columns="columns"
            :data-source="data"
            :pagination="pagination"
            :loading="loading"
            row-key="id"
            @change="handleTableChange"
        >
            <!-- 表格的序号递增 -->
            <template slot="sort" slot-scope="text, record, index">{{ index + 1 }}</template>
        </a-table>
    </custom-modal>
</template>
<script>
// 组件
import { Table as ATable } from 'ant-design-vue'
import CustomModal from '@components/CustomModal'

// 全局数据
import { DEFAULT_PAGES_SIZE } from '@config'
import {
    REMARK_TYPE_CLUE,
    REMARK_TYPE_CUSTOMER,
    REMARK_TYPE_BROKER
} from '@constant/enum'

// 混入
import tableShowTotal from '@mixins/tableShowTotal'

// 接口
import getRemarkListService from '@service/clue/getRemarkListService'
import getCustomerRemarkListService from '@service/customer/getCustomerRemarkListService'
import getBrokerRemarkListService from '@service/broker/getBrokerRemarkListService'

const INTERFACE_LIST = {
    [REMARK_TYPE_CLUE]: {
        url: getRemarkListService,
        idName: 'potentialCustomerId'
    },
    [REMARK_TYPE_CUSTOMER]: {
        url: getCustomerRemarkListService,
        idName: 'customerId'
    },
    [REMARK_TYPE_BROKER]: {
        url: getBrokerRemarkListService,
        idName: 'agentPeopleId'
    }
}

const columns = [
    {
        title: '序号',
        dataIndex: 'sort',
        key: 'sort',
        width: '15%',
        scopedSlots: { customRender: 'sort' }
    },
    {
        title: '备注时间',
        dataIndex: 'createDateTime',
        width: '30%',
        key: 'createDateTime'
    },
    {
        title: '操作人',
        dataIndex: 'createName',
        width: '20%',
        key: 'createName'
    },
    {
        title: '备注内容',
        dataIndex: 'content',
        key: 'content',
        width: '35%',
        ellipsis: true
    }
]

export default {
    name: 'RemarkRecordModal',
    components: {
        ATable,
        CustomModal
    },
    mixins: [tableShowTotal],
    props: {
        visible: {
            type: Boolean,
            default() {
                return false
            }
        },
        id: {
            type: String,
            default() {
                return ''
            }
        },
        businessType: {
            // 业务类型，用于区分客户、线索、经纪人
            type: String,
            default() {
                return ''
            }
        }
    },
    data() {
        return {
            REMARK_TYPE_CLUE, // 业务类型-线索
            REMARK_TYPE_CUSTOMER, // 业务类型-客户
            REMARK_TYPE_BROKER, // 业务类型-经纪人
            // 加载状态
            loading: false,
            // table配置
            columns,
            // 表格数据
            data: [],
            // 分页数量
            limit: DEFAULT_PAGES_SIZE,
            // 当前索引
            current: 1,
            // 总记录数量
            total: 0,
            // 当前索引
            maskClosable: false
        }
    },
    computed: {
        pagination() {
            const { total, limit, current, tableShowTotal } = this
            return {
                showTotal: (total, range) => tableShowTotal(total, range),
                current,
                total,
                pageSize: limit,
                showSizeChanger: true,
                showQuickJumper: true
            }
        },
        watchProp() {
            const { id, visible } = this
            return {
                id,
                visible
            }
        }
    },
    watch: {
        watchProp: {
            handler(val) {
                const { visible, id } = val
                id && visible && this.getList()
            },
            immediate: true
        }
    },
    methods: {
        /**
         * 提交按钮事件
         */
        handleOk() {
            this.$refs.formPanel.handleSend()
        },
        /**
         * 按钮loading
         */
        handleLoading(loading) {
            this.loading = loading
        },
        /**
         * 关闭弹框
         */
        handleCancel() {
            this.$emit('close')
        },
        /**
         * 响应 table change 事件
         * @param pagination
         */
        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.current = current
            this.limit = pageSize
            this.getList()
        },
        /**
         * 拉取表格数据
         */
        async getList() {
            try {
                this.loading = true
                const { params, current, limit, id, businessType } = this
                const { total, records } = await INTERFACE_LIST[businessType][
                    'url'
                ]({
                    current,
                    size: limit,
                    [INTERFACE_LIST[businessType]['idName']]: id,
                    ...params
                })
                this.total = total || 0
                this.data = records
            } catch (e) {
                console.log(e)
                this.$message.error(e.message)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>
<style lang="scss">
</style>
