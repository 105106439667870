import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import {Integrations} from '@sentry/tracing'


const dsn = process.env.VUE_APP_SENTRY_DSN || ''
const OPEN_SENTRY = process.env.VUE_APP_SENTRY_SWITCH === 'true'
const LOG_ERROR = process.env.VUE_APP_SENTRY_LOG_ERROR === 'true'
const LEVEL = {
    fatal: 'fatal',
    error: 'error',
    warning: 'warning',
    log: 'log',
    info: 'info',
    debug: 'debug',
    critical: 'critical'
}

if (OPEN_SENTRY) {
    Sentry.init({
        Vue,
        dsn: dsn,
        integrations: [new Integrations.BrowserTracing()],
        logErrors: LOG_ERROR,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    })
}

/**
 * 异常捕获
 * @param error
 */
export function captureException(error) {
    if (OPEN_SENTRY) {
        Sentry.captureException(error)
    }
}

/**
 * 消息输出
 * @param message
 * @param level
 */
export function captureMessage(message, level) {
    if (OPEN_SENTRY) {
        const lev = LEVEL[level] || LEVEL.info
        Sentry.captureMessage(message, lev)
    }
}

/**
 * 设置用户信息
 * @param user
 */
export function setUser(user) {
    if (OPEN_SENTRY) {
        Sentry.setUser(user)
    }
}