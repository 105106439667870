<template>
    <!--  管家 ServeSteward-->
    <a-select
        v-model="serveSteward"
        show-search
        placeholder="请选择"
        @blur="handleBlur"
        @change="handleChange"
    >
        <a-select-option
            v-for="item in dataList"
            :key="item.agentUserId"
            :value="item.agentUserId"
            :label="item.agentUserName"
        >{{ item.agentUserName }}</a-select-option>
    </a-select>
</template>

<script>
import { Select as ASelect } from 'ant-design-vue'
import getStewardListService from '@service/getStewardListService'
export default {
    name: 'Steward',
    props: {
        value: {
            type: [String, Number],
            default() {
                return ''
            }
        },
        type: {
            type: [String, Number],
            default() {
                return ''
            }
        }
    },
    components: {
        ASelect,
        ASelectOption: ASelect.Option
    },
    data() {
        return {
            dataList: []
        }
    },
    created() {
        this.getList()
    },
    computed: {
        serveSteward: {
            get() {
                return this.value || undefined
            },
            set(val) {
                this.$emit('input', val)
            }
        }
    },
    methods: {
        // 选择的name
        handleChange(val) {
            this.$emit('change', val)
        },
        handleBlur() {
            this.$emit('blur')
        },
        async getList() {
            try {
                const { type } = this
                const res = await getStewardListService({
                    type
                })
                this.dataList = res[0] || []
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            }
        }
    }
}
</script>

<style lang='scss'>
</style>