<template>
    <search-panel
        :model="form"
        layout="vertical"
        @search="handleSearch"
        @refresh="handleRefresh"
    >
        <form-col label="签单日期" prop="signDate">
            <template :slot="formColDefaultSlot">
                <a-select v-model="signDate" placeholder="请选择">
                    <template v-for="item in signDateList">
                        <a-select-option :key="item.value" :value="item.value">{{ item.label }}</a-select-option>
                    </template>
                </a-select>
            </template>
        </form-col>

        <form-col
            v-if="signDate===SIGNING_DATE_THIS_CUSTOM"
            :span="16"
            label="请选择"
            prop="signDateSelect"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
        >
            <template :slot="formColDefaultSlot">
                <a-range-picker
                    v-model="signDateSelect"
                    :mode="signDateSelectMode"
                    :show-time="showTimeOptions"
                />
            </template>
        </form-col>
        <form-col label="平台系统" prop="name">
            <template :slot="formColDefaultSlot">
                <a-select
                    v-model.trim="externalSystemName"
                    show-search
                    placeholder="请输入"
                    option-filter-prop="children"
                    :filter-option="false"
                    :allow-clear="true"
                    :show-arrow="false"
                    @change="handlePlatformNameChange($event,'platformSystemLoading','platformSystemSelectList','externalSystemName','platformSystemCode')"
                    @search="getPlatformNameSelect($event,'platformSystemLoading','platformSystemSelectList','externalSystemName','platformSystemCode')"
                >
                    <a-spin v-if="platformSystemLoading" slot="notFoundContent" size="small" type="loading" />
                    <a-select-option v-for="item in platformSystemSelectList" :key="item.id" :value="`${item.externalSystemName}`">
                        {{ item.externalSystemName }}
                    </a-select-option>
                </a-select>
            </template>
        </form-col>
        <form-col label="险种" prop="dangerPlantedId">
            <template :slot="formColDefaultSlot">
                <a-select v-model="form.dangerPlantedId" placeholder="请选择险种">
                    <a-select-option value>全部</a-select-option>
                    <template v-for="item in insureList">
                        <a-select-option :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
                    </template>
                </a-select>
            </template>
        </form-col>
        <!-- <template slot="expansion"> -->
        <form-col label="保险公司" prop="insuranceEnterpriseId">
            <template :slot="formColDefaultSlot">
                <a-select v-model="form.insuranceEnterpriseId" placeholder="请选择保险公司">
                    <a-select-option value>全部</a-select-option>
                    <template v-for="item in insureCompanyList">
                        <a-select-option :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
                    </template>
                </a-select>
            </template>
        </form-col>
        <!-- </template> -->
    </search-panel>
</template>

<script>
// 组件
import {
    Select as ASelect,
    DatePicker as ADatePicker
} from 'ant-design-vue'
import SearchPanel from '@components/BaseSearchPanel'
import FormCol from '@components/FormCol'

// 全局数据
import {
    SIGNING_DATE_TYPE_ENUM_LIST,
    SIGNING_DATE_THIS_MONTH,
    SIGNING_DATE_THIS_CUSTOM,
    SIGNING_DATE_TODAY,
    SIGNING_DATE_THIS_YEAR,
    INSURANCE_TYPE_OPEN
} from '@constant/enum'

// 工具类
import { filterParams } from '@utils/search.js'
import moment from 'moment'

// 接口请求
import getInsuranceTypeListService from '@service/workSpace/getInsuranceTypeListService'
import getInsureCompanyListService from '@service/insureCompany/getInsureCompanyListService'
import getPlatformSystemSelectListService from '@service/policyAnalysisG/getPlatformSystemSelectListService'

export default {
    name: 'PolicyAnalysisSearchPanel',
    components: {
        SearchPanel,
        FormCol,
        ASelect,
        ASelectOption: ASelect.Option,
        ARangePicker: ADatePicker.RangePicker
    },
    data() {
        return {
            SIGNING_DATE_THIS_CUSTOM,
            form: {
                // 签单开始时间
                singleDateTimeStart: '',
                // 签单结束时间
                singleDateTimeEnd: '',
                // 平台系统
                platformSystemCode: '',
                // 险种
                dangerPlantedId: '',
                // 保险公司
                insuranceEnterpriseId: ''
            },
            // 签单日期
            signDate: SIGNING_DATE_THIS_MONTH,
            // 签单日期自定义
            signDateSelect: [],
            // 签单时间
            signDateSelectMode: ['date', 'date'],
            showTimeOptions: {
                defaultValue: [
                    moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')
                ]
            },
            signDateList: SIGNING_DATE_TYPE_ENUM_LIST,
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
            // 平台系统数据
            platformSystemLoading: false,
            platformSystemSelectList: [],
            externalSystemName: undefined,
            // 险种下拉数据
            insureList: [],
            // 保险公司
            insureCompanyList: [],
            // 筛选-插槽
            formColDefaultSlot: 'formElements'

        }
    },
    computed: {
        // 用户信息缓存
        userInfo() {
            return this.$store.getters.userInfo
        }
    },
    watch: {
        signDate: {
            handler(val) {
                console.log(val, '3333')
                this.$emit('date-change', val)
                if (val !== SIGNING_DATE_THIS_CUSTOM) {
                    this.signDateSelect = []
                    this.getFormTime(val, this.signDateSelect)
                    this.handleSearch()
                } else {
                    this.getFormTime(val, this.signDateSelect)
                }
            }
            // immediate: true
        },
        signDateSelect: {
            handler(val) {
                this.signDateSelect = val
                this.getFormTime(this.signDate, this.signDateSelect)
            },
            immediate: true
        }
    },
    mounted() {
        this.init()
        this.getInsureList()
        this.getInsureCompanyList()
        this.getPlatformNameSelect('', 'platformSystemLoading', 'platformSystemSelectList')
    },
    methods: {
        moment,
        init() {
            const { form } = this
            this.$emit('get-init', filterParams(form))
        },
        /**
         * 获取平台系统数据
         */
        async getPlatformNameSelect(externalSystemNameQuery, loading, list) {
            // if (!externalSystemNameQuery) {
            //     this[list] = []
            //     this[loading] = false
            //     return false
            // }
            const { userInfo } = this
            const { id } = userInfo || {}
            try {
                this[loading] = true
                const data = await getPlatformSystemSelectListService({
                    // assignment: false,
                    authUserId: id,
                    externalSystemNameQuery
                })
                this[list] = this.unique(data) || []
                this.$emit('system-change', externalSystemNameQuery)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this[loading] = false
            }
        },
        handlePlatformNameChange(externalSystemNameQuery, loading, list, name, id) {
            if (!externalSystemNameQuery) {
                // this[list] = []
                this.getPlatformNameSelect(externalSystemNameQuery, loading, list, name, id)
            } else {
                this.getPlatformNameSelect(externalSystemNameQuery, loading, list, name, id)
            }
            let result = this[list].find(item => item.externalSystemName === externalSystemNameQuery)
            this.form[id] = result && result.externalSystemCode || ''
            this.$emit('system-change', externalSystemNameQuery)
        },
        /**
         * 获取启用的险种
         */
        async getInsureList() {
            try {
                const data = await getInsuranceTypeListService({ enabled: INSURANCE_TYPE_OPEN })
                this.insureList = data || []
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {}
        },
        /**
         * 获取保险公司
         */
        async getInsureCompanyList() {
            try {
                const data = await getInsureCompanyListService()
                this.insureCompanyList = data || []
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {}
        },
        /**
         * 响应搜索事件
         */
        handleSearch() {
            const { form } = this
            const { singleDateTimeStart, singleDateTimeEnd } = form
            if (!singleDateTimeStart || !singleDateTimeEnd) {
                this.$message.error('请选择自定义签单日期')
                return false
            }
            this.$emit('search', filterParams(form))
        },
        /**
         * 响应刷新事件
         */
        handleRefresh() {
            const { form } = this
            this.resetData()
            this.$emit('refresh', filterParams(form))
        },
        /**
         *
         * 刷新置空数据
         */
        resetData() {
            this.form.platformSystemCode = ''
            this.externalSystemName = undefined
            this.signDate = SIGNING_DATE_THIS_MONTH
            this.signDateSelect = []
            this.insureDate = undefined
            this.insureDateSelect = []
            this.getFormTime(this.signDate, this.signDateSelect)
            this.getPlatformNameSelect('', 'platformSystemLoading', 'platformSystemSelectList')
        },
        /**
         * 获取时间
         */
        getTime(val, list, start, end) {
            // const { signDate, signDateSelect } = this
            let date = new Date()
            let monthFirstDay = new Date(date.getFullYear(), date.getMonth(), 1)
            let monthLastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
            let year = date.getFullYear()
            let [startTime, endTime] = list || []
            switch (val) {
                case SIGNING_DATE_TODAY: // 今天
                    return {
                        [start]: moment(date).format('YYYY-MM-DD 00:00:00'),
                        [end]: moment(date).format('YYYY-MM-DD 23:59:59')
                    }
                case SIGNING_DATE_THIS_YEAR: // 今年
                    return {
                        [start]: `${year}-01-01 00:00:00`,
                        [end]: `${year}-12-31 23:59:59`
                    }
                case SIGNING_DATE_THIS_CUSTOM: // 自定义
                    return {
                        [start]: startTime ? `${moment(startTime).format('YYYY-MM-DD HH:mm:ss')}` : '',
                        [end]: endTime ? `${moment(endTime).format('YYYY-MM-DD HH:mm:ss')}` : ''
                    }
                case SIGNING_DATE_THIS_MONTH: // 当月
                    return {
                        [start]: moment(monthFirstDay).format('YYYY-MM-DD 00:00:00'),
                        [end]: moment(monthLastDay).format('YYYY-MM-DD 23:59:59')
                    }
                case undefined: // 保险止期可以不传
                    return {
                        [start]: '',
                        [end]: ''
                    }
            }
        },
        getFormTime(val, list) {
            let { startSignTime, endSignTime } = this.getTime(val, list, 'startSignTime', 'endSignTime') || {}
            this.form.singleDateTimeStart = startSignTime
            this.form.singleDateTimeEnd = endSignTime
        },
        /**
         * 数组去重，防止相同系统名点击崩溃bug
         */
        unique(arr) {
            if (arr) {
                for (let i = 0; i < arr.length; i++) {
                    for (let j = i + 1; j < arr.length; j++) {
                        if (arr[i].externalSystemCode === arr[j].externalSystemCode) { // 第一个等同于第二个，splice方法删除第二个
                            arr.splice(j, 1)
                            j--
                        }
                    }
                }
            }
            return arr
        }
    }
}
</script>

<style lang="scss">
.sign-date-customer-fit {
    .ant-col-8 {
        padding-left: 8px;
        padding-right: 8px;
    }
}
</style>
