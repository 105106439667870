<template>
    <search-panel
        :model="form"
        :is-expansion="expansion"
        layout="vertical"
        @search="handleSearch"
        @refresh="handleRefresh"
    >
        <!-- 签单日期/保险起期/保险止期合并搜索 -->
        <a-col :span="6" class="select-compact a-col-custom">
            <a-input-group compact>
                <a-select v-model="selectValue" style="width: 35%">
                    <a-select-option v-for="item in selectList" :key="item.key" :value="item.key">
                        {{ item.value }}
                    </a-select-option>
                </a-select>
                <a-select v-model="selectDateValue" :placeholder="selectListPlaceholder" :allow-clear="selectValue!=='singleTime'" style="width: 65%">
                    <template v-for="item in selectDateList">
                        <a-select-option :key="item.value" :value="item.value">{{ item.label }}</a-select-option>
                    </template>
                </a-select>
            </a-input-group>
        </a-col>
        <form-col
            v-if="selectDateValue===SIGNING_DATE_THIS_CUSTOM"
            :span="12"
            label="请选择"
            prop="selectDateSelect"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
        >
            <template :slot="formColDefaultSlot">
                <a-range-picker
                    v-model="selectDateSelect"
                    :mode="selectDateSelectMode"
                    :show-time="showTimeOptions"
                />
            </template>
        </form-col>
        <!-- 签单日期未自定义 保险止期自定义样式适配 -->
        <!-- <a-row v-if="insureDate===SIGNING_DATE_THIS_CUSTOM && signDate!==SIGNING_DATE_THIS_CUSTOM" class="sign-date-customer-fit">
            <form-col label="签单日期" prop="signDate">
                <template :slot="formColDefaultSlot">
                    <a-select v-model="signDate" placeholder="请选择签单日期">
                        <template v-for="item in selectDateList">
                            <a-select-option :key="item.value" :value="item.value">{{ item.label }}</a-select-option>
                        </template>
                    </a-select>
                </template>
            </form-col>
            <form-col
                v-if="signDate===SIGNING_DATE_THIS_CUSTOM"
                :span="16"
                label="请选择"
                prop="signDateSelect"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
            >
                <template :slot="formColDefaultSlot">
                    <a-range-picker
                        v-model="signDateSelect"
                        :mode="signDateSelectMode"
                        :show-time="showTimeOptions"
                    />
                </template>
            </form-col>
        </a-row>
        <template v-else>
            <form-col label="签单日期" prop="signDate">
                <template :slot="formColDefaultSlot">
                    <a-select v-model="signDate" placeholder="请选择签单日期">
                        <template v-for="item in selectDateList">
                            <a-select-option :key="item.value" :value="item.value">{{ item.label }}</a-select-option>
                        </template>
                    </a-select>
                </template>
            </form-col>
            <form-col
                v-if="signDate===SIGNING_DATE_THIS_CUSTOM"
                :span="16"
                label="请选择"
                prop="signDateSelect"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
            >
                <template :slot="formColDefaultSlot">
                    <a-range-picker
                        v-model="signDateSelect"
                        :mode="signDateSelectMode"
                        :show-time="showTimeOptions"
                    />
                </template>
            </form-col>
        </template> -->
        <!-- <form-col label="保险止期" prop="insureDate">
                <template :slot="formColDefaultSlot">
                    <a-select v-model="insureDate" placeholder="请选择保险止期" :allow-clear="true">
                        <template v-for="item in selectDateList">
                            <a-select-option :key="item.value" :value="item.value">{{ item.label }}</a-select-option>
                        </template>
                    </a-select>
                </template>
            </form-col>
            <form-col
                v-if="insureDate===SIGNING_DATE_THIS_CUSTOM"
                :span="16"
                label="请选择"
                prop="insureDateSelect"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
            >
                <template :slot="formColDefaultSlot">
                    <a-range-picker
                        v-model="insureDateSelect"
                        :mode="signDateSelectMode"
                        :show-time="showTimeOptions"
                    />
                </template>
            </form-col> -->
        <form-col label="投保用户" prop="submitMobile">
            <template :slot="formColDefaultSlot">
                <a-input v-model.trim="form.submitMobile" placeholder="请输入投保用户" />
            </template>
        </form-col>
        <form-col label="经纪人" prop="name">
            <template :slot="formColDefaultSlot">
                <a-select
                    v-model.trim="personalName"
                    show-search
                    placeholder="请输入关键字选择成员"
                    option-filter-prop="children"
                    :filter-option="false"
                    :allow-clear="true"
                    :show-arrow="false"
                    disabled
                    @change="handleNameChange"
                    @search="getNameSelect"
                >
                    <a-spin v-if="personalLoading" slot="notFoundContent" size="small" type="loading" />
                    <a-select-option v-for="item in personalSelectList" :key="item.id" :value="`${item.userName} （${item.userId}）`">
                        {{ item.userName || '-' }} （{{ item.userId }}）
                    </a-select-option>
                </a-select>
            </template>
        </form-col>
        <form-col label="险种" prop="dangerPlantedId">
            <template :slot="formColDefaultSlot">
                <a-select v-model="form.dangerPlantedId" placeholder="请选择险种">
                    <a-select-option value>全部</a-select-option>
                    <template v-for="item in insureList">
                        <a-select-option :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
                    </template>
                </a-select>
            </template>
        </form-col>
        <form-col label="保单号" prop="policyNo">
            <template :slot="formColDefaultSlot">
                <a-input v-model.trim="form.policyNo" placeholder="请输入保单号" />
            </template>
        </form-col>
        <form-col label="保单状态" prop="state">
            <template :slot="formColDefaultSlot">
                <a-select v-model="form.state" placeholder="请选择保单状态">
                    <a-select-option value>全部</a-select-option>
                    <template v-for="item in policyStatusList">
                        <a-select-option :key="item.value" :value="item.label">{{ item.label }}</a-select-option>
                    </template>
                </a-select>
            </template>
        </form-col>
        <template :slot="slotValue">
            <form-col label="保险公司" prop="insuranceEnterpriseId">
                <template :slot="formColDefaultSlot">
                    <a-select v-model="form.insuranceEnterpriseId" placeholder="请选择">
                        <a-select-option value>全部</a-select-option>
                        <template v-for="item in insureCompanyList">
                            <a-select-option :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
                        </template>
                    </a-select>
                </template>
            </form-col>
            <form-col label="投保人" prop="castEntepriseNameLike">
                <template :slot="formColDefaultSlot">
                    <a-input v-model.trim="form.castEntepriseNameLike" placeholder="请输入投保人" />
                </template>
            </form-col>
        </template>
        <template :slot="searchExpansionSlot">
            <form-col label="被保人" prop="insuredEnterpriseNameLike">
                <template :slot="formColDefaultSlot">
                    <a-input v-model.trim="form.insuredEnterpriseNameLike" placeholder="请输入被保人" />
                </template>
            </form-col>
            <form-col label="项目名称" prop="projectNameLike">
                <template :slot="formColDefaultSlot">
                    <a-input v-model="form.projectNameLike" placeholder="请输入" />
                </template>
            </form-col>
        </template>
    </search-panel>
</template>

<script>
import debounce from 'lodash/debounce'
// 组件
import {
    Select as ASelect,
    DatePicker as ADatePicker,
    Input as AInput,
    Col as ACol
} from 'ant-design-vue'
import SearchPanel from '@components/BaseSearchPanel'
import FormCol from '@components/FormCol'

// 全局数据
import {
    SIGNING_DATE_TYPE_ENUM_LIST,
    SIGNING_DATE_THIS_MONTH,
    SIGNING_DATE_THIS_CUSTOM,
    SIGNING_DATE_TODAY,
    SIGNING_DATE_THIS_YEAR,
    INSURANCE_TYPE_OPEN,
    POLICY_ORDER_STATUS_ENUM_LIST,
    CUSTOMER_POLICY_BUSINESS_TYPE_TEAM
} from '@constant/enum'

// 工具类
import { filterParams } from '@utils/search.js'
import moment from 'moment'

// 接口请求
import getTeamUserSelectListService from '@/service/customer/getTeamUserSelectListService'
import getInsuranceTypeListService from '@service/workSpace/getInsuranceTypeListService'
import getInsureCompanyListService from '@service/insureCompany/getInsureCompanyListService'

export default {
    name: 'PolicyManageSearchPanel',
    components: {
        SearchPanel,
        FormCol,
        ASelect,
        ASelectOption: ASelect.Option,
        ARangePicker: ADatePicker.RangePicker,
        AInput,
        ACol,
        AInputGroup: AInput.Group
    },
    data() {
        this.getNameSelect = debounce(this.getNameSelect, 500)
        return {
            SIGNING_DATE_THIS_CUSTOM,
            form: {
                // 投保用户
                submitMobile: '',
                // 险种
                dangerPlantedId: '',
                // 保单号
                policyNo: '',
                // 投保人
                castEntepriseNameLike: '',
                // 被保人
                insuredEnterpriseNameLike: '',
                // 保单状态
                state: '',
                // 保险公司
                insuranceEnterpriseId: '',
                // 项目名称
                projectNameLike: ''
            },
            // 签单日期
            signDate: SIGNING_DATE_THIS_MONTH,
            // 签单日期自定义
            signDateSelect: [],
            // 签单时间
            signDateSelectMode: ['date', 'date'],
            showTimeOptions: {
                defaultValue: [
                    moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')
                ]
            },
            selectDateList: SIGNING_DATE_TYPE_ENUM_LIST,
            // 保险止期
            insureDate: undefined,
            // 保险止期自定义
            insureDateSelect: [],
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
            // 成员姓名搜索数据
            personalLoading: false,
            personalSelectList: [],
            personalName: undefined,
            // 险种下拉数据
            insureList: [],
            // 保单状态下拉数据
            policyStatusList: POLICY_ORDER_STATUS_ENUM_LIST,
            // 保险公司下拉数据
            insureCompanyList: [],
            // 搜索组件是否展开
            expansion: false,
            // 签单日期/保险起期/保险止期合并相关参数
            selectList: [
                {
                    key: 'singleTime',
                    value: '签单日期'
                }, {
                    key: 'startInsuranceTime',
                    value: '保险起期'
                }, {
                    key: 'endInsuranceTime',
                    value: '保险止期'
                }
            ],
            selectValue: 'singleTime',
            selectListPlaceholder: '请选择签单日期',
            selectDateSelectMode: ['date', 'date'],
            selectDateValue: SIGNING_DATE_THIS_MONTH,
            selectDateSelect: [],
            selectStartTime: '',
            selectEndTime: '',
            isRepeat: false,
            // 筛选-插槽
            formColDefaultSlot: 'formElements',
            searchExpansionSlot: 'expansion',
            searchSpecialSlot: 'special'
        }
    },
    computed: {
        // 控制是否展开收起
        slotValue() {
            const { selectDateValue, searchExpansionSlot, searchSpecialSlot } = this
            return selectDateValue === SIGNING_DATE_THIS_CUSTOM ? searchExpansionSlot : searchSpecialSlot
        },
        // 用户信息缓存
        userInfo() {
            return this.$store.getters.userInfo
        }
    },
    watch: {
        // signDate: {
        //     handler(val) {
        //         if (val !== SIGNING_DATE_THIS_CUSTOM) {
        //             this.signDateSelect = []
        //             this.getFormTime(val, this.signDateSelect, 'signDate')
        //             this.handleSearch()
        //         } else {
        //             this.getFormTime(val, this.signDateSelect, 'signDate')
        //         }
        //     },
        //     // immediate: true
        // },
        // signDateSelect: {
        //     handler(val) {
        //         this.signDateSelect = val
        //         this.getFormTime(this.signDate, this.signDateSelect, 'signDate')
        //     },
        //     immediate: true
        // },
        // insureDate: {
        //     handler(val) {
        //         if (val !== SIGNING_DATE_THIS_CUSTOM) {
        //             this.insureDateSelect = []
        //             this.getFormTime(val, this.insureDateSelect, 'insureDate')
        //             this.handleSearch()
        //         } else {
        //             this.getFormTime(val, this.insureDateSelect, 'insureDate')
        //         }
        //     },
        //     // immediate: true
        // },
        // insureDateSelect: {
        //     handler(val) {
        //         this.insureDateSelect = val
        //         this.getFormTime(this.insureDate, this.insureDateSelect, 'insureDate')
        //     },
        //     immediate: true
        // },
        selectDateValue: {
            handler(val) {
                if (val !== SIGNING_DATE_THIS_CUSTOM && !this.isRepeat) {
                    this.selectDateSelect = []
                    this.getFormTimeNew(val, this.selectDateSelect)
                    this.handleSearch()
                } else {
                    this.getFormTimeNew(val, this.selectDateSelect)
                }
                this.isRepeat = false
            }
            // immediate: true
        },
        selectDateSelect: {
            handler(val) {
                this.selectDateSelect = val
                this.getFormTimeNew(this.selectDateValue, this.selectDateSelect)
            },
            immediate: true
        },
        $route: {
            handler(route) {
                const { userInfo } = this
                const { id, name } = userInfo
                const { userId, userName, businessType, signDate, signingStartDate, signingEndDate } = route.query
                // 团队详情跳转过来
                if (businessType && businessType === CUSTOMER_POLICY_BUSINESS_TYPE_TEAM) {
                    // 团队详情携带经纪人参数
                    this.getNameSelect(userName)
                    this.personalName = `${userName || '-'} （${userId}）`
                    this.form.agentId = userId
                    this.expansion = true
                    // 团队详情携带签单时间
                    this.selectValue = 'singleTime',
                    this.selectDateValue = signDate
                    this.form.beginSingleTime = signingStartDate
                    this.form.endSingleTime = signingEndDate
                    // 自定义时间回显处理
                    if (signDate === SIGNING_DATE_THIS_CUSTOM) {
                        this.selectDateSelect = [signingStartDate, signingEndDate]
                    }
                } else {
                    // 默认登录用户
                    this.getNameSelect(name)
                    this.personalName = `${name || '-'} （${id}）`
                    this.form.agentId = id
                    this.expansion = true
                }
            },
            immediate: true
        },
        selectValue: {
            handler(val) {
                let filterList = this.selectList.filter(item => item.key === val)
                let filterName = filterList && filterList.length && filterList[0].value
                this.selectListPlaceholder = `请选择${filterName}`
                if (val === 'singleTime' && !this.selectDateValue) {
                    this.selectDateValue = SIGNING_DATE_THIS_MONTH
                }
                if (val !== 'singleTime') {
                    this.selectDateValue = undefined
                }
                this.getFormTimeNew(this.selectDateValue, this.selectDateSelect)
            },
            immediate: true
        }
    },
    mounted() {
        this.init()
        this.getInsureList()
        this.getInsureCompanyList()
    },
    methods: {
        moment,
        init() {
            const { form } = this
            this.$emit('get-init', filterParams(form))
        },
        /**
         * 获取人员名称数据
         */
        async getNameSelect(userNameQuery) {
            if (!userNameQuery) {
                this.personalSelectList = []
                this.personalLoading = false
                return false
            }
            try {
                this.personalLoading = true
                const data = await getTeamUserSelectListService({
                    backMapQueryResult: false,
                    userNameQuery
                })
                this.personalSelectList = data || []
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.personalLoading = false
            }
        },
        /**
         * 获取启用的险种
         */
        async getInsureList() {
            try {
                const data = await getInsuranceTypeListService({ enabled: INSURANCE_TYPE_OPEN })
                this.insureList = data || []
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {}
        },
        /**
         * 获取保险公司
         */
        async getInsureCompanyList() {
            try {
                const data = await getInsureCompanyListService()
                this.insureCompanyList = data || []
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {}
        },
        handleNameChange(value) {
            if (!value) {
                this.personalSelectList = []
            } else {
                let splitValue = value && value.split(' ')[0]
                this.getNameSelect(splitValue)
            }
            this.getNameId(value)
        },
        /**
         * 根据name获取id
         */
        getNameId(value) {
            const { personalSelectList } = this
            let splitValue = value && value.split(' ')[0]
            let splitValueId = value && value.split(' ')[1]
            let result = personalSelectList.find(item => item.userName === splitValue && `（${item.userId}）` === splitValueId)
            this.form.agentId = result && result.userId || ''
        },
        /**
         * 响应搜索事件
         */
        handleSearch() {
            const { selectStartTime, selectEndTime, selectDateValue, form } = this
            if (selectDateValue === SIGNING_DATE_THIS_CUSTOM && (!selectStartTime || !selectEndTime)) {
                this.$message.error('请选择自定义日期')
                return false
            }
            this.$emit('search', filterParams(form))
        },
        /**
         * 响应刷新事件
         */
        handleRefresh() {
            const { form } = this
            this.resetData()
            this.isRepeat = true
            this.$emit('refresh', filterParams(form))
        },
        /**
         *
         * 刷新置空数据
         */
        resetData() {
            // this.personalName = undefined
            // this.form.agentId = ''
            // this.personalSelectList = []
            this.selectDateValue = SIGNING_DATE_THIS_MONTH
            this.selectDateSelect = []
            this.selectValue = 'singleTime',
            this.getFormTimeNew(this.selectDateValue, this.selectDateSelect)
            // this.getFormTime(this.insureDate, this.insureDateSelect, 'insureDate')
        },
        /**
         * 获取时间
         */
        getTime(val, list, start, end) {
            // const { signDate, signDateSelect } = this
            let date = new Date()
            let monthFirstDay = new Date(date.getFullYear(), date.getMonth(), 1)
            let monthLastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
            let year = date.getFullYear()
            let [startTime, endTime] = list || []
            switch (val) {
                case SIGNING_DATE_TODAY: // 今天
                    return {
                        [start]: moment(date).format('YYYY-MM-DD 00:00:00'),
                        [end]: moment(date).format('YYYY-MM-DD 23:59:59')
                    }
                case SIGNING_DATE_THIS_YEAR: // 今年
                    return {
                        [start]: `${year}-01-01 00:00:00`,
                        [end]: `${year}-12-31 23:59:59`
                    }
                case SIGNING_DATE_THIS_CUSTOM: // 自定义
                    return {
                        [start]: startTime ? `${moment(startTime).format('YYYY-MM-DD HH:mm:ss')}` : '',
                        [end]: endTime ? `${moment(endTime).format('YYYY-MM-DD HH:mm:ss')}` : ''
                    }
                case SIGNING_DATE_THIS_MONTH: // 当月
                    return {
                        [start]: moment(monthFirstDay).format('YYYY-MM-DD 00:00:00'),
                        [end]: moment(monthLastDay).format('YYYY-MM-DD 23:59:59')
                    }
                case undefined: // 保险起期/止期可以不传
                    return {
                        [start]: '',
                        [end]: ''
                    }
            }
        },
        // getFormTime(val, list, type) {
        //     if (type === 'signDate') {
        //         let { startSignTime, endSignTime } = this.getTime(val, list, 'startSignTime', 'endSignTime') || {}
        //         this.form.startSignTime = startSignTime
        //         this.form.endSignTime = endSignTime
        //     } else {
        //         let { startInsuranceTime, endInsuranceTime } = this.getTime(val, list, 'startInsuranceTime', 'endInsuranceTime') || {}
        //         this.form.startInsuranceTime = startInsuranceTime
        //         this.form.endInsuranceTime = endInsuranceTime
        //     }
        // },
        getFormTimeNew(val, list) {
            const { selectValue } = this
            let { selectStartTime, selectEndTime } = this.getTime(val, list, 'selectStartTime', 'selectEndTime') || {}
            this.selectStartTime = selectStartTime
            this.selectEndTime = selectEndTime
            switch (selectValue) {
                case 'startInsuranceTime': // 保险起期
                    let { beginStartInsuranceTime, stopStartInsuranceTime } = this.getTime(val, list, 'beginStartInsuranceTime', 'stopStartInsuranceTime') || {}
                    this.form.beginStartInsuranceTime = beginStartInsuranceTime
                    this.form.stopStartInsuranceTime = stopStartInsuranceTime
                    this.form.beginEndInsuranceTime = ''
                    this.form.stopEndInsuranceTime = ''
                    this.form.beginSingleTime = ''
                    this.form.endSingleTime = ''
                    break
                case 'endInsuranceTime': // 保险止期
                    let { beginEndInsuranceTime, stopEndInsuranceTime } = this.getTime(val, list, 'beginEndInsuranceTime', 'stopEndInsuranceTime') || {}
                    this.form.beginEndInsuranceTime = beginEndInsuranceTime
                    this.form.stopEndInsuranceTime = stopEndInsuranceTime
                    this.form.beginStartInsuranceTime = ''
                    this.form.stopStartInsuranceTime = ''
                    this.form.beginSingleTime = ''
                    this.form.endSingleTime = ''
                    break
                default: // 默认签单日期
                    let { beginSingleTime, endSingleTime } = this.getTime(val, list, 'beginSingleTime', 'endSingleTime') || {}
                    this.form.beginSingleTime = beginSingleTime
                    this.form.endSingleTime = endSingleTime
                    this.form.beginStartInsuranceTime = ''
                    this.form.stopStartInsuranceTime = ''
                    this.form.beginEndInsuranceTime = ''
                    this.form.stopEndInsuranceTime = ''
                    break
            }
        }
    }
}
</script>

<style lang="scss">
.sign-date-customer-fit {
    .ant-col-8 {
        padding-left: 8px;
        padding-right: 8px;
    }
}
</style>
