<!-- 企业认证--添加 -->
<template>
    <div>
        <enterprise-authorization-operation
            :title="title"
            :mode="mode"
        />
    </div>
</template>
<script>
// 组件
import EnterpriseAuthorizationOperation from '../EnterpriseAuthorizationOperation'
export default {
    name: 'EnterpriseAuthorizationPage',
    components: {
        EnterpriseAuthorizationOperation
    },
    data() {
        return {
            // 标题
            title: '新增企业认证',
            // 模式
            mode: 'add'
        }
    }

}
</script>

<style lang='scss'>

</style>
