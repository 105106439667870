import {
    BASE_URL
} from '@config'

/** 企业
 * ————————————————————————————————————————————————————————————————————————————————————————————*/
/**
 * 企业列表
 *
 */
export const GET_ENTERPRISE_LIST = {
    url: `${BASE_URL}/customer/customer-enterprise/select`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 新增企业认证
 * @type {{}}
 */
export const ADD_ENTERPRISE_AUTHORIZATION = {
    url: `${BASE_URL}/insurance/enterprise-authorization/save`,
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    timeout: 30000
}

/**
 * 修改企业认证
 * @type {{}}
 */
export const EDIT_ENTERPRISE_AUTHORIZATION = {
    url: `${BASE_URL}/insurance/enterprise-authorization/update`,
    method: 'put',
    headers: { 'Content-Type': 'multipart/form-data' },
    timeout: 30000
}


/**
 * 查询企业认证列表
 * @type {{}}
 */
export const GET_ENTERPRISE_AUTHORIZATION_LIST = {
    url: `${BASE_URL}/insurance/enterprise-authorization/select`,
    method: 'get',
    headers: { 'Content-Type': 'application/json' },
    timeout: 30000
}

/**
 * 查询企业认证详情
 * @type {{}}
 */
export const GET_ENTERPRISE_AUTHORIZATION_DETAIL = {
    url: `${BASE_URL}/insurance/enterprise-authorization/selectOne`,
    method: 'get',
    headers: { 'Content-Type': 'application/json' },
    timeout: 30000
}

/**
 * 删除企业认证
 * @type {{}}
 */
export const GET_ENTERPRISE_AUTHORIZATION_REMOVE = {
    url: `${BASE_URL}/insurance/enterprise-authorization/remove`,
    method: 'delete',
    headers: { 'Content-Type': 'application/json' },
    timeout: 30000
}

/**
 * 查询授权记录列表
 * @type {{}}
 */
export const GET_ENTERPRISE_AUTHORIZATION_HISTORY_LIST = {
    url: `${BASE_URL}/insurance/enterprise-authorization-book/select`,
    method: 'get',
    headers: { 'Content-Type': 'application/json' },
    timeout: 30000
}