import Cookies from 'js-cookie'
import { CORPORATIONCHANNELID_KEY, CORPORATIONCHANNELID_TIME } from '@config'

/**
 * 获取token
 * @returns {*}
 */
export function getCorporationChannelId() {
    return Cookies.get(CORPORATIONCHANNELID_KEY)
}

/**
 * 设置token
 * @param token
 * @returns {*}
 */
export function setCorporationChannelId(token) {
    return Cookies.set(CORPORATIONCHANNELID_KEY, token, { expires: CORPORATIONCHANNELID_TIME })
}

/**
 * 删除token
 * @returns {*}
 */
export function removeCorporationChannelId() {
    return Cookies.remove(CORPORATIONCHANNELID_KEY)
}
