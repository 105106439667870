<!-- 添加客户-表单 -->
<template>
    <a-form-model
        ref="ruleForm"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 24 }"
        :model="form"
        :rules="rules"
        class="add-customer-modal"
    >
        <a-form-model-item label="手机号码" prop="mobile">
            <a-input
                v-model.trim="form.mobile"
                placeholder="请输入手机号码"
            />
        </a-form-model-item>
        <a-form-model-item label="姓名" prop="nameQuery">
            <a-input
                v-model.trim="form.nameQuery"
                placeholder="请输入"
                :max-length="20"
            />
        </a-form-model-item>
        <a-form-model-item label="企业名称" prop="enterpriseName">
            <a-input
                v-model.trim="form.enterpriseName"
                placeholder="请输入"
                :max-length="50"
            />
        </a-form-model-item>
        <a-form-model-item label="所在地区" prop="selectAreaCode">
            <a-cascader 
                v-model="selectAreaCode"
                :options="areaData"
                placeholder="请选择"
                change-on-select
                @change="handleChangeArea"
            />
        </a-form-model-item>
        <a-form-model-item label="备注" prop="description">
            <a-text-area
                v-model.trim="form.description"
                placeholder="请输入"
                :auto-size="{minRows: 3}"
                :max-length="200"
            />
        </a-form-model-item>
    </a-form-model>
</template>

<script>
// 组件
import {
    FormModel as AFormModel,
    Input as AInput
} from 'ant-design-vue'

// 枚举
import { SALES_CATEGORY_SELF_SUPPORT_TEXT } from '@constant/enum'
import { validatePhoneNumber } from '@/utils/validate.js'

// mixins
import selectArea from '@/mixins/selectArea'

// 接口请求
import distributionCustomerSaveService from '@service/customer/distributionCustomer/distributionCustomerSaveService'
import proprietaryCustomerSaveService from '@service/customer/proprietaryCustomer/proprietaryCustomerSaveService'

export default {
    name: 'AddCustomerFormPanel',
    components: {
        AFormModel,
        AFormModelItem: AFormModel.Item,
        AInput,
        ATextArea: AInput.TextArea,
    },
    mixins: [selectArea],
    props: {
        type: {
            type: String,
            default() {
                return ''
            }
        }
    },
    data() {
        return {
            form: {
                // 手机号码
                mobile: '',
                // 姓名
                nameQuery: '',
                // 企业
                enterpriseName: '',
                // 备注
                description: ''

            },
            // 所在地区
            selectAreaCode: [],
            // 校验规则
            rules: {
                mobile: [
                    {
                        required: true,
                        message: '请输入手机号码',
                        trigger: 'blur'
                    }, {
                        validator: validatePhoneNumber,
                        trigger: 'blur'
                    }
                ],
            }
        }
    },
    computed: {},
    watch: {
        selectAreaCode: {
            handler(value) {
                this.form.provinceCode = value[0] || ''
                this.form.cityCode = value[1] || ''
                this.form.areaCode = value[2] || ''
            },
            immediate: true
        }
    },
    mounted() {},
    methods: {
        /**
         * 添加客户
         */
        async handleSaveCustomer() {
            try {
                this.$emit('loading', true)
                const { form } = this
                if (this.type === SALES_CATEGORY_SELF_SUPPORT_TEXT) {
                    await proprietaryCustomerSaveService(form)
                } else {
                    await distributionCustomerSaveService(form)
                }
                this.$message.success('添加成功')
                this.$emit('success')
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.$emit('loading', false)
            }
        },
        handleSend() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.handleSaveCustomer()
                }
            })
        },
    }
}
</script>

<style lang="scss">
.add-customer-modal {
    .ant-form-item {
        margin-bottom: 4px;
    }
    .ant-form-item-label {
        text-align: left;
        height: 35px;
    }
}
</style>
