<template>
    <a-card class="product-list-panel">
        <!-- 列表 start -->
        <a-list
            class="product-list-panel__list-container"
            size="large"
            :data-source="dataList"
            :loading="loading">
            <a-list-item
                slot="renderItem"
                key="item.title"
                slot-scope="item"
                class="product-list-panel__item">
                <div class="product-list-panel__item-img-wrap">
                    <div class="product-list-panel__item-img">
                        <img
                            slot="extra"
                            width="272"
                            alt="logo"
                            :src="item.insuranceEnterprisePortrait"
                        >
                    </div>
                    <p>{{ item.insuranceEnterpriseName }}</p>
                </div>
                <div class="product-list-panel__item-meta-wrap">
                    <a-list-item-meta class="product-list-panel__item-meta">
                        <template slot="title">
                            <!-- 产品名称 -->
                            <a class="product-list-panel__item-title" @click="handleDetail(item)">{{ item.name }}</a>
                            <!-- 智核logo -->
                            <span v-if="item.sinceNuclear" class="product-list-panel__item-logo" />
                            <!-- 方案类型 -->
                            <div v-if="item.planValueName" class="product-list-panel__item-property">
                                <span class="product-list-panel__item-property-text">{{
                                    item.planValueName
                                }}</span>
                            </div>
                        </template>
                    </a-list-item-meta>
                    <div class="product-list-panel__item-type">
                        <span v-for="(obj, invoiceIndex) in item.invoice" :key="invoiceIndex+obj.type_name">
                            <a-icon type="check-circle" theme="filled" :style="{ color: '#11AF14' }" />
                            {{ obj.type_name }}
                        </span>
                        <span v-for="(obj, insuranceSingleIndex) in item.insuranceSingle" :key="insuranceSingleIndex+obj.type_name">
                            <a-icon type="check-circle" theme="filled" :style="{ color: '#11AF14' }" />
                            {{ obj.type_name }}
                        </span>
                    </div>
                </div>
                <div v-if="commissionData.commissionVisibility"  class="product-list-panel__item-commission">
                    <!-- <div class="product-list-panel__item-commission"> -->
                    <span v-if="commissionDataObj[item.id]" class="product-list-panel__item-commission-text">推广佣金比例：</span><span class="product-list-panel__item-commission-rate" @click="handleCommission(item)">{{ commissionDataObj[item.id] }}</span>
                </div>
                <div class="product-list-panel__item-opt-wrap">
                    <a-button slot="extra"
                              v-auth="PRODUCT_PAGE_LIST_LIST_SHARE_BUTTON"
                              class="product-list-panel__item-opt"
                              @click="handleShare(item)">
                        <i class="iconfont icon-fenxiang" />
                        <span class="btnTxt">分享</span>
                    </a-button>
                    <a-button
                        slot="extra"
                        v-auth="PRODUCT_PAGE_LIST_LIST_DETAIL_BUTTON"
                        class="product-list-panel__item-opt-normal"
                        @click="handleDetail(item)">
                        查看详情
                    </a-button>
                </div>
            </a-list-item>
        </a-list>
        <!-- 列表 end -->

        <!-- 分页 start -->
        <a-pagination
            v-model="current"
            show-size-changer
            show-quick-jumper
            :total="total"
            :show-total="(total, range) => tableShowTotal(total, range)"
            @change="emitPaginationChange"
            @showSizeChange="emitPaginationChange"
        />

        <share-modal :visible="shareModalVisible" :params="shareParams" @close="handleCloseShare" />
        <commission-modal :visible="commissionModalVisible" :params="shareParams" :commission-data="commissionData" @close="handleCloseCommission" />

        <!-- 分页 end -->
    </a-card>
</template>

<script>
// 组件
import {
    Card as ACard,
    List as AList,
    Icon as AIcon,
    Button as AButton,
    Pagination as APagination
} from 'ant-design-vue'
import ShareModal from '../ShareModal'
import CommissionModal from '../CommissionModal'


// 权限code
import {
    PRODUCT_PAGE_LIST_LIST_SHARE_BUTTON,
    PRODUCT_PAGE_LIST_LIST_DETAIL_BUTTON,
    PRODUCT_PAGE_LIST_LIST_COMMISSION_BUTTON
} from '@constant/authEnum/productList'

// 全局数据
import { DEFAULT_PAGES_SIZE, CLIENT_BASE_URL } from '@/config'
import { INSURE_MODE_INSURE } from '@/constant/enum'

import getProductCommissionRangeService from '@/service/product/getProductCommissionRangeService'
import getProductCommissionListService from '@service/product/getProductCommissionListService'

import qs from 'qs'

import { accMul } from '@utils'


// 是单独一个个请求产品的佣金区间，还是批量请求佣金区间开关（批量开发环境耗时30s左右）
const getManyRequestsBySingleId = true


export default {
    name: 'ProductList',
    components: {
        ACard,
        AList,
        AListItem: AList.Item,
        AListItemMeta: AList.Item.Meta,
        AIcon,
        AButton,
        APagination,
        ShareModal,
        CommissionModal
    },
    props: {
        // 列表
        products: {
            type: Array,
            default() {
                return []
            }
        },
        // 渠道id
        corporationChannelId: {
            type: String,
            default() {
                return ''
            }
        },
        // 总数
        total: {
            type: [Number, String],
            default() {
                return 0
            }
        },
        // 分页数量
        limit: {
            type: [Number, String],
            default() {
                return DEFAULT_PAGES_SIZE
            }
        },
        // 当前页码
        current: {
            type: [Number, String],
            default() {
                return 1
            }
        },
        // 列表
        loading: {
            type: Boolean,
            default() {
                return false
            }
        },
        // 佣金信息
        commissionData: {
            type: Object,
            default() {
                return {}
            }
        },
        // 有地区选择的要筛选佣金范围
        commissionAreaSearchData: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            PRODUCT_PAGE_LIST_LIST_SHARE_BUTTON,
            PRODUCT_PAGE_LIST_LIST_DETAIL_BUTTON,
            PRODUCT_PAGE_LIST_LIST_COMMISSION_BUTTON,
            INSURE_MODE_INSURE,
            shareModalVisible: false,
            commissionModalVisible: false,
            shareParams: {},
            commissionDataObj: {},
            dataList: [],
            // url带参
            query: {}
        }

    },
    computed: {
        pagination() {
            const { total, limit, current } = this
            return {
                showSizeChanger: true,
                pageSize: limit,
                current,
                total,
                showQuickJumper: true
            }
        },
        // 用户信息缓存
        userInfo() {
            return this.$store.getters.userInfo
        }
    },
    watch: {
        $route: {
            async handler(route) {
                const { query } = route || {}
                if (query && Object.keys(query).length > 0) {
                    this.query = query
                }
            },
            immediate: true
        },
        products: {
            handler(val) {
                if (val.length > 0) {
                    this.dataList = JSON.parse(JSON.stringify(this.products))
                    if (!this.commissionAreaSearchData.areaCode) {
                        this.getCommissionRangeData(false)

                    }
                }
            },
            deep: true
        },
        commissionAreaSearchData: {
            handler(val) {
                if (val.areaCode) {
                    if (this.dataList.length > 0) {
                    // 如果筛选了地址且选择到区的情况下，还需要请求地址对应的佣金范围
                        this.getCommissionRangeData(true)
                    }
                }
            },
            immediate: true
        }
    },
    mounted() {
    },
    methods: {
        /**
         * 格式化分页提示
         */
        tableShowTotal(total) {
            return `共${total}条`
        },
        /**
         * 查看详情
         * @params item
         */
        handleDetail(item) {
            const { INSURE_MODE_INSURE, corporationChannelId } = this
            const { id } = item
            const { enterprisName, enterpriseId, enterpriseAuthorizationId } = this.query
            const query = {
                id,
                agentUserId: this.userInfo.id, // 经纪人id
                insureType: INSURE_MODE_INSURE,
                details: 'CRM',
                mobile: this.userInfo.mobile, // 经纪人手机号
                enterprisName,
                enterpriseId,
                enterpriseAuthorizationId,
                corporationChannelId
            }

            window.open(`${CLIENT_BASE_URL}/product/info?${qs.stringify(query)}`, '_blank' )
            // window.open(`http://172.16.22.202:3000/product/info?${qs.stringify(query)}`, '_blank' )

        },
        /**
         * 触发分享
         * @params item
         */
        handleShare(item) {
            const { id } = item
            this.shareModalVisible = true
            this.shareParams = {
                id, // 产品id
                productName: item.name,
                agentUserId: this.userInfo.id // 经纪人id
            }
        },
        handleCloseShare() {
            this.shareModalVisible = false
        },
        /**
         * 触发佣金弹框
         * @params item
         */
        handleCommission(item) {
            const { id } = item
            this.commissionModalVisible = true
            this.shareParams = {
                id, // 产品id
                productName: item.name,
                agentUserId: this.userInfo.id // 经纪人id
            }
        },
        handleCloseCommission() {
            this.commissionModalVisible = false
        },
        /**
         * emit pagination change 事件
         * @param {Number} page 页码
         * @param {Number} pageSize 每页显示记录的条数
         */
        emitPaginationChange(page, pageSize) {
            this.$emit('on-pagination-change', {
                page,
                pageSize
            })
        },
        async getCommissionRangeData( isArea ) {
            if (this.commissionData.commissionVisibility) {
                if (getManyRequestsBySingleId) {
                    for (let i = 0; i < this.dataList.length; i ++) {
                        if (isArea) {
                            await this.getAreaRate(this.dataList[i].id)
                        } else {
                            await this.getRate(this.dataList[i].id)

                        }
                    }
                } else {
                    let spuIds = ''
                    for (let i = 0; i < this.dataList.length; i ++) {
                        spuIds += this.dataList[i].id + (i === this.dataList.length - 1 ? '' : ',')
                        // console.log('spuIds:' + spuIds)
                        await this.getRate(spuIds)
                    }
                    // 批量ids请求一个接口返回时长30s，故采用上面单个id请求的方法
                    if (spuIds && spuIds.length > 0) {
                        await this.getRate(spuIds)
                    }
                }

            }

        },
        async getRate(spuId) {
            try {
                const { userInfo } = this
                const { id } = userInfo
                const data = await getProductCommissionRangeService({
                    userId: id,
                    spuId
                })
                if (data) {
                    if (getManyRequestsBySingleId) {
                        for (let i = 0; i < data.length; i ++) {
                            this.commissionDataObj[data[i].spuId] = data[i].small === 0 && data[i].big === 0 ? '查看详情' : data[i].small === data[i].big ? accMul(data[i].small, 100).toFixed(2) + '%' : (data[i].small === 0 ? 0 : accMul(data[i].small, 100).toFixed(2)) + '%-' + (data[i].big === 0 ? 0 : accMul(data[i].big, 100).toFixed(2)) + '%'
                        }
                        this.$set(this, 'commissionDataObj', { ...this.commissionDataObj })
                    // 另一种改变 commissionDataObj 能刷新view的方法
                    // this.commissionDataObj = {
                    //     ...this.commissionDataObj,
                    //     ...tmpObj
                    // }
                    } else {
                        // console.log('getRate data:' + JSON.stringify(data, null, 4))
                        let tmpObj = {}
                        for (let i = 0; i < data.length; i ++) {
                            tmpObj[data[i].spuId] = data[i].small + '%-' + data[i].big + '%'
                        }
                        this.$set(this, 'dataList', [...tmpObj])
                        // this.dataList = [].concat(tmpObj)
                    }

                }
            } catch (e) {
                // this.$message.error(e.message)
            } finally {
            }
        },

        async getAreaRate(spuId) {
            try {
                const { userInfo, commissionAreaSearchData } = this
                const { id } = userInfo
                const data = await getProductCommissionListService({
                    current: 1,
                    size: 1,
                    userId: id,
                    spuId,
                    ...commissionAreaSearchData
                })
                if (data && data.records && data.records.length > 0) {
                    if (getManyRequestsBySingleId) {
                        const commissionInfo = data.records[0]
                        this.commissionDataObj[spuId] = commissionInfo.rate === 0 ? '查看详情' : accMul(commissionInfo.rate, 100).toFixed(2) + '%'
                        this.$set(this, 'commissionDataObj', { ...this.commissionDataObj })
                    // 另一种改变 commissionDataObj 能刷新view的方法
                    // this.commissionDataObj = {
                    //     ...this.commissionDataObj,
                    //     ...tmpObj
                    // }
                    } else {
                        // console.log('getRate data:' + JSON.stringify(data, null, 4))
                        let tmpObj = {}
                        for (let i = 0; i < data.length; i ++) {
                            tmpObj[data[i].spuId] = data[i].small + '%-' + data[i].big + '%'
                        }
                        this.$set(this, 'dataList', [...tmpObj])
                        // this.dataList = [].concat(tmpObj)
                    }

                }
            } catch (e) {
                // this.$message.error(e.message)
            } finally {
            }
        }
    }
}
</script>

<style lang='scss'>
@import "~@/assets/styles/varibles";

.product-list-panel {
    padding-top: 16px;
    margin-bottom: 90px;
    border: none;
    border-top: 1px dashed #ebedf0;

    .ant-pagination {
        border: none !important;
    }

    .ant-card-body {
        padding: 0;
    }

    /* 分页 */
    .ant-pagination {
        padding: 64px 30px 32px;
        text-align: right;
        border-top: 1px solid #e8e8e8;
    }

    /* meta右侧竖线 */
    .ant-list-item-action-split {
        display: none;
    }

    /* title */
    .ant-list-item-meta {
        margin-bottom: 0;
    }

    /* action */
    .ant-list-vertical {
        .ant-list-item-meta-title {
            margin-bottom: 16px;
        }

        .ant-list-item-action {
            margin-top: 0;

            li {
                div {
                    span {
                        margin-right: 32px;
                    }
                }
            }
        }
    }

    &__item {
        position: relative;
        padding: 16px 24px 18px !important;
        border: none !important;

        &::after {
            position: absolute;
            bottom: 0;
            left: 118px;
            width: calc(100% - 142px);
            height: 1px;
            content: "";
            background: #ebedf0;
        }

        &-meta-wrap {
            flex: 1;
        }

        /* 产品名称 */
        &-title {
            display: inline-block;
            max-width: 650px;
            overflow: hidden;
            font-size: $font-sizeTwentyFour;
            font-size: 18px;
            font-weight: 400;
            line-height: 18px;
            color: #333 !important;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        /* 智核logo */
        &-logo {
            position: relative;
            top: -1px;
            display: inline-block;
            flex: none;
            align-items: center;
            width: 82px;
            height: 20px;
            margin-left: 8px;
            color: #1877f2;
            border: 1px solid #1877f2;
            border-radius: 16px;

            &::before {
                display: block;
                width: 22px;
                height: 100%;
                content: "";
                background-color: #1877f2;
                background-image: url("~@/assets/images/product/zhihe-logo.png");
                background-repeat: no-repeat;
                background-position: 5px center;
                background-size: 14px 14px;
                border-radius: 16px 0 0 16px;
            }

            &::after {
                position: absolute;
                top: -3px;
                right: 5px;
                display: block;
                font-size: 12px;
                white-space: nowrap;
                content: "工保智核";
            }
        }

        /* 方案类型 */
        &-property {
            // margin-top: 5px;
            &-text {
                height: 22px;
                padding: 3px 4px;
                font-size: $font-sizeTwelve;
                line-height: 22px;
                color: #969799;
                border: 1px solid #c8c9cc;
                border-radius: 2px;
            }
        }

        &-type {
            font-size: 12px;
            line-height: 12px;
            color: #848b91;

            span {
                margin-right: 16px;
            }
        }

        &-img-wrap {
            display: flex;
            flex-direction: column;
            width: 78px;
            height: 78px;
            margin-right: 16px;
            background: #fff;
            border: 1px solid #f5f6f8;
            border-radius: 4px;

            p {
                width: 78px;
                height: 24px;
                margin: 0;
                overflow: hidden; //超出的文本隐藏
                font-size: 12px;
                line-height: 24px;
                color: #646566;
                text-align: center;
                text-overflow: ellipsis; //溢出用省略号显示
                white-space: nowrap;  // 默认不换行；
                background: #f5f6f8;
                border-radius: 0 0 2px 2px;
            }
        }

        &-img {
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: center;

            img {
                width: 78px;
            }
        }

        &-divider {
            height: 66px;
            margin: 0 30px;
            color: #f3f3f3;
        }

        &-commission {
            display: flex;
            align-self: center;
            margin-right: 30px;

            &-text {
                font-size: 14px;
                color: #333333;
            }

            &-rate {
                color: #1877F2;
                cursor: pointer;
            }
        }

        &-opt-wrap {
            display: flex;
            flex-direction: column;
            align-self: center;
            margin-left: 16px;
        }

        &-opt,
        &-opt:focus {
            width: 87px;
            height: 32px;
            color: $color-white;
            letter-spacing: 2px;
            background-color: $color-wiki-border;
            border-radius: 4px;
        }

        &-opt {
            border: none;

            i {
                margin-right: 8px;
                font-size: 14px;
            }
        }

        &-opt:hover {
            border: 1px solid $color-wiki-border;
        }

        &-opt-normal {
            width: 87px;
            height: 32px;
            margin-top: 8px;
        }

        .ant-list-item-extra {
            margin-top: 20px;
            margin-left: 30px;
        }
    }
}
</style>
