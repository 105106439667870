import moment from 'moment'
// 全局数据
import {
    SIGNING_DATE_THIS_MONTH,
    SIGNING_DATE_THIS_CUSTOM,
    SIGNING_DATE_TODAY,
    SIGNING_DATE_THIS_YEAR
} from '@constant/enum'
export default {
    methods: {
        moment,
        /**
         * 获取时间
         */
        getTime(val, list, start, end) {
            // const { signDate, signDateSelect } = this
            let date = new Date()
            let monthFirstDay = new Date(date.getFullYear(), date.getMonth(), 1)
            let monthLastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
            let year = date.getFullYear()
            let [startTime, endTime] = list || []
            switch (val) {
                case SIGNING_DATE_TODAY: // 今天
                    return {
                        [start]: moment(date).format('YYYY-MM-DD 00:00:00'),
                        [end]: moment(date).format('YYYY-MM-DD 23:59:59')
                    }
                case SIGNING_DATE_THIS_YEAR: // 今年
                    return {
                        [start]: `${year}-01-01 00:00:00`,
                        [end]: `${year}-12-31 23:59:59`,
                    }
                case SIGNING_DATE_THIS_CUSTOM: // 自定义
                    return {
                        [start]: startTime ? `${moment(startTime).format('YYYY-MM-DD HH:mm:ss')}` : '',
                        [end]: endTime ? `${moment(endTime).format('YYYY-MM-DD HH:mm:ss')}` : ''
                    }
                case SIGNING_DATE_THIS_MONTH: // 当月
                    return {
                        [start]: moment(monthFirstDay).format('YYYY-MM-DD 00:00:00'),
                        [end]: moment(monthLastDay).format('YYYY-MM-DD 23:59:59')
                    }
                case undefined: // 保险止期可以不传
                    return {
                        [start]: '',
                        [end]: ''
                    }
            }
        },
    }
}