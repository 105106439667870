<!-- 线索管理/客户线索旧 -->
<template>
    <list-page-layout  class="client-clue-page">
        <!-- search -->
        <search-panel ref="searchPanel" slot="header" @search="handleSearch" @refresh="handleRefresh" />
        <!-- search end -->
        <container-panel>
            <!-- 标题 start -->
            <template slot="title">客户线索列表</template>
            <!-- 标题 end -->

            <!-- 操作按钮 start -->
            <template slot="button" slot-scope>
                <custom-button
                    v-auth="CLIENT_CLUE_ADD_CLUE_BUTTON"
                    text="添加线索"
                    type="default"
                    @click="handleAdd"
                />
            </template>
            <!-- 操作按钮 end -->

            <!-- 表格 start -->
            <template slot="content" slot-scope>
                <a-table
                    size="middle"
                    :columns="columns"
                    :data-source="data"
                    :pagination="pagination"
                    :loading="loading"
                    row-key="id"
                    @change="handleTableChange"
                >
                    <!-- 表格的序号递增 -->
                    <template slot="sort" slot-scope="text, record, index">{{ index + 1 }}</template>
                    <!-- 最近一次备注 -->
                    <template slot="content" slot-scope="content,record">
                        <template v-if="content">
                            <a-button
                                v-auth="CLIENT_CLUE_REMARK_RECORD_BUTTON"
                                class="client-clue-page__remark-button"
                                type="link"
                                @click="handleRemarkRecordClick(record)"
                            >
                                {{ content }}
                            </a-button>
                        </template>
                        <template v-else>
                            -
                        </template>
                    </template>
                    <!-- 创建时间 -->
                    <template slot="createDateTime" slot-scope="createDateTime">
                        {{ formatDate(createDateTime) }}
                    </template>
                    <!-- 预约联系时间 -->
                    <template slot="appointmentContactDateTime" slot-scope="appointmentContactDateTime,record">
                        <template v-if="appointmentContactDateTime">
                            {{ `${appointmentContactDateTime || '-'} ${getApiEnumLabelFromList(appointmentContactTimeList,record.label) || '-'}` }}
                        </template>
                        <template v-else>
                            -
                        </template>
                    </template>
                    <!-- 操作 -->
                    <template slot="action" slot-scope="action">
                        <a-button
                            v-auth="CLIENT_CLUE_HAND_OUT_STEWARD_BUTTON"
                            type="link"
                            @click="handleStewardClick(action)"
                        >
                            分配管家
                        </a-button>
                        <a-button
                            v-auth="CLIENT_CLUE_REMARK_CLUE_BUTTON"
                            type="link"
                            @click="handleRemarkClick(action)"
                        >
                            备注
                        </a-button>
                    </template>
                </a-table>
            </template>
            <!-- 表格 end -->
        </container-panel>
        <!-- 添加线索 start -->
        <create-clue-modal
            :visible="visible"
            :type="type"
            @close="visible = false"
            @success="handleModalSuccess"
        />
        <!-- 添加线索 end -->
        <!-- 备注 start -->
        <remark-modal
            :id="currentRow.id"
            :visible="remarkModalVisible"
            :business-type="businessType"
            @close="remarkModalVisible = false"
            @success="handleModalSuccess"
        />
        <!-- 备注 end -->
        <!-- 分配管家 start -->
        <dispense-steward-modal
            :visible="dispenseStewardModalVisible"
            :data="currentRow"
            :business-type="dispenseStewardType"
            @close="dispenseStewardModalVisible = false"
            @success="handleDispenseStewardSuccess"
        />
        <!-- 分配管家 end -->
        <!-- 备注记录 start -->
        <remark-record-modal
            :id="currentRow.id"
            :visible="remarkRecordModalVisible"
            :business-type="businessType"
            @close="remarkRecordModalVisible = false"
            @success="handleModalSuccess"
        />
        <!-- 备注记录 end -->
    </list-page-layout>
</template>

<script>
// 组件
import { Button as AButton, Table as ATable } from 'ant-design-vue'
import ListPageLayout from '@layout/ListPageLayout'
import ContainerPanel from '@components/ContainerPanel'
import SearchPanel from './SearchPanel'
import CustomButton from '@components/Button'
import CreateClueModal from '@weights/Clue/CreateClueModal'
import RemarkModal from '@weights/RemarkModal'
import DispenseStewardModal from '@weights/DispenseStewardModal'
import RemarkRecordModal from '@weights/RemarkRecordModal'

// 全局数据
import { DEFAULT_PAGES_SIZE } from '@config'
import {
    CLUE_SOURCE_ENUM_LIST,
    CLUE_TYPE_CUSTOMER_CLUE,
    REMARK_TYPE_CLUE,
    STEWARD_LIST_TYPE_CUSTOMER_CLUE,
    IS_CRM,
    APPOINTMENT_CONTACT_TIME_ENUM_LIST
} from '@constant/enum'
import {
    CLIENT_CLUE_ADD_CLUE_BUTTON,
    CLIENT_CLUE_HAND_OUT_STEWARD_BUTTON,
    CLIENT_CLUE_REMARK_CLUE_BUTTON,
    CLIENT_CLUE_REMARK_RECORD_BUTTON
} from '@constant/authEnum/clue'

// mixins
import enumOperate from '@mixins/enumOperate'
import tableShowTotal from '@mixins/tableShowTotal'

// 工具
import { format } from '@utils'
import string2Date from '@utils/string2Date'

// 接口请求
import getClueListService from '@service/clue/getClueListService'

const columns = [
    {
        title: '序号',
        dataIndex: 'sort',
        key: 'sort',
        scopedSlots: { customRender: 'sort' },
        align: 'center'
    },
    {
        title: '线索来源',
        dataIndex: 'potentialCustomerSourceName',
        key: 'potentialCustomerSourceName',
        align: 'center'
    },
    {
        title: '创建时间',
        dataIndex: 'createDateTime',
        key: 'createDateTime',
        align: 'center',
        scopedSlots: { customRender: 'createDateTime' }
    },
    {
        title: '姓名',
        dataIndex: 'name',
        key: 'name',
        align: 'center'
    },
    {
        title: '企业名称',
        dataIndex: 'enterpriseName',
        key: 'enterpriseName',
        align: 'center'
    },
    {
        title: '手机号',
        dataIndex: 'mobile',
        key: 'mobile',
        align: 'center'
    },
    {
        title: '意向险种',
        dataIndex: 'dangerPlantedName',
        key: 'dangerPlantedName',
        align: 'center'
    },
    {
        title: '工程所在地',
        dataIndex: 'address',
        key: 'address',
        align: 'center'
    },
    {
        title: '预约联系时间',
        dataIndex: 'appointmentContactDateTime',
        key: 'appointmentContactDateTime',
        align: 'center',
        scopedSlots: { customRender: 'appointmentContactDateTime' }
    },
    {
        title: '最近一次备注',
        dataIndex: 'content',
        key: 'content',
        scopedSlots: { customRender: 'content' },
        ellipsis: true,
        align: 'center'
    },
    {
        title: '管家',
        dataIndex: 'agentUserName',
        key: 'agentUserName',
        align: 'center'
    },
    {
        title: '操作',
        key: 'action',
        scopedSlots: { customRender: 'action' },
        align: 'center'
    }
]

export default {
    name: 'ClientClue',
    components: {
        ListPageLayout,
        SearchPanel,
        ContainerPanel,
        AButton,
        ATable,
        CustomButton,
        CreateClueModal,
        RemarkModal,
        DispenseStewardModal,
        RemarkRecordModal
    },
    mixins: [enumOperate, tableShowTotal],
    data() {
        return {
            CLIENT_CLUE_REMARK_RECORD_BUTTON,
            // 查询条件
            params: {},
            // 数据
            data: [],
            // 分页数量
            limit: DEFAULT_PAGES_SIZE,
            // 总记录数量
            total: 0,
            // 当前索引
            current: 1,
            // 加载状态
            loading: false,
            // table 列配置
            columns,
            // 当前选中的行数据
            currentRow: {},
            // 模态框相关参数
            visible: false,
            mode: '',
            clueSourceList: CLUE_SOURCE_ENUM_LIST,
            CLIENT_CLUE_ADD_CLUE_BUTTON,
            CLIENT_CLUE_HAND_OUT_STEWARD_BUTTON,
            CLIENT_CLUE_REMARK_CLUE_BUTTON,
            remarkModalVisible: false, // 备注框可见性
            dispenseStewardModalVisible: false, // 分配管家弹框可见性
            remarkRecordModalVisible: false, // 备注记录弹框可见性
            type: CLUE_TYPE_CUSTOMER_CLUE, // 线索类型-客户
            businessType: REMARK_TYPE_CLUE, // 业务类型-线索
            dispenseStewardType: STEWARD_LIST_TYPE_CUSTOMER_CLUE, // 分配管家类型-线索管理
            isCrm: IS_CRM,
            appointmentContactTimeList: APPOINTMENT_CONTACT_TIME_ENUM_LIST // 预约联系时间段 上午/下午
        }
    },
    computed: {
        pagination() {
            const { total, limit, current, tableShowTotal } = this
            return {
                showTotal: (total, range) => tableShowTotal(total, range),
                current,
                total,
                pageSize: limit,
                showSizeChanger: true,
                showQuickJumper: true
            }
        }
    },
    mounted() {
        // 获取列表
        this.getList()
    },
    methods: {
        /**
         * 获取 列表
         */
        async getList() {
            try {
                this.loading = true
                const { params, current, limit, type, isCrm } = this
                const { total, records } = await getClueListService({
                    current,
                    size: limit,
                    type,
                    isCrm,
                    ...params
                })
                this.total = total || 0
                this.data = records
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.loading = false
            }
        },
        /**
         * 弹框关闭
         */
        handleModalClose() {
            this.visible = false
            this.remarkModalVisible = false
            this.dispenseStewardModalVisible = false
        },
        handleModalSuccess() {
            this.handleModalClose()
            this.getList()
        },
        handleDispenseStewardSuccess() {
            this.handleModalSuccess()
            this.$refs.searchPanel.refreshSteward()
        },
        /**
         * 响应搜索事件
         */
        handleSearch(params) {
            this.current = 1
            this.params = params
            this.getList()
        },
        /**
         * 响应刷新事件
         */
        handleRefresh(params) {
            this.handleSearch(params)
        },
        /**
         * 响应 table change 事件
         * @param pagination
         */
        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.current = current
            this.limit = pageSize
            this.getList()
        },
        /**
         * 添加线索
         */
        handleAdd() {
            this.visible = true
        },
        /**
         * 分配管家
         */
        handleStewardClick(record) {
            this.dispenseStewardModalVisible = true
            this.currentRow = record
        },
        /**
         * 备注
         */
        handleRemarkClick(record) {
            this.remarkModalVisible = true
            this.currentRow = record
        },
        /**
         * 备注记录
         */
        handleRemarkRecordClick(record) {
            if (record.content) {
                this.remarkRecordModalVisible = true
                this.currentRow = record
            }
        },
        formatDate(dateStr) {
            if (dateStr) {
                dateStr = string2Date(dateStr)
                return format(dateStr, 'yyyy-MM-dd hh:mm')
            } else {
                return '-'
            }
        }
    }
}
</script>

<style lang="scss">
.client-clue-page {
    &__remark-button {
        width: 100%;
        span {
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}
</style>
