<template>
    <div class="slider-captcha-card">
        <div id="captcha" ref="captcha" />
    <!--    <div class="slider-captcha-card__header"> -->
    <!--      <span>请完成安全验证!</span> -->
    <!--    </div> -->
    <!--    <div class="slider-captcha-card__body"> -->
    <!--      -->
    <!--    </div> -->
    </div>
</template>

<script>
import SliderCaptcha from './core'

export default {
    name: 'SliderCaptcha',
    props: {
        config: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return { sliderCaptcha: null }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            const el = this.$refs.captcha
            const url = require('@/assets/images/captcha/default2x.png')

            if (el) {
                const { config } = this
                const option = {
                    ...config,
                    onSuccess: this.handleSuccess,
                    onFail: this.handleFail,
                    setSrc() {
                        return url
                    }
                }
                this.sliderCaptcha = new SliderCaptcha(el, option)
            }
        },
        handleSuccess() {
            const { sliderCaptcha } = this
            this.$emit('success', { sliderCaptcha })

        },
        handleFail() {
            const { sliderCaptcha } = this
            this.$emit('fail', { sliderCaptcha })
        }
    }
}
</script>

<style lang="scss">
@import "./styles/iconfont.css";

.slider-captcha-card {
    display: flex;

    //width: 314px;
    //height: 286px;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border: 1px solid rgb(0 0 0 / 12.5%);

    &__header {
        padding: 0.75rem 1.25rem;
        margin-bottom: 0;
        background-color: rgb(0 0 0 / 3%);
        border-bottom: 1px solid rgb(0 0 0 / 12.5%);
    }

    &__body {
        flex: 1 1 auto;
        padding: 1.25rem;
    }

    .slider-container {
        position: relative;
        height: 40px;
        line-height: 40px;
        color: #45494c;
        text-align: center;
        background: #f7f9fa;
        border-radius: 2px;

        &--active {
            .slider {
                top: -1px;
                border: 1px solid #1991fa;
            }

            .sliderMask {
                border-width: 1px 0 1px 1px;
            }
        }

        &--success {
            .slider {
                top: -1px;
                background-color: #52ccba !important;
                border: 1px solid #52ccba;
            }

            .sliderMask {
                background-color: #d2f4ef;
                border: 1px solid #52ccba;
                border-width: 1px 0 1px 1px;
            }

            .sliderIcon::before {
                content: "\e60d";
            }
        }

        &--fail {
            .slider {
                top: -1px;
                background-color: #f57a7a !important;
                border: 1px solid #f57a7a;
            }

            .sliderMask {
                background-color: #fce1e1;
                border: 1px solid #f57a7a;
                border-width: 1px 0 1px 1px;
            }

            .sliderIcon::before {
                content: "\e60e";
            }
        }

        &--active .sliderText,
        &--success .sliderText,
        &--fail .sliderText {
            display: none;
        }
    }

    .sliderbg {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 40px;
        background-color: #f7f9fa;
        border: 1px solid #e6e8eb;
        border-radius: 2px;
    }

    .sliderMask {
        position: absolute;
        top: 0;
        left: 0;
        height: 40px;
        background: #d1e9fe;
        border: 0 solid #1991fa;
        border-radius: 2px;
    }

    .slider {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        cursor: pointer;
        background: #fff;
        border-radius: 2px;
        box-shadow: 0 0 3px rgb(0 0 0 / 30%);
        transition: background 0.2s linear;
    }

    .slider:hover {
        background: #1991fa;
    }

    .slider:hover .sliderIcon {
        background-position: 0 -13px;
    }

    .sliderText {
        position: relative;
    }

    .sliderIcon {
    }

    .refreshIcon {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 5;
        margin: 6px;
        font-size: 1rem;
        color: rgb(255 255 255 / 65%);
        text-shadow: 0 0 8px #000;
        cursor: pointer;
        transition: color 0.3s linear;
    }

    .refreshIcon:hover {
        color: rgb(255 255 255 / 100%);
    }

    .block {
        position: absolute;
        top: 0;
        left: 0;
    }
}
</style>
