<template>
    <list-page-layout class="update-password-wrap">
        <div class="header-panel-wrap">
            <h3>修改密码</h3>
        </div>
        <container-panel class="update-password-page">
            <div class="update-password-container">
                <a-steps class="custom-steps" :current="currentStep">
                    <a-step v-for="(item, index) in steps"
                            :key="index"
                    />
                </a-steps>
                <div class="custom-steps-title">
                    <p v-for="({title },index) in steps" :key="title" :class="getStepTitCls(currentStep,index)">{{ title }}</p>
                </div>
                <div class="update-password-container__content">
                    <verify-identity v-show="currentStep == 0"
                                     @submit="getVerifyIdentityForm" />
                    <set-new-password v-show="currentStep == 1"
                                      @submit="getSetNewPasswordForm" />
                    <set-success v-show="currentStep === 2"  ref="setSuccessRef" />
                </div>
            </div>
        </container-panel>
    </list-page-layout>
</template>

<script>
// 组件
import {
    Steps as ASteps
} from 'ant-design-vue'
import ListPageLayout from '@layout/ListPageLayout'
import ContainerPanel from '@components/ContainerPanel'
import VerifyIdentity from './VerifyIdentity'
import SetNewPassword from './SetNewPassword'
import SetSuccess from './SetSuccess'

export default {
    name: 'UserActiveUpdatePassword',
    components: {
        ListPageLayout,
        ContainerPanel,
        VerifyIdentity,
        SetNewPassword,
        SetSuccess,
        ASteps,
        AStep: ASteps.Step
    },
    data() {
        return {
            currentStep: 0, // 当前步骤
            steps: [
                {
                    title: '验证身份',
                    content: 'First-content'
                }, {
                    title: '设置密码',
                    content: 'Second-content'
                }, {
                    title: '处理完成',
                    content: 'Last-content'
                }
            ]
        }
    },

    // 创建完成
    created() {

    },

    // DOM挂载完毕
    mounted() {

    },

    methods: {
        // 前进
        stepForward() {
            this.currentStep += 1
            if (this.currentStep === 2) {
                this.$refs.setSuccessRef.countDown() // 开始倒计时 自动重新登录
            }
        },

        getSetNewPasswordForm(data) {
            console.log('设置密码表单', data)
            this.stepForward()
        },

        getVerifyIdentityForm(data) {
            console.log('表单', data)
            this.stepForward()
        },

        getStepTitCls(currentStep, index) {
            return currentStep > index ? 'finish-title' : currentStep === index ? 'current-title' : null
        }
    }
}
</script>
<style lang='scss'>
.update-password-wrap {
    .header-panel-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 24px;
        background: #f7f8fa;
        border-radius: 4px 4px 0 0;
        box-shadow: 0 2px 30px 0 rgb(220 222 223 / 50%);

        h3 {
            margin: 0;
        }
    }

    .update-password-page {
        border: none;

        .update-password-container {
            width: 600px;
            margin: auto;

            &__content {
                width: 350px;
                padding: 40px 0;
                margin: auto;

                .ant-form-item-control {
                    line-height: 32px;
                }

                .ant-form-item {
                    margin-bottom: 24px;
                }
            }

            .custom-steps {
                padding: 0 4px 0 12px;

                .ant-steps-item-title::after {
                    top: 12px;
                    height: 2px;
                }

                // 整体步骤
                .ant-steps-item-icon {
                    width: 24px;
                    height: 24px;
                    border: 2px solid #1890ff;

                    .ant-steps-icon {
                        top: -7px;
                        font-size: 14px;
                    }
                }

                // 未到步骤
                .ant-steps-item-wait {
                    .ant-steps-item-icon {
                        background: #c8c9cc;
                        border: 2px solid #c8c9cc;

                        .ant-steps-icon {
                            color: #fff;
                        }
                    }
                }

                // 已到步骤
                .ant-steps-item-finish {
                    .ant-steps-item-icon {
                        // background: #c8c9cc;
                        border-width: 2px;

                        .ant-steps-icon {
                            // color: #fff;
                        }
                    }
                }
            }

            .custom-steps-title {
                display: flex;
                justify-content: space-between;
                margin-top: 8px;
                color: #969799;

                .current-title {
                    font-weight: bold;
                    color: #323233;
                }

                .finish-title {
                    font-weight: bold;
                    color: #323233;
                }
            }
        }
    }
}

</style>
