<template>
    <search-panel :model="form"  layout="vertical" @search="handleSearch" @refresh="handleRefresh">
        <!-- 经纪人姓名输入模糊搜索 -->
        <form-col :span="8" label="企业名称" prop="query">
            <!-- 已和产品确认模糊输入搜索 -->
            <template slot="formElements">
                <a-input v-model="form.query" placeholder="请输入企业名称/统一社会信用代码" />
            </template>
        </form-col>

        <!-- 在职状态下拉框 -->
        <form-col :span="6" label="审核状态" prop="state">
            <template slot="formElements">
                <a-select v-model="form.state" placeholder="请选择审核状态">
                    <a-select-option value="">全部</a-select-option>
                    <template v-for="item in enterpriseAuthorizationStatusList">
                        <a-select-option :key="item.value" :value="item.label">{{ item.label }}</a-select-option>
                    </template>
                </a-select>
            </template>
        </form-col>

        <!-- 在职状态下拉框 -->
        <form-col :span="6" label="授权状态" prop="enterpriseAuthorizationBookState">
            <template slot="formElements">
                <a-select v-model="form.enterpriseAuthorizationBookState" placeholder="请选择授权状态">
                    <a-select-option value="">全部</a-select-option>
                    <template v-for="item in enterpriseAuthorizationValidStatusList">
                        <a-select-option :key="item.value" :value="item.label">{{ item.label }}</a-select-option>
                    </template>
                </a-select>
            </template>
        </form-col>
    </search-panel>
</template>

<script>
// 组件
import {
    Select as ASelect,
    Input as AInput
} from 'ant-design-vue'
import SearchPanel from '@components/SearchPanel'
import FormCol from '@components/FormCol'

// utils
import { filterParams } from '@utils/search.js'

// 枚举
import { ENTERPRISE_AUTHORIZATION_LIST, ENTERPRISE_AUTHORIZATION_VALID_LIST } from '@constant/enum'


export default {
    name: 'EnterpriseAuthorizationListSearchPanel',
    components: {
        FormCol,
        SearchPanel,
        ASelect,
        ASelectOption: ASelect.Option,
        AInput
    },
    data() {
        return {
            form: {
                // 企业名称和信用代码模糊查询
                query: '',
                // 审核状态
                state: undefined,
                // 授权状态
                enterpriseAuthorizationBookState: undefined
            },
            // 审核状态下拉数据
            enterpriseAuthorizationStatusList: ENTERPRISE_AUTHORIZATION_LIST,
            // 授权状态下拉数据
            enterpriseAuthorizationValidStatusList: ENTERPRISE_AUTHORIZATION_VALID_LIST

        }
    },
    mounted() {
    },
    methods: {
        /**
       * 响应搜索事件
       */
        handleSearch() {
            const { form } = this
            this.$emit('search', filterParams(form))
        },
        /**
       * 响应刷新事件
       */
        handleRefresh() {
            const { form } = this
            this.$emit('refresh', filterParams(form))
        }
    }
}
</script>

<style scoped>

</style>
