<template>
    <a-form-model
        layout="horizontal"
        :labelCol="{ span: 6 }"
        :wrapperCol="{ span: 18 }"
        :model="form"
        ref="ruleForm"
        :rules="rules"
    >
        <a-form-model-item label="姓名" prop="name">
            <a-input v-model="form.name" placeholder="请输入"/>
        </a-form-model-item>
        <a-form-model-item label="手机号" prop="mobile">
            <a-input v-model="form.mobile" placeholder="请输入"/>
        </a-form-model-item>
    </a-form-model>
</template>

<script>
// 组件
import { FormModel as AFormModel, Input as AInput } from 'ant-design-vue'

// 接口请求
import createClueService from '@service/clue/createClueService'

// 全局数据
import {} from '@constant/enum'

// 工具类
import { filterParams } from '@utils/search.js'
import { validatePhoneNumber } from '@utils/validate'

export default {
    name: 'CreateClueFormPanel',
    props: {
        type: {
            type: [String, Number],
            default() {
                return 0
            }
        }
    },
    data() {
        return {
            form: {
                name: '', // 姓名
                mobile: '' // 手机号
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: '请输入姓名',
                        trigger: 'blur'
                    }
                ],
                mobile: [
                    {
                        required: true,
                        message: '请输入手机号',
                        trigger: 'blur'
                    },
                    {
                        validator: validatePhoneNumber,
                        trigger: 'blur'
                    }
                ]
            },
            loading: false
        }
    },
    computed: {
        userInfo() {
            return this.$store.getters.userInfo
        }
    },
    watch: {},
    mounted() {},
    methods: {
        /**
         * 新增
         */
        async createClientClue(data) {
            try {
                this.$emit('loading', true)
                await createClueService(data)
                this.$message.success('添加成功')
                this.$emit('success')
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.$emit('loading', false)
            }
        },
        /**
         * 点击确定按钮
         */
        handleSend() {
            const { form, type, userInfo } = this
            const { name, userName } = userInfo
            const params = {
                type,
                agentUserId: userName,
                agentUserName: name,
                ...form
            }
            const data = filterParams(params)
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.createClientClue(data)
                }
            })
        },
        /**
         * 关闭弹框
         */
        handleCancel() {
            this.$emit('close')
        },
        /**
         * 重置按钮事件
         */
        handleReset() {
            this.form = {
                name: '',
                description: '',
                id: this.form.id
            }
        }
    },
    components: {
        AFormModel,
        AFormModelItem: AFormModel.Item,
        AInput
    }
}
</script>

<style lang="scss">
</style>