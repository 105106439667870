// 组件
import {
    Table as ATable,
    Input as AInput,
    Select as ASelect,
    DatePicker as ADatePicker
} from 'ant-design-vue'
import ContainerPanel from '@components/ContainerPanel'
import SearchPanel from '@components/BaseSearchPanel'
import FormCol from '@components/FormCol'
import OrderRecordDetail from '@weights/Customer/Components/ComponentsDetail/Detail/OrderRecordDetail'

// 常量
import { DEFAULT_PAGES_SIZE } from '@config'

// 工具类
import { filterParams } from '@utils/search.js'
import moment from 'moment'

// mixins
import tableShowTotal from '@mixins/tableShowTotal'
import enumOperate from '@mixins/enumOperate'
import empty from '@mixins/empty'

export default {
    components: {
        ATable,
        ContainerPanel,
        SearchPanel,
        AInput,
        ASelect,
        FormCol,
        OrderRecordDetail,
        ASelectOption: ASelect.Option,
        ARangePicker: ADatePicker.RangePicker
    },
    mixins: [tableShowTotal, enumOperate, empty],
    data() {
        return {
            // 分页数量
            limit: DEFAULT_PAGES_SIZE,
            submitTimeMode: ['date', 'date'],
            showTimeOptions: {
                defaultValue: [
                    moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')
                ]
            },
            // 业务类型
            businessType: '',
            // 筛选-插槽
            formColDefaultSlot: 'formElements',
            searchExpansionSlot: 'expansion'
        }
    },
    computed: {
        pagination() {
            const {
                total,
                limit,
                current,
                tableShowTotal
            } = this
            return {
                showTotal: (total, range) => tableShowTotal(total, range),
                current,
                total,
                pageSize: limit,
                showSizeChanger: true,
                showQuickJumper: true
            }
        }
    },
    watch: {
        $route: {
            handler(route) {
                const { businessType } = route.query || {}
                this.businessType = Number(businessType)
            },
            immediate: true
        }
    },
    methods: {
        /**
         * 响应 table change 事件
         * @param pagination
         */
        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.current = current
            this.limit = pageSize
            this.getList()
        },
        /**
         * 列表-搜索
         */
        handleListSearch(params) {
            this.current = 1
            this.searchParams = params
            this.getList()
        },
        /**
         * 列表-重置
         */
        handleListRefresh(params) {
            this.handleListSearch(params)
        },
        /**
         * 列表-查看-关闭
         */
        handleDetailClose() {
            this.visible = false
        },
        /**
         * 筛选-响应搜索事件
         */
        handleSearch() {
            const params = this.formatParams()
            this.$emit('search', filterParams(params))
        },
        /**
         * 筛选-响应刷新事件
         */
        handleRefresh() {
            const params = this.formatParams()
            this.$emit('refresh', filterParams(params))
        }
    }
}
