/**
 * 客户 模块
 */
import BasicLayout from '@layout/BasicLayout'
import RouteLayout from '@layout/RouteLayout'

import {
    CUSTOMER_MENU_CODE,
    CUSTOMER_MANAGE_MENU_CODE,
    CUSTOMER_LIST_MENU_CODE,
    HIGHSEA_CUSTOMER_MENU_CODE,
    HIGHSEA_CUSTOMER_LIST_MENU_CODE,
    HIGHSEA_CUSTOMER_DETAIL_MENU_CODE,
    DISTRIBUTION_CUSTOMER_MENU_CODE,
    DISTRIBUTION_CUSTOMER_LIST_MENU_CODE,
    DISTRIBUTION_CUSTOMER_DETAIL_MENU_CODE,
    PROPRIETARY_CUSTOMER_MENU_CODE,
    PROPRIETARY_CUSTOMER_LIST_MENU_CODE,
    PROPRIETARY_CUSTOMER_DETAIL_MENU_CODE
} from '@constant/menu_code'

const routes = [{
    path: '/customer',
    name: 'Customer',
    redirect: '/customer',
    component: BasicLayout,
    code: CUSTOMER_MENU_CODE,
    sort: 2,
    meta: {
        title: '客户管理',
        icon: 'C-kehuguanli',
        iconType: 'iconfont'
    },
    children: [{
        path: 'customer-manage',
        redirect: '/customer/customer-manage/list',
        name: 'CustomerManage',
        component: RouteLayout,
        code: CUSTOMER_MANAGE_MENU_CODE,
        meta: { title: '客户列表' },
        children: [{
            path: 'list',
            name: 'CustomerManageList',
            hidden: true,
            component: () => import('./CustomerList/index'),
            code: CUSTOMER_LIST_MENU_CODE,
            meta: {
                title: '客户列表',
                breadcrumb: false
            }
        }]
    },
    {
        path: 'high-seas-customer-manage',
        redirect: '/customer/high-seas-customer-manage/list',
        name: 'HighSeasCustomerManage',
        component: RouteLayout,
        code: HIGHSEA_CUSTOMER_MENU_CODE,
        meta: { title: '公海客户管理' },
        children: [{
            path: 'list',
            name: 'HighSeasCustomerList',
            hidden: true,
            component: () => import('./HighSeasCustomerList/index'),
            code: HIGHSEA_CUSTOMER_LIST_MENU_CODE,
            meta: {
                title: '公海客户管理',
                breadcrumb: false,
                keepAlive: true
            }
        }, {
            path: 'detail',
            name: 'HighSeasCustomerDetail',
            hidden: true,
            component: () => import('./HighSeasCustomerDetail/index'),
            code: HIGHSEA_CUSTOMER_DETAIL_MENU_CODE,
            meta: {
                title: '客户详情',
                breadcrumb: true
            }
        }]
    },
    {
        path: 'distribution-customer',
        redirect: '/customer/distribution-customer/list',
        name: 'DistributionCustomer',
        component: RouteLayout,
        code: DISTRIBUTION_CUSTOMER_MENU_CODE,
        meta: { title: '客户跟进' },
        children: [
            {
                path: 'list',
                name: 'DistributionCustomerList',
                hidden: true,
                component: () => import('./DistributionCustomer/DistributionCustomerList/index'),
                code: DISTRIBUTION_CUSTOMER_LIST_MENU_CODE,
                meta: {
                    title: '客户跟进',
                    breadcrumb: false,
                    // keepAlive: true
                }
            }, {
                path: 'detail',
                name: 'DistributionCustomerDetail',
                hidden: true,
                component: () => import('./DistributionCustomer/DistributionCustomerDetail/index'),
                code: DISTRIBUTION_CUSTOMER_DETAIL_MENU_CODE,
                meta: {
                    title: '客户详情',
                    breadcrumb: true
                }
            }
        ]
    },
    {
        path: 'proprietary-customer',
        redirect: '/customer/proprietary-customer/list',
        name: 'ProprietaryCustomer',
        component: RouteLayout,
        code: PROPRIETARY_CUSTOMER_MENU_CODE,
        meta: { title: '客户维护' },
        children: [
            {
                path: 'list',
                name: 'ProprietaryCustomerList',
                hidden: true,
                component: () => import('./ProprietaryCustomer/ProprietaryCustomerList/index'),
                code: PROPRIETARY_CUSTOMER_LIST_MENU_CODE,
                meta: {
                    title: '客户维护',
                    breadcrumb: false,
                    // keepAlive: true
                }
            }, {
                path: 'detail',
                name: 'ProprietaryCustomerDetail',
                hidden: true,
                component: () => import('./ProprietaryCustomer/ProprietaryCustomerDetail/index'),
                code: PROPRIETARY_CUSTOMER_DETAIL_MENU_CODE,
                meta: {
                    title: '客户详情',
                    breadcrumb: true
                }
            }
        ]
    }
    ]
}]

export default routes
