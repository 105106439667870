// 接口请求
import getTeamUserSelectListService from '@/service/customer/getTeamUserSelectListService'
import getInsuranceTypeListService from '@service/workSpace/getInsuranceTypeListService'
import getInsureCompanyListService from '@service/insureCompany/getInsureCompanyListService'
import getTeamAuthBrokerListService from '@/service/workSpace/policyMange/getTeamAuthBrokerListService'
export default {
    computed: {
        // 用户信息缓存
        userInfo() {
            return this.$store.getters.userInfo
        }
    },
    methods: {
        /**
         * 获取险种
         */
        async getInsureList(val) {
            try {
                const data = await getInsuranceTypeListService()
                this[val] = data || []
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {}
        },
        /**
         * 获取保险公司
         */
        async getInsureCompanyList(val) {
            try {
                const data = await getInsureCompanyListService()
                this[val] = data || []
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {}
        },
        /**
         * 获取人员名称数据
         */
        async getNameSelect(userNameQuery, loading, list, name, id) {
            if (!userNameQuery) {
                this[list] = []
                this[loading] = false
                return false
            }
            try {
                this[loading] = true
                const data = await getTeamUserSelectListService({
                    backMapQueryResult: false,
                    userNameQuery
                })
                this[list] = data || []
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this[loading] = false
            }
        },
        handleNameChange(value, loading, list, name, id) {
            if (!value) {
                this[list] = []
            } else {
                let splitValue = value && value.split(' ')[0]
                this.getNameSelect(splitValue, loading, list, name, id)
            }
            this.getNameId(value, loading, list, name, id)
        },
        /**
         * 根据name获取id
         */
        getNameId(value, loading, list, name, id) {
            // const { personalSelectList } = this
            let splitValue = value && value.split(' ')[0]
            let splitValueId = value && value.split(' ')[1]
            let result = this[list].find(item => {
                return item.userName === splitValue && `（${item.userId}）` === splitValueId
            })
            this.form[id] = result && result.userId || ''
        },

        /**
         * 获取人员名称数据(权限相关)
         */
        async getNameSelectPower(userNameQuery, loading, list, name, selectId) {
            const { userInfo } = this
            const { id } = userInfo || {}
            try {
                this[loading] = true
                const data = await getTeamAuthBrokerListService({
                    authUserId: id,
                    teamUserNameQuery: userNameQuery
                })
                if (list) {
                    this[list] = data || []
                } else {
                    this.personalSelectList = data
                    this.operatePersonalSelectList = data
                }
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this[loading] = false
            }
        },
        handleNameChangePower(value, loading, list, name, id) {
            if (!value) {
                this.getNameSelectPower('', loading, list, name, id)
            } else {
                let splitValue = value && value.split(' ')[0]
                this.getNameSelectPower(splitValue, loading, list, name, id)
            }
            this.getNameIdPower(value, loading, list, name, id)
        },
        /**
         * 根据name获取id
         */
        getNameIdPower(value, loading, list, name, id) {
            // const { personalSelectList } = this
            let splitValue = value && value.split(' ')[0]
            let splitValueId = value && value.split(' ')[1]
            let result = this[list].find(item => {
                return item.userName === splitValue && `（${item.userId}）` === splitValueId
            })
            this.form[id] = result && result.userId || ''
        }
    }
}