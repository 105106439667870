<!-- 客户详情-跟进记录-筛选 -->
<template>
    <search-panel :model="form"  layout="vertical" @search="handleSearch" @refresh="handleRefresh">
        <form-col label="跟进方式" prop="type">
            <template :slot="formColDefaultSlot">
                <a-select v-model="form.type" placeholder="请选择">
                    <a-select-option value>全部</a-select-option>
                    <template v-for="item in CUSTOMER_FOLLOW_TYPE_ENUM_LIST">
                        <a-select-option :key="item.value" :value="item.value">{{ item.label }}</a-select-option>
                    </template>
                </a-select>
            </template>
        </form-col>
        <form-col label="标题" prop="titleQuery">
            <template :slot="formColDefaultSlot">
                <a-input v-model="form.titleQuery" placeholder="请输入" />
            </template>
        </form-col>
        <form-col label="跟进人" prop="agentUserNameQuery">
            <template :slot="formColDefaultSlot">
                <a-input v-model="form.agentUserNameQuery" placeholder="请输入" />
            </template>
        </form-col>
        <form-col
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            :span="12"
            label="跟进时间"
            prop="submitTime">
            <template :slot="formColDefaultSlot">
                <a-range-picker v-model="form.submitTime"
                                :mode="submitTimeMode"
                                :show-time="showTimeOptions"
                />
            </template>
        </form-col>
    </search-panel>
</template>

<script>
// 常量
import { CUSTOMER_FOLLOW_TYPE_ENUM_LIST } from '@/constant/enum'

// 工具类
import common from '../../mixins'

export default {
    name: 'FollowRecordSearchPanel',
    mixins: [common],
    props: {
        echoData: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            CUSTOMER_FOLLOW_TYPE_ENUM_LIST,
            form: {
                // 跟进方式
                type: '',
                // 标题
                titleQuery: '',
                // 跟进人
                agentUserNameQuery: '',
                // 跟进时间
                submitTime: []
            },
            labelCol: {
                span: 4
            },
            wrapperCol: {
                span: 20
            }
        }
    },
    watch: {
        echoData: {
            handler(val) {
            },
            immediate: true
        }
    },
    mounted() {},
    methods: {
        /**
         * 格式化参数
         */
        formatParams() {
            const { form } = this
            let { submitTime, ...params } = form
            let [startTime, endTime] = submitTime || []
            startTime = startTime ? `${startTime.format('YYYY-MM-DD HH:mm:ss')}` : ''
            endTime = endTime ? `${endTime.format('YYYY-MM-DD HH:mm:ss')}` : ''
            params.dataTimeStart = startTime
            params.dateTimeEnd = endTime
            return params
        }
    }
}
</script>

<style lang="scss">
</style>
