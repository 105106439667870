<!-- 保单记录-详情-查看 -->
<template>
    <a-modal
        class="policy-detail-view"
        :visible="visible"
        @cancel="handleOk"
    >
        <template #title>
            <img :src="status ? success : alert" alt="">
            <span>{{title}}</span>
        </template>
        <div>
            <template v-if="type === 1">
                <p>保单暂未生成！</p>
            </template>
            <template v-if="type === 2">
                <p>可登录工保网填写开票信息，申请发票！</p>
            </template>
            <template v-if="type === 3">
                <p>快递公司：</p>
                <p>快递单号：<a-button type="link" @click="handleCopy">复制</a-button></p>
            </template>
            <template v-if="type === 4">
                <p>电子发票暂未生成！</p>
            </template>
            <template v-if="type === 5">
                <p>纸质发票暂未寄出！</p>
            </template>
            <template v-if="type === 6">
                <p>可能用户暂未申请发票或者发票暂未生成！</p>
            </template>
        </div>
        <template #footer>
            <a-button @click="handleOk">{{status ? '确定' : '关闭'}}</a-button>
        </template>
    </a-modal>
</template>

<script>
    // 组件
    import { Modal as AModal, Icon as AIcon, Button as AButton } from 'ant-design-vue'

    // 工具类
    import { copyText } from '@/utils/copyText'

    export default {
        name: 'PolicyRecordDetailViewModal',
        components: {
            AModal,
            AIcon,
            AButton
        },
        props: {
            visible: {
                type: Boolean,
                default() {
                    return false
                }
            },
            type: {
                type: [Number, String],
                default() {
                    return ''
                }
            },
            status: {
                type: [Number, String],
                default() {
                    return ''
                }
            }
        },
        data() {
            return {
                success: require('@/assets/images/icon/success.png'),
                alert: require('@/assets/images/icon/alert.png')
            }
        },
        computed: {
            title() {
                const { type } = this
                const obj = {
                    1: '未生成保单',
                    2: '暂未申请发票',
                    3: '已申请纸质发票',
                    4: '已申请电子发票',
                    5: '已申请纸质发票',
                    6: '暂未有发票'
                }
                return obj[type]
            }
        },
        methods: {
            handleOk() {
                this.$emit('success')
            },
            handleCopy() {
                copyText('')
                this.$message.success('复制成功！')
            }
        }
    }
</script>

<style lang="scss">
    .policy-detail-view {
        .ant-modal-header {
            padding: 25px 24px 16px;
            border-bottom: none;
            font-size: 16px;
            color: #323233;
            img {
                margin-right: 8px;
            }
        }
        .ant-modal-body {
            padding: 0 22px 71px 56px;
            font-size: 14px;
            color: #323233;
            p {
                margin: 0;
            }
        }
        .ant-modal-footer {
            padding: 0 24px 16px;
            border-top: none;
            button {
                width: 76px;
                background-color: #1877F2;
                color: white
            }
        }
    }
</style>


