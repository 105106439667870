// 常量
import {
    SALES_CATEGORY_DISTRIBUTION_TEXT,
    SALES_CATEGORY_SELF_SUPPORT_TEXT,
    SALES_CATEGORY_DISTRIBUTION,
    SALES_CATEGORY_SELF_SUPPORT
} from '@/constant/enum'
import {
    DISTRIBUTION_CUSTOMER_DETAIL_MENU_CODE,
    PROPRIETARY_CUSTOMER_DETAIL_MENU_CODE
} from '@/constant/menu_code'
// 接口请求
import searchTeamUserService from '@service/customer/common/searchTeamUserService'
import searchTeamUserBatchService from '@service/customer/common/searchTeamUserBatchService'

export default {
    data() {
        return {
            SALES_CATEGORY_DISTRIBUTION_TEXT,
            SALES_CATEGORY_SELF_SUPPORT_TEXT,
            SALES_CATEGORY_DISTRIBUTION,
            SALES_CATEGORY_SELF_SUPPORT,
            DISTRIBUTION_CUSTOMER_DETAIL_MENU_CODE,
            PROPRIETARY_CUSTOMER_DETAIL_MENU_CODE,
            // 加自营分销type的表格数据
            tableListAddType: []
        }
    },
    computed: {
        role() {
            return this.$store.getters.role
        }
    },
    methods: {
        /**
         * 判断经纪人类型
         */
        async judgeAgentType(userId) {
            if (!userId) return

            try {
                this.loading = true
                const result = await searchTeamUserService({ userId })
                const { type } = result
                this.agentType = type
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        },
        /**
         * 批量判断经纪人类型
         */
        async judgeAgentTypeBatch(userIdList, tableList, loadingStatus) {
            // 获取去重数组
            let uniqueUserIdList = [...new Set(userIdList)]
            if (!userIdList || !userIdList.length) {
                this.tableListAddType = []
                return false
            }
            try {
                const resultList = await searchTeamUserBatchService(uniqueUserIdList) || []

                // 组合对象{userId: type} 把type映射到tableList里面
                let typeObj = {}
                resultList.forEach(item => {
                    typeObj[item.userId] = item.type || ''
                })
                // type映射到tableList里面
                tableList.length && tableList.forEach(item => {
                    item.detailType = item.agentUserIdSet ? (typeObj[item.agentUserIdSet] || '') : ''
                    item.hasAuthDetail = this.judgeAuthRole(item.detailType) || false
                })
                this.tableListAddType = tableList
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this[loadingStatus] = false
            }
        },
        /**
         * 判断当前登录账户是否有分销/自营详情的菜单权限
         */
        judgeAuthRole(agentType) {
            const {
                SALES_CATEGORY_DISTRIBUTION_TEXT,
                SALES_CATEGORY_SELF_SUPPORT_TEXT,
                DISTRIBUTION_CUSTOMER_DETAIL_MENU_CODE,
                PROPRIETARY_CUSTOMER_DETAIL_MENU_CODE,
                role
            } = this
            let key = ''

            // 分销
            if (agentType === SALES_CATEGORY_DISTRIBUTION_TEXT) {
                key = DISTRIBUTION_CUSTOMER_DETAIL_MENU_CODE
            } else if (agentType === SALES_CATEGORY_SELF_SUPPORT_TEXT) {
                // 自营
                key = PROPRIETARY_CUSTOMER_DETAIL_MENU_CODE
            }
            return role && role[key]
        }
    }
}
