<template>
    <a-modal
        centered
        destroy-on-close
        title="批量添加客户"
        :visible="visible"
        ok-text="提交"
        class="agreement-edit-modal"
        :width="520"
        :mask-closable="maskClosable"
        @cancel="handleCancel"
    >
        <form-panel
            ref="formPanel"
            @success="handleSuccess"
            @loading="handleLoading"
            @import="handleImportSuccess"
            @download="handleImportDownload" />
        <template #footer>
            <a-button v-if="!importSuccess" key="reset" @click="handleCancel"> 取消 </a-button>
            <a-button v-if="importSuccess" key="reset" @click="handleCancel"> 关闭 </a-button>
            <a-button
                v-if="!importSuccess"
                key="submit"
                type="primary"
                :loading="loading"
                @click="handleOk">
                导入
            </a-button>
            <a-button
                v-else-if="downloadUrl"
                key="submit"
                type="primary"
                :loading="loading"
                @click="handleDownload">
                下载表格
            </a-button>
        </template>
    </a-modal>
</template>

<script>
import { Modal as AModal, Button as AButton } from 'ant-design-vue'
import FormPanel from './FormPanel'
import { gotoPage } from '@utils/dom'

export default {
    name: 'BatchImportCustomerModal',
    components: {
        FormPanel,
        AModal,
        AButton
    },
    props: {
        visible: Boolean
    },
    data() {
        return {
            loading: false,
            maskClosable: false,
            importSuccess: false,
            downloadUrl: ''
        }
    },
    computed: {
        okButtonProps() {
            const { loading } = this
            return {
                props: {
                    loading
                }
            }
        }
    },
    watch: {
        visible: {
            handler(val) {
                if (val) {
                    this.importSuccess = false
                }
            },
            immediate: true
        }
    },
    methods: {
        /**
         * 提交按钮事件
         */
        handleOk() {
            this.$refs.formPanel.handleSend()
        },
        /**
         * 按钮loading
         */
        handleLoading(loading) {
            this.loading = loading
        },
        /**
         * 关闭弹框
         */
        handleCancel() {
            this.$emit('on-close')
            this.importSuccess && this.$emit('on-refresh')
        },
        handleSuccess() {
            this.$emit('on-refresh')
            this.$emit('on-close')
        },
        /**
         * 导入成功
         */
        handleImportSuccess() {
            this.importSuccess = true
        },
        /**
         * 导入失败带下载
         */
        handleImportDownload(url) {
            this.downloadUrl = url
        },
        /**
         * 下载表格
         */
        handleDownload() {
            try {
                // 执行模板下载
                gotoPage(this.downloadUrl)
            } catch (e) {
                this.$message.error(e.message)
            }
        }
    }
}
</script>

<style lang="scss">
.agreement-edit-modal {
    .ant-modal-footer {
        text-align: center;
    }
    .ant-modal-body {
        overflow: hidden;
    }
}
</style>
