/**
 * 判断经纪人是否当前登录账号
 */
 export default {
    computed: {
        userInfo() {
            return this.$store.getters.userInfo
        }
    },
    methods: {
        handleIsLoginUserId(agentId) {
            const { userInfo } = this
            const { id } = userInfo || {}
            if (agentId && id) {
                if (agentId === id) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        }
    }
}
