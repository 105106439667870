/**
 * 产品列表
 * ——————————————————————————————————————————————————————————————————————————————————————————————
 */
// 产品列表-分享
export const PRODUCT_PAGE_LIST_LIST_SHARE_BUTTON = 'PRODUCT_PAGE_LIST_LIST_SHARE'
// 产品列表-查看详情
export const PRODUCT_PAGE_LIST_LIST_DETAIL_BUTTON = 'PRODUCT_PAGE_LIST_LIST_DETAIL'
// 产品列表-推广佣金
export const PRODUCT_PAGE_LIST_LIST_COMMISSION_BUTTON = 'PRODUCT_PAGE_LIST_LIST_COMMISSION'
// 企业认证产品列表-分享
export const ENTERPRISE_AUTHORIZATION_PRODUCT_LIST__SHARE_BUTTON = 'ENTERPRISE_AUTHORIZATION_PRODUCT_LIST__SHARE'
// 企业认证产品列表-查看详情
export const ENTERPRISE_AUTHORIZATION_PRODUCT_LIST_DETAIL_BUTTON = 'ENTERPRISE_AUTHORIZATION_PRODUCT_LIST_DETAIL'
