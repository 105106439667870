<!-- 中标资讯-列表-筛选 -->
<template>
    <search-panel :model="form" layout="vertical" @search="handleSearch" @refresh="handleRefresh">
        <form-col label="项目名称" prop="proNameQuery">
            <template :slot="formColDefaultSlot">
                <a-input v-model.trim="form.proNameQuery" placeholder="请输入项目名称" />
            </template>
        </form-col>
        <form-col label="项目类型" prop="proTypeQuery">
            <template :slot="formColDefaultSlot">
                <a-input v-model.trim="form.proTypeQuery" placeholder="请输入项目类型" />
            </template>
        </form-col>
        <form-col label="地区" prop="cityQuery">
            <template :slot="formColDefaultSlot">
                <a-input v-model.trim="form.cityQuery" placeholder="请输入地区" />
            </template>
        </form-col>
        <form-col label="中标企业" prop="bidWinningNameQuery">
            <template :slot="formColDefaultSlot">
                <a-input v-model.trim="form.bidWinningNameQuery" placeholder="请输入中标企业" />
            </template>
        </form-col>
        <form-col
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            :span="12"
            label="发布时间"
            prop="submitTime">
            <template :slot="formColDefaultSlot">
                <a-range-picker v-model="form.submitTime"
                                :mode="submitTimeMode"
                                :show-time="showTimeOptions"
                />
            </template>
        </form-col>
    </search-panel>
</template>

<script>
// 组件
import {
    Input as AInput,
    DatePicker as ADatePicker
} from 'ant-design-vue'
import SearchPanel from '@components/BaseSearchPanel'
import FormCol from '@components/FormCol'

// 工具类
import { filterParams } from '@utils/search.js'
import moment from 'moment'

// mixins
import getTime from '@mixins/getTime'
import searchCommon from '@mixins/searchCommon'

export default {
    name: 'GPlatformSearchPanel',
    components: {
        SearchPanel,
        FormCol,
        AInput,
        ARangePicker: ADatePicker.RangePicker
    },
    mixins: [getTime, searchCommon],
    data() {
        return {
            form: {
                // 项目名称,
                proNameQuery: '',
                // 项目类型
                proTypeQuery: '',
                // 地区
                cityQuery: '',
                // 中标企业
                bidWinningNameQuery: '',
                // 发布时间
                submitTime: []
            },
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
            submitTimeMode: ['date', 'date'],
            showTimeOptions: {
                defaultValue: [
                    moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')
                ]
            },
            // 筛选-插槽
            formColDefaultSlot: 'formElements'
        }
    },
    computed: { },
    watch: {},
    mounted() {},
    methods: {
        /**
         * 筛选-响应搜索事件
         */
        handleSearch() {
            const params = this.formatParams()
            this.$emit('search', filterParams(params))
        },
        /**
         * 筛选-响应刷新事件
         */
        handleRefresh() {
            const params = this.formatParams()
            this.$emit('refresh', filterParams(params))
        },
        /**
         * 格式化参数
         */
        formatParams() {
            const { form } = this
            let { submitTime, ...params } = form
            let [startTime, endTime] = submitTime || []
            startTime = startTime ? `${startTime.format('YYYY-MM-DD HH:mm:ss')}` : ''
            endTime = endTime ? `${endTime.format('YYYY-MM-DD HH:mm:ss')}` : ''
            params.publishDateStart = startTime
            params.publishDateEnd = endTime
            return params
        }
    }
}
</script>

<style lang="scss">
</style>
