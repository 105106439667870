import { getVm } from '@/utils/vm'
import { MODE_EUMN } from './constant'
import { CUSTOMER_INTENTION_ENUM_LIST, CUSTOMER_FOLLOW_TYPE_ENUM_LIST, SEX_ENUM_LIST } from '@/constant/enum'
import { validatePhoneNumber, validateEmailFunc } from '@utils/validate'
import getTeamUserSelectListService from '@/service/customer/getTeamUserSelectListService'
import moment from 'moment'
import { validatePhoneNumberAndLandlineNumber } from '@/utils/validate.js'

// 分配经纪人配置
const agentUserIdQueryItem = {
    title: '分配绑定经纪人',
    dataIndex: 'agentUserIdQuery',
    valueType: 'searchInput',
    fieldProps: {
        placeholder: '请搜索',
        onSearch: async keyword => {
            try {
                const data = await getTeamUserSelectListService({
                    channel: false,
                    type: '自营',
                    userFormalStateEnum: '在职',
                    userNameQuery: keyword
                })
                return data.map(({ userId, userName }) => ({
                    label: userName,
                    value: userId
                })) || []
            } catch (e) {
                getVm().$message.error(e.message)
            }
        }
    }
}

export default {
    // 新增客户
    [MODE_EUMN.ADD_CUSTOMER]: [
        {
            title: '手机号码',
            dataIndex: 'mobile',
            rules: [{
                required: true,
                message: '请输入手机号'
            }, {
                validator: validatePhoneNumber,
                trigger: 'blur'
            }]
        },
        {
            title: '姓名',
            dataIndex: 'nameQuery',
            fieldProps: { maxLength: 20 }
        },
        {
            title: '企业名称',
            dataIndex: 'enterpriseName',
            fieldProps: { maxLength: 50 }
        },
        {
            title: '所在地区',
            dataIndex: 'area',
            valueType: 'address'
        },
        {
            title: '备注',
            dataIndex: 'description',
            valueType: 'textArea',
            fieldProps: { maxLength: 200 }
        }
        // agentUserIdQueryItem
    ],
    // 客户备注
    [MODE_EUMN.EDIT_CUSTOMER]: [
        {
            title: '手机号码',
            dataIndex: 'mobile',
            initialValue: '',
            fieldProps: { disabled: true }
        },
        {
            title: '姓名',
            dataIndex: 'name',
            fieldProps: { maxLength: 20 }
        },
        {
            title: '性别',
            dataIndex: 'gender',
            valueType: 'select',
            fieldProps: { options: SEX_ENUM_LIST }
        },
        {
            title: '客户意向',
            dataIndex: 'intention',
            valueType: 'select',
            rules: [{
                required: true,
                message: '请选择'
            }],
            fieldProps: { options: CUSTOMER_INTENTION_ENUM_LIST }
        },
        {
            title: '企业名称',
            dataIndex: 'enterpriseName',
            fieldProps: { maxLength: 50 }
        },
        {
            title: '电子邮箱',
            dataIndex: 'email',
            fieldProps: { maxLength: 20 },
            rules: [{ validator: validateEmailFunc }]
        },
        {
            title: '所在地区',
            dataIndex: 'area',
            valueType: 'address'
        },
        {
            title: '详细地址',
            dataIndex: 'address',
            fieldProps: { maxLength: 50 }
        },
        {
            title: '备注',
            dataIndex: 'description',
            valueType: 'textArea',
            fieldProps: { maxLength: 200 }
        }
    ],
    // 跟进记录
    [MODE_EUMN.FOLLOW_RECORD]: [
        {
            title: '跟进方式',
            dataIndex: 'type',
            valueType: 'radio',
            fieldProps: { options: CUSTOMER_FOLLOW_TYPE_ENUM_LIST },
            rules: [{
                required: true,
                message: '请选择'
            }]
        },
        {
            title: '跟进时间',
            dataIndex: 'dataTime',
            valueType: 'datePicker',
            initialValue: () => moment(),
            fieldProps: { showTime: true },
            rules: [{
                required: true,
                message: '请选择'
            }]
        },
        {
            title: '跟进标题',
            dataIndex: 'title',
            fieldProps: { maxLength: 20 },
            rules: [{
                required: true,
                message: '请输入'
            }]
        },
        {
            title: '跟进内容',
            dataIndex: 'content',
            valueType: 'textArea',
            fieldProps: { maxLength: 200 }
        },
        {
            title: '联系人',
            dataIndex: 'contactPerson',
            fieldProps: { maxLength: 20 }
        },
        {
            title: '联系方式',
            dataIndex: 'contactPersonPhone',
            fieldProps: { maxLength: 50 },
            rules: [{
                validator: validatePhoneNumberAndLandlineNumber,
                trigger: 'blur'
            }]
        },
        {
            title: '下次跟进时间',
            dataIndex: 'nextDataTime',
            valueType: 'datePicker',
            fieldProps: {
                showTime: true,
                align: { points: ['bl', 'tl'] }
            }
        },
        {
            title: '下次跟进内容',
            dataIndex: 'nextContent',
            valueType: 'textArea',
            fieldProps: { maxLength: 200 }
        }
    ],
    // 分配经纪人
    [MODE_EUMN.DISTRIBUTE]: [
        {
            dataIndex: 'descriptions',
            valueType: 'descriptions',
            fieldProps: {
                column: 1,
                bordered: true,
                options: [
                    {
                        title: '用户ID',
                        key: 'userId'
                    }, {
                        title: '登录账号',
                        key: 'mobile'
                    }, {
                        title: '姓名',
                        key: 'name'
                    }
                ]
            }
        }, {
            ...agentUserIdQueryItem,
            dataIndex: 'agentUserId',
            rules: [{
                required: true,
                message: '请选择经纪人'
            }]
        }
    ],
    // 批量分配经纪人
    [MODE_EUMN.BATCH_DISTRIBUTE]: [
        {
            dataIndex: 'table',
            valueType: 'table',
            valueName: 'dataSource',
            fieldProps: {
                rowKey: 'mobile',
                columns: [{
                    title: '手机号码',
                    dataIndex: 'mobile'
                }, {
                    title: '姓名',
                    dataIndex: 'name'
                }],
                pagination: {
                    size: 'small',
                    pageSize: 5
                }
            }
        }, {
            ...agentUserIdQueryItem,
            dataIndex: 'agentUserId',
            rules: [{
                required: true,
                message: '请选择经纪人'
            }]
        }
    ]
}
