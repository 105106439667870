<template>
    <a-card class="container-panel" :bordered="false" :class="{noCardBodyPaddingTop}">
        <!-- 筛选条件 start -->
        <div v-if="$slots.screen" class="container-panel__screen">
            <slot name="screen" />
        </div>
        <!-- 筛选条件 end -->

        <!-- 统计内容 start -->
        <div v-if="$slots.statistics" class="container-panel__statistics">
            <slot name="statistics" />
        </div>
        <!-- 统计内容 end -->

        <!-- 左侧内容 start -->
        <div v-if="$slots.leftTitle" class="container-panel__left-title">
            <slot name="leftTitle" />
        </div>
        <!-- 左侧内容 end -->

        <!-- 操作按钮 start -->
        <div v-if="$slots.button" class="container-panel__button-group">
            <slot name="button" />
        </div>
        <!-- 操作按钮 end -->
        <slot name="tabs" />
        <!-- 表格 start -->
        <div  v-if="$slots.content" class="container-panel__content">
            <div class="container-panel__content-container">
                <slot name="content" />
            </div>
        </div>
        <!-- 表格 end -->

        <slot />
    </a-card>
</template>

<script>
import { Card as ACard } from 'ant-design-vue'

export default {
    name: 'PagePanel',
    components: { ACard },
    props: {
        // 该字段解决标题和content插槽内容距离过大
        noCardBodyPaddingTop: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang='scss'>
@import '@assets/styles/varibles.scss';

.container-panel {
    background: #fff;
    box-shadow: 0px 2px 8px 0px #F9F9F9;
    border-radius: 4px;
    border: 1px solid #eee;
    &__screen {
        padding-bottom: 24px;
    }
    /*左侧标题*/
    &__left-title {
        text-align: left;
        margin-bottom: 8px;
    }

    /*操作按钮*/
    &__button-group {
        text-align: left;
        margin-bottom: 8px;
        .ant-btn:not(:last-child) {
            margin-right: $padding;
        }
    }
    /* 表格 */
    &__content {
        .ant-table-content {
            th {
                color: $custom-button-default-text-color;
                font-size: $font-size;
                font-weight: bold;
            }
            td {
                color: $custom-button-default-text-color;
                font-size: $font-size;
            }
            .ant-table-align-right {
                padding-right: 16px !important;
            }
            .ant-table-align-left {
                padding-left: 16px !important;
            }
            button {
                padding-right: 0 !important;
                padding-left: 8px !important;
            }
            button:last-child {
                padding-right: 8px !important;
            }
        }
    }
}
.noCardBodyPaddingTop{
    .ant-card-body{
        padding: 0 $ant-card-body-padding $ant-card-body-padding;
    }
}
</style>
