/**
 * 线索管理旧--客户线索
 * ——————————————————————————————————————————————————————————————————————————————————————————————
 */
// 客户线索-添加线索
export const CLIENT_CLUE_ADD_CLUE_BUTTON = 'CLIENT_CLUE_ADD_CLUE'
// 客户线索-分配管家
export const CLIENT_CLUE_HAND_OUT_STEWARD_BUTTON = 'CLIENT_CLUE_HAND_OUT_STEWARD'
// 客户线索-备注
export const CLIENT_CLUE_REMARK_CLUE_BUTTON = 'CLIENT_CLUE_REMARK_CLUE'
// 客户线索-备注记录
export const CLIENT_CLUE_REMARK_RECORD_BUTTON = 'CLIENT_CLUE_REMARK_RECORD'

// 经纪人线索-添加线索
export const BROKER_CLUE_ADD_CLUE_BUTTON = 'BROKER_CLUE_ADD_CLUE'
// 经纪人线索-分配管家
export const BROKER_CLUE_HAND_OUT_STEWARD_BUTTON = 'BROKER_CLUE_HAND_OUT_STEWARD'
// 经纪人线索-备注
export const BROKER_CLUE_REMARK_CLUE_BUTTON = 'BROKER_CLUE_REMARK_CLUE'
// 经纪人线索-备注记录
export const BROKER_CLUE_REMARK_RECORD_BUTTON = 'BROKER_CLUE_REMARK_RECORD'