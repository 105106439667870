<!-- 客户管理-详情 -->
<template>
    <list-page-layout>
        <!-- title start -->
        <common-title title="客户详情">
            <template #extra>
                <custom-button type="default" text="返回" @click="handleBack" />
            </template>
        </common-title>
        <!-- title end -->

        <!-- 头部 start -->
        <base-header :detail="detail" :business-type="businessType" :loading="loading" />
        <!-- 头部 end -->

        <!-- tabs start -->
        <tabs-panel
            :tabs-data="tabsData"
            :default-active-key="defaultActiveKey"
            @handle-tabs-change="handleTabsChange"
        />
        <!-- tabs end -->

        <!--        <keep-alive> -->
        <component :is="defaultActiveKey" :params="tabData" @refresh-head="getHead" />
        <!--        </keep-alive> -->
    </list-page-layout>
</template>

<script>
// 组件
import ListPageLayout from '@layout/ListPageLayout'
import CommonTitle from '@components/CommonTitle'
import CustomButton from '@components/Button'
import TabsPanel from '@components/TabsPanel'
import BaseHeader from '@weights/Customer/Components/ComponentsDetail/BaseHeader'

// 常量
import { SALES_CATEGORY_DISTRIBUTION } from '@/constant/enum'

// 接口请求
import getCustomerSelectOneCustomerInfoHeadService from '@service/customer/getCustomerSelectOneCustomerInfoHeadService'

export default {
    name: 'DetailPanel',
    components: {
        ListPageLayout,
        CommonTitle,
        CustomButton,
        TabsPanel,
        BaseHeader,
        // 客户概况
        CustomerProfileTabPanel: () => import('@weights/Customer/Components/ComponentsDetail/Tabs/CustomerProfileTabPanel'),
        // 跟进记录
        FollowRecordTabPanel: () => import('@weights/Customer/Components/ComponentsDetail/Tabs/FollowRecordTabPanel'),
        // 线索记录
        LeadRecordTabPanel: () => import('@weights/Customer/Components/ComponentsDetail/Tabs/LeadRecordTabPanel'),
        // 订单记录
        OrderRecordTabPanel: () => import('@weights/Customer/Components/ComponentsDetail/Tabs/OrderRecordTabPanel'),
        // 保单记录
        PolicyRecordTabPanel: () => import('@weights/Customer/Components/ComponentsDetail/Tabs/PolicyRecordTabPanel'),
        // 发票记录
        InvoiceRecordTabPanel: () => import('@weights/Customer/Components/ComponentsDetail/Tabs/InvoiceRecordTabPanel'),
        // 保全记录
        PreservationRecordTabPanel: () => import('@weights/Customer/Components/ComponentsDetail/Tabs/PreservationRecordTabPanel')
    },
    data() {
        return {
            SALES_CATEGORY_DISTRIBUTION,
            // 客户id
            customerId: '',
            // 经纪人id
            agentUserId: '',
            detail: {},
            tabsData: [
                {
                    key: 'CustomerProfileTabPanel',
                    tab: '客户概况'
                },
                {
                    key: 'FollowRecordTabPanel',
                    tab: '跟进记录'
                },
                {
                    key: 'LeadRecordTabPanel',
                    tab: '线索记录'
                },
                {
                    key: 'OrderRecordTabPanel',
                    tab: '订单记录'
                },
                {
                    key: 'PolicyRecordTabPanel',
                    tab: '保单记录'
                },
                {
                    key: 'InvoiceRecordTabPanel',
                    tab: '发票记录'
                },
                {
                    key: 'PreservationRecordTabPanel',
                    tab: '保全记录'
                }
            ],
            defaultActiveKey: 'CustomerProfileTabPanel',
            tabData: {},
            // 业务类型
            businessType: '',
            loading: false
        }
    },
    watch: {
        $route: {
            handler(route) {
                const { customerId, agentUserId, businessType } = route.query || {}
                if (!( customerId && agentUserId)) return
                this.customerId = customerId
                this.agentUserId = agentUserId
                this.businessType = Number(businessType)

                // 初始化
                this.init()
            },
            immediate: true
        }
    },
    mounted() {},
    methods: {
        /**
         * 初始化
         */
        async init() {
            const { businessType } = this

            // 获取 头部
            await this.getHead()

            // tabs处理
            this.tabsData[0].tab = businessType === SALES_CATEGORY_DISTRIBUTION ? '客户备注' : '客户概况'
            this.handleTabsChange(this.defaultActiveKey)
        },
        /**
         * 获取 头部
         */
        async getHead() {
            try {
                this.loading = true
                const {
                    customerId,
                    agentUserId
                } = this
                const params = {
                    customerId,
                    agentUserId
                }
                const result = await getCustomerSelectOneCustomerInfoHeadService(params)
                this.detail = result
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        },
        /**
         * 返回
         */
        handleBack() {
            const hisStoryLength = window.history.length
            if (hisStoryLength <= 1) {
                window.close()
            } else {
                this.$router.back()
            }
        },
        /**
         * tab-change
         */
        handleTabsChange(key) {
            this.defaultActiveKey = key
            const { customerId, businessType, detail } = this
            const {
                userId,
                associatedAgentUserId,
                allotAgentUserId
            } = detail
            const agentUserId = businessType === SALES_CATEGORY_DISTRIBUTION ? associatedAgentUserId : allotAgentUserId

            let tabData = null

            if (key === 'CustomerProfileTabPanel') {
                // 客户概况
                tabData = {
                    customerId,
                    agentUserId
                }
            } else if (key === 'FollowRecordTabPanel') {
                // 跟进记录
                tabData = {
                    customerId,
                    userId: agentUserId
                }
            } else if (key === 'LeadRecordTabPanel') {
                // 线索记录
                tabData = {
                    customerId,
                    agentUserIdQuery: agentUserId
                }
            } else if (key === 'OrderRecordTabPanel') {
                // 订单记录
                tabData = {
                    agentUserId,
                    castInsuranceUserId: userId
                }
            } else if (key === 'PolicyRecordTabPanel') {
                // 保单记录
                tabData = {
                    userId,
                    crmAgentUserId: agentUserId
                }
            } else if (key === 'InvoiceRecordTabPanel') {
                // 发票记录
                tabData = {
                    agentUserId,
                    castInsuranceUserId: userId
                }
            } else if (key === 'PreservationRecordTabPanel') {
                // 保全记录
                tabData = {
                    userId,
                    crmAgentUserId: agentUserId
                }
            }

            this.tabData = tabData
        }
    }
}
</script>
