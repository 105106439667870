/**
 * 保单管理
 * ——————————————————————————————————————————————————————————————————————————————————————————————
 */
// 线上保单列表-查看
export const ONLINE_POLICY_LIST_DETAIL_BUTTON = 'ONLINE_POLICY_LIST_DETAIL'
// 线上保单详情-查看电子保单
export const ONLINE_POLICY_VIEW_POLICY_BUTTON = 'ONLINE_POLICY_VIEW_POLICY'
// 线上保单详情-查看退保凭证
export const ONLINE_POLICY_VIEW_PROOF_BUTTON = 'ONLINE_POLICY_VIEW_PROOF'
// 线上保单详情-查看电子发票
export const ONLINE_POLICY_VIEW_ELECTRONIC_INVOICE_BUTTON = 'ONLINE_POLICY_VIEW_ELECTRONIC_INVOICE'
// 线上保单详情-下载投保单
export const ONLINE_POLICY_DOWNLOAD_CAST_INSURANCE_BUTTON = 'ONLINE_POLICY_DOWNLOAD_CAST_INSURANCE'
// 线上保单详情-下载反担保
export const ONLINE_POLICY_DOWNLOAD_COUNTER_GUARANTEE_BUTTON = 'ONLINE_POLICY_DOWNLOAD_COUNTER_GUARANTEE'
