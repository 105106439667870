<!-- 描述列表 -->
<template>
    <a-descriptions :column="realColumn" :bordered="bordered" v-bind="$attrs" class="custom-descriptions">
        <a-descriptions-item v-for="({ key, title, value: val, customRender, click }) in options" :key="key" :label="title">
            <a v-if="click" @click="handleClick(click)">
                {{ getVal({ val, customRender, key }) }}
            </a>
            <span v-else>
                {{ getVal({ val, customRender, key }) }}
            </span>
        </a-descriptions-item>
    </a-descriptions>
</template>

<script>
import { Descriptions as ADescriptions } from 'ant-design-vue'

export default {
    name: 'CustomDescriptions',
    components: {
        ADescriptions,
        ADescriptionsItem: ADescriptions.Item
    },
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        options: {
            type: Array,
            default: () => []
        },
        column: {
            type: Number,
            default: 0
        },
        bordered: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        realColumn() {
            return this.column ? this.column : this.bordered ? 2 : 3
        }
    },
    methods: {
        handleClick(click) {
            return click?.()
        },
        getVal({ customRender, val, key }) {
            return customRender ? customRender(this.value[key]) : val || this.value[key] || '-'
        }
    }
}
</script>

<style lang="scss">
.custom-descriptions {
    word-break: break-all;

    &.ant-descriptions-bordered {
        color: #323233;

        .ant-descriptions-item-label {
            width: 150px;
            padding: 14px 12px;
            font-weight: bold;
            text-align: right;
            vertical-align: top;
            background: #f7f8fa;

            &::after {
                display: inline;
                content: ":";
            }
        }

        .ant-descriptions-item-content {
            width: 300px;
            padding: 14px 12px;
            vertical-align: top;
        }
    }
}
</style>
