import {
    BASE_URL
} from '@config'

/** 经纪人
 * ————————————————————————————————————————————————————————————————————————————————————————————*/
/**
 * 经纪人-列表
 *
 */
export const GET_BROKER_LIST = {
    url: `${BASE_URL}/customer/agent-people/select`,
    method: 'get',
    headers: {
        'content-type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
/**
 * 经纪人-备注
 *
 */
export const BROKER_CREATE_REMARK = {
    url: `${BASE_URL}/customer/agent-people-communication/save`,
    method: 'post',
    headers: {
        'content-type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
/**
 * 经纪人-记录
 *
 */
export const GET_BROKER_REMARK_LIST = {
    url: `${BASE_URL}/customer/agent-people-communication/select`,
    method: 'get',
    headers: {
        'content-type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}