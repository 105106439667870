<!-- 发票记录-详情 -->
<template>
    <drawer-panel
        title="发票详情"
        :visible="visible"
        :loading="loading"
        @after-visible-change="afterVisibleChange"
        @close="handleClose"
    >
        <!-- 状态 start -->
        <template #top>
            <status-panel>
                <template #logo>
                    <img :src="imgSrc" alt="">
                </template>
                <template #content>
                    <span>当前发票状态：{{ getApiEnumLabelFromList(INVOICE_STATE_MENU_LIST, record && record.state) }}</span>
                </template>
            </status-panel>
        </template>
        <!-- 状态 end -->

        <template #content>
            <!-- 发票信息 start -->
            <field-list title="发票信息" :data="invoiceInfo">
                <template #opts>
                    <a-button v-auth="viewInvoice" v-if="hasPreview" @click="handlePreview(record && record.invoiceAddress)">查看电子发票</a-button>
                    <a-button v-if="hasExpress" @click="handleExpressModal">查看快递信息</a-button>
                </template>
            </field-list>
            <!-- 发票信息 end -->

            <!-- 保单信息 start -->
            <field-list title="保单信息" :data="policyInfo"/>
            <!-- 保单信息 end -->

            <!-- 移入公海弹框 -->
            <express-modal
                :visible="expressModalVisible"
                title="快递信息"
                :current-row="record"
                @close="handleCloseModal()"
            />
        </template>
    </drawer-panel>
</template>

<script>
    // 组件
    import ExpressModal from './ExpressModal'

    // 常量
    import {
        INVOICE_STATE_MENU_LIST,
        INVOICE_ORDER_TYPE_ENUM_LIST,
        INVOICE_STATE_FINISHED,
        INVOICE_STATE_REFUSE,
        INVOICE_ORDER_TYPE_ELECTRONIC,
        INVOICE_ORDER_TYPE_PAPER,
        SALES_CATEGORY_DISTRIBUTION,
        SALES_CATEGORY_SELF_SUPPORT
    } from '@/constant/enum'
    import {
        DISTRIBUTION_CUSTOMER_ELECTRONIC_INVOICE_BUTTON
    } from '@constant/authEnum/distributionCustomer'
    import {
        PROPRIETARY_CUSTOMER_ELECTRONIC_INVOICE_BUTTON
    } from '@constant/authEnum/proprietaryCustomer'

    // mixins
    import common from '../mixins'

    // 接口请求
    import getCustomerCastManageListDetailService from '@service/customer/getCustomerCastManageListDetailService'

    export default {
        name: 'InvoiceRecordDetail',
        components: {
            ExpressModal
        },
        mixins: [common],
        props: {
            castInsuranceId: {
                type: [Number, String],
                default() {
                    return ''
                }
            },
            visible: {
                type: Boolean,
                default() {
                    return false
                }
            }
        },
        data() {
            return {
                INVOICE_STATE_MENU_LIST,
                INVOICE_ORDER_TYPE_ENUM_LIST,
                INVOICE_STATE_FINISHED,
                INVOICE_STATE_REFUSE,
                INVOICE_ORDER_TYPE_ELECTRONIC,
                INVOICE_ORDER_TYPE_PAPER,
                SALES_CATEGORY_DISTRIBUTION,
                SALES_CATEGORY_SELF_SUPPORT,
                DISTRIBUTION_CUSTOMER_ELECTRONIC_INVOICE_BUTTON,
                PROPRIETARY_CUSTOMER_ELECTRONIC_INVOICE_BUTTON,
                loading: false,
                // 单条
                record: {},
                imgSrc: require('@/assets/images/waiting.png'),
                // 发票信息
                invoiceInfo: [],
                // 保单信息
                policyInfo: [],
                // 查看电子保单
                hasPreview: false,
                // 查看快递信息
                hasExpress: false,
                expressModalVisible: false,
                // 查看电子发票-按钮权限
                viewInvoice: ''
            }
        },
        watch: {
            // 查看电子发票-按钮权限
            businessType: {
                handler(businessType) {
                    // 分销
                    if (businessType === SALES_CATEGORY_DISTRIBUTION) {
                        this.viewInvoice = DISTRIBUTION_CUSTOMER_ELECTRONIC_INVOICE_BUTTON
                    } else if (businessType === SALES_CATEGORY_SELF_SUPPORT) {
                        // 自营
                        this.viewInvoice = PROPRIETARY_CUSTOMER_ELECTRONIC_INVOICE_BUTTON
                    }
                },
                immediate: true
            }
        },
        methods: {
            /**
             * 初始化
             */
            init() {
                // 获取单条
                this.getRecord()
            },
            /**
             * 获取单条
             */
            async getRecord() {
                const { castInsuranceId } = this
                if (!castInsuranceId) return

                try {
                    this.loading = true
                    const result = await getCustomerCastManageListDetailService({ castInsuranceId })
                    this.record = result || {}

                    // 数据初始化
                    this.initData()
                } catch (e) {
                    this.$message.error(e.message)
                    this.$captureException(e)
                } finally {
                    this.loading = false
                }
            },
            /**
             * 数据初始化
             */
            initData() {
                const { record, INVOICE_STATE_FINISHED } = this
                const {
                    applyTime,
                    invoiceType,
                    invoiceLookedUp,
                    invoiceTaxCoding,
                    registeredAddress,
                    registeredMobile,
                    openAccountBank,
                    bankCard,
                    receiverName,
                    receiverMobile,
                    receiverAddress,
                    insuranceNo,
                    signingTime,
                    insuranceCompany,
                    dangerPlantedName,
                    insuranceMoney,
                    payMoney,
                    state,
                    invoiceAddress,
                    castInsuranceId
                } = record || {}

                // 0. 状态icon
                this.handelImgSrc()

                // 1. 发票信息
                this.invoiceInfo = [
                    {
                        key: '申请时间',
                        value: applyTime
                    },
                    {
                        key: '发票类型',
                        value: this.getApiEnumLabelFromList(INVOICE_ORDER_TYPE_ENUM_LIST, invoiceType)
                    },
                    {
                        key: '发票抬头',
                        value: invoiceLookedUp
                    },
                    {
                        key: '发票税号',
                        value: invoiceTaxCoding
                    },
                    {
                        key: '注册地址',
                        value: registeredAddress
                    },
                    {
                        key: '注册电话',
                        value: registeredMobile
                    },
                    {
                        key: '开户银行',
                        value: openAccountBank
                    },
                    {
                        key: '银行账号',
                        value: bankCard
                    },
                    {
                        key: '收件人',
                        value: receiverName
                    },
                    {
                        key: '电话',
                        value: receiverMobile
                    },
                    {
                        key: '收件地址',
                        value: receiverAddress
                    }
                ]

                // 2. 保单信息
                this.policyInfo = [
                    {
                        key: '保单号',
                        value: insuranceNo,
                        link: true,
                        linkBusiness: 'PolicyRecordDetail',
                        linkParams: {
                            castId: castInsuranceId
                        }
                    },
                    {
                        key: '签单时间',
                        value: signingTime
                    },
                    {
                        key: '保险公司',
                        value: insuranceCompany
                    },
                    {
                        key: '险种',
                        value: dangerPlantedName
                    },
                    {
                        key: '保险金额（保函金额）元',
                        value: insuranceMoney
                    },
                    {
                        key: '保费（元）',
                        value: payMoney
                    }
                ]

                // 查看电子发票
                this.hasPreview = invoiceAddress && state === INVOICE_STATE_FINISHED && invoiceType === INVOICE_ORDER_TYPE_ELECTRONIC

                // 查看快递信息
                this.hasExpress = invoiceAddress && state === INVOICE_STATE_FINISHED && invoiceType === INVOICE_ORDER_TYPE_PAPER
            },
            /**
             * 状态icon
             */
            handelImgSrc() {
                const { state } = this.record || {}

                const errorList = [
                    INVOICE_STATE_REFUSE
                ]
                const successList = [
                    INVOICE_STATE_FINISHED
                ]

                this.imgSrc = this.handleStateIcon(state, errorList, successList)
            },
            /**
             * 查看快递信息-弹框
             */
            handleExpressModal() {
                this.expressModalVisible = true
            },
            /**
             * 查看快递信息-弹框关闭
             */
            handleCloseModal() {
                this.expressModalVisible = false
            }
        }
    }
</script>


