<!-- 公海客户管理/新客户 全部待分配客户 -->
<template>
    <tabs-panel
        class="high-seas-customer-page__tabs"
        :tabs-data="tabsData"
        :default-active-key="defaultActiveKey"
        @handle-tabs-change="handleTabsChange"
    />
</template>

<script>
// 组件
import TabsPanel from '@components/TabsPanel'

// 全局数据
import {} from '@constant/enum'

// mixins
import enumOperate from '@mixins/enumOperate'

export default {
    name: 'CustomerTab',
    components: {
        TabsPanel,
    },
    mixins: [enumOperate],
    data() {
        return {
            tabsData: [
                {
                    tab: '新客户',
                    key: '1'
                }, {
                    tab: '全部待分配客户',
                    key: '0'
                }
            ],
            defaultActiveKey: '1'
        }
    },
    computed: {},
    mounted() {},
    methods: {
        handleTabsChange(key) {
            this.defaultActiveKey = key
            this.$emit('changetab', { isNewCustomer: key })
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';
.high-seas-customer-page {
    &__tabs.component-tabs-panel {
            margin-bottom: 8px;
            .ant-tabs-bar{
            margin: 0;
            background-color: $component-background;
            border-top: 0px dashed #EBEDF0;
            border-bottom: none;
            .ant-tabs-nav-container {
                margin-bottom: 0;
                .ant-tabs-nav-wrap {
                    margin-bottom: 0;
                    .ant-tabs-tab {
                        margin: 0 36px 0 24px;
                        padding: 13px 0;
                        font-size: $font-sizeFifteen;
                        color: $custom-button-default-text-color;
                    }
                    .ant-tabs-tab-active {
                        color: $base-color;
                        border-bottom: 3px solid $base-color;
                    }
                    .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
                        display: none !important;
                    }
                }
            }
        }
        }
    &__title {
        margin-bottom: 30px;
    }
    &__header {
        background: $component-background;
    }
    .ant-card-head {
        border-bottom: none;
    }
    .ant-card-body {
        padding-bottom: 0;
    }
    .ant-tabs-bar {
        border-bottom: none;
    }
}
</style>
