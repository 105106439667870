import { MODE_EUMN } from './constant'


export default {
    // 分配经纪人
    [MODE_EUMN.GENERAL_CUSTOMER]: [
        {
            dataIndex: 'descriptions',
            valueType: 'descriptions',
            fieldProps: {
                column: 1,
                bordered: true,
                options: [
                    {
                        title: '用户ID',
                        key: 'userId'
                    },
                    {
                        title: '手机号码',
                        key: 'mobile'
                    },
                    {
                        title: '姓名',
                        key: 'name'
                    },
                    {
                        title: '关联经纪人',
                        key: 'agentUserName'
                    }
                ]
            }
        }
    ]
}
