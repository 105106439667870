<template>
  <a-card class="page-panel" :bordered="false">
    <slot v-if="$slots.title" name="title" slot="title"></slot>
    <slot v-if="$slots.extra" name="extra" slot="extra"></slot>
    <slot></slot>
  </a-card>
</template>

<script>
import {Card as ACard} from 'ant-design-vue'

export default {
  name: 'PagePanel',
  components: {
    ACard
  }
}
</script>

<style lang="scss">
@import "@assets/styles/varibles.scss";

.page-panel {
  &:not(:last-child) {
    margin-bottom: $padding;
  }
}
</style>