<!-- 保单管理-列表 -->
<template>
    <list-page-layout class="policy-list-page">
        <!-- title -->
        <common-title title="保单管理" />
        <a-spin :spinning="pageLoading">
            <container-panel>
                <!-- 筛选 -->
                <template #screen>
                    <search-panel
                        slot="header"
                        :query="queryFromG"
                        @get-init="handleGetInit"
                        @search="handleSearch"
                        @refresh="handleRefresh"
                    />
                </template>
                <template #leftTitle>
                    <div class="total-policy"><span class="tip-icon">*</span>不含退保共计保费(元)：{{ thousandBit(insurancePremiumTotal * 1 ? insurancePremiumTotal.toFixed(2) : '0.00')  }}</div>
                </template>
                <!-- 表格 start -->
                <template #content>
                    <a-table
                        size="middle"
                        :columns="columns"
                        :data-source="tableListAddType"
                        :pagination="pagination"
                        :loading="loading"
                        row-key="id"
                        :scroll="{ x: '100%', y: 360 }"
                        @change="handleTableChange"
                    >
                        <!-- 投保用户 -->
                        <template slot="submitName" slot-scope="text, record">
                            <div :class="[record.customerId && record.hasAuthDetail ? 'link-user' : 'default-user']"  @click="jumpToCustomerDetail(record)">{{ `${record.submitMobile ? record.submitMobile : ''}` }}</div>
                            <div :class="[record.customerId && record.hasAuthDetail ? 'link-user' : 'default-user']"  @click="jumpToCustomerDetail(record)">{{ `${record.submitId ? '(' + record.submitId + ')' : ''}` }}</div>
                        </template>
                        <!-- 保险期限 -->
                        <template slot="startInsuranceTime" slot-scope="text, record">
                            <div>{{ text }} </div>
                            <div>{{ record.endInsuranceTime }}</div>
                        </template>

                        <!-- 操作 -->
                        <template slot="action" slot-scope="action">
                            <div class="action-button-list">
                                <a-button
                                    v-auth="ONLINE_POLICY_LIST_DETAIL_BUTTON"
                                    type="link"
                                    @click="handleClickLook(action)"
                                >
                                    查看
                                </a-button>
                                <template>
                                    <a-divider v-auth="ONLINE_POLICY_VIEW_POLICY_BUTTON" type="vertical" />
                                    <a-button
                                        v-auth="ONLINE_POLICY_VIEW_POLICY_BUTTON"
                                        type="link"
                                        @click="handleClickPolicyModel(action)"
                                    >
                                        保单
                                    </a-button>
                                </template>
                                <template>
                                    <a-divider v-if="action && action.letterGuaranteeAddress" v-auth="ONLINE_POLICY_VIEW_PROOF_BUTTON" type="vertical" />
                                    <a-button
                                        v-if="action && action.letterGuaranteeAddress"
                                        v-auth="ONLINE_POLICY_VIEW_PROOF_BUTTON"
                                        type="link"
                                        @click="handleSurrenderCertificate(action)"
                                    >
                                        保险凭证
                                    </a-button>
                                </template>
                                <template>
                                    <a-divider type="vertical" style="color:red" />
                                    <a-button
                                        v-auth="ONLINE_POLICY_VIEW_ELECTRONIC_INVOICE_BUTTON"
                                        type="link"
                                        @click="handleClickInvoices(action)"
                                    >
                                        发票
                                    </a-button>
                                </template>
                            </div>
                        </template>
                    </a-table>
                </template>
                <!-- 详情-线上保单 start -->
                <policy-record-detail
                    policy-tag="online"
                    :visible="visible"
                    :cast-id="castId"
                    :customer-id="customerId"
                    :agent-user-id="agentUserId"
                    :type="CUSTOMER_POLICY_BUSINESS_TYPE_ONLINE"
                    @close="handleDetailClose"
                />
                <!-- 详情-线上保单 end -->

                <!-- 详情-线下保单 start -->
                <policy-record-detail-off
                    :id="id"
                    :visible="visibleOff"
                    :customer-id="customerId"
                    :agent-user-id="agentUserId"
                    :type="CUSTOMER_POLICY_BUSINESS_TYPE_ONLINE"
                    @close="handleDetailCloseOff"
                />
                <!-- 详情 end -->

                <!-- 详情-G端保单 start -->
                <policy-record-detail-g
                    :id="id"
                    :visible="visibleG"
                    :customer-id="customerId"
                    :agent-user-id="agentUserId"
                    :type="CUSTOMER_POLICY_BUSINESS_TYPE_ONLINE"
                    @close="handleDetailCloseG"
                />
            </container-panel>
        </a-spin>
        <view-modal :visible="viewVisible" :type="viewType" :status="0" @success="handleSuccess" />
    </list-page-layout>
</template>

<script>
// 组件
import {
    Table as ATable,
    Divider as ADivider,
    Spin as ASpin
} from 'ant-design-vue'
import ListPageLayout from '@layout/ListPageLayout'
import CommonTitle from '@components/CommonTitle'
import ContainerPanel from '@components/ContainerPanel'
import SearchPanel from './SearchPanel'
import PolicyRecordDetail from '@weights/Customer/Components/ComponentsDetail/Detail/PolicyRecordDetail'
import PolicyRecordDetailOff from '@weights/Customer/Components/ComponentsDetail/Detail/PolicyRecordDetailOff'
import PolicyRecordDetailG from '@weights/Customer/Components/ComponentsDetail/Detail/PolicyRecordDetailG'

import ViewModal from '@weights/Customer/Components/ComponentsDetail/Detail/PolicyRecordDetail/ViewModal'

// 工具类
import { previewFiles } from '@/utils/previewFiles'

// 常量
import { DEFAULT_PAGES_SIZE } from '@config'
import { getLabelformList } from '@/utils'
import {
    POLICY_ORDER_STATUS_ENUM_LIST,
    CUSTOMER_POLICY_BUSINESS_TYPE_ONLINE,
    SALES_CATEGORY_DISTRIBUTION,
    SALES_CATEGORY_SELF_SUPPORT,
    POLICY_TYPE_ENUM_LIST,
    POLICY_POLICY_TYPE_ONE,
    POLICY_POLICY_TYPE_TWO,
    POLICY_POLICY_TYPE_THREE
} from '@/constant/enum'

// 权限code
import {
    ONLINE_POLICY_LIST_DETAIL_BUTTON, // 详情
    ONLINE_POLICY_VIEW_POLICY_BUTTON, // 查看电子保单
    ONLINE_POLICY_VIEW_PROOF_BUTTON, // 退保凭证
    ONLINE_POLICY_VIEW_ELECTRONIC_INVOICE_BUTTON // 电子发票
} from '@constant/authEnum/policy'

// mixins
import tableShowTotal from '@mixins/tableShowTotal'
import enumOperate from '@mixins/enumOperate'
import empty from '@mixins/empty'
import thousandBit from '@mixins/thousandBit'
import fileType from '@/mixins/fileType'
import searchTeamUser from '@/mixins/searchTeamUser'

// 接口请求
import onlineInsuranceListService from '@service/policy/policyManage/onlineInsuranceListService'
import searchTeamUserService from '@service/customer/common/searchTeamUserService'

export default {
    name: 'PolicyManagePageList',
    components: {
        ATable,
        ADivider,
        ASpin,
        ListPageLayout,
        CommonTitle,
        ContainerPanel,
        SearchPanel,
        PolicyRecordDetail,
        ViewModal,
        PolicyRecordDetailOff,
        PolicyRecordDetailG
    },
    mixins: [tableShowTotal,
        enumOperate,
        empty,
        thousandBit,
        searchTeamUser,
        fileType],
    data() {
        return {
            ONLINE_POLICY_LIST_DETAIL_BUTTON,
            ONLINE_POLICY_VIEW_POLICY_BUTTON, // 查看电子保单
            ONLINE_POLICY_VIEW_PROOF_BUTTON, // 退保凭证
            ONLINE_POLICY_VIEW_ELECTRONIC_INVOICE_BUTTON,
            POLICY_TYPE_ENUM_LIST,
            POLICY_ORDER_STATUS_ENUM_LIST,
            CUSTOMER_POLICY_BUSINESS_TYPE_ONLINE,
            POLICY_POLICY_TYPE_ONE,
            POLICY_POLICY_TYPE_TWO,
            POLICY_POLICY_TYPE_THREE,
            columns: [
                {
                    title: '保单号',
                    dataIndex: 'policyNo',
                    key: 'policyNo',
                    width: 220,
                    customRender: text => this.empty(text)
                },
                {
                    title: '保单类型',
                    dataIndex: 'type',
                    key: 'type',
                    align: 'center',
                    width: 150,
                    customRender: getLabelformList(POLICY_TYPE_ENUM_LIST)
                },
                {
                    title: '平台系统',
                    dataIndex: 'externalSystemName',
                    key: 'externalSystemName',
                    align: 'center',
                    width: 150,
                    customRender: text => this.empty(text)
                },
                {
                    title: '签单日期',
                    dataIndex: 'singleDateTime',
                    key: 'singleDateTime',
                    align: 'center',
                    width: 150,
                    customRender: text => this.empty(text)
                },
                {
                    title: '险种',
                    dataIndex: 'dangerPlantedName',
                    key: 'dangerPlantedName',
                    align: 'center',
                    ellipsis: true,
                    width: 200,
                    customRender: text => this.empty(text)
                },
                {
                    title: '保险公司',
                    dataIndex: 'insuranceEnterpriseName',
                    key: 'insuranceEnterpriseName',
                    align: 'center',
                    ellipsis: true,
                    width: 200,
                    customRender: text => this.empty(text)
                },
                {
                    title: '产品名称',
                    dataIndex: 'spuName',
                    key: 'spuName',
                    align: 'center',
                    ellipsis: true,
                    width: 200,
                    customRender: text => this.empty(text)
                },
                {
                    title: '投保用户',
                    dataIndex: 'submitName',
                    key: 'submitName',
                    align: 'center',
                    width: 200,
                    scopedSlots: { customRender: 'submitName' }
                },
                {
                    title: '投保人',
                    dataIndex: 'castEntepriseName',
                    key: 'castEntepriseName',
                    ellipsis: true,
                    align: 'center',
                    width: 200,
                    customRender: text => this.empty(text)
                },
                {
                    title: '被保人',
                    dataIndex: 'insuredEnterpriseName',
                    key: 'insuredEnterpriseName',
                    ellipsis: true,
                    align: 'center',
                    width: 200,
                    customRender: text => this.empty(text)
                },
                {
                    title: '项目名称',
                    dataIndex: 'projectName',
                    key: 'projectName',
                    ellipsis: true,
                    align: 'center',
                    width: 200,
                    customRender: text => this.empty(text)
                },
                {
                    title: '保险金额（元）',
                    dataIndex: 'insuranceMoney',
                    key: 'insuranceMoney',
                    align: 'center',
                    width: 200,
                    customRender: text => text ? (text * 1).toFixed(2) : '0.00'
                },
                {
                    title: '保费（元）',
                    dataIndex: 'payMoney',
                    key: 'payMoney',
                    align: 'center',
                    width: 150,
                    customRender: text => text ? (text * 1).toFixed(2) : '0.00'
                },
                {
                    title: '保险期限',
                    dataIndex: 'startInsuranceTime',
                    key: 'startInsuranceTime',
                    align: 'center',
                    width: 150,
                    scopedSlots: { customRender: 'startInsuranceTime' }
                },
                {
                    title: '操作经纪人',
                    dataIndex: 'agentName',
                    width: 200,
                    customRender: (text, record) => {
                        const { operationAgentName, operationAgentId } = record
                        if (!operationAgentName && !operationAgentId) {
                            return this.empty(operationAgentName)
                        } else {
                            return `${this.empty(operationAgentName)}（${this.empty(operationAgentId)}）`
                        }
                    }
                },
                {
                    title: '成交经纪人',
                    dataIndex: 'dealHousekeeperName',
                    width: 200,
                    customRender: (text, record) => {
                        const { agentName, agentId } = record
                        if (!agentName && !agentId) {
                            return this.empty(agentName)
                        } else {
                            return `${this.empty(agentName)}（${this.empty(agentId)}）`
                        }
                    }
                },
                {
                    title: '保单状态',
                    dataIndex: 'state',
                    key: 'state',
                    align: 'center',
                    width: 150,
                    customRender: text => this.empty(text)
                },
                {
                    title: '操作',
                    key: 'action',
                    fixed: 'right',
                    width: 260,
                    scopedSlots: { customRender: 'action' },
                    align: 'left'
                }
            ],
            // 查询条件
            params: {},
            // 数据
            data: [],
            // 分页数量
            limit: DEFAULT_PAGES_SIZE,
            // 总记录数量
            total: 0,
            // 当前索引
            current: 1,
            // 加载状态
            loading: false,
            // 数据量大需要整体页面加载  不然一直点击会报500
            pageLoading: false,
            // 详情-线上保单-抽屉
            visible: false,
            // 详情-线下保单-抽屉
            visibleOff: false,
            // 详情-G端保单-抽屉
            visibleG: false,
            // 保单号
            id: '',
            // 投保单号
            castId: '',
            // 保费金额
            insurancePremiumTotal: 0,
            // 保单弹框
            policyModel: false,
            // 参数来源-G端保单分析
            queryFromG: {},
            modelParams: {},
            // 查看弹框
            viewVisible: false,
            // 查看类型
            viewType: '',
            // 查看-客户id
            customerId: '',
            // 查看-经纪人id
            agentUserId: ''
        }
    },
    computed: {
        userInfo() {
            return this.$store.getters.userInfo
        },
        pagination() {
            const {
                total,
                limit,
                current,
                tableShowTotal
            } = this
            return {
                showTotal: (total, range) => tableShowTotal(total, range),
                current,
                total,
                pageSize: limit,
                showSizeChanger: true,
                showQuickJumper: true
            }
        }
    },
    watch: {
        $route: {
            handler(route) {
                if (route?.query && Object.keys(route.query).length > 0 ) {
                    const { platformSystemCode: externalSystemCode, ...params } = route.query
                    this.queryFromG = {
                        externalSystemCode,
                        ...params
                    }
                }
            },
            immediate: true
        }
    },
    methods: {
        handleGetInit(params) {
            this.params = params
            this.getList()
        },
        /**
         * 获取 列表
         */
        async getList() {
            try {
                this.pageLoading = true
                const { params, current, limit, userInfo } = this
                const { id: loginUser } = userInfo
                const { total, records, extra } = await onlineInsuranceListService({
                    current,
                    size: limit,
                    loginUser,
                    ...params
                })
                this.total = total || 0
                this.data = records || []
                this.insurancePremiumTotal = extra && extra.insurancePremiumTotal || 0
                // 1.获取列表经纪人id去重数组，无则用当前用户登录id
                const { id } = userInfo || {}
                let agentIdList = []
                this.data.length && this.data.forEach(item => { 
                    item.agentUserIdSet = item.agentId || id || ''
                    if ( item.agentUserIdSet) {
                        agentIdList.push(item.agentUserIdSet)
                    }
                })
                // 2.获取到id数组后调用批量获取是自营还是分销类型
                await this.judgeAgentTypeBatch(agentIdList, this.data, 'pageLoading')
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                if (!this.data.length) {
                    this.pageLoading = false
                }
            }
        },
        /**
          * 查看
         */
        handleClickLook(action) {
            const { userInfo } = this
            const {
                id,
                castInsuranceId,
                type,
                customerId,
                agentId
            } = action
            const { id: userId } = userInfo || {}
            this.id = id
            this.castId = castInsuranceId
            this.customerId = customerId
            this.agentUserId = agentId || userId

            if (type === POLICY_POLICY_TYPE_ONE) {
                this.visible = true
            } else if (type === POLICY_POLICY_TYPE_TWO) {
                this.visibleOff = true
            } else if (type === POLICY_POLICY_TYPE_THREE) {
                this.visibleG = true
            }
        },
        /**
         * 查看--关闭
         */
        handleDetailClose() {
            this.visible = false
        },
        /**
         * 响应 table change 事件
         * @param pagination
         */
        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.current = current
            this.limit = pageSize
            this.getList()
        },
        /**
         * 搜索
         */
        handleSearch(params) {
            this.current = 1
            this.params = params
            this.getList()
            this.isResetSearch = false
        },
        /**
         * 刷新
         */
        handleRefresh(params) {
            this.handleSearch(params)
            this.isResetSearch = true
        },
        /**
         * 打开弹框
         */
        handleOpenModal(modalName, action) {
            this[modalName] = true
            this.currentRow = action ? action : null
        },
        /**
         * 保单-弹框
         */
        handleClickPolicyModel(record) {
            const { insuranceSingleUrl } = record
            if (insuranceSingleUrl) {
                window.open(insuranceSingleUrl)
            } else {
                this.viewType = 1
                this.viewVisible = true
            }
        },
        /**
         * 退保凭证-弹框 // TODO 参数
         */
        handleSurrenderCertificate(item) {
            const url = item.letterGuaranteeAddress
            if (url) {
                let type = this.formatterFileType(url)
                previewFiles(type, url)
            }
        },
        /**
         * 发票-弹框
         */
        handleClickInvoices(record) {
            console.log(record, 'record====')
            const {
                invoiceAddress
            } = record || {}
            // 发票
            if (invoiceAddress) {
                window.open(invoiceAddress)
            } else {
                this.viewType = 6
                this.viewVisible = true
            }
        },
        handleSuccess() {
            this.viewVisible = false
        },
        /**
         * 列表跳转详情
         */
        async jumpToCustomerDetail(item) {
            const { userInfo } = this
            const { agentId, customerId } = item
            if (!(item.customerId && item.hasAuthDetail)) return
            const userId = agentId || userInfo.id
            const result = await searchTeamUserService({ userId })

            //  SALES_CATEGORY_SELF_SUPPORT 自营/客户维护
            //  SALES_CATEGORY_DISTRIBUTION 分销/客户跟进
            // const { customerId, agentUserId } = item // TODO 需确认是取值
            if ( result && result.type === '分销') {
                this.$router.push({
                    name: 'DistributionCustomerDetail',
                    query: {
                        customerId,
                        agentUserId: userId,
                        businessType: SALES_CATEGORY_DISTRIBUTION
                    }
                })
            }
            if (result && result.type === '自营') {
                this.$router.push({
                    name: 'ProprietaryCustomerDetail',
                    query: {
                        customerId,
                        agentUserId: userId,
                        businessType: SALES_CATEGORY_SELF_SUPPORT
                    }
                })
            }
        },
        /**
         * 列表-查看-线上保单-关闭
         */
        handleDetailCloseOff() {
            this.visibleOff = false
        },
        /**
         * 列表-查看-线下保单-关闭
         */
        handleDetailCloseG() {
            this.visibleG = false
        }
    }
}
</script>
<style lang='scss'>
.action-button-list {
    display: flex;
    align-items: center;
}
.proprietary-customer-icon-img {
    width: 14px;
    height: 14px;
    margin-right: 6px;
}
.total-policy {
    line-height: 32px;
    color: #646566;
    font-weight: 500;
}
.submit-name {
    color: '#1890ff'
}
.default-user {
    color: #323233;
}
.link-user {
    color: #1890ff;
    cursor: pointer;
}
.policy-list-page {
    .tip-icon {
        color: #D40000;
        margin-right: 2px;
    }
}
</style>

