import store from '@store'
import router from './index'
import { WHITE_LIST, YUNFU_BUILD_TYPE, CNGBYF_URL } from '@config'
import { getToken } from '@utils/auth'
import {
    WORK_SPACE_MENU_CODE
} from '@constant/menu_code'

router.beforeEach(async (to, from, next) => {
    window.NProgress.start() // 进度条开始

    // 是否在免鉴权列表内
    // 是否已登录
    if (getToken()) {
        console.log('to.path:' + to.path)
        if (to.path === '/login') {
            next({ path: '/' })
            window.NProgress.done()
        } else {
            // 是否已获取到用户权限列表
            if (!store.getters.userInfo) {
                // 如果没有 则 进去权限获取请求
                try {
                    // dispatch 权限
                    await store.dispatch('getUserInfo')
                    // dispatah 路由
                    let routes = await store.dispatch('generateRoutes')
                    // 请求登录背景图和logo
                    if (store.getters.userInfo && store.getters.userInfo.id ) {
                        store.dispatch('getCorporationChannelInfo', { userId: store.getters.userInfo.id })
                    }

                    // 添加404
                    routes = [...routes, {
                        path: '*',
                        redirect: '/404',
                        hidden: true
                    }]
                    // 向路由表中 添加动态路由
                    router.addRoutes(routes)
                    // 跳转至目标 如果路由里有工作台且当前path是欢迎页，优先跳工作台，否则默认欢迎页
                    if (to.path === '/welcome/index') {
                        const defaultPath = routes && routes.length > 0 && routes[0].code === WORK_SPACE_MENU_CODE && routes[0].path || null
                        if (defaultPath) {
                            next({
                                path: defaultPath,
                                replace: true
                            })
                        } else {
                            next({
                                ...to,
                                replace: true
                            })
                        }
                    } else {
                        next({
                            ...to,
                            replace: true
                        })
                    }

                } catch (e) {
                    store.dispatch('logOut')
                    // notification.error({
                    //     message: '错误',
                    //     description: '请求用户信息失败，请重试'
                    // })
                    // setTimeout(()=>{
                    //
                    // },500)
                    throw new Error(e)
                }
            } else {
                // next()
                const routes = store.getters.addRouters
                if (to.path === '/welcome/index' && routes) {
                    const defaultPath = routes && routes.length > 0 && routes[0].code === WORK_SPACE_MENU_CODE && routes[0].path || null
                    if (defaultPath) {
                        next({
                            path: defaultPath,
                            replace: true
                        })
                    } else {
                        next()
                    }
                } else {
                    next()
                }
            }
        }
    } else {
        if (WHITE_LIST.indexOf(to.path) !== -1) {
            next()
        } else {
            // 云服
            if (process.env.VUE_APP_BUILD_TYPE === YUNFU_BUILD_TYPE) {
                window.open('/login', '_self')
            } else {
                // 跳转至登录页
                next(`/login?redirect=${to.path}`)
            }
            window.NProgress.done()
        }
    }

})

router.afterEach(() => {
    window.NProgress.done()
})
