<template>
    <a-dropdown class="app-header-avatar" placement="bottomRight">
        <span style="cursor: pointer;">
            <a-avatar class="avatar" size="small" shape="circle" :src="userInfo && userInfo.avatar" />
            <span>{{ userInfo && userInfo.name }}</span>
            <a-icon class="app-header-avatar__icon-down" type="down" />
        </span>
        <a-menu slot="overlay" style="width: 150px; float:right">
            <a-menu-item v-auth="USER_ACTION_UPDATE_PASSWORD_MENU_CODE" @click="handleChangePasswordClick">
                <a-icon type="setting" />
                <span>修改密码</span>
            </a-menu-item>
            <a-menu-divider />
            <a-menu-item @click="handleLogOutClick">
                <a-icon type="poweroff" />
                <span>退出登录</span>
            </a-menu-item>
        </a-menu>
    </a-dropdown>
</template>

<script>
import {
    Avatar as AAvatar,
    Dropdown as ADropdown,
    Menu as AMenu,
    Icon as AIcon
} from 'ant-design-vue'

// 常量
import { USER_ACTION_UPDATE_PASSWORD_MENU_CODE } from '@constant/menu_code'

export default {
    name: 'HeaderAvatar',
    components: {
        AAvatar,
        ADropdown,
        AIcon,
        AMenu,
        AMenuItem: AMenu.Item,
        AMenuDivider: AMenu.Divider
    },
    data() {
        return { USER_ACTION_UPDATE_PASSWORD_MENU_CODE }
    },
    computed: {
        userInfo() {
            return this.$store.getters.userInfo
        }
    },
    methods: {
        handleLogOutClick() {
            this.$store.dispatch('logOut')
        },
        handleChangePasswordClick() {
            this.$router.push({ name: 'UpdatePassword' })
        }
    }
}
</script>

<style lang="scss">
@import "~@assets/styles/varibles";

.app-header-avatar {
    display: inline-block;
    height: 100%;
    vertical-align: initial;

    .avatar {
        margin: 20px 4px 20px 0;
        color: #1890ff;
        vertical-align: middle;
        background: hsl(0deg 0% 100% / 85%);
    }

    &__icon-down {
        margin-left: $space-row-base;
    }
}

</style>
