import { BASE_URL } from '@config'
    
/** 管家-业务关联
 * ————————————————————————————————————————————————————————————————————————————————————————————*/
/**
 * 列表
 *
 */
export const GET_ASSOCIATION_LIST = {
    url: `${BASE_URL}/customer/assistantAssociated/select`,
    method: 'get',
    headers: {
        'content-type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
/**
 * 获取账号列表
 *
 */
export const GET_ASSOCIATION_ACCOUNT_LIST = {
    url: `${BASE_URL}/customer/assistantAssociated/getStewardList`,
    method: 'get',
    headers: {
        'content-type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
/**
 * 助理账号集合查询
 *
 */
export const GET_ASSOCIATION_ACCOUNT_SELECT = {
    url: `${BASE_URL}/customer/assistantAssociated/selectList`,
    method: 'get',
    headers: {
        'content-type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
/**
 * 添加
 *
 */
export const ASSOCIATION_ADD = {
    url: `${BASE_URL}/customer/assistantAssociated/save`,
    method: 'post',
    headers: {
        'content-type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
/**
 * 编辑
 *
 */
export const ASSOCIATION_EDIT = {
    url: `${BASE_URL}/customer/assistantAssociated/update`,
    method: 'put',
    headers: {
        'content-type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/**
 * 删除
 *
 */
export const ASSOCIATION_DELETE = {
    url: `${BASE_URL}/customer/assistantAssociated/remove`,
    method: 'delete',
    headers: {
        'content-type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}