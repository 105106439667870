export default {
    methods: {
        // 如果为空: /
        empty(value) {

            if (value === "" || value === null || value === undefined) {
                return value = "-"
            }
            return value
        }
    }
}
