<!-- 筛选 -->
<template>
    <div class="base-search-panel" :class="[expansion,layoutClassName]">
        <div class="base-search-panel__inner">
            <!-- 左侧主体 -->
            <div class="base-search-panel__body">
                <a-form-model
                    ref="form"
                    layout="horizontal"
                    :model="model"
                    :label-col="labelCol"
                    :wrapper-col="wrapperCol">
                    <div class="base-search-panel__main">
                        <a-row :gutter="[16, 16]">
                            <a-col>
                                <a-row :gutter="[16, 16]">
                                    <!-- 默认显示部分 -->
                                    <slot />
                                    <!-- 特殊处理 -->
                                    <slot name="special" />
                                    <!-- 展开部分 -->
                                    <div v-show="expansion">
                                        <slot v-if="$slots.expansion" name="expansion" />
                                    </div>
                                </a-row>
                            </a-col>
                        </a-row>
                        <!-- 操作 -->
                        <a-row :gutter="[16, 16]">
                            <a-col :offset="18" :span="6" class="base-search-panel__action">
                                <template v-if="$slots.expansionButton">
                                    <slot name="expansionButton" />
                                </template>
                                <custom-button class="base-search-panel__action-reset" text="重置" type="reset" @click="handleRefresh" />
                                <custom-button text="查询 " type="default" @click="handleSearch" />
                                <template v-if="$slots.expansion">
                                    <custom-button :text="expansionText" type="link" @click="handleExpansion">
                                        <template #rightExpansion>
                                            <a-icon class="base-search-panel__arrow-icon" :type="icon" />
                                        </template>
                                    </custom-button>
                                </template>
                            </a-col>
                        </a-row>
                    </div>
                </a-form-model>
            </div>
        </div>

        <slot name="tabs" />
    </div>
</template>

<script>
import {
    FormModel as AFormModel,
    Button as AButton,
    Icon as AIcon,
    Row as ARow,
    Col as ACol
} from 'ant-design-vue'
import CustomButton from '@components/Button'

export default {
    name: 'BaseSearchPanel',
    components: {
        AFormModel,
        AButton,
        AIcon,
        ARow,
        ACol,
        CustomButton
    },
    props: {
        layout: {
            type: String,
            default: 'horizontal'
        },
        model: {
            type: Object,
            default() {
                return {}
            }
        },
        isExpansion: {
            type: Boolean,
            default() {
                return false
            }
        }
    },
    data() {
        return {
            expansion: false,
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
        }
    },
    computed: {
        expansionText() {
            const { expansion } = this
            return expansion ? '收起筛选' : '更多筛选'
        },
        icon() {
            const { expansion } = this
            return expansion ? 'up' : 'down'
        },
        layoutClassName() {
            const { layout } = this
            return layout
        }
    },
    mounted() {
    },
    methods: {
        handleSearch() {
            this.$emit('search')
        },
        handleRefresh() {
            this.$refs.form.resetFields()
            this.$emit('refresh')
        },
        handleClearForm() {
            this.$refs.form.resetFields()
        },
        handleExpansion() {
            this.expansion = !this.expansion
        }
    }
}
</script>

<style lang="scss">
    @import '@assets/styles/varibles.scss';

    $search-panel-prefix: base-search-panel;
    .base-search-panel {
        position: relative;
        // padding: $space-col-lg;
        background: $component-background;
        border-radius: $border-radius-base;
        // box-shadow: $component-box-shadow;

        &.vertical {
            .#{$search-panel-prefix}__inner {
                display: block;
            }

            .#{$search-panel-prefix}__body {
                margin-right: 0;
            }

            .#{$search-panel-prefix}__action {
                text-align: right;
            }
        }

        &__inner {
            display: flex;
        }

        &__body {
            flex: 1;
            margin-right: $padding;

            .ant-form-item {
                margin-bottom: 0;
                .ant-form-item-label {
                    label {
                        color: $custom-button-default-text-color;
                        font-size: $font-size
                    }
                }

                .ant-calendar-picker {
                    width: 100% !important;
                }
            }
        }

        &__expansion {
            /*display: none;*/
            .ant-form-item {
                margin-top: $padding/2;
            }
        }


        &__main {
            .ant-row:not(:first-child) {
                margin-top: $space-sm;
            }

            .ant-select-selection__rendered {
                /*line-height: 40px;*/
            }
        }

        &__action {
            position: relative;

            .ant-btn:not(:last-child) {
                margin-right: $padding;
            }

            /* 重置 */
            &-reset {
                padding: 0;
            }

            /* 展开 */
            &-expansion {
                color: $base-color;
            }
            &-expansion:hover {
                color: $base-color;
            }
        }

        &__arrow-icon {
            transition: all .3s !important;
            width: 12px;
            height: 14px;
        }

        // 展开后样式
        &.expansion {
            .#{$search-panel-prefix}__arrow-icon {
                transform: rotate(180deg) translateY(2px);
            }

            .#{$search-panel-prefix}__expansion {
                display: block;
            }
        }
    }
</style>
