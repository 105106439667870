import {
    BASE_URL
} from '@config'

/** 保司
 * ————————————————————————————————————————————————————————————————————————————————————————————*/
/**
 * 保司列表
 *
 */
export const GET_INSURE_COMPANY_LIST = {
    url: `${BASE_URL}/product/insurance-enterprise/select`,
    method: 'get',
    headers: {
        'content-type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}