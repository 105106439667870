<!-- 客户备注Tab -->
<template>
    <div>
        <pro-table
            ref="tableRef"
            row-key="id"
            :scroll="{ x: '100%' }"
            :columns="columns"
            :request="getList"
        />
    </div>
</template>
<script>
import ProTable from '@components/ProTable'
import getCustomerAgentSelectService from '@service/customer/getCustomerAgentSelectService'
import { formatAddress } from '@utils/address'
import columns from './columns'

export default {
    name: 'CutomerRemark',
    components: { ProTable },
    data() {
        return { columns }
    },
    computed: {
        customerId() {
            return this.$route.query.id
        }
    },
    methods: {
        // 获取列表数据
        async getList(params) {
            const { customerId } = this
            const { pageSize, current, modifyDateTime = [], ...otherParams } = params
            const { total, records } = await getCustomerAgentSelectService({
                customerId,
                current,
                size: pageSize,
                type: '无',
                modifyStartDateTime: this.$formatDateTime(modifyDateTime[0]) || undefined,
                modifyEndDateTime: this.$formatDateTime(modifyDateTime[1]) || undefined,
                ...otherParams
            })
            const data = []
            for (let item of records) {
                const area = `${await formatAddress([item.provinceCode, item.cityCode, item.areaCode])}`
                data.push({
                    ...item,
                    area
                })
            }
            return {
                data,
                total
            }
        },
        // 用于外部刷新
        refresh() {
            this.$refs.tableRef?.handleSearch?.()
        }
    }
}
</script>
