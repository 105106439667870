<template>
    <a-row ref="selectDataRange">
        <a-col :span="4">
            <a-select v-model="selectValue" :placeholder="placeholder">
                <template v-for="(item,index) in dateSelect">
                    <a-select-option :key="index" :value="item.value">{{ item.label }}</a-select-option>
                </template>
            </a-select>
        </a-col>
        <a-col :span="20">
            <form-col
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
                :span="24"
                prop="selectDataRangeTime">
                <template slot="formElements">
                    <a-range-picker v-model="timeValue"
                                    :mode="submitTimeMode"
                                    :show-time="showTimeOptions"
                                    :disabled-date="disabledDate"
                                    v-bind="$attrs"
                                    @blur="onBlur"
                    />
                </template>
            </form-col>
        </a-col>
    </a-row>
</template>

<script>
import { DatePicker as ADatePicker, Select as ASelect, Row as ARow,
         Col as ACol } from 'ant-design-vue'
import moment from 'moment'
import FormCol from '@components/FormCol'

export default {
    name: 'SelectDataRange',
    components: {
        ARangePicker: ADatePicker.RangePicker,
        ASelect,
        ASelectOption: ASelect.Option,
        ARow,
        ACol,
        FormCol
    },
    props: {
        value: {
            type: Object,
            default() {
                return {
                    selectValue: 1,
                    timeValue: []
                }
            }
        },
        placeholder: {
            type: String,
            default() {
                return ''
            }
        },
        disabledDate: {
            type: Function,
            default: current => current && current > moment().endOf('day')
        },
        dateSelect: {
            type: Array,
            default: () => [
            ]
        },
        labelCol: {
            type: Object,
            default: () => {
                { span: 8 }
            }
        },
        wrapperCol: {
            type: Object,
            default: () => {
                { span: 24 }
            }
        },
        format: {
            type: String,
            default: 'YYYY-MM-DD'
        }
    },
    data() {
        return {
            submitTimeMode: ['date', 'date'],
            showTimeOptions: {
                defaultValue: [
                    moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')
                ]
            }
        }
    },
    computed: {
        timeValue: {
            get() {
                return this.value.timeValue || undefined
            },
            set(val) {
                this.$emit('input', {
                    selectValue: this.selectValue,
                    timeValue: val
                })
            }
        },
        selectValue: {
            get() {
                return this.value.selectValue || undefined
            },
            set(val) {
                this.$emit('input', {
                    selectValue: val,
                    timeValue: this.timeValue
                })
            }
        }
    },
    methods: {
        onBlur() {
            this.$emit('blur')
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
