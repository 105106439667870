import { BASE_URL } from '@config'

/** 保单管理
 * ————————————————————————————————————————————————————————————————————————————————————————————*/

/**
 * 线上保单-列表
 *
 */
export const GET_ONLINE_INSURANCE_LIST = {
    url: `${BASE_URL}/insurance/insurance-policy-integrating/insuranceManage`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 线上保单-统计
 *
 */
export const GET_ONLINE_INSURANCE_STATISTICS = {
    url: `${BASE_URL}/insurance/cast-insurance/onlineInsurance/statistics`,
    method: 'post',
    headers: { 'content-type': 'application/json' },
    timeout: 30000
}
