<!-- 客户详情-保全记录 -->
<template>
    <container-panel>
        <!-- 筛选 start -->
        <template #screen>
            <search-panel slot="header" @search="handleListSearch" @refresh="handleListRefresh" />
        </template>
        <!-- 筛选 end -->

        <!-- 表格 start -->
        <template #content>
            <a-table
                size="middle"
                :columns="columns"
                :data-source="data"
                :pagination="pagination"
                :loading="loading"
                row-key="id"
                :scroll="{ x: '100%' }"
                @change="handleTableChange"
            >
                <!-- 批单类型 -->
                <template slot="type" slot-scope="type">
                    {{ getApiEnumLabelFromList(CORRECT_SERVICE_TYPE_ENUM_LIST, type) }}
                </template>
                <!-- 状态 -->
                <template slot="state" slot-scope="state">
                    {{ getApiEnumLabelFromList(PRESERVATION_STATE_MENU_LIST, state) }}
                </template>
                <!-- 操作 -->
                <template slot="action" slot-scope="action">
                    <custom-button text="查看" type="link" @click="handleDetail(action)" />
                </template>
            </a-table>
        </template>
        <!-- 筛选 end -->

        <!-- 详情 start -->
        <preservation-record-detail
            :visible="visible"
            :id="id"
            @close="handleDetailClose"
        />
        <!-- 详情 end -->
    </container-panel>
</template>

<script>
    // 组件
    import CustomButton from '@components/Button'
    import PreservationRecordDetail from '@weights/Customer/Components/ComponentsDetail/Detail/PreservationRecordDetail'
    import SearchPanel from './SearchPanel'

    // 常量
    import { CORRECT_SERVICE_TYPE_ENUM_LIST, PRESERVATION_STATE_MENU_LIST } from '@/constant/enum'

    // mixins
    import common from '../mixins'

    // 接口请求
    import getCustomerInsuranceCorrectingListService from '@service/customer/getCustomerInsuranceCorrectingListService'

    export default {
        name: 'PreservationRecordTabPanel',
        components: {
            CustomButton,
            SearchPanel,
            PreservationRecordDetail
        },
        mixins: [common],
        props: {
            params: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
        data() {
            const columns = [
                {
                    title: '服务单号',
                    dataIndex: 'id',
                    key: 'id',
                    width: 200
                },
                {
                    title: '订单号',
                    dataIndex: 'castInsuranceId',
                    key: 'castInsuranceId',
                    width: 200
                },
                {
                    title: '保单号',
                    dataIndex: 'insuranceId',
                    key: 'insuranceId',
                    width: 200,
                    ellipsis: true,
                    customRender: (text) => {
                        return this.empty(text)
                    }
                },
                {
                    title: '提交时间',
                    dataIndex: 'createDateTime',
                    key: 'createDateTime',
                    width: 200,
                    customRender: (text) => {
                        return this.empty(text)
                    }
                },
                {
                    title: '批单类型',
                    dataIndex: 'type',
                    key: 'type',
                    width: 200,
                    scopedSlots: {
                        customRender: 'type'
                    }
                },
                {
                    title: '状态',
                    dataIndex: 'state',
                    width: 200,
                    scopedSlots: {
                        customRender: 'state'
                    }
                },
                {
                    title: '操作',
                    key: 'action',
                    fixed: 'right',
                    width: 60,
                    align: 'right',
                    scopedSlots: {
                        customRender: 'action'
                    }
                }
            ]

            return {
                CORRECT_SERVICE_TYPE_ENUM_LIST,
                PRESERVATION_STATE_MENU_LIST,
                columns,
                // 用户id
                userId: '',
                // 经纪人id
                crmAgentUserId: '',
                id: '',
                // 查询条件
                searchParams: {},
                // 数据
                data: [],
                // 总记录数量
                total: 0,
                // 当前索引
                current: 1,
                // 加载状态
                loading: false,
                // 详情-抽屉
                visible: false
            }
        },
        watch: {
            params: {
                handler(params) {
                    const { userId, crmAgentUserId} = params
                    if (!(userId && crmAgentUserId)) return

                    this.userId = userId
                    this.crmAgentUserId = crmAgentUserId

                    // 获取列表
                    this.getList()
                },
                immediate: true
            }
        },
        mounted() {
        },
        methods: {
            /**
             * 获取 列表
             */
            async getList() {
                try {
                    this.loading = true
                    const {
                        userId,
                        crmAgentUserId,
                        searchParams,
                        current,
                        limit
                    } = this
                    const { total, records } = await getCustomerInsuranceCorrectingListService({
                        userId,
                        crmAgentUserId,
                        current,
                        size: limit,
                        ...searchParams
                    })
                    this.total = total || 0
                    this.data = records || []
                } catch (e) {
                    this.$message.error(e.message)
                    this.$captureException(e)
                } finally {
                    this.loading = false
                }
            },
            /**
             * 查看
             */
            handleDetail(action) {
                const { id } = action
                this.id = id
                this.visible = true
            }
        }
    }
</script>
