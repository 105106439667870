<!-- 提交时间 区间范围选择 -->
<template>
    <div>
        <a-range-picker
            v-model="sectionTime"
            :mode="timePickerMode"
            :format="format"
            :disabled-date="disabledDate"
            v-bind="$attrs"
            @change="handleTimeChange"
            @blur="onBlur" />
    </div>
</template>

<script>

import { DatePicker as ADatePicker } from 'ant-design-vue'
import moment from 'moment'

export default {
    name: 'Index',
    components: { ARangePicker: ADatePicker.RangePicker },
    props: {
        value: {
            type: Array,
            default() {
                return []
            }
        },
        format: {
            type: String,
            default: 'YYYY-MM-DD'
        },
        disabledDate: {
            type: Function,
            default: current => current && current > moment().endOf('day')
        }
    },
    data() {
        return { timePickerMode: ['date', 'date'] }
    },
    computed: {
        sectionTime: {
            get() {
                return this.value || undefined
            },
            set(val) {
                this.$emit('input', val)
            }
        }

    },
    methods: {
        handleTimeChange(moment, stringTime) {
            // console.log(stringTime)['a','b']
            this.$emit('time', stringTime)
        },
        onBlur() {
            this.$emit('blur')
        }
    }
}
</script>

<style lang='scss'>

</style>
