<!-- 订单管理-列表-筛选 -->
<template>
    <search-panel
        :model="form"
        layout="vertical"
        class="order-manage-search-panel"
        @search="handleSearch"
        @refresh="handleRefresh"
    >
        <form-col label="提交时间" prop="variousDate">
            <template :slot="formColDefaultSlot">
                <a-select v-model="variousDate" placeholder="请选择">
                    <template v-for="item in variousDateList">
                        <a-select-option :key="item.value" :value="item.value">{{ item.label }}</a-select-option>
                    </template>
                </a-select>
            </template>
        </form-col>
        <form-col
            v-if="variousDate===SIGNING_DATE_THIS_CUSTOM"
            :span="12"
            label="请选择"
            prop="variousDateSelect"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
        >
            <template :slot="formColDefaultSlot">
                <a-range-picker
                    v-model="variousDateSelect"
                    :mode="variousDateSelectMode"
                    :show-time="showTimeOptions"
                />
            </template>
        </form-col>
        <form-col label=""
                  :label-col="labelCol1"
                  :wrapper-col="wrapperCol1"
                  prop="insuranceMerge">
            <template :slot="formColDefaultSlot">
                <a-row>
                    <!-- 投保人和被保人合在一起 -->
                    <a-col :span="8" style="text-align:right">
                        <a-select class="order-manage-search-panel__rule" v-model="selectValue">
                            <a-select-option v-for="item in selectList" :key="item.key" :value="item.key">
                                {{ item.value }}
                            </a-select-option>
                        </a-select>
                    </a-col>
                    <a-col :span="16">
                        <form-col
                            :label-col="labelCol1"
                            :wrapper-col="wrapperCol1"
                            :span="24"
                            prop="submitTime"
                            class="form-col-custom"
                        >
                            <template :slot="formColDefaultSlot">
                                <a-input v-model.trim="selectInputValue" :placeholder="selectListPlaceholder" />
                            </template>
                        </form-col>
                    </a-col>
                    <!-- 投保人和被保人合在一起 -->
<!--                    <a-col :span="6">-->
<!--                        <a-input-group compact>-->
<!--                            <a-select v-model="selectValue" style="width: 35%">-->
<!--                                <a-select-option v-for="item in selectList" :key="item.key" :value="item.key">-->
<!--                                    {{ item.value }}-->
<!--                                </a-select-option>-->
<!--                            </a-select>-->
<!--                            <a-input v-model.trim="selectInputValue" style="width: 65%" :placeholder="selectListPlaceholder" />-->
<!--                        </a-input-group>-->
<!--                    </a-col>-->
                </a-row>
            </template>
        </form-col>
        <form-col label="订单状态" prop="status">
            <template :slot="formColDefaultSlot">
                <a-select v-model="form.status" placeholder="请选择订单状态">
                    <a-select-option value>全部</a-select-option>
                    <template v-for="item in orderStatusList">
                        <a-select-option :key="item.value" :value="item.value">{{ item.label }}</a-select-option>
                    </template>
                </a-select>
            </template>
        </form-col>
        <form-col label="投保用户" prop="castUserMobile">
            <template :slot="formColDefaultSlot">
                <a-input v-model.trim="form.castUserMobile" placeholder="请输入投保用户" />
            </template>
        </form-col>
        <form-col label="投保单号" prop="castId">
            <template :slot="formColDefaultSlot">
                <a-input v-model.trim="form.castId" placeholder="请输入投保单号" />
            </template>
        </form-col>
        <form-col label="成交经纪人" prop="name">
            <template :slot="formColDefaultSlot">
                <a-select
                    v-model.trim="personalName"
                    show-search
                    placeholder="请输入关键字选择成员"
                    option-filter-prop="children"
                    :filter-option="false"
                    :allow-clear="true"
                    :show-arrow="false"
                    @change="handleNameChangePower($event,'personalLoading','personalSelectList','personalName','dealHousekeeperId')"
                    @search="getNameSelectPower($event,'personalLoading','personalSelectList','personalName','dealHousekeeperId')"
                >
                    <a-spin v-if="personalLoading" slot="notFoundContent" size="small" type="loading" />
                    <a-select-option v-for="item in personalSelectList" :key="item.id" :value="`${item.userName} （${item.userId}）`">
                        {{ item.userName || '-' }} （{{ item.userId }}）
                    </a-select-option>
                </a-select>
            </template>
        </form-col>
        <template :slot="slotValue">
            <form-col label="操作经纪人" prop="name">
                <template :slot="formColDefaultSlot">
                    <a-select
                        v-model.trim="operatePersonalName"
                        show-search
                        placeholder="请输入关键字选择成员"
                        option-filter-prop="children"
                        :filter-option="false"
                        :allow-clear="true"
                        :show-arrow="false"
                        @change="handleNameChangePower($event,'operatePersonalLoading','operatePersonalSelectList','operatePersonalName','agentUserId')"
                        @search="getNameSelectPower($event,'operatePersonalLoading','operatePersonalSelectList','operatePersonalName','agentUserId')"
                    >
                        <a-spin v-if="operatePersonalLoading" slot="notFoundContent" size="small" type="loading" />
                        <a-select-option v-for="item in operatePersonalSelectList" :key="item.id" :value="`${item.userName} （${item.userId}）`">
                            {{ item.userName || '-' }} （{{ item.userId }}）
                        </a-select-option>
                    </a-select>
                </template>
            </form-col>
            <form-col label="保险公司" prop="insuranceEnterpriseId">
                <template :slot="formColDefaultSlot">
                    <a-select v-model="form.insuranceEnterpriseId" placeholder="请选择保险公司">
                        <a-select-option value>全部</a-select-option>
                        <template v-for="item in insureCompanyList">
                            <a-select-option :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
                        </template>
                    </a-select>
                </template>
            </form-col>
        </template>
        <template :slot="searchExpansionSlot">
            <form-col label="险种" prop="dangerPlantedId">
                <template :slot="formColDefaultSlot">
                    <a-select v-model="form.dangerPlantedId" placeholder="请选择险种">
                        <a-select-option value>全部</a-select-option>
                        <template v-for="item in insureList">
                            <a-select-option :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
                        </template>
                    </a-select>
                </template>
            </form-col>
            <form-col label="项目名称" prop="projectName">
                <template :slot="formColDefaultSlot">
                    <a-input v-model.trim="form.projectName" placeholder="请输入项目名称" />
                </template>
            </form-col>
            <form-col label="产品名称" prop="spuName">
                <template :slot="formColDefaultSlot">
                    <a-input v-model.trim="form.spuName" placeholder="请输入产品名称" />
                </template>
            </form-col>
            <!-- <form-col label="投保人" prop="castName">
                <template :slot="formColDefaultSlot">
                    <a-input v-model.trim="form.castName" placeholder="请输入投保人" />
                </template>
            </form-col>
            <form-col label="被保人" prop="castInsuranceDestName">
                <template :slot="formColDefaultSlot">
                    <a-input v-model.trim="form.castInsuranceDestName" placeholder="请输入被保人" />
                </template>
            </form-col> -->
        </template>
    </search-panel>
</template>

<script>
import debounce from 'lodash/debounce'
// 组件
import {
    Select as ASelect,
    DatePicker as ADatePicker,
    Input as AInput,
    Col as ACol
} from 'ant-design-vue'
import SearchPanel from '@components/BaseSearchPanel'
import FormCol from '@components/FormCol'

// 全局数据
import {
    SIGNING_DATE_TYPE_ENUM_LIST,
    SIGNING_DATE_THIS_MONTH,
    SIGNING_DATE_THIS_CUSTOM,
    CUSTOMER_ORDER_ORDER_STATE_ENUM_LIST
} from '@constant/enum'

// 工具类
import { filterParams } from '@utils/search.js'
import moment from 'moment'

import getTime from '@mixins/getTime'
import searchCommon from '@mixins/searchCommon'

export default {
    name: 'OrderManageSearchPanel',
    components: {
        SearchPanel,
        FormCol,
        ASelect,
        AInput,
        ACol,
        AInputGroup: AInput.Group,
        ASelectOption: ASelect.Option,
        ARangePicker: ADatePicker.RangePicker
    },
    mixins: [getTime, searchCommon],
    data() {
        this.getNameSelectPower = debounce(this.getNameSelectPower, 500)
        return {
            SIGNING_DATE_THIS_CUSTOM,
            submitTimeMode: ['date', 'date'],
            form: {
                // 提交开始时间
                startTime: '',
                // 提交结束时间
                endTime: '',
                // 投保单号
                castId: '',
                // 订单状态
                status: '',
                // 成交经纪人
                dealHousekeeperId: '',
                // 操作经纪人
                agentUserId: '',
                // 保险公司
                insuranceEnterpriseId: '',
                // 险种
                dangerPlantedId: '',
                // 项目名称
                projectName: '',
                // 产品名称
                spuName: '',
                // 投保人
                castName: '',
                // 被保人
                castInsuranceDestName: ''
            },
            // 提交时间
            variousDate: SIGNING_DATE_THIS_MONTH,
            // 提交时间自定义
            variousDateSelect: [],
            // 提交时间
            variousDateSelectMode: ['date', 'date'],
            showTimeOptions: {
                defaultValue: [
                    moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')
                ]
            },
            variousDateList: SIGNING_DATE_TYPE_ENUM_LIST,
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
            labelCol1: { span: 0 },
            wrapperCol1: { span: 24 },
            // 成交经纪人姓名搜索数据
            personalLoading: false,
            personalSelectList: [],
            personalName: undefined,
            // 操作经纪人姓名搜索数据
            operatePersonalLoading: false,
            operatePersonalSelectList: [],
            operatePersonalName: undefined,
            // 险种list
            insureList: [],
            // 保险公司list
            insureCompanyList: [],
            // 订单状态list
            orderStatusList: CUSTOMER_ORDER_ORDER_STATE_ENUM_LIST,
            // 合并筛选
            selectList: [
                {
                    key: 'castName',
                    value: '投保人'
                }, {
                    key: 'castInsuranceDestName',
                    value: '被保人'
                }
            ],
            selectListPlaceholder: '请输入投保人',
            selectValue: 'castName',
            selectInputValue: '',
            // 筛选-插槽
            formColDefaultSlot: 'formElements',
            searchExpansionSlot: 'expansion',
            searchSpecialSlot: 'special'
        }
    },
    computed: {
        // 控制是否展开收起
        slotValue() {
            const { variousDate, searchExpansionSlot, searchSpecialSlot } = this
            return variousDate === SIGNING_DATE_THIS_CUSTOM ? searchExpansionSlot : searchSpecialSlot
        }
    },
    watch: {
        variousDate: {
            handler(val) {
                if (val !== SIGNING_DATE_THIS_CUSTOM) {
                    this.variousDateSelect = []
                    this.getFormTime()
                    this.handleSearch()
                } else {
                    this.getFormTime()
                }
            }
            // immediate: true
        },
        variousDateSelect: {
            handler(val) {
                this.variousDateSelect = val
                this.getFormTime()
            },
            immediate: true
        },
        selectValue: {
            handler(val) {
                this.form[val] = this.selectInputValue
                let filterList = this.selectList.filter(item => {
                    return item.key === val
                })
                let filterName = filterList && filterList.length && filterList[0].value
                this.selectListPlaceholder = `请输入${filterName}`
                this.selectInputValue = ''
                this.form.castName = ''
                this.form.castInsuranceDestName = ''
            },
            immediate: true
        },
        selectInputValue: {
            handler(val) {
                if (val) {
                    this.form[this.selectValue] = val
                } else {
                    this.selectInputValue = ''
                    this.form.castName = ''
                    this.form.castInsuranceDestName = ''
                }
            },
            immediate: true
        }
    },
    mounted() {
        this.init()
        this.getInsureList('insureList')
        this.getInsureCompanyList('insureCompanyList')
        this.getNameSelectPower()
    },
    methods: {
        moment,
        init() {
            const { form, variousDate } = this
            this.$emit('get-init', filterParams(form), variousDate)
        },
        /**
         * 响应搜索事件
         */
        handleSearch() {
            const { form } = this
            const { startTime, endTime } = form
            if (!startTime || !endTime) {
                this.$message.error('请选择自定义起止时间')
                return false
            }
            this.$emit('search', filterParams(form))
        },
        /**
         * 响应刷新事件
         */
        handleRefresh() {
            this.resetData()
            const { form } = this
            this.$emit('refresh', filterParams(form))
        },
        /**
         *
         * 刷新置空数据
         */
        resetData() {
            // 投保人被保人数据重置--start
            this.electListPlaceholder = '请输入投保人',
            this.selectValue = 'castName',
            this.selectInputValue = ''
            this.form.castName = ''
            this.form.castInsuranceDestName = ''
            // 投保人被保人数据重置--end
            // this.personalSelectList = [],
            this.personalName = undefined
            this.form.dealHousekeeperId = ''
            // this.operatePersonalSelectList = []
            this.operatePersonalName = undefined
            this.form.agentUserId = ''
            this.variousDate = SIGNING_DATE_THIS_MONTH
            this.variousDateSelect = []
            this.getFormTime()
            this.getNameSelectPower()
        },
        getFormTime() {
            const { variousDate, variousDateSelect } = this
            let { startDate, endDate } = this.getTime(variousDate, variousDateSelect, 'startDate', 'endDate') || {}
            this.form.startTime = startDate
            this.form.endTime = endDate
        }
    }
}
</script>

<style lang="scss">
.order-manage-search-panel {
    .select-compact {
        position: relative;
        top: 4px;
    }

    & &__rule {
        width: 82px;
    }

    .form-col-custom {
        padding: 0 !important;
    }
}
</style>
