import axios from '@service'
import qs from 'qs'
import {GET_OAUTH_TOKEN} from './api'
import {HTTP_ERR_SUCCESS, HTTP_RESULT_FIELD, HTTP_ERR_MESSAGE_FIELD} from '@/constant/api_status'
import {ERROR_DEFAULT_HTTP_REQUEST_FAIL} from "@/constant/error";
import {LOGIN_IP,APP_CODE} from '@config'
/**
 * 用户名 密码方式登录
 * @returns {Promise<*>}
 */
export default async function loginByUserNameService(payload = {}, config = {}) {
    const {username, password} = payload
    let params = {
        userName: username,
        password: password,
        // appCode: APP_CODE,
        // ip: LOGIN_IP
    }

    const param = {...GET_OAUTH_TOKEN, ...config, data: qs.stringify(params)}
    const {data} = await axios(param)
    if (data.code === HTTP_ERR_SUCCESS) {
        return data[HTTP_RESULT_FIELD]
    } else {
        throw new Error(data[HTTP_ERR_MESSAGE_FIELD] || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}