import { OSS_CALLBACK_URL, OSS_ACCESS_KEY_SECRET } from '@config'
// import getOssTokenService from '@service/getOssTokenService'

import getOssStsTokenService from '@/service/getOssStsTokenService'
import getOssAddressService from '@/service/getOssAddressService'


// import Client from '@utils/alioss'
import randomString from '@utils/randomString'
import fileType from '@/mixins/fileType'
const OSS = require('ali-oss')

export default {
    props: [
        'directory',
        'data',
        'disabled',
        'headers',
        'multiple',
        'name',
        'payload',
        'accept',
        'type',
        'fileList',
        'size',
        'autoUpload',
        'handleBeforeUpload',
        'ossDirectory'
    ],
    mixins: [
        fileType
    ],
    data() {
        return {
            // oss相关参数
            region: 'oss-cn-hangzhou',
            bucket: 'gongbaojinji',
            url: '',
            host: ''
        }
    },
    computed: {
        props() {
            const { $props } = this
            const _props = { ...$props }
            if (!_props.fileList) {
                delete _props.fileList
            }

            const props = {
                customRequest: this.uploadFile,
                beforeUpload: this.beforeUpload,
                ..._props
            }
            return props
        },
        listeners() {
            return this.$listeners
        }
    },
    methods: {
        /**
         * 上传
         */
        async uploadFile({ onSuccess, onError, file }) {
            // 1. 获取oss client对象
            await this.getAliToken()
            // const client = Client(this.dataObj)
            const { accessKeyId, accessKeySecret, stsToken } = this.dataObj
            const client = new OSS({
                // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
                region: this.region,
                // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
                accessKeyId,
                accessKeySecret,
                // 从STS服务获取的安全令牌（SecurityToken）。
                stsToken,
                secure: true,
                refreshSTSToken: async () => {
                // 向您搭建的STS服务获取临时访问凭证。
                    const { ossDirectory } = this
                    let params = {
                        directory: ossDirectory,
                        url: OSS_CALLBACK_URL,
                        roleSessionName: this.$store.getters.userInfo && this.$store.getters.userInfo.id || 'acquiesce'
                    }
                    let result = await getOssStsTokenService(params)

                    const { accessKeyId, accessKeySecret, securityToken } = result
                    // console.log('new result:' + JSON.stringify(result))
                    return {
                        accessKeyId,
                        accessKeySecret,
                        stsToken: securityToken
                    }
                },
                // 刷新临时访问凭证的时间间隔，单位为毫秒。
                refreshSTSTokenInterval: 300000,
                // 填写Bucket名称。
                bucket: this.bucket
            })

            const { ossDirectory, host } = this
            // 2. 文件随机命名
            const randomName = ossDirectory + randomString(10) + '.' + file.name.split('.').pop()
            // 3. 上传
            try {
                let result = await client.put(randomName, file)
                // const { url } = result
                const url = `${host}/${randomName}`
                // 4. 回调赋值url
                onSuccess(url)
            } catch (e) {
                onError()
                this.$message.error(e.message)
            }
        },
        /**
         * 获取oss token
         */
        async getAliToken() {
            const { ossDirectory } = this
            let params = {
                directory: ossDirectory,
                url: OSS_CALLBACK_URL,
                roleSessionName: this.$store.getters.userInfo && this.$store.getters.userInfo.id || 'acquiesce'
            }
            let result = await getOssStsTokenService(params)

            const { accessKeyId, accessKeySecret, securityToken } = result
            this.dataObj = {
                region: this.region,
                bucket: this.bucket,
                accessKeyId,
                accessKeySecret,
                stsToken: securityToken
            }
            let ossHost = await getOssAddressService()
            this.host = ossHost
        },
        async beforeUpload(file) {
            const {
                size,
                autoUpload,
                handleBeforeUpload,
                accept
            } = this
            if (handleBeforeUpload && handleBeforeUpload(file) === false) {
                return Promise.reject()
            }

            // 限制-文件大小
            if (size) {
                const isLt = file.size < size
                if (!isLt) {
                    this.$emit('validate', {
                        type: 'FILE_LARGE',
                        size
                    })
                    return Promise.reject()
                }
            }

            // 限制-文件类型
            if (accept) {
                // 文件类型：pdf
                if (accept === 'application/pdf') {
                    if (accept !== file.type) {
                        this.$emit('validate', { type: 'FILE_TYPE' })
                        return Promise.reject()
                    }
                }
                // 限制文件类型图片
                if (accept === 'image/jpeg,image/png,image/jpg') {
                    let type = this.formatterFileType(file.name)
                    let imgArr = ['png', 'jpeg', 'jpg']
                    let result = imgArr.every(item => type !== item)
                    if (result) {
                        this.$emit('validate', { type: 'FILE_TYPE_IMAGE' })
                        return Promise.reject()
                    }
                }
                // 文件类型校验
                let fileArrCustom = accept.split(',')
                let typeCustom = this.formatterFileType(file.name)
                let result = fileArrCustom.every(item => item.indexOf(typeCustom) === -1)
                console.log(result)
                if (result) {
                    this.$emit('validate', { type: 'FILE_TYPE_CUSTOM' })
                    return Promise.reject()
                }
            }

            if (autoUpload === false) {
                return Promise.reject()
            }
            return Promise.resolve()
        }
    }
}
