<template>
    <div class="page-header">
        <!-- 面包屑 -->
        <div v-if="breadcrumb" class="page-header__breadcrumb">
            <a-breadcrumb>
                <a-breadcrumb-item>
                    <router-link to="/">首页</router-link>
                </a-breadcrumb-item>
                <template v-for="item in breadcrumb"
                >
                    <a-breadcrumb-item v-if="showBreadcrumbItem(item)"
                                       :key="item.path"
                    >
                        <template>
                            <router-link :to="item.path">{{ item.meta.title }}</router-link>
                        </template>
                    </a-breadcrumb-item>
                </template>
            </a-breadcrumb>
        </div>
        <!-- 标题 -->
        <!-- 判断是否有title部分插槽 -->
        <template v-if="hasTitle && $slots.action">
            <div class="page-header__title-box">
                <div v-if="hasTitle">
                    <slot v-if="$slots.title" name="title" />
                    <h2 v-else class="page-header__title">{{ title }}</h2>
                </div>
                <!-- 操作 -->
                <div v-if="$slots.action" class="page-header__action">
                    <slot name="action" />
                </div>
            </div>
        </template>
        <div v-if="$slots.default" class="page-header__main">
            <!-- 主体内容 -->
            <div class="page-header__content">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
import { Breadcrumb as ABreadcrumb } from 'ant-design-vue'

export default {
    name: 'PageHeader',
    components: {
        ABreadcrumb,
        ABreadcrumbItem: ABreadcrumb.Item
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        breadcrumb: {
            type: Array,
            default: null
        }
    },
    data() {
        return { hasTitle: true }
    },
    methods: {
        showBreadcrumbItem(item) {
            const { meta = {} } = item
            return meta.breadcrumb !== false && item.meta.title
        }
    }
}
</script>

<style lang="scss">
@import "@assets/styles/varibles";

.page-header {
    padding: 10px $padding;
    background: $custom-table-title-background-color;
    border-bottom: 1px solid #e8e8e8;

    &__title-box,
    &__main {
        margin-top: $padding;
    }

    &__title-box {
        line-height: 1;

        &::after {
            display: table;
            clear: both;
            content: "";
        }
    }

    &__title {
        float: left;
        margin: 0;
        font-size: $font-size*2;
    }

    &__action {
        float: right;
        margin-left: $padding;
    }
}
</style>
