<!-- 管家列表 -->
<template>
    <list-page-layout>
        <!-- search -->
        <search-panel slot="header" @search="handleSearch" @refresh="handleRefresh" />
        <container-panel>
            <!-- 标题 start -->
            <template slot="title">管家列表</template>
            <!-- 标题 end -->

            <!-- 表格 start -->
            <template slot="content" slot-scope>
                <a-table
                    size="middle"
                    :columns="columns"
                    :data-source="data"
                    :pagination="false"
                    :loading="loading"
                    row-key="userName"
                    :scroll="scrollConfig">
                    <!-- 表格的序号递增 -->
                    <template slot="sort" slot-scope="text, record, index">{{ index + 1 }}</template>
                </a-table>
            </template>
            <!-- 表格 end -->
        </container-panel>
    </list-page-layout>
</template>

<script>
// 组件
import {
    Table as ATable
} from 'ant-design-vue'
import ListPageLayout from '@layout/ListPageLayout'
import ContainerPanel from '@components/ContainerPanel'
import SearchPanel from './SearchPanel'

// 全局数据
import { scrollConfig } from '@constant/Const'

// mixins
import enumOperate from '@mixins/enumOperate'
import tableShowTotal from '@mixins/tableShowTotal'

// 接口请求
import getStewardListService from '@service/steward/getStewardListService'

const columns = [
    {
        title: '序号',
        dataIndex: 'sort',
        width: 100,
        key: 'sort',
        align: 'center',
        scopedSlots: { customRender: 'sort' }
    },
    {
        title: '添加时间',
        align: 'center',
        width: 300,
        dataIndex: 'createDate',
        key: 'createDate'
    },
    {
        title: '管家类型',
        dataIndex: 'stewards',
        align: 'center',
        width: 300,
        key: 'stewards'
    },
    {
        title: '姓名',
        align: 'center',
        width: 200,
        dataIndex: 'realName',
        key: 'realName'
    },
    {
        title: '手机号',
        dataIndex: 'phone',
        width: 125,
        align: 'center',
        key: 'phone'
    }
]

export default {
    name: 'ClientClue',
    components: {
        ListPageLayout,
        SearchPanel,
        ContainerPanel,
        ATable
    },
    mixins: [enumOperate, tableShowTotal],
    data() {
        return {
            // 查询条件
            params: {},
            // 数据
            data: [],
            // 加载状态
            loading: false,
            // table 列配置
            columns,
            scrollConfig
        }
    },
    mounted() {
    // 获取列表
        this.getList()
    },
    methods: {
    /**
     * 获取 列表
     */
        async getList() {
            try {
                this.loading = true
                const { params } = this
                this.data = await getStewardListService({ ...params })
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.loading = false
            }
        },
        /**
     * 响应搜索事件
     */
        handleSearch(params) {
            this.current = 1
            this.params = params
            this.getList()
        },
        /**
     * 响应刷新事件
     */
        handleRefresh(params) {
            this.handleSearch(params)
        }
    }
}
</script>

<style lang="scss">
</style>
