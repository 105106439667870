/**
 * 设置行属性
 * @returns {*}
 */
export function customRow() {
    return {
        // 这个style就是我自定义的属性，也就是官方文档中的props
        style: {
            // 行背景色
            minHeight: '56px'
            // 'background-color': '#000000'
        }
    }
}

/**
 * 设置头部行属性
 * @returns {*}
 */
export function customHeaderRow() {
    return {
        // 这个style就是我自定义的属性，也就是官方文档中的props
        style: {
            // 行背景色
            minHeight: '48px'
        }
    }
}

/**
 * 设置头部操作文字属性
 * @returns {*}
 */
export function customActionTitle(h, width, text = '操作') {
    return h('div', {
        style: {
            minWidth: width + 'px',
            textAlign: 'left'
            // padding: '0 16px',
        }
    }, [text])
}


