import { BASE_URL } from '@config'

/** 工作台-保单详情
 * ————————————————————————————————————————————————————————————————————————————————————————————*/
/**

/**
 * 工作台-保单详情-统计查询
 *
 */
 export const GET_POLICY_MANAGE_COUNT = {
    url: `${BASE_URL}/insurance/cast-insurance/getInsuranceStatisticsResults`,
    method: 'post',
    headers: { 'content-type': 'application/json' },
    timeout: 30000
}

/**
 * 工作台-保单详情-团队权限经纪人列表查询
 *
 */
export const GET_TEAM_AUTH_BROKER_LIST = {
    url: `${BASE_URL}/user/team-user/teamAuthBrokerSelectList`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 工作台-保单详情-列表
 *
 */
export const GET_POLICY_LIST = {
    url: `${BASE_URL}/insurance/insurance-policy-integrating/onlineInsuranceList`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}
