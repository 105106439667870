<!-- 客户详情--弹框 -->
<template>
    <drawer-panel
        title="投保用户详情"
        :visible="visible"
        :loading="loading"
        width="90%"
        @after-visible-change="afterVisibleChange"
        @close="handleClose"
    >
        <template #content>
            <!-- 头部 start -->
            <base-header :detail="detail" :business-type="businessType" :loading="loading" />
            <!-- 头部 end -->

            <!-- tabs start -->
            <tabs-panel
                :tabs-data="tabsData"
                :default-active-key="defaultActiveKey"
                @handle-tabs-change="handleTabsChange"
            />
            <!-- tabs end -->

            <component :is="defaultActiveKey" :params="tabData" @refresh-head="getHead" />
        </template>
    </drawer-panel>
</template>

<script>
// 组件
import DrawerPanel from '@weights/Customer/Components/ComponentsDetail/DrawerPanel'
import DetailPanel from '@views/Customer/Components/DetailPanel'
import TabsPanel from '@components/TabsPanel'
import BaseHeader from '@weights/Customer/Components/ComponentsDetail/BaseHeader'

// 常量
import { SALES_CATEGORY_DISTRIBUTION } from '@/constant/enum'

// 接口请求
import getCustomerSelectOneCustomerInfoHeadService from '@service/customer/getCustomerSelectOneCustomerInfoHeadService'

export default {
    name: 'CustomerDrawer',
    components: {
        DrawerPanel,
        DetailPanel,
        TabsPanel,
        BaseHeader,
        // 客户概况
        CustomerProfileTabPanel: () => import('@weights/Customer/Components/ComponentsDetail/Tabs/CustomerProfileTabPanel'),
        // 跟进记录
        FollowRecordTabPanel: () => import('@weights/Customer/Components/ComponentsDetail/Tabs/FollowRecordTabPanel'),
        // 线索记录
        LeadRecordTabPanel: () => import('@weights/Customer/Components/ComponentsDetail/Tabs/LeadRecordTabPanel'),
        // 订单记录
        OrderRecordTabPanel: () => import('@weights/Customer/Components/ComponentsDetail/Tabs/OrderRecordTabPanel'),
        // 保单记录
        PolicyRecordTabPanel: () => import('@weights/Customer/Components/ComponentsDetail/Tabs/PolicyRecordTabPanel'),
        // 发票记录
        InvoiceRecordTabPanel: () => import('@weights/Customer/Components/ComponentsDetail/Tabs/InvoiceRecordTabPanel'),
        // 保全记录
        PreservationRecordTabPanel: () => import('@weights/Customer/Components/ComponentsDetail/Tabs/PreservationRecordTabPanel')
    },
    props: {
        visible: {
            type: Boolean,
            default() {
                return false
            }
        },
        params: {
            type: Object,
            default: () => {

            }
        }
    },
    data() {
        return {
            SALES_CATEGORY_DISTRIBUTION,
            // 客户id
            customerId: '',
            // 经纪人id
            agentUserId: '',
            detail: {},
            tabsData: [
                {
                    key: 'CustomerProfileTabPanel',
                    tab: '客户概况'
                },
                {
                    key: 'FollowRecordTabPanel',
                    tab: '跟进记录'
                },
                {
                    key: 'LeadRecordTabPanel',
                    tab: '线索记录'
                },
                {
                    key: 'OrderRecordTabPanel',
                    tab: '订单记录'
                },
                {
                    key: 'PolicyRecordTabPanel',
                    tab: '保单记录'
                },
                {
                    key: 'InvoiceRecordTabPanel',
                    tab: '发票记录'
                },
                {
                    key: 'PreservationRecordTabPanel',
                    tab: '保全记录'
                }
            ],
            defaultActiveKey: 'CustomerProfileTabPanel',
            tabData: {},
            // 业务类型
            businessType: '',
            loading: false
        }
    },
    mounted() {

    },
    methods: {
        /**
         * 初始化
         */
        async init() {
            const { businessType } = this

            // 获取 头部
            await this.getHead()

            // tabs处理
            this.tabsData[0].tab = businessType === SALES_CATEGORY_DISTRIBUTION ? '客户备注' : '客户概况'
            this.handleTabsChange(this.defaultActiveKey)
        },
        /**
         * 获取 头部
         */
        async getHead() {
            try {
                this.loading = true
                const {
                    customerId,
                    agentUserId
                } = this
                const params = {
                    customerId,
                    agentUserId
                }
                const result = await getCustomerSelectOneCustomerInfoHeadService(params)
                this.detail = result
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        },
        /**
         * tab-change
         */
        handleTabsChange(key) {
            this.defaultActiveKey = key
            const { customerId, businessType, detail } = this
            const {
                userId,
                associatedAgentUserId,
                allotAgentUserId
            } = detail
            const agentUserId = businessType === SALES_CATEGORY_DISTRIBUTION ? associatedAgentUserId : allotAgentUserId

            let tabData = null

            if (key === 'CustomerProfileTabPanel') {
                // 客户概况
                tabData = {
                    customerId,
                    agentUserId
                }
            } else if (key === 'FollowRecordTabPanel') {
                // 跟进记录
                tabData = {
                    customerId,
                    userId: agentUserId
                }
            } else if (key === 'LeadRecordTabPanel') {
                // 线索记录
                tabData = {
                    customerId,
                    agentUserIdQuery: agentUserId
                }
            } else if (key === 'OrderRecordTabPanel') {
                // 订单记录
                tabData = {
                    agentUserId,
                    castInsuranceUserId: userId
                }
            } else if (key === 'PolicyRecordTabPanel') {
                // 保单记录
                tabData = {
                    userId,
                    crmAgentUserId: agentUserId
                }
            } else if (key === 'InvoiceRecordTabPanel') {
                // 发票记录
                tabData = {
                    agentUserId,
                    castInsuranceUserId: userId
                }
            } else if (key === 'PreservationRecordTabPanel') {
                // 保全记录
                tabData = {
                    userId,
                    crmAgentUserId: agentUserId
                }
            }

            this.tabData = tabData
        },
        /**
         * 初始化
         */
        afterVisibleChange(visible) {
            if (visible) {
                const { customerId, agentUserId, businessType } = this.params || {}
                if (!( customerId && agentUserId)) return
                this.customerId = customerId
                this.agentUserId = agentUserId
                this.businessType = Number(businessType)
                this.init()
            } else {
                this.record = {}
            }
        },
        /**
         * 抽屉-关闭
         */
        handleClose() {
            this.$emit('close')
        }
    }
}
</script>

<style lang="scss" scoped>
.ant-drawer-body {
    .ant-spin-nested-loading {
        height: auto ;

        .ant-spin-container {
            height: auto;
        }
    }
}
</style>
