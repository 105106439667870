<!-- 客户详情-线索记录-筛选 -->
<template>
    <search-panel :model="form"  layout="vertical" @search="handleSearch" @refresh="handleRefresh">
        <form-col label="类型" prop="sourceType">
            <template :slot="formColDefaultSlot">
                <a-select v-model="form.sourceType" placeholder="请选择">
                    <a-select-option value>全部</a-select-option>
                    <template v-for="item in CUSTOMER_LEAD_SOURCE_TYPE_ENUM_LIST">
                        <a-select-option :key="item.value" :value="item.value">{{ item.label }}</a-select-option>
                    </template>
                </a-select>
            </template>
        </form-col>
        <form-col label="订单状态" prop="stateQuery">
            <template :slot="formColDefaultSlot">
                <a-select v-model="form.stateQuery" placeholder="请选择">
                    <a-select-option value>全部</a-select-option>
                    <template v-for="item in CUSTOMER_LEAD_ORDER_STATE_ENUM_LIST">
                        <a-select-option :key="item.value" :value="item.value">{{ item.label }}</a-select-option>
                    </template>
                </a-select>
            </template>
        </form-col>
        <form-col label="联系手机" prop="mobile">
            <template :slot="formColDefaultSlot">
                <a-input v-model="form.mobile" placeholder="请输入" />
            </template>
        </form-col>
        <form-col label="咨询人" prop="nameQuery">
            <template :slot="formColDefaultSlot">
                <a-input v-model="form.nameQuery" placeholder="请输入" />
            </template>
        </form-col>
        <form-col label="服务单号" prop="id">
            <template :slot="formColDefaultSlot">
                <a-input v-model="form.id" placeholder="请输入" />
            </template>
        </form-col>
        <form-col label="企业名称" prop="enterpriseNameQuery">
            <template :slot="formColDefaultSlot">
                <a-input v-model="form.enterpriseNameQuery" placeholder="请输入" />
            </template>
        </form-col>
        <form-col label="地区" prop="area">
            <template :slot="formColDefaultSlot">
                <a-cascader v-model="form.code"
                            :options="areaData"
                            placeholder="请选择"
                            change-on-select
                            @change="handleChangeArea" />
            </template>
        </form-col>
        <form-col label="经纪人" prop="name">
            <template :slot="formColDefaultSlot">
                <a-select
                    v-model.trim="personalName"
                    show-search
                    placeholder="请输入关键字选择成员"
                    option-filter-prop="children"
                    :filter-option="false"
                    :allow-clear="true"
                    :show-arrow="false"
                    @change="handleNameChangePower($event,'personalLoading','personalSelectList','personalName','agentUserIdQuery')"
                    @search="getNameSelectPower($event,'personalLoading','personalSelectList','personalName','agentUserIdQuery')"
                >
                    <a-spin v-if="personalLoading" slot="notFoundContent" size="small" type="loading" />
                    <a-select-option v-for="item in personalSelectList" :key="item.id" :value="`${item.userName} （${item.userId}）`">
                        {{ item.userName || '-' }} （{{ item.userId }}）
                    </a-select-option>
                </a-select>
            </template>
        </form-col>
        <template :slot="searchExpansionSlot">
            <form-col
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
                :span="12"
                label="提交时间"
                prop="submitTime">
                <template :slot="formColDefaultSlot">
                    <a-range-picker v-model="form.submitTime"
                                    :mode="submitTimeMode"
                                    :show-time="showTimeOptions"
                    />
                </template>
            </form-col>
        </template>
    </search-panel>
</template>

<script>
import debounce from 'lodash/debounce'

// 常量
import { CUSTOMER_LEAD_ORDER_STATE_ENUM_LIST, CUSTOMER_LEAD_SOURCE_TYPE_ENUM_LIST } from '@/constant/enum'

// 组件
import {
    Select as ASelect,
    DatePicker as ADatePicker,
    Input as AInput
} from 'ant-design-vue'
import SearchPanel from '@components/BaseSearchPanel'
import FormCol from '@components/FormCol'

// 工具类
import { filterParams } from '@utils/search.js'
import moment from 'moment'

// mixins
import selectArea from '@mixins/selectArea'
// import common from '@weights/Customer/Components/ComponentsDetail/Tabs/mixins'
import searchCommon from '@mixins/searchCommon'

export default {
    name: 'LeadRecordSearchPanel',
    components: {
        SearchPanel,
        FormCol,
        ASelect,
        AInput,
        ASelectOption: ASelect.Option,
        ARangePicker: ADatePicker.RangePicker
    },
    mixins: [selectArea, searchCommon],
    props: {
        echoData: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        this.getNameSelectPower = debounce(this.getNameSelectPower, 500)

        return {
            CUSTOMER_LEAD_ORDER_STATE_ENUM_LIST,
            CUSTOMER_LEAD_SOURCE_TYPE_ENUM_LIST,
            submitTimeMode: ['date', 'date'],
            form: {
                // 类型
                sourceType: '',
                // 服务单号
                id: '',
                // 订单状态
                stateQuery: '',
                // 联系手机
                mobileQuery: '',
                // 咨询人
                nameQuery: '',
                // 经纪人
                agentUserIdQuery: '',
                // 企业名称
                enterpriseNameQuery: '',
                // 地区
                code: [],
                // 省市区参数
                provinceCode: '',
                cityCode: '',
                areaCode: '',
                // 提交时间
                submitTime: []
            },
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
            // 成交经纪人姓名搜索数据
            personalLoading: false,
            personalSelectList: [],
            personalName: undefined,
            // 筛选-插槽
            formColDefaultSlot: 'formElements',
            searchExpansionSlot: 'expansion',
            showTimeOptions: {
                defaultValue: [
                    moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')
                ]
            }

        }
    },
    watch: {
        echoData: {
            handler(val) {
            },
            immediate: true
        },
        'form.code': {
            handler(value) {
                const [provinceCode, cityCode, areaCode] = value
                this.form.provinceCode = provinceCode || ''
                this.form.cityCode = cityCode || ''
                this.form.areaCode = areaCode || ''
            },
            immediate: true
        }
    },
    mounted() {
        this.getNameSelectPower()
    },
    methods: {
        /**
         * 格式化参数
         */
        formatParams() {
            const { form } = this
            let { submitTime, code, ...params } = form
            let [startTime, endTime] = submitTime || []
            startTime = startTime ? `${startTime.format('YYYY-MM-DD HH:mm:ss')}` : ''
            endTime = endTime ? `${endTime.format('YYYY-MM-DD HH:mm:ss')}` : ''
            params.startTime = startTime
            params.endTime = endTime
            return { ...params }
        },

        /**
         * 响应搜索事件
         */
        handleSearch() {
            const params = this.formatParams()
            this.$emit('search', filterParams(params))
        },

        /**
         * 响应刷新事件
         */
        handleRefresh() {
            this.resetData()
            const { form } = this
            this.$emit('refresh', filterParams(form))
        },

        /**
         *
         * 刷新置空数据
         */
        resetData() {
            // 投保人被保人数据重置--start
            this.personalName = undefined
            this.form.agentUserIdQuery = ''
            this.getNameSelectPower()
        }
    }
}
</script>

<style lang="scss">
</style>
