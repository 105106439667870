<!-- 企业认证操作--详情/编辑/添加共用表单 -->
<template>
    <page-layout class="agent-management-operation">
        <page-panel>
            <span>{{ title }}</span>
            <span class="agent-management-operation__cross" @click="handleGoList">X</span>
        </page-panel>
        <steps-container>
            <a-steps :current="stepCurrent" label-placement="vertical">
                <a-step :title="firstState"  />
                <a-step :title="secondState"  />
                <a-step :title="thirdState"  />
            </a-steps>
        </steps-container>
        <container-panel>
            <template slot="content">
                <div v-if="mode !== 'add'" class="agent-management-operation__edit">
                    <h1 class="agent-management-operation__state">当前状态：{{ type + currentState }}</h1>
                    <template v-if="!isEdit">
                        <a-space v-if="!isEdit" :size="20">
                            <custom-button
                                v-if="currentState !== '' && currentState !== ENTERPRISE_AUTHORIZATION_WAIT_AUTHORIZATION_TEXT && currentState !== ENTERPRISE_AUTHORIZATION_PASS_TEXT"
                                v-auth="ENTERPRISE_AUTHORIZATION_EDIT_BUTTON"
                                text="编辑"
                                type="default"
                                @click="handleEditAgent"
                            />
                            <!-- <a-button
                            v-if="currentState === '待提交' || currentState === '已撤销'"
                            v-auth="ENTERPRISE_AUTHORIZATION_EDIT_BUTTON"
                            type="link"
                            @click="handleEditAgent"
                        >
                            编辑
                        </a-button> -->
                            <custom-button
                                v-if="currentState === ENTERPRISE_AUTHORIZATION_WAIT_SUBBMIT_TEXT || currentState === ENTERPRISE_AUTHORIZATION_CANCEL_TEXT"
                                v-auth="ENTERPRISE_AUTHORIZATION_SUBMIT_BUTTON"
                                text="提交审批"
                                type="default"
                                :loading="editLoading"
                                @click="handleAuthSubmit"
                            />
                            <custom-button
                                v-if="currentState !== '' && currentState !== ENTERPRISE_AUTHORIZATION_WAIT_SUBBMIT_TEXT && currentState !== ENTERPRISE_AUTHORIZATION_CANCEL_TEXT && currentState !== ENTERPRISE_AUTHORIZATION_WAIT_AUTHORIZATION_TEXT && currentState !== ENTERPRISE_AUTHORIZATION_PASS_TEXT"
                                v-auth="ENTERPRISE_AUTHORIZATION_SUBMIT_BUTTON"
                                text="重新提交"
                                type="default"
                                :loading="editLoading"
                                @click="handleAuthSubmitAgain"
                            />
                        </a-space>

                        <custom-button
                            v-if="currentState === ENTERPRISE_AUTHORIZATION_WAIT_AUTHORIZATION_TEXT"
                            v-auth="ENTERPRISE_AUTHORIZATION_CANCEL_BUTTON"
                            text="撤销审批"
                            type="default"
                            :loading="editLoading"
                            @click="handleAuthCancel"
                        />
                    </template>
                </div>
                <a-spin :spinning="pageLoading">
                    <a-form-model
                        ref="ruleForm"
                        layout="horizontal"
                        :label-col="{ span: 7 }"
                        :wrapper-col="{ span: 17 }"
                        :model="form"
                        :rules="rules"
                    >
                        <descriptions-with-head title="基本信息">
                            <a-row>
                                <a-col :span="colSpan">
                                    <a-form-model-item label="企业名称" prop="enterprisName">
                                        <a-input
                                            v-model.trim="form.enterprisName"
                                            type="text"
                                            placeholder="请输入企业名称"
                                            :max-length="50"
                                            :disabled="isFormEdit"
                                        />
                                    </a-form-model-item>
                                </a-col>
                                <a-col :span="colSpan">
                                    <a-form-model-item label="统一信用代码" prop="creditCode">
                                        <a-input
                                            v-model.trim="form.creditCode"
                                            type="text"
                                            placeholder="请输入统一信用代码"
                                            :max-length="50"
                                            :disabled="isFormEdit"
                                        />
                                    </a-form-model-item>
                                </a-col>
                                <a-col :span="colSpan">
                                    <a-form-model-item label="注册地址" prop="regAddress">
                                        <a-input
                                            v-model.trim="form.regAddress"
                                            type="text"
                                            placeholder="请输入注册地址"
                                            :max-length="500"
                                            :disabled="isFormEdit"
                                        />
                                    </a-form-model-item>
                                </a-col>
                                <a-col :span="colSpan">
                                    <a-form-model-item label="法定代表人" prop="legalPerson">
                                        <a-input
                                            v-model.trim="form.legalPerson"
                                            type="text"
                                            placeholder="请输入法定代表人"
                                            :max-length="50"
                                            :disabled="isFormEdit"
                                        />
                                    </a-form-model-item>
                                </a-col>
                                <!-- 扩展性：如果是身份证需要校验身份证符合规则 -->
                                <a-col :span="colSpan">
                                    <a-form-model-item label="法定代表人身份证号" prop="farenCode">
                                        <a-input
                                            v-model.trim="form.farenCode"
                                            type="text"
                                            placeholder="请输入法定代表人身份证号"
                                            :max-length="32"
                                            :disabled="isFormEdit"
                                        />
                                    </a-form-model-item>
                                </a-col>
                                <a-col :span="colSpan">
                                    <a-form-model-item label="联系人" prop="contacts">
                                        <a-input
                                            v-model.trim="form.contacts"
                                            type="text"
                                            placeholder="请输入联系人"
                                            :max-length="50"
                                            :disabled="isFormEdit"
                                        />
                                    </a-form-model-item>
                                </a-col>
                                <a-col :span="colSpan">
                                    <a-form-model-item label="联系电话" prop="mobile">
                                        <a-input
                                            v-model.trim="form.mobile"
                                            type="text"
                                            placeholder="请输入联系电话"
                                            :max-length="50"
                                            :disabled="isFormEdit"
                                        />
                                    </a-form-model-item>
                                </a-col>
                            </a-row>
                        </descriptions-with-head>

                        <descriptions-with-head title="附件">
                            <a-row>
                                <a-col :span="colSpan">
                                    <a-form-model-item label="营业执照" prop="businessLicenseInfo">
                                        <upload-file
                                            :prohibit="isFormEdit"
                                            :accept="fileAccept"
                                            :oss-directory="pictureFileDirectory"
                                            :file-list="businessLicenseInfoFileList"
                                            :size="1024 * 1024 * 2"
                                            text="上传文件"
                                            :preview-in-open="false"
                                            @change="handleEnterpriseAuthorizationBusinessLicenseInfoChange"
                                            @validate="handleFileValidate"
                                        >
                                            <!-- <div slot="uploadConditions">{{ fileAcceptText }}</div> -->
                                        </upload-file>
                                    </a-form-model-item>
                                </a-col>
                                <a-col :span="colSpan">
                                    <a-form-model-item label="法人身份证正面" prop="corporateIdCardInfoFront">
                                        <upload-file
                                            :prohibit="isFormEdit"
                                            :accept="fileAccept"
                                            :oss-directory="pictureFileDirectory"
                                            :file-list="corporateIdCardInfoFrontFileList"
                                            :size="1024 * 1024 * 2"
                                            text="上传文件"
                                            @change="handleEnterpriseAuthorizationCorporateIdCardInfoFrontChange"
                                            @validate="handleFileValidate"
                                        >
                                            <!-- <div slot="uploadConditions">{{ fileAcceptText }}</div> -->
                                        </upload-file>
                                    </a-form-model-item>
                                </a-col>
                                <a-col :span="colSpan">
                                    <a-form-model-item label="法人身份证反面" prop="corporateIdCardInfoSide">
                                        <upload-file
                                            :prohibit="isFormEdit"
                                            :accept="fileAccept"
                                            :oss-directory="pictureFileDirectory"
                                            :file-list="corporateIdCardInfoSideFileList"
                                            :size="1024 * 1024 * 2"
                                            text="上传文件"
                                            @change="handleEnterpriseAuthorizationCorporateIdCardInfoSideChange"
                                            @validate="handleFileValidate"
                                        >
                                            <!-- <div slot="uploadConditions">{{ fileAcceptText }}</div> -->
                                        </upload-file>
                                    </a-form-model-item>
                                </a-col>
                                <a-col :span="colSpan">
                                    <a-form-model-item label="资质证书" prop="qualifeCertInfo">
                                        <upload-file
                                            :prohibit="isFormEdit"
                                            :accept="fileAccept"
                                            :oss-directory="pictureFileDirectory"
                                            :file-list="qualifeCertInfoFileList"
                                            :size="1024 * 1024 * 2"
                                            text="上传文件"
                                            @change="handleEnterpriseAuthorizationQualifeCertInfoChange"
                                            @validate="handleFileValidate"
                                        >
                                            <!-- <div slot="uploadConditions">{{ fileAcceptText }}</div> -->
                                        </upload-file>
                                    </a-form-model-item>
                                </a-col>
                                <a-col :span="colSpan">
                                    <a-form-model-item label="近2年财务报表" prop="financeReport">
                                        <upload-file
                                            :prohibit="isFormEdit"
                                            :accept="fileAccept"
                                            :oss-directory="pictureFileDirectory"
                                            :file-list="financeReportFileList"
                                            :size="1024 * 1024 * 2"
                                            text="上传文件"
                                            @change="handleEnterpriseAuthorizationFinanceReportChange"
                                            @validate="handleFileValidate"
                                        >
                                            <!-- <div slot="uploadConditions">{{ fileAcceptText }}</div> -->
                                        </upload-file>
                                    </a-form-model-item>
                                </a-col>
                                <a-col :span="colSpan">
                                    <a-form-model-item label="近2年审计报告" prop="financialAuditReportInfo">
                                        <upload-file
                                            :prohibit="isFormEdit"
                                            :accept="fileAccept"
                                            :oss-directory="pictureFileDirectory"
                                            :file-list="financialAuditReportInfoFileList"
                                            :size="1024 * 1024 * 2"
                                            text="上传文件"
                                            @change="handleEnterpriseAuthorizationFinancialAuditReportInfoChange"
                                            @validate="handleFileValidate"
                                        >
                                            <!-- <div slot="uploadConditions">{{ fileAcceptText }}</div> -->
                                        </upload-file>
                                    </a-form-model-item>
                                </a-col>
                                <a-col :span="colSpan">
                                    <a-form-model-item label="开户许可证" prop="permit">
                                        <upload-file
                                            :prohibit="isFormEdit"
                                            :accept="fileAccept"
                                            :oss-directory="pictureFileDirectory"
                                            :file-list="permitFileList"
                                            :size="1024 * 1024 * 2"
                                            text="上传文件"
                                            @change="handleEnterpriseAuthorizationPermitChange"
                                            @validate="handleFileValidate"
                                        >
                                            <!-- <div slot="uploadConditions">{{ fileAcceptText }}</div> -->
                                        </upload-file>
                                    </a-form-model-item>
                                </a-col>
                                <a-col :span="colSpan">
                                    <a-form-model-item label="安全许可证" prop="safetyPermit">
                                        <upload-file
                                            :prohibit="isFormEdit"
                                            :accept="fileAccept"
                                            :oss-directory="pictureFileDirectory"
                                            :file-list="safetyPermitFileList"
                                            :size="1024 * 1024 * 2"
                                            text="上传文件"
                                            @change="handleEnterpriseAuthorizationSafetyPermitChange"
                                            @validate="handleFileValidate"
                                        >
                                            <!-- <div slot="uploadConditions">{{ fileAcceptText }}</div> -->
                                        </upload-file>
                                    </a-form-model-item>
                                </a-col>
                            </a-row>
                        </descriptions-with-head>

                        <descriptions-with-head title="授权信息">
                            <a-row>
                                <a-col :span="colSpan">
                                    <a-form-model-item label="授权经纪人" prop="userName">
                                        <a-input
                                            v-model.trim="form.userName"
                                            type="text"
                                            placeholder="请输入授权经纪人"
                                            :max-length="100"
                                            disabled
                                        />
                                    </a-form-model-item>
                                </a-col>
                                <a-col :span="colSpan">
                                    <a-form-model-item label="授权生效日期" prop="startDateTime">
                                        <a-date-picker
                                            v-model="form.startDateTime"
                                            format="YYYY-MM-DD"
                                            placeholder="请选择授权生效日期"
                                            :disabled="isFormEdit"
                                            :disabled-date="disabledAuthorizationDateStart"
                                        />
                                    </a-form-model-item>
                                </a-col>
                                <a-col :span="colSpan">
                                    <a-form-model-item label="授权到期日期" prop="endDateTime">
                                        <a-date-picker
                                            v-model="form.endDateTime"
                                            format="YYYY-MM-DD"
                                            placeholder="请选择授权到期日期"
                                            :disabled="isFormEdit"
                                            :disabled-date="disabledAuthorizationDateEnd"
                                        />
                                    </a-form-model-item>
                                </a-col>
                                <a-col :span="colSpan">
                                    <a-form-model-item label="授权委托书" prop="authReport">
                                        <upload-file
                                            :prohibit="isFormEdit"
                                            :accept="fileAccept"
                                            :oss-directory="pictureFileDirectory"
                                            :file-list="authReportFileList"
                                            :size="1024 * 1024 * 2"
                                            text="上传文件"
                                            @change="handleEnterpriseAuthorizationAuthReportChange"
                                            @validate="handleFileValidate"
                                        >
                                            <!-- <div slot="uploadConditions">{{ fileAcceptText }}</div> -->
                                        </upload-file>
                                        <div><a-button @click="handleDownloadTemplate">授权书模板下载</a-button></div>
                                    </a-form-model-item>
                                </a-col>
                            </a-row>
                        </descriptions-with-head>
                        <descriptions-with-head v-if="currentState === ENTERPRISE_AUTHORIZATION_PASS_TEXT && total > 0" title="历史授权记录">
                            <a-table
                                :columns="columns"
                                :data-source="tableData"
                                :pagination="pagination"
                                :loading="loading"
                                row-key="id"
                                @change="handleTableChange"
                            >
                                <template slot="action" slot-scope="text, record">
                                    <a
                                        @click="handlePreview(record.authReport ,formatterFileType(record.authReport))">
                                        授权书（点击支持预览/下载）
                                    </a>
                                </template>
                            </a-table>
                        </descriptions-with-head>
                    </a-form-model>
                </a-spin>
                <div class="agent-management-operation__bottom-button">
                    <!-- 新增按钮 -->
                    <a-space v-if="mode === 'add'" :size="20">
                        <custom-button
                            :text="okText"
                            type="primary"
                            :loading="addLoading"
                            @click="handleAddSave(ENTERPRISE_AUTHORIZATION_WAIT_AUTHORIZATION_TEXT)"
                        />
                        <custom-button
                            :text="saveText"
                            type="primary"
                            :loading="addLoading"
                            @click="handleAddSave(ENTERPRISE_AUTHORIZATION_WAIT_SUBBMIT_TEXT)"
                        />
                        <custom-button
                            :text="cancelText"
                            type="default"
                            :loading="addLoading"
                            @click="handleGoList"
                        />
                    </a-space>
                    <!-- 编辑按钮 -->
                    <a-space v-if="isEdit" :size="20">
                        <custom-button
                            :text="okText"
                            type="primary"
                            :loading="editLoading"
                            @click="handleEditSave(ENTERPRISE_AUTHORIZATION_WAIT_AUTHORIZATION_TEXT)"
                        />
                        <custom-button
                            :text="saveText"
                            type="primary"
                            :loading="addLoading"
                            @click="handleEditSave(ENTERPRISE_AUTHORIZATION_WAIT_SUBBMIT_TEXT)"
                        />
                        <custom-button
                            :text="cancelText"
                            type="default"
                            :loading="editLoading"
                            @click="handleCancelEdit"
                        />
                    </a-space>
                    <!-- 详情按钮 -->
                    <a-space v-if="mode !== 'add' && !isEdit" :size="20">
                        <custom-button
                            text="返回"
                            type="default"
                            @click="handleGoList"
                        />
                    </a-space>
                </div>
            </template>
        </container-panel>
    </page-layout>
</template>
<script>
// 组件
import {
    FormModel as AFormModel,
    Input as AInput,
    Col as ACol,
    Row as ARow,
    Button as AButton,
    Space as ASpace,
    Select as ASelect,
    Radio as ARadio,
    DatePicker as ADatePicker,
    Spin as ASpin,
    Steps as ASteps,
    Table as ATable
} from 'ant-design-vue'
import ContainerPanel from '@components/ContainerPanel'
import PageLayout from '@layout/PageLayout'
import PagePanel from '@components/PagePanel'
import DescriptionsWithHead from '@components/DescriptionsWithHead'
import CustomButton from '@components/Button'
// import UploadFile from '@weights/AppUpload/uploadNew.vue'
import UploadFile from '@weights/AppUpload/upload.vue'
import { DEFAULT_PAGES_SIZE } from '@config'

import StepsContainer from '@components/StepsContainer'

// 权限
import {
    ENTERPRISE_AUTHORIZATION_EDIT_BUTTON,
    ENTERPRISE_AUTHORIZATION_CANCEL_BUTTON,
    ENTERPRISE_AUTHORIZATION_SUBMIT_BUTTON
} from '@constant/authEnum/enterprise'
// 工具类
import { validateSocialCreditCode, validateIDCard } from '@/utils/validate.js'
import moment from 'moment'


// mixins
import selectArea from '@mixins/selectArea'

// utils
// import { getBirthdayByIdNO, getSexByIdNO } from '@utils/getInfoByIdCard.js'
import { filterParams } from '@utils/search.js'

// 枚举
import {
    ENTERPRISE_AUTHORIZATION_LIST,
    SEX_ENUM_LIST,
    JOB_STATUS_LIST,
    CONTRACT_EMPLOY_TYPE_LIST,
    CERTIFICATE_TYPE_LIST,
    CERTIFICATE_TYPE_ID_CARD,
    NATURE_OF_WORK_LIST,
    FILE_UPLOAD_ENTERPRISE_AUTHORIZATION,
    ENTERPRISE_AUTHORIZATION_WAIT_SUBBMIT_TEXT,
    ENTERPRISE_AUTHORIZATION_WAIT_AUTHORIZATION_TEXT,
    ENTERPRISE_AUTHORIZATION_PASS_TEXT,
    ENTERPRISE_AUTHORIZATION_UNPASS_TEXT,
    ENTERPRISE_AUTHORIZATION_CANCEL_TEXT,
    CERTIFICATE_TYPE_ID_CARD_TEXT,
    JOB_STATUS_LEAVE_JOB_TEXT
} from '@constant/enum'
import { HTTP_ERR_MESSAGE_STATE_FIELD } from '@/constant/api_status'

// 工具类
import { previewFiles } from '@/utils/previewFiles'

import { gotoPage } from '@utils/dom'

// 接口请求
// import getAgentManagementSelectOneService from '@service/agent/agentManagement/getAgentManagementSelectOneService'
// import agentManagementSaveOneService from '@service/agent/agentManagement/agentManagementSaveOneService'
// import agentManagementUpdateService from '@service/agent/agentManagement/agentManagementUpdateService'
import addEnterpriseAuthorizationService from '@service/enterprise/addEnterpriseAuthorizationService'
import getEnterpriseAuthorizationDetailService from '@service/enterprise/getEnterpriseAuthorizationDetailService'
import editEnterpriseAuthorizationService from '@service/enterprise/editEnterpriseAuthorizationService'
import getEnterpriseAuthorizationHistoryListService from '@service/enterprise/getEnterpriseAuthorizationHistoryListService'



const columns = [
    {
        title: '申请时间',
        dataIndex: 'modifyDateTime',
        key: 'modifyDateTime'
    },
    {
        title: '授权经纪人',
        dataIndex: 'userName',
        key: 'userName'
    },
    {
        title: '授权生效日期',
        dataIndex: 'startDateTime',
        key: 'startDateTime'
    },
    {
        title: '授权到期日期',
        dataIndex: 'endDateTime',
        key: 'endDateTime'
    },
    {
        title: '授权书',
        key: 'action',
        scopedSlots: { customRender: 'action' }
    }
]

export default {
    name: 'EnterpriseAuthorizationOperationPage',
    components: {
        AFormModel,
        AFormModelItem: AFormModel.Item,
        AInput,
        AButton,
        ACol,
        ARow,
        ASpace,
        ASelect,
        ASelectOption: ASelect.Option,
        ARadio,
        ARadioGroup: ARadio.Group,
        ADatePicker,
        ASpin,
        ASteps,
        AStep: ASteps.Step,
        PageLayout,
        PagePanel,
        ContainerPanel,
        DescriptionsWithHead,
        CustomButton,
        UploadFile,
        StepsContainer,
        ATable
    },
    mixins: [selectArea],
    props: {
        title: {
            type: String,
            default() {
                return ''
            }
        },
        mode: {
            type: String,
            default() {
                return ''
            }
        }
    },
    data() {
        // 证件有效期
        let idCardCheckTime = (rule, value, callback) => {
            if (value.certificateEndDate && !value.certificateStartDate) {
                callback(
                    new Error(
                        '请选择证件有效起期'
                    )
                )
            } else if (value.certificateStartDate && !value.certificateEndDate) {
                callback(
                    new Error(
                        '请选择证件有效止期'
                    )
                )
            } else {
                callback()
            }
        }
        return {
            ENTERPRISE_AUTHORIZATION_EDIT_BUTTON, // 编辑权限code
            ENTERPRISE_AUTHORIZATION_CANCEL_BUTTON,
            ENTERPRISE_AUTHORIZATION_SUBMIT_BUTTON,
            ENTERPRISE_AUTHORIZATION_WAIT_SUBBMIT_TEXT,
            ENTERPRISE_AUTHORIZATION_WAIT_AUTHORIZATION_TEXT,
            ENTERPRISE_AUTHORIZATION_PASS_TEXT,
            ENTERPRISE_AUTHORIZATION_UNPASS_TEXT,
            ENTERPRISE_AUTHORIZATION_CANCEL_TEXT,
            CERTIFICATE_TYPE_ID_CARD,
            // 页面加载状态
            pageLoading: false,
            // 页面id
            pageId: '',
            // 每行显示数据
            colSpan: 12,
            // 是否编辑
            isEdit: false,
            isRenewCer: false,
            // 授权文件预览
            authReportFileList: [],
            // 营业执照预览
            businessLicenseInfoFileList: [],
            // 法人身份证正面预览
            corporateIdCardInfoFrontFileList: [],
            // 法人身份证反面预览
            corporateIdCardInfoSideFileList: [],
            // 资质证书预览
            qualifeCertInfoFileList: [],
            // 财务报表预览
            financeReportFileList: [],
            // 审计报告预览
            financialAuditReportInfoFileList: [],
            // 开户许可证预览
            permitFileList: [],
            // 安全许可证预览
            safetyPermitFileList: [],
            // 证件类型数据
            cardTypeList: CERTIFICATE_TYPE_LIST,
            // 性别筛选数据
            sexList: SEX_ENUM_LIST,
            // 在职状态筛选数据
            jobStatusList: JOB_STATUS_LIST,
            // 工作类型筛选数据
            contractTypeList: CONTRACT_EMPLOY_TYPE_LIST,
            // 工作性质筛选数据
            jobNatureList: NATURE_OF_WORK_LIST,
            // 图片-阿里云oss目录
            pictureFileDirectory: FILE_UPLOAD_ENTERPRISE_AUTHORIZATION,
            // 合同文件预览
            contractFileList: [],
            // 电子执业证预览
            electronicLicenseFileList: [],
            // 文件类型
            fileAccept: 'image/jpeg,image/png,image/jpg,image/JPG,image/PNG,image/JPEG,.doc,.docx,.zip,.rar,.xls,.xlsx,application/pdf',
            fileAcceptText: '支持doc、docx、zip、jpeg、jpg、png、rar、xls、xlsx、pdf，文件大小不超过 2M',
            // 文件回显
            fileIndex: 1,
            // 添加按钮加载装填
            addLoading: false,
            // 编辑按钮加载状态
            editLoading: false,
            // 经纪人是否认证
            isCertifiedBroker: false,
            // 长期按钮点击值
            radiochange: '',
            // 当前状态
            currentState: '',
            // 当前阶段
            stepCurrent: -1,
            enterpriseAuthorizationStatusList: ENTERPRISE_AUTHORIZATION_LIST,
            firstState: '资料提交',
            secondState: '认证审核',
            thirdState: '认证完成',
            enterpriseAuthorizationId: '',
            type: '',
            columns,
            tableData: [],
            // 分页数量
            limit: DEFAULT_PAGES_SIZE,
            // 总记录数量
            total: 0,
            // 当前索引
            current: 1,
            loading: false,
            // 表单数据
            form: {
                // 基本信息
                // 企业名称
                enterprisName: '',
                // 统一信用代码
                creditCode: '',
                // 注册地址
                regAddress: '',
                // 法定代表人
                legalPerson: '',
                // 法定代表人身份证号
                farenCode: '',
                // 联系人
                contacts: '',
                // 联系电话
                mobile: '',
                // 授权经纪人
                userName: '',
                // 授权生效日期
                startDateTime: null,
                // 授权到期日期
                endDateTime: null,
                // 授权委托书
                authReport: '',
                // 营业执照
                businessLicenseInfo: '',
                // 法人身份证正面
                corporateIdCardInfoFront: '',
                // 法人身份证反面
                corporateIdCardInfoSide: '',
                // 资质证书
                qualifeCertInfo: '',
                // 财务报表
                financeReport: '',
                // 审计报告
                financialAuditReportInfo: '',
                // 开户许可证
                permit: '',
                // 安全许可证
                safetyPermit: ''
                // enterpriseAuthorizationBook: {
                //     // 授权生效日期
                //     startDateTime: null,
                //     // 授权到期日期
                //     endDateTime: null,
                //     // 授权委托书
                //     authReport: ''
                // },
                // // 手机号码
                // mobile: '',
                // 用户ID
                // userId: '',
                // // 证件类型
                // certificateType: undefined,
                // // 证件号码(产品说目前只支持身份证)
                // idCard: '',
                // // 证件有效期
                // validity: {
                //     certificateStartDate: null,
                //     certificateEndDate: null
                // },
                // // 证件永久有效
                // certificatePermanent: null,
                // // 出生日期
                // birthday: null,
                // // 性别
                // sex: undefined,
                // // 所在省市区
                // addressAreaCode: [],
                // // 详细地址
                // address: '',
                // // 所属银行
                // bank: '',
                // // 银行卡
                // bankCardNumber: '',
                // // 银行卡开户姓名
                // openAccountName: '',
                // // 银行卡开户地址
                // openAccountAddress: '',

                // // 组织架构信息
                // // 所属机构
                // institutionsId: undefined,
                // // 推荐人
                // referees: '',
                // // 在职状态
                // state: undefined,
                // // 入职时间
                // onboardingDateTime: null,
                // // 离职日期
                // leaveDateTime: null,

                // // 合同信息
                // // 用工合同类型
                // contractType: undefined,
                // // 工作性质
                // natureWork: undefined,
                // // 合同文件
                // contractFile: '',

                // // 经纪人信息
                // // 执业证号
                // certificateCode: '',
                // // 执业证发证日期
                // issueCertificatesDate: null,
                // // 电子执业证
                // certificateFileAddress: ''
            },
            // 校验规则
            rules: {
                enterprisName: [
                    {
                        required: true,
                        message: '请输入企业名称',
                        trigger: 'blur'
                    }
                ],
                creditCode: [
                    {
                        required: true,
                        message: '请输入统一信用代码',
                        trigger: 'blur'
                    }, {
                        validator: validateSocialCreditCode,
                        trigger: 'blur'
                    }
                ],
                regAddress: [
                    {
                        required: true,
                        message: '请输入注册地址',
                        trigger: 'blur'
                    }
                ],
                legalPerson: [
                    {
                        required: true,
                        message: '请输入法定代表人',
                        trigger: 'blur'
                    }
                ],
                farenCode: [
                    {
                        required: true,
                        message: '请输入法定代表人身份证号',
                        trigger: 'blur'
                    }, {
                        validator: validateIDCard,
                        trigger: 'blur'
                    }
                ],
                userName: [
                    {
                        required: true,
                        message: '请输入授权经纪人',
                        trigger: 'blur'
                    }
                ],
                startDateTime: [
                    {
                        required: true,
                        message: '请选择授权生效日期',
                        trigger: 'change'
                    }
                ],
                endDateTime: [
                    {
                        required: true,
                        message: '请选择授权到期日期',
                        trigger: 'change'
                    }
                ],
                authReport: [
                    {
                        required: true,
                        message: '请上传授权委托书',
                        trigger: 'change'
                    }
                ],
                businessLicenseInfo: [
                    {
                        required: true,
                        message: '请上传营业执照',
                        trigger: 'change'
                    }
                ],
                corporateIdCardInfoFront: [
                    {
                        required: true,
                        message: '请上传法人身份证正面',
                        trigger: 'change'
                    }
                ],
                corporateIdCardInfoSide: [
                    {
                        required: true,
                        message: '请上传法人身份证反面',
                        trigger: 'change'
                    }
                ],
                qualifeCertInfo: [
                    {
                        required: true,
                        message: '请上传资质证书',
                        trigger: 'change'
                    }
                ]
                // enterpriseAuthorizationBook: [
                //     {
                //         required: false,
                //         trigger: 'blur'
                //     }, {
                //         validator: idAuthorizationCheck,
                //         trigger: 'blur'
                //     }
                // ],
                // mobile: [
                //     {
                //         required: true,
                //         message: '请输入手机号',
                //         trigger: 'blur'
                //     }, {
                //         validator: validatePhoneNumber,
                //         trigger: 'blur'
                //     }
                // ],
                // certificateType: [
                //     {
                //         required: true,
                //         message: '请选择证件类型',
                //         trigger: 'change'
                //     }
                // ],
                // idCard: [
                //     {
                //         required: true,
                //         message: '请输入证件号码',
                //         trigger: 'blur'
                //     }
                // ],
                // validity: [
                //     {
                //         required: false,
                //         trigger: 'blur'
                //     }, {
                //         validator: idCardCheckTime,
                //         trigger: 'blur'
                //     }
                // ],
                // birthday: [
                //     {
                //         required: false,
                //         trigger: 'blur'
                //     }
                // ],
                // sex: [
                //     {
                //         required: false,
                //         trigger: 'blur'
                //     }
                // ],
                // institutionsId: [
                //     {
                //         required: true,
                //         message: '请选择所属机构',
                //         trigger: 'change'
                //     }
                // ],
                // state: [
                //     {
                //         required: true,
                //         message: '请选择在职状态',
                //         trigger: 'change'
                //     }
                // ],
                // onboardingDateTime: [
                //     {
                //         required: true,
                //         message: '请选择入职时间',
                //         trigger: 'change'
                //     }
                // ],
                // contractType: [
                //     {
                //         required: true,
                //         message: '请选择用工合同类型',
                //         trigger: 'change'
                //     }
                // ],
                // natureWork: [
                //     {
                //         required: true,
                //         message: '请选择工作性质',
                //         trigger: 'change'
                //     }
                // ],
                // leaveDateTime: [
                //     { required: false }
                // ]
            }
        }
    },
    computed: {
        pagination() {
            const { total, limit, current } = this
            return {
                showTotal: (total, range) => `第${range[0]}-${range[1]}条/总共${total}条`,
                current,
                total,
                pageSize: limit,
                showSizeChanger: true,
                showQuickJumper: true
            }
        },
        cancelText() {
            const { mode, isEdit } = this
            let getCancelText = mode === 'add' || isEdit ? '取消' : '返回'
            return getCancelText
        },

        okText() {
            const { isEdit } = this
            return isEdit ? '提交审核' : '提交审核'
        },

        saveText() {
            const { isEdit } = this
            return isEdit ? '保存' : '保存'
        },
        /**
         * 表单是否可填写
         */
        isFormEdit() {
            const { mode, isEdit } = this
            return mode === 'add' || isEdit ? false : true
        },
        // /**
        //  * 证件类型  目前只支持选择身份证
        //  */
        // cardTypeListSelect() {
        //     const { cardTypeList } = this
        //     let cardTypeListSelectArr = cardTypeList.filter(item => item.value === CERTIFICATE_TYPE_ID_CARD)
        //     return cardTypeListSelectArr
        // },
        /**
         * 长期按钮是否选中
         */
        radioValue() {
            let radioSet = null
            const { form } = this
            const { form: { certificatePermanent } } = this
            const { validity: { certificateStartDate, certificateEndDate } } = form
            if (certificatePermanent) {
                radioSet = 1
            }
            if (certificateStartDate || certificateEndDate) {
                radioSet = null
            }
            return radioSet
        },
        userInfo() {
            return this.$store.getters.userInfo
        }
    },
    watch: {
        $route: {
            handler(route) {
                const { id, needEdit, isRenewCer } = route.query || {}
                if (id) {
                    this.getEnterpriseAuthorizationInfoSelectOne(id)
                }
                if (needEdit) {
                    this.isEdit = needEdit
                }
                if (isRenewCer) {
                    this.isRenewCer = isRenewCer
                }
                this.pageId = id
            },
            immediate: true
        },
        userInfo: {
            handler(val) {
                // 新增才赋值，否则数据返回里取
                if (this.mode === 'add') {
                    this.form.userName = val.name
                }
            },
            immediate: true
        }
    },
    mounted() {
        // 获取机构名称
        // this.getOrganizationDataList()
    },
    methods: {
        moment,
        /**
         * 证件有效期联动处理
         */
        handleClikRadio() {
            // 选中
            this.radiochange = 1
            this.form.validity.certificateStartDate = ''
            this.form.validity.certificateEndDate = ''
            this.form.certificatePermanent = true
        },
        /**
         * 在职状态改变
         */
        handleStateChange(val) {
            // 状态是离职  离职日期必须填
            // this.handleLeave(val)
        },
        /**
         * 身份证校验
         */
        handleValidateCard() {
            // 身份证需要校验
            this.rules.farenCode = [
                {
                    required: true,
                    message: '请输入证件号码',
                    trigger: 'blur'
                }, {
                    validator: validateIDCard,
                    trigger: 'blur'
                }
            ]
        },
        /**
         * 证件类型change
         */
        handleChangeCertificateType(val) {
            this.form.idCard = ''
            this.handleValidateCard(val)
        },
        /**
         * 身份证获取出生日期和性别
         */
        handleGetIdCardInfo(idCardValue) {
            let reg = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
            if (reg.test(idCardValue)) {
                this.form.birthday = getBirthdayByIdNO(idCardValue)
                this.form.sex = getSexByIdNO(idCardValue)
            } else {
                this.form.birthday = ''
                this.form.sex = undefined
            }
        },
        /**
         * 身份证变化获取出生日期和性别
         */
        handleIdCardChange(e) {
            let idCardValue = e.target.value
            this.handleGetIdCardInfo(idCardValue)
        },
        /**
         * 证件有效止期 > 证件有效起期
         */
        disabledDateStart(current) {
            const { form: { validity } } = this
            if (validity.certificateEndDate && current) {
                return moment(current).format('YYYYMMDD') > moment(validity.certificateEndDate).format('YYYYMMDD')
            }
        },
        disabledDateEnd(current) {
            const { form: { validity } } = this
            if (validity.certificateStartDate && current) {
                return moment(current).format('YYYYMMDD') < moment(validity.certificateStartDate).format('YYYYMMDD')
            }
        },
        /**
         * 证件有效止期 > 证件有效起期
         */
        disabledAuthorizationDateStart(current) {
            if (this.isRenewCer) {
                return false
            } else {
                const { form: { endDateTime } } = this
                if (endDateTime && current) {
                    return moment(current).format('YYYYMMDD') > moment(endDateTime).format('YYYYMMDD')
                }
            }

        },
        disabledAuthorizationDateEnd(current) {
            // if (this.isRenewCer) {
            //     return false
            // } else {
            const { form: { startDateTime } } = this
            if (startDateTime && current) {
                return moment(current).format('YYYYMMDD') < moment(startDateTime).format('YYYYMMDD')
            }
            // }
        },
        /**
         * 获取授权历史记录列表
         */

        async getEnterpriseAuthorizationHistorySelect() {
            const { pageId, limit, current } = this

            try {
                this.loading = true
                const { total, records } = await getEnterpriseAuthorizationHistoryListService({
                    enterpriseAuthorizationId: pageId,
                    size: limit,
                    current
                })
                this.total = total || 0
                this.tableData = records
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.loading = false
            }
        },

        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.limit = pageSize
            this.current = current
            this.getEnterpriseAuthorizationHistorySelect()
        },

        /**
         * 获取单条回显记录
         */
        async getEnterpriseAuthorizationInfoSelectOne(id) {
            this.pageLoading = true
            try {
                const obj = await getEnterpriseAuthorizationDetailService({ id }) || {}
                console.log('getEnterpriseAuthorizationInfoSelectOne: ' + JSON.stringify(obj, null, 4))
                this.currentState = obj.state !== ENTERPRISE_AUTHORIZATION_UNPASS_TEXT ? obj.state : '（失败原因：' + obj.description + ')'
                this.stepCurrent = this.getCurrentSetpFromState(obj.state)
                this.type = obj.type === '更新' && obj.type || ''
                const { enterpriseAuthorizationBook } = obj
                this.enterpriseAuthorizationId = enterpriseAuthorizationBook && enterpriseAuthorizationBook.id || ''
                if (obj.state === ENTERPRISE_AUTHORIZATION_PASS_TEXT && this.enterpriseAuthorizationId) {
                    this.getEnterpriseAuthorizationHistorySelect()
                }
                const enterpriseAuthorizationBookFormObj = {
                    startDateTime: enterpriseAuthorizationBook && enterpriseAuthorizationBook.startDateTime || null,
                    endDateTime: enterpriseAuthorizationBook && enterpriseAuthorizationBook.endDateTime || null,
                    authReport: enterpriseAuthorizationBook && enterpriseAuthorizationBook.authReport || ''
                }
                let resultObj = {
                    ...enterpriseAuthorizationBookFormObj,
                    ...obj
                }
                // const {
                //     provinceCode,
                //     cityCode,
                //     areaCode,
                //     contractFile,
                //     certificateStartDate,
                //     certificateEndDate,
                //     certificateType,
                //     idCard
                // } = userExtendsVO || {}
                // const { certificateFileAddress, certificateCode, issueCertificatesDate } = userAgentCertification || {}
                // // 根据certificateCode判断经纪人是否认证  certificateCode有值说明经纪人已认证 编辑时候该值不能为空
                // if (certificateCode) {
                //     this.isCertifiedBroker = true
                // }
                // 回显-表单-userExtendsVO
                for (let k in this.form) {
                    this.form[k] = resultObj[k] ? resultObj[k] : undefined
                }

                // // 回显是身份证校验身份证正确性
                // this.handleValidateCard(certificateType)

                // // 身份证回显出生日期和性别
                // this.handleGetIdCardInfo(idCard)

                // 回显表单其他信息
                // this.form.institutionsId = institutionsId || undefined
                // this.form.referees = referees || undefined
                // this.form.state = state || undefined
                // this.form.certificateCode = certificateCode || undefined
                // this.form.issueCertificatesDate = issueCertificatesDate || undefined

                // 回显-地区级联
                // this.form.addressAreaCode = provinceCode || cityCode || areaCode ? [provinceCode, cityCode, areaCode] : []

                // 回显证件有效期
                // this.form.validity = {
                //     certificateStartDate,
                //     certificateEndDate
                // }
                // 回显-文件
                this.uploadInfo('authReportFileList', enterpriseAuthorizationBook && enterpriseAuthorizationBook.authReport || '', '授权委托书')
                this.uploadInfo('businessLicenseInfoFileList', obj.businessLicenseInfo, '营业执照')
                this.uploadInfo('corporateIdCardInfoFrontFileList', obj.corporateIdCardInfoFront, '法人身份证正面')
                this.uploadInfo('corporateIdCardInfoSideFileList', obj.corporateIdCardInfoSide, '法人身份证反面')
                this.uploadInfo('qualifeCertInfoFileList', obj.qualifeCertInfo, '资质证书')
                this.uploadInfo('financeReportFileList', obj.financeReport, '财务报表')
                this.uploadInfo('financialAuditReportInfoFileList', obj.financialAuditReportInfo, '审计报告')
                this.uploadInfo('permitFileList', obj.permit, '开户许可证')
                this.uploadInfo('safetyPermitFileList', obj.safetyPermit, '安全许可证')
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.pageLoading = false
            }
        },
        /**
         * 文件回显
         */
        uploadInfo(fileList, url, name) {
            if (url) {
                let i = url.lastIndexOf('.')
                let imagesList = [
                    {
                        uid: this.fileIndex,
                        name: name + url.slice(i),
                        status: 'done',
                        url
                    }
                ]
                this[fileList] = imagesList
                this.fileIndex++
            }
        },
        /**
         * 返回列表
         */
        handleGoList() {
            this.$router.go(-1)
        },
        /**
         * 编辑按钮操作
         */
        handleEditAgent() {
            this.isEdit = true
        },
        /**
         * 撤销审批操作
         */
        handleAuthCancel() {
            this.$confirm({
                title: '该企业认证将被撤销审批, 是否继续？',
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk: async () => {
                    this.handleEditSave(ENTERPRISE_AUTHORIZATION_CANCEL_TEXT)
                },
                onCancel() {
                }
            })
        },
        /**
         * 提交审批操作
         */
        handleAuthSubmit() {
            this.$confirm({
                title: '该企业认证将被提交审批, 是否继续？',
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk: async () => {
                    this.handleEditSave(ENTERPRISE_AUTHORIZATION_WAIT_AUTHORIZATION_TEXT, true)
                },
                onCancel() {
                }
            })
        },

        /**
         * 重新提交审批操作
         */
        handleAuthSubmitAgain() {
            this.$confirm({
                title: '该企业认证将重新提交审批, 是否继续？',
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk: async () => {
                    this.handleEditSave(ENTERPRISE_AUTHORIZATION_WAIT_AUTHORIZATION_TEXT)
                },
                onCancel() {
                }
            })
        },
        /**
         * 文件上传
         */
        getFileUploadUrl(action) {
            let { fileList } = action
            let url = ''
            fileList.map(file => {
                if (file.response) {
                    url = file.response
                } else if (file.url) {
                    url = file.url
                }
            })
            return {
                fileList,
                url
            }
        },
        /**
         * 合同文件上传
         */
        handleContractFileUploadChange(action) {
            this.form.contractFile = this.getFileUploadUrl(action).url
            this.contractFileList = this.getFileUploadUrl(action).fileList
        },
        /**
         * 电子执业证上传
         */
        handleElectronicLicenseChange(action) {
            this.form.certificateFileAddress = this.getFileUploadUrl(action).url
            this.electronicLicenseFileList = this.getFileUploadUrl(action).fileList
        },
        /**
         * 授权文件上传
         */
        handleEnterpriseAuthorizationAuthReportChange(action) {
            this.form.authReport = this.getFileUploadUrl(action).url
            this.authReportFileList = this.getFileUploadUrl(action).fileList
        },
        /**
         * 营业执照上传
         */
        handleEnterpriseAuthorizationBusinessLicenseInfoChange(action) {
            this.form.businessLicenseInfo = this.getFileUploadUrl(action).url
            this.businessLicenseInfoFileList = this.getFileUploadUrl(action).fileList
        },
        /**
         * 法人身份证正面上传
         */
        handleEnterpriseAuthorizationCorporateIdCardInfoFrontChange(action) {
            this.form.corporateIdCardInfoFront = this.getFileUploadUrl(action).url
            this.corporateIdCardInfoFrontFileList = this.getFileUploadUrl(action).fileList
        },
        /**
         * 法人身份证反面上传
         */
        handleEnterpriseAuthorizationCorporateIdCardInfoSideChange(action) {
            this.form.corporateIdCardInfoSide = this.getFileUploadUrl(action).url
            this.corporateIdCardInfoSideFileList = this.getFileUploadUrl(action).fileList
        },
        /**
         * 资质证书上传
         */
        handleEnterpriseAuthorizationQualifeCertInfoChange(action) {
            this.form.qualifeCertInfo = this.getFileUploadUrl(action).url
            this.qualifeCertInfoFileList = this.getFileUploadUrl(action).fileList
        },
        /**
         * 财务报表上传
         */
        handleEnterpriseAuthorizationFinanceReportChange(action) {
            this.form.financeReport = this.getFileUploadUrl(action).url
            this.financeReportFileList = this.getFileUploadUrl(action).fileList
        },
        /**
         * 审计报告上传
         */
        handleEnterpriseAuthorizationFinancialAuditReportInfoChange(action) {
            this.form.financialAuditReportInfo = this.getFileUploadUrl(action).url
            this.financialAuditReportInfoFileList = this.getFileUploadUrl(action).fileList
        },
        /**
         * 开户许可证上传
         */
        handleEnterpriseAuthorizationPermitChange(action) {
            this.form.permit = this.getFileUploadUrl(action).url
            this.permitFileList = this.getFileUploadUrl(action).fileList
        },
        /**
         * 安全许可证上传
         */
        handleEnterpriseAuthorizationSafetyPermitChange(action) {
            this.form.safetyPermit = this.getFileUploadUrl(action).url
            this.safetyPermitFileList = this.getFileUploadUrl(action).fileList
        },
        /**
         * 文件校验
         * @param type
         */
        handleFileValidate({ type }) {
            const obj = {
                FILE_LARGE: '请上传少于2M的文件',
                FILE_TYPE_CUSTOM: '请上传doc、docx、zip、jpeg、jpg、png、rar、xls、xlsx、pdf文件'
            }
            this.$message.error(obj[type])
        },
        /**
         * 格式化参数
         */
        /**
         * 格式化参数
         *
         * // 待提交
export const ENTERPRISE_AUTHORIZATION_WAIT_SUBBMIT_TEXT = '待提交'
// 待审核
export const ENTERPRISE_AUTHORIZATION_WAIT_AUTHORIZATION_TEXT = '待审核'
// 已通过
export const ENTERPRISE_AUTHORIZATION_PASS_TEXT = '已通过'
// 未通过
export const ENTERPRISE_AUTHORIZATION_UNPASS_TEXT = '未通过'
// 已撤销
export const ENTERPRISE_AUTHORIZATION_CANCEL_TEXT = '已撤销'
         */
        formatParams(stateText, isUpdate) {
            const { form } = this

            // const { form, radioValue } = this
            const {
                // // 最外层
                // institutionsId,
                // referees,
                // state,

                // // userExtendsBO
                enterprisName,
                creditCode,
                regAddress,
                legalPerson,
                farenCode,
                contacts,
                mobile,
                userName,
                businessLicenseInfo,
                corporateIdCardInfoFront,
                corporateIdCardInfoSide,
                qualifeCertInfo,
                financeReport,
                financialAuditReportInfo,
                permit,
                safetyPermit,
                startDateTime,
                endDateTime,
                authReport
                // birthday,
                // sex,
                // idCard,
                // validity,
                // certificateType,
                // addressAreaCode,
                // address,
                // // mobile,
                // onboardingDateTime,
                // leaveDateTime,
                // bankCardNumber,
                // bank,
                // openAccountAddress,
                // openAccountName,
                // contractType,
                // natureWork,
                // contractFile,

                // userAgentCertification
                // certificateCode,
                // issueCertificatesDate,
                // certificateFileAddress
            } = form || {}
            // let enterpriseAuthorizationBook = {
            //     startDateTime: startDateTime ? moment(startDateTime).format('YYYY-MM-DD') : '',
            //     endDateTime: endDateTime ? moment(endDateTime).format('YYYY-MM-DD') : '',
            //     authReport
            // }
            let firstParams = { userId: this.userInfo.id || '' }
            let commonParams = {
                enterprisName,
                creditCode,
                regAddress,
                legalPerson,
                farenCode,
                contacts,
                mobile,
                userName,
                businessLicenseInfo,
                corporateIdCardInfoFront,
                corporateIdCardInfoSide,
                qualifeCertInfo,
                financeReport,
                financialAuditReportInfo,
                permit,
                safetyPermit,
                state: stateText
            }
            let params = {
                ...firstParams,
                ...commonParams
            }
            params['enterpriseAuthorizationBook.startDateTime'] = startDateTime ? moment(startDateTime).format('YYYY-MM-DD 00:00:00') : ''
            params['enterpriseAuthorizationBook.endDateTime'] = endDateTime ? moment(endDateTime).format('YYYY-MM-DD 23:59:59') : ''
            params['enterpriseAuthorizationBook.authReport'] = authReport || ''
            // 编辑状态且不是更新认证或更新审核的情况下才传此id
            // 待审核 的情况且不是更新认证或更新审核的情况下也传此id
            if ((this.isEdit || stateText === ENTERPRISE_AUTHORIZATION_CANCEL_TEXT || stateText === ENTERPRISE_AUTHORIZATION_WAIT_AUTHORIZATION_TEXT) && this.enterpriseAuthorizationId && !this.isRenewCer) {
                params['enterpriseAuthorizationBook.id'] = this.enterpriseAuthorizationId || ''
            }
            // 如果是更新认证更新授权type改为更新
            if (this.isRenewCer) {
                params.type = '更新'
            }
            if (isUpdate) {
                params.id = this.pageId || ''
            }
            let clearParams = { ...this.handleClearParams(params) }
            let formData = new FormData()
            Object.keys(clearParams).map(item => {
                formData.append(item, clearParams[item])
            })
            // const { startDateTime, endDateTime, authReport } = enterpriseAuthorizationBook

            // const [provinceCode, cityCode, areaCode] = addressAreaCode || []
            // const { certificateStartDate, certificateEndDate } = validity
            // let userExtendsBO = {
            //     enterprisName,
            //     creditCode,
            //     regAddress,
            //     legalPerson,
            //     farenCode,
            //     contacts,
            //     mobile,
            //     birthdayStr: birthday ? moment(birthday).format('YYYY-MM-DD') : '',
            //     sex,
            //     idCard,
            //     certificatePermanent: radioValue ? true : false,
            //     certificateStartDateStr: !radioValue && certificateStartDate ? moment(certificateStartDate).format('YYYY-MM-DD') : '',
            //     certificateEndDateStr: !radioValue && certificateEndDate ? moment(certificateEndDate).format('YYYY-MM-DD') : '',
            //     certificateType,
            //     provinceCode,
            //     cityCode,
            //     areaCode,
            //     address,
            //     // mobile,
            //     onboardingDateTimeStr: onboardingDateTime ? moment(onboardingDateTime).format('YYYY-MM-DD 00:00:00') : '',
            //     leaveDateTimeStr: leaveDateTime ? moment(leaveDateTime).format('YYYY-MM-DD 00:00:00') : '',
            //     bankCardNumber,
            //     bank,
            //     openAccountAddress,
            //     openAccountName,
            //     contractType,
            //     natureWork,
            //     contractFile
            // }
            // let userAgentCertification = {
            //     certificateCode: certificateCode || '',
            //     issueCertificatesDateStr: issueCertificatesDate ? moment(issueCertificatesDate).format('YYYY-MM-DD') : '',
            //     certificateFileAddress: certificateFileAddress || ''
            // }
            // let firstParams = {
            //     institutionsId: institutionsId || '',
            //     referees: referees || '',
            //     state: state || ''
            // }
            // let userExtendsBOSet = { ...this.handleClearParams(userExtendsBO) }
            // let params = {
            //     ...firstParams,
            //     userExtendsBO: userExtendsBOSet,
            //     userAgentCertification
            // }
            // if (state === JOB_STATUS_LEAVE_JOB_TEXT && !leaveDateTime) {
            //     this.$message.error('离职状态请填写离职日期')
            //     return false
            // }
            // console.log('params detail:' + JSON.stringify(params, null, 4))
            return formData
        },

        validateSaveParams() {
            const { form } = this
            const {
                enterprisName,
                creditCode,
                regAddress,
                legalPerson,
                farenCode,
                businessLicenseInfo,
                corporateIdCardInfoFront,
                corporateIdCardInfoSide,
                qualifeCertInfo,
                startDateTime,
                endDateTime,
                authReport
            } = form || {}
            if (enterprisName && creditCode && regAddress && legalPerson &&
            farenCode && businessLicenseInfo && corporateIdCardInfoFront &&
            corporateIdCardInfoSide && qualifeCertInfo && startDateTime && endDateTime && authReport) {
                return true
            } else {
                return false
            }
        },
        /**
         * 参数清空
         */
        handleClearParams(obj) {
            for (let key in obj) {
                if (obj[key] === null || obj[key] === undefined) {
                    obj[key] = ''
                }
            }
            return obj
        },
        /**
         * 新增企业认证
         */
        async handelAdd(stateText) {
            let paramsForm = this.formatParams(stateText, false)
            if (!paramsForm) {
                return false
            }
            try {
                this.addLoading = true
                await addEnterpriseAuthorizationService(paramsForm)
                this.$message.success('添加成功')
                this.$router.go(-1)
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.addLoading = false
            }
        },
        /**
         * 新增企业认证表单校验
         */
        handleAddSave(stateText) {
            if ( stateText === ENTERPRISE_AUTHORIZATION_WAIT_SUBBMIT_TEXT ) {
                if (this.form.enterprisName && this.form.creditCode) {
                    this.handelAdd(stateText)
                } else {
                    this.$confirm({
                        title: '企业名称、统一信用代码必填，其他可不填',
                        okText: '确定',
                        cancelText: '取消'
                    })
                }
            } else {
                this.$refs.ruleForm.validate(valid => {
                    if (valid) {
                        this.handelAdd(stateText)
                    } else {
                        return false
                    }
                })
            }

        },
        /**
         * 编辑企业认证
         */
        async handelEdit(stateText) {
            let paramsForm = this.formatParams(stateText, true)
            if (!paramsForm) {
                return false
            }
            try {
                this.addLoading = true
                const result = await editEnterpriseAuthorizationService(paramsForm)
                if (result === HTTP_ERR_MESSAGE_STATE_FIELD) {
                    this.$confirm({
                        title: '状态异常，请刷新页面后重试',
                        okText: '确定',
                        cancelText: '取消'
                    })
                } else {
                    this.$message.success('提交成功')
                    this.$router.go(-1)
                }
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.addLoading = false
            }

            // const { isCertifiedBroker, form } = this
            // let paramsForm = this.formatParams()
            // if (!paramsForm) {
            //     return false
            // }
            // paramsForm.id = this.pageId
            // if (isCertifiedBroker && !form.certificateCode) {
            //     this.$message.error('企业认证已认证,执业证号不能为空')
            //     return false
            // }
            // try {
            //     // this.editLoading = true
            //     // await agentManagementUpdateService(paramsForm)
            //     // this.$message.success('编辑成功')
            //     // this.getEnterpriseAuthorizationInfoSelectOne(this.pageId)
            //     // this.isEdit = false
            // } catch (e) {
            //     this.$message.error(e.message)
            // } finally {
            //     this.editLoading = false
            // }
        },
        /**
         * 编辑企业认证表单校验
         */
        handleEditSave(stateText, needValidate) {
            if ( stateText === ENTERPRISE_AUTHORIZATION_WAIT_SUBBMIT_TEXT) {
                if (this.form.enterprisName && this.form.creditCode) {
                    this.handelEdit(stateText)
                } else {
                    this.$confirm({
                        title: '企业名称、统一信用代码必填，其他可不填',
                        okText: '确定',
                        cancelText: '取消'
                    })
                }
            } else if ( needValidate) {
                // 校验所有字段
                let validateResult = this.validateSaveParams()
                if (validateResult) {
                    this.$refs.ruleForm.validate(valid => {
                        if (valid) {
                            this.handelEdit(stateText)
                        } else {
                            return false
                        }
                    })
                } else {
                    this.$confirm({
                        title: '企业认证信息未填写完整，填写完整后提交！',
                        okText: '编辑',
                        okType: 'danger',
                        cancelText: '取消',
                        onOk: async () => {
                            this.handleEditAgent()
                        },
                        onCancel() {
                        }
                    })
                }
            } else {
                this.$refs.ruleForm.validate(valid => {
                    if (valid) {
                        this.handelEdit(stateText)
                    } else {
                        return false
                    }
                })
            }
        },
        /**
         * 编辑取消
         */
        handleCancelEdit() {
            this.isEdit = false
            this.$router.go(-1)
            // window.location.reload()
            // const { pageId } = this
            // 重新请求详情
            // this.getEnterpriseAuthorizationInfoSelectOne(pageId)
        },

        /**
         *  转换当前阶段
        */
        getCurrentSetpFromState(state) {
            for (let i = 0; i < this.enterpriseAuthorizationStatusList.length; i++) {
                const item = this.enterpriseAuthorizationStatusList[i]
                if (item.label === state) {
                    if (item.value === 2 || item.value === 3 || item.value === 4 ) {
                        this.thirdState = item.label
                    }
                    return item.value === 2 || item.value === 3 || item.value === 4 ? 2 : item.value === 0 ? -1 : item.value
                }
            }
        },
        async handleDownloadTemplate() {
            try {
                // 执行模板下载
                const templateUrl = 'https://gongbaojinji.oss-cn-hangzhou.aliyuncs.com/comnon/%E6%8E%88%E6%9D%83%E5%A7%94%E6%89%98%E4%B9%A6%EF%BC%88%E4%BF%9D%E9%99%A9%EF%BC%89-20230919.docx'
                gotoPage(templateUrl)
            } catch (e) {
                this.$message.error(e.message)
            }
        },

        /**
         * 文件-获取文件type
         */
        formatterFileType(url) {
            let type = ''
            if (url) {
                let i = url.lastIndexOf('.')
                type = url.slice(i + 1)
            }
            return type
        },

        /**
         * 文件-预览
         */
        handlePreview(url, type) {
            previewFiles(type, url)
        }
    }

}
</script>

<style lang='scss'>
.agent-management-operation {
    &__cross {
        float: right;
        cursor: pointer;
    }
    &__edit {
       display: flex;
       justify-content: space-between;
        width:100%;
        // text-align: right;
        margin-bottom: 10px;
    }
    &__state {
        color: #D70A25
    }
    &__bottom {
        &-button {
            width:100%;
            text-align: center;
            margin-top: 30px;
            .ant-btn {
                width: 100px;
            }
        }
    }
    .ant-col-12 {
        min-height: 70px;
    }
    .ant-calendar-picker {
        width: 100%;
    }
    &__validity {
        &-text {
            margin: 0 5px;
        }
        .ant-calendar-picker {
            width: 112px;
            min-width: 112px !important;
        }
        .ant-calendar-picker-input {
            width: 112px;
        }
        .ant-radio-wrapper {
            margin-left: 5px;
        }
    }
}
</style>
