<template>
    <search-panel :model="form" layout="vertical" @search="handleSearch" @refresh="handleRefresh">
        <form-col label="创建时间" prop="submitTime">
            <template :slot="formColDefaultSlot">
                <submit-time-section v-model="form.submitTime" :mode="submitTimeMode" />
            </template>
        </form-col>
        <form-col label="姓名" prop="name">
            <template :slot="formColDefaultSlot">
                <a-input v-model="form.name" placeholder="请输入"></a-input>
            </template>
        </form-col>
        <form-col label="手机号" prop="mobile">
            <template :slot="formColDefaultSlot">
                <a-input v-model="form.mobile" placeholder="请输入"></a-input>
            </template>
        </form-col>
        <form-col label="线索来源" prop="sourceQuery">
            <template :slot="formColDefaultSlot">
                <clue-source v-model="form.sourceQuery" :listType="type"/>
            </template>
        </form-col>
        <form-col label="管家" prop="userIdQuery">
            <template :slot="formColDefaultSlot">
                <steward ref="stewardList" v-model="form.userIdQuery" :type="stewardType"/>
            </template>
        </form-col>
    </search-panel>
</template>

<script>
// 组件
import { Input as AInput, Select as ASelect } from 'ant-design-vue'
import Steward from '@weights/Steward'
import SubmitTimeSection from '@weights/SubmitTimeSection'
import ClueSource from '@weights/ClueSource'
import SearchPanel from '@components/SearchPanel'
import FormCol from '@components/FormCol'

// 工具类
import { filterParams } from '@utils/search.js'

// 全局数据
import { STEWARD_LIST_TYPE_CUSTOMER_CLUE , CLUE_TYPE_CUSTOMER_CLUE} from '@constant/enum'

export default {
    name: 'ClientClueSearchPanel',
    data() {
        return {
            submitTimeMode: ['date', 'date'],
            form: {
                // 创建时间
                submitTime: [],
                // 姓名
                name: '',
                // 手机号
                mobile: '',
                // 线索来源
                sourceQuery: '',
                // 管家
                userIdQuery: ''
            },
            // 管家类型
            stewardType:STEWARD_LIST_TYPE_CUSTOMER_CLUE,
            type:CLUE_TYPE_CUSTOMER_CLUE,
            // 筛选-插槽
            formColDefaultSlot: 'formElements'
        }
    },
    computed: {},
    mounted() {},
    methods: {
        /**
         * 响应搜索事件
         */
        handleSearch() {
            const params = this.formatParams()
            this.$emit('search', filterParams(params))
        },
        /**
         * 响应刷新事件
         */
        handleRefresh() {
            const params = this.formatParams()
            this.$emit('refresh', filterParams(params))
        },
        /**
         * 格式化参数
         */
        formatParams() {
            const { form } = this
            let { submitTime, ...params } = form
            let [startTime, endTime] = submitTime || []
            startTime = startTime
                ? `${startTime.format('YYYY-MM-DD')} 00:00:00`
                : ''
            endTime = endTime ? `${endTime.format('YYYY-MM-DD')} 23:59:59` : ''
            params.startTime = startTime
            params.endTime = endTime
            return params
        },
        refreshSteward(){
            this.$refs.stewardList.getList()
        }
    },
    components: {
        SearchPanel,
        AInput,
        ASelect,
        ASelectOption: ASelect.Option,
        FormCol,
        Steward,
        SubmitTimeSection,
        ClueSource
    }
}
</script>

<style lang="scss">
</style>
