// 组件
import DrawerPanel from '@weights/Customer/Components/ComponentsDetail/DrawerPanel'
import StatusPanel from '@weights/Customer/Components/ComponentsDetail/StatusPanel'
import FieldList from '@weights/Customer/Components/ComponentsDetail/FieldList'
import OperationLog from '@weights/Customer/Components/ComponentsDetail/OperationLog'

// 常量
import {
    SALES_CATEGORY_DISTRIBUTION_TEXT,
    SALES_CATEGORY_SELF_SUPPORT_TEXT,
    SALES_CATEGORY_DISTRIBUTION,
    SALES_CATEGORY_SELF_SUPPORT
} from '@/constant/enum'
import {
    DISTRIBUTION_CUSTOMER_DETAIL_MENU_CODE,
    PROPRIETARY_CUSTOMER_DETAIL_MENU_CODE
} from '@/constant/menu_code'

// mixins
import enumOperate from '@mixins/enumOperate'
import empty from '@mixins/empty'
import fileType from '@/mixins/fileType'
import searchTeamUser from '@/mixins/searchTeamUser'

// 工具类
import { previewFiles } from '@/utils/previewFiles'

export default {
    components: {
        DrawerPanel,
        StatusPanel,
        FieldList,
        OperationLog
    },
    mixins: [enumOperate,
        empty,
        fileType,
        searchTeamUser],
    data() {
        return {
            SALES_CATEGORY_DISTRIBUTION_TEXT,
            SALES_CATEGORY_SELF_SUPPORT_TEXT,
            SALES_CATEGORY_DISTRIBUTION,
            SALES_CATEGORY_SELF_SUPPORT,
            DISTRIBUTION_CUSTOMER_DETAIL_MENU_CODE,
            PROPRIETARY_CUSTOMER_DETAIL_MENU_CODE,
            log: {
                // 分页数量
                limit: 9999,
                // 总记录数量
                total: 0,
                // 当前索引
                current: 1,
                loading: false
            },
            // 业务类型
            businessType: '',
            // 经纪人类型
            agentType: ''
        }
    },
    computed: {
        userInfo() {
            return this.$store.getters.userInfo
        },
        role() {
            return this.$store.getters.role
        }
    },
    watch: {
        $route: {
            handler(route) {
                const { businessType } = route.query || {}
                this.businessType = Number(businessType)
            },
            immediate: true
        }
    },
    methods: {
        /**
         * 初始化
         */
        afterVisibleChange(visible) {
            if (visible) {
                this.init()
            } else {
                this.record = {}
            }
        },
        /**
         * 状态icon
         * @params state  状态值
         * @params errorList  失败/取消/关闭
         * @params successList  成功/完成
         */
        handleStateIcon(state, errorList, successList) {
            let type = 'waiting'

            if (errorList && errorList.length > 0 && errorList.includes(state)) {
                type = 'error'
            } else if (successList && successList.length > 0 && successList.includes(state)) {
                type = 'success'
            }

            const stateIcon = require(`@/assets/images/${type}.png`)

            return stateIcon
        },
        /**
         * 状态icon 文字匹配版本
         * @params state  状态值文字版
         * @params errorList  失败/取消/关闭/保险退保
         * @params successList  成功/完成
         * @params insuranceIngList  保险中
         * @params insuranceRenewList  待续保
         */
        handleStateWordIcon(state, errorList, successList, insuranceIngList, insuranceRenewList) {
            // 处理中、待处理、待生效
            let type = 'waiting'

            if (errorList && errorList.length > 0 && errorList.includes(state)) {
                type = 'error'
            } else if (successList && successList.length > 0 && successList.includes(state)) {
                type = 'success'
            } else if (insuranceIngList && insuranceIngList.length > 0 && insuranceIngList.includes(state)) {
                type = 'insuranceIng'
            } else if (insuranceRenewList && insuranceRenewList.length > 0 && insuranceRenewList.includes(state)) {
                type = 'insuranceRenew'
            }

            const stateIcon = require(`@/assets/images/${type}.png`)

            return stateIcon
        },
        /**
         * 状态icon 线上保单 crmInsuranceStatus 匹配版本
         * @params state  状态值
         * @params errorList  保险退保
         * @params successList  成功/完成
         * @params insuranceIngList  保险中
         * @params insuranceRenewList  待续保
         */
        handleStateIconOnline(state, errorList, successList, insuranceIngList, insuranceRenewList) {
            // 处理中、待处理、待生效
            let type = 'waiting'

            if (errorList && errorList.length > 0 && errorList.includes(state)) {
                type = 'error'
            } else if (successList && successList.length > 0 && successList.includes(state)) {
                type = 'success'
            } else if (insuranceIngList && insuranceIngList.length > 0 && insuranceIngList.includes(state)) {
                type = 'insuranceIng'
            } else if (insuranceRenewList && insuranceRenewList.length > 0 && insuranceRenewList.includes(state)) {
                type = 'insuranceRenew'
            }

            const stateIcon = require(`@/assets/images/${type}.png`)

            return stateIcon
        },
        /**
         * 详情-时间范围
         * @params startInsuranceTime  保险开始时间
         * @params  endInsuranceTime 保险结束时间
         */
        handleRangeTime(startInsuranceTime, endInsuranceTime) {
            if (!(startInsuranceTime && endInsuranceTime)) return

            return `自${startInsuranceTime}零时起 至 ${endInsuranceTime}二十四时止`
        },
        /**
         * 抽屉-关闭
         */
        handleClose() {
            this.$emit('close')
        },
        /**
         *
         * 预览
         */
        handlePreview(url) {
            if (url) {
                let type = this.formatterFileType(url)
                previewFiles(type, url)
            } else {
                this.$message.error('文件错误')
            }
        },
        /**
         * 投保用户超链接
         */
        async handleInsuredUser(label, id, name) {
            const {
                SALES_CATEGORY_DISTRIBUTION_TEXT,
                SALES_CATEGORY_SELF_SUPPORT_TEXT,
                SALES_CATEGORY_DISTRIBUTION,
                SALES_CATEGORY_SELF_SUPPORT,
                customerId,
                agentUserId
            } = this
            let result = {
                key: label,
                value: `${name ? name : '-'}${id ? '(' + id + ')' : ''}`
            }

            // 1. 必要参数
            if (!customerId) return result
            if (!agentUserId) return result

            let routerPath = ''
            let businessType = null

            // 2. 判断经纪人类型
            await this.judgeAgentType(agentUserId)
            const { agentType } = this
            if (!agentType) return result

            // 3. 判断有无分销/自营详情  页面权限   有则添加超链接
            const hasAuth = await this.judgeAuth(agentType) || false
            if (hasAuth) {
                // 分销
                if (agentType === SALES_CATEGORY_DISTRIBUTION_TEXT) {
                    routerPath = '/customer/distribution-customer/detail'
                    businessType = SALES_CATEGORY_DISTRIBUTION
                } else if (agentType === SALES_CATEGORY_SELF_SUPPORT_TEXT) {
                    // 自营
                    routerPath = '/customer/proprietary-customer/detail'
                    businessType = SALES_CATEGORY_SELF_SUPPORT
                }

                result = {
                    ...result,
                    open: true,
                    openBusiness: routerPath,
                    openParams: {
                        customerId,
                        agentUserId,
                        businessType
                    }
                }
            }

            return result
        },
        /**
         * 判断当前登录账户是否有分销/自营详情的菜单权限
         */
        async judgeAuth(agentType) {
            const {
                SALES_CATEGORY_DISTRIBUTION_TEXT,
                SALES_CATEGORY_SELF_SUPPORT_TEXT,
                DISTRIBUTION_CUSTOMER_DETAIL_MENU_CODE,
                PROPRIETARY_CUSTOMER_DETAIL_MENU_CODE,
                role
            } = this
            let key = ''

            // 分销
            if (agentType === SALES_CATEGORY_DISTRIBUTION_TEXT) {
                key = DISTRIBUTION_CUSTOMER_DETAIL_MENU_CODE
            } else if (agentType === SALES_CATEGORY_SELF_SUPPORT_TEXT) {
                // 自营
                key = PROPRIETARY_CUSTOMER_DETAIL_MENU_CODE
            }

            return role && role[key]
        }
    }
}
