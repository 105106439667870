<!-- 保单记录Tab -->
<template>
    <div>
        <pro-table
            ref="tableRef"
            row-key="id"
            :scroll="{ x: '100%' }"
            :columns="columns"
            :request="getList"
        >
            <template slot="tableExtendLeft">
                <div style="margin-bottom: 27px;">共计保费（元）：{{ insurancePremiumTotal }}</div>
            </template>
            <!-- 保险期间 -->
            <template  #startInsuranceTime="{text, record}">
                <p>{{ text }}</p>
                <p>{{ record.endInsuranceTime }}</p>
            </template>
            <template #action="{record}">
                <a-button
                    type="link"
                    @click="handleDetail(record)"
                >
                    查看
                </a-button>
            </template>
        </pro-table>

        <!-- 详情-线上保单 start -->
        <policy-record-detail
            :visible="visible"
            :cast-id="castId"
            @close="handleDetailClose"
        />
        <!-- 详情 end -->

        <!-- 详情-线下保单 start -->
        <policy-record-detail-off
            :id="id"
            :visible="visibleOff"
            @close="handleDetailCloseOff"
        />
        <!-- 详情 end -->

        <!-- 详情-G端保单 start -->
        <policy-record-detail-g
            :id="id"
            :visible="visibleG"
            @close="handleDetailCloseG"
        />
        <!-- 详情 end -->
    </div>
</template>
<script>
import ProTable from '@components/ProTable'
// 组件
import PolicyRecordDetail from '@weights/Customer/Components/ComponentsDetail/Detail/PolicyRecordDetail'
import PolicyRecordDetailOff from '@weights/Customer/Components/ComponentsDetail/Detail/PolicyRecordDetailOff'
import PolicyRecordDetailG from '@weights/Customer/Components/ComponentsDetail/Detail/PolicyRecordDetailG'

// 常量
import {
    POLICY_TYPE_ONLINE,
    POLICY_TYPE_LINE,
    POLICY_TYPE_G
} from '@/constant/enum'

// 配置
import columns from './columns'

// 请求
// import getCastManageInsuranceListService from '@service/customer/getCastManageInsuranceListService'
import getPolicyListService from '@service/customer/common/getPolicyListService'

export default {
    name: 'PolicyRecord',
    components: {
        ProTable,
        PolicyRecordDetail,
        PolicyRecordDetailOff,
        PolicyRecordDetailG
    },
    data() {
        return {
            POLICY_TYPE_ONLINE,
            POLICY_TYPE_LINE,
            POLICY_TYPE_G,
            columns,
            // 投保单号
            castId: '',
            // 保单号
            id: '',
            insurancePremiumTotal: 0,
            // 详情-线上保单-抽屉
            visible: false,
            // 详情-线下保单-抽屉
            visibleOff: false,
            // 详情-G端保单-抽屉
            visibleG: false
        }
    },
    computed: {
        userInfo() {
            return this.$store.getters.userInfo
        },
        userId() {
            return this.$route.query.userId
        }
    },
    methods: {
        // 获取列表数据
        async getList(params) {
            const { userId } = this
            if (!userId) {
                return {
                    data: [],
                    total: 0
                }
            }
            const timeObj = {
                // 签单日期
                1: {
                    start: 'beginSingleTime',
                    end: 'endSingleTime'
                },
                // 保险起期
                2: {
                    start: 'beginStartInsuranceTime',
                    end: 'stopStartInsuranceTime'
                },
                // 保险止期
                3: {
                    start: 'beginEndInsuranceTime',
                    end: 'stopEndInsuranceTime'
                }
            }
            console.log(params, '333333333333')
            const { pageSize, current, insuranceTime, ...otherParams } = params
            const { timeValue, selectValue } = insuranceTime || {
                timeValue: [],
                selectValue: 1
            }
            let [startTime, endTime] = timeValue || []
            startTime = startTime ? `${startTime.format('YYYY-MM-DD HH:mm:ss')}` : ''
            endTime = endTime ? `${endTime.format('YYYY-MM-DD HH:mm:ss')}` : ''
            let startField = timeObj[selectValue].start
            let endField = timeObj[selectValue].end
            let payload = {
                submitId: userId,
                customerType: 0,
                current,
                size: pageSize,
                ...otherParams
            }
            if (startTime) {
                payload[startField] = startTime
                payload[endField] = endTime
            }
            const { total, records: data, extra } = await getPolicyListService(payload)

            const { insurancePremiumTotal } = extra || {}
            this.insurancePremiumTotal = insurancePremiumTotal || 0
            return {
                data,
                total
            }
        },
        // 打开详情抽屉
        handleDetail(record) {
            const { id, castInsuranceId, type } = record
            this.id = id
            this.castId = castInsuranceId
            if (type === POLICY_TYPE_ONLINE ) {
                this.visible = true
            } else if (type === POLICY_TYPE_LINE ) {
                this.visibleOff = true
            } else if (type === POLICY_TYPE_G) {
                this.visibleG = true
            }
            // this.toggleDetail({
            //     key: 'PolicyRecordDetail',
            //     toggle: true,
            //     detailId: record.castInsuranceId
            // })
        },
        // 用于外部刷新
        refresh() {
            this.$refs.tableRef?.handleSearch?.()
        },
        handleDetailClose() {
            this.visible = false
        },
        /**
         * 列表-查看-线上保单-关闭
         */
        handleDetailCloseOff() {
            this.visibleOff = false
        },
        /**
         * 列表-查看-线下保单-关闭
         */
        handleDetailCloseG() {
            this.visibleG = false
        }
    },
    inject: { toggleDetail: { default: () => {} } }
}
</script>
