import { BASE_URL } from '@config'

/** 中标资讯接口
 * ————————————————————————————————————————————————————————————————————————————————————————————*/


/**
 * 中标资讯-列表
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const GET_WINNING_INFO_LIST = {
    url: `${BASE_URL}/common/nlp-winning/select`,
    method: 'get',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}


