/**
 * 保司 模块
 */
import BasicLayout from '@layout/BasicLayout'
import RouteLayout from '@layout/RouteLayout'

import {
    INSURE_COMPANY_MENU_CODE,
    INSURE_COMPANY_MANAGE_MENU_CODE,
    INSURE_COMPANY_LIST_MENU_CODE
} from '@constant/menu_code'

const routes = [{
    path: '/insure-company',
    name: 'InsureCompany',
    component: BasicLayout,
    code: INSURE_COMPANY_MENU_CODE,
    sort: 7,
    meta: {
        title: '保司管理',
        icon: 'C-baosiguanli',
        iconType: 'iconfont'
    },
    children: [{
        path: 'insure-company-manage',
        redirect: '/insure-company/insure-company-manage/list',
        // name: 'StewardManage',
        name: 'InsureCompanyManage',
        component: RouteLayout,
        code: INSURE_COMPANY_MANAGE_MENU_CODE,
        meta: { title: '保司列表' },
        children: [{
            path: 'list',
            name: 'InsureCompanyList',
            component: () => import('./InsureCompanyList'),
            code: INSURE_COMPANY_LIST_MENU_CODE,
            meta: {
                title: '保司列表',
                breadcrumb: false
            },
            hidden: true
        }
        ]
    }]
}]

export default routes
