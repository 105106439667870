import { getVm } from '@/utils/vm'
import moment from 'moment'

import getInsuranceTypeListService from '@service/workSpace/getInsuranceTypeListService'
import getInsureCompanyListService from '@service/insureCompany/getInsureCompanyListService'

export default [
    {
        hideInTable: true,
        title: '险种',
        dataIndex: 'dangerPlantedId',
        valueType: 'select',
        initialValue: '',
        fieldProps: {
            asyncData: async () => {
                try {
                    const data = await getInsuranceTypeListService()
                    return [
                        {
                            label: '全部',
                            value: ''
                        }, ...data.map(({ id, name }) => ({
                            label: name,
                            value: id
                        }))
                    ]
                } catch (e) {
                    getVm().$message.error(e.message)
                }
            }
        }
    },
    {
        hideInTable: true,
        title: '保单状态',
        dataIndex: 'state',
        valueType: 'select',
        fieldProps: {
            options: [{
                label: '全部',
                value: ''
            },
            {
                label: '保险中',
                value: '保险中'
            },
            {
                label: '待生效',
                value: '待生效'
            },
            {
                label: '待续保',
                value: '待续保'
            },
            {
                label: '保险退保',
                value: '保险退保'
            }]
        }
    },
    {
        hideInTable: true,
        title: '保险公司',
        dataIndex: 'insuranceEnterpriseId',
        valueType: 'select',
        initialValue: '',
        fieldProps: {
            asyncData: async () => {
                try {
                    const data = await getInsureCompanyListService()
                    return [
                        {
                            label: '全部',
                            value: ''
                        }, ...data.map(({ id, name }) => ({
                            label: name,
                            value: id
                        }))
                    ]
                } catch (e) {
                    getVm().$message.error(e.message)
                }
            }
        }
    },
    {
        hideInTable: true,
        title: '保单号',
        dataIndex: 'policyNo'
    },
    {
        hideInTable: true,
        title: '投保人',
        dataIndex: 'castEntepriseNameLike'
    },
    {
        hideInTable: true,
        title: '被保人',
        dataIndex: 'insuredEnterpriseNameLike'
    },
    {
        hideInTable: true,
        title: '项目名称',
        dataIndex: 'projectNameLike'
    },
    {
        hideInTable: true,
        dataIndex: 'insuranceTime',
        valueType: 'selectDataRange',
        isExpansion: true,
        span: 12,
        labelCol: { span: 4 },
        wrapperCol: { span: 24 },
        fieldProps: {
            dateSelect: [{
                value: 1,
                label: '签单日期'
            }, {
                value: 2,
                label: '保险起期'
            }, {
                value: 3,
                label: '保险止期'
            }],
            showTime: { defaultValue: [moment('2022-01-01 00:00:00'), moment('2022-01-01 23:59:59')] },
            format: 'YYYY-MM-DD HH:mm:ss',
            disabledDate: () => false
        }
    },
    {
        hideInSearch: true,
        title: '保单号',
        dataIndex: 'policyNo',
        width: 200
    },
    {
        hideInSearch: true,
        title: '保单类型',
        dataIndex: 'type',
        key: 'type',
        width: 220
    },
    {
        hideInSearch: true,
        title: '平台系统',
        dataIndex: 'externalSystemName',
        key: 'externalSystemName',
        width: 220
    },
    {
        hideInSearch: true,
        title: '签单日期',
        dataIndex: 'singleDateTime',
        width: 180
    },
    {
        hideInSearch: true,
        title: '险种',
        dataIndex: 'dangerPlantedName',
        width: 180
    },
    {
        hideInSearch: true,
        title: '投保人',
        dataIndex: 'castEntepriseName',
        width: 180
    },
    {
        hideInSearch: true,
        title: '被保人',
        dataIndex: 'insuredEnterpriseName',
        width: 180
    },
    {
        hideInSearch: true,
        title: '保险金额（元）',
        dataIndex: 'insuranceMoney',
        width: 180
    },
    {
        hideInSearch: true,
        title: '保费（元）',
        dataIndex: 'payMoney',
        width: 180
    },
    {
        hideInSearch: true,
        title: '项目名称',
        dataIndex: 'projectName',
        width: 180
    },
    {
        hideInSearch: true,
        title: '保险公司',
        dataIndex: 'insuranceEnterpriseName',
        width: 180
    },
    {
        hideInSearch: true,
        title: '保险期间',
        dataIndex: 'startInsuranceTime',
        width: 180,
        scopedSlots: { customRender: 'startInsuranceTime' }
    },
    {
        hideInSearch: true,
        title: '经纪人',
        dataIndex: 'dealHousekeeperName',
        customRender: (text, record) => text ? `${text}(${record.agentName})` : '-',
        width: 180
    },
    {
        hideInSearch: true,
        title: '保单状态',
        dataIndex: 'state',
        width: 180
    },
    {
        hideInSearch: true,
        title: '操作',
        key: 'action',
        scopedSlots: { customRender: 'action' },

        width: 100,
        fixed: 'right'
    }
]
