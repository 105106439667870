<!-- 保单详情 -->
<template>
    <custom-drawer
        title="保单详情"
        v-bind="$attrs"
        :after-visible-change="afterVisibleChange"
        placement="right"
        :loading="loading"
        v-on="$listeners"
    >
        <div slot="top" class="status">
            <img :src="stateImg">
            <p>当前保单状态：{{ getStateName(detail.insuranceStatus) }}</p>
        </div>
        <div slot="content" key="基本信息" class="content-body">
            <custom-descriptions bordered :value="detail" :options="baseOptions" />
        </div>
        <div slot="content"  key="保障内容" class="content-body">
            <custom-descriptions bordered :value="detail" :options="assureOptions" />
            <a-button v-if="detail.insuranceSingleUrl" v-auth="HIGHSEAS_CUSTOMER_VIEW_POLICY_BUTTON" class="ePolicyBtn" @click="handleOpenEPolicy">
                查看电子保单
            </a-button>
            <a-button v-else disabled icon="clock-circle" class="ePolicyBtn">
                保单生成中…
            </a-button>
        </div>
        <div v-if="projectOptions.length" slot="content" key="项目信息" class="content-body">
            <custom-descriptions bordered :value="detail" :options="projectOptions" />
        </div>
        <div v-if="insuredOptions.length" slot="content" key="被保人" class="content-body">
            <custom-descriptions bordered :value="detail" :options="insuredOptions" />
        </div>
        <div v-if="policyholderOptions.length" slot="content" key="投保人" class="content-body">
            <custom-descriptions bordered :value="detail" :options="policyholderOptions" />
        </div>
    </custom-drawer>
</template>
<script>
import CustomDescriptions from '@/weights/CustomDescriptions'
import getCastManageInsuranceDetailService from '@service/customer/getCastManageInsuranceDetailService'
import { getLabelformList } from '@/utils'
import { POLICY_ORDER_STATUS_ENUM_LIST, ORDER_SOURCE_ENUM_LIST, PRESERVATION_STATE_MENU_LIST, POLICY_ORDER_STATUS_SURRENDER, POLICY_ORDER_STATUS_INSURANCE, POLICY_ORDER_STATUS_RENEWED } from '@/constant/enum'
import CustomDrawer from '@/weights/CustomDrawer'
import moment from 'moment'
import fileType from '@/mixins/fileType'

// 工具类
import { previewFiles } from '@/utils/previewFiles'

import { HIGHSEAS_CUSTOMER_VIEW_POLICY_BUTTON } from '@/constant/authEnum/HighSeasCustomer'

export default {
    name: 'PolicyRecordDetail',
    components: {
        CustomDrawer,
        CustomDescriptions
    },
    mixins: [fileType],
    props: {
        detailId: {
            type: null,
            default: null
        }
    },
    data() {
        return {
            HIGHSEAS_CUSTOMER_VIEW_POLICY_BUTTON,
            loading: false,
            detail: { },
            baseOptions: [
                {
                    title: '保单号',
                    key: 'policyNo'
                },
                {
                    title: '渠道',
                    key: 'source',
                    customRender: getLabelformList(ORDER_SOURCE_ENUM_LIST)
                },
                {
                    title: '签单日期',
                    key: 'signingTime'
                },
                {
                    title: '投保账号',
                    key: 'castInsuranceUserName',
                    customRender: () => this.detail.castInsuranceUserId && `${this.detail.castInsuranceUserName}(${this.detail.castInsuranceUserId})`
                },
                {
                    title: '成交经纪人',
                    key: 'agentUserName',
                    customRender: () => this.detail.agentUserId && `${this.detail.agentUserName}(${this.detail.agentUserId})`
                },
                {
                    title: '业务助理',
                    key: 'businessAssistantName',
                    customRender: () => this.detail.businessAssistantId && `${this.detail.businessAssistantName}(${this.detail.businessAssistantId})`
                },
                {
                    title: '退保操作日期',
                    key: 'refundModifyDateTime'
                },
                {
                    title: '退保金额',
                    key: 'refundMoney'
                },
                {
                    title: '退保日期',
                    key: 'refundTime'
                },
                {
                    title: '退保状态',
                    key: 'refundState',
                    customRender: getLabelformList(PRESERVATION_STATE_MENU_LIST)
                }
            ],
            assureOptions: [
                {
                    title: '产品名称',
                    key: 'spuName'
                },
                {
                    title: '险种',
                    key: 'dangerPlantedName'
                },
                {
                    title: '保险公司',
                    key: 'insuranceCompany'
                },
                {
                    title: '保险金额（保函金额）元',
                    key: 'insuranceMoney'
                },
                {
                    title: '保费（元）',
                    key: 'payMoney'
                },
                {
                    title: '保险期间',
                    key: 'dateRange'
                }
            ],
            projectOptions: [],
            insuredOptions: [],
            policyholderOptions: []
        }
    },
    computed: {
        stateImg() {
            switch (this.detail.state) {
                case POLICY_ORDER_STATUS_SURRENDER: // 保险退保
                    return require('@/assets/images/error.png')
                case POLICY_ORDER_STATUS_INSURANCE: // 保险中
                    return require('@/assets/images/insurance.png')
                case POLICY_ORDER_STATUS_RENEWED: // 待续保
                    return require('@/assets/images/renewed.png')
                default: // 待生效
                    return require('@/assets/images/waiting.png')
            }
        }
    },
    methods: {
        // 获取保单状态中文显示
        getStateName: getLabelformList(POLICY_ORDER_STATUS_ENUM_LIST),
        async init() {
            this.loading = true
            try {
                // 详情接口
                const data = await getCastManageInsuranceDetailService({ castId: this.detailId }) || {}

                const detail = {
                    ...data,
                    dateRange: `自${data.startInsuranceTime ? moment(data.startInsuranceTime).format('YYYY-MM-DD') : ''}零时起 至 ${data.endInsuranceTime ? moment(data.endInsuranceTime).format('YYYY-MM-DD') : ''}二十四时止`
                }
                // 投保人动态表单
                this.policyholderOptions = data?.contentNewVo?.castEnterpriseText?.map(item => ({
                    title: item.key,
                    value: item.value
                })) || []
                // 被保人动态表单
                this.insuredOptions = data?.contentNewVo?.insuredOptions?.map(item => ({
                    title: item.key,
                    value: item.value
                })) || []
                // 项目信息动态表单
                this.projectOptions = data?.contentNewVo?.proEnterpriseText?.map(item => ({
                    title: item.key,
                    value: item.value
                })) || []
                this.detail = detail || {}
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.loading = false
            }
        },
        afterVisibleChange(visible) {
            if (visible) {
                this.init()
            } else {
                Object.assign(this, {
                    detail: {},
                    projectOptions: [],
                    insuredOptions: [],
                    policyholderOptions: []
                })
            }
        },
        // 查看电子保单
        handleOpenEPolicy() {
            const url = this.detail?.insuranceSingleUrl
            if (url) {
                let type = this.formatterFileType(url)
                previewFiles(type, url)
            } else {
                this.$message.error('文件错误')
            }
        }
    }
}
</script>
<style lang="scss" scoped>
@import "../common";

.ePolicyBtn {
    margin-top: 8px;
}
</style>


