<!-- 产品列表 -->
<template>
    <list-page-layout class="customer-list-page">
        <!-- 选则分类 start -->
        <product-type
            :total="total"
            :insurance-list.sync="insuranceList"
            :insurance-company-list="insuranceCompanyList"
            @search="handleChange"
        />
        <!-- 选则分类 end -->

        <!-- 产品 start -->
        <product-list
            v-show="!dataMissed"
            ref="productList"
            :products.sync="productList"
            :corporation-channel-id="corporationChannelId"
            :total="total"
            :limit="limit"
            :current="current"
            :loading="loading"
            :commission-data="commissionData"
            :commission-area-search-data="commissionAreaSearchData"
            @on-pagination-change="handleListChange" />
        <!-- 产品 end -->

        <!-- 搜索-无结果 start -->
        <data-missed v-show="dataMissed" :keywords="keywords" />
    </list-page-layout>
</template>

<script>
// 组件
import ListPageLayout from '@layout/ListPageLayout'
import ProductType from '@/weights/Product/ProductList/ProductType'
import ProductList from '@/weights/Product/ProductList/ProductList'
import DataMissed from '@/weights/Product/ProductList/DataMissed'
import getIndividualCommissionService from '@service/workSpace/getIndividualCommissionService'

// 全局数据
import { DEFAULT_PAGES_SIZE } from '@/config'

// 接口请求
import getInsuranceListService from '@/service/product/getInsuranceListService'
import getInsuranceCompanyListService from '@/service/product/getInsuranceCompanyListService'
import getProductListService from '@/service/product/getProductListService'
import getChannelSpuidService from '@/service/product/getChannelSpuidService'


export default {
    name: 'ProductListPage',
    components: {
        ListPageLayout,
        ProductType,
        ProductList,
        DataMissed
    },
    data() {
        return {
            params: {}, // 条件参数（暂存）
            // productInfoAreaParams: {}, // 产品详情页-地区参数
            loading: false, // 加载状态
            // insuranceTypeList: [], // 险种分类 选项列表
            insuranceList: [], // 险种 选项列表
            insuranceCompanyList: [], // 保险公司 选项列表
            productList: [], // 产品列表
            total: 0, // 列表-总记录数
            limit: DEFAULT_PAGES_SIZE, // 列表-分页数量
            current: 1, // 列表-当前索引
            commissionVisibility: false, // 个人佣金是否可见状态
            commissionData: {}, // 单条个人佣金数据
            idIn: '1', // 默认无渠道，传1 无数据
            commissionAreaSearchData: {} // 佣金范围地区是否选择的判断obj
        }
    },
    computed: {
        dataMissed() {
            // 是否搜素出结果
            return this.productList.length === 0
        },
        keywords() {
            // 搜索-关键字
            return this.$route.query.keywords || ''
        },
        // 用户信息缓存
        userInfo() {
            return this.$store.getters.userInfo
        },
        // 渠道id
        corporationChannelId() {
            return this.$store.getters.loginBgImgLogoImgInfo &&
            this.$store.getters.loginBgImgLogoImgInfo.id || ''
        }
    },
    watch: {
        corporationChannelId: {
            handler(val) {
                if (val) this.getChannelSpuid()
            },
            immediate: true
        }
    },
    created() {
        this.getIndividualCommission()
    },
    methods: {
        /**
         * 获取产品id
         */
        async getChannelSpuid() {
            try {
                this.statisticsLoading = true
                const { userInfo } = this
                const { id } = userInfo
                const data = await getChannelSpuidService({
                    userId: id,
                    corporationChannelId: this.corporationChannelId
                })
                this.idIn = data || '1'
                this.getData()
            } catch (e) {
                this.$message.error(e.message)
            } finally {
            }
        },
        /**
         * 获取产品列表数据
         */
        async getData() {
            // 获取险种 选项列表
            const insuranceList = getInsuranceListService({ propaganda: false })
            // 获取保险公司 选项列表
            const insuranceCompanyList = getInsuranceCompanyListService()

            // 获取产品
            let param = {
                current: 1,
                size: DEFAULT_PAGES_SIZE,
                dangerPlantedCategoryId: '',
                dangerPlantedId: '',
                query: '',
                areaIsNull: true,
                idIn: this.idIn,
                notShelves: true
            }
            const { total, records } = await getProductListService(param)

            const update = {
                insuranceList: await insuranceList || [],
                insuranceCompanyList: await insuranceCompanyList || [],
                total: total || 0,
                productList: records || []
            }

            update.insuranceList.unshift({
                id: '',
                name: '全部'
            })
            update.insuranceCompanyList.unshift({
                id: '',
                name: '全部'
            })
            Object.assign(this, update)
        },
        async getProductList(params = {}, init = true) {
            /**
             * 获取产品列表
             * @param {Object} params 上方 选择分类 选择的险种分类、险种、保险公司
             * @param {Boolean} init: true 切换分类则分页设置为第一页
             */
            try {
                this.loading = true
                const { current, limit } = this

                const _params = {
                    ...params,
                    current: init ? 1 : current,
                    size: limit,
                    areaIsNull: true,
                    idIn: this.idIn,
                    notShelves: true
                }
                const { total, records } = await getProductListService(_params)

                Object.assign(this, {
                    total: total || 0,
                    productList: records || []
                })
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.loading = false
            }
        },
        async getIndividualCommission() {
            try {
                this.statisticsLoading = true
                const { userInfo } = this
                const { id } = userInfo
                const data = await getIndividualCommissionService({ userId: id })
                if (data) {
                    let { commissionVisibility } = data
                    this.commissionVisibility = commissionVisibility || false
                    this.commissionData = data
                }
            } catch (e) {
                this.$message.error(e.message)
            } finally {
            }
        },
        handleChange(params) {
            this.current = 1
            // 响应 product type change 事件
            this.params = params
            this.getProductList(params)

            // 如果筛选了地址且选择到区的情况下，还需要请求地址对应的佣金范围
            if (params.areaCode) {

                this.commissionAreaSearchData = {
                    provinceCode: params.provinceCode,
                    cityCode: params.cityCode,
                    areaCode: params.areaCode
                }

            } else {

                this.commissionAreaSearchData = {}
            }

        },
        handleListChange({ page, pageSize }) {

            // 响应 table change 事件
            Object.assign(this, {
                current: page,
                limit: pageSize
            })
            this.getProductList(this.params, false)
        }
    }

}
</script>

<style lang="scss">
.product-breadcrumb {
    height: 44px;
    line-height: 44px;
}
</style>
