import { BASE_URL } from '@config'

/** 基础查询接口
 * ————————————————————————————————————————————————————————————————————————————————————————————*/

/**
 * 用户登录接口
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const GET_OAUTH_TOKEN = {
    url: `${BASE_URL}/user/login`,
    method: 'post',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 判断用户手机号/验证码是否匹配
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const AUTH_USER = {
    url: `${BASE_URL}/user/personClassify/authUser`,
    method: 'post',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 更新用户信息
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const UPDATE_USER_INFO = {
    url: `${BASE_URL}/user/personClassify/updateUserInfo`,
    method: 'put',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 更新用户信息--新
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const UPDATE_USER_INFO_NEW = {
    url: `${BASE_URL}/user/user/update`,
    method: 'put',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 获取用户信息
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const GET_USER_INFO = {
    url: `${BASE_URL}/user/getUserInfo`,
    method: 'post',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}


/**
 * 获取用户权限
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const GET_USER_PERMISSION = {
    url: `${BASE_URL}/user/getUserPermission`,
    method: 'post',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}


/**
 * 注销
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const USER_LOG_OUT = {
    url: `${BASE_URL}/user/logOut`,
    method: 'get',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}


/**
 * 根据AppCode查询用户组列表
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const GET_USER_GROUP_LIST_BY_USER_TYPE_CODE = {
    url: `${BASE_URL}/customer/potential-customer/queryStewardByUserTypeCode`,
    method: 'get',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 获取列表过滤下拉管家列表
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const GET_STEWARD_LIST = {
    url: `${BASE_URL}/customer/potential-customer/getStewardList`,
    method: 'get',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 分配管家
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const DISPENSE_STEWARD = {
    url: `${BASE_URL}/customer/potential-customer/allotSteward`,
    method: 'get',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 线索来源枚举
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const CLUE_SOURCE_ENUM_LIST = {
    url: `${BASE_URL}/customer/potential-customer-source/select`,
    method: 'get',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 校验身份
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const UPDATE_USER_VERIFY_IDENTITY = {
    url: `${BASE_URL}/user/userQuery/verifyIdentity`,
    method: 'post',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 修改密码
 * @type {{}}
 */
export const UPDATE_PASSWORD = {
    url: `${BASE_URL}/user/user/password`,
    method: 'put',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 获取省市区-联合-缓存
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const GET_PROVINCE_REDIS_LIST = {
    url: `${BASE_URL}/product/province/selectRedisProvince`,
    method: 'get',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}


/**
 * 获取指定地址二维码
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const GET_QRCODE_LINK_ADDRESS_IMG = {
    url: `${BASE_URL}/customer/promote-form/getQrCodeLinkAddress`,
    method: 'get',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}



/**
 * 获取登录背景图和登录后的logo图
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const GET_CORPORATION_CHANNEL_INFO = {
    url: `${BASE_URL}/product/corporation-channel/selectOne`,
    method: 'get',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/** 阿里云oss 接口 sts模式
 * ————————————————————————————————————————————————————————————————————————————————————————————*/
/**
 * 阿里云oss-获取token sts
 * @type {{}}
 */
export const GET_OSS_TOKEN_STS = {
    url: `${BASE_URL}/common/oss/sts`,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/** 阿里云oss 接口 host
 * ————————————————————————————————————————————————————————————————————————————————————————————*/
/**
 * 阿里云oss-获取host
 * @type {{}}
 */
export const GET_OSS_ADDRESS = {
    url: `${BASE_URL}/common/oss/address`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
