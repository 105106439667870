/**
 * 客户 模块
 */
import BasicLayout from '@layout/BasicLayout'
import RouteLayout from '@layout/RouteLayout'

import {
    BROKER_MENU_CODE,
    BROKER_MANAGE_MENU_CODE,
    BROKER_LIST_MENU_CODE
} from '@constant/menu_code'

const routes = [{
    path: '/broker',
    name: 'Broker',
    redirect: '/broker',
    component: BasicLayout,
    code: BROKER_MENU_CODE,
    sort: 3,
    meta: {
        title: '经纪人管理',
        icon: 'C-jingjirenguanli',
        iconType: 'iconfont'
    },
    children: [{
        path: 'broker-manage',
        redirect: '/broker/broker-manage/list',
        name: 'BrokerManage',
        component: RouteLayout,
        code: BROKER_MANAGE_MENU_CODE,
        meta: { title: '经纪人列表' },
        children: [{
            path: 'list',
            name: 'BrokerManageList',
            hidden: true,
            component: () => import('./BrokerList/index'),
            code: BROKER_LIST_MENU_CODE,
            meta: {
                title: '经纪人列表',
                breadcrumb: false
            }
        }

        ]
    }]
}]

export default routes
