<template>
    <list-page-layout>
        <pro-table ref="tableRef"
                   row-key="id"
                   title="推广获客"
                   :scroll="{ x: '100%' }"
                   :columns="columns"
                   :request="getList"
                   class="promote-list__warp"
        >
            <!-- 表单标题 -->
            <template slot="imgTitle" slot-scope="{record}">
                <div class="promote-list__table-panel-title">
                    <img
                        :src="record.promoteFormWechat && record.promoteFormWechat.icon || titleAdornImg"
                        alt=""
                        class="promote-list__table-panel-title-icon"
                    >
                    <span class="promote-list__table-panel-title-text"> {{ record.title }}</span>
                </div>
            </template>
            <template #action="{record}">
                <template>
                    <a-button
                        v-auth="PROMOTE_GUEST_COPY_URL_BUTTON"
                        type="link"
                        class="copy-link"
                        @click="handleCopyLink(record)"
                    >
                        复制链接
                    </a-button>
                </template>
                <a-divider type="vertical" />
                <template>
                    <preview-q-r-code-popover :record="record" />
                </template>
            </template>
        </pro-table>
    </list-page-layout>
</template>

<script>
// 组件
import { Divider as ADivider } from 'ant-design-vue'
import ListPageLayout from '@layout/ListPageLayout'
import ProTable from '@components/ProTable'
import PreviewQRCodePopover from './PreviewQRCodePopover'

// table配置
import columns from './columns'

// 工具类
import { copyText } from '@/utils/copyText'

import {
    PROMOTE_GUEST_COPY_URL_BUTTON
} from '@constant/authEnum/promoteGuest'

// 请求
import getPromoteFormSelectListService from '@service/promoteQuest/getPromoteFormSelectListService'

export default {
    name: 'PromoteGuestList',
    components: {
        ADivider,
        ProTable,
        ListPageLayout,
        PreviewQRCodePopover
    },
    data() {
        return {
            PROMOTE_GUEST_COPY_URL_BUTTON,
            columns,
            titleAdornImg: require('@/assets/images/promoteQuest/empty.png')
        }
    },
    computed: {
        userInfo() {
            return this.$store.getters.userInfo
        }
    },
    methods: {
        /**
         * 获取推广获客列表
         * @param params
         * @returns {Promise<{total, data}>}
         */
        async getList(params) {

            const { pageSize, current, ...otherParams } = params

            const { total, records } = await getPromoteFormSelectListService({
                current,
                size: pageSize,
                ...otherParams
            })

            return {
                data: records,
                total
            }

        },
        /**
         * 复制链接
         * @param records
         */
        handleCopyLink(records) {
            const { id, name } = this.userInfo
            const url = `${records.linkAddress}&agentUserName=${name}&agentUserId=${id}`
            if (records.linkAddress) {
                copyText(encodeURI(url))
                this.$message.success('复制成功！')
            } else {
                this.$message.error('地址为空')
            }
        }
    }
}
</script>

<style lang="scss">
.promote-list{
    &__warp {
        .header-panel-wrap {
            h3 {
                line-height: 32px;
            }
        }
    }
  &__table-panel-title{
    display: flex;
    align-items: center;
    &-icon {
      display:block;
      width:60px;
      height:60px
    }
    &-text  {
      margin-left: 12px;
    }
  }
}
.copy-link.ant-btn {
    padding: 0px;
}
</style>
