import axios from '@service'
import {ASSOCIATION_EDIT} from './api'
import qs from 'qs'
import {HTTP_ERR_SUCCESS, HTTP_RESULT_FIELD, HTTP_ERR_MESSAGE_FIELD} from '@/constant/api_status'
import {ERROR_DEFAULT_HTTP_REQUEST_FAIL} from '@/constant/error'

/**
 * 业务关联编辑
 * @param payload
 * @param config
 * @returns {Promise<*>}
 */
export default async function associationUpdateService(payload = {}, config = {}) {
    const param = {...ASSOCIATION_EDIT, ...config, data: qs.stringify(payload)}
    const {data} = await axios(param)
    if (data.code === HTTP_ERR_SUCCESS) {
        return data[HTTP_RESULT_FIELD]
    } else {
        throw new Error(data[HTTP_ERR_MESSAGE_FIELD] || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}