import { BASE_URL } from '@config'

/** 客户跟进/客户维护
 * ————————————————————————————————————————————————————————————————————————————————————————————*/

/**
 * 订单-操作日志
 *
 */
export const SEARCH_ORDER = {
    url: `${BASE_URL}/order/order/findOrderInfoById`,
    method: 'get',
    headers: {
        'content-type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/**
 * 保单记录-保单详情
 *
 */
export const SEARCH_POLICY = {
    url: `${BASE_URL}/insurance/insurance-policy-integrating/selectOne`,
    method: 'get',
    headers: {
        'content-type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/**
 * 保单记录-保单列表
 *
 */
export const GET_POLICY_LIST = {
    url: `${BASE_URL}/insurance/insurance-policy-integrating/selectCustomer`,
    method: 'get',
    headers: {
        'content-type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/**
 * 判断经纪人类型
 *
 */
export const SEARCH_TEAM_USER = {
    url: `${BASE_URL}/user/team-user/selectOne`,
    method: 'get',
    headers: {
        'content-type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/**
 * 批量判断经纪人类型
 *
 */
export const SEARCH_TEAM_USER_BATCH = {
    url: `${BASE_URL}/user/team-user/selectListByUserIdList`,
    method: 'post',
    headers: {
        'content-type': 'application/json'
    },
    timeout: 30000
}

