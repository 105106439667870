<script src="../../service/queryStewardByUserTypeCodeService.js"></script>
<template>
    <!--  用户组列表-->
    <a-select
        v-model="user"
        show-search
        placeholder="请选择"
        @blur="handleBlur"
        @change="handleChange"
        label-in-value
    >
        <a-select-option
            v-for="item in dataList"
            :key="item.userId"
            :value="item.userId"
            :label="item.name"
        >{{ item.name }}</a-select-option>
    </a-select>
</template>

<script>
// 组件
import { Select as ASelect } from 'ant-design-vue'

// 全局数据
import {
    STEWARD_LIST_TYPE_CUSTOMER_CLUE, // 客户线索
    STEWARD_LIST_TYPE_BROKER_CLUE, // 经纪人线索
    STEWARD_LIST_TYPE_CUSTOMER_LIST, // 客户列表
    STEWARD_LIST_TYPE_BROKER_LIST, // 经纪人列表
    STEWARD_TYPE_BUSINESS, // 业务管家
    STEWARD_TYPE_SERVE, // 服务管家
    STEWARD_TYPE_INTERNET, // 互联网管家
    STEWARD_TYPE_COMMERICAL, // 商务管家
    STEWARD_TYPE_BUSINESS_ADMIN, // 业务管家主管
    STEWARD_TYPE_INTERNET_ADMIN, // 互联网管家主管
    STEWARD_TYPE_COMMERICAL_ADMIN, // 商务管家主管
    STEWARD_TYPE_SERVE_ADMIN // 服务管家主管
} from '@constant/enum'

// 接口请求
import queryStewardByUserTypeCodeService from '@service/queryStewardByUserTypeCodeService'

// 管家下拉配置，根据列表来显示下来
const STEWARD_CONFIG = {
    // 客户线索
    [STEWARD_LIST_TYPE_CUSTOMER_CLUE]: [
        STEWARD_TYPE_BUSINESS,
        STEWARD_TYPE_BUSINESS_ADMIN,
        STEWARD_TYPE_INTERNET,
        STEWARD_TYPE_INTERNET_ADMIN,
        STEWARD_TYPE_COMMERICAL,
        STEWARD_TYPE_COMMERICAL_ADMIN
    ],
    // 经纪人线索
    [STEWARD_LIST_TYPE_BROKER_CLUE]: [
        STEWARD_TYPE_BUSINESS,
        STEWARD_TYPE_BUSINESS_ADMIN,
        STEWARD_TYPE_INTERNET,
        STEWARD_TYPE_INTERNET_ADMIN
    ],
    // 客户列表
    [STEWARD_LIST_TYPE_CUSTOMER_LIST]: [
        STEWARD_TYPE_BUSINESS,
        STEWARD_TYPE_BUSINESS_ADMIN,
        STEWARD_TYPE_SERVE,
        STEWARD_TYPE_SERVE_ADMIN,
        STEWARD_TYPE_INTERNET,
        STEWARD_TYPE_INTERNET_ADMIN
    ],
    // 经纪人列表
    [STEWARD_LIST_TYPE_BROKER_LIST]: [
        STEWARD_TYPE_BUSINESS,
        STEWARD_TYPE_BUSINESS_ADMIN,
        STEWARD_TYPE_SERVE,
        STEWARD_TYPE_SERVE_ADMIN,
        STEWARD_TYPE_INTERNET,
        STEWARD_TYPE_INTERNET_ADMIN
    ]
}

export default {
    name: 'UserGroup',
    props: {
        value: {
            type: Object,
            default() {
                return {}
            }
        },
        businessType: {
            // 业务类型，区分客户线索，经纪人线索、客户管理、经纪人管理
            type: [String, Number],
            default() {
                return ''
            }
        }
    },
    components: {
        ASelect,
        ASelectOption: ASelect.Option
    },
    data() {
        return {
            dataList: []
        }
    },
    created() {
        this.getList()
    },
    computed: {
        user: {
            get() {
                return this.value || undefined
            },
            set(val) {
                this.$emit('input', val)
            }
        }
    },
    methods: {
        // 选择的name
        handleChange(val) {
            this.$emit('change', val)
        },
        handleBlur() {
            this.$emit('blur')
        },
        async getList() {
            try {
                let res = await queryStewardByUserTypeCodeService()

                // 数据处理，根据不同列表展示不同组的管家
                const { businessType } = this // 业务类型
                const stewardConfig = STEWARD_CONFIG[businessType] || [] // 根据业务类型匹配管家配置
                let dataList = []
                // 匹配组
                res = res.filter(item => {
                    return stewardConfig.includes(item.userTypeValueCode)
                })
                // 获取组中用户
                res.map(group => {
                    const userList = group.userBasicInfoVOList || []
                    userList.map(user => {
                        user.name = user.name || '-'
                        dataList.push(user)
                    })
                })
                // 去重
                const maps = new Map();
                dataList = dataList.filter((a) =>{
                    if(a.userId&&!maps.has(a.userId)){
                        maps.set(a.userId, 1)
                        return  true
                    }
                    return false
                })

                this.dataList = dataList || []
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            }
        }
    }
}
</script>

<style lang='scss'>
</style>