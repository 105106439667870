<template>
    <search-panel :model="form" layout="vertical" @search="handleSearch" @refresh="handleRefresh">
        <form-col label="签单日期" prop="signDate">
            <template :slot="formColDefaultSlot">
                <a-select v-model="signDate" placeholder="请选择">
                    <template v-for="item in signDateList">
                        <a-select-option :key="item.value" :value="item.value">{{ item.label }}</a-select-option>
                    </template>
                </a-select>
            </template>
        </form-col>
        <form-col
            v-if="signDate===SIGNING_DATE_THIS_CUSTOM"
            :span="12"
            label="请选择"
            prop="signDateSelect"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
        >
            <template :slot="formColDefaultSlot">
                <a-range-picker
                    v-model="signDateSelect"
                    :mode="signDateSelectMode"
                    :show-time="showTimeOptions"
                />
            </template>
        </form-col>
        <form-col label="成员姓名" prop="name">
            <template :slot="formColDefaultSlot">
                <a-select
                    v-model.trim="personalName"
                    show-search
                    placeholder="请输入关键字选择成员"
                    option-filter-prop="children"
                    :filter-option="false"
                    :allow-clear="true"
                    :show-arrow="false"
                    @change="handleNameChange"
                    @search="getNameSelect"
                >
                    <a-spin v-if="personalLoading" slot="notFoundContent" size="small" type="loading" />
                    <a-select-option v-for="item in personalSelectList" :key="item.userId" :value="`${item.userName} （${item.userId}）`">
                        {{ item.userName || '-' }} （{{ item.userId }}）
                    </a-select-option>
                </a-select>
            </template>
        </form-col>
        <form-col label="团队名称" prop="teamName">
            <template :slot="formColDefaultSlot">
                <a-select
                    v-model.trim="teamName"
                    show-search
                    placeholder="请输入关键字选择团队"
                    option-filter-prop="children"
                    :filter-option="false"
                    :allow-clear="true"
                    :show-arrow="false"
                    @change="handleTeamChange"
                    @search="getTeamSelect"
                >
                    <a-spin v-if="teamLoading" slot="notFoundContent" size="small" type="loading" />
                    <a-select-option v-for="item in teamSelectList" :key="item.id" :value="item.name">
                        {{ item.name }}
                    </a-select-option>
                </a-select>
            </template>
        </form-col>
    </search-panel>
</template>

<script>
import debounce from 'lodash/debounce'
// 组件
import {
    Select as ASelect,
    DatePicker as ADatePicker
} from 'ant-design-vue'
import SearchPanel from '@components/BaseSearchPanel'
import FormCol from '@components/FormCol'

// 全局数据
import {
    SIGNING_DATE_TYPE_ENUM_LIST,
    SIGNING_DATE_THIS_MONTH,
    SIGNING_DATE_THIS_CUSTOM,
    SIGNING_DATE_TODAY,
    SIGNING_DATE_THIS_YEAR
} from '@constant/enum'

// 工具类
import { filterParams } from '@utils/search.js'
import moment from 'moment'

// 接口请求
// import getTeamUserSelectListService from '@/service/customer/getTeamUserSelectListService'
// import getTeamNameListService from '@/service/workSpace/teamMange/getTeamNameListService'
import getTeamNameAuthListService from '@/service/workSpace/teamMange/getTeamNameAuthListService'
import getTeamAuthBrokerListService from '@/service/workSpace/policyMange/getTeamAuthBrokerListService'

export default {
    name: 'CustomerSearchPanel',
    components: {
        SearchPanel,
        FormCol,
        ASelect,
        ASelectOption: ASelect.Option,
        ARangePicker: ADatePicker.RangePicker
    },
    data() {
        this.getTeamSelect = debounce(this.getTeamSelect, 500)
        this.getNameSelect = debounce(this.getNameSelect, 500)
        return {
            SIGNING_DATE_THIS_CUSTOM,
            submitTimeMode: ['date', 'date'],
            form: {},
            // 签单日期
            signDate: SIGNING_DATE_THIS_MONTH,
            // 签单日期自定义
            signDateSelect: [],
            // 签单时间
            signDateSelectMode: ['date', 'date'],
            showTimeOptions: {
                defaultValue: [
                    moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')
                ]
            },
            signDateList: SIGNING_DATE_TYPE_ENUM_LIST,
            labelCol: {
                span: 4
            },
            wrapperCol: {
                span: 20
            },
            // 成员姓名搜索数据
            personalLoading: false,
            personalSelectList: [],
            personalName: undefined,
            // 团队名称搜索数据
            teamLoading: false,
            teamSelectList: [],
            teamName: undefined,
            // 筛选-插槽
            formColDefaultSlot: 'formElements'
        }
    },
    computed: {
        // 用户信息缓存
        userInfo() {
            return this.$store.getters.userInfo
        }
    },
    watch: {
        signDate: {
            handler(val) {
                if (val !== SIGNING_DATE_THIS_CUSTOM) {
                    this.signDateSelect = []
                    this.getFormTime()
                    this.handleSearch()
                } else {
                    this.getFormTime()
                }
            },
            // immediate: true
        },
        signDateSelect: {
            handler(val) {
                this.signDateSelect = val
                this.getFormTime()
            },
            immediate: true
        }
    },
    mounted() {
        this.init()
        this.getNameSelect()
        this.getTeamSelect()
    },
    methods: {
        moment,
        init() {
            const { form, signDate } = this
            this.$emit('get-init', filterParams(form), signDate)
        },
        /**
         * 获取人员名称数据
         */
        async getNameSelect(userNameQuery) {
            const { userInfo } = this
            const { id } = userInfo || {}
            // if (!userNameQuery) {
            //     this.personalSelectList = []
            //     this.personalLoading = false
            //     return false
            // }
            try {
                this.personalLoading = true
                const data = await getTeamAuthBrokerListService({
                    authUserId: id,
                    teamUserNameQuery: userNameQuery
                })
                this.personalSelectList = data || []
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.personalLoading = false
            }
        },
        handleNameChange(value) {
            if (!value) {
                this.getNameSelect()
            } else {
                let splitValue = value && value.split(' ')[0]
                this.getNameSelect(splitValue)
            }
            this.getNameId(value)
        },
        /**
         * 根据name获取id
         */
        getNameId(value) {
            const { personalSelectList } = this
            let splitValue = value && value.split(' ')[0]
            let splitValueId = value && value.split(' ')[1]
            let result = personalSelectList.find(item => {
                return item.userName === splitValue && `（${item.userId}）` === splitValueId
            })
            this.form.teamUserId = result && result.userId || ''
        },
        /**
         * 获取团队数据
         */
        async getTeamSelect(nameQuery) {
            const { userInfo } = this
            const { id } = userInfo || {}
            // if (!nameQuery) {
            //     this.teamSelectList = []
            //     this.teamLoading = false
            //     return false
            // }
            let queryParams = {}
            if (nameQuery) {
                queryParams = {
                    nameQuery,
                    teamLevelQuery: true
                }
            } else {
                queryParams = {
                    nameQuery
                }
            }
            try {
                this.teamLoading = true
                const data = await getTeamNameAuthListService({
                    authUserId: id,
                    ...queryParams
                })
                this.teamSelectList = data || []
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.teamLoading = false
            }
        },
        handleTeamChange(value) {
            if (!value) {
                this.getTeamSelect()
            } else {
                this.getTeamSelect(value)
            }
            this.getId(value)
        },
        /**
         * 根据name获取id
         */
        getId(value) {
            const { teamSelectList } = this
            let result = teamSelectList.find(item => {
                return item.name === value
            })
            this.form.teamId = result && result.id || ''
        },
        /**
         * 响应搜索事件
         */
        handleSearch() {
            const { form, signDate } = this
            const { signingStartDate, signingEndDate, teamId } = form
            if (!signingStartDate || !signingEndDate) {
                this.$message.error('请选择自定义起止时间')
                return false
            }
            if (teamId) {
                this.form.teamLevelQuery = true
            } else {
                this.form.teamLevelQuery = ''
            }
            this.$emit('search', filterParams(form), signDate)
        },
        /**
         * 响应刷新事件
         */
        handleRefresh() {
            this.resetData()
            const { form, signDate } = this
            this.$emit('refresh', filterParams(form), signDate)
        },
        /**
         * 
         * 刷新置空数据
         */
        resetData() {
            this.teamName = undefined
            this.form.teamId = ''
            this.personalName = undefined
            this.form.teamUserId = ''
            this.signDate = SIGNING_DATE_THIS_MONTH
            // this.teamSelectList = []
            // this.personalSelectList = []
            this.signDateSelect = []
            this.getFormTime()
            this.form.teamLevelQuery = ''
            this.getNameSelect()
            this.getTeamSelect()
        },
        /**
         * 获取时间
         */
        getTime() {
            const { signDate, signDateSelect } = this
            let date = new Date()
            let monthFirstDay = new Date(date.getFullYear(), date.getMonth(), 1)
            let monthLastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
            let year = date.getFullYear()
            let [startTime, endTime] = signDateSelect || []
            switch (signDate) {
                case SIGNING_DATE_TODAY: // 今天
                    return {
                        signingStartDate: moment(date).format('YYYY-MM-DD 00:00:00'),
                        signingEndDate: moment(date).format('YYYY-MM-DD 23:59:59')
                    }
                case SIGNING_DATE_THIS_YEAR: // 今年
                    return {
                        signingStartDate: `${year}-01-01 00:00:00`,
                        signingEndDate: `${year}-12-31 23:59:59`,
                    }
                case SIGNING_DATE_THIS_CUSTOM: // 自定义
                    return {
                        signingStartDate: startTime ? `${moment(startTime).format('YYYY-MM-DD HH:mm:ss')}` : '',
                        signingEndDate: endTime ? `${moment(endTime).format('YYYY-MM-DD HH:mm:ss')}` : ''
                    }
                default: // 默认当月
                    return {
                        signingStartDate: moment(monthFirstDay).format('YYYY-MM-DD 00:00:00'),
                        signingEndDate: moment(monthLastDay).format('YYYY-MM-DD 23:59:59')
                    }
            }
        },
        getFormTime() {
            let { signingStartDate, signingEndDate } = this.getTime() || {}
            this.form.signingStartDate = signingStartDate
            this.form.signingEndDate = signingEndDate
        }
    }
}
</script>

<style lang="scss">
</style>
