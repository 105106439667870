/**
 * 管家 模块
 */
import BasicLayout from '@layout/BasicLayout'
import RouteLayout from '@layout/RouteLayout'

import {
    STEWARD_MENU_CODE,
    STEWARD_MANAGE_MENU_CODE,
    STEWARD_LIST_MENU_CODE,

    // 业务关联列表
    BUSINESS_ASSOCIATION_MENU_CODE,
    BUSINESS_ASSOCIATION_LIST_MENU_CODE

} from '@constant/menu_code'

const routes = [{
    path: '/steward',
    name: 'Steward',
    // redirect: '/clue',
    component: BasicLayout,
    code: STEWARD_MENU_CODE,
    sort: 6,
    meta: {
        title: '管家管理',
        icon: 'C-guanjiaguanli',
        iconType: 'iconfont'
    },
    children: [
        {
            path: 'steward-manage',
            redirect: '/steward/steward-manage/list',
            name: 'StewardManage',
            component: RouteLayout,
            code: STEWARD_MANAGE_MENU_CODE,
            meta: { title: '管家列表' },
            children: [
                {
                    path: 'list',
                    name: 'StewardList',
                    component: () => import('./StewardList'),
                    code: STEWARD_LIST_MENU_CODE,
                    meta: {
                        title: '管家列表',
                        breadcrumb: false
                    },
                    hidden: true
                }
            ]
        }, {
            path: 'business-association',
            redirect: '/steward/business-association/list',
            name: 'BusinessAssociation',
            component: RouteLayout,
            code: BUSINESS_ASSOCIATION_MENU_CODE,
            meta: { title: '业务关联列表' },
            children: [
                {
                    path: 'list',
                    name: 'AssociationList',
                    component: () => import('./AssociationList'),
                    code: BUSINESS_ASSOCIATION_LIST_MENU_CODE,
                    meta: {
                        title: '业务关联列表',
                        breadcrumb: false
                    },
                    hidden: true
                }
            ]
        }
    ]
}]

export default routes
