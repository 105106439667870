<template>
  <a-tabs :default-active-key="defaultActiveKey" @change="handleTabsChange" class="component-tabs-panel">
    <a-tab-pane
        v-for="item in tabsData"
        :key="item.key"
        :tab="item.tab"/>
  </a-tabs>
</template>

<script>
import {Tabs as ATabs} from 'ant-design-vue'

export default {
  name: "TabsPanel",
  props:{
    tabsData:{
      type:Array,
      default(){
        return []
      }
    },
    defaultActiveKey:{
      type:String,
      default(){
        return ''
      }
    },
  },
  components: {
    ATabs,
    ATabPane: ATabs.TabPane,
  },
  methods:{
    handleTabsChange(key){
      this.$emit('handle-tabs-change',key)
    }
  }
}
</script>

<style lang="scss">
    @import "@assets/styles/varibles.scss";

    .component-tabs-panel {
        margin-bottom: 9px;
        .ant-tabs-bar{
            margin: 0;
            background-color: $component-background;
            border-top: 1px dashed #EBEDF0;
            border-bottom: none;
            .ant-tabs-nav-container {
                margin-bottom: 0;
                .ant-tabs-nav-wrap {
                    margin-bottom: 0;
                    .ant-tabs-tab {
                        margin: 0 36px 0 24px;
                        padding: 13px 0;
                        font-size: $font-sizeFifteen;
                        color: $custom-button-default-text-color;
                    }
                    .ant-tabs-tab-active {
                        color: $base-color;
                        border-bottom: 3px solid $base-color;
                    }
                    .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
                        display: none !important;
                    }
                }
            }
        }
    }
</style>
