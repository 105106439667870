import {
    BASE_URL
} from '@config'

/** 管家
 * ————————————————————————————————————————————————————————————————————————————————————————————*/
/**
 * 管家列表
 *
 */
export const GET_STEWARD_LIST = {
    url: `${BASE_URL}/customer/potential-customer/queryStewardByPage`,
    method: 'get',
    headers: {
        'content-type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}