<!-- 客户详情-头部 -->
<template>
    <a-spin :spinning="loading">
        <div class="customer-base-header">
            <img v-if="detail && detail.avatarUrl" :src="detail && detail.avatarUrl">
            <img v-else src="~@assets/images/avatar.png">
            <div class="customer-base-header__content">
                <div>
                    <div class="customer-base-header__info">
                        <div class="customer-base-header__info-person">
                            <p class="customer-base-header__info-mobile">{{ detail && detail.customerMobile || '' }}</p>
                            <p class="customer-base-header__info-oth">
                                <span v-if="detail && detail.userName" class="customer-base-header__info-oth-name">{{ detail && detail.userName }}</span>
                                <a-tag v-if="detail && detail.state == 1" class="customer-base-header__info-oth-has">
                                    已注册
                                </a-tag>
                                <a-tag v-else class="customer-base-header__info-oth-null">
                                    未注册
                                </a-tag>
                            </p>
                        </div>
                        <div class="customer-base-header__more">
                            <a-descriptions v-bind="$attrs" :column="3">
                                <a-descriptions-item v-for="item in baseInfo" :key="item.key"  :label="item.key">
                                    {{ empty(item.value) }}
                                </a-descriptions-item>
                            </a-descriptions>
                        </div>
                    </div>
                    <div class="customer-base-header__tags">
                        <a-tag v-if="detail && detail.label" class="customer-base-header__tags-content tag-common">
                            <span class="customer-base-header__tags-text">{{ detail && detail.label }}</span>
                        </a-tag>
                        <a-tag v-if="detail && detail.intentionValue" class="customer-base-header__tags-content tag-common">
                            <span class="customer-base-header__tags-text">客户意向：{{ detail && detail.intentionValue }}</span>
                        </a-tag>
                        <a-tag v-if="detail && detail.customerStage" class="customer-base-header__tags-content tag-common">
                            <span class="customer-base-header__tags-text">{{ detail && detail.customerStage }}</span>
                        </a-tag>
                    </div>
                </div>
            </div>
        </div>
    </a-spin>
</template>

<script>
// 组件
import { Tag as ATag, Icon as AIcon, Descriptions as ADescriptions } from 'ant-design-vue'

// 常量
import { SALES_CATEGORY_DISTRIBUTION } from '@/constant/enum'

// mixins
import empty from '@mixins/empty'

export default {
    name: 'BaseHeader',
    components: {
        ATag,
        AIcon,
        ADescriptions,
        ADescriptionsItem: ADescriptions.Item
    },
    mixins: [empty],
    props: {
        detail: {
            type: Object,
            default() {
                return {}
            }
        },
        businessType: {
            type: [Number, String],
            default() {
                return SALES_CATEGORY_DISTRIBUTION
            }
        },
        loading: {
            type: Boolean,
            default() {
                return false
            }
        }
    },
    data() {
        return {
            SALES_CATEGORY_DISTRIBUTION,
            baseInfo: []
        }
    },
    computed: {},
    watch: {
        detail: {
            handler(detail) {
                if (!detail) return
                this.init()
            },
            immediate: true
        }
    },
    mounted() {},
    methods: {
        /**
             * 初始化
             */
        init() {
            const { detail } = this
            const {
                customerCreateDateTime,
                customerSourceName,
                userCreateDateTime,
                userSource
            } = detail || {}
            this.baseInfo = [
                {
                    key: '客户创建时间',
                    value: this.$formatDateTime(customerCreateDateTime)
                },
                {
                    key: '客户来源',
                    value: customerSourceName
                },
                {
                    key: '经纪人',
                    value: this.handleAgent(detail)
                },
                {
                    key: '注册时间',
                    value: userCreateDateTime
                },
                {
                    key: '注册来源',
                    value: userSource
                }
            ]
        },
        /**
             * 经纪人
             */
        handleAgent() {
            const { businessType, detail } = this
            const {
                associatedAgentUserName,
                associatedAgentUserId,
                allotAgentUser,
                allotAgentUserId
            } = detail
            let name = ''
            let id = ''

            // 分销
            if (businessType === SALES_CATEGORY_DISTRIBUTION) {
                name = associatedAgentUserName
                id = associatedAgentUserId
            } else {
                // 自营
                name = allotAgentUser
                id = allotAgentUserId
            }

            return `${name ? name : '-'}${id ? '(' + id + ')' : ''}`
        }
    }
}
</script>

<style lang="scss">
    @import '@assets/styles/varibles.scss';

    .customer-base-header {
        padding: 24px 24px 36px;
        background: #fff;
        display: flex;

        img {
            width: 80px;
            height: 80px;
            margin-right: 24px;
            border-radius: 50%;
            box-shadow: 0px 0px 8px 0px #F2F2F2;
            border: 4px solid #FFFFFF;
        }

        &__info {
            display: flex;
            justify-content: space-between;
            min-height: 80px;
            height: auto;
            padding-top: 14px;
            &-person {
                flex: none;
                margin-right: 96px;
            }
            &-mobile {
                margin-bottom: 10px;
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: bold;
                color: #323233;
                line-height: 20px;
            }
            &-oth {
                display: flex;
                margin: 0;

                &-name {
                    margin-right: 16px;
                    color: #646566;
                    font-weight: bold;
                }

                &-has {
                    background-color: #EDFFED;
                    color: #0B8E2B;
                    border: none;
                    border-radius: 2px;
                }

                &-null {
                    background-color: #FFF1F0;
                    color: #E6125F;
                    border: none;
                    border-radius: 2px;
                }
            }
        }

        &__more {
            .ant-descriptions-row > th,
            .ant-descriptions-row > td {
                padding-bottom: 10px;
            }
            .ant-descriptions-item-label {
                color: #848B91;
            }
            .ant-descriptions-item-content {
                color: #323233;
            }
        }

        &__tags {
            &-content {
                padding: 6px 12px;
                background-color: #F2FBFF;
                border: none;
                border-radius: $font-size;
                color: #1079C5;
                font-size: $font-size;
                .intention-value-icon {
                    margin-right: 4px;
                }
            }
            .tag-common {
                background-color: #FFF7E6;
                color: #EF7400;
            }
        }
    }
</style>
