/**
 * 企业 模块
 */
import BasicLayout from '@layout/BasicLayout'
import RouteLayout from '@layout/RouteLayout'

import {
    ENTERPRISE_MENU_CODE,
    ENTERPRISE_MANAGE_MENU_CODE,
    ENTERPRISE_LIST_MENU_CODE,
    ENTERPRISE_AUTHORIZATION_MENU_CODE,
    ENTERPRISE_AUTHORIZATION_LIST_MENU_CODE,
    ENTERPRISE_AUTHORIZATION_DETAIL_MENU_CODE,
    ENTERPRISE_AUTHORIZATION_ADD_MENU_CODE,
    ENTERPRISE_AUTHORIZATION_PRODUCT_LIST_MENU_CODE
} from '@constant/menu_code'

const routes = [{
    path: '/enterprise',
    name: 'Enterprise',
    // redirect: '/clue',
    component: BasicLayout,
    code: ENTERPRISE_MENU_CODE,
    sort: 4,
    meta: {
        title: '企业管理',
        icon: 'C-qiyeguanli',
        iconType: 'iconfont'
    },
    children: [{
        path: 'enterprise-manage',
        redirect: '/enterprise/enterprise-manage/list',
        name: 'EnterpriseManage',
        component: RouteLayout,
        code: ENTERPRISE_MANAGE_MENU_CODE,
        meta: { title: '企业列表' },
        children: [{
            path: 'list',
            name: 'EnterpriseList',
            component: () => import('./EnterpriseList'),
            code: ENTERPRISE_LIST_MENU_CODE,
            meta: {
                title: '企业列表',
                breadcrumb: false
            },
            hidden: true
        }
        ]
    }, {
        path: 'enterprise-authorization',
        redirect: '/enterprise/enterprise-authorization/list',
        name: 'EnterpriseAuthorization',
        component: RouteLayout,
        code: ENTERPRISE_AUTHORIZATION_MENU_CODE,
        meta: { title: '企业认证' },
        children: [
            {
                path: 'list',
                name: 'EnterpriseAuthorizationList',
                hidden: true,
                component: () => import('./EnterpriseAuthorization/EnterpriseAuthorizationList'),
                code: ENTERPRISE_AUTHORIZATION_LIST_MENU_CODE,
                meta: {
                    title: '企业认证列表',
                    breadcrumb: false
                    // keepAlive: true
                }
            }, {
                path: 'detail',
                name: 'EnterpriseAuthorizationDetail',
                hidden: true,
                component: () => import('./EnterpriseAuthorization/EnterpriseAuthorizationInfo'),
                code: ENTERPRISE_AUTHORIZATION_DETAIL_MENU_CODE,
                meta: {
                    title: '企业认证详情',
                    breadcrumb: true
                }
            }, {
                path: 'enterprise-authorization-add',
                name: 'EnterpriseAuthorizationAdd',
                hidden: true,
                component: () => import('./EnterpriseAuthorization/EnterpriseAuthorizationAdd'),
                code: ENTERPRISE_AUTHORIZATION_ADD_MENU_CODE,
                meta: { title: '新增企业认证' }
            }, {
                path: 'productlist',
                name: 'EnterpriseAuthorizationProductList',
                component: () => import('./EnterpriseAuthorization/EnterpriseAuthorizationProductList/index'),
                hidden: true,
                code: ENTERPRISE_AUTHORIZATION_PRODUCT_LIST_MENU_CODE,
                meta: {
                    title: '产品列表',
                    breadcrumb: true
                },
                children: []
            }
        ]
    }]
}]

export default routes
