<!-- 可搜索输入框 -->
<template>
    <div class="search-input-wrap">
        <a-select
            allow-clear
            show-search
            label-in-value
            :value="realValue"
            :placeholder="placeholder"
            style="width: 100%;"
            :filter-option="false"
            :not-found-content="fetching ? undefined : null"
            @search="handleSearch"
            @change="handleChange"
        >
            <a-spin v-if="fetching" slot="notFoundContent" size="small" />
            <a-select-option v-for="item in dataSource" v-else :key="item.value">
                {{ getContent(item) }}
            </a-select-option>
        </a-select>
    </div>
</template>

<script>
import {
    Select as ASelect
} from 'ant-design-vue'

export default {
    name: 'SearchInput',
    components: {
        ASelect,
        ASelectOption: ASelect.Option
    },
    props: {
        value: { type: [String, Number] },
        disabled: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: '请选择'
        },
        onSearch: {
            type: Function,
            default: null
        },
        isALL: {
            type: Boolean,
            default: true
        },
        getContent: {
            type: Function,
            default: item => item.label
        }
    },
    data() {
        return {
            realValue: undefined,
            fetching: false,
            dataSource: [],
            timer: null
        }
    },
    watch: {
        value: {
            handler(val) {
                if (!val) {
                    // 清空的时候要调一次
                    this.isALL && this.handleSearch()
                    this.realValue = undefined
                    this.dataSource = []
                }
            }
        }
    },
    created() {
        this.handleSearch()
    },
    methods: {
        // 模糊查询
        handleSearch(value) {
            // 不存在onSearch不执行
            if (!this.onSearch) {
                return
            }
            if (!value) {
                this.dataSource = []
                return
            }
            const { timer } = this
            if (timer) {
                clearTimeout(timer)
            }
            this.timer = setTimeout(async () => {
                // 根据输入框内容搜索查询
                this.fetching = true
                try {
                    this.dataSource = await this.onSearch(value)
                } catch (e) {
                    this.$message.error(e.message)
                } finally {
                    this.fetching = false
                }

            }, 300)
        },
        // 选中
        handleChange(item) {
            this.realValue = item
            this.$emit('input', item?.key)
            this.$emit('change', item?.key)
        }
    }

}
</script>
<style lang='scss'>
.search-input-wrap {
    // display: flex;

    .ant-select {
        display: inline-block;

        // width: 60%;
        flex: 1;
    }
}
</style>
