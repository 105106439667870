<!-- 业务关联列表搜索组件 -->
<template>
    <search-panel
        :model="form"
        layout="vertical"
        @search="handleSearch"
        @refresh="handleRefresh"
    >
        <form-col label="业务助理" prop="assistantId">
            <template :slot="formColDefaultSlot">
                <a-select v-model="form.assistantId" placeholder="请选择业务助理">
                    <a-select-option value="">全部</a-select-option>
                    <template v-for="item in assistantList">
                        <a-select-option :key="item.assistantId" :value="item.assistantId">
                           {{ item.assistantRealName }}
                       </a-select-option>
                    </template>
                </a-select>
            </template>
        </form-col>
    </search-panel>
</template>

<script>
// 组件
import {
    Select as ASelect,
} from 'ant-design-vue'
import SearchPanel from '@components/SearchPanel'
import FormCol from '@components/FormCol'

// 工具类
import { filterParams } from '@utils/search.js'

// 请求接口
import getAssociationAccountSelectService from '@service/steward/businessAssociation/getAssociationAccountSelectService'
export default {
    name: 'AssociationListSearchPanel',
    data() {
        return {
            form: {
                assistantId: '',
            },
            // 业务助理列表
            assistantList: [],
            // 筛选-插槽
            formColDefaultSlot: 'formElements'
        }
    },
    computed: {},
    mounted() {
        this.getAssistantList()
    },
    methods: {
        /**
         * 获取业务助理
         */
        async getAssistantList() {
            const data = await getAssociationAccountSelectService()
            this.assistantList = data || []
        },
        /**
         * 响应搜索事件
         */
        handleSearch() {
            const { form } = this
            let { ...params } = form
            this.$emit('search', filterParams(params))
        },
        /**
         * 响应刷新事件
         */
        handleRefresh() {
            const { form } = this
            let { ...params } = form
            this.$emit('refresh', filterParams(params))
        },
    },
    components: {
        ASelect,
        ASelectOption: ASelect.Option,
        SearchPanel,
        FormCol,
    }
}
</script>

<style lang='scss'>
</style>
