<!-- 发票详情 -->
<template>
    <div>
        <custom-drawer
            title="发票详情"
            v-bind="$attrs"
            :after-visible-change="afterVisibleChange"
            placement="right"
            :loading="loading"
            v-on="$listeners"
        >
            <div slot="top" class="status">
                <img :src="stateImg">
                <p>当前发票状态：{{ getStateName(detail.state) }}</p>
            </div>
            <div slot="content" key="发票信息" class="content-body">
                <custom-descriptions bordered :value="detail" :options="billOptions" />
                <a-button v-if="detail.invoiceAddress" v-auth="HIGHSEAS_CUSTOMER_ELECTRONIC_INVOICE_BUTTON" class="eBillBtn" @click="handleOpenEBill">
                    查看电子发票
                </a-button>
                <a-button v-if="detail.courierCoding" class="expBtn" @click="handleOpenExp">
                    查看快递信息
                </a-button>
            </div>
            <div slot="content"  key="保单信息" class="content-body">
                <custom-descriptions bordered :value="detail" :options="insuranceOptions" />
            </div>
        </custom-drawer>
        <custom-modal
            :width="560"
            title="快递信息"
            :visible="visible"
            @close="handleCloseExp"
        >
            <template slot="customFooter">
                <div style="display: flex; justify-content: flex-end;">
                    <a-button @click="handleCloseExp">关闭</a-button>
                </div>
            </template>
            <custom-descriptions :column="1" bordered :value="detail" :options="billModalOptions" />
        </custom-modal>
    </div>
</template>
<script>
import CustomDescriptions from '@/weights/CustomDescriptions'
import getCustomerCastManageListDetailService from '@service/customer/getCustomerCastManageListDetailService'
import { getLabelformList } from '@/utils'
import { INVOICE_STATE_MENU_LIST, INVOICE_ORDER_TYPE_ENUM_LIST, INVOICE_STATE_REFUSE, INVOICE_STATE_FINISHED } from '@/constant/enum'
import CustomDrawer from '@/weights/CustomDrawer'
import CustomModal from '@/components/CustomModal'
import fileType from '@/mixins/fileType'

// 工具类
import { previewFiles } from '@/utils/previewFiles'

import { HIGHSEAS_CUSTOMER_ELECTRONIC_INVOICE_BUTTON } from '@/constant/authEnum/HighSeasCustomer'

export default {
    name: 'OrderRecordDetail',
    components: {
        CustomDrawer,
        CustomModal,
        CustomDescriptions
    },
    mixins: [fileType],
    props: {
        detailId: {
            type: null,
            default: null
        }
    },
    data() {
        return {
            HIGHSEAS_CUSTOMER_ELECTRONIC_INVOICE_BUTTON,
            loading: false,
            visible: false,
            detail: { },
            billModalOptions: [
                {
                    title: '快递公司',
                    key: 'courierEnterprise'
                }, {
                    title: '快递单号',
                    key: 'courierCoding'
                }
            ],
            billOptions: [
                {
                    title: '申请时间',
                    key: 'applyTime'
                },
                {
                    title: '发票类型',
                    key: 'invoiceType',
                    customRender: getLabelformList(INVOICE_ORDER_TYPE_ENUM_LIST)
                },
                {
                    title: '发票抬头',
                    key: 'invoiceLookedUp'
                },
                {
                    title: '发票税号',
                    key: 'invoiceTaxCoding'
                },
                {
                    title: '收件人',
                    key: 'receiverName'
                },
                {
                    title: '电话',
                    key: 'receiverMobile'
                },
                {
                    title: '收件地址',
                    key: 'receiverAddress'
                }
            ],
            insuranceOptions: [
                {
                    title: '保单号',
                    key: 'insuranceNo',
                    click: this.handleOpenPolicyDetail
                },
                {
                    title: '签单时间',
                    key: 'signingTime'
                },
                {
                    title: '保险公司',
                    key: 'insuranceCompany'
                },
                {
                    title: '险种',
                    key: 'dangerPlantedName'
                },
                {
                    title: '保险金额（保函金额）',
                    key: 'insuranceMoney'
                },
                {
                    title: '保费（元）',
                    key: 'payMoney'
                }
            ]
        }
    },
    computed: {
        stateImg() {
            switch (this.detail.state) {
                case INVOICE_STATE_REFUSE: // 拒绝发票
                    return require('@/assets/images/error.png')
                case INVOICE_STATE_FINISHED: // 发票完成
                    return require('@/assets/images/success.png')
                default:
                    return require('@/assets/images/waiting.png')
            }
        }
    },
    methods: {
        // 获取发票状态中文显示
        getStateName: getLabelformList(INVOICE_STATE_MENU_LIST),
        async init() {
            this.loading = true
            try {
                // 详情接口
                const data = await getCustomerCastManageListDetailService({ castInsuranceId: this.detailId }) || {}
                this.detail = data || {}
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.loading = false
            }
        },
        afterVisibleChange(visible) {
            if (visible) {
                this.init()
            } else {
                this.detail = {}
            }
        },
        // 查看电子发票
        handleOpenEBill() {
            const url = this.detail?.insuranceSingleUrl
            if (url) {
                let type = this.formatterFileType(url)
                previewFiles(type, url)
            } else {
                this.$message.error('文件错误')
            }
        },
        // 查看快递
        handleOpenExp() {
            this.visible = true
        },
        // 关闭快递
        handleCloseExp() {
            this.visible = false
        },
        // 点击打开保单详情
        handleOpenPolicyDetail() {
            if (this.detail.insuranceNo) {
                this.toggleDetail({
                    key: 'PolicyRecordDetail',
                    toggle: true,
                    detailId: this.detail.insuranceNo
                })
            }
        }
    },
    inject: { toggleDetail: { default: () => {} } }
}
</script>

<style lang="scss" scope>
@import "../common";

.expBtn {
    margin-top: 8px;
}

.eBillBtn {
    margin-top: 8px;
    margin-right: 8px;
}
</style>


