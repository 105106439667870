<!-- 客户详情-线索记录 -->
<template>
    <list-page-layout>
        <!-- title -->
        <common-title title="客户线索" />
        <container-panel>
            <!-- 筛选 start -->
            <template #screen>
                <search-panel
                    slot="header"
                    :echo-data="params"
                    @search="handleSearch"
                    @refresh="handleRefresh" />
            </template>
            <!-- 筛选 end -->

            <!-- 表格 start -->
            <template #content>
                <a-table
                    size="middle"
                    :columns="columns"
                    :data-source="data"
                    :pagination="pagination"
                    :loading="loading"
                    row-key="id"
                    :scroll="scrollConfig"
                    @change="handleTableChange"
                >
                    <!-- 类型 -->
                    <template slot="sourceType" slot-scope="sourceType">
                        {{ getApiEnumLabelFromList(CUSTOMER_LEAD_SOURCE_TYPE_ENUM_LIST, sourceType) }}
                    </template>
                    <!-- 咨询内容 -->
                    <template slot="listTitleName" slot-scope="listTitleName, action">
                        {{ empty(listTitleName) }}
                        <a-tag v-if="false" class="lead-record-tab-panel__tags">
                            <span class="lead-record-tab-panel__tags-text">
                                {{ action.agentUserTypeLabel }}
                            </span>
                        </a-tag>
                    </template>
                    <!-- 联系手机 和经纪人有关系且customerId存在值则可以跳转 -->
                    <template slot="mobile" slot-scope="mobile, action">
                        <template v-if="isMobileLink(action)">
                            <a @click="handleJumpInsureCustomer(action)">
                                {{ mobile || '-' }}
                            </a>
                        </template>
                        <template v-else>
                            {{ mobile || '-' }}
                        </template>
                    </template>
                    <!-- 订单状态 -->
                    <template slot="state" slot-scope="state">
                        {{ getApiEnumLabelFromList(CUSTOMER_LEAD_ORDER_STATE_ENUM_LIST, state) }}
                    </template>
                    <!-- 操作 -->
                    <template slot="action" slot-scope="action">
                        <div>
                            <custom-button v-auth="CLIENT_CLEW_DETAIL_BUTTON" text="查看" type="link" @click="handleDetail(action)" />
                            <template v-if="handleIsShowGeneralCustomer(action)">
                                <a-divider type="vertical" />
                                <a-button
                                    v-auth="CLIENT_CLEW_GENERAL_CUSTOMER_BUTTON"
                                    type="link"
                                    @click="handleOpenModal(GENERAL_CUSTOMER, action)"
                                >
                                    生成客户
                                </a-button>
                            </template>
                        </div>
                    </template>
                </a-table>
            </template>
            <!-- 筛选 end -->

            <!-- 详情 start -->
            <lead-record-detail
                :id="id"
                :visible="visible"
                :customer-id="customerId"
                :agent-user-id="agentUserId"
                :is-related-agent="isRelatedAgent"
                @close="handleDetailClose"
            />
            <!-- 详情 end -->

            <!-- 弹框 start -->
            <cutomer-modal :loading="modalLoading"
                           :init-model-value="initModelValue"
                           :mode="mode"
                           @cancel="handleCancel"
                           @ok="handleOk"
                           @close="handleCancel" />
            <!-- 弹框 end -->
        </container-panel>
    </list-page-layout>
</template>

<script>

// 权限
import {
    PROPRIETARY_CUSTOMER_DETAIL_MENU_CODE,
    DISTRIBUTION_CUSTOMER_DETAIL_MENU_CODE
} from '@constant/menu_code'

// 常量
import { DEFAULT_PAGES_SIZE } from '@config'

// 组件
import { Tag as ATag } from 'ant-design-vue'
import CustomButton from '@components/Button'
import LeadRecordDetail from '@weights/Customer/Components/ComponentsDetail/Detail/LeadRecordDetail'
import SearchPanel from './SearchPanel'
import CutomerModal from './Modal'

// 常量
import { CUSTOMER_LEAD_ORDER_STATE_ENUM_LIST, SALES_CATEGORY_DISTRIBUTION, CUSTOMER_LEAD_SOURCE_TYPE_ENUM_LIST, CUSTOMER_LEAD_SOURCE_TYPE_FOUR, SALES_CATEGORY_USE_ENUM_LIST, SALES_CATEGORY_DISTRIBUTION_TEXT, SALES_CATEGORY_SELF_SUPPORT_TEXT } from '@/constant/enum'
import { MODE_EUMN } from './Modal/constant'
import { CLIENT_CLEW_DETAIL_BUTTON,
         CLIENT_CLEW_GENERAL_CUSTOMER_BUTTON } from '@/constant/authEnum/clew'
import { scrollConfig } from '@constant/listScrollConfig'

// 方法
import { getObjectLowKey } from '@/utils'
import hasPermission from '@/utils/hasPermission'

// mixins
import tableShowTotal from '@mixins/tableShowTotal'
import mixins from '@views/Customer/Components/mixins'
import enumOperate from '@mixins/enumOperate'
import isLoginUserId from '@mixins/isLoginUserId'
import empty from '@mixins/empty'
import searchTeamUser from '@/mixins/searchTeamUser'

// 接口请求
import getPotentialCustomerSelectListPageService from '@service/customer/getPotentialCustomerSelectListPageService'
import generateCustomerService from '@service/customer/generateCustomerService'

export default {
    name: 'ClientClewList',
    components: {
        ATag,
        CutomerModal,
        CustomButton,
        SearchPanel,
        LeadRecordDetail
    },
    mixins: [tableShowTotal,
        mixins,
        enumOperate,
        isLoginUserId,
        empty,
        searchTeamUser],
    data() {
        const columns = [
            {
                title: '服务单号',
                dataIndex: 'id',
                key: 'id',
                width: 200
            },
            {
                title: '提交时间',
                dataIndex: 'createDateTime',
                key: 'createDateTime',
                width: 200
            },
            {
                title: '类型',
                dataIndex: 'sourceType',
                key: 'sourceType',
                width: 200,
                scopedSlots: { customRender: 'sourceType' }
            },
            {
                title: '咨询内容',
                dataIndex: 'listTitleName',
                key: 'listTitleName',
                width: 200,
                ellipsis: true,
                scopedSlots: { customRender: 'listTitleName' }
            },
            {
                title: '企业名称',
                dataIndex: 'enterpriseName',
                key: 'enterpriseName',
                width: 200,
                customRender: text => this.empty(text)
            },
            {
                title: '地区',
                dataIndex: 'provinceCode',
                key: 'provinceCode',
                width: 200,
                customRender: (text, record) => {
                    const { provinceName, cityName, areaName } = record
                    const result = `${provinceName ? provinceName : ''}${cityName ? cityName : ''}${areaName ? areaName : ''}`

                    return this.empty(result)
                }
            },
            {
                title: '咨询人',
                dataIndex: 'name',
                key: 'name',
                width: 200,
                customRender: text => this.empty(text)
            },
            {
                title: '联系手机',
                dataIndex: 'mobile',
                key: 'mobile',
                width: 200,
                scopedSlots: { customRender: 'mobile' }

            },
            {
                title: '经纪人',
                dataIndex: 'agentUserName',
                key: 'agentUserName',
                width: 200
            },
            {
                title: '订单状态',
                dataIndex: 'state',
                width: 200,
                scopedSlots: { customRender: 'state' }
            },
            {
                title: '操作',
                // title: '<div style="min-width:60px;text-align:left"></div>',
                key: 'action',
                fixed: 'right',
                width: 90,
                align: 'left',
                scopedSlots: { customRender: 'action' }
            }
        ]

        return {
            ...MODE_EUMN,
            PROPRIETARY_CUSTOMER_DETAIL_MENU_CODE,
            DISTRIBUTION_CUSTOMER_DETAIL_MENU_CODE,
            CLIENT_CLEW_DETAIL_BUTTON,
            CLIENT_CLEW_GENERAL_CUSTOMER_BUTTON,
            CUSTOMER_LEAD_ORDER_STATE_ENUM_LIST,
            SALES_CATEGORY_DISTRIBUTION,
            CUSTOMER_LEAD_SOURCE_TYPE_ENUM_LIST,
            SALES_CATEGORY_DISTRIBUTION_TEXT,
            SALES_CATEGORY_SELF_SUPPORT_TEXT,
            columns,
            // 客户id
            customerId: '',
            // 查看-经纪人id
            agentUserId: '',
            // 经纪人id
            agentUserIdQuery: '',
            id: '',
            // 绑定关系
            isRelatedAgent: '',
            // 查询条件
            params: {},
            // 数据
            data: [],
            // 分页数量
            limit: DEFAULT_PAGES_SIZE,
            // 总记录数量
            total: 0,
            // 当前索引
            current: 1,
            // 加载状态
            loading: false,
            // 详情-抽屉
            visible: false,
            modalLoading: false,
            initModelValue: {},
            mode: null,
            // 当前登录用户的经纪人类型，用于判断是否展示生成客户
            currentUserAgentType: null,
            categoryList: SALES_CATEGORY_USE_ENUM_LIST,
            scrollConfig
        }
    },
    computed: {
        pagination() {
            const {
                total,
                limit,
                current,
                tableShowTotal
            } = this
            return {
                showTotal: (total, range) => tableShowTotal(total, range),
                current,
                total,
                pageSize: limit,
                showSizeChanger: true,
                showQuickJumper: true
            }
        },
        // 用户信息缓存
        userInfo() {
            return this.$store.getters.userInfo
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        /**
             * 获取 列表
             */
        async getList() {
            const { userInfo } = this
            const { id } = userInfo || {}
            try {
                this.loading = true
                const {
                    params,
                    current,
                    limit
                } = this
                const cluesPort = '4'
                const { total, records } = await getPotentialCustomerSelectListPageService({
                    current,
                    cluesPort,
                    size: limit,
                    ...params
                })
                this.total = total || 0
                this.data = records || []

                await this.judgeAgentType(id)
                this.currentUserAgentType = this.agentType

            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        },
        /**
         * 响应 table change 事件
         * @param pagination
         */
        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.current = current
            this.limit = pageSize
            this.getList()
        },
        /**
         * 搜索
         */
        handleSearch(params) {
            this.current = 1
            this.params = params
            this.getList()
            this.isResetSearch = false
        },
        /**
         * 刷新
         */
        handleRefresh(params) {
            this.handleSearch(params)
            this.isResetSearch = true
        },
        /**
             * 查看
             */
        handleDetail(action) {
            const { userInfo } = this
            const { id: userId } = userInfo || {}
            const {
                id,
                customerId,
                agentUserId,
                isRelatedAgent
            } = action

            this.customerId = customerId
            this.agentUserId = agentUserId || userId
            this.id = id
            this.isRelatedAgent = isRelatedAgent

            this.visible = true
        },
        /**
         * 线索-关闭
         */
        handleDetailClose() {
            this.visible = false
        },
        /**
         * 线索-判断是否显示[生成客户]按钮
         * 1）如果当前登录账号经纪人类型=分销：线索类型=G端保单 && 联系手机+经纪人未建立关系 && 当前登录账号=经纪人
         * 2）如果当前登录账号经纪人类型=自营：线索类型=G端保单 && 联系手机没有分配自营经纪人 &&联系手机+经纪人未建立关系 && 当前登录账号=经纪人
         * 判断：如果当前登录账号经纪人类型=分销  需要调接口channel/team-user/selectOne? 获取   "type" 销售类别（0：分销，1：自营）
         * 判断：当前登录账号=经纪人  userinfo里的 id === 线索里的 agentUserId
         * 判断：联系手机+经纪人未建立关系  线索里的 isRelatedAgent  是否和经纪人有关系 true：是   false：否
         * 判断：线索类型=G端保单 线索里的 sourceType === 4
         * 判断：联系手机没有分配自营经纪人 allocationAgent 是否为null或空字符串
         */
        handleIsShowGeneralCustomer(record = {}) {
            const { currentUserAgentType, userInfo } = this
            const { id } = userInfo || {}
            const { sourceType, isRelatedAgent, agentUserId, allocationAgent } = record

            // 线索类型=G端保单
            const isG = sourceType === CUSTOMER_LEAD_SOURCE_TYPE_FOUR
            // 联系手机+经纪人未建立关系
            const isPhoneAgentRelated = !isRelatedAgent
            // 当前登录账号=经纪人
            const isAgent = id === agentUserId
            // 联系手机没有分配自营经纪人
            const hasAgent = !allocationAgent || allocationAgent === ''

            if (currentUserAgentType === '分销') {
                return isG && isPhoneAgentRelated && isAgent
            } else if (currentUserAgentType === '自营') {
                return isG && isPhoneAgentRelated && isAgent && hasAgent
            } else {
                return false
            }
        },
        /**
         * 打开多种模式弹窗
         */
        async handleOpenModal(mode, record = {}) {
            switch (mode) {
                case MODE_EUMN.GENERAL_CUSTOMER:
                    this.initModelValue = {
                        descriptions: getObjectLowKey(record, ['userId',
                            'name',
                            'mobile',
                            'agentUserName']),
                        potentialCustomerId: record.id
                    }
                    break
                default:
                    this.initModelValue = {}
                    break
            }
            this.mode = mode
        },

        /**
         * 弹窗 关闭
         */
        handleCancel() {
            this.mode = null
        },

        /**
         * 弹窗 确认
         */
        async handleOk(form) {
            const { ...otherForm } = form
            let params = {}
            this.modalLoading = true
            try {
                switch (this.mode) {
                    // 生成客户
                    case MODE_EUMN.GENERAL_CUSTOMER:
                        params = { ...otherForm }
                        await generateCustomerService({ potentialCustomerId: params.potentialCustomerId })
                        break
                    default:
                        break
                }
                this.mode = null // 关闭弹窗
                this.$message.success('确认成功')
                this.getList()
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.modalLoading = false
            }
        },

        /**
         * 跳转投保用户
         */
        async handleJumpInsureCustomer(action) {
            const {
                userInfo,
                SALES_CATEGORY_DISTRIBUTION_TEXT,
                SALES_CATEGORY_SELF_SUPPORT_TEXT
            } = this
            let agentUserId = action && action.agentUserId || ''
            // 如果有归属经纪人 根据归属经纪人判断是自营分销 没有经纪人根据当前登录账户判断是自营还是分销
            // 前面已经请求过了，如果agentUserId有值，agentUserTypeLabel也返回了，无需再请求
            // let justUserId = agentUserId ? agentUserId : userInfo && userInfo.id
            // await this.judgeAgentType(justUserId)
            const { categoryList } = this
            // 用于区分是当前用户的经纪人类型还是归属经纪人类型，优先归属经纪人类型
            const agentType = agentUserId && action && action.agentUserTypeLabel || this.currentUserAgentType
            let result = categoryList.find(item => item.label === agentType)
            let businessType = result && result.value
            let name = ''
            // 如果有归属经纪人 传值归属经纪人id和投保账户id  无归属经纪人传当前登录账号id
            let query = null
            if (agentUserId) {
                query = {
                    customerId: action && action.customerId,
                    agentUserId,
                    businessType
                }
            } else {
                query = {
                    customerId: action && action.customerId,
                    agentUserId: userInfo && userInfo.id,
                    businessType
                }
            }

            // 页面类型
            // 分销
            if (agentType === SALES_CATEGORY_DISTRIBUTION_TEXT) {
                name = 'DistributionCustomerDetail'
            } else if (agentType === SALES_CATEGORY_SELF_SUPPORT_TEXT) {
                // 自营
                name = 'ProprietaryCustomerDetail'
            }

            this.$router.push({
                name,
                query
            })
        },
        goToDetailAction(record = {}) {
            const {
                SALES_CATEGORY_DISTRIBUTION_TEXT,
                SALES_CATEGORY_SELF_SUPPORT_TEXT
            } = this
            let agentUserId = record && record.agentUserId || ''
            // 用于区分是当前用户的经纪人类型还是归属经纪人类型，优先归属经纪人类型
            const agentType = agentUserId && record && record.agentUserTypeLabel || this.currentUserAgentType

            let hasPremission = false
            // 分销
            if (agentType === SALES_CATEGORY_DISTRIBUTION_TEXT) {
                hasPremission = hasPermission(this.$store, DISTRIBUTION_CUSTOMER_DETAIL_MENU_CODE)
            } else if (agentType === SALES_CATEGORY_SELF_SUPPORT_TEXT) {
                // 自营
                hasPremission = hasPermission(this.$store, PROPRIETARY_CUSTOMER_DETAIL_MENU_CODE)
            }
            return hasPremission
        },
        isMobileLink(record = {}) {
            return (
                this.goToDetailAction(record) &&
                record.isRelatedAgent &&
                record.customerId &&
                record.customerId !== ''
            )
        }
    }
}
</script>

<style lang="scss">
    @import '@assets/styles/varibles.scss';

    .lead-record-tab-panel {
        &__tags {
            padding: 1px 8px;
            background-color: #F2FBFF;
            border: none;
            border-radius: 2px;
            color: #1079C5;
            font-size: $font-sizeTwelve;
        }
    }

    .action-button-list {
    display: flex;
    align-items: center;
    min-width: 65px;
}
</style>
