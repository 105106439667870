/**
 * 保单 模块
 */
import BasicLayout from '@layout/BasicLayout'
import RouteLayout from '@layout/RouteLayout'

import {
    POLICY_MANAGE_MENU_CODE,
    POLICY_MANAGE_LIST_MENU_CODE
} from '@constant/menu_code'

const routes = [{
    path: '/policy',
    name: 'Policy',
    component: BasicLayout,
    redirect: '/policy/list',
    code: POLICY_MANAGE_MENU_CODE,
    sort: 8,
    meta: {
        title: '保单管理',
        icon: 'baodanguanli',
        iconType: 'iconfont'
    },
    children: [{
        path: 'list',
        name: 'PolicyManageList',
        component: () => import('./OnlinePolicyList'),
        hidden: true,
        code: POLICY_MANAGE_LIST_MENU_CODE,
        meta: {
            breadcrumb: false,
            keepAlive: true
        },
        children: []
    }]
}]

export default routes

