import { BASE_URL } from '@config'

/** 客户维护
 * ————————————————————————————————————————————————————————————————————————————————————————————*/
/**
 * 客户维护-列表
 *
 */
export const GET_PROPRIETARY_CUSTOMER_LIST = {
    url: `${BASE_URL}/customer/customer-agent/selectProprietaryList`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 客户维护-添加
 *
 */
 export const PROPRIETARY_CUSTOMER_SAVE = {
    url: `${BASE_URL}/customer/customer/saveProprietaryCustomer`,
    method: 'post',
    headers: { 'content-type': 'application/json' },
    timeout: 30000
}

/**
 * 客户维护-批量移入公海
 *
 */
 export const PROPRIETARY_BATCH_SEAS = {
    url: `${BASE_URL}/customer/customer-agent/batchAllotUntie`,
    method: 'post',
    headers: { 'content-type': 'application/json' },
    timeout: 30000
}

/**
 * 客户维护-移入公海
 *
 */
 export const PROPRIETARY_TRANSFER_SEAS = {
    url: `${BASE_URL}/customer/customer-agent/untieAgent`,
    method: 'post',
    headers: { 'content-type': 'application/json' },
    timeout: 30000
}

/**
 * 客户维护-转交客户
 *
 */
 export const PROPRIETARY_TRANSFER_CUSTOMER = {
    url: `${BASE_URL}/customer/customer-agent/transferAgent`,
    method: 'post',
    headers: { 'content-type': 'application/json' },
    timeout: 30000
}


/**
 * 客户维护-批量添加导入
 *
 */
export const PROPRIETARY_CUSTOMER_IMPORT = {
    url: `${BASE_URL}/customer/customer/importProprietaryCustomer`,
    method: 'post',
    headers: { 'content-type': 'application/json' },
    timeout: 30000
}
