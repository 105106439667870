<template>
    <div class="set-success-box">
        <img src="@/assets/images/user_action/success_icon.png"
             width="120"
             alt="icon">
        <div v-text="text" />
        <p v-text="description" />
        <a-button type="primary"
                  :loading="loading"
                  class="next-btn"
                  block
                  @click="handleReLogin">
            {{ reLoginTxt }}
        </a-button>
    </div>
</template>

<script>
import {
    Button as AButton
} from 'ant-design-vue'
import { RELOGIN_COUNTDOWN_TIME } from '@/config'

export default {
    name: 'SetAccountSetSuccess',

    components: { AButton },
    props: {
        text: {
            type: String,
            default: '密码重置成功'
        },

        description: {
            type: String,
            default: '请妥善保管您的账户信息'
        }
    },
    data() {
        return {
            loading: false,
            reLoginTxt: '重 新 登 录'
        }
    },
    // 生命周期 - 创建完成
    created() {

    },

    // DOM挂载完毕
    mounted() {
    },

    methods: {
        async handleReLogin() {
            try {
                this.loading = true
                await this.$store.dispatch('logOut')
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.loading = false
            }
        },

        countDown() {
            let time = RELOGIN_COUNTDOWN_TIME
            let timer = setInterval(() => {
                if (time === 0) {
                    clearInterval(timer)
                    this.handleReLogin()
                } else {
                    this.reLoginTxt = '重 新 登 录(' + time + ')'
                    time--
                }
            }, 1000)
        }
    }
}
</script>
<style lang='scss'>
.set-success-box {
    text-align: center;

    > img {
        margin-bottom: 17px;
    }

    > div {
        margin-bottom: 5px;
        font-size: 20px;
        color: #649d13;
        letter-spacing: 7px;
    }

    > p {
        margin-bottom: 32px;
        font-size: 14px;
        color: #969799;
    }

    .next-btn {
        height: 36px;
        font-size: 16px;
    }
}
</style>
