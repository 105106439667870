/**
 * 鉴权指令
 * @param Vue
 * @param options
 */
function install(Vue, options = {}) {

    Vue.directive(options.name || "auth", {
        inserted(el, bind, vNode) {
            // 获取 按钮权限
            const buttonRole = vNode.context.$store.getters.buttonRole || {}
            // 获取按钮值
            const roleName = bind.value
            // 判断是否有权限
            if (!buttonRole[roleName]) {
                el.remove()
            }
        },
    })
}

export default {install}

// import hasPermission from '../utils/hasPermission'

// /**
//  * 鉴权指令
//  * @param Vue
//  * @param options
//  */
// function install(Vue, options = {}) {
//     Vue.directive(options.name || "auth", {
//         inserted(el, bind, vNode) {
//             const roleName = bind.value
//             const store = vNode.context.$store

//             if (!hasPermission(store, roleName)) {
//                 el.remove()
//             }
//         }
//     })
// }

// export default { install }
