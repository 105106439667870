<!-- 跟进记录Tab -->
<template>
    <div>
        <pro-table
            ref="tableRef"
            row-key="id"
            :scroll="{ x: '100%' }"
            :columns="columns"
            :request="getList"
        />
    </div>
</template>
<script>
import ProTable from '@components/ProTable'
import getCustomerCommunicationSelectService from '@service/customer/getCustomerCommunicationSelectService'
import columns from './columns'

export default {
    name: 'FollowRecord',
    components: { ProTable },
    data() {
        return { columns }
    },
    computed: {
        customerId() {
            return this.$route.query.id
        }
    },
    methods: {
        // 获取列表数据
        async getList(params) {
            const { customerId } = this
            const { pageSize, current, dataTime = [], ...otherParams } = params
            const { total, records } = await getCustomerCommunicationSelectService({
                customerId,
                current,
                size: pageSize,
                dataTimeStart: this.$formatDateTime(dataTime[0]) || undefined,
                dateTimeEnd: this.$formatDateTime(dataTime[1]) || undefined,
                ...otherParams
            })
            return {
                data: records,
                total
            }
        },
        // 用于外部刷新
        refresh() {
            this.$refs.tableRef?.handleSearch?.()
        }
    }
}
</script>
