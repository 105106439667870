/**
 * 枚举操作相关方法
 */
export default {
    methods: {
        /**
         * 从枚举列表中获取字段拼接
         * @param list
         * @param field
         * @returns {(function(*=, *=): string)|(function(*=, *=): boolean)|string}
         */
        splitStringFromList(list = [], field) {
            let str = list.map(function (obj, index) {
                return obj[field]
            }).join(',')

            return str || ''
        },
        /**
         * 从枚举列表中获取描述字段
         * @param list
         * @param value
         * @returns {(function(*=, *=): boolean)|(function(*=, *=): boolean)|string}
         */
        getApiEnumLabelFromList(list = [], value) {
            for (let i = 0; i < list.length; i++) {
                const item = list[i]
                if (item.value === value) {
                    return item.label
                }
            }
        },
        getTypeEnumLabelFromList(list = [], value) {
            for (let i = 0; i < list.length; i++) {
                const item = list[i]
                if (item.type === value) {
                    return item.label
                }
            }
        },

        /**
         * 拼接列表中中文字段
         * @param list
         * @param value
         * @param separator
         * @returns {(function(*=, *=): boolean)|(function(*=, *=): boolean)|string}
         */
        getName(list = [], value, separator) {
            let newArr = list.map( item => item[value])
            let str = newArr.join(separator)
            return str
        },

        /**
         * 列表字段为空处理
         * @param list
         * @param value
         * @param separator
         * @returns {(function(*=, *=): boolean)|(function(*=, *=): boolean)|string}
         */
        getEmpty(list = [], separator) {
            list.forEach(item => {
                if (item && typeof item === 'object') {
                    for (let key in item) {
                        item[key] = item[key] || separator
                    }
                } else {
                    item = item || separator
                }
            })
            return list
        },
        /**
         * 从枚举中获取表格对应的枚举对象
         * @param list
         * @returns {*}
         */
        getTableEnumObject(list = []) {
            let tableEnumObject = {}
            list.forEach(item => {
                tableEnumObject[item.value] = item.label
            })
            return tableEnumObject
        }
    }
}
