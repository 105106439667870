import { CUSTOMER_LEAD_ORDER_STATE_ENUM_LIST, CUSTOMER_LEAD_SOURCE_TYPE_ENUM_LIST } from '@/constant/enum'
import { getLabelformList } from '@/utils'
import moment from 'moment'

export default [
    {
        hideInTable: true,
        title: '类型',
        dataIndex: 'sourceType',
        valueType: 'select',

        fieldProps: {
            options: [{
                label: '全部',
                value: ''
            }, ...CUSTOMER_LEAD_SOURCE_TYPE_ENUM_LIST]
        }
    },
    {
        hideInTable: true,
        title: '订单状态',
        dataIndex: 'stateQuery',
        valueType: 'select',

        fieldProps: {
            options: [{
                label: '全部',
                value: ''
            }, ...CUSTOMER_LEAD_ORDER_STATE_ENUM_LIST]
        }
    },
    {
        hideInTable: true,
        title: '经纪人',
        dataIndex: 'agentUserNameQuery'
    },
    {
        hideInTable: true,
        title: '企业名称',
        dataIndex: 'enterpriseNameQuery'
    },
    {
        hideInTable: true,
        title: '地区',
        dataIndex: 'area',
        span: 12,
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
        valueType: 'address'
    },
    {
        title: '服务单号',
        dataIndex: 'id',
        width: 200
    },
    {
        hideInTable: true,
        title: '提交时间',
        dataIndex: 'submitTime',
        valueType: 'dateRange',
        isExpansion: true,
        span: 12,
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
        fieldProps: {
            showTime: { defaultValue: [moment('2022-01-01 00:00:00'), moment('2022-01-01 23:59:59')] },
            format: 'YYYY-MM-DD HH:mm:ss',
            disabledDate: () => false
        }
    },
    {
        hideInSearch: true,
        title: '提交时间',
        dataIndex: 'submitTime',
        width: 180
    },
    {
        title: '类型',
        hideInSearch: true,
        dataIndex: 'sourceType',
        customRender: getLabelformList(CUSTOMER_LEAD_SOURCE_TYPE_ENUM_LIST),
        width: 180
    },
    {
        hideInSearch: true,
        title: '咨询内容',
        dataIndex: 'listTitleName',
        scopedSlots: { customRender: 'listTitleName' },
        width: 240
    },
    {
        hideInSearch: true,
        title: '企业名称',
        dataIndex: 'enterpriseName',
        width: 180
    },
    {
        hideInSearch: true,
        title: '地区',
        dataIndex: 'area',
        width: 180
    },
    {
        hideInSearch: true,
        title: '咨询人',
        dataIndex: 'name',
        width: 180
    },
    {
        hideInSearch: true,
        title: '联系手机',
        dataIndex: 'mobile',
        width: 180
    },
    {
        hideInSearch: true,
        title: '经纪人',
        dataIndex: 'agentUserName',
        width: 180
    },
    {
        hideInSearch: true,
        title: '订单状态',
        dataIndex: 'state',
        customRender: getLabelformList(CUSTOMER_LEAD_ORDER_STATE_ENUM_LIST),
        width: 180
    },
    {
        hideInSearch: true,
        title: '操作',
        key: 'action',
        scopedSlots: { customRender: 'action' },

        width: 100,
        fixed: 'right'
    }
]
