<!-- 查看快递信息弹框 -->
<template>
    <custom-modal
        :title="title"
        :visible="visible"
        :mask-closable="maskClosable"
        :width="560"
        class="express-modal"
        @close="handleCancel">
        <template>
            <!-- 表单 start -->
            <form-panel
                ref="formPanel"
                :current-row="currentRow"
                @loading="handleLoading"
            />
            <!-- 表单 end -->

            <!-- 按钮 start -->
            <template slot="customFooter">
                <a-button type="default" @click="handleCancel">关闭</a-button>
                <!-- 按钮 end -->
            </template>
        </template>
    </custom-modal>
</template>

<script>
    // 组件
    import { Button as AButton } from 'ant-design-vue'
    import CustomModal from '@components/CustomModal'
    import FormPanel from './FormPanel'

    export default {
        name: 'ExpressModal',
        components: {
            AButton,
            FormPanel,
            CustomModal
        },
        props: {
            visible: {
                type: Boolean,
                default() {
                    return false
                }
            },
            title: {
                type: String,
                default() {
                    return ''
                }
            },
            currentRow: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
        data() {
            return {
                loading: false,
                maskClosable: false
            }
        },
        computed: {},
        methods: {
            /**
             * 按钮loading
             */
            handleLoading(loading) {
                this.loading = loading
            },
            /**
             * 关闭弹框
             */
            handleCancel() {
                this.$emit('close')
            }
        }
    }
</script>

<style lang="scss">
    .express-modal {
        .ant-modal-footer {
            text-align: right;
        }
    }
</style>
