<!-- 线索列表-生成客户弹窗 -->
<template>
    <custom-modal
        :width="400"
        :loading="loading"
        :title="title"
        :visible="visible"
        :mask-closable="maskClosable"
        v-on="$listeners"
    >
        <template>
            <div class="modal-content">
                <pro-form v-model="form" ref="formRef" :columns="columns" />
            </div>
        </template>
        <template slot="customFooter">
            <template v-for="item in btnGroup">
                <a-button
                    v-if="!!item.text"
                    :key="item.text"
                    v-bind="item"
                    @click="item.handleClick"
                >
                    {{ item.text }}
                </a-button>
            </template>
        </template>
    </custom-modal>
</template>

<script>
import CustomModal from '@/components/CustomModal'
import { Button } from 'ant-design-vue'
import ProForm from '@components/ProForm'

// columns 配置
import multiplecColumns from './columns'

import { MODE_EUMN } from './constant'

export default {
    name: 'GeneralCustomerModal',
    components: {
        CustomModal,
        ProForm,
        AButton: Button
    },
    props: {
        mode: { // 弹窗模式
            type: String,
            default: null
        },
        initModelValue: { // 初始化参数
            type: Object,
            default: () => ({})
        },
        loading: { // 加载
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            columns: [],
            form: {},
            currentMode: null, // 当前模式
            MODE_EUMN,
            maskClosable: false
        }
    },
    computed: {
        title() {
            switch (this.currentMode) {
                case MODE_EUMN.GENERAL_CUSTOMER:
                    return '生成客户'
                default:
                    return '提示'
            }
        },
        visible() {
            return !!this.mode
        },
        btnGroup() {
            return [
                {
                    text: '取消',
                    handleClick: () => {
                        this.$emit('cancel')
                    }
                }, {
                    text: '确认',
                    loading: this.loading,
                    type: 'primary',
                    handleClick: () => {
                        this.$refs.formRef.validate(res => {
                            if (res) {
                                this.$emit('ok', this.form)
                            }
                        })

                    }
                }
            ]
        }
    },
    watch: {
        mode: {
            handler(val) {
                if (val) {
                    this.currentMode = val
                    this.columns = multiplecColumns[val] || []

                    this.form = {
                        ...this.columns.reduce((t, i) => Object.assign(t, { [i.dataIndex]: typeof i.initialValue === 'function' ? i.initialValue() : i.initialValue }), {}),
                        ...this.initModelValue
                    }
                }
            }
            // immediate: true
        }
    },
    methods: {
        handleSendSuccess() {
            this.$emit('success')
        }
    }
}
</script>

<style lang='scss'>
    .modal-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            width: 70px;
            height: 70px;
            margin: 12px;
        }

        .tipSuc {
            margin-bottom: 16px;
            color: #66be74;
        }

        .tipWarn {
            margin-bottom: 16px;
            color: #f1924e;
        }

        .suggest {
            margin-bottom: 48px;
            font-family: PingFangSC-Regular, "PingFang SC";
            font-size: 18px;
            font-weight: 400;
            line-height: 22px;
            color: #333;
            text-shadow: 0 8px 32px rgb(103 103 103 / 50%);
            letter-spacing: 2px;
        }

        .remark {
            font-family: PingFangSC-Regular, "PingFang SC";
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            color: #999;
            text-shadow: 0 8px 32px rgb(103 103 103 / 50%);
        }
    }

    .artificial-content {
        display: flex;

        .left {
            flex: 1;

            img {
                width: 129px;
                height: 24px;
                margin-bottom: 8px;
            }

            .name {
                margin-bottom: 8px;
                font-family: PingFangSC-Medium, "PingFang SC";
                font-size: 18px;
                font-weight: 500;
                line-height: 28px;
                color: #2a458e;
                text-shadow: 0 8px 32px rgb(103 103 103 / 50%);
            }

            .info {
                margin-bottom: 8px;
                font-family: PingFangSC-Regular, "PingFang SC";
                font-size: 16px;
                font-weight: 400;
                line-height: 28px;
                color: #333;
                text-shadow: 0 8px 32px rgb(103 103 103 / 50%);
            }

            .remark {
                font-family: PingFangSC-Regular, "PingFang SC";
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
                color: #999;
                text-shadow: 0 8px 32px rgb(103 103 103 / 50%);
            }
        }

        .right {
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
                width: 120px;
                height: 120px;
                margin-bottom: 8px;
            }

            p {
                font-family: PingFangSC-Regular, "PingFang SC";
                font-size: 18px;
                font-weight: 400;
                line-height: 18px;
                color: #333;
                text-shadow: 0 8px 32px rgb(103 103 103 / 50%);
            }
        }
    }
</style>
