<template>
    <div class="send-verify-box">
        <a-input v-model.trim="code"
                 type="text"
                 :max-length="maxLength"
                 placeholder="请输入短信验证码">
            <div slot="prefix">
                <img src="@/assets/images/icon/password.png"
                     width="20"
                     alt="">
                <a-divider type="vertical" />
            </div>
        </a-input>
        <a-button :loading="sendCodeLoading"
                  :disabled="verifyCodeBtnDis"
                  @click="validateBtn">
            {{ sendCodeLoading ? '加载中' : verifyCodeBtnTxt }}
        </a-button>
    </div>
</template>

<script>
import {
    Input as AInput,
    Icon as AIcon,
    Divider as ADivider,
    Button as AButton
} from 'ant-design-vue'

import sendCodeService from '@/service/userAction/sendCodeService'
import { SMS_COUNTDOWN_TIME } from '@/config'
import { VERIFY_CODE_TYPE } from '@/constant/enum'

export default {
    name: 'SendVerifyCode',

    components: {
        AInput,
        AIcon,
        ADivider,
        AButton
    },
    props: {
        phone: {
            type: [String, Number]
            // required: true
        },

        codeType: {
            type: String,
            default: VERIFY_CODE_TYPE.login
        },

        sendVerify: {
            type: Function,
            default: () => true
        },

        value: [String, Number],

        showIcon: {
            type: Boolean,
            default: false
        },

        maxLength: {
            type: Number,
            default: 8
        }
    },

    data() {
        return {
            verifyCodeBtnTxt: '获取验证码',
            verifyCodeBtnDis: false,
            sendCodeLoading: false
        }
    },

    computed: {
        code: {
            get() {
                return this.value
            },

            set(val) {
                this.$emit('input', val)
            }
        }
    },
    // 生命周期 - 创建完成
    created() {

    },

    // DOM挂载完毕
    mounted() {

    },

    methods: {
        validateBtn() {
            console.log(this.sendVerify)
            if (this.sendVerify()) {
                this.sendCode({
                    phone: this.phone,
                    type: this.codeType
                })
            }
        },

        async sendCode(data) {
            let { phone, type } = data
            this.sendCodeLoading = true
            try {
                await sendCodeService({
                    phone,
                    verifyType: type
                })
                this.sendCodeLoading = false
                this.$emit('sendSuccess', phone)
                this.$message.success('发送成功')
                this.countDown()
            } catch (e) {
                this.sendCodeLoading = false
                this.$emit('sendFailed', phone)
                this.$message.error(e.message || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
            }
        },

        countDown() {
            let time = SMS_COUNTDOWN_TIME
            let timer = setInterval(() => {
                if (time == 0) {
                    clearInterval(timer)
                    this.verifyCodeBtnDis = false
                    this.verifyCodeBtnTxt = '获取验证码'
                } else {
                    this.verifyCodeBtnDis = true
                    this.verifyCodeBtnTxt = time + '秒后重试'
                    time--
                }
            }, 1000)
        }
    }
}
</script>
<style lang='scss'>
.send-verify-box {
    display: flex;
    justify-content: flex-start;

    .ant-input-affix-wrapper {
        .ant-input {
            height: 36px;
            color: #323233;
            border-radius: 2px 0 0 2px;
        }

        .anticon {
            color: #c8c9cc;
        }
    }

    button {
        width: 96px;
        height: 36px;
        margin-left: -1px;
        color: #1877f2;
        border: 1px solid #dcdee0;
        border-radius: 0 2px 2px 0;
    }
}
</style>
