<template>
    <custom-modal
        title="支付链接"
        :width="660"
        :visible="visible"
        :mask-closable="maskClosable"
        @close="handleCancel"
    >
        <template>
            <a-spin :spinning="loading">
                <div v-show="!loading">
                    <template>
                        <div class="preview-form-content">
                            <div class="preview-form-content__address">
                                <div class="preview-form-content__address-left">
                                    <div>
                                        <label>支付链接：</label
                                        ><span>{{ linkAddress }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </a-spin>
            <template slot="customFooter">
                <a-button type="default" @click="handleCancel">关闭</a-button>
                <a-button type="primary" :disabled="loading" @click="handleCopyLink()">复制链接</a-button>
            </template>
        </template>
    </custom-modal>
</template>

<script>
// 组件
import { Button as AButton, Spin as ASpin } from 'ant-design-vue'
import CustomModal from '@components/CustomModal'

// 工具类
import { copyText } from '@/utils/copyText.js'

export default {
    name: 'PayCopyLinkModal',
    components: {
        AButton,
        ASpin,
        CustomModal
    },
    props: {
        visible: {
            type: Boolean,
            default() {
                return false
            }
        },
        mode: {
            type: String,
            default() {
                return ''
            }
        },
        data: {
            type: String,
            default() {
                return ''
            }
        }
    },
    data() {
        return {
            maskClosable: false,
            linkAddress: '',
            loading: false
        }
    },
    watch: {
        data: {
            handler(val) {
                if (val) {
                    this.getLinkById(val)
                }
            },
            immediate: true
        }
    },
    methods: {
        /**
         * 关闭弹框
         */
        handleCancel() {
            this.$emit('close')
        },
        handleCopyLink() {
            copyText(this.linkAddress)
            this.$message.success('复制成功')
        },
        // 根据渠道id获取地址链接
        async getLinkById(data) {
            // const { id } = data
            this.loading = true
            try {
                this.linkAddress = data
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/varibles.scss';
.preview-form-content {
    &__address {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-left {
            label {
                float: left;
                width: 70px;
            }
            span {
                width: calc(500px);
                float: left;
            }
        }
    }
}
</style>
