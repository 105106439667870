import {
    BASE_URL
} from '@config'

/** 客户
 * ————————————————————————————————————————————————————————————————————————————————————————————*/
/**
 * 客户-列表
 *
 */
export const GET_CUSTOMER_LIST = {
    url: `${BASE_URL}/customer/customer/select`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}
/**
 * 客户-备注
 *
 */
export const CUSTOMER_CREATE_REMARK = {
    url: `${BASE_URL}/customer/customer-communication/save`,
    method: 'post',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}
/**
 * 客户-备注记录
 *
 */
export const GET_CUSTOMER_REMARK_LIST = {
    url: `${BASE_URL}/customer/customer-communication/select`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 线索-生成客户
 *
 */
 export const GENERAL_CUSTOMER = {
    url: `${BASE_URL}/customer/customer/generateCustomer`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}


/** 公海客户
 * ————————————————————————————————————————————————————————————————————————————————————————————*/
/**
 * 公海客户-列表
 *
 */
export const GET_HIGHSEAS_CUSTOMER_LIST = {
    url: `${BASE_URL}/customer/customer/selectHighSeas`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 300000
}

/**
 * 公海客户-客户表新增
 *
 */
export const GET_HIGHSEAS_CUSTOMER_SAVE = {
    url: `${BASE_URL}/customer/customer/save`,
    method: 'post',
    headers: { 'content-type': 'application/json' },
    timeout: 30000
}

/**
 * 公海客户-客户备注单条查询
 *
 */
export const GET_HIGHSEAS_CUSTOMER_AGENT_SELECTONE = {
    url: `${BASE_URL}/customer/customer-agent/selectOne`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 公海客户-客户备注
 *
 */
export const SAVE_HIGHSEAS_CUSTOMER_AGENT = {
    url: `${BASE_URL}/customer/customer-agent/saveOrEdit`,
    method: 'post',
    headers: { 'content-type': 'application/json' },
    timeout: 30000
}

/**
 * 公海客户-客户沟通表新增
 *
 */
export const SAVE_HIGHSEAS_CUSTOMER_COMMUNICATION = {
    url: `${BASE_URL}/customer/customer-communication/save`,
    method: 'post',
    headers: { 'content-type': 'application/json' },
    timeout: 30000
}

/**
 * 客户来源列表-列表
 *
 */
export const GET_CUSTOMER_SOURCE_LIST = {
    url: `${BASE_URL}/customer/customer-source/selectList`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}
/**
 * 注册来源列表-列表
 *
 */
export const GET_SOURCE_LIST = {
    url: `${BASE_URL}/user/source/selectList`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}
/**
 * 经纪人下拉（团队人员集合查询）
 *
 */
export const GET_TEAM_USER_LIST = {
    url: `${BASE_URL}/user/team-user/selectList`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}


/**
 * 公海客户-分配经纪人
 *
 */
export const ALLOT_CUSTOMER_AGENT = {
    url: `${BASE_URL}/customer/customer-agent/allotCustomerAgent`,
    method: 'post',
    headers: { 'content-type': 'application/json' },
    timeout: 30000
}

/**
 * 公海客户-批量分配经纪人
 *
 */
export const BATCH_ALLOT_CUSTOMER_AGENT = {
    url: `${BASE_URL}/customer/customer-agent/batchAllotCustomerAgent`,
    method: 'post',
    headers: { 'content-type': 'application/json' },
    timeout: 30000
}

/**
 * 公海客户-客户详情头部
 *
 */
export const GET_CUSTOMER_SELECTONECUSTOMERINFOHEAD = {
    url: `${BASE_URL}/customer/customer/selectOneCustomerInfoHead`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 公海客户-客户详情
 *
 */
export const GET_CUSTOMER_SELECTONECUSTOMER = {
    url: `${BASE_URL}/customer/customer/selectOneCustomer`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 公海客户-客户跟进记录列表查询
 *
 */
export const GET_CUSTOMER_COMMUNICATION_LIST = {
    url: `${BASE_URL}/customer/customer-communication/select`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 公海客户-潜在客户表单条查询
 *
 */
export const GET_POTENTIAL_CUSTOMER_SELECTONE_LIST = {
    url: `${BASE_URL}/customer/potential-customer/selectOne`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 公海客户-潜在客户表操作记录查询
 *
 */
export const GET_POTENTIAL_CUSTOMER_LOG_SELECT_LIST = {
    url: `${BASE_URL}/customer/potential-customer-log/select`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 公海客户-潜在客户表集合查询
 *
 */
export const GET_POTENTIAL_CUSTOMER_LIST = {
    url: `${BASE_URL}/customer/potential-customer/selectListPage`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 公海客户-客户备注列表
 *
 */
export const GET_CUSTOMER_AGENT_LIST = {
    url: `${BASE_URL}/customer/customer-agent/select`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 公海客户-订单列表
 *
 */
export const GET_CAST_MANAGE_LIST = {
    url: `${BASE_URL}/insurance/cast/manage/all/list`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 公海客户-订单详情
 *
 */
export const GET_CAST_MANAGE_DETAIL = {
    url: `${BASE_URL}/insurance/cast/manage/all/detail`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 公海客户-保单列表
 *
 */
export const GET_CAST_MANAGE_INSURANCE_LIST = {
    url: `${BASE_URL}/insurance/cast/manage/insurance/list`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 公海客户-保单详情
 *
 */
export const GET_CAST_MANAGE_INSURANCE_DETAIL = {
    url: `${BASE_URL}/insurance/cast/manage/insurance/detail`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 公海客户-保全列表
 *
 */
export const GET_CUSTOMER_INSURANCE_CORRECTING_LIST = {
    url: `${BASE_URL}/insurance/insurance-correcting/select`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 公海客户-保全详情
 *
 */
export const GET_CUSTOMER_INSURANCE_CORRECTING_SELECT_ONE = {
    url: `${BASE_URL}/insurance/insurance-correcting/selectOne`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 公海客户-发票列表
 *
 */
export const GET_CUSTOMER_CAST_MANAGE_LIST = {
    url: `${BASE_URL}/insurance/cast/manage/allPage`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 公海客户-发票详情
 *
 */
export const GET_CUSTOMER_CAST_MANAGE_INVOICE_DETAIL = {
    url: `${BASE_URL}/insurance/cast/manage/invoiceDetail`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 公海客户-分配记录列表
 *
 */
export const GET_CUSTOMER_AGENT_OPERATE_LIST = {
    url: `${BASE_URL}/customer/customer-agent-operate-record/select`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 下载反担保
 *
 */
export const DOWN_COUNTER_GUARANTEE = {
    url: `${BASE_URL}/insurance/download/downCounterGuarantee`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 下载投保单
 *
 */
export const DOWN_CUST_INSURANCE = {
    url: `${BASE_URL}/insurance/download/downCustInsurance`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}