<!-- 客户详情-列表组件 -->
<template>
    <div class="list-container">
        <div class="list-container__header">
            <!-- 标题 start -->
            <div class="list-container__header-title" v-if="$slots.header || title">
                <slot v-if="$slots.header" name="header"/>
                <span class="list-container__header-title-text" v-else>{{ title }}</span>
            </div>
            <!-- 标题 end -->

            <!-- 操作 start -->
            <div class="list-container__header-action" v-if="$slots.action">
                <slot name="action"/>
            </div>
            <!-- 操作 end -->
        </div>

        <!-- 主体内容 start -->
        <div class="list-container__content" v-if="$slots.content">
            <slot name="content"/>
        </div>
        <!-- 主体内容 end -->

        <slot />
    </div>
</template>

<script>
    export default {
        name: 'ListContainer',
        props: {
            title: {
                type: String,
                default: ''
            }
        },
        data() {
            return {}
        },
        methods: {
        }
    }
</script>

<style lang="scss">
    @import "@assets/styles/varibles.scss";

    .list-container {
        margin-bottom: 8px;
        background-color: $component-background;
        border-radius: 4px;

        &__header {
            height: 48px;
            padding: 0 24px;
            line-height: 1;
            border-bottom: 1px solid #EBEDF0;

            &-title {
                float: left;
                padding: 16px 0;
                color: $custom-button-default-text-color;
                font-size: $font-size;
                font-weight: bold;
            }

            &-action {
                float: right;
                padding: 8px 0;
            }
        }

        &__content {
            padding: 24px 24px 32px;
        }
    }
</style>
