<template>
    <a-modal
        class="custom-modal"
        centered
        destroy-on-close
        :title="title"
        :visible="visible"
        :mask-closable="maskClosable"
        :width="width"
        @cancel="handleCancel">
        <!-- 按钮 start -->
        <slot name="tabs" />

        <template slot="footer">
            <!-- 自定义footer -->
            <div v-if="$slots.customFooter">
                <slot name="customFooter" />
            </div>
            <!-- 初始化footer -->
            <div v-else>
                <a-button key="reset" @click="handleReset">
                    重置
                </a-button>
                <a-button key="submit" type="primary" :loading="loading" @click="handleOk">
                    提交
                </a-button>
            </div>
        </template>
        <!-- 按钮 end -->

        <slot />
    </a-modal>
</template>

<script>
// 组件
import {
    Modal as AModal,
    Button as AButton
} from 'ant-design-vue'

export default {
    name: 'CustomModal',
    components: {
        AModal,
        AButton
    },
    props: {
        visible: {
            type: Boolean,
            default() {
                return false
            }
        },
        title: {
            type: String,
            default() {
                return ''
            }
        },
        loading: {
            type: Boolean,
            default() {
                return false
            }
        },
        width: {
            type: Number,
            default() {
                return 520
            }
        }
    },
    data() {
        return { maskClosable: false }
    },
    methods: {
        /**
             * 提交按钮事件
             */
        handleOk() {
            this.$emit('ok')
        },
        /**
             * 重置按钮事件
             */
        handleReset() {
            this.$emit('reset')
        },
        /**
             * 关闭弹框
             */
        handleCancel() {
            this.$emit('close')
        }
    }
}
</script>

<style lang="scss">
.custom-modal {
    .ant-modal-footer {
        text-align: right;
        background: #fdfdfe;
    }

    .ant-modal-header {
        background: #f7f8fa;
    }
    .button-box-shadow {
        box-shadow: 0px 4px 6px -4px rgba(24,119,242,0.6);
    }
}
</style>
