<template>
    <div class="common-title-components">
        <!-- 左边标题 -->
        <span class="common-title-components__text">{{ title }}</span>
        <!-- 右边额外 -->
        <div class="common-title-components__extra">
            <slot v-if="$slots.extra" name="extra" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'CommonTitleComponents',
    props: {
        title: {
            type: String,
            default: ''
        }
    }

}
</script>

<style lang='scss'>
@import '@assets/styles/varibles.scss';
.common-title-components {
    width: 100%;
    height: 56px;
    line-height: 51px;
    background: #F7F8FA;
    border-radius: 4px 4px 0px 0px;
    padding: 0 24px;
    &__text {
        display: inline-block;
        width: 50%;
        font-size: 16px;
        font-family: 'PingFangSC-Medium', 'PingFang SC';
        font-weight: 500;
        color: #323233;
    }
    &__extra {
        display: inline-block;
        width: 50%;
        text-align: right;
        .ant-btn:not(:last-child) {
            margin-right: $padding;
        }
    }
}
</style>