/**
 * 中标资讯模块 模块
 */
import BasicLayout from '@layout/BasicLayout'

import {
    WINNING_INFO_MENU_CODE,
    WINNING_INFO_LIST_MENU_CODE
} from '@constant/menu_code'

const routes = [{
    path: '/winningInfo',
    name: 'WinningInfo',
    redirect: '/winningInfo/list',
    component: BasicLayout,
    code: WINNING_INFO_MENU_CODE,
    sort: 99,
    meta: {
        title: '中标资讯',
        icon: 'zixun',
        iconType: 'iconfont'
    },
    children: [{
        path: 'list',
        name: 'WinningInfoList',
        component: () => import('./List/index'),
        hidden: true,
        code: WINNING_INFO_LIST_MENU_CODE,
        meta: {
            breadcrumb: false,
            keepAlive: true
        },
        children: []
    }]
}]

export default routes
