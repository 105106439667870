import axios from '@service'
import {GET_CLUE_REMARK_LIST} from './api'
import {HTTP_ERR_SUCCESS, HTTP_RESULT_FIELD, HTTP_ERR_MESSAGE_FIELD} from '@/constant/api_status'
import {ERROR_DEFAULT_HTTP_REQUEST_FAIL} from '@/constant/error'

/**
 * 获取线索备注记录
 * @param payload
 * @param config
 * @returns {Promise<*>}
 */
export default async function getRemarkListService(payload = {}, config = {}) {
    const param = {...GET_CLUE_REMARK_LIST, ...config, params: payload}
    const {data} = await axios(param)
    if (data.code === HTTP_ERR_SUCCESS) {
        return data[HTTP_RESULT_FIELD]
    } else {
        throw new Error(data[HTTP_ERR_MESSAGE_FIELD] || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}