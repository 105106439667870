<!-- 客户管理-客户维护管理-详情 -->
<template>
    <detail-panel/>
</template>

<script>
    // 组件
    import DetailPanel from '@views/Customer/Components/DetailPanel'

    export default {
        name: 'ProprietaryCustomerDetail',
        components: {
            DetailPanel
        },
        data() {
            return {}
        }
    }
</script>
