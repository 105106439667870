<!-- 公海客户管理 -->
<template>
    <list-page-layout class="customer-list-page">
        <waiting-list :key="pageKey" :prev-route="prevRoute" />
    </list-page-layout>
</template>

<script>
// 组件
import ListPageLayout from '@layout/ListPageLayout'
import WaitingList from './WaitingList'

export default {
    name: 'HighSeasCustomerList',
    components: {
        WaitingList,
        ListPageLayout
    },
    data() {
        return {
            pageKey: Date.now(),
            prevRoute: null
        }
    },
    // 此为keepalive缓存页面
    activated() {
        // 非详情页返回就强制刷新
        if (this.prevRoute?.name !== 'HighSeasCustomerDetail') {
            Object.assign(this, { pageKey: Date.now() })
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from
        })
    }
}
</script>
