/**
 * 动态收集 views 中的路由文件
 */
import {sortBy} from '@utils'

let routes = [];

(modules => modules.keys().forEach((key) => {
    routes = routes.concat(modules(key).default)
}))(require.context('../views/', true, /routes\.js$/))


export default routes.sort(sortBy('sort', true))

