<template>
  <router-view/>
</template>

<script>
export default {
  name: 'RouteLayout'
}
</script>

<style lang="scss">

</style>