/**
 * 客户跟进-列表权限
 * ——————————————————————————————————————————————————————————————————————————————————————————————
 */
// 客户跟进列表-添加客户
export const DISTRIBUTION_CUSTOMER_ADD_BUTTON = 'DISTRIBUTION_CUSTOMER_ADD'
// 客户跟进列表-批量添加客户
export const DISTRIBUTION_GROUP_CUSTOMER_ADD_BUTTON = 'DISTRIBUTION_GROUP_CUSTOMER_ADD'
// 客户跟进列表-跟进记录
export const DISTRIBUTION_CUSTOMER_FOLLOW_RECORD_BUTTON = 'DISTRIBUTION_CUSTOMER_FOLLOW_RECORD'
// 客户跟进详情-查看电子保单
export const DISTRIBUTION_CUSTOMER_VIEW_POLICY_BUTTON = 'DISTRIBUTION_CUSTOMER_VIEW_POLICY'
// 客户跟进详情-查看电子发票
export const DISTRIBUTION_CUSTOMER_ELECTRONIC_INVOICE_BUTTON = 'DISTRIBUTION_CUSTOMER_ELECTRONIC_INVOICE'
// 客户跟进详情-查看保险凭证
export const DISTRIBUTION_CUSTOMER_VIEW_PROOF_BUTTON = 'DISTRIBUTION_CUSTOMER_VIEW_PROOF'
// 客户跟进详情-下载投保单
export const DISTRIBUTION_CUSTOMER_DOWNLOAD_CAST_INSURANCE_BUTTON = 'DISTRIBUTION_CUSTOMER_DOWNLOAD_CAST_INSURANCE'
// 客户跟进详情-下载反担保
export const DISTRIBUTION_CUSTOMER_DOWNLOAD_COUNTER_GUARANTEE_BUTTON = 'DISTRIBUTION_CUSTOMER_DOWNLOAD_COUNTER_GUARANTEE'
