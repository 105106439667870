export default [
    {
        hideInTable: true,
        title: '类型',
        dataIndex: 'operation',
        valueType: 'select',
        fieldProps: {
            options: [
                {
                    label: '全部',
                    value: ''
                },
                {
                    label: '关联',
                    value: '关联'
                },
                {
                    label: '分配',
                    value: '分配'
                },
                {
                    label: '解绑',
                    value: '解绑'
                },
                {
                    label: '转移',
                    value: '转移'
                }
            ]
        }
    },
    {
        hideInSearch: true,
        title: '创建时间',
        dataIndex: 'createDateTime'
    },
    {
        hideInSearch: true,
        title: '类型',
        dataIndex: 'operation'
    },
    {
        hideInSearch: true,
        title: '操作方式',
        dataIndex: 'source'
    },
    {
        hideInSearch: true,
        title: '经纪人',
        dataIndex: 'agentUserName'
    },
    {
        hideInSearch: true,
        title: '操作人',
        dataIndex: 'createName'
    },
    {
        hideInSearch: true,
        title: '更新时间',
        dataIndex: 'modifyDateTime'
    }
].map(item => ({
    ...item,
    width: 180
}))
