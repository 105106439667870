<!-- 分配记录Tab -->
<template>
    <div>
        <pro-table
            ref="tableRef"
            row-key="id"
            :scroll="{ x: '100%' }"
            :columns="columns"
            :request="getList"
        />
    </div>
</template>
<script>
import ProTable from '@components/ProTable'
import getCustomerAgentOperateRecordSelectService from '@service/customer/getCustomerAgentOperateRecordSelectService'
import columns from './columns'

export default {
    name: 'AllocationRecord',
    components: { ProTable },
    data() {
        return { columns }
    },
    computed: {
        customerId() {
            return this.$route.query.id
        }
    },
    methods: {
        // 获取列表数据
        async getList(params) {
            const { customerId } = this
            const { pageSize, current, ...otherParams } = params
            const { total, records } = await getCustomerAgentOperateRecordSelectService({
                customerId,
                current,
                size: pageSize,
                ...otherParams
            })
            return {
                data: records.map(item => ({
                    ...item,
                    agentUserName: `${item.agentUserName}-${item.agentUserId}`
                })),
                total
            }
        },
        // 用于外部刷新
        refresh() {
            this.$refs.tableRef?.handleSearch?.()
        }
    }
}
</script>
