<template>
    <div class="platform-import-modal-content">
        <a-spin :spinning="loading" tip="拼命导入中">
            <template v-if="uploadFinishState === '1'">
                <div class="platform-import-modal-content__success">
                    <div>
                        <a-icon type="exclamation-circle" theme="twoTone" two-tone-color="#52c41a" />
                    </div>
                    <div class="platform-import-modal-content__success-title">有导入失败客户，可下载表格查看失败原因！</div>
                </div>
            </template>
            <template v-else-if="uploadFinishState === '2'">
                <div class="platform-import-modal-content__success">
                    <div>
                        <a-icon type="check-circle" theme="twoTone" two-tone-color="#52c41a" />
                    </div>
                    <div class="platform-import-modal-content__success-title">导入完成</div>
                </div>
            </template>
            <template v-else>
                <div class="platform-import-modal-content__base">
                    <div>1、支持Excel表格导入（.xls与.xlsx），其他文件会导入失败</div>
                    <div>2、导入模板下载（请严格按照模板提供的格式进行导入，否则导入失败）</div>
                    <div><a-button @click="handleDownloadTemplate">下载模版</a-button></div>
                    <div>3、上传导入文件，支持.xls,.xlsx格式（每次最多500条，如果过多，请分多次导入）</div>
                    <div>
                        <a-upload :file-list="fileList" :before-upload="beforeUpload" :remove="handleRemove" accept=".xlsx,.xls">
                            <a-button type="primary">选择文件</a-button>
                        </a-upload>
                    </div>
                </div>
            </template>
        </a-spin>
    </div>
</template>

<script>
import { Button as AButton, Upload as AUpload, Spin as ASpin, Icon as AIcon } from 'ant-design-vue'


// 文件上传接口
import importProprietaryCustomerService from '@service/customer/proprietaryCustomer/importProprietaryCustomerService'

import { gotoPage } from '@utils/dom'

export default {
    name: 'ImportModal',
    components: {
        AButton,
        AUpload,
        ASpin,
        AIcon
    },
    data() {
        return {
            fileList: [],
            uploadFile: '',
            loading: false,
            uploadFinishState: ''
        }
    },
    methods: {
        async handleDownloadTemplate() {
            try {
                // 执行模板下载
                const templateUrl = 'https://gongbaojinji.oss-cn-hangzhou.aliyuncs.com/xls/%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx'
                gotoPage(templateUrl)
            } catch (e) {
                this.$message.error(e.message)
            }
        },
        beforeUpload(file) {
            // console.log(file)
            this.uploadFile = file
            this.fileList = [file]
            return false
        },
        handleRemove() {
            this.uploadFile = ''
            this.fileList = []
        },
        async handleSend() {
            // 执行文件上传接口
            try {
                this.$emit('loading', true)
                this.loading = true
                const params = new FormData()
                if (this.uploadFile) {
                    params.append('file', this.uploadFile)
                    const res = await importProprietaryCustomerService(params)
                    this.uploadFinishState = res ? '1' : '2'
                    this.$emit('download', res)
                    this.$emit('import')
                } else {
                    this.$message.error('请先选择文件')

                }

                // console.log(res)
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.loading = false
                this.$emit('loading', false)
            }
        }
    }
}
</script>

<style lang="scss">
.platform-import-modal-content {
    &__base {
        div {
            margin: 5px 0;
        }
    }

    &__success {
        text-align:center;
        &-title {
            margin: 15px 0 20px;
            font-size: 16px;
            text-align:center;
        }
        .anticon {
            font-size: 64px;
        }
    }
}
</style>
