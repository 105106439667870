import getProvinceRedisService from '@service/getProvinceRedisService'
import {
    Cascader as ACascader
} from 'ant-design-vue'
export default {
    data() {
        return {
            // 地区数组
            areaData: [],
            // 已选择的code数组
            areaCodeValue: [],
            // 关联该code的所有数据
            areaCodeList: [],
        }
    },
    components:{
        ACascader,
    },
    created() {
        this.getProvinceRedis()
    },
    methods: {
        /**
         * 获取地区
         */
        async getProvinceRedis() {
            try {
                this.areaData = await getProvinceRedisService()
            } catch (e) {
                this.$message.error(e.message)
            }
        },
        /**
         * 地区选择
         */
        handleChangeArea(value, selectedOptions) {
            this.areaCodeValue = value
            this.areaCodeList = selectedOptions
        },

    }
}