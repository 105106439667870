<!-- 业务级表单页组件 -->
<template>
    <a-spin :spinning="loading">
        <div v-if="title" class="header-panel-wrap">
            <h3>{{ title }}</h3>
            <slot name="extendBtn" />
        </div>
        <slot name="tabs" />
        <div class="form-panel-wrap">
            <form-panel v-model="form"
                        ref="formPanelRef"
                        :span="span"
                        :columns="columns"
                        @search="handleSearch" />
        </div>
        <div class="table-panel-wrap">
            <div v-if="$slots.tableExtendLeft">
                <slot name="tableExtendLeft" />
            </div>
            <div v-if="$slots.tableExtend" class="table-extend">
                <slot name="tableExtend" />
            </div>
            <a-table
                size="middle"
                v-bind="$attrs"
                :columns="tableColumns"
                :pagination="pagination"
                :data-source="dataSource"
                :transform-cell-text="handleTransformCellText"
                v-on="$listeners"
                @change="handleTableChange"
            >
                <template v-for="(i, name) in $scopedSlots" #[name]="text, record, index">
                    <slot :name="name" v-bind="{text, record, index}" />
                </template>
            </a-table>
        </div>
    </a-spin>
</template>
<script>
import { Table as ATable } from 'ant-design-vue'
import FormPanel from './FormPanel/index'

// 全局数据
import { DEFAULT_PAGES_SIZE } from '@config'
import './index.scss'

export default {
    name: 'ProTable',
    components: {
        ATable,
        FormPanel
    },
    props: {
        // 整体列表配置
        columns: {
            type: Array,
            default: () => []
        },
        // 表格参数获取
        request: {
            type: Function,
            default: null
        },
        title: {
            type: String,
            default: null
        },
        span: {
            type: Number,
            default: 8
        }
    },
    data() {
        return {
            loading: false, // 加载
            dataSource: [], // 表格参数
            form: this.columns.filter(i => !i.hideInSearch).reduce((t, i) => Object.assign(t, { [i.dataIndex]: i.initialValue }), {}),
            pagination: {
                showTotal: (total, range) => this.tableShowTotal(total, range),
                current: 1,
                total: 0,
                pageSize: DEFAULT_PAGES_SIZE,
                showSizeChanger: true,
                showQuickJumper: true
            } // 分页配置
        }
    },
    computed: {
        // 格式化表格配置
        tableColumns() {
            return this.columns.filter(i => !i.hideInTable)
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.handleRequest('search')
        })
    },
    methods: {
        /**
         * 格式化分页提示
         */
        tableShowTotal(total, range) {
            return `第${range[0]}-${range[1]}条/总共${total}条`
        },
        /**
         * 触发搜索按钮
         */
        handleSearch() {
            Object.assign(this.pagination, { current: 1 })

            this.handleRequest('search')
        },
        /**
         * 获取格式化查询参数
         */
        getParams() {
            const { current, pageSize } = this.pagination

            const data = Object.entries(this.form).reduce((t, i) => {
                // 过滤一些不需要传给后端的字符
                if (i[1] === null || i[1] === undefined || i[1] === '') {
                    return t
                }
                return Object.assign(t, { [i[0]]: i[1] })
            }, {})
            return {
                pageSize,
                current,
                ...data
            }
        },
        /**
         * 触发获取表单数据
         */
        async handleRequest(action) {
            const { request } = this
            if (request) {
                this.loading = true
                try {
                    const { data = [], total = 0 } = await request(this.getParams(), action) || {}
                    this.dataSource = data
                    this.pagination.total = total
                } catch (e) {
                    this.$message.error(e.message)
                } finally {
                    this.loading = false
                }
            }
        },
        /**
         * 响应 table change 事件
         */
        handleTableChange(pagination) {
            Object.assign(this.pagination, pagination )
            this.handleRequest('pagination')
        },
        // 重新
        onReload() {
            this.handleSearch()
        },
        // 重置搜索按钮并刷新
        onReset() {
            this.$refs.formPanelRef.$refs.searchPanelRef.handleRefresh()
        },
        // 空值替换
        handleTransformCellText({ text }) {
            return text || '-'
        }
    }
}
</script>

<style lang="scss">
.header-panel-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 24px;
    background: #f7f8fa;
    border-radius: 4px 4px 0 0;
    box-shadow: 0 2px 30px 0 rgb(220 222 223 / 50%);

    // margin-bottom: 8px;

    h3 {
        margin: 0;
    }
}

.form-panel-wrap {
    .search-panel {
        box-shadow: none;
    }
}

.table-panel-wrap {
    padding: 0 20px 24px;
    background: #fff;

    .table-extend {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 8px;
    }
}
</style>
