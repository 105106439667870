// 业务级表单页查询组件
import SearchPanel from '@components/SearchPanel'
import FormCol from '@components/FormCol'
import FormItem from '@components/ProForm/FormItem'

export default {
    name: 'FormPanel',
    components: {
        SearchPanel,
        FormCol,
        FormItem
    },
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        columns: {
            type: Array,
            default: () => []
        },
        span: {
            type: Number,
            default: 8
        }
    },
    data() {
        return { }
    },
    computed: {
        // 始终显示的筛选的条件
        formColumns() {
            return this.columns.filter(i => !i.hideInSearch && !i.isExpansion)
        },
        // 需要收起筛选的条件
        formExpansionColumns() {
            return this.columns.filter(i => !i.hideInSearch && i.isExpansion)
        },
        form: {
            get() {
                return this.value || undefined
            },
            set(val) {
                this.$emit('input', val)
            }
        }
    },
    methods: {
        /**
         * 响应搜索事件
         */
        handleSearch() {
            this.$emit('search')
        }
    },
    render(c) {
        return c('div', {}, [
            c(
                'SearchPanel',
                {
                    attrs: {
                        model: this.form,
                        layout: 'vertical'
                    },
                    ref: 'searchPanelRef',
                    on: { search: this.handleSearch }
                },
                [
                    c(
                        'FormItem',
                        {
                            attrs: {
                                value: this.form,
                                columns: this.formColumns,
                                span: 6
                            },
                            on: {
                                input: val => {
                                    this.form = val
                                }
                            }
                        },
                        []
                    ), this.formExpansionColumns.length ? c(
                        'FormItem',
                        {
                            slot: 'expansion',
                            attrs: {
                                value: this.form,
                                columns: this.formExpansionColumns,
                                span: 6
                            },
                            on: {
                                input: val => {
                                    this.form = val
                                }
                            }
                        },
                        []
                    ) : null
                ]
            )
        ])
    }
}
