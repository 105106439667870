<!-- 企业列表 -->
<template>
    <list-page-layout>
        <!-- search -->
        <search-panel slot="header" @search="handleSearch" @refresh="handleRefresh" />
        <container-panel>
            <!-- 标题 start -->
            <template slot="title">企业列表</template>
            <!-- 标题 end -->

            <!-- 表格 start -->
            <template slot="content" slot-scope>
                <a-table
                    size="middle"
                    :columns="columns"
                    :data-source="data"
                    :pagination="pagination"
                    :loading="loading"
                    row-key="id"
                    :scroll="scrollConfig"
                    @change="handleTableChange">
                    <!-- 表格的序号递增 -->
                    <template slot="sort" slot-scope="text, record, index">{{ index + 1 }}</template>
                </a-table>
            </template>
            <!-- 表格 end -->
        </container-panel>
    </list-page-layout>
</template>

<script>
// 组件
import {
    Button as AButton,
    Table as ATable
} from 'ant-design-vue'
import ListPageLayout from '@layout/ListPageLayout'
import ContainerPanel from '@components/ContainerPanel'
import SearchPanel from './SearchPanel'

// 全局数据
import { DEFAULT_PAGES_SIZE } from '@config'
import { CLUE_SOURCE_ENUM_LIST } from '@constant/enum'
import { scrollConfig } from '@constant/listScrollConfig'

// mixins
import enumOperate from '@mixins/enumOperate'
import tableShowTotal from '@mixins/tableShowTotal'

// 接口请求
import getEnterpriseListService from '@service/enterprise/getEnterpriseListService'

const columns = [
    {
        title: '序号',
        dataIndex: 'sort',
        key: 'sort',
        width: 100,
        align: 'center',
        scopedSlots: { customRender: 'sort' }
    },
    {
        title: '转化时间',
        width: 300,
        align: 'center',
        dataIndex: 'createDateTime',
        key: 'createDateTime'
    },
    {
        title: '企业ID',
        width: 300,
        dataIndex: 'enterpriseId',
        align: 'center',
        key: 'enterpriseId'
    },
    {
        title: '企业名称',
        align: 'center',
        width: 300,
        dataIndex: 'name',
        key: 'name'
    },
    {
        title: '信用代码',
        width: 300,
        dataIndex: 'code',
        key: 'code'
    }
]

export default {
    name: 'ClientClue',
    components: {
        ListPageLayout,
        SearchPanel,
        ContainerPanel,
        AButton,
        ATable
    },
    mixins: [enumOperate, tableShowTotal],
    data() {
        return {
            // 查询条件
            params: {},
            // 数据
            data: [],
            // 分页数量
            limit: DEFAULT_PAGES_SIZE,
            // 总记录数量
            total: 0,
            // 当前索引
            current: 1,
            // 加载状态
            loading: false,
            // table 列配置
            columns,
            scrollConfig
        }
    },
    computed: {
        pagination() {
            const { total, limit, current, tableShowTotal } = this
            return {
                showTotal: (total, range) => tableShowTotal(total, range),
                current,
                total,
                pageSize: limit,
                showSizeChanger: true,
                showQuickJumper: true
            }
        }
    },
    mounted() {
    // 获取列表
        this.getList()
    },
    methods: {
    /**
     * 获取 列表
     */
        async getList() {
            try {
                this.loading = true
                const { params, current, limit } = this
                const { total, records } = await getEnterpriseListService({
                    current,
                    size: limit,
                    ...params
                })
                this.total = total || 0
                this.data = records
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.loading = false
            }
        },
        /**
     * 响应搜索事件
     */
        handleSearch(params) {
            this.current = 1
            this.params = params
            this.getList()
        },
        /**
     * 响应刷新事件
     */
        handleRefresh(params) {
            this.handleSearch(params)
        },
        /**
     * 响应 table change 事件
     * @param pagination
     */
        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.current = current
            this.limit = pageSize
            this.getList()
        }
    }
}
</script>

<style lang="scss">
</style>
