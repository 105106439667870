<!-- 中标资讯-列表 -->
<template>
    <list-page-layout>
        <!-- title -->
        <common-title title="中标资讯" />
        <container-panel>
            <!-- 筛选 -->
            <template #screen>
                <search-panel
                    slot="header"
                    @search="handleSearch"
                    @refresh="handleRefresh"
                />
            </template>

            <!-- 表格 start -->
            <template #content>
                <a-table
                    size="middle"
                    :columns="columns"
                    :data-source="data"
                    :pagination="pagination"
                    :loading="loading"
                    row-key="id"
                    :scroll="scrollConfig"
                    @change="handleTableChange"
                >
                    <!-- 操作 -->
                    <template slot="action" slot-scope="action">
                        <a-button v-auth="WINNING_INFO_SOURCE_BUTTON" type="link" @click="handleClick(action)">
                            信息来源
                        </a-button>
                    </template>
                </a-table>
            </template>
        </container-panel>
    </list-page-layout>
</template>

<script>
// 组件
import {
    Table as ATable
} from 'ant-design-vue'
import ListPageLayout from '@layout/ListPageLayout'
import CommonTitle from '@components/CommonTitle'
import ContainerPanel from '@components/ContainerPanel'
import SearchPanel from './SearchPanel'

// 常量
import { DEFAULT_PAGES_SIZE } from '@config'

import {
    WINNING_INFO_SOURCE_BUTTON
} from '@constant/authEnum/winningInfo'

import { scrollConfig } from '@constant/listScrollConfig'

// mixins
import tableShowTotal from '@mixins/tableShowTotal'
import enumOperate from '@mixins/enumOperate'
import empty from '@mixins/empty'

// 接口请求
import getWinningInfoListService from '@service/winningInfo/getWinningInfoListService'

export default {
    name: 'GPlatformPageList',
    components: {
        ATable,
        ListPageLayout,
        CommonTitle,
        ContainerPanel,
        SearchPanel
    },
    mixins: [tableShowTotal, enumOperate, empty],
    data() {
        const columns = [
            {
                title: '项目名称',
                dataIndex: 'proName',
                key: 'proName',
                width: 300,
                customRender: text => this.empty(text)
            },
            {
                title: '项目类型',
                dataIndex: 'proType',
                key: 'proType',
                width: 150,
                customRender: text => this.empty(text)
            },
            {
                title: '地区',
                dataIndex: 'city',
                width: 200,
                ellipsis: true,
                customRender: text => this.empty(text)
            },
            {
                title: '中标企业',
                dataIndex: 'bidWinningName',
                width: 300,
                ellipsis: true,
                customRender: text => this.empty(text)
            },
            {
                title: '中标金额',
                dataIndex: 'bidWinningAmount',
                width: 200,
                ellipsis: true,
                customRender: text => this.empty(text)
            },
            {
                title: '联系人',
                dataIndex: 'proLeader',
                ellipsis: true,
                width: 200,
                customRender: text => this.empty(text)
            },
            {
                title: '项目工期',
                dataIndex: 'proDuration',
                ellipsis: true,
                width: 200,
                customRender: text => this.empty(text)
            },
            {
                title: '招标人',
                dataIndex: 'proTen',
                ellipsis: true,
                width: 200,
                customRender: text => this.empty(text)
            },
            {
                title: '代理机构',
                dataIndex: 'bidAgency',
                width: 200,
                customRender: text => this.empty(text)
            },
            {
                title: '发布日期',
                dataIndex: 'publishDate',
                width: 200,
                customRender: text => this.empty(text)
            },
            {
                title: <div style="min-width:60px;text-align:left;padding: 0 16px">操作</div>,
                key: 'action',
                fixed: 'right',
                align: 'left',
                scopedSlots: { customRender: 'action' }
            }
        ]
        return {
            WINNING_INFO_SOURCE_BUTTON,
            columns,
            // 查询条件
            params: {},
            // 数据
            data: [],
            // 分页数量
            limit: DEFAULT_PAGES_SIZE,
            // 总记录数量
            total: 0,
            // 当前索引
            current: 1,
            // 加载状态
            loading: false,
            scrollConfig
        }
    },
    computed: {
        pagination() {
            const {
                total,
                limit,
                current,
                tableShowTotal
            } = this
            return {
                showTotal: (total, range) => tableShowTotal(total, range),
                current,
                total,
                pageSize: limit,
                showSizeChanger: true,
                showQuickJumper: true
            }
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        /**
             * 获取 列表
             */
        async getList() {
            try {
                this.loading = true
                const { params, current, limit } = this
                const { total, records } = await getWinningInfoListService({
                    current,
                    size: limit,
                    ...params
                })
                this.total = total || 0
                this.data = records || []
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.loading = false
            }
        },
        /**
             * 信息来源
             */
        handleClick(action) {
            const { website } = action
            if (!website) return
            window.open(website)
        },
        /**
             * 响应 table change 事件
             * @param pagination
             */
        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.current = current
            this.limit = pageSize
            this.getList()
        },
        /**
             * 搜索
             */
        handleSearch(params) {
            this.current = 1
            this.params = params
            this.getList()
        },
        /**
             * 刷新
             */
        handleRefresh(params) {
            this.handleSearch(params)
        }
    }
}
</script>

<style lang='scss'>
    @import '@assets/styles/varibles.scss';
</style>

