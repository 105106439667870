<!-- 跟进客户-表单 -->
<template>
    <a-form-model
        ref="ruleForm"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 24 }"
        :model="form"
        :rules="rules"
        class="follow-record-modal"
    >
        <a-form-model-item label="跟进方式" prop="type">
            <a-radio-group v-model="form.type" name="radioGroup">
                <a-radio
                    v-for="item in followMethodsData"
                    :key="item.value"
                    :value="item.value"
                >
                    {{ item.label }}
                </a-radio>
            </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="跟进时间" prop="dataTime">
            <a-date-picker
                v-model="form.dataTime"
                show-time
                type="date"
                placeholder="请选择"
                style="width: 100%;"
                :disabled-date="disabledDateStart"
            />
        </a-form-model-item>
        <a-form-model-item label="跟进标题" prop="title">
            <a-input
                v-model.trim="form.title"
                placeholder="请输入"
                :max-length="50"
            />
        </a-form-model-item>
        <a-form-model-item label="跟进内容" prop="content">
            <a-text-area
                v-model.trim="form.content"
                placeholder="请输入"
                :auto-size="{minRows: 3}"
                :max-length="200"
            />
        </a-form-model-item>
        <a-form-model-item label="联系人" prop="contactPerson">
            <a-input
                v-model.trim="form.contactPerson"
                placeholder="请输入"
                :max-length="20"
            />
        </a-form-model-item>
        <a-form-model-item label="联系方式" prop="contactPersonPhone">
            <a-input
                v-model.trim="form.contactPersonPhone"
                placeholder="请输入"
                :max-length="50"
            />
        </a-form-model-item>
        <a-form-model-item label="下次跟进时间" prop="nextDataTime">
            <a-date-picker
                v-model="form.nextDataTime"
                show-time
                type="date"
                placeholder="请选择"
                style="width: 100%;"
                :disabled-date="disabledDateEnd"
            />
        </a-form-model-item>
        <a-form-model-item label="下次跟进内容" prop="nextContent">
            <a-text-area
                v-model.trim="form.nextContent"
                placeholder="请输入"
                :auto-size="{minRows: 3}"
                :max-length="200"
            />
        </a-form-model-item>
    </a-form-model>
</template>

<script>
// 组件
import {
    FormModel as AFormModel,
    Input as AInput,
    Radio as ARadio,
    DatePicker as ADatePicker
} from 'ant-design-vue'

// 枚举
import { CUSTOMER_FOLLOW_TYPE_ENUM_LIST } from '@constant/enum'
import moment from 'moment'

// 工具类
import { validatePhoneNumberAndLandlineNumber } from '@/utils/validate.js'

// 接口请求
import saveCustomerCommunicationService from '@service/customer/saveCustomerCommunicationService'

export default {
    name: 'FollowRecordFormPanel',
    components: {
        AFormModel,
        AFormModelItem: AFormModel.Item,
        AInput,
        ARadio,
        ARadioGroup: ARadio.Group,
        ATextArea: AInput.TextArea,
        ADatePicker
    },
    props: {
        currentRow: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            followMethodsData: CUSTOMER_FOLLOW_TYPE_ENUM_LIST,
            form: {
                // 跟进方式
                type: null,
                // 跟进时间
                dataTime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                // 跟进标题
                title: '',
                // 跟进内容
                content: '',
                // 联系人
                contactPerson: '',
                // 联系方式
                contactPersonPhone: '',
                // 下次跟进时间
                nextDataTime: undefined,
                // 下次跟进内容
                nextContent: ''
            },
            // 校验规则
            rules: {
                type: [
                    {
                        required: true,
                        message: '请选择跟进方式',
                        trigger: 'change'
                    }
                ],
                dataTime: [
                    {
                        required: true,
                        message: '请选择跟进时间',
                        trigger: 'change'
                    }
                ],
                title: [
                    {
                        required: true,
                        message: '请输入跟进标题',
                        trigger: 'blur'
                    }
                ],
                contactPersonPhone: [
                    {
                        required: false,
                        trigger: 'blur'
                    }, {
                        validator: validatePhoneNumberAndLandlineNumber,
                        trigger: 'blur'
                    }
                ],
            }
        }
    },
    computed: {
        // 用户信息缓存
        userInfo() {
            return this.$store.getters.userInfo
        }
    },
    watch: {},
    mounted() {},
    methods: {
        moment,
        /**
         * 下次跟进时间 > 跟进时间
         */
        disabledDateStart(current) {
            const { form } = this
            const { nextDataTime } = form
            if (nextDataTime && current) {
                let currentString = (new Date(moment(current).format('YYYY-MM-DD HH:mm:ss'))).valueOf()
                let nextDataTimeString = (new Date(moment(nextDataTime).format('YYYY-MM-DD HH:mm:ss'))).valueOf()
                return currentString > nextDataTimeString
            }
        },
        disabledDateEnd(current) {
            const { form } = this
            const { dataTime } = form
            if (dataTime && current) {
                let currentString = (new Date(moment(current).format('YYYY-MM-DD HH:mm:ss'))).valueOf()
                let dataTimeString = (new Date(moment(dataTime).format('YYYY-MM-DD HH:mm:ss'))).valueOf()
                return currentString < dataTimeString
            }
        },
        /**
         * 跟进记录
         */
        async handleSaveRecords() {
            try {
                this.$emit('loading', true)
                const params = this.formatParams()
                await saveCustomerCommunicationService(params)
                this.$message.success('添加成功')
                this.$emit('success')
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.$emit('loading', false)
            }
        },
        handleSend() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.handleSaveRecords()
                }
            })
        },
        /**
         * 格式化数据
         */
        formatParams() {
            const { form, currentRow } = this
            const { customerId } = currentRow
            const { dataTime, nextDataTime, ...params } = form
            const getParams = {
                customerId,
                // userId: userInfo.id, 后端反馈不传后端自己查
                ...params,
                dataTime: dataTime ? moment(dataTime).format('YYYY-MM-DD HH:mm:ss') : undefined,
                nextDataTime: nextDataTime ? moment(nextDataTime).format('YYYY-MM-DD HH:mm:ss') : undefined
            }
            return getParams
        }
    }
}
</script>

<style lang="scss">
.follow-record-modal {
    .ant-form-item {
        margin-bottom: 4px;
    }
    .ant-form-item-label {
        text-align: left;
        height: 35px;
    }
}
</style>
