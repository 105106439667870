<template>
    <div class="verify-identity-box">
        <a-form-model ref="formModel"
                      :rules="formRules"
                      :model="formModel">
            <a-form-model-item prop="phone">
                <a-input :value="privacyPhone(phone)"
                         disabled
                         type="text"
                         class="phone-wrap"
                         :max-length="11"
                         placeholder="请输入手机号">
                    <template slot="prefix">
                        <img src="@/assets/images/icon/phone.png"
                             width="20"
                             alt="">
                        <a-divider type="vertical" />
                    </template>
                </a-input>
                <div class="verify-identity-box--description">
                    (若该手机号已无法使用，请联系客服：{{ serviceTel }})
                </div>
            </a-form-model-item>
            <a-form-model-item prop="verifyCode">
                <send-verify-code v-model="formModel.verifyCode"
                                  :code-type="verifyCodeType.updatePassword"
                                  :send-verify="sendCodeVerify"
                                  :phone="phone" />
            </a-form-model-item>
            <a-form-model-item prop="captchaState">
                <click-captcha :decorator="['captcha']"
                               :change-arr="captchaChangeArr"
                               @change="changeCaptchaState" />
            </a-form-model-item>
            <a-form-model-item>
                <a-button type="primary"
                          class="next-btn"
                          :loading="loading"
                          block
                          @click="setStep">
                    下一步
                </a-button>
            </a-form-model-item>
        </a-form-model>
    </div>
</template>

<script>
import {
    FormModel as AFormModel,
    Input as AInput,
    Divider as ADivider,
    Button as AButton
} from 'ant-design-vue'
import ClickCaptcha from '@/components/ClickCaptcha'
import SendVerifyCode from '@/weights/SendVerifyCode'

import { VERIFY_CODE_TYPE } from '@/constant/enum'
import { SERVICE_TEL } from '@/config'
import privacyPhone from '@/mixins/privacyPhone'

import getAuthUserService from '@/service/getAuthUserService'
import updateUserVerifyIdentityService from '@/service/updateUserVerifyIdentityService'
export default {
    name: 'SetAccountVerifyIdentity',

    components: {
        AFormModel,
        AFormModelItem: AFormModel.Item,
        AInput,
        ADivider,
        ClickCaptcha,
        SendVerifyCode,
        AButton
    },
    mixins: [privacyPhone],
    data() {
        return {
            verifyCodeType: VERIFY_CODE_TYPE,
            serviceTel: SERVICE_TEL,
            loading: false,
            formModel: {
                verifyCode: '',
                captchaState: false
            },
            formRules: {
                verifyCode: [
                    {
                        required: true,
                        message: '请输入验证码'
                    }, {
                        number: 'number',
                        max: 8,
                        message: '请输入正确的验证码'
                    }
                ],

                captchaState: {
                    required: true,
                    validator: this.validateCaptchaState
                }
            }
        }
    },

    computed: {
        // captcha组件监听是否需要重置状态
        captchaChangeArr() {
            return {
                phone: this.formModel.phone,
                verifyCode: this.formModel.verifyCode
            }
        },

        phone() {
            return this.$store.getters.userInfo.mobile
        },

        appCode() {
            return this.$store.getters.userInfo.appCode
        }
    },

    // 生命周期 - 创建完成
    created() {

    },

    // DOM挂载完毕
    mounted() {

    },

    methods: {
        setStep() {
            let that = this,
                { phone, appCode, verifyCodeType, formModel: { verifyCode: smsCode } } = this


            this.$refs.formModel.validate(async valid => {
                if (valid) {
                    try {
                        that.loading = true
                        await updateUserVerifyIdentityService({
                            mobile: phone,
                            smsCode,
                            verifyType: verifyCodeType.verityIdentity
                        })
                        that.$emit('submit', that.formModel)
                    } catch (e) {
                        that.$message.error(e.message)
                    } finally {
                        that.loading = false
                    }
                }
            })
        },

        // 发送验证码之前校验
        sendCodeVerify() {
            let result = true

            return result
        },

        // captcha 回调
        changeCaptchaState(state) {
            this.formModel.captchaState = state
        },

        validateCaptchaState(rule, val, cb) {
            if (!val) {
                cb(new Error('请点击验证'))
            } else {
                cb()
            }
        }
    }
}
</script>
<style lang='scss'>
.verify-identity-box {
    .ant-input-affix-wrapper {
        .ant-input:not(:first-child) {
            padding-left: 52px;
        }
    }

    &--description {
        margin-top: 8px;
        color: #969799;
    }

    .phone-wrap {
        height: 36px;

        .ant-input-prefix {
            left: 16px;
        }

        .ant-input {
            height: 36px;
            font-size: 14px;
            border: 1px solid #dcdee0;
            border-radius: 2px;
        }
    }

    .next-btn {
        height: 36px;
        font-size: 16px;
    }
}
</style>
