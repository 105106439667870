<!-- 客户详情-线索记录 -->
<template>
    <container-panel class="lead-record-tab-panel">
        <!-- 筛选 start -->
        <template #screen>
            <search-panel slot="header" @search="handleListSearch" @refresh="handleListRefresh" />
        </template>
        <!-- 筛选 end -->

        <!-- 表格 start -->
        <template #content>
            <a-table
                size="middle"
                :columns="columns"
                :data-source="data"
                :pagination="pagination"
                :loading="loading"
                row-key="id"
                :scroll="{ x: '100%' }"
                @change="handleTableChange"
            >
                <!-- 类型 -->
                <template slot="sourceType" slot-scope="sourceType">
                    {{ getApiEnumLabelFromList(CUSTOMER_LEAD_SOURCE_TYPE_ENUM_LIST, sourceType) }}
                </template>
                <!-- 咨询内容 -->
                <template slot="listTitleName" slot-scope="listTitleName, action">
                    {{ empty(listTitleName) }}
                    <a-tag v-if="false" class="lead-record-tab-panel__tags">
                        <span class="lead-record-tab-panel__tags-text">
                            {{action.agentUserTypeLabel}}
                        </span>
                    </a-tag>
                </template>
                <!-- 订单状态 -->
                <template slot="state" slot-scope="state">
                    {{ getApiEnumLabelFromList(CUSTOMER_LEAD_ORDER_STATE_ENUM_LIST, state) }}
                </template>
                <!-- 操作 -->
                <template slot="action" slot-scope="action">
                    <custom-button text="查看" type="link" @click="handleDetail(action)" />
                </template>
            </a-table>
        </template>
        <!-- 筛选 end -->

        <!-- 详情 start -->
        <lead-record-detail
            :visible="visible"
            :id="id"
            @close="handleDetailClose"
        />
        <!-- 详情 end -->
    </container-panel>
</template>

<script>
    // 组件
    import { Tag as ATag } from 'ant-design-vue'
    import CustomButton from '@components/Button'
    import LeadRecordDetail from '@weights/Customer/Components/ComponentsDetail/Detail/LeadRecordDetail'
    import SearchPanel from './SearchPanel'

    // 常量
    import { CUSTOMER_LEAD_ORDER_STATE_ENUM_LIST, SALES_CATEGORY_DISTRIBUTION, CUSTOMER_LEAD_SOURCE_TYPE_ENUM_LIST } from '@/constant/enum'

    // mixins
    import common from '../mixins'

    // 接口请求
    import getPotentialCustomerSelectListPageService from '@service/customer/getPotentialCustomerSelectListPageService'

    export default {
        name: 'LeadRecordTabPanel',
        components: {
            ATag,
            CustomButton,
            SearchPanel,
            LeadRecordDetail
        },
        mixins: [common],
        props: {
            params: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
        data() {
            const columns = [
                {
                    title: '服务单号',
                    dataIndex: 'id',
                    key: 'id',
                    width: 200
                },
                {
                    title: '提交时间',
                    dataIndex: 'createDateTime',
                    key: 'createDateTime',
                    width: 200
                },
                {
                    title: '类型',
                    dataIndex: 'sourceType',
                    key: 'sourceType',
                    width: 200,
                    scopedSlots: {
                        customRender: 'sourceType'
                    }
                },
                {
                    title: '咨询内容',
                    dataIndex: 'listTitleName',
                    key: 'listTitleName',
                    width: 200,
                    ellipsis: true,
                    scopedSlots: {
                        customRender: 'listTitleName'
                    }
                },
                {
                    title: '企业名称',
                    dataIndex: 'enterpriseName',
                    key: 'enterpriseName',
                    width: 200,
                    customRender: (text) => {
                        return this.empty(text)
                    }
                },
                {
                    title: '地区',
                    dataIndex: 'provinceCode',
                    key: 'provinceCode',
                    width: 200,
                    customRender: (text, record) => {
                        const { provinceName, cityName, areaName } = record
                        const result = `${provinceName ? provinceName : ''}${cityName ? cityName : ''}${areaName ? areaName : ''}`

                        return this.empty(result)
                    }
                },
                {
                    title: '咨询人',
                    dataIndex: 'name',
                    key: 'name',
                    width: 200,
                    customRender: (text) => {
                        return this.empty(text)
                    }
                },
                {
                    title: '联系手机',
                    dataIndex: 'mobile',
                    key: 'mobile',
                    width: 200
                },
                {
                    title: '经纪人',
                    dataIndex: 'agentUserName',
                    key: 'agentUserName',
                    width: 200
                },
                {
                    title: '订单状态',
                    dataIndex: 'state',
                    width: 200,
                    scopedSlots: {
                        customRender: 'state'
                    }
                },
                {
                    title: '操作',
                    key: 'action',
                    fixed: 'right',
                    width: 60,
                    align: 'right',
                    scopedSlots: {
                        customRender: 'action'
                    }
                }
            ]

            return {
                CUSTOMER_LEAD_ORDER_STATE_ENUM_LIST,
                SALES_CATEGORY_DISTRIBUTION,
                CUSTOMER_LEAD_SOURCE_TYPE_ENUM_LIST,
                columns,
                // 客户id
                customerId: '',
                // 经纪人id
                agentUserIdQuery: '',
                id: '',
                // 查询条件
                searchParams: {},
                // 数据
                data: [],
                // 总记录数量
                total: 0,
                // 当前索引
                current: 1,
                // 加载状态
                loading: false,
                // 详情-抽屉
                visible: false
            }
        },
        watch: {
            params: {
                handler(params) {
                    const { customerId, agentUserIdQuery } = params
                    if (!(customerId && agentUserIdQuery)) return

                    this.customerId = customerId
                    this.agentUserIdQuery = agentUserIdQuery

                    // 获取列表
                    this.getList()
                },
                immediate: true
            }
        },
        mounted() {
        },
        methods: {
            /**
             * 获取 列表
             */
            async getList() {
                try {
                    this.loading = true
                    const {
                        customerId,
                        agentUserIdQuery,
                        searchParams,
                        current,
                        limit,
                        businessType,
                        SALES_CATEGORY_DISTRIBUTION
                    } = this
                    const cluesPort = businessType === SALES_CATEGORY_DISTRIBUTION ? '2' : '3'
                    const { total, records } = await getPotentialCustomerSelectListPageService({
                        customerId,
                        agentUserIdQuery,
                        current,
                        cluesPort,
                        size: limit,
                        ...searchParams
                    })
                    this.total = total || 0
                    this.data = records || []
                } catch (e) {
                    this.$message.error(e.message)
                    this.$captureException(e)
                } finally {
                    this.loading = false
                }
            },
            /**
             * 查看
             */
            handleDetail(action) {
                const { id } = action
                this.id = id
                this.visible = true
            }
        }
    }
</script>

<style lang="scss">
    @import '@assets/styles/varibles.scss';

    .lead-record-tab-panel {
        &__tags {
            padding: 1px 8px;
            background-color: #F2FBFF;
            border: none;
            border-radius: 2px;
            color: #1079C5;
            font-size: $font-sizeTwelve;
        }
    }
</style>
