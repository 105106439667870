<!-- 客户管理-客户维护管理-列表 -->
<template>
    <list-page-layout>
        <!-- title -->
        <common-title title="客户维护列表">
            <template #extra>
                <a-button
                    v-auth="PROPRIETARY_GROUP_CUSTOMER_ADD_BUTTON"
                    @click="handleImport"
                >
                    批量添加客户
                </a-button>
                <a-button
                    v-auth="PROPRIETARY_CUSTOMER_ADD_BUTTON"
                    class="button-box-shadow"
                    type="primary"
                    icon="plus-circle"
                    @click="handleOpenModal('showAddDistributionCustomerModal')"
                >
                    添加客户
                </a-button>
            </template>
        </common-title>
        <container-panel>
            <template #tabs>
                <customer-tab slot="header" @changetab="changeTab" />
            </template>
            <!-- 筛选 -->
            <template #screen>
                <search-panel
                    ref="tableRef"
                    slot="header"
                    :type="SALES_CATEGORY_SELF_SUPPORT_TEXT"
                    :echo-data="params"
                    @search="handleSearch"
                    @refresh="handleRefresh"
                />
            </template>
            <template #button>
                <a-button
                    v-auth="PROPRIETARY_CUSTOMER_BATCH_TRANSFER_SEAS_BUTTON"
                    type="primary"
                    :class="[selectedRows.length ? 'button-box-shadow' : '']"
                    :disabled="!selectedRows.length"
                    @click="handleOpenModal('showBatchTransferSeasModal')"
                >
                    <img :src="seaIcon" alt="" class="proprietary-customer-icon-img">
                    批量移入公海
                </a-button>
            </template>

            <!-- 表格 start -->
            <template #content>
                <a-table
                    size="middle"
                    :columns="proprietaryColumns"
                    :data-source="data"
                    :pagination="pagination"
                    :loading="loading"
                    row-key="id"
                    :components="headerColumnTitle"
                    :scroll="scrollConfig"
                    :row-selection="rowSelection"
                    @change="handleTableChange"
                >
                    <!-- 客户意向 -->
                    <!-- <template slot="intention" slot-scope="text">
                        <span>{{ text ? getApiEnumLabelFromList(CUSTOMER_INTENTION_ENUM_LIST, String(text)) : '-' }}</span>
                    </template> -->
                    <!-- 操作 -->
                    <template slot="action" slot-scope="action">
                        <div class="action-button-list">
                            <a-button
                                v-auth="PROPRIETARY_CUSTOMER_DETAIL_MENU_CODE"
                                type="link"
                                @click="handleClickLook(action)"
                            >
                                查看
                            </a-button>
                            <template v-if="handleIsLoginUserId(action.agentUserId)">
                                <a-divider type="vertical" />
                                <a-button
                                    v-auth="PROPRIETARY_CUSTOMER_FOLLOW_RECORD_BUTTON"
                                    type="link"
                                    @click="handleOpenModal('showFollowRecordModal', action)"
                                >
                                    跟进记录
                                </a-button>
                            </template>
                            <a-divider type="vertical" />
                            <a-button
                                v-auth="PROPRIETARY_CUSTOMER_TRANSFER_SEAS_BUTTON"
                                type="link"
                                @click="handleOpenModal('showTransferSeasModal', action)"
                            >
                                移入公海
                            </a-button>
                            <a-divider type="vertical" />
                            <a-button
                                v-auth="PROPRIETARY_CUSTOMER_HAND_CUSTOMER_BUTTON"
                                type="link"
                                @click="handleOpenModal('showHandCustomerModal', action)"
                            >
                                转交客户
                            </a-button>
                        </div>
                    </template>
                </a-table>
            </template>
            <!-- 添加客户弹框 -->
            <add-customer-modal
                :visible="showAddDistributionCustomerModal"
                title="添加客户"
                :type="SALES_CATEGORY_SELF_SUPPORT_TEXT"
                @close="handleCloseModal('showAddDistributionCustomerModal')"
                @success="handleSuccessModal('showAddDistributionCustomerModal')"
            />
            <!-- 跟进记录弹框 -->
            <follow-record-modal
                :visible="showFollowRecordModal"
                title="添加跟进记录"
                :current-row="currentRow"
                @close="handleCloseModal('showFollowRecordModal')"
                @success="handleSuccessModal('showFollowRecordModal')"
            />
            <!-- 移入公海弹框 -->
            <transfer-seas-modal
                :visible="showTransferSeasModal"
                title="移入公海"
                :current-row="currentRow"
                @close="handleCloseModal('showTransferSeasModal')"
                @success="handleSuccessModal('showTransferSeasModal')"
            />
            <!-- 批量移入公海弹框 -->
            <batch-transfer-seas-modal
                :visible="showBatchTransferSeasModal"
                title="批量移入公海"
                :selected-rows="selectedRows"
                @close="handleCloseModal('showBatchTransferSeasModal')"
                @success="handleSuccessModal('showBatchTransferSeasModal')"
            />
            <!-- 转交客户弹框 -->
            <hand-customer-modal
                v-if="showHandCustomerModal"
                :visible="showHandCustomerModal"
                title="转交客户"
                :current-row="currentRow"
                :type="SALES_CATEGORY_SELF_SUPPORT_TEXT"
                @close="handleCloseModal('showHandCustomerModal')"
                @success="handleSuccessModal('showHandCustomerModal')"
            />
            <!-- 模态框 -->
            <component v-bind="modalProp" :is="modalComponent" @on-close="handleCloseImportModal" @on-refresh="getList" />
        </container-panel>
    </list-page-layout>
</template>

<script>
// 组件
import TransferSeasModal from './TransferSeasModal'
import BatchTransferSeasModal from './BatchTransferSeasModal'
import HandCustomerModal from './HandCustomerModal'
import CustomerTab from './CustomerTab'
import ImportModal from './ImportModal'

// 常量
import { DEFAULT_PAGES_SIZE } from '@config'
import { CUSTOMER_INTENTION_ENUM_LIST, SALES_CATEGORY_SELF_SUPPORT_TEXT, SALES_CATEGORY_SELF_SUPPORT } from '@constant/enum'
import { scrollConfig } from '@constant/listScrollConfig'

// 权限
import {
    PROPRIETARY_CUSTOMER_DETAIL_MENU_CODE
} from '@constant/menu_code'
import {
    PROPRIETARY_CUSTOMER_ADD_BUTTON,
    PROPRIETARY_GROUP_CUSTOMER_ADD_BUTTON,
    PROPRIETARY_CUSTOMER_BATCH_TRANSFER_SEAS_BUTTON,
    PROPRIETARY_CUSTOMER_FOLLOW_RECORD_BUTTON,
    PROPRIETARY_CUSTOMER_TRANSFER_SEAS_BUTTON,
    PROPRIETARY_CUSTOMER_HAND_CUSTOMER_BUTTON
} from '@constant/authEnum/proprietaryCustomer'

// mixins
import tableShowTotal from '@mixins/tableShowTotal'
import mixins from '@views/Customer/Components/mixins'
import enumOperate from '@mixins/enumOperate'
import isLoginUserId from '@mixins/isLoginUserId'

// 接口请求
import getProprietaryCustomerListService from '@service/customer/proprietaryCustomer/getProprietaryCustomerListService'

export default {
    name: 'ProprietaryCustomerList',
    components: {
        TransferSeasModal,
        BatchTransferSeasModal,
        HandCustomerModal,
        CustomerTab,
        ImportModal
    },
    mixins: [tableShowTotal,
        mixins,
        enumOperate,
        isLoginUserId],
    data() {
        return {
            PROPRIETARY_CUSTOMER_DETAIL_MENU_CODE,
            PROPRIETARY_CUSTOMER_ADD_BUTTON,
            PROPRIETARY_GROUP_CUSTOMER_ADD_BUTTON,
            PROPRIETARY_CUSTOMER_BATCH_TRANSFER_SEAS_BUTTON,
            PROPRIETARY_CUSTOMER_FOLLOW_RECORD_BUTTON,
            PROPRIETARY_CUSTOMER_TRANSFER_SEAS_BUTTON,
            PROPRIETARY_CUSTOMER_HAND_CUSTOMER_BUTTON,
            CUSTOMER_INTENTION_ENUM_LIST,
            SALES_CATEGORY_SELF_SUPPORT_TEXT,
            SALES_CATEGORY_SELF_SUPPORT,
            // 查询条件
            params: {},
            // 数据
            data: [],
            // 分页数量
            limit: DEFAULT_PAGES_SIZE,
            // 总记录数量
            total: 0,
            // 当前索引
            current: 1,
            // 加载状态
            loading: false,
            // 添加客户弹框相关参数
            showAddDistributionCustomerModal: false,
            // 跟进记录弹框相关参数
            showFollowRecordModal: false,
            // 当前行数据
            currentRow: null,
            // 移入公海弹框
            showTransferSeasModal: false,
            // 批量移入公海弹框
            showBatchTransferSeasModal: false,
            // 转交客户弹框
            showHandCustomerModal: false,
            // 批量移入公海选中
            selectedRows: [],
            selectedRowsObj: {},
            iconNormal: require('@assets/images/transfer@2x.png'),
            iconDisabled: require('@assets/images/transfer_disabled@2x.png'),
            // 重置数据
            isResetSearch: false,
            scrollConfig,
            // 是否已跟进
            connectionState: false,
            // 模态框
            // 模态框组件
            modalComponent: '',
            // 给模态框的传参
            modalProp: { visible: false }
        }
    },
    computed: {
        pagination() {
            const {
                total,
                limit,
                current,
                tableShowTotal
            } = this
            return {
                showTotal: (total, range) => tableShowTotal(total, range),
                current,
                total,
                pageSize: limit,
                showSizeChanger: true,
                showQuickJumper: true
            }
        },
        rowSelection() {
            return {
                fixed: true,
                onChange: (selectedRowKeys, selectedRows) => {
                    this.selectedRowsObj[this.current] = selectedRows
                    this.selectedRows = Object.values(this.selectedRowsObj).flat()
                }
            }
        },
        seaIcon() {
            const { selectedRows, iconNormal, iconDisabled } = this
            return selectedRows && selectedRows.length ? iconNormal : iconDisabled
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        /**
         * 获取 列表
         */
        async getList() {
            try {
                this.loading = true
                const { params, current, limit } = this
                const { total, records } = await getProprietaryCustomerListService({
                    connectionState: this.connectionState,
                    current,
                    size: limit,
                    ...params
                })
                this.total = total || 0
                this.data = records || []
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.loading = false
            }
        },
        // 导入
        handleImport() {
            this.handleOpenImportModal('ImportModal')
        },
        // 打开模态框
        handleOpenImportModal(modalComponent, props) {
            Object.assign(this, {
                modalComponent,
                modalProp: {
                    ...props,
                    visible: true
                }
            })
        },
        // 关闭模态框
        handleCloseImportModal() {
            this.$set(this.modalProp, 'visible', false)
        },
        /**
         * 打开弹框
         */
        handleOpenModal(modalName, action) {
            this[modalName] = true
            this.currentRow = action ? action : null
        },
        /**
         * 关闭弹框
         */
        handleCloseModal(modalName) {
            this[modalName] = false
        },
        /**
         * 成功弹框
         */
        handleSuccessModal(modalName) {
            this[modalName] = false
            this.current = 1
            this.getList()
        },
        /**
         * 查看
         */
        handleClickLook(action) {
            const { SALES_CATEGORY_SELF_SUPPORT, current, limit, isResetSearch, params } = this
            const { customerId, agentUserId } = action
            this.$router.push({
                name: 'ProprietaryCustomerDetail',
                query: isResetSearch ? {
                    customerId,
                    agentUserId,
                    businessType: SALES_CATEGORY_SELF_SUPPORT
                } : {
                    customerId,
                    agentUserId,
                    businessType: SALES_CATEGORY_SELF_SUPPORT,
                    params: JSON.stringify(params),
                    current,
                    limit
                }
            })
        },
        /**
         * 响应 table change 事件
         * @param pagination
         */
        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.current = current
            this.limit = pageSize
            this.getList()
        },
        /**
         * 搜索
         */
        handleSearch(params) {
            this.current = 1
            this.params = params
            this.getList()
            this.isResetSearch = false
        },
        /**
         * 刷新
         */
        handleRefresh(params) {
            this.handleSearch(params)
            this.isResetSearch = true
        },
        /**
         * 切换tab
         */
        changeTab(param) {
            this.connectionState = param.hasConnection !== '1'

            this.$refs.tableRef.resetForm() // 重载列表

        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (from.name === 'ProprietaryCustomerDetail') {
                if (!vm.isResetSearch && Object.getOwnPropertyNames(from.query).length > 0 ) {
                    const { params, current, limit } = from.query
                    vm.params = params && JSON.parse(params) || {}
                    vm.current = +current || 1
                    vm.limit = +limit || DEFAULT_PAGES_SIZE
                }
            }
        })
    }
}
</script>
<style lang='scss'>
.proprietary-customer-icon-img {
    width: 14px;
    height: 14px;
    margin-right: 6px;
}
.action-button-list {
    display: flex;
    align-items: center;
}
</style>

