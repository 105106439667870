import {BASE_URL} from '@config'

/** 线索分配
 * ————————————————————————————————————————————————————————————————————————————————————————————*/

/**
 * 线索分配-列表
 *
 */
export const GET_CLUE_DISTRIBUTE_LIST = {
    url: `${BASE_URL}/customer/customerManagerRuleConfiguration/selectList`,
    method: 'get',
    headers: {
        'content-type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/**
 * 线索分配-新增
 * @type {{}}
 */
export const CREATE_CLUE_DISTRIBUTE = {
    url: `${BASE_URL}/customer/customerManagerRuleConfiguration/save`,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/**
 * 线索分配-修改
 * @type {{headers: {"content-type": string}, method: string, url: string, timeout: number}}
 */
export const UPDATE_CLUE_DISTRIBUTE = {
    url: `${BASE_URL}/customer/customerManagerRuleConfiguration/update`,
    method: 'put',
    headers: {
        'content-type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}