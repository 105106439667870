<!-- 线索详情 -->
<template>
    <custom-drawer
        title="线索详情"
        v-bind="$attrs"
        :after-visible-change="afterVisibleChange"
        placement="right"
        :loading="loading"
        v-on="$listeners"
    >
        <div slot="top" class="status">
            <img :src="stateImg">
            <p>当前订单状态：{{ getStateName(detail.state) }}</p>
        </div>
        <div slot="content" key="基本信息" class="content-body">
            <custom-descriptions bordered :value="detail" :options="baseOptions" />
        </div>
        <div slot="content"  key="操作记录" class="content-body">
            <a-table size="middle" row-key="id" :pagination="false" :data-source="recordList || []" :columns="recordOptions" />
        </div>
    </custom-drawer>
</template>
<script>
import { Table as ATable } from 'ant-design-vue'
import CustomDescriptions from '@/weights/CustomDescriptions'
import getPotentialCustomerSelectOneService from '@service/customer/getPotentialCustomerSelectOneService'
import getPotentialCustomerLogSelectService from '@service/customer/getPotentialCustomerLogSelectService'
import { getLabelformList } from '@/utils'
import {
    CUSTOMER_LEAD_ORDER_STATE_ENUM_LIST,
    CUSTOMER_LEAD_ORDER_STATE_CANCEL,
    CUSTOMER_LEAD_ORDER_STATE_WE_CLOSE,
    CUSTOMER_LEAD_ORDER_STATE_WE_COMPLETED,
    CUSTOMER_LEAD_SOURCE_TYPE_ENUM_LIST
} from '@/constant/enum'
import CustomDrawer from '@/weights/CustomDrawer'

export default {
    name: 'LeadRecordDetail',
    components: {
        CustomDrawer,
        ATable,
        CustomDescriptions
    },
    props: {
        detailId: {
            type: null,
            default: null
        }
    },
    data() {
        return {
            loading: false,
            detail: { },
            recordList: [],
            baseOptions: [
                {
                    title: '服务单号',
                    key: 'id'
                },
                {
                    title: '提交时间',
                    key: 'submitTime'
                },
                {
                    title: '咨询人',
                    key: 'name'
                },
                {
                    title: '联系手机',
                    key: 'mobile'
                },
                {
                    title: '咨询预约时间',
                    key: 'appointmentContactDateTime'
                },
                {
                    title: '产品名称',
                    key: 'productSpuName'
                },
                {
                    title: '险种大类',
                    key: 'dangerPlantedCategoryName'
                },
                {
                    title: '咨询险种',
                    key: 'dangerPlantedName'
                },
                {
                    title: '企业名称',
                    key: 'enterpriseName'
                },
                {
                    title: '地区',
                    key: 'area'
                },
                {
                    title: '项目类型',
                    key: 'projectTypeName'
                },
                {
                    title: '保险服务',
                    key: 'projectTypeValueName'
                },
                {
                    title: '项目名称',
                    key: 'projectName'
                },
                {
                    title: '经纪人',
                    key: 'agentUserName'
                },
                {
                    title: '订单来源/需求备注',
                    key: 'description'
                },
                {
                    title: '类型',
                    key: 'sourceType',
                    customRender: getLabelformList(CUSTOMER_LEAD_SOURCE_TYPE_ENUM_LIST)
                }
            ],
            recordOptions: [
                {
                    title: '操作人',
                    dataIndex: 'operator'
                },
                {
                    title: '操作时间',
                    dataIndex: 'finishedTime'
                },
                {
                    title: '订单状态',
                    dataIndex: 'state'
                },
                {
                    title: '操作内容',
                    dataIndex: 'operationContent'
                }
            ]
        }
    },
    computed: {
        stateImg() {
            switch (this.detail.state) {
                case CUSTOMER_LEAD_ORDER_STATE_CANCEL: // 取消
                case CUSTOMER_LEAD_ORDER_STATE_WE_CLOSE: // 关闭
                    return require('@/assets/images/error.png')
                case CUSTOMER_LEAD_ORDER_STATE_WE_COMPLETED: // 已完成
                    return require('@/assets/images/success.png')
                default:
                    return require('@/assets/images/waiting.png')
            }
        }
    },
    methods: {
        // 获取订单状态中文显示
        getStateName: getLabelformList(CUSTOMER_LEAD_ORDER_STATE_ENUM_LIST),
        async init() {
            this.loading = true
            try {
                // 操作记录接口
                const recordList = await getPotentialCustomerLogSelectService({
                    potentialCustomerId: this.detailId,
                    pageNumber: 1,
                    pageSize: 9999
                })
                this.recordList = recordList?.records || []
                // 详情接口
                const data = await getPotentialCustomerSelectOneService({ id: this.detailId }) || {}

                const detail = {
                    ...data,
                    productSpuName: data.productSpu?.name || '',
                    area: [data.provinceName || '', data.cityName || '', data.areaName || ''].join(' ')
                }
                this.detail = detail || {}
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.loading = false
            }
        },
        afterVisibleChange(visible) {
            if (visible) {
                this.init()
            } else {
                this.detail = {}
                this.recordList = []
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../common";
</style>


