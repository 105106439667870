/**
 * 菜单code
 * @type {string}
 */

/**
 * 线索旧管理code
 * ——————————————————————————————————————————————————————————————————————————————————————————————
 */
// 线索旧管理
export const CLUE_MENU_CODE = 'CLUE'
// 线索旧分配
export const CLUE_MANAGEMENT_CLUE_DISTRIBUTE_MENU_CODE = 'CLUE_MANAGEMENT_CLUE_DISTRIBUTE'
// 线索旧分配-列表
export const CLUE_MANAGEMENT_CLUE_DISTRIBUTE_LIST_MENU_CODE = 'CLUE_MANAGEMENT_CLUE_DISTRIBUTE_LIST'
// 客户线索旧
export const CLIENT_CLUE_MENU_CODE = 'CLIENT_CLUE'
// 客户线索旧-列表
export const CLIENT_CLUE_LIST_MENU_CODE = 'CLIENT_CLUE_LIST'
// 经纪人线索旧
export const BROKER_CLUE_MENU_CODE = 'BROKER_CLUE'
// 经纪人线索旧-列表
export const BROKER_CLUE_LIST_MENU_CODE = 'BROKER_CLUE_LIST'

/**
 * 线索管理code
 * ——————————————————————————————————————————————————————————————————————————————————————————————
 */
// 线索管理
export const CLEW_MENU_CODE = 'CLEW'
// 客户线索
export const CLIENT_CLEW_MENU_CODE = 'CLIENT_CLEW'
// 客户线索-列表
export const CLIENT_CLEW_LIST_MENU_CODE = 'CLIENT_CLEW_LIST'

/*
* 企业
* */
export const ENTERPRISE_MENU_CODE = 'ENTERPRISE'
export const ENTERPRISE_MANAGE_MENU_CODE = 'ENTERPRISE_MANAGE'
export const ENTERPRISE_LIST_MENU_CODE = 'ENTERPRISE_LIST'
export const ENTERPRISE_AUTHORIZATION_MENU_CODE = 'ENTERPRISE_AUTHORIZATION'
export const ENTERPRISE_AUTHORIZATION_LIST_MENU_CODE = 'ENTERPRISE_AUTHORIZATION_LIST'
export const ENTERPRISE_AUTHORIZATION_DETAIL_MENU_CODE = 'ENTERPRISE_AUTHORIZATION_DETAIL'
export const ENTERPRISE_AUTHORIZATION_ADD_MENU_CODE = 'ENTERPRISE_AUTHORIZATION_ADD'
export const ENTERPRISE_AUTHORIZATION_PRODUCT_LIST_MENU_CODE = 'ENTERPRISE_AUTHORIZATION_PRODUCT_LIST'
export const ENTERPRISE_AUTHORIZATION_RENEW_AUTHENTICATION_MENU_CODE = 'ENTERPRISE_AUTHORIZATION_RENEW_AUTHENTICATION'
export const ENTERPRISE_AUTHORIZATION_RENEW_VALID_MENU_CODE = 'ENTERPRISE_AUTHORIZATION_RENEW_VALID'

/*
* 管家
* */
export const STEWARD_MENU_CODE = 'STEWARD'
export const STEWARD_MANAGE_MENU_CODE = 'STEWARD_MANAGE'
export const STEWARD_LIST_MENU_CODE = 'STEWARD_LIST'

// 管家-业务关联列表
export const BUSINESS_ASSOCIATION_MENU_CODE = 'BUSINESS_ASSOCIATION'
export const BUSINESS_ASSOCIATION_LIST_MENU_CODE = 'BUSINESS_ASSOCIATION_LIST'
/*
* 保险公司
* */
export const INSURE_COMPANY_MENU_CODE = 'INSURE_COMPANY'
export const INSURE_COMPANY_MANAGE_MENU_CODE = 'INSURE_COMPANY_MANAGE'
export const INSURE_COMPANY_LIST_MENU_CODE = 'INSURE_COMPANY_LIST'
/*
* 订单(旧)
* */
export const ORDER_MENU_CODE = 'ORDER'
export const ORDER_MANAGE_MENU_CODE = 'ORDER_MANAGE'
export const ORDER_LIST_MENU_CODE = 'ORDER_LIST'
/*
* 客户
* */
export const CUSTOMER_MENU_CODE = 'CUSTOMER'
export const CUSTOMER_MANAGE_MENU_CODE = 'CUSTOMER_MANAGE'
export const CUSTOMER_LIST_MENU_CODE = 'CUSTOMER_LIST'
export const HIGHSEA_CUSTOMER_MENU_CODE = 'HIGHSEA_CUSTOMER'
export const HIGHSEA_CUSTOMER_LIST_MENU_CODE = 'HIGHSEA_CUSTOMER_LIST'
export const HIGHSEA_CUSTOMER_DETAIL_MENU_CODE = 'HIGHSEA_CUSTOMER_DETAIL'

/*
* 客户跟进
* */
// 客户跟进
export const DISTRIBUTION_CUSTOMER_MENU_CODE = 'DISTRIBUTION_CUSTOMER'
// 客户跟进-列表
export const DISTRIBUTION_CUSTOMER_LIST_MENU_CODE = 'DISTRIBUTION_CUSTOMER_LIST'
// 客户跟进-详情
export const DISTRIBUTION_CUSTOMER_DETAIL_MENU_CODE = 'DISTRIBUTION_CUSTOMER_DETAIL'

/*
* 客户维护
* */
// 客户维护
export const PROPRIETARY_CUSTOMER_MENU_CODE = 'PROPRIETARY_CUSTOMER'
// 客户维护-列表
export const PROPRIETARY_CUSTOMER_LIST_MENU_CODE = 'PROPRIETARY_CUSTOMER_LIST'
// 客户维护-详情
export const PROPRIETARY_CUSTOMER_DETAIL_MENU_CODE = 'PROPRIETARY_CUSTOMER_DETAIL'

/*
* 经纪人
* */
export const BROKER_MENU_CODE = 'BROKER'
export const BROKER_MANAGE_MENU_CODE = 'BROKER_MANAGE'
export const BROKER_LIST_MENU_CODE = 'BROKER_LIST'

/**
 * 用户操作
 * ——————————————————————————————————————————————————
 */

export const USER_ACTION_MENU_CODE = 'USER_ACTION' // 用户操作
export const USER_ACTION_UPDATE_PASSWORD_MENU_CODE = 'USER_ACTION_UPDATE_PASSWORD' // 用户修改密码

/*
* 工作台
* */
// 工作台
export const WORK_SPACE_MENU_CODE = 'WORK_SPACE'
// 团队管理
export const WORKSPACE_TEAM_MANAGE_MENU_CODE = 'WORKSPACE_TEAM_MANAGE'
// 保单管理
export const WORKSPACE_POLICY_MANAGE_MENU_CODE = 'WORKSPACE_POLICY_MANAGE'

/**
* 产品列表
 */
// 产品列表
export const PRODUCT_PAGE_LIST_MENU_CODE = 'PRODUCT_PAGE_LIST'
// 产品列表-列表
export const PRODUCT_PAGE_LIST_LIST_MENU_CODE = 'PRODUCT_PAGE_LIST_LIST'

/**
 * 推广获客
 * ——————————————————————————————————————————————————
 */
// 推广获客
export const PROMOTE_GUEST_MENU_CODE = 'PROMOTE_GUEST'
// 推广获客-列表
export const PROMOTE_GUEST_LIST_MENU_CODE = 'PROMOTE_GUEST_LIST'


/**
 * 订单管理
 */
// 订单管理
export const ORDER_MANAGE_NEW_MENU_CODE = 'ORDER_MANAGE_NEW'
// 订单列表
export const ORDER_MANAGE_NEW_LIST_MENU_CODE = 'ORDER_MANAGE_NEW_LIST'

/**
 * 保单管理
 * ——————————————————————————————————————————————————
 */
export const POLICY_MANAGE_MENU_CODE = 'POLICY_MANAGE'
export const POLICY_MANAGE_LIST_MENU_CODE = 'POLICY_MANAGE_LIST'

/**
* 保单分析
 */
// 保单分析-G
export const POLICY_ANALYSIS_LIST_G_MENU_CODE = 'POLICY_ANALYSIS_LIST_G'
// 保单分析-G-列表
export const POLICY_ANALYSIS_LIST_LIST_G_MENU_CODE = 'POLICY_ANALYSIS_LIST_LIST_G'

/**
 * G端平台系统
 */
// G端平台系统
export const G_PLATFORM_MENU_CODE = 'G_PLATFORM'
// G端平台系统 列表
export const G_PLATFORM_LIST_MENU_CODE = 'G_PLATFORM_LIST'

/**
 * 中标资讯
 */
// 中标资讯
export const WINNING_INFO_MENU_CODE = 'WINNING_INFO'
// 中标资讯 列表
export const WINNING_INFO_LIST_MENU_CODE = 'WINNING_INFO_LIST'
