/**
 * 推广获客
 */


import BasicLayout from '@layout/BasicLayout'
import { PROMOTE_GUEST_MENU_CODE, PROMOTE_GUEST_LIST_MENU_CODE } from '@constant/menu_code'

const routes = [{
    path: '/promote_guest',
    name: 'PromoteGuest',
    component: BasicLayout,
    code: PROMOTE_GUEST_MENU_CODE,
    redirect: '/promote_guest/list',
    meta: {
        title: '推广获客',
        icon: 'a-bianzu11',
        iconType: 'iconfont'
    },
    children: [{
        path: 'list',
        name: 'PromoteGuestList',
        code: PROMOTE_GUEST_LIST_MENU_CODE,
        component: () => import('./List'),
        hidden: true,
        meta: { breadcrumb: false }
    }]
}]

export default routes
