
import './assets/fonts/iconfont.css'
import 'nprogress/nprogress.css'
import './assets/styles/index.scss'


import NProgress from 'nprogress' // progress bar
import Vue from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import Auth from './directive/auth'
import './router/permission'
import { captureException, captureMessage, setUser } from '@utils/log'
import { setVm } from '@utils/vm'

import { message, Modal, notification } from 'ant-design-vue'
import Fragment from 'vue-fragment'
import moment from 'moment'

// 全局注册常用组件
[
    'Row',
    'Col',
    'Button',
    'Spin',
    'Icon'
].forEach(i => Vue.component(`A${i}`, require('ant-design-vue')[i]))

Vue.use(Fragment.Plugin)

const isDebugMode = process.env.NODE_ENV !== 'production'
Vue.config.debug = isDebugMode
Vue.config.devtools = isDebugMode
Vue.config.productionTip = isDebugMode

// 注册 鉴权指令
Vue.use(Auth)
Vue.use(Modal)


Vue.prototype.$message = message
Vue.prototype.$notification = notification
Vue.prototype.$info = Modal.info
Vue.prototype.$success = Modal.success
Vue.prototype.$error = Modal.error
Vue.prototype.$warning = Modal.warning
Vue.prototype.$confirm = Modal.confirm
Vue.prototype.$destroyAll = Modal.destroyAll

Vue.prototype.$captureException = captureException
Vue.prototype.$captureMessage = captureMessage
Vue.prototype.$setSentryUser = setUser

// // NProgress Configuration
NProgress.configure({ showSpinner: false })

Vue.prototype.$moment = moment
Vue.prototype.$formatDateTime = date => date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : ''

window.NProgress = NProgress

const vm = new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

setVm(vm)
