<!-- 订单管理-列表 -->
<template>
    <list-page-layout class="order-manage-page">
        <!-- title -->
        <common-title title="订单管理" />
        <container-panel>
            <!-- 筛选 -->
            <template #screen>
                <search-panel
                    slot="header"
                    @get-init="handleGetInit"
                    @search="handleSearch"
                    @refresh="handleRefresh"
                />
            </template>

            <!-- 表格 start -->
            <template #content>
                <a-table
                    size="middle"
                    :columns="columns"
                    :data-source="tableListAddType"
                    :pagination="pagination"
                    :loading="loading"
                    row-key="castInsuranceId"
                    :scroll="{ x: '100%', y: 360 }"
                    @change="handleTableChange"
                >
                    <!-- 投保用户 -->
                    <template slot="customerInsure" slot-scope="customerInsure">
                        <template v-if="customerInsure.castUserMobile || customerInsure.castInsuranceUserId">
                            <div :class="[customerInsure.potentialCustomerId && customerInsure.hasAuthDetail ? 'link-user' : 'default-user']"  @click="jumpToCustomerDetail(customerInsure)">{{ `${customerInsure.castUserMobile ? customerInsure.castUserMobile : ''}` }}</div>
                            <div :class="[customerInsure.potentialCustomerId && customerInsure.hasAuthDetail ? 'link-user' : 'default-user']"  @click="jumpToCustomerDetail(customerInsure)">{{ `${customerInsure.castInsuranceUserId ? '(' + customerInsure.castInsuranceUserId + ')' : ''}` }}</div>
                        </template>
                        <span v-else>-</span>
                    </template>
                    <!-- 操作 -->
                    <template slot="action" slot-scope="action">
                        <a-button
                            v-auth="ORDER_MANAGE_LIST_VIEW_DETAIL_BUTTON"
                            type="link"
                            @click="handleClickLook(action)"
                        >
                            查看
                        </a-button>
                    </template>
                </a-table>
            </template>
            <!-- 详情 start -->
            <order-record-detail
                :visible="visible"
                :cast-id="castId"
                :customer-id="customerId"
                :agent-user-id="agentUserId"
                @close="handleDetailClose"
            />
        <!-- 详情 end -->
        </container-panel>
    </list-page-layout>
</template>

<script>
// 组件
import {
    Table as ATable
} from 'ant-design-vue'
import ListPageLayout from '@layout/ListPageLayout'
import CommonTitle from '@components/CommonTitle'
import ContainerPanel from '@components/ContainerPanel'
import SearchPanel from './SearchPanel'
import OrderRecordDetail from '@weights/Customer/Components/ComponentsDetail/Detail/OrderRecordDetail'
// 常量
import { DEFAULT_PAGES_SIZE } from '@config'
import {
    CUSTOMER_ORDER_ORDER_STATE_ENUM_LIST,
    SALES_CATEGORY_USE_ENUM_LIST,
    SALES_CATEGORY_DISTRIBUTION_TEXT,
    SALES_CATEGORY_SELF_SUPPORT_TEXT
} from '@constant/enum'
import { getLabelformList } from '@/utils'

import {
    ORDER_MANAGE_LIST_VIEW_DETAIL_BUTTON
} from '@constant/authEnum/orderManage'

// mixins
import tableShowTotal from '@mixins/tableShowTotal'
import enumOperate from '@mixins/enumOperate'
import empty from '@mixins/empty'
import searchTeamUser from '@/mixins/searchTeamUser'

// 接口请求
import getOrderManageListService from '@service/orderManage/getOrderManageListService'

export default {
    name: 'OrderManagePageList',
    components: {
        ATable,
        ListPageLayout,
        CommonTitle,
        ContainerPanel,
        SearchPanel,
        OrderRecordDetail
    },
    mixins: [tableShowTotal, enumOperate, empty, searchTeamUser],
    data() {
        const columns = [
            {
                title: '投保单号',
                dataIndex: 'castInsuranceId',
                key: 'castInsuranceId',
                width: 200,
                customRender: text => this.empty(text)
            },
            {
                title: '提交时间',
                dataIndex: 'createDateTime',
                key: 'createDateTime',
                width: 150,
                customRender: text => this.empty(text)
            },
            {
                title: '险种',
                dataIndex: 'dangerPlantedName',
                width: 200,
                ellipsis: true,
                customRender: text => this.empty(text)
            },
            {
                title: '保险公司',
                dataIndex: 'insuranceCompany',
                width: 200,
                ellipsis: true,
                customRender: text => this.empty(text)
            },
            {
                title: '产品名称',
                dataIndex: 'spuName',
                width: 200,
                ellipsis: true,
                customRender: text => this.empty(text)
            },
            {
                title: '投保用户',
                key: 'customerInsure',
                width: 200,
                scopedSlots: { customRender: 'customerInsure' }
            },
            {
                title: '投保人',
                dataIndex: 'castInsuranceEnterpriseName',
                ellipsis: true,
                width: 200,
                customRender: text => this.empty(text)
            },
            {
                title: '被保人',
                dataIndex: 'castInsuranceDestName',
                ellipsis: true,
                width: 200,
                customRender: text => this.empty(text)
            },
            {
                title: '项目名称',
                dataIndex: 'projectName',
                ellipsis: true,
                width: 200,
                customRender: text => this.empty(text)
            },
            {
                title: '保险金额（元）',
                dataIndex: 'insuranceMoney',
                width: 200,
                customRender: text => {
                    return text ? (text * 1).toFixed(2) : '0.00'
                }
            },
            {
                title: '保费（元）',
                dataIndex: 'payMoney',
                width: 200,
                customRender: text => {
                    return text ? (text * 1).toFixed(2) : '0.00'
                }
            },
            {
                title: '操作经纪人',
                dataIndex: 'agentName',
                width: 200,
                customRender: (text, record) => {
                    const { agentName, agentUserId } = record
                    if (!agentName && !agentUserId) {
                        return this.empty(agentName)
                    } else {
                        return `${this.empty(agentName)}（${this.empty(agentUserId)}）`
                    }
                }
            },
            {
                title: '成交经纪人',
                dataIndex: 'dealHousekeeperName',
                width: 200,
                customRender: (text, record) => {
                    const { dealHousekeeperName, dealHousekeeperId } = record
                    if (!dealHousekeeperName && !dealHousekeeperId) {
                        return this.empty(dealHousekeeperName)
                    } else {
                        return `${this.empty(dealHousekeeperName)}（${this.empty(dealHousekeeperId)}）`
                    }
                }
            },
            {
                title: '订单状态',
                dataIndex: 'insuranceStatus',
                // key: 'insuranceStatus',
                width: 150,
                customRender: getLabelformList(CUSTOMER_ORDER_ORDER_STATE_ENUM_LIST)
            },
            {
                title: '操作',
                key: 'action',
                fixed: 'right',
                align: 'left',
                width: 60,
                scopedSlots: { customRender: 'action' }
            }
        ]
        return {
            ORDER_MANAGE_LIST_VIEW_DETAIL_BUTTON,
            SALES_CATEGORY_DISTRIBUTION_TEXT,
            SALES_CATEGORY_SELF_SUPPORT_TEXT,
            columns,
            // 查询条件
            params: {},
            // 数据
            data: [],
            // 分页数量
            limit: DEFAULT_PAGES_SIZE,
            // 总记录数量
            total: 0,
            // 当前索引
            current: 1,
            // 加载状态
            loading: false,
            // 详情-抽屉
            visible: false,
            castId: '',
            categoryList: SALES_CATEGORY_USE_ENUM_LIST,
            // 查看-客户id
            customerId: '',
            // 查看-经纪人id
            agentUserId: '',
            // 是否有跳转权限
            hasAuthJump: false
        }
    },
    computed: {
        pagination() {
            const {
                total,
                limit,
                current,
                tableShowTotal
            } = this
            return {
                showTotal: (total, range) => tableShowTotal(total, range),
                current,
                total,
                pageSize: limit,
                showSizeChanger: true,
                showQuickJumper: true
            }
        },
        // 用户信息缓存
        userInfo() {
            return this.$store.getters.userInfo
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        handleGetInit(params) {
            this.params = params
            this.getList()
        },
        /**
         * 获取 列表
         */
        async getList() {
            try {
                this.loading = true
                const { params, current, limit } = this
                const { total, records } = await getOrderManageListService({
                    current,
                    size: limit,
                    ...params
                })
                this.total = total || 0
                this.data = records || []
                // 1.获取列表经纪人id去重数组，无则用当前用户登录id
                const { userInfo } = this
                const { id } = userInfo || {}
                let agentIdList = []
                this.data.length && this.data.forEach(item => { 
                    item.agentUserIdSet = item.agentUserId || id || ''
                    if (item.agentUserIdSet) {
                        agentIdList.push(item.agentUserIdSet)
                    }
                })
                // 2.获取到id数组后调用批量获取是自营还是分销类型
                await this.judgeAgentTypeBatch(agentIdList, this.data, 'loading')
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                if (!this.data.length) {
                    this.loading = false
                }
            }
        },
        /**
         * 查看
         */
        handleClickLook(action) {
            const { userInfo } = this
            const { id } = userInfo || {}
            const { castInsuranceId, agentUserId, potentialCustomerId } = action

            this.customerId = potentialCustomerId
            this.agentUserId = agentUserId || id
            this.castId = castInsuranceId

            this.visible = true
        },
        /**
         * 关闭查看弹框
         */
        handleDetailClose() {
            this.visible = false
        },
        /**
         * 响应 table change 事件
         * @param pagination
         */
        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.current = current
            this.limit = pageSize
            this.getList()
        },
        /**
         * 搜索
         */
        handleSearch(params) {
            this.current = 1
            this.params = params
            this.getList()
            this.isResetSearch = false
        },
        /**
         * 刷新
         */
        handleRefresh(params) {
            this.handleSearch(params)
            this.isResetSearch = true
        },
        /**
         * 跳转投保用户
         */
        async jumpToCustomerDetail(action) {
            if (!(action.potentialCustomerId && action.hasAuthDetail)) return
            const {
                userInfo,
                SALES_CATEGORY_DISTRIBUTION_TEXT,
                SALES_CATEGORY_SELF_SUPPORT_TEXT
            } = this
            let agentUserId = action && action.agentUserId || ''
            // 如果有归属经纪人 根据归属经纪人判断是自营分销 没有经纪人根据当前登录账户判断是自营还是分销
            let justUserId = agentUserId ? agentUserId : userInfo && userInfo.id
            await this.judgeAgentType(justUserId)
            const { agentType, categoryList } = this
            let result = categoryList.find(item => item.label === agentType)
            let businessType = result && result.value
            let name = ''
            // 如果有归属经纪人 传值归属经纪人id和投保账户id  无归属经纪人传当前登录账号id和投保账户id
            let query = null
            if (agentUserId) {
                query = {
                    customerId: action && action.potentialCustomerId,
                    agentUserId,
                    businessType
                }
            } else {
                query = {
                    customerId: action && action.potentialCustomerId,
                    agentUserId: userInfo && userInfo.id,
                    businessType
                }
            }

            // 页面类型
            // 分销
            if (agentType === SALES_CATEGORY_DISTRIBUTION_TEXT) {
                name = 'DistributionCustomerDetail'
            } else if (agentType === SALES_CATEGORY_SELF_SUPPORT_TEXT) {
                // 自营
                name = 'ProprietaryCustomerDetail'
            }

            this.$router.push({
                name,
                query
            })
        }
    }
}
</script>
<style lang='scss'>
.order-manage-page {
    .default-user {
        color: #323233;
    }
    .link-user {
        color: #1890ff;
        cursor: pointer;
    }
}
</style>

