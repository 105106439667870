<!-- 详情-列表 -->
<template>
    <div class="descriptions-panel">
        <!-- header start -->
        <div v-if="title" class="descriptions-panel__header">
            <span class="descriptions-panel__header-icon" />
            <b class="descriptions-panel__header-text">
                {{ title }}
            </b>
            <slot v-if="$slots.tag" name="tag" />
        </div>
        <!-- header end -->

        <!-- content start -->
        <div class="descriptions-panel__content">
            <slot v-if="$slots.content" name="content" />
        </div>
        <!-- content end -->
    </div>
</template>

<script>
export default {
    name: 'DescriptionsPanel',
    props: {
        title: {
            type: String,
            default() {
                return ''
            }
        }
    }
}
</script>

<style lang='scss'>
    @import '@assets/styles/varibles.scss';

    .descriptions-panel {
        border-top: 1px solid #F5F6F7;

        /* header */
        &__header {
            height: 44px;
            padding: 15px 13px 8px;
            &-icon,
            &-text {
                display: inline-block;
            }
            &-icon {
                position: relative;
                top: 2px;
                width: 3px;
                height: $font-size;
                background-color: $base-color;
            }
            &-text {
                margin-left: 10px;
                color: $custom-button-default-text-color;
                font-size: $font-size;
            }
        }

        /* content */
        &__content {
            padding: 16px 24px 32px;
        }
    }
</style>
