<!-- 订单详情 -->
<template>
    <custom-drawer
        title="订单详情"
        v-bind="$attrs"
        :after-visible-change="afterVisibleChange"
        placement="right"
        :loading="loading"
        v-on="$listeners"
    >
        <div slot="top" class="status">
            <img :src="stateImg">
            <p>当前订单状态：{{ getStateName(detail.insuranceStatus) }}</p>
        </div>
        <div slot="content" key="基本信息" class="content-body">
            <custom-descriptions bordered :value="detail" :options="baseOptions" />
        </div>
        <div slot="content"  key="保障内容" class="content-body">
            <custom-descriptions bordered :value="detail" :options="assureOptions" />
        </div>
        <div v-if="projectOptions.length" slot="content" key="项目信息" class="content-body">
            <custom-descriptions bordered :value="detail" :options="projectOptions" />
        </div>
        <div v-if="insuredOptions.length" slot="content" key="被保人" class="content-body">
            <custom-descriptions bordered :value="detail" :options="insuredOptions" />
        </div>
        <div v-if="policyholderOptions.length" slot="content" key="投保人" class="content-body">
            <custom-descriptions bordered :value="detail" :options="policyholderOptions" />
        </div>

        <div slot="content"  key="操作记录" class="content-body">
            <a-table size="middle" row-key="id" :pagination="false" :data-source="recordList || []" :columns="recordOptions" />
        </div>
    </custom-drawer>
</template>
<script>
import { Table as ATable } from 'ant-design-vue'
import CustomDescriptions from '@/weights/CustomDescriptions'
import getCastManageAllDetailService from '@service/customer/getCastManageAllDetailService'
import { getLabelformList } from '@/utils'
import { CUSTOMER_ORDER_ORDER_STATE_ENUM_LIST, ORDER_SOURCE_ENUM_LIST, CUSTOMER_ORDER_ORDER_STATE_CLOSE, CUSTOMER_ORDER_ORDER_STATE_COMPLETED } from '@/constant/enum'
import CustomDrawer from '@/weights/CustomDrawer'
import moment from 'moment'

export default {
    name: 'OrderRecordDetail',
    components: {
        ATable,
        CustomDrawer,
        CustomDescriptions
    },
    props: {
        detailId: {
            type: null,
            default: null
        }
    },
    data() {
        return {
            loading: false,
            detail: { },
            recordList: [],
            baseOptions: [
                {
                    title: '投保单号',
                    key: 'castInsuranceId'
                },
                {
                    title: '提交日期',
                    key: 'createDateTime'
                },
                {
                    title: '订单来源',
                    key: 'source',
                    customRender: getLabelformList(ORDER_SOURCE_ENUM_LIST)
                },
                {
                    title: '投保账号',
                    key: 'castInsuranceUserName',
                    customRender: () => this.detail.castInsuranceUserId ? `${this.detail.castInsuranceUserName}(${this.detail.castInsuranceUserId})` : ''
                },
                {
                    title: '操作经纪人',
                    key: 'agentName',
                    customRender: () => this.detail.agentId ? `${this.detail.agentName}(${this.detail.agentId})` : ''
                },
                {
                    title: '成交经纪人',
                    key: 'dealHousekeeperName',
                    customRender: () => this.detail.dealHousekeeperId ? `${this.detail.dealHousekeeperName}(${this.detail.dealHousekeeperId})` : ''
                },
                {
                    title: '风控单号',
                    key: 'riskCode'
                },
                {
                    title: '保单号',
                    key: 'companyCastId',
                    click: this.handleOpenPolicyDetail
                }
            ],
            assureOptions: [
                {
                    title: '产品名称',
                    key: 'spuName'
                },
                {
                    title: '险种',
                    key: 'dangerPlantedName'
                },
                {
                    title: '保险公司',
                    key: 'insuranceCompany'
                },
                {
                    title: '保险金额（保函金额）元',
                    key: 'insuranceMoney'
                },
                {
                    title: '保险期间',
                    key: 'dateRange'
                },
                {
                    title: '保费（元）',
                    key: 'payMoney'
                }
            ],
            projectOptions: [],
            insuredOptions: [],
            policyholderOptions: [],
            recordOptions: [
                {
                    title: '操作人',
                    dataIndex: 'createName',
                    customRender: (text, record) => `${text}(${record.createUserId})`
                },
                {
                    title: '操作时间',
                    dataIndex: 'createDateTime'
                },
                {
                    title: '订单状态',
                    dataIndex: 'status',
                    customRender: getLabelformList(CUSTOMER_ORDER_ORDER_STATE_ENUM_LIST)
                },
                {
                    title: '操作内容',
                    dataIndex: 'remark'
                }
            ]
        }
    },
    computed: {
        stateImg() {
            switch (this.detail.state) {
                case CUSTOMER_ORDER_ORDER_STATE_CLOSE: // 订单已关闭
                    return require('@/assets/images/error.png')
                case CUSTOMER_ORDER_ORDER_STATE_COMPLETED: // 订单完成
                    return require('@/assets/images/success.png')
                default:
                    return require('@/assets/images/waiting.png')
            }
        }
    },
    methods: {
        // 获取订单中文显示
        getStateName: getLabelformList(CUSTOMER_ORDER_ORDER_STATE_ENUM_LIST),
        async init() {
            this.loading = true
            try {
                // 详情接口
                const data = await getCastManageAllDetailService({ castId: this.detailId }) || {}

                const detail = {
                    ...data,
                    dateRange: `自${data.startInsuranceTime ? moment(data.startInsuranceTime).format('YYYY-MM-DD') : ''}零时起 至 ${data.endInsuranceTime ? moment(data.endInsuranceTime).format('YYYY-MM-DD') : ''}二十四时止`
                }
                // 投保人动态表单
                this.policyholderOptions = data?.contentNewVo?.castEnterpriseText?.map(item => ({
                    title: item.key,
                    value: item.value
                })) || []
                // 被保人动态表单
                this.insuredOptions = data?.contentNewVo?.insuredEnterpriseText?.map(item => ({
                    title: item.key,
                    value: item.value
                })) || []
                // 项目信息动态表单
                this.projectOptions = data?.contentNewVo?.proEnterpriseText?.map(item => ({
                    title: item.key,
                    value: item.value
                })) || []

                this.recordList = data?.operationLogs || []
                this.detail = detail || {}
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.loading = false
            }
        },
        afterVisibleChange(visible) {
            if (visible) {
                this.init()
            } else {
                Object.assign(this, {
                    detail: {},
                    recordList: [],
                    projectOptions: [],
                    insuredOptions: [],
                    policyholderOptions: []
                })
            }
        },
        // 点击打开保单详情
        handleOpenPolicyDetail() {
            if (this.detail.companyCastId) {
                this.toggleDetail({
                    key: 'PolicyRecordDetail',
                    toggle: true,
                    detailId: this.detail.companyCastId
                })
            }

        }
    },
    inject: { toggleDetail: { default: () => {} } }
}
</script>

<style lang="scss" scoped>
@import "../common";
</style>


