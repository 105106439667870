<!-- 自定义抽屉：拓展折叠功能 -->
<template>
    <a-drawer
        v-bind="$attrs"
        wrap-class-name="drawer-wrap"
        :width="$attrs.width || 992"
        v-on="$listeners"
    >
        <a-spin :spinning="loading">
            <div class="content-wrap">
                <slot name="top" />
                <div ref="contentRef" class="content">
                    <template v-for="(item, index) in $slots.content">
                        <div :key="item.key">
                            <!-- <a-affix :offset-top="52 * index"  :offset-bottom="52 * ($slots.content.length - index - 1)" :target="() => $refs.contentRef"> -->
                            <p class="title" @click="handleJump(item, index)">{{ item.key }}</p>
                            <!-- </a-affix> -->
                            <v-nodes :vnodes="item" />
                        </div>
                    </template>
                </div>
                <slot name="footer" />
            </div>
        </a-spin>
    </a-drawer>
</template>
<script>
import { Drawer as ADrawer } from 'ant-design-vue'

export default {
    name: 'CustomDrawer',
    components: {
        ADrawer,
        // AAffix: Affix,
        VNodes: {
            functional: true,
            render: (h, ctx) => ctx.props.vnodes
        }
    },
    props: {
        loading: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        handleJump(item, index) {
            // console.dir( item.children[0].elm)
        }
    }
}
</script>

<style lang="scss">
.drawer-wrap {
    .ant-drawer-content {
        border-radius: 4px 0 0 4px;

        .ant-drawer-wrapper-body {
            display: flex;
            flex-direction: column;
            height: 100%;

            .ant-drawer-header {
                background: #f7f8fa;

                .ant-drawer-title {
                    color: #646566;
                }
            }

            .ant-drawer-body {
                flex: 1;
                padding: 0;
                overflow: hidden;

                .ant-spin-nested-loading {
                    height: 100%;

                    .ant-spin-container {
                        height: 100%;
                    }
                }
            }
        }
    }
}

.content-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;

    .content {
        flex: 1;
        overflow-y: auto;

        .title {
            position: relative;
            padding: 15px 0 15px 24px;
            margin: 0;
            margin-bottom: 16px;
            font-size: 14px;
            font-weight: 400;
            font-weight: bold;
            color: #323233;

            // cursor: pointer;
            background: #fff;
            border-top: 1px solid #ebedf0;

            &::before {
                position: absolute;
                top: 16px;
                left: 13px;
                width: 4px;
                height: 18px;
                content: "";
                background: #1877f2;
            }
        }
    }
}
</style>


