<template>
    <a-modal
        title="请完成安全验证"
        :visible="visible"
        destroy-on-close
        :width="328"
        :height="305"
        :footer="null"
        centered
        @cancel="handleCancel"
    >
        <slider-captcha-panel :config="config"
                              @success="handleSuccess"
                              @fail="handleFail"
        />
    </a-modal>
</template>

<script>
import { Modal as AModal } from 'ant-design-vue'
import SliderCaptchaPanel from './SliderCaptchaPanel'

export default {
    name: 'SliderCaptcha',
    components: {
        AModal,
        SliderCaptchaPanel
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        config: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    methods: {
        handleCancel() {
            this.$emit('cancel')
        },
        handleSuccess(data) {
            this.$emit('success', data)
        },
        handleFail(data) {
            this.$emit('fail', data)
        }
    }
}
</script>

<style lang="scss">

</style>
