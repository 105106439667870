<!-- 订单记录-详情 -->
<template>
    <drawer-panel
        title="订单详情"
        :visible="visible"
        :loading="loading"
        @after-visible-change="afterVisibleChange"
        @close="handleClose"
    >
        <!-- 状态 start -->
        <template #top>
            <status-panel>
                <template #logo>
                    <img :src="imgSrc" alt="">
                </template>
                <template #content>
                    <span>当前订单状态：{{ getApiEnumLabelFromList(CUSTOMER_ORDER_ORDER_STATE_ENUM_LIST, record && record.insuranceStatus) }}</span>
                </template>
                <template #button>
                    <a-button
                        v-if="record && record.insuranceStatus && record && record.insuranceStatus === 5 && record.paymentUrl && record.paymentUrl !== ''"
                        type="primary"
                        @click="handleLinkOpen(record.paymentUrl)"
                    >
                        支付链接
                    </a-button>
                </template>
            </status-panel>
        </template>
        <!-- 状态 end -->

        <template #content>
            <!-- 基本信息 start -->
            <field-list title="基本信息" :data="baseInfo" />
            <!-- 基本信息 end -->

            <!-- 保障内容 start -->
            <field-list title="保障内容" :data="coverageInfo" />
            <!-- 保障内容 end -->

            <!-- 项目信息 start -->
            <field-list v-if="projectText && projectText.length > 0" title="项目信息" :data="projectText" />
            <!-- 项目信息 end -->

            <!-- 投保人 start -->
            <field-list v-if="policyHolderText && policyHolderText.length > 0" title="投保人" :data="policyHolderText" />
            <!-- 投保人 end -->

            <!-- 被保人 start -->
            <field-list v-if="insuredText && insuredText.length > 0" title="被保人" :data="insuredText" />
            <!-- 被保人 end -->

            <!-- 操作日志 start -->
            <operation-log :columns="logColumns" :data="logList" />
            <!-- 操作日志 end -->
        </template>
        <link-modal
            v-if="showLinkModal"
            :visible="showLinkModal"
            :data="payUrl"
            @close="handleLinkModalClose"
        />
    </drawer-panel>
</template>

<script>
// 常量
import {
    CUSTOMER_ORDER_ORDER_STATE_ENUM_LIST,
    CUSTOMER_ORDER_ORDER_STATE_CLOSE,
    CUSTOMER_ORDER_ORDER_STATE_COMPLETED,
    ORDER_SOURCE_ENUM_LIST
} from '@/constant/enum'



// mixins
import common from '../mixins'

// 接口请求
import getCastManageAllDetailService from '@service/customer/getCastManageAllDetailService'

// 组件
import LinkModal from './LinkModal'


export default {
    name: 'OrderRecordDetail',
    components: { LinkModal },
    mixins: [common],
    props: {
        visible: {
            type: Boolean,
            default() {
                return false
            }
        },
        castId: {
            type: [Number, String],
            default() {
                return ''
            }
        },
        // 客户id
        customerId: {
            type: [Number, String],
            default() {
                return ''
            }
        },
        // 经纪人id
        agentUserId: {
            type: [Number, String],
            default() {
                return ''
            }
        }
    },
    data() {

        // 操作日志
        const logColumns = [
            {
                title: '操作人',
                dataIndex: 'createName',
                key: 'createName',
                customRender: value => this.empty(value)
            },
            {
                title: '操作时间',
                dataIndex: 'createDateTime',
                key: 'createDateTime',
                customRender: value => this.empty(value)
            },
            {
                title: '订单状态',
                key: 'status',
                dataIndex: 'status',
                scopedSlots: { customRender: 'status' }
            },
            {
                title: '操作内容',
                dataIndex: 'remark',
                key: 'remark',
                customRender: value => this.empty(value)
            }
        ]

        return {
            CUSTOMER_ORDER_ORDER_STATE_ENUM_LIST,
            CUSTOMER_ORDER_ORDER_STATE_CLOSE,
            CUSTOMER_ORDER_ORDER_STATE_COMPLETED,
            logColumns,
            loading: false,
            // 单条
            record: {},
            imgSrc: require('@/assets/images/waiting.png'),
            // 基本信息
            baseInfo: [],
            // 保障内容
            coverageInfo: [],
            // 项目信息
            projectText: [],
            // 投保人信息
            policyHolderText: [],
            // 被保人信息
            insuredText: [],
            // 操作日志
            logList: [],
            // 地址链接弹框
            showLinkModal: false,
            // 支付链接
            payUrl: ''
        }
    },
    methods: {
        /**
             * 初始化
             */
        async init() {
            // 获取单条
            await this.getRecord()

            // 数据初始化
            await this.initData()
        },
        /**
             * 获取单条
             */
        async getRecord() {
            const { castId } = this
            if (!castId) return

            try {
                this.loading = true
                const result = await getCastManageAllDetailService({ castId })
                this.record = result || {}
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        },
        /**
             * 数据初始化
             */
        async initData() {
            const { record } = this
            const {
                castInsuranceId,
                createDateTime,
                source,
                agentName,
                agentId,
                castInsuranceUserName,
                castInsuranceUserId,
                companyCastId,
                riskCode,
                spuName,
                dangerPlantedName,
                insuranceCompany,
                insuranceMoney,
                payMoney,
                startInsuranceTime,
                endInsuranceTime,
                contentNewVo,
                operationLogs
            } = record || {}
            const {
                proEnterpriseText,
                castEnterpriseText,
                insuredEnterpriseText
            } = contentNewVo

            // 0. 状态icon
            this.handelImgSrc()

            // 1. 基本信息
            // 投保用户超链接
            const insuredUserLink = await this.handleInsuredUser('投保账号', castInsuranceUserId, castInsuranceUserName)
            this.baseInfo = [
                {
                    key: '投保单号',
                    value: castInsuranceId
                },
                {
                    key: '提交日期',
                    value: createDateTime
                },
                {
                    key: '订单来源',
                    value: this.empty(this.getApiEnumLabelFromList(ORDER_SOURCE_ENUM_LIST, source))
                },
                {
                    key: '操作经纪人',
                    value: `${agentName ? agentName : '-'}${agentId ? '(' + agentId + ')' : ''}`
                },
                insuredUserLink,
                {
                    key: '保单号',
                    value: companyCastId,
                    link: true,
                    linkBusiness: 'PolicyRecordDetail',
                    linkParams: { castId: castInsuranceId }
                },
                {
                    key: '风控单号',
                    value: riskCode
                }
            ]

            // 2. 保障内容
            this.coverageInfo = [
                {
                    key: '产品名称',
                    value: spuName
                },
                {
                    key: '险种',
                    value: dangerPlantedName
                },
                {
                    key: '保险公司',
                    value: insuranceCompany
                },
                {
                    key: '保险金额（保函金额）元',
                    value: insuranceMoney
                },
                {
                    key: '保费（元）',
                    value: payMoney
                },
                {
                    key: '保险期间',
                    value: this.empty(this.handleRangeTime(startInsuranceTime, endInsuranceTime))
                }
            ]

            // 3. 项目信息
            this.projectText = proEnterpriseText || []

            // 4. 投保人信息
            this.policyHolderText = castEnterpriseText || []

            // 5. 被保人信息
            this.insuredText = insuredEnterpriseText || []

            // 6. 操作日志
            this.logList = operationLogs || []
        },
        /**
             * 状态icon
             */
        handelImgSrc() {
            const { insuranceStatus } = this.record || {}

            const errorList = [
                CUSTOMER_ORDER_ORDER_STATE_CLOSE
            ]
            const successList = [
                CUSTOMER_ORDER_ORDER_STATE_COMPLETED
            ]

            this.imgSrc = this.handleStateIcon(insuranceStatus, errorList, successList)
        },
        /**
        * 地址链接
        */
        handleLinkOpen(paymentUrl) {
            console.log('payUrl:' + paymentUrl)
            this.showLinkModal = true
            this.payUrl = paymentUrl
        },
        /**
        * 弹框地址链接关闭
        */
        handleLinkModalClose() {
            this.showLinkModal = false
        }
    }
}
</script>


