<!-- 客户详情-订单记录 -->
<template>
    <container-panel>
        <!-- 筛选 start -->
        <template #screen>
            <search-panel slot="header" @search="handleListSearch" @refresh="handleListRefresh" />
        </template>
        <!-- 筛选 end -->

        <!-- 表格 start -->
        <template #content>
            <a-table
                size="middle"
                :columns="columns"
                :data-source="data"
                :pagination="pagination"
                :loading="loading"
                row-key="castInsuranceId"
                :scroll="{ x: '100%' }"
                @change="handleTableChange"
            >
                <!-- 订单状态 -->
                <template slot="insuranceStatus" slot-scope="insuranceStatus">
                    {{ getApiEnumLabelFromList(CUSTOMER_ORDER_ORDER_STATE_ENUM_LIST, insuranceStatus) }}
                </template>
                <!-- 操作 -->
                <template slot="action" slot-scope="action">
                    <custom-button text="查看" type="link" @click="handleDetail(action)" />
                </template>
            </a-table>
        </template>
        <!-- 筛选 end -->

        <!-- 详情 start -->
        <order-record-detail
            :visible="visible"
            :castId="castId"
            @close="handleDetailClose"
        />
        <!-- 详情 end -->
    </container-panel>
</template>

<script>
    // 组件
    import CustomButton from '@components/Button'
    import OrderRecordDetail from '@weights/Customer/Components/ComponentsDetail/Detail/OrderRecordDetail'
    import SearchPanel from './SearchPanel'

    // 常量
    import { CUSTOMER_ORDER_ORDER_STATE_ENUM_LIST, SALES_CATEGORY_DISTRIBUTION } from '@/constant/enum'

    // mixins
    import common from '../mixins'

    // 接口请求
    import getCastManageAllListService from '@service/customer/getCastManageAllListService'

    export default {
        name: 'OrderRecordTabPanel',
        components: {
            CustomButton,
            SearchPanel,
            OrderRecordDetail
        },
        mixins: [common],
        props: {
            params: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
        data() {

            return {
                CUSTOMER_ORDER_ORDER_STATE_ENUM_LIST,
                SALES_CATEGORY_DISTRIBUTION,
                COLUMNS: [
                    {
                        title: '投保单号',
                        dataIndex: 'castInsuranceId',
                        key: 'castInsuranceId',
                        width: 200
                    },
                    {
                        title: '提交时间',
                        dataIndex: 'createDateTime',
                        key: 'createDateTime',
                        width: 200
                    },
                    {
                        title: '险种',
                        dataIndex: 'dangerPlantedName',
                        key: 'dangerPlantedName',
                        width: 200,
                        ellipsis: true,
                        customRender: (text) => {
                            return this.empty(text)
                        }
                    },
                    {
                        title: '投保人',
                        dataIndex: 'castInsuranceEnterpriseName',
                        key: 'castInsuranceEnterpriseName',
                        width: 200,
                        customRender: (text) => {
                            return this.empty(text)
                        }
                    },
                    {
                        title: '保险金额（元）',
                        dataIndex: 'insuranceMoney',
                        key: 'insuranceMoney',
                        width: 200,
                        customRender: (text) => {
                            return this.empty(text)
                        }
                    },
                    {
                        title: '保费（元）',
                        dataIndex: 'payMoney',
                        key: 'payMoney',
                        width: 200,
                        customRender: (text) => {
                            return this.empty(text)
                        }
                    },
                    {
                        title: '保险公司',
                        dataIndex: 'insuranceCompany',
                        key: 'insuranceCompany',
                        width: 200
                    },
                    {
                        title: '操作经纪人',
                        dataIndex: 'agentName',
                        key: 'agentName',
                        width: 200,
                        customRender: (text, record) => {
                            const { agentUserId } = record
                            const result = `${text ? text : '-'}${agentUserId ? '(' + agentUserId + ')' : ''}`

                            return this.empty(result)
                        }
                    },
                    {
                        title: '成交经纪人',
                        dataIndex: 'dealHousekeeperName',
                        customRender: (text, record) => {
                            const {
                                dealHousekeeperName,
                                dealHousekeeperId
                            } = record || {}
                            let result = ''
                            result = `${dealHousekeeperName || '-'} (${dealHousekeeperId || '-'})`
                            if (!dealHousekeeperName && !dealHousekeeperId) {
                                result = '-'
                            }
                            return result
                        },
                        width: 200,
                    },
                    {
                        title: '订单状态',
                        dataIndex: 'insuranceStatus',
                        width: 200,
                        scopedSlots: {
                            customRender: 'insuranceStatus'
                        }
                    },
                    {
                        title: '操作',
                        key: 'action',
                        fixed: 'right',
                        width: 60,
                        align: 'right',
                        scopedSlots: {
                            customRender: 'action'
                        }
                    }
                ],
                // 经纪人id
                agentUserId: '',
                // 投保用户id
                castInsuranceUserId: '',
                // 投保单号
                castId: '',
                // 查询条件
                searchParams: {},
                // 数据
                data: [],
                // 总记录数量
                total: 0,
                // 当前索引
                current: 1,
                // 加载状态
                loading: false,
                // 详情-抽屉
                visible: false
            }
        },
        computed: {
            columns() {
                const { COLUMNS, businessType, SALES_CATEGORY_DISTRIBUTION } = this
                let columns = COLUMNS
                // 分销客户
                if (businessType === SALES_CATEGORY_DISTRIBUTION) {
                    columns = columns.filter(item => item.key !== 'agentName')
                }

                return columns
            }
        },
        watch: {
            params: {
                handler(params) {
                    const { agentUserId, castInsuranceUserId } = params
                    if (!(agentUserId && castInsuranceUserId)) return

                    this.agentUserId = agentUserId
                    this.castInsuranceUserId = castInsuranceUserId

                    // 获取列表
                    this.getList()
                },
                immediate: true
            }
        },
        mounted() {
        },
        methods: {
            /**
             * 获取 列表
             */
            async getList() {
                try {
                    this.loading = true
                    const {
                        agentUserId,
                        castInsuranceUserId,
                        searchParams,
                        current,
                        limit
                    } = this
                    const { total, records } = await getCastManageAllListService({
                        agentUserId,
                        castInsuranceUserId,
                        current,
                        size: limit,
                        ...searchParams
                    })
                    this.total = total || 0
                    this.data = records || []
                } catch (e) {
                    this.$message.error(e.message)
                    this.$captureException(e)
                } finally {
                    this.loading = false
                }
            },
            /**
             * 查看
             */
            handleDetail(action) {
                const { castInsuranceId } = action
                this.castId = castInsuranceId
                this.visible = true
            }
        }
    }
</script>
