<!-- 详情-字段列表 -->
<template>
    <descriptions-panel class="field-list" :title="title">
        <!-- 标题-右侧标签 -->
        <template #tag>
            <slot name="tag" />
        </template>
        <!-- 标题-右侧标签 end -->

        <template #content>
            <!-- 列表 start -->
            <a-descriptions bordered :column="2">
                <a-descriptions-item v-for="item in data" :key="item.key" :label="item.key +'：'">
                    <a-button v-if="item.link && item.value" type="link" @click="handleLink(item)">
                        {{ empty(item.value) }}
                    </a-button>
                    <a-button v-else-if="item.open && item.value" type="link" @click="handleOpen(item)">
                        {{ empty(item.value) }}
                    </a-button>
                    <span v-else>
                        {{ empty(item.value) }}
                    </span>
                </a-descriptions-item>
            </a-descriptions>
            <!-- 列表 end -->

            <!-- 操作 start -->
            <div v-if="$slots.opts" class="field-list__opts">
                <slot name="opts" />
            </div>
            <!-- 操作 end -->

            <!-- 保单详情 start -->
            <policy-record-detail
                :visible="visiblePolicyRecordDetail"
                :cast-id="paramsPolicyRecordDetail && paramsPolicyRecordDetail.castId"
                @close="handleClosePolicyRecordDetail"
            />
            <!-- 保单详情 end -->

            <!-- 订单详情 start -->
            <order-record-detail
                :visible="visibleOrderRecordDetail"
                :cast-id="paramsOrderRecordDetail && paramsOrderRecordDetail.castId"
                @close="handleCloseOrderRecordDetail"
            />
            <!-- 订单详情 end -->

            <!-- 客户列表 start -->
            <customer-drawer
                :visible="visibleCustomerDrawer"
                :params="paramsCustomerDrawer"
                @close="handleCloseCustomerDrawer"
            />
            <!-- 订单详情 end -->
        </template>
    </descriptions-panel>
</template>

<script>
// 组件
import { Descriptions as ADescriptions, Button as AButton } from 'ant-design-vue'
import DescriptionsPanel from '../DescriptionsPanel'

// mixins
import empty from '@mixins/empty'

export default {
    name: 'FieldList',
    components: {
        DescriptionsPanel,
        ADescriptions,
        AButton,
        ADescriptionsItem: ADescriptions.Item,
        // 保单详情
        PolicyRecordDetail: () => import('@weights/Customer/Components/ComponentsDetail/Detail/PolicyRecordDetail'),
        // 订单详情
        OrderRecordDetail: () => import('@weights/Customer/Components/ComponentsDetail/Detail/OrderRecordDetail'),
        // 客户列表
        CustomerDrawer: () => import('@weights/Policy/CustomerDrawer')
    },
    mixins: [empty],
    props: {
        title: {
            type: String,
            default() {
                return ''
            }
        },
        data: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            // 保单详情-展示
            visiblePolicyRecordDetail: false,
            // 保单详情-参数
            paramsPolicyRecordDetail: {},
            // 订单详情-展示
            visibleOrderRecordDetail: false,
            // 订单详情-参数
            paramsOrderRecordDetail: {},
            // 客户列表
            visibleCustomerDrawer: false,
            // 客户列表参数
            paramsCustomerDrawer: {}

        }
    },
    computed: {},
    mounted() {},
    methods: {
        /**
        * 链接 查看
        */
        handleLink(item) {
            const { value, linkBusiness, linkParams } = item
            if (!value) return

            this['params' + linkBusiness] = linkParams
            this['visible' + linkBusiness] = true
        },
        /**
        * 保单详情 关闭
        */
        handleClosePolicyRecordDetail() {
            this.visiblePolicyRecordDetail = false
        },
        /**
        * 订单详情 关闭
        */
        handleCloseOrderRecordDetail() {
            this.visibleOrderRecordDetail = false
        },
        /**
        * 客户列表 关闭
        */
        handleCloseCustomerDrawer() {
            this.visibleCustomerDrawer = false
        },
        /**
         * 链接新开页 查看
         */
        handleOpen(item) {
            const { openBusiness, openParams } = item
            let  path = this.$router.resolve({
                path: openBusiness,
                query: openParams
            })
            window.open(path.href, '_blank')
        }
    }
}
</script>

<style lang="scss">
    @import '@assets/styles/varibles.scss';

    .field-list {
        .ant-descriptions-bordered {
            .ant-descriptions-item-label,
            .ant-descriptions-item-content {
                padding: 14px 12px;
                color: $custom-button-default-text-color;
            }
            .ant-descriptions-item-label {
                width: 16%;
                background-color: #F7F8FA;
                text-align: right;
                font-weight: bold;
            }
            .ant-descriptions-item-content {
                width: 34%;
            }
        }

        &__opts {
            margin-top: 8px;
            .ant-btn:not(:disabled) {
                margin-right: 8px;
                border-color: #E2E2E2;
                color: $custom-button-default-text-color;
            }
        }
    }
</style>
