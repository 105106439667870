/**
 * 客户维护-列表权限
 * ——————————————————————————————————————————————————————————————————————————————————————————————
 */
// 客户维护列表-添加客户
export const PROPRIETARY_CUSTOMER_ADD_BUTTON = 'PROPRIETARY_CUSTOMER_ADD'
// 客户维护列表-批量添加客户
export const PROPRIETARY_GROUP_CUSTOMER_ADD_BUTTON = 'PROPRIETARY_GROUP_CUSTOMER_ADD'
// 客户维护列表-批量移入公海
export const PROPRIETARY_CUSTOMER_BATCH_TRANSFER_SEAS_BUTTON = 'PROPRIETARY_CUSTOMER_BATCH_TRANSFER_SEAS'
// 客户维护列表-跟进记录
export const PROPRIETARY_CUSTOMER_FOLLOW_RECORD_BUTTON = 'PROPRIETARY_CUSTOMER_FOLLOW_RECORD'
// 客户维护列表-移入公海
export const PROPRIETARY_CUSTOMER_TRANSFER_SEAS_BUTTON= 'PROPRIETARY_CUSTOMER_TRANSFER_SEAS'
// 客户维护列表-转交客户
export const PROPRIETARY_CUSTOMER_HAND_CUSTOMER_BUTTON = 'PROPRIETARY_CUSTOMER_HAND_CUSTOMER'
// 客户维护详情-查看电子保单
export const PROPRIETARY_CUSTOMER_VIEW_POLICY_BUTTON = 'PROPRIETARY_CUSTOMER_VIEW_POLICY'
// 客户维护详情-查看电子发票
export const PROPRIETARY_CUSTOMER_ELECTRONIC_INVOICE_BUTTON = 'PROPRIETARY_CUSTOMER_ELECTRONIC_INVOICE'
// 客户维护详情-查看保险凭证
export const PROPRIETARY_CUSTOMER_VIEW_PROOF_BUTTON = 'PROPRIETARY_CUSTOMER_VIEW_PROOF'
// 客户维护详情-下载投保单
export const PROPRIETARY_CUSTOMER_DOWNLOAD_CAST_INSURANCE_BUTTON = 'PROPRIETARY_CUSTOMER_DOWNLOAD_CAST_INSURANCE'
// 客户维护详情-下载反担保
export const PROPRIETARY_CUSTOMER_DOWNLOAD_COUNTER_GUARANTEE_BUTTON = 'PROPRIETARY_CUSTOMER_DOWNLOAD_COUNTER_GUARANTEE'
