<!-- 自定义下拉，支持异步 -->
<template>
    <div class="custom-select-wrap">
        <a-select v-bind="$attrs" v-model="realValue" :disabled="disabled" :placeholder="placeholder">
            <template v-for="item in optionsList">
                <a-select-option :key="item[valueField]" :value="item[valueField]">
                    {{ item[labelField] }}
                </a-select-option>
            </template>
        </a-select>
    </div>
</template>

<script>
import {
    Select as ASelect
} from 'ant-design-vue'

export default {
    name: 'CustomSelect',
    components: {
        ASelect,
        ASelectOption: ASelect.Option
    },
    props: {
        value: { type: [String, Number] },
        disabled: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: '请选择'
        },
        asyncData: {
            type: null,
            default: undefined
        },
        labelField: {
            type: String,
            default: 'label'
        },
        valueField: {
            type: String,
            default: 'value'
        },
        options: {
            type: Array,
            default: null
        }
    },
    data() {
        return { optionsList: [] }
    },
    computed: {
        realValue: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
                this.$emit('change', val)
            }
        }
    },
    watch: {
        options: {
            handler(val) {
                this.init(val)
            }
        }
    },
    created() {
        this.init(this.options)
    },
    methods: {
        async init(val) {
            this.optionsList = val
            if (this.asyncData) {
                this.optionsList = await this.asyncData()
            }
        }
    }
}
</script>
<style lang='scss'>
</style>
