<!-- 工作台 -->
<template>
    <list-page-layout class="customer-list-page work-space-page">
        <div class="infoCard">
            <img v-if="avatarUrl" class="avatar" :src="avatarUrl">
            <img v-else class="avatar" src="~@assets/images/workSpace/default-avatar@2x.png">
            <div class="info">
                <p class="name">{{ welcomeText }}，{{ welcomeUserText }}!</p>
                <p v-if="false" class="job">{{ teamName }}</p>
                <a-button
                    v-if="teamName && !personal"
                    class="look"
                    @click="handleTeam"
                >
                    <img src="~@assets/images/workSpace/team-search@2x.png" alt="" class="search"><span>查看团队详情</span>
                </a-button>
                <!-- G端保单分析-按钮 -->
                <a-button
                    v-auth="POLICY_ANALYSIS_G_BUTTON"
                    type="info"
                    class="policy-analysis-button"
                    @click="handleToPolicyAnalysisG"
                >
                    <img :src="policyAnalysisIcon" alt="" class="policy-icon"> <span>G端保单分析</span>
                </a-button>
            </div>
        </div>
        <div class="content">
            <p class="header">
                <span>业绩数据</span>
                <a @click="handleMoreDetail">查看更多<span>></span></a>
            </p>
            <statistics-simple-bar :data-source="statisticsList" :loading="statisticsLoading" />
            <div class="msgTitle">
                <p>消息中心</p>
            </div>
            <a-list ref="list"
                    item-layout="vertical"
                    size="large"
                    :pagination="paginationProps"
                    :data-source="listData"
            >
                <template #renderItem="{ id, type ,content, createDateTime,name ,reading}, index">
                    <a-list-item class="msg-item" @click="readingItem(reading,id)">
                        <img v-if="avatarUrl" class="avatar" :src="avatarUrl">
                        <img v-else class="avatar" src="~@assets/images/workSpace/default-avatar@2x.png">
                        <div class="center">
                            <p class="message">{{ welcomeUserText }} <span class="type">收到消息</span> <content-text :message="content" @click-order="handleClickOrderNo($event,name,reading,id)" /></p>
                            <p class="time">{{ createDateTime }}</p>
                        </div>
                        <div :class="['tag', reading ?'read-tag':'unread-tag']">{{ messageStatus[reading] }}</div>
                    </a-list-item>
                </template>
            </a-list>
        </div>
        <lead-record-detail
            :detail-id="leadRecordDetailId"
            :visible="leadRecordDetailVisible"
            @close="handleLeadRecordDetailClose"
        />
        <order-record-detail
            :cast-id="orderRecordDetailId"
            :visible="orderRecordDetailVisible"
            @close="handleOrderRecordDetailClose"
        />
    </list-page-layout>
</template>

<script>
// 组件
import { List, Button as AButton } from 'ant-design-vue'
import ListPageLayout from '@layout/ListPageLayout'
import StatisticsSimpleBar from '@/weights/StatisticsSimpleBar'
import LeadRecordDetail from '@/views/Customer/HighSeasCustomerDetail/LeadRecord/detail'
import OrderRecordDetail from '@/weights/Customer/Components/ComponentsDetail/Detail/OrderRecordDetail'
import ContentText from '@/weights/WorkSpace/contentText.vue'

// 引入样式
import './index.scss'

// 引入枚举
import {
    MESSAGE_READ_STATE_MENU,
    MESSAGE_TYPE_LIST_MENU,
    CUSTOMER_POLICY_BUSINESS_TYPE_WORKSPACE
} from '@constant/enum'
import { DEFAULT_PAGES_SIZE } from '@config'
import { POLICY_ANALYSIS_G_BUTTON } from '@constant/authEnum/workSpace'

// mixins
import thousandBit from '@mixins/thousandBit'
import enumOperate from '@mixins/enumOperate'
import tableShowTotal from '@mixins/tableShowTotal'

// 接口请求
import getAgentWorkbenchService from '@service/workSpace/getAgentWorkbenchService'
import getMessageListService from '@service/workSpace/getMessageListService'
import messageUpdateService from '@service/workSpace/messageUpdateService'
import getIndividualCommissionService from '@service/workSpace/getIndividualCommissionService'

const statisticsList = [{
    title: '今日保费',
    imgSrc: require('@/assets/images/workSpace/today-premium.png'),
    money: '0',
    unit: '元',
    key: 'todayInsurancePremium'
}, {
    title: '本月保费',
    imgSrc: require('@/assets/images/workSpace/month-premium.png'),
    money: '0',
    unit: '元',
    key: 'monthInsurancePremium'
}, {
    title: '累计保费',
    imgSrc: require('@/assets/images/workSpace/accumulative-total-premium.png'),
    money: '0',
    unit: '元',
    key: 'insurancePremiumTotal'
}]

const statisticsListWithCommision = [{
    title: '今日保费',
    imgSrc: require('@/assets/images/workSpace/today-premium.png'),
    money: '0',
    unit: '元',
    key: 'todayInsurancePremium'
},
{
    title: '本月保费',
    imgSrc: require('@/assets/images/workSpace/month-premium.png'),
    money: '0',
    unit: '元',
    key: 'monthInsurancePremium'
},
{
    title: '累计保费',
    imgSrc: require('@/assets/images/workSpace/accumulative-total-premium.png'),
    money: '0',
    unit: '元',
    key: 'insurancePremiumTotal'
},
{
    title: '今日应收',
    imgSrc: require('@/assets/images/workSpace/today-premium.png'),
    money: '0',
    unit: '元',
    key: 'todaySettlementPremium'
},
{
    title: '本月应收',
    imgSrc: require('@/assets/images/workSpace/month-premium.png'),
    money: '0',
    unit: '元',
    key: 'monthSettlementPremium'
},
{
    title: '累计应收',
    imgSrc: require('@/assets/images/workSpace/accumulative-total-premium.png'),
    money: '0',
    unit: '元',
    key: 'insuranceSettlementTotal'
}]

export default {
    name: 'HighSeasCustomerList',
    components: {
        AButton,
        AList: List,
        AListItem: List.Item,
        ListPageLayout,
        StatisticsSimpleBar,
        ContentText,
        LeadRecordDetail,
        OrderRecordDetail
    },
    mixins: [enumOperate, tableShowTotal, thousandBit],
    data() {
        return {
            POLICY_ANALYSIS_G_BUTTON,
            CUSTOMER_POLICY_BUSINESS_TYPE_WORKSPACE,
            // 个人佣金是否可见状态
            commissionVisibility: false,
            // 团队名称
            teamName: '',
            // 是否是个人/团队 true 是个人
            personal: false,
            // 消息状态
            MESSAGE_READ_STATE_MENU,
            // 消息数据
            listData: [],
            // 业绩数据统计
            statisticsData: {},
            statisticsLoading: true,
            // 业绩数据配置
            statisticsList,
            // 分页信息
            current: 0,
            pageSize: DEFAULT_PAGES_SIZE,
            limit: 9999,
            // 跳转咨询详情
            leadRecordDetailId: '',
            leadRecordDetailVisible: false,
            // 跳转订单详情
            orderRecordDetailId: '',
            orderRecordDetailVisible: false,
            policyAnalysisIcon: require('@/assets/images/workSpace/policy-analysis-icon.png')
        }
    },
    computed: {
        // 消息分页配置
        paginationProps() {
            const _this = this
            return {
                showTotal: (total, range) => _this.tableShowTotal(total, range),
                pageSize: _this.pageSize,
                total: _this.listData.length,
                showQuickJumper: true,
                showSizeChanger: true,
                onChange: (page, pageSize) => {
                    console.log(page, pageSize)
                },
                onShowSizeChange: (page, pageSize) => {
                    // 查询分页数量
                    _this.pageSize = pageSize
                }
            }
        },
        // 用户信息缓存
        userInfo() {
            return this.$store.getters.userInfo
        },
        avatarUrl() {
            return this.userInfo && this.userInfo.avatar || ''
        },
        welcomeText() {
            const hour = this.$moment().hour()
            if ( hour >= 6 && hour < 11) {
                return '早上好'
            } else if ( hour >= 11 && hour < 13) {
                return '中午好'
            } else if ( hour >= 13 && hour < 19) {
                return '下午好'
            } else {
                return '晚上好'
            }
        },
        // 用户名称 -- 显示名称 || 手机号
        welcomeUserText() {
            const { name, mobile } = this.userInfo
            return name || mobile || ''
        },
        // 信息类型 -- 普通、重要、紧急、提醒
        messageTypeText() {
            return this.getTableEnumObject(MESSAGE_TYPE_LIST_MENU)
        },
        // 消息状态 -- 已读/未读
        messageStatus() {
            return this.getTableEnumObject(MESSAGE_READ_STATE_MENU)
        }
    },
    mounted() {
        // 获取团队及统计数据
        this.initData()
        // 获取消息数据
        this.initMessageList()
        // 获取个人佣金单条查询
        this.getIndividualCommission()
    },
    methods: {
        async initData() {
            try {
                this.statisticsLoading = true
                const { userInfo } = this
                const { id } = userInfo
                const data = await getAgentWorkbenchService({ agentUserId: id })
                if (data) {
                    let { teamName, personal, ...rest } = data
                    this.teamName = teamName || ''
                    this.personal = personal
                    this.statisticsData = rest || {}
                    this.statisticsList.forEach(item => {
                        item.money = this.thousandBit(this.statisticsData[item.key] * 1 ? this.statisticsData[item.key].toFixed(2) : '0.00')
                    })
                }
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.statisticsLoading = false
            }
        },
        async initMessageList() {
            try {
                const { current, limit } = this
                const data = await getMessageListService({
                    current,
                    size: limit
                })
                this.listData = data && data?.records || []
                this.$nextTick(() => {
                    const aListDom = document.querySelectorAll('.ant-pagination >li > a')
                    aListDom.length > 0 && aListDom.forEach(item => {
                        item.setAttribute('href', 'javascript:;')
                    })
                })
            } catch (e) {
                this.$message.error(e.message)
            } finally {
            }
        },
        async getIndividualCommission() {
            try {
                this.statisticsLoading = true
                const { userInfo } = this
                const { id } = userInfo
                const data = await getIndividualCommissionService({ userId: id })
                if (data) {
                    let { commissionVisibility } = data
                    this.commissionVisibility = commissionVisibility || false
                    if (this.commissionVisibility) {
                        this.statisticsList = statisticsListWithCommision
                    } else {
                        this.statisticsList = statisticsList
                    }
                }
            } catch (e) {
                this.$message.error(e.message)
            } finally {
            }
        },
        // 跳转团队详情
        handleTeam() {
            const { userInfo } = this
            const { id } = userInfo
            this.$router.push({
                name: 'TeamManage',
                query: {
                    authUserId: id,
                    commissionVisibility: this.commissionVisibility
                }
            })
        },
        // 跳转业绩数据 查看更多
        handleMoreDetail() {
            const { CUSTOMER_POLICY_BUSINESS_TYPE_WORKSPACE } = this
            this.$router.push({
                name: 'PolicyManage',
                query: {
                    businessType: CUSTOMER_POLICY_BUSINESS_TYPE_WORKSPACE,
                    commissionVisibility: this.commissionVisibility
                }
            })
        },
        // 点击消息中心 单号跳转
        async  handleClickOrderNo( orderNo, name) {
            if (orderNo) {
                if (name === '投保咨询' || name === '经纪人咨询') {
                    this.leadRecordDetailId = orderNo
                    this.leadRecordDetailVisible = true
                } else {
                    this.orderRecordDetailId = orderNo
                    this.orderRecordDetailVisible = true
                }
            }
        },
        // 关闭咨询详情
        handleLeadRecordDetailClose() {
            this.leadRecordDetailVisible = false
        },
        // 关闭订单
        handleOrderRecordDetailClose() {
            this.orderRecordDetailVisible = false
        },
        async readingItem(reading, id) {
            if (!reading) { // 未读的话标记已读
                try {
                    await messageUpdateService({ id })
                    this.initMessageList()
                } catch (e) {
                    this.$message.error(e.message)
                } finally {
                }
            }
        },
        handleToPolicyAnalysisG() {
            this.$router.push({ name: 'PolicyAnalysisG' })
        }
    }
}
</script>
