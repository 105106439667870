<template>
    <a-form-model
        layout="horizontal"
        :labelCol="{ span: 6 }"
        :wrapperCol="{ span: 18 }"
        :model="form"
        ref="ruleForm"
        :rules="rules"
    >
        <a-form-model-item label="分配管家" prop="dispense">
            <user-group v-model="form.dispense" :businessType="businessType" />
        </a-form-model-item>
    </a-form-model>
</template>

<script>
// 组件
import { FormModel as AFormModel } from 'ant-design-vue'
import UserGroup from '@weights/UserGroup'

// 接口请求
import dispenseStewardService from '@service/dispenseStewardService'

// 全局数据
import {
    STEWARD_LIST_TYPE_CUSTOMER_CLUE, // 客户线索
    STEWARD_LIST_TYPE_BROKER_CLUE, // 经纪人线索
    STEWARD_LIST_TYPE_CUSTOMER_LIST, // 客户列表
    STEWARD_LIST_TYPE_BROKER_LIST, // 经纪人列表
    DISPENSE_STEWARD_TYPE_CUSTOMER_CLUE, // 客户线索分配管家
    DISPENSE_STEWARD_TYPE_CUSTOMER, // 客户分配管家
    DISPENSE_STEWARD_TYPE_BROKER, // 经纪人分配管家
    DISPENSE_STEWARD_TYPE_BROKER_CLUE // 经纪人线索分配管家
} from '@constant/enum'

// 工具类
import { filterParams } from '@utils/search.js'

const TYPE_CONFIG = {
    [STEWARD_LIST_TYPE_CUSTOMER_CLUE]: DISPENSE_STEWARD_TYPE_CUSTOMER_CLUE,
    [STEWARD_LIST_TYPE_BROKER_CLUE]: DISPENSE_STEWARD_TYPE_BROKER_CLUE,
    [STEWARD_LIST_TYPE_CUSTOMER_LIST]: DISPENSE_STEWARD_TYPE_CUSTOMER,
    [STEWARD_LIST_TYPE_BROKER_LIST]: DISPENSE_STEWARD_TYPE_BROKER
}

export default {
    name: 'DispenseStewardFormPanel',
    props: {
        data: {
            type: Object,
            default() {
                return {}
            }
        },
        businessType: {
            // 业务类型，区分客户线索，经纪人线索、客户管理、经纪人管理
            type: [String, Number],
            default() {
                return ''
            }
        }
    },
    data() {
        return {
            form: {
                dispense: {} // 管家
            },
            rules: {
                dispense: [
                    {
                        required: true,
                        message: '请选择管家',
                        trigger: 'blur'
                    }
                ]
            },
            loading: false
        }
    },
    computed: {
        dataChange() {
            const { mode, data } = this
            return {
                mode,
                data
            }
        }
    },
    watch: {
        dataChange: {
            handler(val) {
                this.formatForm(val)
            },
            immediate: true
        }
    },
    mounted() {},
    methods: {
        /**
         * 新增
         */
        async dispenseSteward(data) {
            try {
                this.$emit('loading', true)
                await dispenseStewardService(data)
                this.$message.success('分配成功')
                this.$emit('success')
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.$emit('loading', false)
            }
        },
        /**
         * 点击确定按钮
         */
        handleSend() {
            const { form, businessType, data } = this
            const { id, mobile } = data
            const { dispense } = form
            let params = {
                agentUserId: dispense.key,
                agentUserName: dispense.label,
                ...form
            }
            params = filterParams(params)
            delete params.dispense
            params = { id, mobile, type: TYPE_CONFIG[businessType], ...params }
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.dispenseSteward(params)
                }
            })
        },
        /**
         * 关闭弹框
         */
        handleCancel() {
            this.$emit('close')
        },
        /**
         * 重置按钮事件
         */
        handleReset() {
            this.form = {
                name: '',
                description: '',
                id: this.form.id
            }
        },
        /**
         * 格式化form数据
         */
        formatForm(data = {}) {
            const { agentUserId, agentUserName } = data.data
            this.form = {
                dispense: {
                    key: agentUserId || '',
                    label: agentUserName || ''
                }
            }
        }
    },
    components: {
        AFormModel,
        AFormModelItem: AFormModel.Item,
        UserGroup
    }
}
</script>

<style lang="scss">
</style>