/**
 * 系统 模块
 */
import BasicLayout from '@layout/BasicLayout'
import RouteLayout from '@layout/RouteLayout'

import {
    CLUE_MENU_CODE,
    CLUE_MANAGEMENT_CLUE_DISTRIBUTE_MENU_CODE,
    CLUE_MANAGEMENT_CLUE_DISTRIBUTE_LIST_MENU_CODE,
    CLIENT_CLUE_MENU_CODE,
    CLIENT_CLUE_LIST_MENU_CODE,
    BROKER_CLUE_MENU_CODE,
    BROKER_CLUE_LIST_MENU_CODE
} from '@constant/menu_code'

const routes = [{
    path: '/clue',
    name: 'Clue',
    redirect: '/clue',
    component: BasicLayout,
    code: CLUE_MENU_CODE,
    sort: 1,
    meta: {
        title: '线索管理旧',
        icon: 'C-xiansuoguanli',
        iconType: 'iconfont'
    },
    children: [{
        path: 'clue-distribute',
        redirect: '/clue/clue-distribute/list',
        name: 'ClueDistribute',
        component: RouteLayout,
        code: CLUE_MANAGEMENT_CLUE_DISTRIBUTE_MENU_CODE,
        meta: { title: '线索分配' },
        children: [{
            path: 'list',
            name: 'ClueDistributeList',
            hidden: true,
            component: () => import('./ClueDistribute/ClueDistributeList/index'),
            code: CLUE_MANAGEMENT_CLUE_DISTRIBUTE_LIST_MENU_CODE,
            meta: {
                title: '线索分配',
                breadcrumb: false
            }
        }]
    }, {
        path: 'client-clue',
        redirect: '/clue/client-clue/list',
        name: 'ClientClue',
        component: RouteLayout,
        code: CLIENT_CLUE_MENU_CODE,
        meta: { title: '客户线索' },
        children: [{
            path: 'list',
            name: 'ClientClueList',
            hidden: true,
            component: () => import('./ClientClue/ClientClueList/index'),
            code: CLIENT_CLUE_LIST_MENU_CODE,
            meta: {
                title: '客户线索',
                breadcrumb: false
            }
        }

        ]
    }, {
        path: 'broker-clue',
        redirect: '/clue/broker-clue/list',
        name: 'BrokerClue',
        component: RouteLayout,
        code: BROKER_CLUE_MENU_CODE,
        meta: { title: '经纪人线索' },
        children: [{
            path: 'list',
            name: 'BrokerClueList',
            hidden: true,
            component: () => import('./BrokerClue/BrokerClueList/index'),
            code: BROKER_CLUE_LIST_MENU_CODE,
            meta: {
                title: '经纪人线索',
                breadcrumb: false
            }
        }]
    }]
}]

export default routes
