<!-- 线索记录-详情 -->
<template>
    <drawer-panel
        title="线索详情"
        :visible="visible"
        :loading="loading"
        @after-visible-change="afterVisibleChange"
        @close="handleClose"
    >
        <!-- 状态 start -->
        <template #top>
            <status-panel>
                <template #logo>
                    <img :src="imgSrc" alt="">
                </template>
                <template #content>
                    <span>当前订单状态：{{ getApiEnumLabelFromList(CUSTOMER_LEAD_ORDER_STATE_ENUM_LIST, record && record.state) }}</span>
                </template>
            </status-panel>
        </template>
        <!-- 状态 end -->

        <template #content>
            <!-- 基本信息 start -->
            <field-list title="基本信息" :data="baseInfo"/>
            <!-- 基本信息 end -->

            <!-- 操作日志 start -->
            <operation-log :columns="logColumns" :data="logList" :loading="log.loading"/>
            <!-- 操作日志 end -->
        </template>
    </drawer-panel>
</template>

<script>
    // 组件
    import DrawerPanel from '@weights/Customer/Components/ComponentsDetail/DrawerPanel'
    import StatusPanel from '@weights/Customer/Components/ComponentsDetail/StatusPanel'
    import FieldList from '@weights/Customer/Components/ComponentsDetail/FieldList'
    import OperationLog from '@weights/Customer/Components/ComponentsDetail/OperationLog'

    // 常量
    import {
        CUSTOMER_LEAD_ORDER_STATE_ENUM_LIST,
        CUSTOMER_LEAD_ORDER_STATE_CANCEL,
        CUSTOMER_LEAD_ORDER_STATE_WE_CLOSE,
        CUSTOMER_LEAD_ORDER_STATE_WE_COMPLETED,
        CUSTOMER_LEAD_SOURCE_TYPE_ENUM_LIST
    } from '@/constant/enum'

    // mixins
    import common from '../mixins'

    // 接口请求
    import getPotentialCustomerSelectOneService from '@service/customer/getPotentialCustomerSelectOneService'
    import getPotentialCustomerLogSelectService from '@service/customer/getPotentialCustomerLogSelectService'

    export default {
        name: 'LeadRecordDetail',
        components: {
            DrawerPanel,
            StatusPanel,
            FieldList,
            OperationLog
        },
        mixins: [common],
        props: {
            id: {
                type: [Number, String],
                default() {
                    return ''
                }
            },
            visible: {
                type: Boolean,
                default() {
                    return false
                }
            },
            // 客户id
            customerId: {
                type: [Number, String],
                default() {
                    return ''
                }
            },
            // 经纪人id
            agentUserId: {
                type: [Number, String],
                default() {
                    return ''
                }
            },
            // 绑定关系
            isRelatedAgent: {
                type: Boolean,
                default() {
                    return false
                }
            }
        },
        data() {

            // 操作日志
            const logColumns = [
                {
                    title: '操作人',
                    dataIndex: 'operator',
                    key: 'operator',
                    customRender: (value) => {
                        return this.empty(value)
                    }
                },
                {
                    title: '操作时间',
                    dataIndex: 'finishedTime',
                    key: 'finishedTime',
                    customRender: (value) => {
                        return this.empty(value)
                    }
                },
                {
                    title: '订单状态',
                    key: 'state',
                    dataIndex: 'state'
                },
                {
                    title: '操作内容',
                    dataIndex: 'operationContent',
                    key: 'operationContent',
                    customRender: (value) => {
                        return this.empty(value)
                    }
                }
            ]

            return {
                CUSTOMER_LEAD_ORDER_STATE_ENUM_LIST,
                CUSTOMER_LEAD_ORDER_STATE_CANCEL,
                CUSTOMER_LEAD_ORDER_STATE_WE_CLOSE,
                CUSTOMER_LEAD_ORDER_STATE_WE_COMPLETED,
                CUSTOMER_LEAD_SOURCE_TYPE_ENUM_LIST,
                logColumns,
                loading: false,
                // 单条
                record: {},
                imgSrc: require('@/assets/images/waiting.png'),
                // 基本信息
                baseInfo: [],
                // 操作日志
                logList: []
            }
        },
        computed: {
        },
        watch: {
        },
        methods: {
            /**
             * 初始化
             */
            async init() {
                // 获取单条
                await this.getRecord()

                // 数据初始化
                await this.initData()

                // 获取日志
                await this.getOperationLogList()
            },
            /**
             * 获取单条
             */
            async getRecord() {
                const { id } = this
                if (!id) return

                try {
                    this.loading = true
                    const result = await getPotentialCustomerSelectOneService({ id })
                    this.record = result || {}
                } catch (e) {
                    this.$message.error(e.message)
                    this.$captureException(e)
                } finally {
                    this.loading = false
                }
            },
            /**
             * 数据初始化
             */
            async initData() {
                const { record } = this
                const {
                    id,
                    createDateTime,
                    name,
                    mobile,
                    appointmentContactDateTime,
                    dangerPlantedCategoryName,
                    dangerPlantedName,
                    enterpriseName,
                    provinceName,
                    cityName,
                    areaName,
                    projectTypeName,
                    projectTypeValueName,
                    projectName,
                    agentUserName,
                    agentUserId,
                    productSpu,
                    description,
                    sourceType
                } = record || {}
                const { name: productName } = productSpu || {}
                const area = this.empty(`${provinceName ? provinceName : ''}${cityName ? cityName : ''}${areaName ? areaName : ''}`)


                // 0. 状态icon
                this.handelImgSrc()

                // 1.基本信息
                // 联系手机超链接
                const mobileLink = await this.handleMobile()
                this.baseInfo = [
                    {
                        key: '服务单号',
                        value: id
                    },
                    {
                        key: '提交时间',
                        value: createDateTime
                    },
                    {
                        key: '咨询人',
                        value: name
                    },
                    mobileLink,
                    {
                        key: '咨询预约时间',
                        value: appointmentContactDateTime
                    },
                    {
                        key: '产品名称',
                        value: productName
                    },
                    {
                        key: '险种大类',
                        value: dangerPlantedCategoryName
                    },
                    {
                        key: '咨询险种',
                        value: dangerPlantedName
                    },
                    {
                        key: '企业名称',
                        value: enterpriseName
                    },
                    {
                        key: '地区',
                        value: area
                    },
                    {
                        key: '项目类型',
                        value: projectTypeName
                    },
                    {
                        key: '保险服务',
                        value: projectTypeValueName
                    },
                    {
                        key: '项目名称',
                        value: projectName
                    },
                    {
                        key: '经纪人',
                        value: agentUserName
                    },
                    {
                        key: '订单来源/需求备注',
                        value: description
                    },
                    {
                        key: '类型',
                        value: this.getApiEnumLabelFromList(CUSTOMER_LEAD_SOURCE_TYPE_ENUM_LIST, sourceType)
                    }
                ]
            },
            /**
             * 获取日志
             */
            async getOperationLogList() {
                try {
                    this.log.loading = true
                    const { id: potentialCustomerId, log } = this
                    const { current, limit } = log
                    const { total, records } = await getPotentialCustomerLogSelectService({
                        potentialCustomerId,
                        pageNumber: current,
                        pageSize: limit
                    })
                    this.log.total = total || 0
                    records.map((item, index) => {
                        item.id = index
                    })
                    this.logList = records || []
                } catch (e) {
                    this.$message.error(e.message)
                    this.$captureException(e)
                } finally {
                    this.log.loading = false
                }
            },
            /**
             * 状态icon
             */
            handelImgSrc() {
                const { state } = this.record || {}

                const errorList = [
                    CUSTOMER_LEAD_ORDER_STATE_CANCEL,
                    CUSTOMER_LEAD_ORDER_STATE_WE_CLOSE
                ]
                const successList = [
                    CUSTOMER_LEAD_ORDER_STATE_WE_COMPLETED
                ]

                this.imgSrc = this.handleStateIcon(state, errorList, successList)
            },
            /**
             * 投保用户超链接
             */
            async handleMobile() {
                const {
                    SALES_CATEGORY_DISTRIBUTION_TEXT,
                    SALES_CATEGORY_SELF_SUPPORT_TEXT,
                    SALES_CATEGORY_DISTRIBUTION,
                    SALES_CATEGORY_SELF_SUPPORT,
                    customerId,
                    agentUserId,
                    record,
                    isRelatedAgent
                } = this
                const {
                    mobile,
                    agentUserId: recordAgentUserId,
                    agentUserTypeLabel,
                } = record
                let result = {
                    key: '联系手机',
                    value: `${mobile ? mobile : ''}`
                }

                // 1. 必要参数
                if (!customerId) return result
                if (!agentUserId) return result
                if(!isRelatedAgent) return result

                let routerPath = ''
                let businessType = null

                // 2. 判断经纪人类型
                await this.judgeAgentType(agentUserId)
                const { agentType } = this
                const curAgentType = recordAgentUserId && agentUserTypeLabel || agentType
                if (!curAgentType) return result

                // 3. 判断有无分销/自营详情  页面权限   有则添加超链接
                const hasAuth = await this.judgeAuth(curAgentType) || false
                if (hasAuth) {
                    // 分销
                    if (curAgentType === SALES_CATEGORY_DISTRIBUTION_TEXT) {
                        routerPath = '/customer/distribution-customer/detail'
                        businessType = SALES_CATEGORY_DISTRIBUTION
                    } else if (curAgentType === SALES_CATEGORY_SELF_SUPPORT_TEXT) {
                        // 自营
                        routerPath = '/customer/proprietary-customer/detail'
                        businessType = SALES_CATEGORY_SELF_SUPPORT
                    }

                    result = {
                        ...result,
                        open: true,
                        openBusiness: routerPath,
                        openParams: {
                            customerId,
                            agentUserId,
                            businessType
                        }
                    }
                }

                return result
            }
        }
    }
</script>


