<template>
    <!--  线索来源-->
    <a-select
        v-model="serveSteward"
        show-search
        placeholder="请选择"
        @blur="handleBlur"
        @change="handleChange"
    >
        <a-select-option value="">全部</a-select-option>
        <a-select-option
            v-for="item in dataList"
            :key="item.id"
            :value="item.id"
            :label="item.name"
        >{{ item.name }}</a-select-option>
    </a-select>
</template>

<script>
// 组件
import { Select as ASelect } from 'ant-design-vue'

// 接口请求
import getClueSourceEnumListService from '@service/getClueSourceEnumListService'

export default {
    name: 'ClueSource',
    props: {
        value: {
            type: [String, Number],
            default() {
                return ''
            }
        },
        // 列表类型，区分客户线索/经纪人线索
        listType: {
            type: [String, Number],
            default() {
                return ''
            }
        }
    },
    components: {
        ASelect,
        ASelectOption: ASelect.Option
    },
    data() {
        return {
            dataList: []
        }
    },
    created() {
        this.getClueSourceList()
    },
    computed: {
        serveSteward: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        }
    },
    methods: {
        // 选择的name
        handleChange(val) {
            this.$emit('change', val)
        },
        handleBlur() {
            this.$emit('blur')
        },
        /**
         * 拉取线索来源
         */
        async getClueSourceList() {
            const {listType} = this
            try {
                this.loading = true
                const records = await getClueSourceEnumListService({
                    listType
                })
                this.dataList = records
            } catch (e) {
                console.log(e)
                this.$message.error(e.message)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang='scss'>
</style>