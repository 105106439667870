<template>
    <a-card class="product-list-data-missed">
        <div class="product-list-data-missed__container">
            <img src="@/assets/images/default/null2x.png" alt="">
            <p class="product-list-data-missed__container-result">
                抱歉，没有搜索到相关商品
            </p>
        </div>
    </a-card>
</template>

<script>
// 组件
import {
    Card as ACard
} from 'ant-design-vue'

export default {
    name: 'DataMissed',
    components: { ACard },
    props: {
        // 关键词
        keywords: {
            type: String,
            default() {
                return ''
            }
        }
    },
    data() {
        return {}
    },
    computed: {},
    watch: {},
    mounted() {
    },
    methods: {}
}
</script>

<style lang='scss'>
@import '~@/assets/styles/varibles.scss';

.product-list-data-missed {
    border-top: 1px dashed #EBEDF0;
    &__container {
        width: 100%;
        margin: 50px 0;
        text-align: center;
        &-result {
            font-size: 18px;
            // font-weight: 400;
            color: #848B91;
            line-height: 25px;
        }
        &-key {
            color: $color-action;
        }
        &-prompt {
            color: $footer-color;
            font-size: $font-sizeTwelve;
        }
        &-link {
            color: $color-base-blue;
        }
    }
}
</style>
