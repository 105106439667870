<script>
    import { Button as AButton } from "ant-design-vue"
    export default {
        name: "customButton",
        props: {
            // 类别
            type: {
                type: String,
                required: false,
                default: 'default'
            },
            // 行为
            act: {
                type: String,
                required: false,
                default: ''
            },
            // 尺寸
            size: {
                type: String,
                required: false,
                default: 'default'
            },
            // 文本
            text: {
                type: String,
                required: true,
                default: ''
            }
        },
        computed: {
            className() {
                const {type} = this
                return `custom-button custom-button__${type}`
            },
            props() {
                const {$props} = this
                const {...props} = $props
                return props
            },
            listeners() {
                return Object.assign({}, this.$listeners,
                    {
                        // 点击事件
                        // click: (event) => {
                        //     this.$emit('click', event, this.act)
                        // }
                    }
                )
            }
        },
        render(h) {
            return h('AButton', {
                class: this.className,
                on         : this.listeners,
                props      : this.props,
                scopedSlots: this.scopedSlots || this.$scopedSlots
            }, [this.$slots.expansion,this.text,this.$slots.rightExpansion])
        },
        methods: {
        },
        components: {
            AButton,
        }
    }
</script>

<style lang="scss">
    @import "@assets/styles/varibles.scss";

    .custom-button {
        letter-spacing: -1px;

        /*操作按钮--默认*/
        &__default {
            border: 1px solid $custom-button-default-border-color;
            color: $custom-button-default-text-color;
        }

        /*操作按钮--重置*/
        &__reset {
            border: none;
            color: $custom-button-reset-text-color;
        }

        /*操作按钮--link*/
        &__link {
            border: none;
            color: $base-color;
            &:hover {
                color: $base-color;
            }
        }
    }
</style>
