<template>
    <div class="batch-transfer-seas-modal-form-panel">
        <div>
            以下列表客户即将释放到公海，操作后将 <span class="key-point">无法查看到该客户数据</span>，确认提交？
        </div>
        <div class="batch-transfer-seas-modal-form-panel__total">已选中以下 <span class="count">{{ selectedRows.length }}</span> 个客户批量分配</div>
        <a-table
            size="middle"
            :columns="columns"
            :data-source="list"
            row-key="id"
            :pagination="false"
        />
        <a-pagination
            v-model="current"
            :total="selectedRows.length"
            size="small"
            :page-size="size"
        />
    </div>
</template>

<script>
import {
    Table as ATable,
    Pagination as APagination
} from 'ant-design-vue'
// 接口请求
import proprietaryBatchSeasService from '@service/customer/proprietaryCustomer/proprietaryBatchSeasService'
const columns = [
    {
        title: '手机号码',
        dataIndex: 'customerMobile',
        customRender: text => text || '-'
    }, {
        title: '姓名',
        dataIndex: 'name',
        customRender: text => text || '-'
    }
]
export default {
    name: 'BatchTransferSeasModalFormPanel',
    components: {
        ATable,
        APagination
    },
    props: {
        selectedRows: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            columns,
            size: 5,
            current: 1
        }
    },
    computed: {
        list() {
            const { selectedRows, current, size } = this
            return selectedRows.slice((current * 1 - 1) * size, current * 1 * size)
        }
    },
    methods: {
        async handleSend() {
            const { selectedRows } = this
            let selectedRowsParams = selectedRows.map(item => ({
                customerId: item.customerId,
                agentUserId: item.agentUserId
            }))
            try {
                this.$emit('loading', true)
                await proprietaryBatchSeasService(selectedRowsParams)
                this.$message.success('操作成功')
                this.$emit('success')
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.$emit('loading', false)
            }
        }
    }

}
</script>

<style lang='scss'>
.batch-transfer-seas-modal-form-panel {
    .ant-pagination {
        margin-top: 20px;
        text-align: right;
    }
    &__total {
        margin-top: 20px;
        margin-bottom: 8px;
        .count {
            color: #1877F2;
        }
    }
    .key-point {
        color: #DF4545;
    }
}
</style>
