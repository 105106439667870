<!-- 保全详情 -->
<template>
    <custom-drawer
        title="保全详情"
        v-bind="$attrs"
        :after-visible-change="afterVisibleChange"
        placement="right"
        :loading="loading"
        v-on="$listeners"
    >
        <div slot="top" class="status">
            <img :src="stateImg">
            <p>当前批单状态：{{ getStateName(detail.state) }}</p>
        </div>
        <div slot="content" key="保全信息" class="content-body">
            <custom-descriptions bordered :value="detail" :options="preservationOptions" />
            <a-button v-if="detail.insuranceRefund && detail.insuranceRefund.credentials" class="certBtn" @click="handleOpenCert">
                查看凭证
            </a-button>
        </div>
        <div slot="content"  key="保单信息" class="content-body">
            <custom-descriptions bordered :value="detail" :options="insuranceOptions" />
        </div>
    </custom-drawer>
</template>
<script>
import CustomDescriptions from '@/weights/CustomDescriptions'
import getCustomerInsuranceCorrectingDetailService from '@service/customer/getCustomerInsuranceCorrectingDetailService'
import { getLabelformList } from '@/utils'
import { PRESERVATION_STATE_MENU_LIST, PRESERVATION_STATE_REFUSE, PRESERVATION_STATE_FINISHED } from '@/constant/enum'
import CustomDrawer from '@/weights/CustomDrawer'
import moment from 'moment'
import fileType from '@/mixins/fileType'

// 工具类
import { previewFiles } from '@/utils/previewFiles'

export default {
    name: 'PreservationRecordDetail',
    components: {
        CustomDrawer,
        CustomDescriptions
    },
    mixins: [fileType],
    props: {
        detailId: {
            type: null,
            default: null
        }
    },
    data() {
        return {
            loading: false,
            detail: { },
            preservationOptions: [
                {
                    title: '服务单号',
                    key: 'id'
                },
                {
                    title: '申请时间',
                    key: 'createDateTime'
                },
                {
                    title: '批单类型',
                    key: 'type',
                    customRender: getLabelformList(PRESERVATION_STATE_MENU_LIST)
                },
                {
                    title: '退保原因',
                    key: 'reason'
                },
                {
                    title: '原保费（元）',
                    key: 'payMoney'
                },
                {
                    title: '退保金额',
                    key: 'refundMoney'
                },
                {
                    title: '退保手续费',
                    key: 'refundPoundage'
                },
                {
                    title: '备注',
                    key: 'description'
                }
            ],
            insuranceOptions: [
                {
                    title: '保单号',
                    key: 'insuranceId',
                    click: this.handleOpenPolicyDetail
                },
                {
                    title: '签单时间',
                    key: 'insuranceTime'
                },
                {
                    title: '保险公司',
                    key: 'insuranceCompany'
                },
                {
                    title: '险种',
                    key: 'dangerPlantedName'
                },
                {
                    title: '保险金额（保函金额）',
                    key: 'insuranceMoney'
                },
                {
                    title: '保费（元）',
                    key: 'payMoney'
                },
                {
                    title: '项目名称',
                    key: 'projectName'
                },
                {
                    title: '投保人',
                    key: 'policyHolderEnterpriseName'
                },
                {
                    title: '被保人',
                    key: 'insuredEnterpriseName'
                },
                {
                    title: '关联订单号',
                    key: 'castInsuranceId',
                    click: this.handleOpenOrderDetail
                },
                {
                    title: '保险期间',
                    key: 'dateRange'
                }
            ]
        }
    },
    computed: {
        stateImg() {
            switch (this.detail.state) {
                case PRESERVATION_STATE_REFUSE: // 拒绝
                    return require('@/assets/images/error.png')
                case PRESERVATION_STATE_FINISHED: // 通过
                    return require('@/assets/images/success.png')
                default:
                    return require('@/assets/images/waiting.png')
            }
        }
    },
    methods: {
        // 获取批单状态中文显示
        getStateName: getLabelformList(PRESERVATION_STATE_MENU_LIST),
        async init() {
            this.loading = true
            try {
                // 详情接口
                const data = await getCustomerInsuranceCorrectingDetailService({ id: this.detailId }) || {}
                const detail = {
                    ...data,
                    description: data?.insuranceRefund.description,
                    refundPoundage: data?.insuranceRefund.refundPoundage,
                    refundMoney: data?.insuranceRefund.refundMoney,
                    reason: data?.insuranceRefund.reason,
                    dateRange: `自${data.startInsuranceTime ? moment(data.startInsuranceTime).format('YYYY-MM-DD') : ''}零时起 至 ${data.endInsuranceTime ? moment(data.endInsuranceTime).format('YYYY-MM-DD') : ''}二十四时止`
                }
                this.detail = detail || {}
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.loading = false
            }
        },
        afterVisibleChange(visible) {
            if (visible) {
                this.init()
            } else {
                this.detail = {}
            }
        },
        // 查看凭证
        handleOpenCert() {
            const url = this.detail?.insuranceRefund?.credentials
            if (url) {
                let type = this.formatterFileType(url)
                previewFiles(type, url)
            } else {
                this.$message.error('文件错误')
            }
        },
        // 点击打开保单详情
        handleOpenPolicyDetail() {
            if (this.detail.insuranceId) {
                this.toggleDetail({
                    key: 'PolicyRecordDetail',
                    toggle: true,
                    detailId: this.detail.insuranceId
                })
            }
        },
        // 点击打开订单详情
        handleOpenOrderDetail() {
            if (this.detail.castInsuranceId) {
                this.toggleDetail({
                    key: 'OrderRecordDetail',
                    toggle: true,
                    detailId: this.detail.castInsuranceId
                })
            }
        }
    },
    inject: { toggleDetail: { default: () => {} } }
}
</script>

<style lang="scss" scope>
@import "../common";

.certBtn {
    margin-top: 8px;
}
</style>


