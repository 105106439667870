<template>
    <layout-header :class="[theme, 'gongbao-header']" style="background: #1877f2;">
        <div :class="['gongbao-header--wide', layout]">
            <img src="@assets/images/head-left.jpg" alt="" style="position: absolute;left: 0;z-index: -1;height: 64px;">
            <img src="@assets/images/head-right.jpg" alt="" style="position: absolute;right: 0;z-index: -1;height: 64px;">
            <template v-if="isYunFu">
                <a href="/"
                   :class="['logo', isMobile ? null : 'pc', theme]">
                    <img src="@assets/images/header-logo.png">
                </a>
            </template>
            <template v-else>
                <router-link
                    to="/"
                    :class="['logo', isMobile ? null : 'pc', theme]">
                    <img :src="crmlogo">
                </router-link>
            </template>

            <div v-if="layout === 'head'"
                 class="gongbao-header__menu">
                <i-menu class="gongbao-header__menu"
                        :theme="theme"
                        mode="horizontal"
                        :menu-data="menuData"
                        @select="onSelect" />
            </div>
            <div :class="['gongbao-header__right', theme]">
                <!--                <header-search class="header-item"/> -->
                <!-- <a-tooltip class="gongbao-header__item" title="帮助文档" placement="bottom">
            <a>
                <a-icon type="question-circle-o"/>
            </a>
        </a-tooltip>
        -->
                <!-- <header-notice class="gongbao-header__item"/> -->
                <!-- <a-divider type="vertical" /> -->
                <header-avatar class="gongbao-header__item" />
            </div>
        </div>
    </layout-header>
</template>

<script>
import {
    Layout
} from 'ant-design-vue'
import HeaderAvatar from '@components/HeaderAvatar'
import IMenu from '@components/SiderMenu'

import { YUNFU_BUILD_TYPE } from '@config'


export default {
    name: 'GlobalHeader',
    components: {
        IMenu,
        HeaderAvatar,
        LayoutHeader: Layout.Header
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile
        },
        layout() {
            return this.$store.getters.layout
        },
        theme() {
            return this.layout === 'side' ? 'light' : this.$store.getters.theme
        },
        systemName() {
            return this.$store.getters.systemName
        },
        isYunFu() {
            return process.env.VUE_APP_BUILD_TYPE === YUNFU_BUILD_TYPE
        },
        crmlogo() {
            return this.$store.getters.loginBgImgLogoImgInfo &&
            this.$store.getters.loginBgImgLogoImgInfo.logoAddress ||
            require('@assets/images/header-logo-crm.png')
        }
    },
    methods: {
        onSelect(obj) {
            // eslint-disable-next-line vue/custom-event-name-casing
            this.$emit('menuSelect', obj)
        }
    }
}
</script>

<style lang="scss">
@import "@assets/styles/varibles";

.gongbao-header {
    /* -webkit-box-shadow: 0 1px 4px rgba(0, 21, 41, .08); */
    // box-shadow: 0 1px 4px rgba(0, 21, 41, .08);
    position: relative;
    padding: 0 12px 0 0;

    &.light {
        background: #fff;
    }

    &.dark {
        background: #001529;
    }

    &__trigger {
        padding: 0 24px;
        font-size: 20px;
        line-height: 64px;
        cursor: pointer;
        transition: color 0.3s;

        &:hover {
            color: #1890ff;
        }
    }

    &--wide {
        // background: #1877F2;
        &.head {
            max-width: 1400px;
            margin: auto;
        }

        .logo {
            display: inline-flex;
            align-items: center;
            height: 64px;
            padding: 0 12px 0 38px;
            font-size: 20px;
            line-height: 58px;
            cursor: pointer;

            //&.pc {
            //  padding: 0 12px 0 0;
            //}

            img {
                display: inline-block;
                width: 143px;
                // height: 32px;
                margin-right: 10px;
                vertical-align: middle;
            }

            h1 {
                display: inline-block;
                margin: 0;
                font-family: PingFangSC-Regular, "PingFang SC";
                font-size: 20px;
                font-weight: inherit;
                line-height: 18px;
                color: #fff;
                letter-spacing: 1px;
            }

            &.dark h1 {
                color: #fff;
            }
        }

        .gongbao-header__menu {
            display: inline-block;
        }

        .gongbao-header__right {
            float: right;

            &.dark {
                color: #fff;

                i {
                    color: #fff;
                }
            }

            &.light {
                color: #fff;

                i {
                    color: #fff;
                }
            }
        }
    }

    &__item {
        display: inline-block;
        height: 100%;
        padding: 0 12px;
        vertical-align: middle;
        cursor: pointer;

        i {
            font-size: 16px;
            color: rgb(0 0 0 / 65%);
        }
    }

    &__menu {
        height: $navbar-height;
        line-height: $navbar-height;
    }

    .dialog {
        position: relative;
        display: inline-flex;
        align-items: center;
        padding: 0 5px;
        margin-left: 8px;
        line-height: 22px;
        background: linear-gradient(100deg, #00b0fe 0%, #74c4cf 100%);
        border-radius: 2px;
    }

    .dialog span {
        font-family: PingFangSC-Medium, "PingFang SC";
        font-size: $font-size;
        font-weight: 500;
        line-height: 22px;
        color: #fff;
        letter-spacing: 1px;
    }

    .dialog::before {
        position: absolute;
        top: 6px;
        left: -10px;
        content: "";
        border: 5px solid;
        border-color: transparent #00b0fe transparent transparent;
    }
}
</style>
