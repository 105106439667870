import { BASE_URL } from '@config'

/** 订单管理接口
 * ————————————————————————————————————————————————————————————————————————————————————————————*/


/**
 * 订单管理列表
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const GET_ORDER_MANAGE_LIST = {
    url: `${BASE_URL}/insurance/cast/manage/crm/list`,
    method: 'get',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}


