import { setToken, removeToken, getToken } from '@utils/auth'
import {
    SET_TOKEN,
    SET_USER_INFO,
    SET_ROUTES,
    SET_ROLE,
    SET_BUTTON_ROLE,
    SET_LOGINBG_LOGO

} from './mutation-types'

import asyncRouterMap from '@router/asyncLoadRoutes'


import { PERMISSION_TYPE_MENU, PERMISSION_TYPE_BUTTON } from '@constant/enum'

import getUserInfoService from '@service/getUserInfoService'
import getUserPermissionService from '@service/getUserPermissionService'
import loginByUserNameService from '@service/loginByUserNameService'
import loginByPhoneService from '@service/loginByPhoneService'
import getCorporationChannelInfoService from '@service/getCorporationChannelInfoService'

import userLogOutService from '@service/userLogOutService'
import { message } from 'ant-design-vue'

/**
 * 通过roles判断是否与当前用户权限匹配
 * @param route
 * @param roles
 */
function hasPermission(route, roles) {
    return roles[route.code]
    // return true
}

/**
 * 格式化 权限为对象
 * @param permissions
 * @returns {{}}
 */
function getRule(permissions) {

    permissions = permissions || []

    let obj = {}

    permissions.forEach(item => {
        const { value } = item
        obj[value] = item
    })

    return obj
}

/**
 * 递归过滤异步路由表，返回符合用户角色权限的路由表
 * @param routes asyncRouterMap
 * @param roles
 */
function filterAsyncRouter(routes, roles = {}) {
    const res = []
    routes.forEach(route => {
        const tmp = { ...route }

        if (hasPermission(tmp, roles)) {
            // 子路由权限
            if (tmp.children) {
                tmp.children = filterAsyncRouter(tmp.children, roles)
            }
            // 判断 是否不拒绝重写重定向 并且
            // 有配置 redirect 属性 并且
            // 有子路由
            if (tmp.doNotRewriteRedirect !== false &&
                tmp.redirect &&
                tmp.children.length) {
                const childItem = tmp.children[0]
                tmp.redirect = { name: childItem.name }
            }

            res.push(tmp)
        }
    })

    return res
}

/**
 * 菜单-树形转数组
 * @param route
 * @param roles
 */
function treeConvertList(tree) {
    const list = []
    tree.map(root => {
        const Root = JSON.parse(JSON.stringify(root))
        const queue = []
        queue.push(Root)
        while (queue.length) {
            const node = queue.shift()
            if (node.treeList && node.treeList.length) {
                queue.push(...node.treeList)
            }
            delete node.treeList
            // if (node.parentId !== null && node.parentId !== undefined) {
            list.push(node)
            // }
        }
    })
    return list
}

/**
 * 登录
 * @param commit
 * @param loginInfo
 * @returns {Promise<void>}
 */
export async function loginByUserName({ commit }, loginInfo) {
    // const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJzeXN0ZW1BZG1pbkluZm9WTyI6ImV5SnBaQ0k2TVRZeUxDSnNiMmRwYms1aGJXVWlPaUpuWW5kaVlXTnJaVzVrTVNJc0luVnpaWEpPWVcxbElqb2lNVGt5T0RRM01EWXdJaXdpYVhBaU9pSXhNamN1TUM0d0xqRWlMQ0p5WldGc1RtRnRaU0k2SXVhY2llYWRnK21aa0NJc0ltNXBZMnRPWVcxbElqb2k1WkNPNVkrdzU2Nmg1NUNHNVpHWU1TSXNJbkJvYjI1bElqb2lORFEwSWl3aVpXMWhhV3dpT2lJeE1VQXhOak11WTI5dElpd2ljR3hoZEdadmNtMU9ZVzFsSWpvaTViZWw1TCtkNTcyUjVaQ081WSt3NTY2aDU1Q0c1NnV2SWl3aWNHeGhkR1p2Y20xRGIyUmxJam9pYm1WMExXSmhZMnRsYm1RaWZRPT0iLCJpc3MiOiJjbmdvbmdiYW8ifQ.CVmxyXpnaZTTFzwzQrTfhHmJWmfOJP3g_6J7Wem30qeOvWbfylhAqizdYsRPDJaszVw6ojJWXPwATP1rTj9G_Whwp844VYBm5vkp2yEkfKbEKruFAncr8TAVmYxNTao50b92ye86QQvsnIdfIjV7hQYQIZBjw-11-UjUF5TyJQA'
    const token = await loginByUserNameService(loginInfo)
    commit(SET_TOKEN, token)
    setToken(token)
}
export async function loginByPhone({ commit }, loginInfo) {
    // const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJzeXN0ZW1BZG1pbkluZm9WTyI6ImV5SnBaQ0k2TVRZeUxDSnNiMmRwYms1aGJXVWlPaUpuWW5kaVlXTnJaVzVrTVNJc0luVnpaWEpPWVcxbElqb2lNVGt5T0RRM01EWXdJaXdpYVhBaU9pSXhNamN1TUM0d0xqRWlMQ0p5WldGc1RtRnRaU0k2SXVhY2llYWRnK21aa0NJc0ltNXBZMnRPWVcxbElqb2k1WkNPNVkrdzU2Nmg1NUNHNVpHWU1TSXNJbkJvYjI1bElqb2lORFEwSWl3aVpXMWhhV3dpT2lJeE1VQXhOak11WTI5dElpd2ljR3hoZEdadmNtMU9ZVzFsSWpvaTViZWw1TCtkNTcyUjVaQ081WSt3NTY2aDU1Q0c1NnV2SWl3aWNHeGhkR1p2Y20xRGIyUmxJam9pYm1WMExXSmhZMnRsYm1RaWZRPT0iLCJpc3MiOiJjbmdvbmdiYW8ifQ.CVmxyXpnaZTTFzwzQrTfhHmJWmfOJP3g_6J7Wem30qeOvWbfylhAqizdYsRPDJaszVw6ojJWXPwATP1rTj9G_Whwp844VYBm5vkp2yEkfKbEKruFAncr8TAVmYxNTao50b92ye86QQvsnIdfIjV7hQYQIZBjw-11-UjUF5TyJQA'
    const token = await loginByPhoneService(loginInfo)
    commit(SET_TOKEN, token)
    setToken(token)
}

/**
 * 获取用户信息
 * @param commit
 * @param state
 * @returns {Promise<void>}
 */
export async function getUserInfo({ commit }) {

    const { name, userName, mobile, appCode, id, avatarUrl } = await getUserInfoService()
    const userInfo = {
        id,
        compCode: '',
        compName: '',
        name,
        userName,
        appCode,
        mobile,
        avatar: avatarUrl || require('@assets/images/workSpace/default-avatar@2x.png')
    }

    const menuData = await getUserPermissionService({ type: PERMISSION_TYPE_MENU }) // 获取权限-菜单
    const buttonData = await getUserPermissionService({ type: PERMISSION_TYPE_BUTTON }) // 获取权限-菜单
    const menu = getRule(menuData) // 菜单-格式化为对象
    const button = {
        ...getRule(buttonData),
        ...menu
    } // 按钮-格式化为对象，并针对新开页将菜单权限加入按钮权限中

    // 菜单权限
    commit(SET_ROLE, menu)
    commit(SET_BUTTON_ROLE, button)

    // 用户信息
    commit(SET_USER_INFO, userInfo)
}

/**
 * 登出系统
 * @param commit
 * @param state
 * @returns {Promise<void>}
 */
export async function logOut({ commit }) {
    try {
        commit(SET_ROLE, [])
        commit(SET_USER_INFO, null)
        commit(SET_TOKEN, '')

        if (getToken()) {
            // 注销
            await userLogOutService()

            // 删除token
            removeToken()
            window.location.reload()
        }
    } catch (e) {
        message.error(e.message)
    } finally {
    }
}

/**
 * 生成路由表
 * @param commit
 * @param state
 * @param roles
 */
export function generateRoutes({ commit, state }) {
    const role = state.role || {}

    let accessedRouters = filterAsyncRouter(asyncRouterMap, role)

    commit(SET_ROUTES, accessedRouters)
    return accessedRouters
    // commit(SET_ROUTES,asyncRouterMap)
    // return asyncRouterMap
}

/**
 * 请求登录背景图和登录后的logo图
 * @param commit
 * @param param
 * @returns {Promise<void>}
 */
export async function getCorporationChannelInfo({ commit }, param) {
    // console.log('getCorporationChannelInfo action param:' + JSON.stringify(param, null, 4))

    const loginBgImgLogoImgInfo = await getCorporationChannelInfoService(param)
    // console.log('loginBgImgLogoImgInfo:' + JSON.stringify(loginBgImgLogoImgInfo, null, 4))
    commit(SET_LOGINBG_LOGO, loginBgImgLogoImgInfo)

}
