<!-- 佣金弹框 -->
<template>
    <custom-modal
        :visible="visible"
        :mask-closable="false"
        :width="824"
        :title="params.productName || ''"
        class="add-customer-modal-components"
        @close="handleCancel"
        @ok="handleOk">
        <template #tabs>
            <customer-tab slot="header" :commission-data="commissionData" @changetab="changeTab" />
        </template>
        <template>
            <!-- 表单 start -->
            <form-panel
                v-if="isCommissionView"
                ref="formPanel"
                :type="type"
                :commission-data="commissionData"
                :spu-id="params.id || ''"
                @success="handleSendSuccess"
                @loading="handleLoading"
            />
            <div v-if="!isCommissionView" class="rule-content" v-html="commissionData.subtitleDescription" />

            <!-- 表单 end -->
            <!-- 按钮 start -->
            <template slot="customFooter">
                <a-button type="default" @click="handleCancel">关闭</a-button>
                <!-- <a-button type="primary" :loading="loading" class="button-box-shadow" @click="handleOk">添加</a-button> -->
                <!-- 按钮 end -->
            </template>
        </template>
    </custom-modal>
</template>

<script>
// 组件
import { Button as AButton } from 'ant-design-vue'
import FormPanel from './FormPanel'
import CustomModal from '@components/CustomModal'
import CustomerTab from '../CustomerTab'

export default {
    name: 'CommissionModal',
    components: {
        AButton,
        FormPanel,
        CustomModal,
        CustomerTab
    },
    props: {
        params: {
            type: Object,
            default: () => ({})
        },
        visible: {
            type: Boolean,
            default() {
                return false
            }
        },
        title: {
            type: String,
            default() {
                return ''
            }
        },
        type: {
            type: String,
            default() {
                return ''
            }
        },
        // 佣金信息
        commissionData: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            loading: false,
            maskClosable: false,
            isCommissionView: true
        }
    },
    computed: {},
    methods: {
        /**
         * 提交按钮事件
         */
        handleOk() {
            this.$refs.formPanel.handleSend()
        },
        /**
         * 按钮loading
         */
        handleLoading(loading) {
            this.loading = loading
        },
        /**
         * 关闭弹框
         */
        handleCancel() {
            this.isCommissionView = true
            this.$emit('close')
        },
        handleSendSuccess() {
            this.$emit('success')
        },
        changeTab(param) {
            this.isCommissionView = param.isCommissionView === '1'
        }
    }
}
</script>

<style lang="scss">
.add-customer-modal-components {
    .ant-modal-footer {
        text-align: right;
    }
    .ant-modal-body {
        padding: 0;
        min-height: 550px;
    }
}
.rule {
    &-content {
        padding: 30px 20px 32px 20px;
        height: 490px;
        overflow:auto;
    }
}
</style>
