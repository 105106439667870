import { BASE_URL } from '@/config'

/** 推广获客
 * ————————————————————————————————————————————————————————————————————————————————————————————*/

/**
 * 推广获客-表单集合分页查询
 *
 */
export const GET_PROMOTE_FORM_SELECT_LIST = {
    url: `${BASE_URL}/customer/promote-form/selectListPage`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}


/**
 * 推广获客-获取预览二维码
 *
 */
export const GET_PROMOTE_FORM_PREVIEW_FORM = {
    url: `${BASE_URL}/customer/promote-form/previewForm`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}
