/**
 * 预览图片：因接口图片响应格式有异，图片需手动执行预览
 * @params  fileType
 * @params  url
 */
export function previewFiles(fileType, url) {
    let typeArr = [
        'image/png',
        'image/jpeg',
        'png',
        'jpeg',
        'image/jpg',
        'jpg'
    ]
    if (typeArr.includes(fileType)) {
        let newWindow = window.open('')
        newWindow.document.body.innerHTML = '<img src="' + url + '" alt="">'
    } else {
        window.open(url)
    }
}
