<template>
  <custom-modal
    :loading="loading"
    title="添加线索"
    :visible="visible"
    :maskClosable="maskClosable"
    @close="handleCancel"
    @reset="handleReset"
    @ok="handleOk"
  >
    <form-panel
      :type="type"
      ref="formPanel"
      @success="handleSendSuccess"
      @loading="handleLoading"
    />
  </custom-modal>
</template>

<script>
import FormPanel from './FormPanel'
import CustomModal from '@components/CustomModal'

export default {
  name: 'CreateClueModal',
  data() {
    return {
      loading: false,
      maskClosable: false
    }
  },
  components: {
    FormPanel,
    CustomModal
  },
  props: {
    visible: {
      type: Boolean,
      default() {
        return false
      }
    },
    type: {
      type: [String,Number],
      default() {
        return 0
      }
    },
    
  },
  methods: {
    /**
     * 提交按钮事件
     */
    handleOk() {
      this.$refs.formPanel.handleSend()
    },
    /**
     * 按钮loading
     */
    handleLoading(loading) {
      this.loading = loading
    },
    /**
     * 重置按钮事件
     */
    handleReset() {
      this.$refs.formPanel.handleReset()
    },
    /**
     * 关闭弹框
     */
    handleCancel() {
      this.$emit('close')
    },
    handleSendSuccess() {
      this.$emit('success')
    }
  }
}
</script>

<style lang="scss">
.ant-modal-footer {
  text-align: center;
}
</style>