import axios from '@service'
import {CREATE_CLUE_DISTRIBUTE} from './api'
import {HTTP_ERR_SUCCESS, HTTP_RESULT_FIELD, HTTP_ERR_MESSAGE_FIELD} from '@/constant/api_status'
import {ERROR_DEFAULT_HTTP_REQUEST_FAIL} from '@/constant/error'

/**
 * 新增线索分配
 * @param payload
 * @param config
 * @returns {Promise<*>}
 */
export default async function createClueDistributeService(payload = {}, config = {}) {
    const param = {...CREATE_CLUE_DISTRIBUTE, ...config, params: payload}
    const {data} = await axios(param)
    if (data.code === HTTP_ERR_SUCCESS) {
        return data[HTTP_RESULT_FIELD]
    } else {
        throw new Error(data[HTTP_ERR_MESSAGE_FIELD] || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}