<!-- 保全记录Tab -->
<template>
    <div>
        <pro-table
            ref="tableRef"
            row-key="id"
            :scroll="{ x: '100%' }"
            :columns="columns"
            :request="getList"
        >
            <template #action="{record}">
                <a-button
                    type="link"
                    @click="handleDetail(record)"
                >
                    查看
                </a-button>
            </template>
        </pro-table>
    </div>
</template>
<script>
import ProTable from '@components/ProTable'
import getCustomerInsuranceCorrectingListService from '@service/customer/getCustomerInsuranceCorrectingListService'
import columns from './columns'
import { formatAddress } from '@utils/address'

export default {
    name: 'PreservationRecord',
    components: { ProTable },
    data() {
        return { columns }
    },
    computed: {
        userId() {
            return this.$route.query.userId
        }
    },
    methods: {
        // 获取列表数据
        async getList(params) {
            const { userId } = this
            if (!userId) {
                return {
                    data: [],
                    total: 0
                }
            }
            const { pageSize, current, submitTime = [], ...otherParams } = params
            const { total, records } = await getCustomerInsuranceCorrectingListService({
                userId,
                current,
                size: pageSize,
                startCreateDateTime: this.$formatDateTime(submitTime[0]) || undefined,
                endCreateDateTime: this.$formatDateTime(submitTime[1]) || undefined,
                ...otherParams
            })
            const data = []
            for (let item of records) {
                const area = `${await formatAddress([item.provinceCode, item.cityCode, item.areaCode])}`
                data.push({
                    ...item,
                    area
                })
            }
            return {
                data,
                total
            }
        },
        // 打开详情抽屉
        handleDetail(record) {
            this.toggleDetail({
                key: 'PreservationRecordDetail',
                toggle: true,
                detailId: record.id
            })
        },
        // 用于外部刷新
        refresh() {
            this.$refs.tableRef?.handleSearch?.()
        }
    },
    inject: { toggleDetail: { default: () => {} } }
}
</script>
