<!-- 工作台/保单详情 -->
<template>
    <list-page-layout class="policy-manage-page">
        <!-- title -->
        <common-title title="保单列表">
            <template #extra>
                <custom-button type="default" text="返回" @click="handleBack" />
            </template>
        </common-title>

        <container-panel>
            <!-- 筛选 start -->
            <template #screen>
                <search-panel-work-space
                    ref="searchPanel"
                    slot="header"
                    @search="handleWorkSpaceSearch"
                    @refresh="handleWorkSpaceRefresh"
                    @get-init="handleGetInit"
                />
            </template>
            <!-- 筛选 end -->

            <!-- statistics start -->
            <!-- from 工作台/团队管理 -->
            <template #statistics>
                <div>
                    <span class="tip-icon">*</span>
                    <span>统计数据不含退保和转交保单</span>
                </div>
                <statistics-bar :data-source="statisticsList" :loading="statisticsLoading" />
            </template>
            <!-- statistics end -->

            <!-- 表格 start -->
            <template #content>
                <a-table
                    size="middle"
                    :columns="columns"
                    :data-source="data"
                    :pagination="pagination"
                    :loading="loading"
                    row-key="id"
                    :scroll="{ x: '100%' }"
                    @change="handleTableChange"
                >
                    <!-- 操作 -->
                    <template slot="action" slot-scope="action">
                        <custom-button text="查看" type="link" @click="handleDetail(action)" />
                    </template>
                </a-table>
            </template>
            <!-- 筛选 end -->

            <!-- 详情-线上保单 start -->
            <policy-record-detail
                :visible="visible"
                :cast-id="castId"
                @close="handleDetailClose"
            />
            <!-- 详情 end -->

            <!-- 详情-线下保单 start -->
            <policy-record-detail-off
                :id="id"
                :visible="visibleOff"
                @close="handleDetailCloseOff"
            />
            <!-- 详情 end -->

            <!-- 详情-G端保单 start -->
            <policy-record-detail-g
                :id="id"
                :visible="visibleG"
                @close="handleDetailCloseG"
            />
            <!-- 详情 end -->
        </container-panel>
    </list-page-layout>
</template>

<script>
// 组件
import {
    Table as ATable
} from 'ant-design-vue'
import ListPageLayout from '@layout/ListPageLayout'
import ContainerPanel from '@components/ContainerPanel'
import CommonTitle from '@components/CommonTitle'
import CustomButton from '@components/Button'
import SearchPanelWorkSpace from '@views/WorkSpace/PolicyManage/SearchPanel'
import StatisticsBar from '@/weights/StatisticsBarDetails'
import PolicyRecordDetail from '@weights/Customer/Components/ComponentsDetail/Detail/PolicyRecordDetail'
import PolicyRecordDetailOff from '@weights/Customer/Components/ComponentsDetail/Detail/PolicyRecordDetailOff'
import PolicyRecordDetailG from '@weights/Customer/Components/ComponentsDetail/Detail/PolicyRecordDetailG'

// 常量
import {
    CUSTOMER_POLICY_BUSINESS_TYPE_TEAM,
    CUSTOMER_POLICY_BUSINESS_TYPE_WORKSPACE,
    POLICY_POLICY_TYPE_ONE,
    POLICY_POLICY_TYPE_TWO,
    POLICY_POLICY_TYPE_THREE
} from '@constant/enum'
import { DEFAULT_PAGES_SIZE } from '@config'

// mixins
import thousandBit from '@mixins/thousandBit'
import tableShowTotal from '@mixins/tableShowTotal'
import enumOperate from '@mixins/enumOperate'
import empty from '@mixins/empty'

import { accMul } from '@utils'


// 接口请求
import getPolicyListService from '@/service/workSpace/policyMange/getPolicyListService'

const statisticsList = [
    {
        title: '合计保费',
        imgSrc: require('@assets/images/workSpace/policy/insurancePremiumTotal2x.png'),
        money: '0.00',
        unit: '元',
        key: 'insurancePremiumTotal'
    }, {
        title: '合计保险金额',
        imgSrc: require('@assets/images/workSpace/policy/insuranceAmountTotal2x.png'),
        money: '0.00',
        unit: '元',
        key: 'insuranceAmountTotal'
    }, {
        title: '合计保单笔数',
        imgSrc: require('@assets/images/workSpace/policy/insuranceCount2x.png'),
        money: '0',
        unit: '单',
        key: 'insuranceCount'
    }]

const statisticsListWithCommision = [
    {
        title: '合计保费',
        imgSrc: require('@assets/images/workSpace/policy/insurancePremiumTotal2x.png'),
        money: '0.00',
        unit: '元',
        key: 'insurancePremiumTotal'
    },
    {
        title: '合计保险金额',
        imgSrc: require('@assets/images/workSpace/policy/insuranceAmountTotal2x.png'),
        money: '0.00',
        unit: '元',
        key: 'insuranceAmountTotal'
    },
    {
        title: '合计保单笔数',
        imgSrc: require('@assets/images/workSpace/policy/insuranceCount2x.png'),
        money: '0',
        unit: '单',
        key: 'insuranceCount'
    },
    {
        title: '合计应收佣金',
        imgSrc: require('@assets/images/workSpace/policy/insuranceSettlementTotal2x.png'),
        money: '0.00',
        unit: '元',
        key: 'insuranceSettlementTotal'
    }]

export default {
    name: 'PolicyManage',
    components: {
        ATable,
        ListPageLayout,
        CommonTitle,
        ContainerPanel,
        CustomButton,
        SearchPanelWorkSpace,
        StatisticsBar,
        PolicyRecordDetail,
        PolicyRecordDetailOff,
        PolicyRecordDetailG
    },
    mixins: [thousandBit,
        tableShowTotal,
        enumOperate,
        empty],
    data() {
        const columnsNoCommision = [
            {
                title: '保单号',
                dataIndex: 'policyNo',
                key: 'policyNo',
                width: 220
            },
            {
                title: '签单日期',
                dataIndex: 'singleDateTime',
                key: 'singleDateTime',
                width: 200
            },
            {
                title: '险种',
                dataIndex: 'dangerPlantedName',
                key: 'dangerPlantedName',
                width: 200,
                ellipsis: true,
                customRender: text => this.empty(text)
            },
            {
                title: '投保用户',
                dataIndex: 'submitMobile',
                key: 'submitMobile',
                width: 200,
                customRender: (text, record) => {
                    const { submitId } = record
                    const result = `${text ? text : ''}${submitId ? '(' + submitId + ')' : ''}`

                    return this.empty(result)
                }
            },
            {
                title: '投保人',
                dataIndex: 'castEntepriseName',
                key: 'castEntepriseName',
                width: 200,
                customRender: text => this.empty(text)
            },
            {
                title: '被保人',
                dataIndex: 'insuredEnterpriseName',
                key: 'insuredEnterpriseName',
                width: 200,
                customRender: text => this.empty(text)
            },
            {
                title: '保险金额（元）',
                dataIndex: 'insuranceMoney',
                key: 'insuranceMoney',
                width: 200,
                customRender: text => this.empty(text)
            },
            {
                title: '保费（元）',
                dataIndex: 'payMoney',
                key: 'payMoney',
                width: 200
            },
            {
                title: '项目名称',
                dataIndex: 'projectName',
                key: 'projectName',
                width: 200
            },
            {
                title: '保险公司',
                dataIndex: 'insuranceEnterpriseName',
                key: 'insuranceEnterpriseName',
                width: 200
            },
            {
                title: '保险起期',
                dataIndex: 'startInsuranceTime',
                key: 'startInsuranceTime',
                width: 200
            },
            {
                title: '保险止期',
                dataIndex: 'endInsuranceTime',
                key: 'endInsuranceTime',
                width: 200
            },
            {
                title: '经纪人',
                dataIndex: 'agentName',
                key: 'agentName',
                width: 200,
                customRender: (text, record) => {
                    const { agentId } = record
                    const result = `${text ? text : ''}${agentId ? '(' + agentId + ')' : ''}`

                    return this.empty(result)
                }
            },
            {
                title: '保单状态',
                dataIndex: 'state',
                width: 200
            },
            {
                title: '操作',
                key: 'action',
                fixed: 'right',
                width: 60,
                align: 'right',
                scopedSlots: { customRender: 'action' }
            }
        ]

        const columnsWithCommision = [
            {
                title: '保单号',
                dataIndex: 'policyNo',
                key: 'policyNo',
                width: 220
            },
            {
                title: '签单日期',
                dataIndex: 'singleDateTime',
                key: 'singleDateTime',
                width: 200
            },
            {
                title: '险种',
                dataIndex: 'dangerPlantedName',
                key: 'dangerPlantedName',
                width: 200,
                ellipsis: true,
                customRender: text => this.empty(text)
            },
            {
                title: '投保用户',
                dataIndex: 'submitMobile',
                key: 'submitMobile',
                width: 200,
                customRender: (text, record) => {
                    const { submitId } = record
                    const result = `${text ? text : ''}${submitId ? '(' + submitId + ')' : ''}`

                    return this.empty(result)
                }
            },
            {
                title: '投保人',
                dataIndex: 'castEntepriseName',
                key: 'castEntepriseName',
                width: 200,
                customRender: text => this.empty(text)
            },
            {
                title: '被保人',
                dataIndex: 'insuredEnterpriseName',
                key: 'insuredEnterpriseName',
                width: 200,
                customRender: text => this.empty(text)
            },
            {
                title: '保险金额（元）',
                dataIndex: 'insuranceMoney',
                key: 'insuranceMoney',
                width: 200,
                customRender: text => this.empty(text)
            },
            {
                title: '保费（元）',
                dataIndex: 'payMoney',
                key: 'payMoney',
                width: 200
            },
            {
                title: '应收佣金费率',
                dataIndex: 'personalResultRate',
                key: 'personalResultRate',
                width: 200,
                customRender: text => {
                    const result = text !== null ? `${accMul(text, 100).toFixed(2)}%` : '-'

                    return this.empty(result)
                }
            },
            {
                title: '应收佣金（元）',
                dataIndex: 'personalMoney',
                key: 'personalMoney',
                width: 200
            },
            {
                title: '项目名称',
                dataIndex: 'projectName',
                key: 'projectName',
                width: 200
            },
            {
                title: '保险公司',
                dataIndex: 'insuranceEnterpriseName',
                key: 'insuranceEnterpriseName',
                width: 200
            },
            {
                title: '保险起期',
                dataIndex: 'startInsuranceTime',
                key: 'startInsuranceTime',
                width: 200
            },
            {
                title: '保险止期',
                dataIndex: 'endInsuranceTime',
                key: 'endInsuranceTime',
                width: 200
            },
            {
                title: '经纪人',
                dataIndex: 'agentName',
                key: 'agentName',
                width: 200,
                customRender: (text, record) => {
                    const { agentId } = record
                    const result = `${text ? text : ''}${agentId ? '(' + agentId + ')' : ''}`

                    return this.empty(result)
                }
            },
            {
                title: '保单状态',
                dataIndex: 'state',
                width: 200
            },
            {
                title: '操作',
                key: 'action',
                fixed: 'right',
                width: 60,
                align: 'right',
                scopedSlots: { customRender: 'action' }
            }
        ]
        return {
            CUSTOMER_POLICY_BUSINESS_TYPE_TEAM,
            CUSTOMER_POLICY_BUSINESS_TYPE_WORKSPACE,
            POLICY_POLICY_TYPE_ONE,
            POLICY_POLICY_TYPE_TWO,
            POLICY_POLICY_TYPE_THREE,
            columns: [],
            columnsNoCommision,
            columnsWithCommision,
            tabData: {},
            // 统计-数据
            statisticsList,
            // 统计-loading
            statisticsLoading: false,
            // 保单号
            id: '',
            // 投保单号
            castId: '',
            // 查询条件
            searchParams: {},
            // 详情-线上保单-抽屉
            visible: false,
            // 详情-线下保单-抽屉
            visibleOff: false,
            // 详情-G端保单-抽屉
            visibleG: false,
            // 数据
            data: [],
            // 总记录数量
            total: 0,
            // 当前索引
            current: 1,
            // 分页数量
            limit: DEFAULT_PAGES_SIZE,
            // 加载状态
            loading: false,
            loginUser: '',
            // 个人佣金是否可见状态
            commissionVisibility: false
        }
    },
    computed: {
        userInfo() {
            return this.$store.getters.userInfo
        },
        pagination() {
            const {
                total,
                limit,
                current,
                tableShowTotal
            } = this
            return {
                showTotal: (total, range) => tableShowTotal(total, range),
                current,
                total,
                pageSize: limit,
                showSizeChanger: true,
                showQuickJumper: true
            }
        }
    },
    watch: {
        $route: {
            handler(route) {
                const { query } = route
                if (query && Object.keys(query).length > 0) {
                    this.init(query)
                }
            },
            immediate: true
        }
    },
    mounted() {},
    methods: {
        /**
             * 初始化
             */
        init(query) {
            const { CUSTOMER_POLICY_BUSINESS_TYPE_TEAM } = this
            const { businessType, userId, commissionVisibility } = query
            this.businessType = Number(businessType)
            this.commissionVisibility = commissionVisibility
            if (this.commissionVisibility) {
                this.columns = this.columnsWithCommision
                this.statisticsList = statisticsListWithCommision
            } else {
                this.columns = this.columnsNoCommision
                this.statisticsList = statisticsList
            }

            // from 团队
            if (this.businessType === CUSTOMER_POLICY_BUSINESS_TYPE_TEAM) {
                this.tabData = { loginUser: userId }
            }
        },
        /**
         * 返回
         */
        handleBack() {
            this.$router.back()
        },
        /**
         * 响应 table change 事件
         * @param pagination
         */
        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.current = current
            this.limit = pageSize
            this.getList()
        },
        /**
         * 查看
         */
        handleDetail(action) {
            const { id, castInsuranceId, type } = action
            this.id = id
            this.castId = castInsuranceId
            if (type === POLICY_POLICY_TYPE_ONE) {
                this.visible = true
            } else if (type === POLICY_POLICY_TYPE_TWO) {
                this.visibleOff = true
            } else if (type === POLICY_POLICY_TYPE_THREE) {
                this.visibleG = true
            }
        },
        /**
         * 保单列表
         */
        async handleGetInit(params) {
            // 1. 默认筛选条件
            this.searchParams = params

            // 处理  工作台/团队  参数
            await this.handleTeamAndWork()
            const { loginUser } = this

            // 2. 获取 列表
            if (loginUser) {
                this.getList()
            }
        },
        /**
         * 处理  工作台/团队  参数
         */
        async handleTeamAndWork() {
            const { businessType } = this

            // from 工作台
            if (businessType === CUSTOMER_POLICY_BUSINESS_TYPE_WORKSPACE) {
                const { id } = this.userInfo || {}
                this.loginUser = id
            } else if (businessType === CUSTOMER_POLICY_BUSINESS_TYPE_TEAM) {
                // from 团队
                const { loginUser } = this.tabData || {}
                this.loginUser = loginUser
            }
        },
        /**
         * 获取 列表
         */
        async getList() {
            try {
                this.loading = true
                const {
                    searchParams,
                    current,
                    limit,
                    loginUser
                } = this
                const data = {
                    current,
                    size: limit,
                    loginUser,
                    ...searchParams
                }

                const { total, records, extra } = await getPolicyListService(data)
                this.total = total || 0
                this.data = records || []

                // 加载统计数据
                this.loadStatistics(extra || {})
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        },
        /**
         * 加载统计数据
         */
        loadStatistics(data) {
            // 数据格式化
            this.statisticsList.forEach(item => {
                if (item.key !== 'insuranceCount') {
                    item.money = data[item.key] && parseFloat(data[item.key]).toFixed(2) || '0.00'
                    // item.money = this.thousandBit(data[item.key] * 1 ? data[item.key].toFixed(2) : '0.00')
                } else {
                    item.money = this.thousandBit(data[item.key] * 1 ? data[item.key] : '0')
                }
            })
        },
        /**
         * 响应搜索事件
         */
        handleWorkSpaceSearch(params) {
            this.current = 1
            this.searchParams = params
            this.getList()
        },
        /**
         * 响应刷新事件
         */
        handleWorkSpaceRefresh(params) {
            this.handleWorkSpaceSearch(params)
        },
        /**
         * 查看-线上保单-关闭
         */
        handleDetailClose() {
            this.visible = false
        },
        /**
         * 查看-线下保单-关闭
         */
        handleDetailCloseOff() {
            this.visibleOff = false
        },
        /**
         * 查看-G端保单-关闭
         */
        handleDetailCloseG() {
            this.visibleG = false
        }
    }
}
</script>

<style lang="scss">
    .policy-manage-page {
        .container-panel {
            border: none;
            .tip-icon {
                margin-right: 2px;
                color: #d40000;
            }
        }
    }
</style>
