/**
 * 公海客户-列表权限
 * ——————————————————————————————————————————————————————————————————————————————————————————————
 */
// 公海客户管理-添加客户
export const HIGHSEAS_CUSTOMER_ADD_BUTTON = 'HIGHSEAS_CUSTOMER_ADD'
// 公海客户列表-分配
export const HIGHSEAS_CUSTOMER_ALLOT_BUTTON = 'HIGHSEAS_CUSTOMER_ALLOT'
// 公海客户列表-备注
export const HIGHSEAS_CUSTOMER_REMARK_BUTTON = 'HIGHSEAS_CUSTOMER_REMARK'
// 公海客户列表-跟进
export const HIGHSEAS_CUSTOMER_FOLLOW_BUTTON = 'HIGHSEAS_CUSTOMER_FOLLOW'
// 公海客户详情-查看电子保单
export const HIGHSEAS_CUSTOMER_VIEW_POLICY_BUTTON = 'HIGHSEAS_CUSTOMER_VIEW_POLICY'
// 公海客户详情-查看电子发票
export const HIGHSEAS_CUSTOMER_ELECTRONIC_INVOICE_BUTTON = 'HIGHSEAS_CUSTOMER_ELECTRONIC_INVOICE'
