import { CUSTOMER_FOLLOW_TYPE_ENUM_LIST } from '@/constant/enum'
import { getLabelformList } from '@/utils'
import moment from 'moment'

export default [
    {
        hideInTable: true,
        title: '跟进方式',
        dataIndex: 'type',
        valueType: 'select',
        fieldProps: {
            options: [
                {
                    label: '全部',
                    value: ''
                }, ...CUSTOMER_FOLLOW_TYPE_ENUM_LIST
            ]
        }
    },
    {
        hideInTable: true,
        title: '标题',
        dataIndex: 'titleQuery'
    },
    {
        hideInTable: true,
        title: '跟进人',
        dataIndex: 'agentUserNameQuery'
    },
    {
        hideInSearch: true,
        title: '跟进ID',
        dataIndex: 'id'
    },
    {
        hideInSearch: true,
        title: '跟进人',
        dataIndex: 'createName'
    },
    {
        title: '跟进时间',
        dataIndex: 'dataTime',

        valueType: 'dateRange',
        span: 12,
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
        fieldProps: {
            showTime: { defaultValue: [moment('2022-01-01 00:00:00'), moment('2022-01-01 23:59:59')] },
            format: 'YYYY-MM-DD HH:mm:ss',
            disabledDate: () => false
        }
    },
    {
        hideInSearch: true,
        title: '跟进方式',
        dataIndex: 'type',
        customRender: getLabelformList(CUSTOMER_FOLLOW_TYPE_ENUM_LIST)
    },
    {
        hideInSearch: true,
        title: '跟进标题',
        dataIndex: 'title'
    },
    {
        hideInSearch: true,
        title: '跟进内容',
        dataIndex: 'content'
    },
    {
        title: '联系人',
        dataIndex: 'contactPerson'
    },
    {
        title: '联系方式',
        dataIndex: 'contactPersonPhone'
    },
    {
        hideInSearch: true,
        title: '下次跟进时间',
        dataIndex: 'nextDataTime'
    },
    {
        hideInSearch: true,
        title: '下次跟进内容',
        dataIndex: 'nextContent'
    }
].map(item => ({
    ...item,
    width: 180
}))
