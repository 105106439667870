<!-- 客户详情-保单记录-筛选 -->
<template>
    <search-panel :model="form"  layout="vertical" @search="handleSearch" @refresh="handleRet">
        <form-col label="险种" prop="dangerPlantedId">
            <template :slot="formColDefaultSlot">
                <a-select v-model="form.dangerPlantedId" placeholder="请选择">
                    <a-select-option value>全部</a-select-option>
                    <template v-for="item in insuranceList">
                        <a-select-option :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
                    </template>
                </a-select>
            </template>
        </form-col>
        <form-col label="保单状态" prop="state">
            <template :slot="formColDefaultSlot">
                <a-select v-model="form.state" placeholder="请选择">
                    <a-select-option value>全部</a-select-option>
                    <template v-for="item in POLICY_ORDER_STATUS_ENUM_LIST">
                        <a-select-option :key="item.value" :value="item.label">{{ item.label }}</a-select-option>
                    </template>
                </a-select>
            </template>
        </form-col>
        <form-col label="保单号" prop="policyNo">
            <template :slot="formColDefaultSlot">
                <a-input v-model="form.policyNo" placeholder="请输入" />
            </template>
        </form-col>
            <form-col label="保险公司" prop="insuranceEnterpriseId">
                <template :slot="formColDefaultSlot">
                    <a-select v-model="form.insuranceEnterpriseId" placeholder="请选择">
                        <a-select-option value>全部</a-select-option>
                        <template v-for="item in insureCompanyList">
                            <a-select-option :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
                        </template>
                    </a-select>
                </template>
            </form-col>
            <form-col label="投保人" prop="castEntepriseNameLike">
                <template :slot="formColDefaultSlot">
                    <a-input v-model="form.castEntepriseNameLike" placeholder="请输入" />
                </template>
            </form-col>
            <form-col label="被保人" prop="insuredEnterpriseNameLike">
                <template :slot="formColDefaultSlot">
                    <a-input v-model="form.insuredEnterpriseNameLike" placeholder="请输入" />
                </template>
            </form-col>
            <form-col label="项目名称" prop="projectNameLike">
                <template :slot="formColDefaultSlot">
                    <a-input v-model="form.projectNameLike" placeholder="请输入" />
                </template>
            </form-col>
        <template :slot="searchExpansionSlot">
            <form-col label=""
                      :span="12"
                      :label-col="labelCol"
                      :wrapper-col="wrapperCol">
                <template :slot="formColDefaultSlot">
                    <a-row>
                        <a-col :span="4">
                            <a-select v-model="form.dateType" placeholder="请选择">
                                <template v-for="item in dateSelect">
                                    <a-select-option :key="item.value" :value="item.value">{{ item.label }}</a-select-option>
                                </template>
                            </a-select>
                        </a-col>
                        <a-col :span="20">
                            <form-col
                                :label-col="labelCol"
                                :wrapper-col="wrapperCol"
                                :span="24"
                                prop="submitTime">
                                <template :slot="formColDefaultSlot">
                                    <a-range-picker v-model="form.time"
                                                    :mode="submitTimeMode"
                                                    :show-time="showTimeOptions"
                                    />
                                </template>
                            </form-col>
                        </a-col>
                    </a-row>
                </template>
            </form-col>
        </template>
    </search-panel>
</template>

<script>
    // 组件
    import { Row as ARow, Col as ACol } from 'ant-design-vue'

// 常量
import { POLICY_ORDER_STATUS_ENUM_LIST } from '@/constant/enum'

// mixins
import common from '../../mixins'

// 工具类
import { filterParams } from '@utils/search.js'

// 接口
import getInsuranceTypeListService from '@service/workSpace/getInsuranceTypeListService'
import getInsureCompanyListService from '@service/insureCompany/getInsureCompanyListService'

export default {
    name: 'PolicyRecordSearchPanel',
    mixins: [common],
    components: {
        ARow,
        ACol
    },
    props: {
        echoData: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            POLICY_ORDER_STATUS_ENUM_LIST,
            form: {
                // 保单号
                policyNo: '',
                // 保单状态
                state: '',
                // 投保人
                castEntepriseNameLike: '',
                // 被保人
                insuredEnterpriseNameLike: '',
                // 险种
                dangerPlantedId: '',
                // 保险公司
                insuranceEnterpriseId: '',
                // 项目名称
                projectNameLike: '',
                // 日期
                dateType: 1,
                time: []
            },
            labelCol: {
                span: 0
            },
            wrapperCol: {
                span: 24
            },
            dateSelect: [
                {
                    value: 1,
                    label: '签单日期'
                },
                {
                    value: 2,
                    label: '保险起期'
                },
                {
                    value: 3,
                    label: '保险止期'
                }
            ],
            insuranceList: [],
            insureCompanyList: []
        }
    },
    watch: {
        echoData: {
            handler(val) {
            },
            immediate: true
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.getInsuranceListService()
            this.getInsureCompanyList()
        },
        /**
         * 获取险种
         */
        async getInsuranceListService() {
            try {
                const data = await getInsuranceTypeListService()
                this.insuranceList = data || []
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {}
        },
        /**
         * 获取保险公司
         */
        async getInsureCompanyList() {
            try {
                const data = await getInsureCompanyListService()
                this.insureCompanyList = data || []
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {}
        },
        /**
         * 格式化参数
         */
        formatParams() {
            const { form } = this
            const timeObj = {
                // 签单日期
                1: {
                    start: 'beginSingleTime',
                    end: 'endSingleTime'
                },
                // 保险起期
                2: {
                    start: 'beginStartInsuranceTime',
                    end: 'stopStartInsuranceTime'
                },
                // 保险止期
                3: {
                    start: 'beginEndInsuranceTime',
                    end: 'stopEndInsuranceTime'
                }
            }
            let { time, dateType, ...params } = form
            let [startTime, endTime] = time || []
            startTime = startTime ? `${startTime.format('YYYY-MM-DD HH:mm:ss')}` : ''
            endTime = endTime ? `${endTime.format('YYYY-MM-DD HH:mm:ss')}` : ''

            const startField = timeObj[dateType].start
            const endField = timeObj[dateType].end
            params[startField] = startTime
            params[endField] = endTime

            return params
        },
        /**
         * 重置
         */
        handleRet() {
            this.form.dateType = 1
            this.form.time = []
            const params = this.formatParams()
            this.$emit('refresh', filterParams(params))
        }
    }
}
</script>

<style lang="scss">
</style>
