<!-- 客户详情-发票记录 -->
<template>
    <container-panel>
        <!-- 筛选 start -->
        <template #screen>
            <search-panel slot="header" @search="handleListSearch" @refresh="handleListRefresh" />
        </template>
        <!-- 筛选 end -->

        <!-- 表格 start -->
        <template #content>
            <a-table
                size="middle"
                :columns="columns"
                :data-source="data"
                :pagination="pagination"
                :loading="loading"
                row-key="insuranceNo"
                :scroll="{ x: '100%' }"
                @change="handleTableChange"
            >
                <!-- 发票类型 -->
                <template slot="invoiceType" slot-scope="invoiceType">
                    {{ getApiEnumLabelFromList(INVOICE_ORDER_TYPE_ENUM_LIST, invoiceType) }}
                </template>
                <!-- 状态 -->
                <template slot="state" slot-scope="state">
                    {{ getApiEnumLabelFromList(INVOICE_STATE_MENU_LIST, state) }}
                </template>
                <!-- 操作 -->
                <template slot="action" slot-scope="action">
                    <custom-button text="查看" type="link" @click="handleDetail(action)" />
                </template>
            </a-table>
        </template>
        <!-- 筛选 end -->

        <!-- 详情 start -->
        <invoice-record-detail
            :visible="visible"
            :castInsuranceId="castInsuranceId"
            @close="handleDetailClose"
        />
        <!-- 详情 end -->
    </container-panel>
</template>

<script>
    // 组件
    import CustomButton from '@components/Button'
    import InvoiceRecordDetail from '@weights/Customer/Components/ComponentsDetail/Detail/InvoiceRecordDetail'
    import SearchPanel from './SearchPanel'

    // 常量
    import { INVOICE_ORDER_TYPE_ENUM_LIST, INVOICE_STATE_MENU_LIST } from '@/constant/enum'

    // mixins
    import common from '../mixins'

    // 接口请求
    import getCastManageAllPageService from '@service/customer/getCastManageAllPageService'

    export default {
        name: 'InvoiceRecordTabPanel',
        components: {
            CustomButton,
            SearchPanel,
            InvoiceRecordDetail
        },
        mixins: [common],
        props: {
            params: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
        data() {
            const columns = [
                {
                    title: '保单号',
                    dataIndex: 'insuranceNo',
                    key: 'insuranceNo',
                    width: 200
                },
                {
                    title: '提交时间',
                    dataIndex: 'applyTime',
                    key: 'applyTime',
                    width: 200
                },
                {
                    title: '险种',
                    dataIndex: 'dangerPlantedName',
                    key: 'dangerPlantedName',
                    width: 200,
                    ellipsis: true,
                    customRender: (text) => {
                        return this.empty(text)
                    }
                },
                {
                    title: '投保人',
                    dataIndex: 'castInsuranceEnterprise',
                    key: 'castInsuranceEnterprise',
                    width: 200,
                    customRender: (text) => {
                        return this.empty(text)
                    }
                },
                {
                    title: '保费（元）',
                    dataIndex: 'payMoney',
                    key: 'payMoney',
                    width: 200
                },
                {
                    title: '保险公司',
                    dataIndex: 'insuranceCompany',
                    key: 'insuranceCompany',
                    width: 200
                },
                {
                    title: '发票类型',
                    dataIndex: 'invoiceType',
                    key: 'invoiceType',
                    width: 200,
                    scopedSlots: {
                        customRender: 'invoiceType'
                    }
                },
                {
                    title: '状态',
                    dataIndex: 'state',
                    width: 200,
                    scopedSlots: {
                        customRender: 'state'
                    }
                },
                {
                    title: '操作',
                    key: 'action',
                    fixed: 'right',
                    width: 60,
                    align: 'right',
                    scopedSlots: {
                        customRender: 'action'
                    }
                }
            ]

            return {
                INVOICE_ORDER_TYPE_ENUM_LIST,
                INVOICE_STATE_MENU_LIST,
                columns,
                // 经纪人id
                agentUserId: '',
                // 投保用户id
                castInsuranceUserId: '',
                // 投保单id
                castInsuranceId: '',
                // 查询条件
                searchParams: {},
                // 数据
                data: [],
                // 总记录数量
                total: 0,
                // 当前索引
                current: 1,
                // 加载状态
                loading: false,
                // 详情-抽屉
                visible: false
            }
        },
        watch: {
            params: {
                handler(params) {
                    const { agentUserId, castInsuranceUserId } = params
                    if (!(agentUserId && castInsuranceUserId)) return

                    this.agentUserId = agentUserId
                    this.castInsuranceUserId = castInsuranceUserId

                    // 获取列表
                    this.getList()
                },
                immediate: true
            }
        },
        mounted() {
        },
        methods: {
            /**
             * 获取 列表
             */
            async getList() {
                try {
                    this.loading = true
                    const {
                        agentUserId,
                        castInsuranceUserId,
                        searchParams,
                        current,
                        limit
                    } = this
                    const { total, records } = await getCastManageAllPageService({
                        agentUserId,
                        castInsuranceUserId,
                        current,
                        size: limit,
                        ...searchParams
                    })
                    this.total = total || 0
                    this.data = records || []
                } catch (e) {
                    this.$message.error(e.message)
                    this.$captureException(e)
                } finally {
                    this.loading = false
                }
            },
            /**
             * 查看
             */
            handleDetail(action) {
                const { castInsuranceId } = action
                this.castInsuranceId = castInsuranceId
                this.visible = true
            }
        }
    }
</script>
