import getProvinceRedisService from '@/service/getProvinceRedisService'

export const formatAddress = async codeList => {
    const data = await getProvinceRedisService()
    const nameList = []
    const findFn = (list = {}, i = 0) => {
        list.forEach(item => {
            const { children, label, value } = item
            if (value === codeList[i]) {
                nameList[i] = label
                if (children?.length) {
                    findFn(children, i + 1)
                }
            }
        })
    }
    findFn(data)
    return nameList.join(' ')
}
