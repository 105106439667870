<!-- 客户详情-线索记录-筛选 -->
<template>
    <search-panel :model="form"  layout="vertical" @search="handleSearch" @refresh="handleRefresh">
        <form-col label="类型" prop="sourceType">
            <template :slot="formColDefaultSlot">
                <a-select v-model="form.sourceType" placeholder="请选择">
                    <a-select-option value>全部</a-select-option>
                    <template v-for="item in CUSTOMER_LEAD_SOURCE_TYPE_ENUM_LIST">
                        <a-select-option :key="item.value" :value="item.value">{{ item.label }}</a-select-option>
                    </template>
                </a-select>
            </template>
        </form-col>
        <form-col label="订单状态" prop="stateQuery">
            <template :slot="formColDefaultSlot">
                <a-select v-model="form.stateQuery" placeholder="请选择">
                    <a-select-option value>全部</a-select-option>
                    <template v-for="item in CUSTOMER_LEAD_ORDER_STATE_ENUM_LIST">
                        <a-select-option :key="item.value" :value="item.value">{{ item.label }}</a-select-option>
                    </template>
                </a-select>
            </template>
        </form-col>
        <form-col label="企业名称" prop="enterpriseNameQuery">
            <template :slot="formColDefaultSlot">
                <a-input v-model="form.enterpriseNameQuery" placeholder="请输入" />
            </template>
        </form-col>
        <form-col label="地区" prop="code">
            <template :slot="formColDefaultSlot">
                <a-cascader v-model="form.code"
                            :options="areaData"
                            placeholder="请选择"
                            change-on-select
                            @change="handleChangeArea" />
            </template>
        </form-col>
        <form-col label="服务单号" prop="id">
            <template :slot="formColDefaultSlot">
                <a-input v-model="form.id" placeholder="请输入" />
            </template>
        </form-col>
<!--            <form-col label="经纪人" prop="agentUserNameQuery">-->
<!--                <template :slot="formColDefaultSlot">-->
<!--                    <a-input v-model="form.agentUserNameQuery" placeholder="请输入" />-->
<!--                </template>-->
<!--            </form-col>-->
        <form-col
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            :span="12"
            label="提交时间"
            prop="submitTime">
            <template :slot="formColDefaultSlot">
                <a-range-picker v-model="form.submitTime"
                                :mode="submitTimeMode"
                                :show-time="showTimeOptions"
                />
            </template>
        </form-col>
    </search-panel>
</template>

<script>
// 常量
import { CUSTOMER_LEAD_ORDER_STATE_ENUM_LIST, CUSTOMER_LEAD_SOURCE_TYPE_ENUM_LIST } from '@/constant/enum'

// mixins
import selectArea from '@mixins/selectArea'
import common from '../../mixins'

export default {
    name: 'LeadRecordSearchPanel',
    props: {
        echoData: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    mixins: [selectArea, common],
    data() {
        return {
            CUSTOMER_LEAD_ORDER_STATE_ENUM_LIST,
            CUSTOMER_LEAD_SOURCE_TYPE_ENUM_LIST,
            form: {
                // 类型
                sourceType: '',
                // 服务单号
                id: '',
                // 订单状态
                stateQuery: '',
                // 经纪人
                // agentUserNameQuery: '',
                // 企业名称
                enterpriseNameQuery: '',
                // 地区
                code: [],
                // 省市区参数
                provinceCode: '',
                cityCode: '',
                areaCode: '',
                // 提交时间
                submitTime: []
            },
            labelCol: {
                span: 4
            },
            wrapperCol: {
                span: 20
            }
        }
    },
    watch: {
        echoData: {
            handler(val) {
            },
            immediate: true
        },
        'form.code': {
            handler(value) {
                const [ provinceCode, cityCode, areaCode ] = value
                this.form.provinceCode = provinceCode || ''
                this.form.cityCode = cityCode || ''
                this.form.areaCode = areaCode || ''
            },
            immediate: true
        }
    },
    mounted() {},
    methods: {
        /**
         * 格式化参数
         */
        formatParams() {
            const { form } = this
            let { submitTime, code, ...params } = form
            let [startTime, endTime] = submitTime || []
            startTime = startTime ? `${startTime.format('YYYY-MM-DD HH:mm:ss')}` : ''
            endTime = endTime ? `${endTime.format('YYYY-MM-DD HH:mm:ss')}` : ''
            params.startTime = startTime
            params.endTime = endTime
            return params
        }
    }
}
</script>

<style lang="scss">
</style>
