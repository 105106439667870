<template>
    <div class="click-captcha">
        <div :class="[checked ? 'click-captcha--active' : '']">
            <a-input v-decorator="decorator"
                     type="text"
                     :value="checked ? successText : failText"
                     :read-only="true"
                     @click="checked ? '' : showVerify ? visibleCaptcha = true : $emit('click')"
                     @focus="(e) => e.target.blur()">
                <template slot="prefix">
                    <a-icon v-if="checked"
                            type="check-circle"
                            theme="filled" />
                    <img v-else
                         class="verify-icon"
                         src="@/assets/images/icon/verify.png"
                         alt="">
                    <a-divider type="vertical" />
                </template>
            </a-input>
        </div>
        <slider-captcha :visible="visibleCaptcha"
                        @success="handleSuccess"
                        @fail="handleFail"
                        @cancel="visibleCaptcha = false" />
    </div>
</template>

<script>
import SliderCaptcha from '@/components/SliderCaptcha'

import {
    Input as AInput,
    Icon as AIcon,
    Divider as ADivider
} from 'ant-design-vue'
export default {
    name: 'ClickCaptcha',

    components: {
        SliderCaptcha,
        AInput,
        AIcon,
        ADivider
    },
    props: {
        // 验证成功 文字提示
        successText: {
            type: String,
            default: '验证成功'
        },

        failText: {
            type: String,
            default: '点击验证'
        },

        // 监听放入数组的数据，如果发生改变重置校验状态
        changeArr: null,

        // 是否显示验证
        showVerify: {
            type: null,
            default: true
        },

        decorator: null

    },
    data() {
        return {
            visibleCaptcha: false,
            checked: false
        }
    },

    watch: {
        checked: {
            handler(newD) {
                this.$emit('change', newD)
            }
            // immediate: true
        },

        changeArr: {
            handler(newD) {
                console.log('%c reset verify', 'color: lightgreen')
                this.checked = false
            },
            deep: true
        }
    },
    // 生命周期 - 创建完成
    created() {
        console.log(this.changeArr)
    },

    // DOM挂载完毕
    mounted() {
    },

    methods: {
        handleSuccess(data) {
            setTimeout(() => {
                this.visibleCaptcha = false
                this.checked = true
            }, 600)
        },

        handleFail(data) {
        },

        triggerBlur(e) {
            e.target.blur()
        }
    }
}
</script>
<style lang='scss'>
.click-captcha {
    .ant-input-affix-wrapper {
        .ant-input {
            height: 36px;
            color: #c8c9cc;
            cursor: pointer;
            background: linear-gradient(360deg, #e5f1fd 0%, #fff 100%);
        }

        .anticon {
            color: #999;
        }
    }

    .click-captcha--active {
        .ant-input-affix-wrapper .ant-input {
            color: #1890ff;
        }

        .anticon {
            color: #1890ff;
        }
    }
}

.verify-icon {
    width: 20px;
    height: 20px;
}
</style>
