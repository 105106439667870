<template>
    <layout-sider v-model="collapsed"
                  :class="layoutSideClassName"
                  :width="sidebarWidth"
                  :collapsible="collapsible"
                  :collapsed-width="sidebarCollapsedWidth"
                  :trigger="null">
        <div style="height: 48px;">
            <img src="@assets/images/decorate.jpg" alt="" width="60px" height="48px">
        </div>
        <div class="menu-list">
            <i-menu :theme="theme"
                    :collapsed="collapsed"
                    :menu-data="menuData"
                    @select="onSelect" />
        </div>
        <div class="gongbao-sider__footer" @click="toggleCollapse">
            <!-- 展开/关闭按钮 -->
            <a-icon
                style="margin-right: 10px;"
                :type="collapsed ? 'menu-unfold' : 'menu-fold'"
            />
            <span v-show="!collapsed">隐藏边栏</span>
        </div>
    </layout-sider>
</template>

<script>
import { Layout, Icon as AIcon } from 'ant-design-vue'
import IMenu from './menu'
import { sidebarWidth, sidebarCollapsedWidth } from '@assets/styles/varibles.scss'

export default {
    name: 'SiderMenu',
    components: {
        IMenu,
        AIcon,
        LayoutSider: Layout.Sider
    },
    props: {
        collapsible: {
            type: Boolean,
            required: false,
            default: false
        },
        collapsed: {
            type: Boolean,
            required: false,
            default: false
        },
        menuData: {
            type: Array,
            required: true
        },
        theme: {
            type: String,
            required: false,
            default: 'light'
        },
        fixed: {
            type: Boolean,
            default: false
        },
        isMobile: {
            type: Boolean,
            default: false
        },
        systemName: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            sidebarWidth,
            sidebarCollapsedWidth
        }
    },
    computed: {
        layoutSideClassName() {
            const { theme, fixed } = this
            return [
                theme, 'gongbao-sider', fixed ? 'gongbao-sider--fixed' : null
            ]
        }
    },
    methods: {
        onSelect(obj) {
            this.$emit('menuSelect', obj)
        },
        toggleCollapse() {
            this.$emit('toggleCollapse')
        }
    }
}
</script>

<style lang="scss">
@import "@assets/styles/varibles";

.gongbao-sider {
    z-index: 8;

    &.light {
        background-color: #fff;
    }

    &.dark {
        background-color: #001529;
    }

    &__footer {
        cursor: pointer;
    }

    &--fixed {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        border-right: none;
        box-shadow: 0 2px 30px 0 rgb(220 222 223 / 50%);

        .ant-menu-root {
            height: calc(100% - 32px);
            overflow: hidden;
            overflow-y: auto;
        }
    }

    .menu-list {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100% - 92px);
        overflow: hidden;
        overflow-y: auto;

        .ant-menu-item {
            margin: 0;
        }
    }

    &__footer {
        padding: 6px 22px;
        border-top: 1px solid #f0f0f0;
    }

    &__icon {
        &.anticon {
            font-size: 20px !important;
            vertical-align: -0.125em;

            &::before {
                display: inline-block;
            }
        }
    }

    .ant-menu-inline-collapsed {
        width: $sidebar-collapsed-width;

        & > .ant-menu-item,
        & > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
        & > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
        & > .ant-menu-submenu > .ant-menu-submenu-title {
            padding: 0 22px !important;
        }
    }

    .ant-menu-inline,
    .ant-menu-vertical,
    .ant-menu-vertical-left {
        border-right: none;
    }

    .ant-menu-item > a {
        color: #323233;
    }

    .ant-menu-item:not(.ant-menu-item-selected) i,
    .ant-menu-submenu i {
        color: #a3a5b3;
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: $base-color;
        transition: all 0.5;
    }

    .ant-menu-item-selected > a,
    .ant-menu-item-selected > a:hover {
        color: #fff;
    }

    // 一级+二级菜单hover 背景色
    .ant-menu-item-active,
    .ant-menu-submenu-active {
        background: #f4f5f7;
    }

    .ant-menu-vertical .ant-menu-item::after,
    .ant-menu-vertical-left .ant-menu-item::after,
    .ant-menu-vertical-right .ant-menu-item::after,
    .ant-menu-inline .ant-menu-item::after {
        color: #323233;
        border-right: none;
    }

    // 一级菜单的字体颜色
    .ant-menu-submenu-title,
    .ant-menu-submenu-title:hover {
        height: 48px !important;
        margin: 0 !important;
        line-height: 48px !important;
        color: #323233;
    }

    // 右边箭头的颜色
    .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
    .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after {
        background: #fff;
        background-image: linear-gradient(to right, rgb(0 0 0 / 65%), rgb(0 0 0 / 65%));
    }

    // 一级菜单选中时 icon+文本颜色
    .ant-menu-submenu-selected .iconfont,
    .ant-menu-submenu-selected .ant-menu-submenu-title {
        color: #1890ff;
    }
}
</style>
