<!-- 业务关联列表-添加关联/编辑关联 -->
<template>
    <custom-modal
        :loading="loading"
        :title="title"
        :visible="visible"
        :maskClosable="maskClosable"
        @close="handleCancel"
        @reset="handleReset"
        @ok="handleOk">
        <template>
            <!-- 表单 start-->
            <form-panel
                :mode="mode"
                :data="data"
                ref="formPanel"
                @success="handleSendSuccess"
                @loading="handleLoading"
            />
            <!-- 表单 end-->
        </template>
    </custom-modal>
</template>

<script>
    // 组件
    import FormPanel from './FormPanel'
    import CustomModal from '@components/CustomModal'

    export default {
        name: 'CreateOrUpdateAssociationModal',
        props: {
            visible: {
                type: Boolean,
                default() {
                    return false
                }
            },
            mode: {
                type: String,
                default() {
                    return ''
                }
            },
            data: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
        data() {
            return {
                loading: false,
                maskClosable: false,
            }
        },
        computed: {
            title() {
                const {mode} = this
                return mode === 'create' ? '添加关联' : '编辑关联'
            }
        },
        methods: {
            /**
             * 提交按钮事件
             */
            handleOk() {
                this.$refs.formPanel.handleSend()
            },
            /**
             * 重置按钮事件
             */
            handleReset() {
                this.$refs.formPanel.handleReset()
            },
            /**
             * 按钮loading
             */
            handleLoading(loading) {
                this.loading = loading
            },
            /**
             * 关闭弹框
             */
            handleCancel() {
                this.$emit('close')
            },
            handleSendSuccess() {
                this.$emit('success')
            }
        },
        components: {
            FormPanel,
            CustomModal
        }
    }
</script>

<style lang="scss">
</style>