<!--业务关联列表-添加/编辑-表单-->
<template>
    <a-form-model
        layout="horizontal"
        :labelCol="{ span: 6 }"
        :wrapperCol="{ span: 17 }"
        :model="form"
        ref="ruleForm"
        :rules="rules"
    >
        <!--业务助理  select-->
        <a-form-model-item label="业务助理" prop="assistantId">
            <a-select
                v-model="form.assistantId"
                placeholder="请选择业务助理"
            >
                <template v-for="item in assistantList">
                    <template v-if="item">
                        <a-select-option :key="item.userName" :value="item.userName">
                            {{ item.realName }}
                        </a-select-option>
                    </template>
                </template>
            </a-select>
        </a-form-model-item>

        <!--关联用户标签  select-->
        <a-form-model-item label="关联用户标签" prop="type">
            <a-select
                v-model="form.type"
                placeholder="请选择关联用户标签"
                @change="handleTagChange"
            >
                <template v-for="item in userTagList">
                    <template v-if="item">
                        <a-select-option :key="item.label" :value="item.label">
                            {{ item.label }}
                        </a-select-option>
                    </template>
                </template>
            </a-select>
        </a-form-model-item>

        <!--业务关联账号  select-->
        <a-form-model-item label="业务关联账号" prop="housekeeperId">
            <a-select
                v-model="form.housekeeperId"
                placeholder="请选择业务关联账号"
                @change="handleAccountChange"
            >
                <template v-for="item in associatedAccountList">
                    <template v-if="item">
                        <a-select-option :key="item.userName" :value="item.userName">
                            {{ item.realName }}
                        </a-select-option>
                    </template>
                </template>
            </a-select>
        </a-form-model-item>
    </a-form-model>
</template>

<script>
    // 组件
    import {
        FormModel as AFormModel,
        Select as ASelect,
        Input as AInput
    } from 'ant-design-vue'

    // 工具类
    import { filterParams } from '@utils/search.js'

    // 枚举数据
    import { 
        ASSISTANT_LIST_ID, 
        BUSINESS_HOUSEKEEPER_ENUM_LIST, 
        GROUP_ID_ENUM_LIST,
        BUSINESS_HOUSEKEEPER, 
        SPECIAL_BUSINESS_HOUSEKEEPER,
        BUSINESS_HOUSEKEEPER_ID,
        SPECIAL_AGENT_ID,
        BUSINESS_HOUSEKEEPER_TEXT,
        SPECIAL_BUSINESS_HOUSEKEEPER_TEXT
    } from '@constant/enum'

    // 接口请求
    import associationAddService from '@service/steward/businessAssociation/associationAddService'
    import associationUpdateService from '@service/steward/businessAssociation/associationUpdateService'
    import getAssociationAccountListService from '@service/steward/businessAssociation/getAssociationAccountListService'

    export default {
        name: 'FormPanel',
        props: {
            // 编辑还是添加模式
            mode: {
                type: String,
                default() {
                    return ''
                }
            },
            // 当前数据
            data: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
        data() {
            return {
                // 业务助理列表
                assistantList: [],
                // 用户标签列表
                userTagList: BUSINESS_HOUSEKEEPER_ENUM_LIST,
                groupIdList: GROUP_ID_ENUM_LIST,
                // 业务关联账号列表
                associatedAccountList: [],
                form: {
                    // 当前id
                    id: '',
                    // 业务助理
                    assistantId: undefined,
                    // 关联用户标签
                    type: undefined,
                    // 业务关联账号
                    housekeeperId: undefined,
                },
                rules: {
                    assistantId: [
                        {required: true, message: '请选择业务助理', trigger: 'blur'},
                    ],
                    type: [
                        {required: true, message: '请选择关联用户标签', trigger: 'blur'}
                    ],
                    housekeeperId: [
                        {required: true, message: '请选择业务关联账号', trigger: 'blur'}
                    ]
                },
                loading: false
            }
        },
        computed: {
            dataChange() {
                const {mode, data} = this
                return {
                    mode,
                    data
                }
            }
        },
        watch: {
            dataChange: {
                handler(val) {
                    const {mode, data} = val
                    if (mode === 'edit') {
                        this.formatForm(data)
                    } else {
                        this.formatForm()
                    }
                },
                immediate: true
            },

        },
        mounted() {
           this.getAssistantList()
        },
        methods: {
            /**
             * 获取业务助理列表
             */
            async getAssistantList() {
                try {
                    const data = await getAssociationAccountListService({groupId: ASSISTANT_LIST_ID})
                    this.assistantList =  data || []
                } catch(e) {
                    this.$message.error(e.message)
                }
            },
            /**
             * 获取业务关联账号
             */
            async getAssociatedAccountList(groupId) {
                try {
                    const data = await getAssociationAccountListService({groupId})
                    this.associatedAccountList = data || []
                } catch(e) {
                    this.$message.error(e.message)
                }
            },
            /**
             * 业务关联账号列表初始化
             */
            getAssociatedAccountListInit(type) {
                let groupObj = this.groupIdList.find(item => item.label === type) || {}
                let groupId = groupObj.value
                if(groupId) {
                    this.getAssociatedAccountList(groupId)
                }

            },
            /**
             * 用户标签/业务关联账号联动
             */
            async handleTagChange(e) {
                console.log(e)
                this.form.housekeeperId = undefined
                let groupId = ''
                if(e === BUSINESS_HOUSEKEEPER_TEXT) {
                    groupId = BUSINESS_HOUSEKEEPER_ID
                } else {
                    groupId = SPECIAL_AGENT_ID
                }
                this.getAssociatedAccountList(groupId)
            },
            handleAccountChange(e) {
                this.form.housekeeperId = e
            },
            /**
             * 新增
             */
            async updateAssociation(data) {
                try {
                    this.$emit('loading', true)
                    await associationUpdateService(data)
                    this.$message.success('添加成功')
                    this.$emit('success')
                } catch (e) {
                    this.$message.error(e.message)
                } finally {
                    this.$emit('loading', false)
                }
            },
            /**
             * 修改
             */
            async createAssociation(data) {
                try {
                    this.$emit('loading', true)
                    await associationAddService(data)
                    this.$message.success('修改成功')
                    this.$emit('success')
                } catch (e) {
                    this.$message.error(e.message)
                } finally {
                    this.$emit('loading', false)
                }
            },
            /**
             * 点击确定按钮
             */
            handleSend() {
                const {mode, form} = this
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                        let data = filterParams(form)
                        if (mode === 'edit') {
                            this.updateAssociation(data)
                        } else {
                            this.createAssociation(data)
                        }
                    }
                })
            },
            /**
             * 关闭弹框
             */
            handleCancel() {
                this.$emit('close')
            },
            /**
             * 格式化form数据
             * @param data
             */
            formatForm(data = {}) {
                const {
                    id = '',
                    assistantId = undefined,
                    type = undefined,
                    housekeeperId = undefined,
                } = data
                this.form = {
                    id,
                    assistantId,
                    type,
                    housekeeperId,
                }
                this.getAssociatedAccountListInit(type)
            },
            /**
             * 重置按钮事件
             */
            handleReset() {
                this.form = {
                    assistantId: undefined,
                    type: undefined,
                    housekeeperId: undefined,
                    id: this.form.id
                }
            }
        },
        components: {
            AFormModel,
            AFormModelItem: AFormModel.Item,
            ASelect,
            ASelectOption: ASelect.Option,
            AInput
        }
    }
</script>

<style lang="scss">
</style>