<!-- 详情-状态 -->
<template>
    <div class="status-panel">
        <div class="status-panel__header">
            <!-- logo start -->
            <div class="status-panel__logo">
                <slot v-if="$slots.logo" name="logo" />
            </div>
            <!-- logo end -->

            <!-- content start -->
            <div class="status-panel__content">
                <slot v-if="$slots.content" name="content" />
            </div>
        <!-- content end -->
        </div>

        <!-- button start -->
        <div class="status-panel__button">
            <slot v-if="$slots.button" name="button" />
        </div>
        <!-- content end -->
    </div>
</template>

<script>
export default {
    name: 'StatusPanel',
    props: {
        title: {
            type: String,
            default() {
                return ''
            }
        }
    }
}
</script>

<style lang='scss'>
    @import '@assets/styles/varibles.scss';

    .status-panel {
        height: 48px;
        line-height: 48px;
        margin: 24px;
        display: flex;
        justify-content: space-between;

        &__logo,
        &__content {
            display: inline-block;
        }
        &__button {
            display: inline-block;
        }

        /* header */
        &__header {
            display: flex;
            justify-content: flex-start;
        }

        /* logo */
        &__logo {
            img {
                width: 48px;
                height: 48px;
                vertical-align: bottom;
            }
        }

        /* content */
        &__content {
            margin-left: 16px;
            color: $custom-button-default-text-color;
            font-size: $font-sizeSixteen;
            font-weight: bold;
        }

        /* button */
        &__button {
            margin-left: 0px;
        }
    }
</style>
