<!-- 客户详情-列表组件：基本信息&客户备注 -->
<template>
    <a-spin :spinning="loading">
        <list-container :title="title" class="base-info">
            <!-- action start -->
            <template #action>
                <slot name="action"/>
            </template>
            <!-- action end -->

            <!-- content start -->
            <template #content>
                <a-descriptions v-bind="$attrs" :column="2">
                    <a-descriptions-item v-for="item in data" :key="item.key"  :label="item.key">
                        {{ empty(item.value) }}
                    </a-descriptions-item>
                </a-descriptions>
            </template>
            <!-- content end -->

            <slot />
        </list-container>
    </a-spin>
</template>

<script>
    // 组件
    import { Descriptions as ADescriptions } from 'ant-design-vue'
    import ListContainer from '../ListContainer'

    // 工具类
    import empty from '@mixins/empty'

    export default {
        name: 'BaseInfo',
        mixins: [empty],
        components: {
            ListContainer,
            ADescriptions,
            ADescriptionsItem: ADescriptions.Item
        },
        props: {
            data: {
                type: Array,
                default: () => []
            },
            title: {
                type: String,
                default: () => ''
            },
            loading: {
                type: Boolean,
                default() {
                    return false
                }
            }
        }
    }
</script>

<style lang="scss">
    .base-info {
        .ant-descriptions-item-label {
            color: #848B91;
        }
        .ant-descriptions-item-content {
            color: #323233;
        }
    }
</style>
