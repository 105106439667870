<!-- 线索管理/线索分配-->
<template>
    <list-page-layout>
        <container-panel>
            <!-- 标题 start-->
            <template slot="title">
                线索配置
            </template>
            <!-- 标题 end-->

            <!-- 副标题 start-->
            <template slot="content" slot-scope="">
                <p>请在下方表单编辑线索配置相关内容</p>
            </template>
            <!-- 副标题 end-->
        </container-panel>

        <container-panel>
            <!-- 内容 start-->
            <template slot="content" slot-scope="">
                <form-panel :mode="mode" :data="currentRow" @success="handleSuccess"></form-panel>
            </template>
            <!-- 内容 end-->
        </container-panel>
    </list-page-layout>
</template>


<script>
    // 组件
    import ListPageLayout from '@layout/ListPageLayout'
    import ContainerPanel from '@components/ContainerPanel'
    import FormPanel from './FormPanel'

    // 接口请求
    import getClueDistributeListService from '@service/clue/clueDistribute/getClueDistributeListService'

    export default {
        name: 'ClueDistributeList',
        data() {
            return {
                // 新增 or 修改
                mode: '',
                // 当前数据
                currentRow: {}
            }
        },
        computed: {
        },
        mounted() {
            // 获取列表
            this.getList()
        },
        methods: {
            /**
             * 获取 列表
             */
            async getList() {
                try {
                    this.loading = true
                    const result = await getClueDistributeListService()
                    if (result) {
                        if (result.length > 0) {
                            this.mode = 'edit'
                            this.currentRow = result[0]
                        } else {
                            this.mode = 'create'
                        }
                    }
                } catch (e) {
                    this.$message.error(e.message)
                } finally {
                    this.loading = false
                }
            },
            /**
             * 提交成功
             */
            handleSuccess() {
                this.getList()
            }
        },
        components: {
            ListPageLayout,
            ContainerPanel,
            FormPanel
        }
    }
</script>

<style lang="scss">
</style>