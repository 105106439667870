import {
    BASE_URL
} from '@config'

/** 线索
 * ————————————————————————————————————————————————————————————————————————————————————————————*/
/**
 * 线索-列表
 *
 */
export const GET_CLUE_LIST = {
    url: `${BASE_URL}/customer/potential-customer/select`,
    method: 'get',
    headers: {
        'content-type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
/**
 * 线索-添加
 *
 */
export const CREATE_CLUE = {
    url: `${BASE_URL}/customer/potential-customer/saveCustomerClues`,
    method: 'post',
    headers: {
        'content-type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
/**
 * 线索-添加备注
 *
 */
export const CLUE_CREATE_REMARK = {
    url: `${BASE_URL}/customer/potential-customer-communication/save`,
    method: 'post',
    headers: {
        'content-type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
/**
 * 线索-备注记录
 *
 */
export const GET_CLUE_REMARK_LIST = {
    url: `${BASE_URL}/customer/potential-customer-communication/select`,
    method: 'get',
    headers: {
        'content-type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}