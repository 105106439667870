export const userInfo = (state) => {
    return state.userInfo
}

export const isMobile = (state) => {
    return state.isMobile
}

export const theme = (state) => {
    return state.theme
}

export const layout = (state) => {
    return state.layout
}


export const multipage = (state) => {
    return state.multipage
}

export const fixedHeader = (state) => {
    return state.fixedHeader
}

export const fixedSidebar = (state) => {
    return state.fixedSidebar
}

export const routes = (state) => {
    return state.routes
}

export const addRouters = (state) => {
    return state.addRouters
}

export const role = (state) => {
    return state.role
}

export const buttonRole = (state) => {
    return state.buttonRole
}

export const systemName = (state) => {
    return state.systemName
}

export const copyright = (state) => {
    return state.copyright
}

export const licenseNumber = (state) => {
    return state.licenseNumber
}


export const paymentEarnestMoneyManagementType = (state) => {
    return state.paymentEarnestMoneyManagementType
}

export const isInit = (state) => {
    return state.isInit
}

export const wagesMarginType = (state) => {
    return state.wagesMarginType
}

export const loginBgImgLogoImgInfo = (state) => {
    return state.loginBgImgLogoImgInfo
}