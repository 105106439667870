<!-- 保单记录-详情-G端保单 -->
<template>
    <drawer-panel
        class="policy-record-detail"
        title="保单详情"
        :visible="visible"
        :loading="loading"
        @after-visible-change="afterVisibleChange"
        @close="handleClose"
    >
        <!-- 状态 start -->
        <template #top>
            <status-panel>
                <template #logo>
                    <img :src="imgSrc" alt="">
                </template>
                <template #content>
                    <span>当前保单状态：{{ record && record.state }}</span>
                </template>
            </status-panel>
        </template>
        <!-- 状态 end -->

        <template #content>
            <!-- 基本信息 start -->
            <field-list title="基本信息" :data="baseInfo">
                <template #tag>
                    <span class="policy-record-detail__tag">G端保单</span>
                </template>
            </field-list>
            <!-- 基本信息 end -->

            <!-- 保障内容 start -->
            <field-list title="保障内容" :data="coverageInfo" />
            <!-- 保障内容 end -->

            <!-- 项目信息 start -->
            <field-list v-if="projectText && projectText.length > 0" title="项目信息" :data="projectText" />
            <!-- 项目信息 end -->

            <!-- 投保人 start -->
            <field-list v-if="policyHolderText && policyHolderText.length > 0" title="投保人" :data="policyHolderText" />
            <!-- 投保人 end -->

            <!-- 被保人 start -->
            <field-list v-if="insuredText && insuredText.length > 0" title="被保人" :data="insuredText" />
            <!-- 被保人 end -->
        </template>

        <div class="policy-record-detail__detail-btns">
            <a-button
                v-auth="viewPolicy"
                @click="handleView('insuranceSingleUrl')"
            >
                查看保单
            </a-button>
            <a-button
                v-if="record && record.letterGuaranteeAddress"
                v-auth="viewProof"
                @click="handleView('letterGuaranteeAddress')"
            >
                查看保险凭证
            </a-button>
            <a-button
                v-auth="viewInvoice"
                @click="handleView('invoiceAddress')"
            >
                查看发票
            </a-button>
        </div>

        <view-modal :visible="viewVisible" :type="viewType" :status="0" @success="handleSuccess" />
    </drawer-panel>
</template>

<script>
// 组件
import ViewModal from '../PolicyRecordDetail/ViewModal'

// 常量
import {
    POLICY_ORDER_STATUS_INSURANCE_LABEL,
    POLICY_ORDER_STATUS_EFFECTIVE_LABEL,
    POLICY_ORDER_STATUS_RENEWED_LABEL,
    POLICY_ORDER_STATUS_SURRENDER_LABEL,
    // POLICY_ORDER_STATUS_OVERDUE,
    POLICY_ORDER_STATUS_SURRENDER,
    // POLICY_ORDER_STATUS_REFUSAL,
    ORDER_SOURCE_ENUM_LIST,
    PRESERVATION_STATE_MENU_LIST,
    SALES_CATEGORY_DISTRIBUTION,
    SALES_CATEGORY_SELF_SUPPORT,
    CUSTOMER_POLICY_BUSINESS_TYPE_WORKSPACE,
    CUSTOMER_POLICY_BUSINESS_TYPE_TEAM,
    CUSTOMER_POLICY_BUSINESS_TYPE_ONLINE
} from '@/constant/enum'
import {
    DISTRIBUTION_CUSTOMER_VIEW_POLICY_BUTTON,
    DISTRIBUTION_CUSTOMER_ELECTRONIC_INVOICE_BUTTON,
    DISTRIBUTION_CUSTOMER_VIEW_PROOF_BUTTON
} from '@constant/authEnum/distributionCustomer'
import {
    PROPRIETARY_CUSTOMER_VIEW_POLICY_BUTTON,
    PROPRIETARY_CUSTOMER_ELECTRONIC_INVOICE_BUTTON,
    PROPRIETARY_CUSTOMER_VIEW_PROOF_BUTTON
} from '@constant/authEnum/proprietaryCustomer'
import {
    ONLINE_POLICY_VIEW_POLICY_BUTTON,
    ONLINE_POLICY_VIEW_PROOF_BUTTON,
    ONLINE_POLICY_VIEW_ELECTRONIC_INVOICE_BUTTON
} from '@constant/authEnum/policy'

// mixins
import common from '../mixins'

// 接口请求
import searchPolicyService from '@service/customer/common/searchPolicyService'

export default {
    name: 'PolicyRecordDetailG',
    components: { ViewModal },
    mixins: [common],
    props: {
        id: {
            type: [Number, String],
            default() {
                return ''
            }
        },
        visible: {
            type: Boolean,
            default() {
                return false
            }
        },
        // 同businessType
        type: {
            type: [Number, String],
            default() {
                return ''
            }
        },
        // 客户id
        customerId: {
            type: [Number, String],
            default() {
                return ''
            }
        },
        // 经纪人id
        agentUserId: {
            type: [Number, String],
            default() {
                return ''
            }
        }
    },
    data() {
        return {
            POLICY_ORDER_STATUS_INSURANCE_LABEL,
            POLICY_ORDER_STATUS_EFFECTIVE_LABEL,
            POLICY_ORDER_STATUS_RENEWED_LABEL,
            POLICY_ORDER_STATUS_SURRENDER_LABEL,
            // POLICY_ORDER_STATUS_OVERDUE,
            POLICY_ORDER_STATUS_SURRENDER,
            // POLICY_ORDER_STATUS_REFUSAL,
            ORDER_SOURCE_ENUM_LIST,
            PRESERVATION_STATE_MENU_LIST,
            SALES_CATEGORY_DISTRIBUTION,
            SALES_CATEGORY_SELF_SUPPORT,
            CUSTOMER_POLICY_BUSINESS_TYPE_WORKSPACE,
            CUSTOMER_POLICY_BUSINESS_TYPE_TEAM,
            CUSTOMER_POLICY_BUSINESS_TYPE_ONLINE,
            DISTRIBUTION_CUSTOMER_VIEW_POLICY_BUTTON,
            DISTRIBUTION_CUSTOMER_ELECTRONIC_INVOICE_BUTTON,
            DISTRIBUTION_CUSTOMER_VIEW_PROOF_BUTTON,
            PROPRIETARY_CUSTOMER_VIEW_POLICY_BUTTON,
            PROPRIETARY_CUSTOMER_ELECTRONIC_INVOICE_BUTTON,
            PROPRIETARY_CUSTOMER_VIEW_PROOF_BUTTON,
            ONLINE_POLICY_VIEW_POLICY_BUTTON,
            ONLINE_POLICY_VIEW_PROOF_BUTTON,
            ONLINE_POLICY_VIEW_ELECTRONIC_INVOICE_BUTTON,
            loading: false,
            // 单条
            record: {},
            imgSrc: require('@/assets/images/waiting.png'),
            // 基本信息
            baseInfo: [],
            // 保障内容
            coverageInfo: [],
            // 项目信息
            projectText: [],
            // 投保人信息
            policyHolderText: [],
            // 被保人信息
            insuredText: [],
            // 查看电子保单-按钮权限
            viewPolicy: '',
            // 查看保单凭证-按钮权限
            viewProof: '',
            // 查看发票-按钮权限
            viewInvoice: '',
            // 查看弹框
            viewVisible: false,
            // 查看类型
            viewType: ''
        }
    },
    watch: {
        // 查看电子保单-按钮权限
        businessType: {
            handler(businessType) {
                // 分销
                if (businessType === SALES_CATEGORY_DISTRIBUTION) {
                    this.viewPolicy = DISTRIBUTION_CUSTOMER_VIEW_POLICY_BUTTON
                    this.viewProof = DISTRIBUTION_CUSTOMER_VIEW_PROOF_BUTTON
                    this.viewInvoice = DISTRIBUTION_CUSTOMER_ELECTRONIC_INVOICE_BUTTON
                } else if (businessType === SALES_CATEGORY_SELF_SUPPORT) {
                    // 自营
                    this.viewPolicy = PROPRIETARY_CUSTOMER_VIEW_POLICY_BUTTON
                    this.viewProof = PROPRIETARY_CUSTOMER_VIEW_PROOF_BUTTON
                    this.viewInvoice = PROPRIETARY_CUSTOMER_ELECTRONIC_INVOICE_BUTTON
                } else if (businessType === CUSTOMER_POLICY_BUSINESS_TYPE_WORKSPACE || businessType === CUSTOMER_POLICY_BUSINESS_TYPE_TEAM) {
                    // 工作台/团队
                    this.viewPolicy = DISTRIBUTION_CUSTOMER_VIEW_POLICY_BUTTON || PROPRIETARY_CUSTOMER_VIEW_POLICY_BUTTON
                    this.viewProof = DISTRIBUTION_CUSTOMER_VIEW_PROOF_BUTTON || PROPRIETARY_CUSTOMER_VIEW_PROOF_BUTTON
                    this.viewInvoice = DISTRIBUTION_CUSTOMER_ELECTRONIC_INVOICE_BUTTON || PROPRIETARY_CUSTOMER_ELECTRONIC_INVOICE_BUTTON
                }
            },
            immediate: true
        },
        // 查看电子保单-按钮权限
        type: {
            handler(type) {
                // 线上保单
                if (type === CUSTOMER_POLICY_BUSINESS_TYPE_ONLINE) {
                    this.viewPolicy = ONLINE_POLICY_VIEW_POLICY_BUTTON
                    this.viewProof = ONLINE_POLICY_VIEW_PROOF_BUTTON
                    this.viewInvoice = ONLINE_POLICY_VIEW_ELECTRONIC_INVOICE_BUTTON
                }
            },
            immediate: true
        }
    },
    methods: {
        /**
             * 初始化
             */
        async init() {
            // 获取单条
            await this.getRecord()

            // 数据初始化
            await this.initData()
        },
        /**
             * 获取单条
             */
        async getRecord() {
            const { id } = this
            if (!id) return

            try {
                this.loading = true
                const result = await searchPolicyService({
                    id,
                    assignment: true
                })
                this.record = result || {}
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        },
        /**
             * 数据初始化
             */
        async initData() {
            const { record, userInfo } = this
            const {
                policyNo,
                externalPlatformName,
                singleDateTime,
                externalSystemName,
                submitMobile,
                submitId,
                dangerPlantedName,
                insuranceEnterpriseName,
                insuranceMoney,
                payMoney,
                insuranceEnterpriseInstitutionsName,
                startInsuranceTime,
                endInsuranceTime,
                projectName,
                provinceName,
                cityName,
                areaName,
                castEntepriseName,
                insuredEnterpriseName,
                castEntepriseCode,
                insuredEnterpriseCode,
                insurancePolicyIntegratingRefundVO
            } = record || {}
            const {
                createDateTime,
                refundMoney,
                refundDateTime,
                refundStatus
            } = insurancePolicyIntegratingRefundVO || {}
            const { id } = userInfo
            const start = startInsuranceTime && startInsuranceTime.split(' ')[0]
            const end = endInsuranceTime && endInsuranceTime.split(' ')[0]

            // 0. 状态icon
            this.handelImgSrc()

            // 1. 基本信息
            // 投保用户超链接
            const insuredUserLink = await this.handleInsuredUser('投保用户', submitId, submitMobile)
            this.baseInfo = [
                {
                    key: '保单号',
                    value: policyNo
                },
                {
                    key: '渠道',
                    value: externalPlatformName
                },
                {
                    key: '签单日期',
                    value: singleDateTime
                },
                {
                    key: '平台系统',
                    value: externalSystemName
                },
                insuredUserLink,
                {
                    key: '退保操作日期',
                    value: createDateTime
                },
                {
                    key: '退保金额',
                    value: refundMoney
                },
                {
                    key: '退保日期',
                    value: refundDateTime
                },
                {
                    key: '退保状态',
                    value: refundStatus
                }
            ]

            // 2. 保障内容
            this.coverageInfo = [
                {
                    key: '险种',
                    value: dangerPlantedName
                },
                {
                    key: '保险金额（保函金额）元',
                    value: insuranceMoney
                },
                {
                    key: '保险公司',
                    value: insuranceEnterpriseName
                },
                {
                    key: '保费（元）',
                    value: payMoney
                },
                {
                    key: '分支机构',
                    value: insuranceEnterpriseInstitutionsName
                },
                {
                    key: '保险期间',
                    value: this.empty(this.handleRangeTime(start, end))
                }
            ]

            // 3. 项目信息
            this.projectText = [
                {
                    key: '项目名称',
                    value: projectName
                }, {
                    key: '项目属地',
                    value: `${provinceName ? provinceName : ''}${cityName ? cityName : ''}${areaName ? areaName : ''}`
                }
            ]

            // 4. 投保人信息
            this.policyHolderText = [
                {
                    key: '名称',
                    value: castEntepriseName
                }, {
                    key: '社会统一信用代码',
                    value: castEntepriseCode
                }
            ]

            // 5.被保人信息
            this.insuredText = [
                {
                    key: '名称',
                    value: insuredEnterpriseName
                }, {
                    key: '社会统一信用代码',
                    value: insuredEnterpriseCode
                }
            ]
        },
        /**
             * 状态icon
             */
        handelImgSrc() {
            // const { insuranceStatus } = this.record || {}
            // 没有insuranceStatus值，只有state值，文字匹配状态
            const { state } = this.record || {}

            // const errorList = [
            //     POLICY_ORDER_STATUS_OVERDUE,
            //     POLICY_ORDER_STATUS_SURRENDER,
            //     POLICY_ORDER_STATUS_REFUSAL
            // ]
            const errorList = [
                POLICY_ORDER_STATUS_SURRENDER_LABEL
            ]
            const successList = [
            ]
            const insuranceIngList = [
                POLICY_ORDER_STATUS_INSURANCE_LABEL
            ]
            const insuranceRenewList = [
                POLICY_ORDER_STATUS_RENEWED_LABEL
            ]

            // this.imgSrc = this.handleStateIcon(insuranceStatus, errorList)
            this.imgSrc = this.handleStateWordIcon(state, errorList, successList, insuranceIngList, insuranceRenewList)

        },
        /**
             * 查看
             */
        handleView(type) {
            const {
                insuranceSingleUrl,
                letterGuaranteeAddress,
                invoiceAddress
            } = this.record || {}

            // 电子保单
            if (type === 'insuranceSingleUrl') {
                if (insuranceSingleUrl) {
                    window.open(insuranceSingleUrl)
                } else {
                    this.viewType = 1
                    this.viewVisible = true
                }
            } else if (type === 'letterGuaranteeAddress') {
                // 凭证
                if (letterGuaranteeAddress) {
                    window.open(letterGuaranteeAddress)
                }
            } else if (type === 'invoiceAddress') {
                // 发票
                if (invoiceAddress) {
                    window.open(invoiceAddress)
                } else {
                    this.viewType = 6
                    this.viewVisible = true
                }
            }
        },
        handleSuccess() {
            this.viewVisible = false
        }
    }
}
</script>

<style lang="scss">
    .policy-record-detail {
        &__tag {
            display: inline-block;
            height: 28px;
            line-height: 28px;
            margin-left: 11px;
            padding:0 10px;
            background: #F2FBFF;
            border-radius: 14px;
            color: #1079C5;
            text-align: center;
        }
        &__detail-btns {
            padding: 12px 24px;
            text-align: right;
            button {
                margin-left: 8px;
                border-color: #1877F2;
                color: #1877F2;
            }
        }
    }
</style>


