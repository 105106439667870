<!-- 客户详情-客户概况 -->
<template>
    <div>
        <!-- 基本信息 start -->
        <base-info
            v-if="businessType === SALES_CATEGORY_SELF_SUPPORT"
            title="基本信息"
            :data="baseInfo"
            :loading="loading"
        />
        <!-- 基本信息 end -->

        <!-- 客户备注 start -->
        <base-remarks :params="params" @refresh-head="handleRefreshHead" />
        <!-- 客户备注 end -->
    </div>
</template>

<script>
    // 组件
    import BaseInfo from '@weights/Customer/Components/ComponentsDetail/BaseInfo'
    import BaseRemarks from '@weights/Customer/Components/ComponentsDetail/BaseRemarks'

    // 常量
    import { SALES_CATEGORY_DISTRIBUTION, SALES_CATEGORY_SELF_SUPPORT, CUSTOMER_BASE_INFO_SEX_LIST } from '@/constant/enum'

    // mixins
    import common from '../mixins'

    // api
    import getCustomerSelectOneCustomerService from '@service/customer/getCustomerSelectOneCustomerService'

    export default {
        name: 'CustomerProfileTabPanel',
        components: {
            BaseInfo,
            BaseRemarks
        },
        mixins: [common],
        props: {
            params: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
        data() {
            return {
                SALES_CATEGORY_DISTRIBUTION,
                SALES_CATEGORY_SELF_SUPPORT,
                CUSTOMER_BASE_INFO_SEX_LIST,
                // 客户id
                customerId: '',
                // 基本信息
                baseInfo: [],
                loading: false
            }
        },
        computed: {},
        watch: {
            params: {
                handler(params) {
                    const { customerId } = params || {}
                    if (!( customerId)) return

                    this.customerId = customerId

                    // 初始化
                    this.init()
                },
                immediate: true
            }
        },
        mounted() {
        },
        methods: {
            /**
             * 初始化
             */
            async init() {
                // 获取 详情
                await this.getRecord()
            },
            handleRefreshHead() {
                this.$emit('refresh-head')
            },
            /**
             * 获取 详情
             */
            async getRecord() {
                try {
                    this.loading = true
                    const {
                        customerId
                    } = this
                    const result = await getCustomerSelectOneCustomerService({
                        customerId
                    })

                    this.initRecordData(result)
                } catch (e) {
                    this.$message.error(e.message)
                    this.$captureException(e)
                } finally {
                    this.loading = false
                }
            },
            /**
             * 详情-初始化数据
             */
            initRecordData(result) {
                const { userVO } = result
                const {
                    userName,
                    id,
                    mobile,
                    createDateTime,
                    name,
                    userExtends
                } = userVO || {}
                const {
                    alias,
                    sex,
                    birthday,
                    email,
                    provinceName,
                    cityName,
                    areaName,
                    address
                } = userExtends || {}

                this.baseInfo = [
                    {
                        key: '登录账号',
                        value: userName
                    },
                    {
                        key: '用户ID',
                        value: id
                    },
                    {
                        key: '手机号码',
                        value: mobile
                    },
                    {
                        key: '注册时间',
                        value: createDateTime
                    },
                    {
                        key: '姓名',
                        value: name
                    },
                    {
                        key: '昵称',
                        value: alias
                    },
                    {
                        key: '性别',
                        value: this.getApiEnumLabelFromList(CUSTOMER_BASE_INFO_SEX_LIST, sex)
                    },
                    {
                        key: '生日',
                        value: birthday
                    },
                    {
                        key: '电子邮箱',
                        value: email
                    },
                    {
                        key: '所在省市区',
                        value: `${provinceName || ''}${cityName || ''}${areaName || ''}`
                    },
                    {
                        key: '详细地址',
                        value: address
                    }
                ]
            }
        }
    }
</script>
