import { BASE_URL } from '@/config'

/** 保险产品
 * ————————————————————————————————————————————————————————————————————————————————————————————*/

/**
 * 险种分类-列表
 *
 */
export const GET_INSURANCE_TYPE_LIST = {
    url: `${BASE_URL}/common/home/productDangerPlantedCategory`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 险种-列表
 *
 */
export const GET_INSURANCE_LIST = {
    url: `${BASE_URL}/common/home/dangerPlantedCategory`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 保险公司-列表
 *
 */
export const GET_INSURANCE_COMPANY_LIST = {
    url: `${BASE_URL}/common/home/insuranceEnterpriseSelect`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 产品-列表
 *
 */
export const GET_PRODUCT_LIST = {
    url: `${BASE_URL}/common/product/productListPage`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}


/**
 * 产品-产品id获取
 *
 */
export const GET_CHANNEL_SPUID = {
    url: `${BASE_URL}/product/corporation-channel-spu/spuId`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 产品-单条查询
 *
 */
export const SEARCH_PRODUCT = {
    url: `${BASE_URL}/common/product/selectOne`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 产品-省市区
 *
 */
export const GET_SCOPE = {
    url: `${BASE_URL}/common/product/scope`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 产品-获取规格、投保人
 *
 */
export const GET_PROPERTIES_AND_INSURED = {
    url: `${BASE_URL}/common/product/showProduct`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 产品-获取sku
 *
 */
export const GET_PRODUCT_SKU = {
    url: `${BASE_URL}/common/product/productSku`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 预约咨询
 * @type {{}}
 */
export const CREATE_BOOKING = {
    url: `${BASE_URL}/customer/potential-customer/save`,
    method: 'post',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

// 用户评价-列表
export const GET_USER_EVALUATION_LIST = {
    url: `${BASE_URL}/common/evaluation/select`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * 产品详情-查列表页带参省市区有无配置
 * @type {{}}
 */
export const PRODUCT_REGIONAL_CHECK = {
    url: `${BASE_URL}/common/product/regionalCheck`,
    method: 'get',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}


/**
 * 地区佣金-查列表页带参省市区地区佣金列表
 * @type {{}}
 */
export const GET_PRODUCT_SPU_SCOPE_COMMISSION = {
    url: `${BASE_URL}/product/product-spu-scope/commission`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}


/**
 * 佣金区间-查单个产品的佣金区间
 * @type {{}}
 */
export const GET_PRODUCT_SPU_SCOPE_COMMISSION_RANGE = {
    url: `${BASE_URL}/product/product-spu-scope/commissionRange`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 60000
}
