// 接口地址
export const BASE_URL = process.env.VUE_APP_BASE_URL

// 前台系统域名
export const CLIENT_BASE_URL = process.env.VUE_APP_CLIENT_BASE_URL

// H5系统域名
export const H5_BASE_URL = process.env.VUE_APP_H5_BASE_URL

// 接口版本号
export const API_VERSION = '3.1.4'

// 登录-ip
export const LOGIN_IP = '183.129.233.146'
// 登录-来源编码
export const LOGIN_SOURCE_CODE = 'SCRM'
// 登录-来源值编码
export const LOGIN_SOURCE_VALUE_CODE = 'GBW_FRONT'
// 登录-来源编码值
export const LOGIN_APP_CODE = 'CRM'
// 登录-业务来源明细
export const REGISTER_BUSINESS_DETAILS_CODE = '工保网PC'

// 系统编码
export const APP_CODE = 'CRM'

// 媒体查询枚举
export const MediaQueryEnum = {
    'screen-xs': { maxWidth: 575 },
    'screen-sm': {
        minWidth: 576,
        maxWidth: 767
    },
    'screen-md': {
        minWidth: 768,
        maxWidth: 991
    },
    'screen-lg': {
        minWidth: 992,
        maxWidth: 1199
    },
    'screen-xl': {
        minWidth: 1200,
        maxWidth: 1599
    },
    'screen-xxl': { minWidth: 1600 }
}

// 系统免鉴权白名单
export const WHITE_LIST = ['/login',
    '/registration',
    '/404',
    '/401',
    '/500']

// 默认跳转路径
export const DEFAULT_REDIRECT_PATH = '/'

// 系统token key 名称
export const TOKEN_KEY = 'Admin-Token'

// token 有效期 （天）
export const TOKEN_TIME = 1

// 系统token key 名称
export const CORPORATIONCHANNELID_KEY = 'CorporationChannel-ID'

// token 有效期 （天）
export const CORPORATIONCHANNELID_TIME = 30

// 默认table 分页数量
export const DEFAULT_PAGES_SIZE = 10
// 详情记录列表 分页数量
export const DETAIL_LIST_PAGE_SIZE = 5


// 客户端id
export const CLIENT_ID = 'cms'
// 客户端秘钥
export const CLIENT_SECRET = 'cms_secret'

// 阿里云oss-回调地址
export const OSS_CALLBACK_URL = process.env.VUE_APP_OSS_CALLBACK_URL
// 阿里云oss-秘钥
export const OSS_ACCESS_KEY_SECRET = process.env.VUE_APP_OSS_ACCESS_KEY_SECRET

// 客服电话
export const SERVICE_TEL = '400-800-5100'

// 短信发送间隔
export const SMS_COUNTDOWN_TIME = 60

// 重新登录间隔
export const RELOGIN_COUNTDOWN_TIME = 5

// 云服运行环境type

export const YUNFU_BUILD_TYPE = 'yunfu'

// 云服官网login
export const CNGBYF_URL = '/login'
