<!-- G端平台系统-列表 -->
<template>
    <list-page-layout class="g-platform-container">
        <!-- title -->
        <common-title title="G端平台系统" />
        <container-panel>
            <!-- 筛选 -->
            <template #screen>
                <search-panel
                    slot="header"
                    @search="handleSearch"
                    @refresh="handleRefresh"
                />
            </template>

            <!-- 表格 start -->
            <template #content>
                <a-table
                    class="g-platform-container__table"
                    size="middle"
                    :columns="columns"
                    :data-source="data"
                    :pagination="pagination"
                    :loading="loading"
                    row-key="id"
                    @change="handleTableChange"
                >
                    <!-- 操作 -->
                    <template slot="action" slot-scope="action">
                        <a-button v-auth="G_PLATFORM_ENTRY_BUTTON" type="primary" @click="handleClick(action)">
                            <a-icon type="right-circle" theme="twoTone" />进入系统
                        </a-button>
                        <custom-button
                            v-auth="G_PLATFORM_COPY_BUTTON"
                            type="default"
                            @click="handleCopy(action)"
                            text="复制链接"
                        >
                            <template #expansion>
                                <a-icon type="copy" />
                            </template>
                        </custom-button>
                    </template>
                </a-table>
            </template>
        </container-panel>
    </list-page-layout>
</template>

<script>
// 组件
import {
    Table as ATable,
    Icon as AIcon
} from 'ant-design-vue'
import ListPageLayout from '@layout/ListPageLayout'
import CommonTitle from '@components/CommonTitle'
import ContainerPanel from '@components/ContainerPanel'
import CustomButton from '@components/Button'
import SearchPanel from './SearchPanel'

// 常量
import { DEFAULT_PAGES_SIZE } from '@config'

import {
    G_PLATFORM_ENTRY_BUTTON,
    G_PLATFORM_COPY_BUTTON
} from '@constant/authEnum/gPlatform'

// mixins
import tableShowTotal from '@mixins/tableShowTotal'
import enumOperate from '@mixins/enumOperate'
import empty from '@mixins/empty'

// 工具类
import { copyText } from '@/utils/copyText'

// 接口请求
import getGPlatformListService from '@service/gPlatform/getGPlatformListService'

export default {
    name: 'GPlatformPageList',
    components: {
        ATable,
        ListPageLayout,
        CommonTitle,
        ContainerPanel,
        SearchPanel,
        AIcon,
        CustomButton
    },
    mixins: [tableShowTotal, enumOperate, empty],
    data() {
        const columns = [
            {
                title: '系统名称',
                dataIndex: 'externalSystemName',
                key: 'externalSystemName',
                width: 360,
                customRender: text => this.empty(text)
            },
            {
                title: '平台名称',
                dataIndex: 'externalPlatformName',
                key: 'externalPlatformName',
                customRender: text => this.empty(text)
            },
            {
                title: '地区',
                dataIndex: 'provinceName',
                key: 'provinceName',
                customRender: (text, record) => {
                    const {
                        provinceName,
                        cityName,
                        areaName
                    } = record

                    const result = this.empty(`${provinceName ? provinceName : ''}${cityName ? cityName : ''}${areaName ? areaName : ''}`)
                    return result
                }
            },
            {
                title: '操作',
                key: 'action',
                width: 255,
                align: 'left',
                scopedSlots: { customRender: 'action' }
            }
        ]
        return {
            G_PLATFORM_ENTRY_BUTTON,
            G_PLATFORM_COPY_BUTTON,
            columns,
            // 查询条件
            params: {},
            // 数据
            data: [],
            // 分页数量
            limit: DEFAULT_PAGES_SIZE,
            // 总记录数量
            total: 0,
            // 当前索引
            current: 1,
            // 加载状态
            loading: false
        }
    },
    computed: {
        pagination() {
            const {
                total,
                limit,
                current,
                tableShowTotal
            } = this
            return {
                showTotal: (total, range) => tableShowTotal(total, range),
                current,
                total,
                pageSize: limit,
                showSizeChanger: true,
                showQuickJumper: true
            }
        },
        userInfo() {
            return this.$store.getters.userInfo
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        /**
         * 获取 列表
         */
        async getList() {
            try {
                this.loading = true
                const {
                    params,
                    current,
                    limit,
                    userInfo
                } = this
                const { id } = userInfo || {}
                const { total, records } = await getGPlatformListService({
                    current,
                    size: limit,
                    collationFields: 'sorting',
                    state: '启用',
                    authUserId: id,
                    ...params
                })
                this.total = total || 0
                this.data = records || []
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.loading = false
            }
        },
        /**
         * 进入系统
         */
        handleClick(action) {
            const { linkAddress } = action
            window.open(linkAddress)
        },
        /**
         * 复制链接
         */
        handleCopy(action) {
            const { linkAddress } = action
            copyText(linkAddress)
            this.$message.success('复制成功！')
        },
        /**
         * 响应 table change 事件
         * @param pagination
         */
        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.current = current
            this.limit = pageSize
            this.getList()
        },
        /**
         * 搜索
         */
        handleSearch(params) {
            this.current = 1
            this.params = params
            this.getList()
        },
        /**
         * 刷新
         */
        handleRefresh(params) {
            this.handleSearch(params)
        }
    }
}
</script>

<style lang='scss'>
    @import '@assets/styles/varibles.scss';

    .g-platform-container {
        &__table {
            thead {
                th {
                    padding: 12px 8px !important;
                }
            }
            tbody {
                tr {
                    button {
                        width: 110px;
                        margin-right: 8px;
                        padding-left: 0;
                    }
                }
            }
        }
    }
</style>

