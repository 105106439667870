<!-- 线索记录Tab -->
<template>
    <div>
        <pro-table
            ref="tableRef"
            row-key="id"
            :scroll="{ x: '100%' }"
            :columns="columns"
            :request="getList"
        >
            <!-- 咨询内容 -->
            <template #listTitleName="{text, record}">
                {{ text }}
                <a-tag v-if="record.agentUserTypeLabel" class="lead-record-tab-panel__tags">
                    <span>
                        {{ record.agentUserTypeLabel }}
                    </span>
                </a-tag>
            </template>
            <template #action="{record}">
                <a-button
                    type="link"
                    @click="handleDetail(record)"
                >
                    查看
                </a-button>
            </template>
        </pro-table>
    </div>
</template>
<script>
import ProTable from '@components/ProTable'
import getPotentialCustomerSelectListPageService from '@service/customer/getPotentialCustomerSelectListPageService'
import columns from './columns'
import { formatAddress } from '@utils/address'

export default {
    name: 'LeadRecord',
    components: { ProTable },
    data() {
        return { columns }
    },
    computed: {
        customerId() {
            return this.$route.query.id
        }
    },
    methods: {
        // 获取列表数据
        async getList(params) {
            const { customerId } = this
            const { pageSize, current, submitTime = [], area = [], ...otherParams } = params
            const { total, records } = await getPotentialCustomerSelectListPageService({
                customerId,
                current,
                size: pageSize,
                startTime: this.$formatDateTime(submitTime[0]) || undefined,
                endTime: this.$formatDateTime(submitTime[1]) || undefined,
                provinceCode: area[0],
                cityCode: area[1],
                areaCode: area[2],
                ...otherParams
            })
            const data = []
            for (let item of records) {
                const area = `${await formatAddress([item.provinceCode, item.cityCode, item.areaCode])}`
                data.push({
                    ...item,
                    area
                })
            }
            return {
                data,
                total
            }
        },
        // 打开详情抽屉
        handleDetail(record) {
            this.toggleDetail({
                key: 'LeadRecordDetail',
                toggle: true,
                detailId: record.id
            })
        },
        // 用于外部刷新
        refresh() {
            this.$refs.tableRef?.handleSearch?.()
        }
    },
    inject: { toggleDetail: { default: () => {} } }
}
</script>

<style lang="scss">
    @import "@assets/styles/varibles";

    .lead-record-tab-panel {
        &__tags {
            padding: 1px 8px;
            font-size: $font-sizeTwelve;
            color: #1079c5;
            word-break: keep-all;
            background-color: #f2fbff;
            border: none;
            border-radius: 2px;
        }
    }
</style>
