import { BASE_URL } from '@/config'

/** G端保单分析
 * ————————————————————————————————————————————————————————————————————————————————————————————*/

/**
 * G端保单分析-列表
 *
 */
export const GET_INSURANCE_POLICY_ANALYSIS = {
    url: `${BASE_URL}/insurance/statistic/tog/policyAnalysis`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}

/**
 * G端保单分析-获取平台系统
 *
 */
export const GET_PLATFORM_SYSTEM_SELECT_LIST = {
    url: `${BASE_URL}/user/transformation-external-platform-system/selectList`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    timeout: 30000
}
