<!-- 保单管理-列表-筛选 -->
<template>
    <search-panel
        ref="policySearchPanel"
        :model="form"
        layout="vertical"
        @search="handleSearch"
        @refresh="handleRefresh">
        <form-col label="签单日期" prop="signingDate">
            <template :slot="formColDefaultSlot">
                <a-select v-model="signingDate" placeholder="请选择">
                    <template v-for="item in signingDateList">
                        <a-select-option :key="item.value" :value="item.value">{{ item.label }}</a-select-option>
                    </template>
                </a-select>
            </template>
        </form-col>

        <form-col
            v-if="signingDate===SIGNING_DATE_THIS_CUSTOM"
            :span="12"
            label="请选择"
            prop="signingDateSelect"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
        >
            <template :slot="formColDefaultSlot">
                <a-range-picker
                    v-model="signingDateSelect"
                    :mode="signingDateSelectMode"
                    :show-time="showTimeOptions"
                />
            </template>
        </form-col>
        <form-col label="保单类型" prop="type">
            <template :slot="formColDefaultSlot">
                <a-select v-model="form.type" placeholder="请选择保单类型">
                    <template v-for="item in policyTypeList">
                        <a-select-option :key="item.value" :value="item.value">{{ item.label }}</a-select-option>
                    </template>
                </a-select>
            </template>
        </form-col>
        <form-col label="保险起期" prop="insuranceStartDate">
            <template :slot="formColDefaultSlot">
                <a-select v-model="insuranceStartDate" placeholder="请选择">
                    <template v-for="item in insuranceStartDateList">
                        <a-select-option :key="item.value" :value="item.value">{{ item.label }}</a-select-option>
                    </template>
                </a-select>
            </template>
        </form-col>
        <form-col
            v-if="insuranceStartDate===SIGNING_DATE_THIS_CUSTOM"
            :span="12"
            label="请选择"
            prop="InsuranceDateSelect"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
        >
            <template :slot="formColDefaultSlot">
                <a-range-picker
                    v-model="insuranceStartDateSelect"
                    :mode="insuranceStartDateSelectMode"
                    :show-time="showTimeOptions"
                />
            </template>
        </form-col>
        <form-col label="保险止期" prop="insuranceDate">
            <template :slot="formColDefaultSlot">
                <a-select v-model="insuranceDate" placeholder="请选择保险止期">
                    <template v-for="item in insuranceDateList">
                        <a-select-option :key="item.value" :value="item.value">{{ item.label }}</a-select-option>
                    </template>
                </a-select>
            </template>
        </form-col>
        <form-col
            v-if="insuranceDate===SIGNING_DATE_THIS_CUSTOM"
            :span="12"
            label="请选择"
            prop="InsuranceDateSelect"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
        >
            <template :slot="formColDefaultSlot">
                <a-range-picker
                    v-model="insuranceDateSelect"
                    :mode="insuranceDateSelectMode"
                    :show-time="showTimeOptions"
                />
            </template>
        </form-col>
        <form-col label="成交经纪人" prop="name">
            <template :slot="formColDefaultSlot">
                <a-select
                    v-model.trim="personalName"
                    show-search
                    placeholder="请输入关键字选择成员"
                    option-filter-prop="children"
                    :filter-option="false"
                    :allow-clear="true"
                    :show-arrow="false"
                    @change="handleNameChangePower($event,'personalLoading','personalSelectList','personalName','agentId')"
                    @search="getNameSelectPower($event,'personalLoading','personalSelectList','personalName','agentId')"
                >
                    <a-spin v-if="personalLoading" slot="notFoundContent" size="small" type="loading" />
                    <a-select-option v-for="item in personalSelectList" :key="item.id" :value="`${item.userName} （${item.userId}）`">
                        {{ item.userName || '-' }} （{{ item.userId }}）
                    </a-select-option>
                </a-select>
            </template>
        </form-col>
        <form-col label="投保用户" prop="submitMobile">
            <template :slot="formColDefaultSlot">
                <a-input v-model.trim="form.submitMobile" placeholder="请输入手机号码" />
            </template>
        </form-col>
        <template :slot="slotValue">
            <form-col label="平台系统" prop="name">
                <template :slot="formColDefaultSlot">
                    <a-select
                        v-model.trim="externalSystemName"
                        show-search
                        placeholder="请输入"
                        option-filter-prop="children"
                        :filter-option="false"
                        :allow-clear="true"
                        :show-arrow="false"
                        @change="handlePlatformNameChange($event,'platformSystemLoading','platformSystemSelectList','externalSystemName','externalSystemCode')"
                        @search="getPlatformNameSelect($event,'platformSystemLoading','platformSystemSelectList','externalSystemName','externalSystemCode')"
                    >
                        <a-spin v-if="platformSystemLoading" slot="notFoundContent" size="small" type="loading" />
                        <a-select-option v-for="item in platformSystemSelectList" :key="item.id" :value="`${item.externalSystemName}`">
                            {{ item.externalSystemName }}
                        </a-select-option>
                    </a-select>
                </template>
            </form-col>
            <form-col label="保单号" prop="policyNo">
                <template :slot="formColDefaultSlot">
                    <a-input v-model.trim="form.policyNo" placeholder="请输入投保单号" />
                </template>
            </form-col>
        </template>
        <template :slot="searchExpansionSlot">
            <form-col label="保单状态" prop="state">
                <template :slot="formColDefaultSlot">
                    <a-select v-model="form.state" placeholder="请选择保单状态">
                        <a-select-option value>全部</a-select-option>
                        <template v-for="item in policyStatusList">
                            <a-select-option :key="item.value" :value="item.label">{{ item.label }}</a-select-option>
                        </template>
                    </a-select>
                </template>
            </form-col>
            <form-col label="险种" prop="dangerPlantedId">
                <template :slot="formColDefaultSlot">
                    <a-select v-model="form.dangerPlantedId" placeholder="请选择险种">
                        <a-select-option value>全部</a-select-option>
                        <template v-for="item in insureList">
                            <a-select-option :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
                        </template>
                    </a-select>
                </template>
            </form-col>

            <form-col label="保险公司" prop="insuranceEnterpriseId">
                <template :slot="formColDefaultSlot">
                    <a-select v-model="form.insuranceEnterpriseId" placeholder="请选择保险公司">
                        <a-select-option value>全部</a-select-option>
                        <template v-for="item in insureCompanyList">
                            <a-select-option :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
                        </template>
                    </a-select>
                </template>
            </form-col>
            <form-col label="操作经纪人" prop="name">
                <template :slot="formColDefaultSlot">
                    <a-select
                        v-model.trim="operatePersonalName"
                        show-search
                        placeholder="请输入关键字选择成员"
                        option-filter-prop="children"
                        :filter-option="false"
                        :allow-clear="true"
                        :show-arrow="false"
                        @change="handleNameChangePower($event,'operatePersonalLoading','operatePersonalSelectList','operatePersonalName','operationAgentId')"
                        @search="getNameSelectPower($event,'operatePersonalLoading','operatePersonalSelectList','operatePersonalName','operationAgentId')"
                    >
                        <a-spin v-if="operatePersonalLoading" slot="notFoundContent" size="small" type="loading" />
                        <a-select-option v-for="item in operatePersonalSelectList" :key="item.id" :value="`${item.userName} （${item.userId}）`">
                            {{ item.userName || '-' }} （{{ item.userId }}）
                        </a-select-option>
                    </a-select>
                </template>
            </form-col>

            <form-col label="项目名称" prop="projectNameLike">
                <template :slot="formColDefaultSlot">
                    <a-input v-model.trim="form.projectNameLike" placeholder="请输入项目名称" />
                </template>
            </form-col>
            <form-col label="产品名称" prop="spuNameLike">
                <template :slot="formColDefaultSlot">
                    <a-input v-model.trim="form.spuNameLike" placeholder="请输入产品名称" />
                </template>
            </form-col>

            <form-col label="被保人" prop="insuredEnterpriseNameLike">
                <template :slot="formColDefaultSlot">
                    <a-input v-model.trim="form.insuredEnterpriseNameLike" placeholder="请输入被保人" />
                </template>
            </form-col>
            <form-col label="投保人" prop="castEntepriseNameLike">
                <template :slot="formColDefaultSlot">
                    <a-input v-model.trim="form.castEntepriseNameLike" placeholder="请输入投保人" />
                </template>
            </form-col>
        </template>
    </search-panel>
</template>

<script>
import debounce from 'lodash/debounce'
// 组件
import {
    Select as ASelect,
    DatePicker as ADatePicker,
    Input as AInput
} from 'ant-design-vue'
import SearchPanel from '@components/BaseSearchPanel'
import FormCol from '@components/FormCol'
import searchCommon from '@mixins/searchCommon'

// 全局数据
import {
    SIGNING_DATE_TYPE_ENUM_LIST,
    INSURANCE_DATE_TYPE_ENUM_LIST,
    INSURANCE_DATE_ALL,
    SIGNING_DATE_THIS_MONTH,
    SIGNING_DATE_THIS_CUSTOM,
    SIGNING_DATE_TODAY,
    SIGNING_DATE_THIS_YEAR,
    POLICY_ORDER_STATUS_ENUM_LIST,
    POLICY_POLICY_TYPE_THREE,
    POLICY_TYPE_ENUM_LIST
} from '@constant/enum'

// 工具类
import { filterParams } from '@utils/search.js'
import moment from 'moment'

// 接口请求
import getTeamUserSelectListService from '@/service/customer/getTeamUserSelectListService'
import getInsuranceTypeListService from '@service/workSpace/getInsuranceTypeListService'
import getInsureCompanyListService from '@service/insureCompany/getInsureCompanyListService'

import getPlatformSystemSelectListService from '@service/policyAnalysisG/getPlatformSystemSelectListService'

import getTime from '@mixins/getTime'

export default {
    name: 'OrderManageSearchPanel',
    components: {
        SearchPanel,
        FormCol,
        ASelect,
        AInput,
        ASelectOption: ASelect.Option,
        ARangePicker: ADatePicker.RangePicker
    },
    mixins: [getTime, searchCommon],
    props: {
        query: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        this.getNameSelect = debounce(this.getNameSelect, 500)
        this.getPlatformNameSelect = debounce(this.getPlatformNameSelect, 500)
        return {
            SIGNING_DATE_THIS_CUSTOM,
            submitTimeMode: ['date', 'date'],
            form: {
                // 签单日期-开始
                beginSingleTime: '',
                // 签单日期-结束
                endSingleTime: '',
                // 保单类型
                type: '',
                // 保险起期-开始
                beginStartInsuranceTime: '',
                // 保险起期-结束
                stopStartInsuranceTime: '',
                // 保险止期-开始
                beginEndInsuranceTime: '',
                // 保险止期-结束
                stopEndInsuranceTime: '',
                // 投保账号
                submitMobile: '',
                // 成交经纪人
                agentId: '',
                // 操作经纪人
                operationAgentId: '',
                // 平台系统
                externalSystemCode: '',
                // 投保单号
                policyNo: '',
                // 订单状态
                state: undefined,
                // 险种
                dangerPlantedId: undefined,
                // 保险公司
                insuranceEnterpriseId: undefined,
                // 项目名称
                projectNameLike: '',
                // 产品名称
                spuNameLike: '',
                // 被保人
                insuredEnterpriseNameLike: '',
                // 投保人
                castEntepriseNameLike: ''
            },
            // 提交时间
            signingDate: SIGNING_DATE_THIS_MONTH,
            // 提交时间自定义
            signingDateSelect: [],
            // 提交时间
            signingDateSelectMode: ['date', 'date'],
            showTimeOptions: {
                defaultValue: [
                    moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')
                ]
            },
            signingDateList: SIGNING_DATE_TYPE_ENUM_LIST,
            // 保险起期
            insuranceStartDate: INSURANCE_DATE_ALL,
            insuranceStartDateSelect: [],
            insuranceStartDateSelectMode: ['date', 'date'],
            insuranceStartDateList: INSURANCE_DATE_TYPE_ENUM_LIST,
            // 保险止期
            insuranceDate: INSURANCE_DATE_ALL,
            insuranceDateSelect: [],
            insuranceDateSelectMode: ['date', 'date'],
            insuranceDateList: INSURANCE_DATE_TYPE_ENUM_LIST,

            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
            // 成交经纪人姓名搜索数据
            personalLoading: false,
            personalSelectList: [],
            personalName: undefined,
            // 操作经纪人姓名搜索数据
            operatePersonalLoading: false,
            operatePersonalSelectList: [],
            operatePersonalName: undefined,
            // 险种list
            insureList: [],
            // 保险公司list
            insureCompanyList: [],
            // 保单状态list
            policyStatusList: POLICY_ORDER_STATUS_ENUM_LIST,
            // 保单类型List
            policyTypeList: POLICY_TYPE_ENUM_LIST,
            // 平台系统数据
            platformSystemLoading: false,
            platformSystemSelectList: [],
            externalSystemName: undefined,
            // 筛选-插槽
            formColDefaultSlot: 'formElements',
            searchExpansionSlot: 'expansion',
            searchSpecialSlot: 'special'
        }
    },
    computed: {
        // 控制是否展开收起
        slotValue() {
            const { signingDate, searchExpansionSlot, searchSpecialSlot } = this
            return signingDate === SIGNING_DATE_THIS_CUSTOM ? searchExpansionSlot : searchSpecialSlot
        },
        // 用户信息缓存
        userInfo() {
            return this.$store.getters.userInfo
        }
    },
    watch: {
        signingDate: {
            handler(val) {
                if (val !== SIGNING_DATE_THIS_CUSTOM) {
                    this.signingDateSelect = []
                    this.getFormSigningTime()
                    this.handleSearch()
                } else {
                    this.getFormSigningTime()
                }
            }
            // immediate: true
        },
        signingDateSelect: {
            handler(val) {
                this.signingDateSelect = val
                this.getFormSigningTime()
            },
            immediate: true
        },
        insuranceDate: {
            handler(val) {
                if (val !== SIGNING_DATE_THIS_CUSTOM ) {
                    this.insuranceDateSelect = []
                    this.getFormInsuranceTime()
                    this.handleSearch()
                } else {
                    this.getFormInsuranceTime()
                }
            }
            // immediate: true
        },
        insuranceStartDate: {
            handler(val) {
                if (val !== SIGNING_DATE_THIS_CUSTOM ) {
                    this.insuranceStartDateSelect = []
                    this.getFormInsuranceStartTime()
                    this.handleSearch()
                } else {
                    this.getFormInsuranceStartTime()
                }
            }
            // immediate: true
        },
        insuranceDateSelect: {
            handler(val) {
                this.insuranceDateSelect = val
                this.getFormInsuranceTime()
            },
            immediate: true
        },
        insuranceStartDateSelect: {
            handler(val) {
                this.insuranceStartDateSelect = val
                this.getFormInsuranceStartTime()
            },
            immediate: true
        },
        query: {
            handler(val) {
                console.log(val, ' val=====')
                if (Object.keys(val).length > 0 && val.isPlatFormG) {
                    // 从G端保单分析跳转过来携带参数并清空未携带的参数
                    this.$refs.policySearchPanel && this.$refs.policySearchPanel.handleClearForm()
                    this.resetData()
                    const { externalSystemName, signingDate, dangerPlantedId,
                        externalSystemCode,
                        insuranceEnterpriseId,
                        singleDateTimeEnd: endSingleTime,
                        singleDateTimeStart: beginSingleTime } = val
                    if (beginSingleTime) {
                        this.form.beginSingleTime = beginSingleTime
                    }
                    if (endSingleTime) {
                        this.form.endSingleTime = endSingleTime
                    }
                    this.form.dangerPlantedId = dangerPlantedId || undefined
                    this.form.externalSystemCode = externalSystemCode || undefined
                    this.form.insuranceEnterpriseId = insuranceEnterpriseId || undefined
                    if (+signingDate === SIGNING_DATE_THIS_CUSTOM) {
                        this.signingDateSelect = [beginSingleTime, endSingleTime]
                    }
                    this.externalSystemName = externalSystemName || undefined
                    this.signingDate = +signingDate || SIGNING_DATE_THIS_MONTH
                    this.form.type = POLICY_POLICY_TYPE_THREE || undefined
                    this.handleSearch()
                }
            },
            immediate: true
        }
    },
    mounted() {
        this.init()
        this.getInsureList()
        this.getInsureCompanyList()
        this.getPlatformNameSelect('', 'platformSystemLoading', 'platformSystemSelectList')
        this.getNameSelectPower()
    },
    methods: {
        moment,
        init() {
            const { form } = this
            this.$emit('get-init', filterParams(form))
        },
        /**
         * 获取险种
         */
        async getInsureList() {
            try {
                const data = await getInsuranceTypeListService()
                this.insureList = data || []
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {}
        },
        /**
         * 获取保险公司
         */
        async getInsureCompanyList() {
            try {
                const data = await getInsureCompanyListService()
                this.insureCompanyList = data || []
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {}
        },
        /**
         * 获取平台系统数据
         */
        async getPlatformNameSelect(externalSystemNameQuery, loading, list, name, code) {
            const { userInfo } = this
            const { id } = userInfo || {}
            // if (!externalSystemNameQuery) {
            //     this[list] = []
            //     this[loading] = false
            //     return false
            // }
            try {
                this[loading] = true
                const data = await getPlatformSystemSelectListService({
                    authUserId: id,
                    externalSystemNameQuery
                })
                this[list] = data || []
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this[loading] = false
            }
        },
        handlePlatformNameChange(externalSystemNameQuery, loading, list, name, id) {
            if (!externalSystemNameQuery) {
                this.getPlatformNameSelect(externalSystemNameQuery, loading, list, name, id)
            } else {
                this.getPlatformNameSelect(externalSystemNameQuery, loading, list, name, id)
            }
            let result = this[list].find(item => item.externalSystemName === externalSystemNameQuery)
            this.form[id] = result && result.externalSystemCode || ''
        },
        /**
         * 获取人员名称数据
         */
        async getNameSelect(userNameQuery, loading, list, name, id) {
            if (!userNameQuery) {
                this[list] = []
                this[loading] = false
                return false
            }
            try {
                this[loading] = true
                const data = await getTeamUserSelectListService({
                    backMapQueryResult: false,
                    userNameQuery
                })
                this[list] = data || []
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this[loading] = false
            }
        },
        handleNameChange(value, loading, list, name, id) {
            if (!value) {
                this[list] = []
            } else {
                let splitValue = value && value.split(' ')[0]
                this.getNameSelect(splitValue, loading, list, name, id)
            }
            this.getNameId(value, loading, list, name, id)
        },
        /**
         * 根据name获取id
         */
        getNameId(value, loading, list, name, id) {
            let splitValue = value && value.split(' ')[0]
            let splitValueId = value && value.split(' ')[1]
            let result = this[list].find(item => item.userName === splitValue && `（${item.userId}）` === splitValueId)
            this.form[id] = result && result.userId || ''
        },
        /**
         * 响应搜索事件
         */
        handleSearch() {
            const { form, insuranceDate, insuranceStartDate } = this
            console.log(form, 'from-----')
            const { beginSingleTime, endSingleTime, beginEndInsuranceTime, stopEndInsuranceTime, beginStartInsuranceTime, stopStartInsuranceTime } = form
            if (!beginSingleTime || !endSingleTime) {
                this.$message.error('请选择签单日期自定义起止时间')
                return false
            }
            if (insuranceDate !== INSURANCE_DATE_ALL) {
                if (!beginEndInsuranceTime || !stopEndInsuranceTime) {
                    this.$message.error('请选择保险止期自定义起止时间')
                    return false
                }
            }
            if (insuranceStartDate !== INSURANCE_DATE_ALL) {
                if (!beginStartInsuranceTime || !stopStartInsuranceTime) {
                    this.$message.error('请选择保险止期自定义起止时间')
                    return false
                }
            }
            this.$emit('search', filterParams(form))
        },
        /**
         * 响应刷新事件
         */
        handleRefresh() {
            this.resetData()
            const { form } = this
            this.$emit('refresh', filterParams(form))
        },
        /**
         *
         * 刷新置空数据
         */
        resetData() {
            this.personalName = undefined
            this.form.operationAgentId = ''
            this.form.agentId = ''
            this.form.dangerPlantedId = undefined
            this.signingDate = SIGNING_DATE_THIS_MONTH
            this.personalSelectList = []
            this.signingDateSelect = []
            this.getFormSigningTime()
            this.form.insuranceEnterpriseId = undefined
            this.operatePersonalName = undefined
            this.insuranceDate = INSURANCE_DATE_ALL
            this.insuranceDateSelect = []
            this.getFormInsuranceTime()
            this.insuranceStartDate = INSURANCE_DATE_ALL
            this.insuranceStartDateSelect = []
            this.getFormInsuranceStartTime()
            this.externalSystemName = undefined
            this.form.externalSystemCode = ''
            this.form.beginStartInsuranceTime = ''
            this.form.stopStartInsuranceTime = ''
            this.getPlatformNameSelect('', 'platformSystemLoading', 'platformSystemSelectList')
            this.getNameSelectPower()

        },
        getFormSigningTime() {
            const { signingDate, signingDateSelect } = this
            let { startDate, endDate } = this.getTime(signingDate, signingDateSelect, 'startDate', 'endDate') || {}
            this.form.beginSingleTime = startDate
            this.form.endSingleTime = endDate
        },
        getFormInsuranceStartTime() {
            const { insuranceStartDate, insuranceStartDateSelect } = this
            let { startDate, endDate } = this.getTime(insuranceStartDate, insuranceStartDateSelect, 'startDate', 'endDate') || {}
            this.form.beginStartInsuranceTime = startDate
            this.form.stopStartInsuranceTime = endDate
        },
        getFormInsuranceTime() {
            const { insuranceDate, insuranceDateSelect } = this
            let { startDate, endDate } = this.getTime(insuranceDate, insuranceDateSelect, 'startDate', 'endDate') || {}
            this.form.beginEndInsuranceTime = startDate
            this.form.stopEndInsuranceTime = endDate
        }
    }
}
</script>

<style lang="scss">
</style>
