<!-- 客户管理-客户跟进-列表-筛选 -->
<template>
    <search-panel ref="tableRef"
                  :model="form"
                  layout="vertical"
                  @search="handleSearch"
                  @refresh="handleRefresh">
        <form-col label="手机号码" prop="mobileQuery">
            <template :slot="formColDefaultSlot">
                <a-input v-model.trim="form.mobileQuery" placeholder="请输入" />
            </template>
        </form-col>
        <form-col label="姓名" prop="nameQuery">
            <template :slot="formColDefaultSlot">
                <a-input v-model.trim="form.nameQuery" placeholder="请输入" />
            </template>
        </form-col>
        <form-col label="企业名称" prop="enterpriseNameQuery">
            <template :slot="formColDefaultSlot">
                <a-input v-model.trim="form.enterpriseNameQuery" placeholder="请输入" />
            </template>
        </form-col>
        <form-col label="所在地区" prop="code">
            <template :slot="formColDefaultSlot">
                <a-cascader
                    v-model="selectAreaCode"
                    :options="areaData"
                    placeholder="请选择"
                    change-on-select
                    @change="handleChangeArea" />
            </template>
        </form-col>
        <form-col label="经纪人" prop="agentUserIdQuery">
            <template :slot="formColDefaultSlot">
                <a-select
                    v-model.trim="agentName"
                    show-search
                    placeholder="请选择经纪人"
                    option-filter-prop="children"
                    :filter-option="false"
                    :allow-clear="true"
                    :show-arrow="false"
                    @change="handleChange"
                    @search="getAgentList"
                >
                    <a-spin v-if="loading" slot="notFoundContent" size="small" type="loading" />
                    <a-select-option v-for="item in agentSelectList" :key="item.userId" :value="`${item.userName} （${item.userId}）`">
                        {{ item.userName || '-' }}（{{ item.userId }}）
                    </a-select-option>
                </a-select>
            </template>
        </form-col>
        <form-col label="客户意向" prop="intentionQuery">
            <template :slot="formColDefaultSlot">
                <a-select v-model="form.intentionQuery" placeholder="请选择">
                    <a-select-option value>全部</a-select-option>
                    <template v-for="item in CUSTOMER_INTENTION_ENUM_LIST">
                        <a-select-option :key="item.value" :value="item.value">{{ item.label }}</a-select-option>
                    </template>
                </a-select>
            </template>
        </form-col>
        <form-col label="客户阶段" prop="stageQuery">
            <template :slot="formColDefaultSlot">
                <a-select v-model="form.stageQuery" placeholder="请选择">
                    <a-select-option value>全部</a-select-option>
                    <template v-for="item in CUSTOMER_STAGE_TYPE_ENUM_LIST">
                        <a-select-option :key="item.value" :value="item.value">{{ item.label }}</a-select-option>
                    </template>
                </a-select>
            </template>
        </form-col>
        <form-col label="是否注册" prop="registerQuery">
            <template :slot="formColDefaultSlot">
                <a-select v-model="form.registerQuery" placeholder="请选择">
                    <a-select-option value>全部</a-select-option>
                    <template v-for="item in CUSTOMER_REGISTER_ENUM_LIST">
                        <a-select-option :key="item.value" :value="item.value">{{ item.label }}</a-select-option>
                    </template>
                </a-select>
            </template>
        </form-col>
        <template :slot="searchExpansionSlot">
            <form-col
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
                :span="12"
                label="更新时间"
                prop="updateTime">
                <template :slot="formColDefaultSlot">
                    <a-range-picker
                        v-model="updateTime"
                        :mode="updateTimeMode"
                        :show-time="showTimeOptions"
                    />
                </template>
            </form-col>
        </template>
        <template :slot="searchExpansionSlot">
            <form-col
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
                :span="12"
                label="分配时间"
                prop="allocateTime">
                <template :slot="formColDefaultSlot">
                    <a-range-picker
                        v-model="allocateTime"
                        :mode="allocateTimeMode"
                        :show-time="showTimeOptions"
                    />
                </template>
            </form-col>
        </template>
    </search-panel>
</template>

<script>
import debounce from 'lodash/debounce'
// 组件
import {
    Input as AInput,
    Select as ASelect,
    DatePicker as ADatePicker
} from 'ant-design-vue'
import SearchPanel from '@components/BaseSearchPanel'
import FormCol from '@components/FormCol'

// mixins
import selectArea from '@mixins/selectArea'

// 枚举
import { CUSTOMER_INTENTION_ENUM_LIST, CUSTOMER_REGISTER_ENUM_LIST, CUSTOMER_STAGE_TYPE_ENUM_LIST } from '@constant/enum'

// 工具类
import { filterParams } from '@utils/search.js'
import moment from 'moment'

// 接口
// import getTeamUserSelectListService from '@/service/customer/getTeamUserSelectListService'
import getTeamAuthBrokerListService from '@/service/workSpace/policyMange/getTeamAuthBrokerListService'

export default {
    name: 'CustomerSearchPanel',
    components: {
        SearchPanel,
        AInput,
        ASelect,
        ASelectOption: ASelect.Option,
        FormCol,
        ARangePicker: ADatePicker.RangePicker
    },
    mixins: [selectArea],
    props: {
        echoData: {
            type: Object,
            default() {
                return {}
            }
        },
        type: {
            type: String,
            default() {
                return ''
            }
        }
    },
    data() {
        this.getAgentList = debounce(this.getAgentList, 500)
        return {
            CUSTOMER_INTENTION_ENUM_LIST,
            CUSTOMER_REGISTER_ENUM_LIST,
            CUSTOMER_STAGE_TYPE_ENUM_LIST,
            form: {
                // 手机号码
                mobileQuery: '',
                // 姓名
                nameQuery: '',
                // 企业名称
                enterpriseNameQuery: '',
                // 省
                provinceCodeQuery: '',
                // 市
                cityCodeQuery: '',
                // 区
                areaCodeQuery: '',
                // 经纪人
                agentUserIdQuery: '',
                // 客户意向
                intentionQuery: '',
                // 客户阶段
                stageQuery: '',
                // 是否注册
                registerQuery: '',
                // 更新开始时间
                modifyStartDateTime: '',
                // 更新结束时间
                modifyEndDateTime: '',
                // 开始分配时间
                startAllocateDateTime: '',
                // 结束分配时间
                endAllocateDateTime: ''
            },
            // 所选地区
            selectAreaCode: [],
            // 更新时间
            updateTime: [],
            updateTimeMode: ['date', 'date'],
            // 分配时间
            allocateTime: [],
            allocateTimeMode: ['date', 'date'],
            showTimeOptions: {
                defaultValue: [
                    moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')
                ]
            },
            // 经纪人数据
            agentSelectList: [],
            agentName: undefined,
            loading: true,
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
            // 筛选-插槽
            formColDefaultSlot: 'formElements',
            searchExpansionSlot: 'expansion'
        }
    },
    computed: {
        // 用户信息缓存
        userInfo() {
            return this.$store.getters.userInfo
        }
    },
    watch: {
        selectAreaCode: {
            handler(value) {
                const [provinceCode, cityCode, areaCode] = value
                this.form.provinceCodeQuery = provinceCode || ''
                this.form.cityCodeQuery = cityCode || ''
                this.form.areaCodeQuery = areaCode || ''
            },
            immediate: true
        },
        echoData: {
            handler(val) {
                const {
                    mobileQuery,
                    nameQuery,
                    enterpriseNameQuery,
                    provinceCodeQuery,
                    cityCodeQuery,
                    areaCodeQuery,
                    agentUserIdQuery,
                    intentionQuery,
                    stageQuery,
                    registerQuery,
                    modifyStartDateTime,
                    modifyEndDateTime,
                    startAllocateDateTime,
                    endAllocateDateTime
                } = val || {}
                this.form = {
                    mobileQuery,
                    nameQuery,
                    enterpriseNameQuery,
                    provinceCodeQuery,
                    cityCodeQuery,
                    areaCodeQuery,
                    agentUserIdQuery,
                    intentionQuery,
                    stageQuery,
                    registerQuery,
                    modifyStartDateTime,
                    modifyEndDateTime,
                    startAllocateDateTime,
                    endAllocateDateTime
                }
                if ( provinceCodeQuery || cityCodeQuery || areaCodeQuery) {
                    this.selectAreaCode = [provinceCodeQuery, cityCodeQuery, areaCodeQuery]
                } else {
                    this.selectAreaCode = []
                }
                if (modifyStartDateTime && modifyEndDateTime) {
                    this.updateTime = [modifyStartDateTime, modifyEndDateTime]
                } else {
                    this.updateTime = []
                }
                if (startAllocateDateTime && endAllocateDateTime) {
                    this.allocateTime = [startAllocateDateTime, endAllocateDateTime]
                } else {
                    this.allocateTime = []
                }
            },
            immediate: true
        },
        agentSelectList: {
            handler(val) {
                const { form } = this
                const { agentUserIdQuery } = form
                if (val && val.length) {
                    let result = val.find(item => item.userId === agentUserIdQuery) || {}
                    this.agentName = agentUserIdQuery ? `${result.userName} （${result.userId}）` : undefined
                }
            },
            immediate: true
        }
    },
    mounted() {
        this.getAgentList()
    },
    methods: {
        /**
         * 获取经纪人
         */
        async getAgentList(userNameQuery) {
            const { userInfo } = this
            const { id } = userInfo || {}
            const { type } = this
            try {
                this.loading = true
                const data = await getTeamAuthBrokerListService({
                    authUserId: id,
                    teamUserNameQuery: userNameQuery,
                    type
                })
                this.agentSelectList = data || []
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        },
        handleChange(value) {
            if (!value) {
                this.getAgentList()
            } else {
                let splitValue = value && value.split(' ')[0]
                this.getAgentList(splitValue)
            }
            this.getId(value)
        },
        /**
         * 根据name获取id
         */
        getId(value) {
            const { agentSelectList } = this
            let splitValue = value && value.split(' ')[0]
            let splitValueId = value && value.split(' ')[1]
            let result = agentSelectList.find(item => item.userName === splitValue && `（${item.userId}）` === splitValueId)
            this.form.agentUserIdQuery = result && result.userId || ''
        },
        /**
         * 响应搜索事件
         */
        handleSearch() {
            const params = this.formatParams()
            this.$emit('search', filterParams(params))
        },
        /**
         * 响应刷新事件
         */
        handleRefresh() {
            this.agentName = undefined
            this.form.agentUserIdQuery = ''
            this.updateTime = []
            this.allocateTime = []
            this.selectAreaCode = []
            this.form.provinceCodeQuery = ''
            this.form.cityCodeQuery = ''
            this.form.areaCodeQuery = ''
            const params = this.formatParams()
            this.$emit('refresh', filterParams(params))
            this.getAgentList()
        },
        /**
         * 格式化参数
         */
        formatParams() {
            const { form, updateTime, allocateTime } = this
            let [startTime, endTime] = updateTime || []
            startTime = startTime ? `${startTime.format('YYYY-MM-DD HH:mm:ss')}` : ''
            endTime = endTime ? `${endTime.format('YYYY-MM-DD HH:mm:ss')}` : ''
            form.modifyStartDateTime = startTime
            form.modifyEndDateTime = endTime

            let [startAllocateTime, endAllocateTime] = allocateTime || []
            startAllocateTime = startAllocateTime ? `${startAllocateTime.format('YYYY-MM-DD HH:mm:ss')}` : ''
            endAllocateTime = endAllocateTime ? `${endAllocateTime.format('YYYY-MM-DD HH:mm:ss')}` : ''
            form.startAllocateDateTime = startAllocateTime
            form.endAllocateDateTime = endAllocateTime
            return form
        },
        /**
         * 切换tab初始化参数
         */
        resetForm() {
            this.$refs.tableRef.handleRefresh() // 切换tab初始化参数
        }
    }
}
</script>

<style lang="scss">
</style>
