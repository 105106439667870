import {
    Table as ATable,
    Divider as ADivider
} from 'ant-design-vue'
import ListPageLayout from '@layout/ListPageLayout'
import CommonTitle from '@components/CommonTitle'
import ContainerPanel from '@components/ContainerPanel'
import SearchPanel from '@views/Customer/Components/SearchPanel'
import AddCustomerModal from '@views/Customer/Components/AddCustomerModal'
import FollowRecordModal from '@views/Customer/Components/FollowRecordModal'
import { customActionTitle } from '@utils/customRow'

const columns = [
    {
        title: '手机号码',
        dataIndex: 'customerMobile',
        key: 'customerMobile',
        fixed: 'left',
        width: 150
        // align: 'center'
    },
    {
        title: '姓名',
        dataIndex: 'name',
        width: 150,
        // align: 'center',
        customRender: text => text || '-'
    },
    {
        title: '企业名称',
        dataIndex: 'enterpriseName',
        width: 250,
        // align: 'center',
        customRender: text => text || '-'
    },
    {
        title: '所在地区',
        dataIndex: 'area',
        width: 200,
        // align: 'center',
        customRender: (text, record) => {
            const { provinceName, cityName, areaName } = record
            let provinceNameString = provinceName || ''
            let cityNameString = cityName || ''
            let areaNameString = areaName || ''
            if (!provinceNameString && !cityNameString && !areaNameString) {
                return '-'
            } else {
                return `${provinceNameString}${cityNameString}${areaNameString}`
            }
        }
    },
    {
        title: '客户意向',
        dataIndex: 'intention',
        width: 100,
        // align: 'center',
        // scopedSlots: { customRender: 'intention' },
        customRender: text => text || '-'
    },
    // TODO 待联调
    {
        title: '客户阶段',
        dataIndex: 'stage',
        width: 150,
        // align: 'center',
        // scopedSlots: { customRender: 'stage' },
        customRender: text => text || '-'
    },
    {
        title: '成交保费',
        dataIndex: 'premium',
        width: 200,
        // align: 'center',
        customRender: text => text ? (text * 1).toFixed(2) : '0.00'
    },
    {
        title: '经纪人',
        dataIndex: 'agent',
        width: 200,
        // align: 'center',
        customRender: (text, record) => {
            const { agentUserName, agentUserId } = record
            if (!agentUserName && !agentUserId) {
                return '-'
            } else {
                return `${agentUserName || '-'}（${agentUserId || '-'}）`
            }
        }
    },
    {
        title: '客户创建时间',
        dataIndex: 'customerCreateDateTime',
        width: 200,
        // align: 'center',
        customRender: text => text || '-'
    },
    {
        title: '注册时间',
        dataIndex: 'registerDateTime',
        width: 200,
        // align: 'center',
        customRender: text => text || '-'
    },
    {
        title: '更新时间',
        dataIndex: 'customerModifyDateTime',
        width: 200,
        // align: 'center',
        customRender: text => text || '-'
    },
    {
        title: '分配时间',
        dataIndex: 'allocateDateTime',
        width: 200,
        // align: 'center',
        customRender: text => text || '-'
    }
]
const distributionColumns = [
    ...columns, {
        // title: '操作',
        key: 'action',
        fixed: 'right',
        align: 'left',
        // width: 168,
        scopedSlots: { customRender: 'action' },
        customHeaderCell: () => ({
            props: { title: 'title' },
            renderTitle(h) {
                return customActionTitle(h, 30)
            }
        })
    }
]
const proprietaryColumns = [
    ...columns, {
        // title: '操作',
        key: 'action',
        fixed: 'right',
        align: 'left',
        // width: 288,
        scopedSlots: { customRender: 'action' },
        customHeaderCell: () => ({
            props: { title: 'title' },
            renderTitle(h) {
                return customActionTitle(h, 200)
            }
        })

    }
]
const headerColumnTitle = {
    header: {
        cell(h, headerCellProps, children) {
            const { renderTitle } = headerCellProps
            if (typeof renderTitle !== 'function') {
                return <th {...headerCellProps}>{children}</th>
            }

            return (
                <th {...headerCellProps}>
                    <span class="ant-table-header-column">
                        <div>
                            <div class="ant-table-column-title">
                                {renderTitle(h)}
                            </div>
                        </div>
                    </span>
                </th>
            )
        }
    }
}
export default {
    components: {
        ATable,
        ADivider,
        ListPageLayout,
        CommonTitle,
        ContainerPanel,
        SearchPanel,
        AddCustomerModal,
        FollowRecordModal
    },
    data() {
        return {
            columns,
            distributionColumns,
            proprietaryColumns,
            headerColumnTitle
        }
    },
    methods: {}
}
