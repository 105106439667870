<!-- 分享弹窗 -->
<template>
    <custom-modal
        :width="824"
        :mask-closable="false"
        :title="params.productName || ''"
        v-bind="$attrs"
        v-on="$listeners"
    >
        <template>
            <div class="share-content">
                <div class="platform-wrap">
                    <div class="platform">
                        <p class="title">
                            pc端:
                        </p>
                        <p class="url">{{ pcShareUrl }}</p>
                    </div>
                    <a-button  type="primary"
                               class="copy-btn"
                               @click="handleCopy(pcShareUrl)"
                    >
                        <i class="iconfont icon-fuzhi" />
                        <span>复制链接</span>
                    </a-button>
                </div>
                <div class="platform-wrap">
                    <div class="platform">
                        <p class="title">
                            h5端:
                        </p>
                        <p class="url">{{ h5ShareUrl }}</p>
                    </div>
                    <a-button  type="primary"
                               class="copy-btn"
                               @click="handleCopy(h5ShareUrl)"
                    >
                        <i class="iconfont icon-fuzhi" />
                        <span>复制链接</span>
                    </a-button>
                </div>
                <div class="qrcode">
                    <img v-if="QRcodeUrl" width="100" :src="QRcodeUrl">
                    <a-spin v-else spinning />
                    <p>
                        <i class="iconfont icon-tishi" />
                        请用微信扫一扫预览H5移动端
                    </p>
                </div>
            </div>
        </template>
        <template slot="customFooter">
            <a-button
                @click="handleClose"
            >
                关闭
            </a-button>
        </template>
    </custom-modal>
</template>

<script>
import CustomModal from '@/components/CustomModal'

import qs from 'qs'
import { copyText } from '@/utils/copyText'
import getQrcodeLinkAddressImgService from '@/service/getQrcodeLinkAddressImgService'


import { CLIENT_BASE_URL, H5_BASE_URL } from '@/config'

import { INSURE_MODE_INSURE } from '@/constant/enum'

export default {
    name: 'ShareModal',
    components: { CustomModal },
    props: {
        params: { // 分享参数
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            INSURE_MODE_INSURE,
            columns: [],
            QRcodeUrl: ''
        }
    },
    computed: {
        pcShareUrl() {
            const query = {
                agentUserId: this.params.agentUserId,
                id: this.params.id,
                insureType: this.INSURE_MODE_INSURE
            }
            return `${CLIENT_BASE_URL}/product/info?${qs.stringify(query)}`
        },
        h5ShareUrl() {
            const query = {
                agentUserId: this.params.agentUserId,
                id: this.params.id // 产品id
            }
            return `${H5_BASE_URL}/product/detail?${qs.stringify(query)}`
        }
    },
    watch: {
        h5ShareUrl(val) {
            this.getQRcodeUrl(val)
        }
    },
    methods: {
        async getQRcodeUrl(url) {
            this.QRcodeUrl = ''
            const data = await getQrcodeLinkAddressImgService({ qrCodeLinkAddress: url })
            this.QRcodeUrl = data
        },
        handleClose() {
            this.$emit('close')
        },
        handleCopy(url) {
            copyText(url)
            this.$message.success('复制成功！')
        }
    }
}
</script>

<style lang='scss' scoped>
.share-content {
    p {
        margin: 0;
    }

    .platform-wrap {
        display: flex;
        align-items: flex-end;

        &:nth-child(1) {
            margin-bottom: 40px;
        }

        .platform {
            flex: 1;
            word-break: break-all;

            .title {
                margin-bottom: 8px;
                font-size: 16px;
                font-weight: 500;
                line-height: 22px;
                color: #848b91;
            }

            .url {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                color: #848b91;
            }
        }

        .copy-btn {
            width: 110px;
            height: 32px;
            margin-left: 32px;
            border-radius: 4px;

            i {
                margin-right: 8px;
                font-size: 14px;
            }
        }
    }

    .qrcode {
        display: flex;
        align-items: center;
        margin-top: 6px;

        img {
            width: 120px;
            height: 120px;
            margin-left: -5px;
        }

        p {
            margin-left: 20px;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: #323233;
        }

        i {
            font-size: 14px;
            color: #1877f2;
        }
    }
}

</style>
