import { Input as AInput, Radio as ARadio, Table as ATable } from 'ant-design-vue'
import SubmitTimeSection from '@weights/SubmitTimeSection'
import Steward from '@weights/Steward'
import CustomSelect from '@weights/CustomSelect'
import UserGroup from '@weights/UserGroup'
import AddressCascader from '@weights/AddressCascader'
import SearchInput from '@weights/SearchInput'
import CustomDescriptions from '@weights/CustomDescriptions'
import CustomDatePicker from '@weights/CustomDatePicker'
import SelectDataRange from '@weights/SelectDataRange'

// 组件渲染
export default {
    input: {
        component: AInput,
        name: 'AInput',
        placeholder: '请输入',
        input: e => e.target.value, // 预处理回参
        change: e => e.target.value // 预处理回参
    },
    radio: {
        component: ARadio.Group,
        name: 'ARadio'
    },
    dateRange: {
        component: SubmitTimeSection,
        name: 'SubmitTimeSection'
    },
    steward: {
        component: Steward,
        name: 'Steward',
        placeholder: '请选择'
    },
    select: {
        component: CustomSelect,
        name: 'CustomSelect',
        placeholder: '请选择'
    },
    textArea: {
        component: AInput.TextArea,
        name: 'TextArea',
        placeholder: '请输入',
        autoSize: { minRows: 5 },
        input: e => e.target.value // 预处理回参
    },
    userGroup: {
        component: UserGroup,
        name: 'UserGroup',
        placeholder: '请选择'
    },
    address: {
        component: AddressCascader,
        name: 'AddressCascader',
        placeholder: '请选择'
    },
    searchInput: {
        component: SearchInput,
        name: 'SearchInput',
        placeholder: '请输入'
    },
    descriptions: {
        component: CustomDescriptions,
        name: 'CustomDescriptions'
    },
    datePicker: {
        component: CustomDatePicker,
        name: 'CustomDatePicker',
        placeholder: '请选择'
    },
    table: {
        component: ATable,
        name: 'ATable'
    },
    selectDataRange: {
        component: SelectDataRange,
        name: 'SelectDataRange'
    }
}
