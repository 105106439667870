import { getVm } from '@/utils/vm'
import getCustomerSourceListService from '@/service/customer/getCustomerSourceListService'
import getSourceListService from '@/service/customer/getSourceListService'
import { CUSTOMER_INTENTION_ENUM_LIST } from '@/constant/enum'
import { getLabelformList } from '@/utils'
import moment from 'moment'

const CUSTOMER_LABEL = [{
    label: '潜在客户',
    value: '潜在客户'
}, {
    label: '活跃客户',
    value: '活跃客户'
}, {
    label: '流失客户',
    value: '流失客户'
}]


export default [
    {
        hideInTable: true,
        title: '手机号',
        dataIndex: 'mobileQuery'
    },
    {
        hideInTable: true,
        title: '企业名称',
        dataIndex: 'enterpriseName'
    },
    {
        hideInTable: true,
        title: '地区',
        dataIndex: 'areaCodeList',
        valueType: 'address',
        initialValue: []
    },
    {
        title: '客户标签',
        valueType: 'select',
        hideInTable: true,
        dataIndex: 'label',
        initialValue: '',
        fieldProps: {
            options: [
                {
                    label: '全部',
                    value: ''
                }, ...CUSTOMER_LABEL
            ]
        }
    },
    {
        hideInTable: true,
        title: '客户来源',
        dataIndex: 'customerSourceIdQuery',
        valueType: 'select',
        initialValue: '',
        fieldProps: {
            asyncData: async () => {
                try {
                    const data = await getCustomerSourceListService()

                    return [
                        {
                            label: '全部',
                            value: ''
                        }, ...data.map(({ id, name }) => ({
                            label: name,
                            value: id
                        }))
                    ]
                } catch (e) {
                    getVm().$message.error(e.message)
                }
            }
        }
    },
    {
        hideInTable: true,
        title: '是否注册',
        dataIndex: 'stateQuery',
        valueType: 'select',
        initialValue: '',
        fieldProps: {
            options: [
                {
                    label: '全部',
                    value: ''
                }, {
                    label: '是',
                    value: '1'
                }, {
                    label: '否',
                    value: '0'
                }
            ]
        }
    },

    {
        hideInSearch: true,
        title: '手机号',
        dataIndex: 'mobile',
        width: 125,
        fixed: 'left'
    },
    {
        title: '客户标签',
        hideInSearch: true,
        dataIndex: 'label',
        width: 180
    },
    {
        hideInSearch: true,
        title: '客户来源',
        dataIndex: 'customerSourceName',
        width: 180
    },
    {
        title: '客户意向',
        dataIndex: 'intention',
        width: 180,
        customRender: getLabelformList(CUSTOMER_INTENTION_ENUM_LIST),
        valueType: 'select',
        initialValue: '',
        fieldProps: {
            options: [
                {
                    label: '全部',
                    value: ''
                }, ...CUSTOMER_INTENTION_ENUM_LIST
            ]
        }
    },
    {
        hideInTable: true,
        title: '注册类型',
        dataIndex: 'userSourceCode',
        valueType: 'select',
        initialValue: '',
        fieldProps: {
            asyncData: async () => {
                try {
                    const data = await getSourceListService()

                    return [
                        {
                            label: '全部',
                            value: ''
                        }, ...data.map(({ code, name }) => ({
                            label: name,
                            value: code
                        }))
                    ]
                } catch (e) {
                    getVm().$message.error(e.message)
                }
            }
        }
    },
    {
        hideInSearch: true,
        title: '注册类型',
        dataIndex: 'userSourceName',
        width: 180

    },
    {
        hideInSearch: true,
        title: '客户创建时间',
        dataIndex: 'createDateTime',
        valueType: 'dateRange',
        fieldProps: { disabledDate: () => false },
        width: 180
    },
    {
        title: '注册时间',
        dataIndex: 'registerDateTime',
        valueType: 'dateRange',
        width: 180,
        span: 12,
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
        fieldProps: {
            showTime: { defaultValue: [moment('2022-01-01 00:00:00'), moment('2022-01-01 23:59:59')] },
            format: 'YYYY-MM-DD HH:mm:ss',
            disabledDate: () => false
        },
        isExpansion: true
    },
    {
        title: '更新时间',
        dataIndex: 'modifyDateTime',
        valueType: 'dateRange',
        width: 180,
        span: 12,
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
        fieldProps: {
            showTime: { defaultValue: [moment('2022-01-01 00:00:00'), moment('2022-01-01 23:59:59')] },
            format: 'YYYY-MM-DD HH:mm:ss',
            disabledDate: () => false
        },
        isExpansion: true
    },
    {
        hideInSearch: true,
        title: '操作',
        key: 'action',
        scopedSlots: { customRender: 'action' },
        width: 240,
        fixed: 'right'
    }
]
