/** *
 * @desc 用户操作 - 修改密码
 */

// 组件
import BasicLayout from '@layout/BasicLayout'

// code
import { USER_ACTION_MENU_CODE, USER_ACTION_UPDATE_PASSWORD_MENU_CODE } from '@constant/menu_code'

export default [
    {
        path: '/user-action',
        name: 'UserAction',
        code: USER_ACTION_MENU_CODE,
        component: BasicLayout,
        hidden: true,
        meta: {
            title: '用户',
            breadcrumb: false
        },
        children: [
            {
                path: 'update-password',
                name: 'UpdatePassword',
                code: USER_ACTION_UPDATE_PASSWORD_MENU_CODE,
                component: () => import('./UpdatePassword'),
                meta: {
                    title: '修改密码',
                    breadcrumb: true
                }
            }
        ]
    }
]
