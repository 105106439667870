// 通用form子项渲染组件
import FormCol from '@components/FormCol'
import typeEnum from './typeEnum'

export default {
    name: 'FormItem',
    components: {
        FormCol,
        ...Object.values(typeEnum).reduce((t, i) => Object.assign(t, { [i.name]: i.component }), {}) // 导入配置注册组件
    },
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        columns: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        trimVal(val) {
            if (typeof val === 'string') {
                return val.trim()
            }
            return val
        }
    },
    data() {
        return {}
    },
    render(c) {
        return c('fragment', {}, this.columns.map(
            ({ key, dataIndex, valueType, title, span, fieldProps, valueName = 'value', ...other }) => {
                const { name: componentName, input: preInput, change: preChange, ...componentOther } = typeEnum[valueType] || typeEnum.input
                return c('FormCol', {
                    attrs: {
                        key: key || dataIndex,
                        label: title,
                        prop: String(dataIndex),
                        ...other,
                        ...this.$attrs,
                        span: span || this.$attrs.span
                    }
                }, [
                    c('template', { slot: 'formElements' }, [
                        c(componentName, {
                            domProps: { [valueName]: this.value[dataIndex] },
                            attrs: {
                                [valueName]: this.value[dataIndex],
                                ...componentOther,
                                ...fieldProps
                            },
                            on: {
                                input: val => {
                                    this.value[dataIndex] = this.trimVal(preInput ? preInput(val) : val)
                                },
                                change: val => {
                                    if (preChange) {
                                        this.value[dataIndex] = this.trimVal(preChange(val))
                                    }
                                }
                            }
                        }, [])
                    ])
                ])
            }
        ))
    }
}
