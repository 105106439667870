<template>
    <div class="descriptions-with-head__container">
      <div class="descriptions-with-head__container__title">

        <div class="descriptions-with-head__container__title__text">
          <template v-if="title">{{title}}</template>
          <!--插槽用于动态数据-->
          <slot v-if="!title && $slots.title" name="title"></slot>
          <div v-if="$slots.titleDesc" class="descriptions-with-head__container__title__text__desc">
            <slot name="titleDesc"></slot>
          </div>
        </div>

        <div class="descriptions-with-head__container__button">
          <slot name="button"></slot>
        </div>

      </div>

      <div v-if="$slots.default" class="descriptions-with-head__container__slot">
        <slot></slot>
      </div>
    </div>
</template>

<script>

export default {
name: "DescriptionsHead",
  components:{
  },
  props: {
    title: {
      type: String,
      default() {
        return '';
      }
    },
  },
}
</script>

<style lang="scss">
@import "@assets/styles/varibles.scss";
.descriptions-with-head__container{
  border-radius: $border-radius-base;
  border: 1px solid #F0F0F0;
  &__title{
    padding:12px 20px;
    background: #FAFAFA;
    border-bottom: 1px solid $border-color;
    display: flex;
    align-items: center;
    &__text{
      display: inline-block;
      flex: 1;
      //overflow: hidden;
      //white-space: nowrap;
      //text-overflow: ellipsis;
      &__desc{
        margin-top: 20px
      }
    }
  }
  &::after {
    clear: both;
  }

  &__button{
    float: right;
    padding-right: 4px;
  }
  &__slot{
    padding:$space-base 12px
  }
  &:not(:last-child){
    margin-bottom: $space-base;
  }
}

</style>