/**
 * 产品列表 模块
 */
import BasicLayout from '@layout/BasicLayout'

import {
    PRODUCT_PAGE_LIST_MENU_CODE,
    PRODUCT_PAGE_LIST_LIST_MENU_CODE
} from '@constant/menu_code'

const routes = [{
    path: '/product',
    name: 'Product',
    redirect: '/product/list',
    component: BasicLayout,
    code: PRODUCT_PAGE_LIST_MENU_CODE,
    sort: 99,
    meta: {
        title: '产品列表',
        icon: 'chanpinliebiao',
        iconType: 'iconfont'
    },
    children: [{
        path: 'list',
        name: 'ProductList',
        component: () => import('./ProductList/index'),
        hidden: true,
        code: PRODUCT_PAGE_LIST_LIST_MENU_CODE,
        meta: {
            breadcrumb: false,
            keepAlive: true
        },
        children: []
    }]
}]

export default routes

