/**
 * Created by hiro on 2020/07/23.
 * axios 数据拦截
 */

import axios from 'axios'
import {HTTP_ERR_UNAUTHORIZED} from '@/constant/api_status'
// import {throwHTTPError} from '@utils/throwHTTPError'
import {getToken, removeToken} from '@utils/auth'
import {
    API_VERSION,
    LOGIN_SOURCE_CODE,
    LOGIN_APP_CODE,
    LOGIN_SOURCE_VALUE_CODE,
    REGISTER_BUSINESS_DETAILS_CODE,
    APP_CODE
} from '@config'
import store from '@store'
import {notification} from 'ant-design-vue'
import qs from 'qs';


let isLogout = false

function loginOut() {

    if (isLogout) {
        return
    }

    isLogout = true

    store.dispatch('logOut')
    // notification.error({
    //     message: '错误',
    //     description: '长时间未操作，请重新登录'
    // })
    // setTimeout(() => {
    //     store.dispatch('logOut')
    // }, 500)
}

axios.interceptors.request.use(function (config) {

    // 1. 请求头-添加token、version
    // 获取token
    const TOKEN = getToken()
    if (TOKEN) {
        config.headers.common['token'] = TOKEN
    }
    if (config.url !== '/api/user/login') {
        const version = API_VERSION
        config.headers.common['version'] = version

        // 2. 请求头-添加appCode 针对不是登录情况的，发送验证码的时候的appCode
        if (config.url === '/api/user/sendCode') {
            // 2.1 获取验证码
            config.headers['appCode'] = APP_CODE
        }
    } else {
        // 3. 请求头-添加appCode
        //    登录
        config.headers['appCode'] = LOGIN_APP_CODE
    }

    // 3. 请求头-添加sourceCode、sourceValueCode
    //    登录、退出登录
    const userApis = [
        '/api/user/login',
        '/api/user/logOut',
        '/api/user/sendCode',
        '/api/user/user/update',
        '/api/user/userQuery/verifyIdentity',
        '/api/user/user/password'
    ]
    if (userApis.includes(config.url)) {
        config.headers['sourceCode'] = LOGIN_SOURCE_CODE
        config.headers['sourceValueCode'] = LOGIN_SOURCE_VALUE_CODE
    }

    // 4. 请求头-添加业务来源明细
    const userDetailApis = [
        '/api/user/login'
    ]
    if (userDetailApis.includes(config.url)) {
        config.headers['businessDetails'] = encodeURIComponent(REGISTER_BUSINESS_DETAILS_CODE)
    }

    window.NProgress.start() // 进度条开始
    return config
})
// 劫持响应数据
axios.interceptors.response.use(function (response) {
    window.NProgress.done()

    if (response.data.code === HTTP_ERR_UNAUTHORIZED) {
        loginOut()
        removeToken()
        window.location.reload()
        return
    }

    // 对响应数据做点什么
    return response
}, function (error) {
    window.NProgress.done()

    if (error.response.status === HTTP_ERR_UNAUTHORIZED) {
        loginOut()
        removeToken()
        window.location.reload()
        return
    }
    //  处理错误信息
    // let errMsg = throwHTTPError(error)
    // 对响应错误做点什么
    return Promise.reject(error)
})


export default axios
