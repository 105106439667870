import { BASE_URL } from '@/config'

/** 用户中心-登录
 * ————————————————————————————————————————————————————————————————————————————————————————————*/

/**
 * 密码登录
 */
export const PASSWORD_LOGIN = {
    url: `${BASE_URL}/user/login`,
    method: 'post',
    headers: {
        'content-type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

export const SEND_CODE = {
    url: `${BASE_URL}/user/sendCode`,
    method: 'post',
    headers: {
        'content-type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

export const SMS_LOGIN_OR_REGISTER = {
    url: `${BASE_URL}/user/smsLoginRegister`,
    method: 'post',
    headers: {
        'content-type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}


export const USER_REGISTER = {
    url: `${BASE_URL}/user/userRegister`,
    method: 'post',
    headers: {
        'content-type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}