<template>
    <div class="handle-customer-modal-form-panel">
        <a-descriptions bordered :column="1" size="middle">
            <a-descriptions-item label="用户ID：">
                {{ empty(currentRow.userId) }}
            </a-descriptions-item>
            <a-descriptions-item label="手机号码：">
                {{ empty(currentRow.customerMobile) }}
            </a-descriptions-item>
            <a-descriptions-item label="姓名：">
                {{ empty(currentRow.name) }}
            </a-descriptions-item>
            <a-descriptions-item label="当前经纪人：">
                {{ `${empty(currentRow.agentUserName)}（${empty(currentRow.agentUserId)}）`}}
            </a-descriptions-item>
        </a-descriptions>
        <a-form-model
            ref="ruleForm"
            layout="horizontal"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 24 }"
            :model="form"
            :rules="rules"
        >
            <!-- 转交经纪人  输入关键字搜索select -->
            <a-form-model-item label="转交经纪人" prop="agentName">
                <a-select
                    v-model="form.agentName"
                    show-search
                    placeholder="请选择经纪人"
                    option-filter-prop="children"
                    :filter-option="true"
                    :allow-clear="true"
                    :show-arrow="true"
                    @change="handleChange"
                    @search="getAgentList"
                >
                    <a-spin v-if="loading" slot="notFoundContent" size="small" type="loading" />
                    <a-select-option v-for="item in agentSelectList" :key="item.userId" :value="`${item.userName} （${item.userId}）`">
                        {{ item.userName || '-' }} （{{ item.userId }}）
                    </a-select-option>
                </a-select>
            </a-form-model-item>
        </a-form-model>
    </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { 
    Descriptions as ADescriptions,
    FormModel as AFormModel,
    Select as ASelect,
} from 'ant-design-vue'
// mixins
import empty from '@mixins/empty'
import { PERSONAL_STATUS_IN_JOBS_TEXT } from '@constant/enum'
// 接口请求
import proprietaryTransferCustomerService from '@service/customer/proprietaryCustomer/proprietaryTransferCustomerService'
import getTeamUserSelectListService from '@/service/customer/getTeamUserSelectListService'

export default {
    name: 'HandCustomerModalFormPanel',
    components: {
        AFormModel,
        AFormModelItem: AFormModel.Item,
        ASelect,
        ASelectOption: ASelect.Option,
        ADescriptions,
        ADescriptionsItem: ADescriptions.Item,
    },
    mixins: [empty],
    props: {
        currentRow: {
            type: Object,
            default() {
                return {}
            }
        },
        type: {
            type: String,
            default() {
                return ''
            }
        }
    },
    data() {
        this.getAgentList = debounce(this.getAgentList, 500)
        return {
            form: {
                agentName: undefined,
            },
            agentUserId: '',
            // 经纪人数据
            agentSelectList: [],
            loading: true,
            rules: {
                agentName: [
                    {
                        required: true,
                        message: '请输入关键字选择团队',
                        trigger: 'blur'
                    }
                ]
            }
        }
    },
    mounted() {
        this.getAgentList()
    },
    methods: {
        /**
         * 获取经纪人
         */
        async getAgentList(userNameQuery) {
            const { type } = this
            try {
                this.loading = true
                const data = await getTeamUserSelectListService({
                    assignment: true,
                    userNameQuery,
                    type,
                    userFormalStateEnum: PERSONAL_STATUS_IN_JOBS_TEXT
                })
                this.agentSelectList = data || []
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        },
        handleChange(value) {
            if (!value) {
                this.getAgentList()
            } else {
                let splitValue = value && value.split(' ')[0]
                this.getAgentList(splitValue)
            }
            this.getId(value)
        },
        /**
         * 根据name获取id
         */
        getId(value) {
            const { agentSelectList } = this
            let splitValue = value && value.split(' ')[0]
            let splitValueId = value && value.split(' ')[1]
            let result = agentSelectList.find(item => {
                return item.userName === splitValue && `（${item.userId}）` === splitValueId
            })
            this.agentUserId = result && result.userId || ''
        },
        async handleSaveTransfer() {
            const { currentRow, agentUserId } = this
            const { customerId } = currentRow
            try {
                this.$emit('loading', true)
                await proprietaryTransferCustomerService(
                    {
                        customerId,
                        agentUserId
                    }
                )
                this.$message.success('操作成功')
                this.$emit('success')
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.$emit('loading', false)
            }
        },
        handleSend() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.handleSaveTransfer()
                }
            })
        },
    }

}
</script>

<style lang='scss'>
.handle-customer-modal-form-panel {
    .ant-descriptions-item-label {
        width: 120px;
        text-align: right;
    }
    .ant-descriptions-view table {
        table-layout: fixed;
    }
    .ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-label, 
    .ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-content {
        padding: 12px;
    }
    .ant-form {
        margin-top: 20px;
    }
}
</style>