import Vue from 'vue'
import Router from 'vue-router'
import {constantRouterMap} from './routes'

Vue.use(Router)

let debug = process.env.NODE_ENV !== 'production'

export default new Router({
    routes: constantRouterMap,
    strict: debug,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    base  : process.env.VUE_APP_ROUTER_BASE || '/',
    mode  : process.env.VUE_APP_ROUTER_MODE || 'hash'
})


