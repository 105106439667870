var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('list-page-layout',{staticClass:"customer-list-page work-space-page"},[_c('div',{staticClass:"infoCard"},[(_vm.avatarUrl)?_c('img',{staticClass:"avatar",attrs:{"src":_vm.avatarUrl}}):_c('img',{staticClass:"avatar",attrs:{"src":require("@assets/images/workSpace/default-avatar@2x.png")}}),_c('div',{staticClass:"info"},[_c('p',{staticClass:"name"},[_vm._v(_vm._s(_vm.welcomeText)+"，"+_vm._s(_vm.welcomeUserText)+"!")]),(false)?_c('p',{staticClass:"job"},[_vm._v(_vm._s(_vm.teamName))]):_vm._e(),(_vm.teamName && !_vm.personal)?_c('a-button',{staticClass:"look",on:{"click":_vm.handleTeam}},[_c('img',{staticClass:"search",attrs:{"src":require("@assets/images/workSpace/team-search@2x.png"),"alt":""}}),_c('span',[_vm._v("查看团队详情")])]):_vm._e(),_c('a-button',{directives:[{name:"auth",rawName:"v-auth",value:(_vm.POLICY_ANALYSIS_G_BUTTON),expression:"POLICY_ANALYSIS_G_BUTTON"}],staticClass:"policy-analysis-button",attrs:{"type":"info"},on:{"click":_vm.handleToPolicyAnalysisG}},[_c('img',{staticClass:"policy-icon",attrs:{"src":_vm.policyAnalysisIcon,"alt":""}}),_vm._v(" "),_c('span',[_vm._v("G端保单分析")])])],1)]),_c('div',{staticClass:"content"},[_c('p',{staticClass:"header"},[_c('span',[_vm._v("业绩数据")]),_c('a',{on:{"click":_vm.handleMoreDetail}},[_vm._v("查看更多"),_c('span',[_vm._v(">")])])]),_c('statistics-simple-bar',{attrs:{"data-source":_vm.statisticsList,"loading":_vm.statisticsLoading}}),_c('div',{staticClass:"msgTitle"},[_c('p',[_vm._v("消息中心")])]),_c('a-list',{ref:"list",attrs:{"item-layout":"vertical","size":"large","pagination":_vm.paginationProps,"data-source":_vm.listData},scopedSlots:_vm._u([{key:"renderItem",fn:function(ref, index){
var id = ref.id;
var type = ref.type;
var content = ref.content;
var createDateTime = ref.createDateTime;
var name = ref.name;
var reading = ref.reading;
return [_c('a-list-item',{staticClass:"msg-item",on:{"click":function($event){return _vm.readingItem(reading,id)}}},[(_vm.avatarUrl)?_c('img',{staticClass:"avatar",attrs:{"src":_vm.avatarUrl}}):_c('img',{staticClass:"avatar",attrs:{"src":require("@assets/images/workSpace/default-avatar@2x.png")}}),_c('div',{staticClass:"center"},[_c('p',{staticClass:"message"},[_vm._v(_vm._s(_vm.welcomeUserText)+" "),_c('span',{staticClass:"type"},[_vm._v("收到消息")]),_vm._v(" "),_c('content-text',{attrs:{"message":content},on:{"click-order":function($event){return _vm.handleClickOrderNo($event,name,reading,id)}}})],1),_c('p',{staticClass:"time"},[_vm._v(_vm._s(createDateTime))])]),_c('div',{class:['tag', reading ?'read-tag':'unread-tag']},[_vm._v(_vm._s(_vm.messageStatus[reading]))])])]}}])})],1),_c('lead-record-detail',{attrs:{"detail-id":_vm.leadRecordDetailId,"visible":_vm.leadRecordDetailVisible},on:{"close":_vm.handleLeadRecordDetailClose}}),_c('order-record-detail',{attrs:{"cast-id":_vm.orderRecordDetailId,"visible":_vm.orderRecordDetailVisible},on:{"close":_vm.handleOrderRecordDetailClose}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }