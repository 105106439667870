/**
 * 系统 模块
 */
import BasicLayout from '@layout/BasicLayout'
import RouteLayout from '@layout/RouteLayout'

import {
    CLEW_MENU_CODE,
    CLIENT_CLEW_MENU_CODE,
    CLIENT_CLEW_LIST_MENU_CODE
} from '@constant/menu_code'

const routes = [{
    path: '/clew',
    name: 'clew',
    redirect: '/clew',
    component: BasicLayout,
    code: CLEW_MENU_CODE,
    sort: 99,
    meta: {
        title: '线索管理',
        icon: 'C-xiansuoguanli',
        iconType: 'iconfont'
    },
    children: [{
        path: 'client-clew',
        redirect: '/clew/client-clew/list',
        name: 'ClientClew',
        component: RouteLayout,
        code: CLIENT_CLEW_MENU_CODE,
        meta: { title: '客户线索' },
        children: [{
            path: 'list',
            name: 'ClientClewList',
            hidden: true,
            component: () => import('./ClientClew/ClientClewList/index'),
            code: CLIENT_CLEW_LIST_MENU_CODE,
            meta: {
                title: '客户线索',
                breadcrumb: false
            }
        }

        ]
    }]
}]

export default routes

