<template>
    <div class="clearfix upload-container">
        <a-upload
            :list-type="listType"
            :disabled="prohibit"
            v-bind="props"
            v-on="listeners"
            @preview="handlePreview"
        >
            <div v-if="fileList.length < uploadAmount">
                <a-icon type="plus" />
                <div class="ant-upload-text">
                    {{ text }}
                </div>
            </div>
        </a-upload>
        <div v-if="$slots.samplePicture" class="sample_picture">
            <slot name="samplePicture" />
            <div class="sample_picture__title">示例图片</div>
        </div>
        <div class="upload-conditions">
            <slot name="uploadConditions" />
        </div>
        <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
            <img alt="example" style="width: 100%" :src="previewFile">
        </a-modal>
    </div>
</template>

<script>
import {
    Icon as AIcon,
    Upload as AUpload,
    Modal as AModal
} from 'ant-design-vue'
import mixins from './mixins'
import fileType from '@/mixins/fileType'
function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
    })
}
export default {
    name: 'Upload',
    components: {
        AUpload,
        AIcon,
        AModal
    },
    mixins: [mixins, fileType],
    props: {
        text: {
            type: [String],
            default: '上传图片'
        },
        uploadAmount: {
            type: [Number, String],
            default: 1
        },
        prohibit: {
            type: Boolean,
            default: false
        },
        // 在新标签页预览
        previewInOpen: {
            type: Boolean,
            default: false
        },
        listType: {
            type: String,
            default: 'picture-card'
        },
        // 需要下载
        needDownload: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            previewVisible: false,
            previewFile: ''
        }
    },
    methods: {
        handleCancel() {
            this.previewVisible = false
        },
        async handlePreview(file) {
            if (!file.url && !file.preview && !file.response) {
                file.preview = await getBase64(file.originFileObj)
            }
            this.previewFile = file.url || file.response || file.preview

            const { previewInOpen } = this
            // 非图片则在新标签页预览
            if (previewInOpen) {
                window.open(this.previewFile)
            } else {
                let typeArr = [
                    'image/png',
                    'image/jpeg',
                    'png',
                    'jpeg',
                    'image/jpg',
                    'jpg'
                ]
                let fileType = this.formatterFileType(this.previewFile)
                if (typeArr.includes(fileType)) {
                    this.previewVisible = true
                } else {
                    window.open(this.previewFile)
                }
            }
        }
    }
}
</script>

<style lang='scss'>

.upload-container {
    .sample_picture{
        width: 104px;
        margin: 0 8px 8px 0;
        display: inline-block;
        vertical-align: top;
        border: 1px dashed #d9d9d9;
        border-radius: 4px;
        &__title{
            text-align: center;
            line-height: 24px;
        }
        img{
            width: 86px;
            margin: 8px 8px 0 8px;
            border-radius: 4px;
            min-height: 70px;
        }
    }
    .upload-conditions {
        font-size: 12px;
        color: #999999;
    }
    .ant-upload-picture-card-wrapper{
        vertical-align: middle;
        width: auto;
    }
    .ant-upload-list{
        display: inline-block;
    }
    .ant-upload-select-picture-card i {
        font-size: 32px;
        color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
        margin-top: 8px;
        color: #666;
    }
}
</style>
